<template>
  <div class="fontSmall taLeft">
    <div class="p-mb-2">
      So what are some not-allowed thoughts? Listed below are the most common
      among our amateur and professional clients. Place a check in the box that
      represents the frequency you have the thought during a round.
    </div>
    <div class="p-mb-2">
      Thinking About Your Score: This is a preoccupation-not a casual
      awareness-with your score that keeps you involved with your numbers and
      position. It can interfere with peaceful focus and your ability to give
      100 percent to the shot at hand. After their best rounds, our clients
      consistently describe having only a vague awareness of their score, mainly
      because they directed their mental energies toward more productive or
      calming topics.
    </div>
    <div class="p-mb-2">
      Holes ahead of you: This is anticipating holes not yet played. Whether it
      is done with fear, dread, or excitement, it is poor use of mental energies
      and it compromises your ability to concentrate on your next shot.
    </div>
    <div class="p-mb-2">
      Looking Back on Your Round: If you do this while you're still in the
      process of playing your round, nonconstructive thoughts of regret,
      frustration, disappointment, or faulty fundamentals will only take away
      from the shot at hand and further sabotage your play. Left unchecked, many
      players spend far too much time between shots dwelling on their previous
      play. We encourage players to give themselves a couple of seconds to react
      to their shot, quickly assess which part of their mental routine they
      could have done better, and move on. You should not look back on your
      round until you're finished, at which point you can effectively "debrief"
      yourself and learn from your mistakes. It is said that, to prevent himself
      from looking back in his rounds and distracting himself with analytical,
      negative, or mechanical thoughts while playing, Ben Hogan would imagine
      building a brick wall behind him, This imaginary wall helped keep his
      thoughts 100 percent in the present for every shot.
    </div>
    <div class="p-mb-2">
      Negative Self-Talk: We've all heard players tell their partners, "You
      talked yourself into that one." This refers to the habit of being self
      critical, dwelling on the worst in your play, berating your mental and
      physical abilities, placing emphasis on faults and weaknesses, or calling
      yourself names. All this negativity spirals confidence downward, fosters
      self-doubt, and inhibits play.
    </div>
    <div class="p-mb-2">
      Mechanical Analysis: While analyzing how to hit a shot and then how to
      correct a shot comes naturally to most abstract golfers, it generally does
      more harm than good when you do it during a round. Analysis compromises
      your use of natural and trained skills. As a result, the player will find
      himself putting more mental energy into making a good golf swing than into
      intuitively playing his round.
    </div>
    <div class="p-mb-2">
      Thinking about the Outcome: In simple terms, this is the habit of
      concerning yourself more with the results of your shots than with the
      process of executing them. Many of my clients and I refer to this
      potentially all consuming type of faulty thinking as the "black hole of
      the golfer's universe." Everything about the game seems to unwittingly
      pull you into a state of being preoccupied with outcome thoughts. You can
      be totally engulfed before you even know you are there. And once you are
      there in the black hole, it is virtually impossible to play your best
      golf.
    </div>
    <div class="p-mb-2">
      Most golfers fall into this trap because scores are usually cumulative
      over eighteen or more holes. After you've finished a round, those scores
      become a permanent history of your skills. The most frequently asked
      question among professional golfers is probably, “What did you shoot?” The
      most frequently asked question among amateurs is probably, “What is your
      handicap?” you will never get far away from outcome thinking, but to play
      your best you must learn to manage it.
    </div>
    <div class="p-mb-2">
      Not a good idea to concentrate your energies on “birdie-holes”, or what
      you should or should-not be making on a hole. Especially damaging is
      having these thoughts while over the ball preparing to hit a shot.
      Successful players usually find great results by simply going back to
      their strong mental routine. For example, as they prepare to hit their tee
      shots, they choose to think less about distance and more about a good
      target, a good picture, and a good tempo on a par 5 “birdie hole”.
    </div>
    <div class="p-mb-2">
      Outcome-thinking is common on the green, to, where too often players want
      a putt to fall so badly - perhaps they're in a putting slump or not
      playing well from tee to green - that they think about the result rather
      than the putt itself. Some unknowingly put more pressure on themselves
      when they want to capitalize on a streak of great ball-striking. Others
      feel an urgency to make more putts to compensate for poor ball-striking.
      In either case, fewer putts drop when golfers start thinking more about
      making a putt than about making a smooth stroke or a nice roll. Each of
      these players have improved the quality of their putting by simply
      concentrating more on seeing their line and feeling their stroke and less
      on how much they need or want to make the putt.
    </div>
    <div class="p-mb-2">
      What Others Think: Do you concern yourself with the thoughts and opinions
      of others as you try to compete? You shouldn't because what you're doing
      is the equivalent of trying to read the minds of other players, the
      gallery, teachers, coaches, family, or friends in anticipation of their
      judgment, their disapproval, or letting them down. Such thinking typically
      clutters focus, elevates tension, increases emotions, and sabotages play.
    </div>
  </div>
</template>
