<template>
  <Panel
    header="Emotional Resilience Training"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">EMOTIONAL RESILIENCE EXERCISES</div>
      <div class="p-mb-2">
        Write down ten things you are grateful for, and genuinely feel that
        gratitude.
      </div>
      <div class="p-mb-2">
        Hit ten shots and fall in love with each shot before stepping into the
        Play Box.
      </div>
      <div class="p-mb-2">
        Hit some shots with lots of adrenaline and some shots with very little
        adrenaline. Which makes you swing better? You can change your adrenaline
        state through breathing exercises and physical movements in the Think
        Box. Do the same on the putting green.
      </div>
      <div class="p-mb-2">
        Hit fifteen different shots, and before each shot spend fifteen extra
        seconds genuinely feeling something positive in your heart in the Think
        Box. A PGA Tour player we coach calls this “accessing active gratitude”
        before he enters the Play Box.
      </div>
      <div class="p-mb-2">
        Hit ten shots while you smile at the golf ball. Keep smiling the entire
        swing.
      </div>
      <div class="p-mb-2">“Hit ten putts, smiling for the entire stroke.”</div>
      <div class="p-mb-2">
        Practice hitting shots with high, medium and low adrenaline levels. Jog
        or do jumping jacks to get your adrenaline up. Sit down and breathe
        deeply with long exhales to get your adrenaline down. At what adrenaline
        level do you perform the best?
      </div>
      <div class="p-mb-2">
        Write down ten things that create DHEA for you. What makes you feel
        positive emotions? At least five of these things need to be things you
        can do while playing on the golf course.
      </div>
      <div class="p-mb-2">
        Play on the course and monitor your adrenaline and emotional state after
        each hole. Do it with the grid the way we explained in this chapter.
      </div>
      <div class="p-mb-2">
        Play on the course, and after each shot, for ten seconds, access
        something that makes you feel positive emotions.
      </div>
      <div class="p-mb-2">Functional Emotional Resilience:</div>
      <div class="p-mb-2">
        You can catch yourself feeling negative emotions on the golf course and
        you are able to shift your emotions during the same hole, or before
        starting the next hole.
      </div>
      <div class="p-mb-2">Masterful Emotional Resilience:</div>
      <div class="p-mb-2">
        For eighteen holes you are neutral or happy. You feel that you come off
        the golf course with more energy, with more DHEA and feeling better
        about yourself “than when you started, no matter the outcome.”
      </div>
    </div>
  </Panel>
</template>
