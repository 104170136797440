<template>
  <Panel
    header="Self-Talk guiding RAS (Nielsson)"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">Drown Self-Talk with Useful Ideas</div>
      <div class="p-mb-2">
        It’s not enough to rage against the lie; you’ve got to replace it with
        the truth. Bono, rock musician and social activist
      </div>
      <div class="p-mb-2">
        SWING KEY: You can control what that voice says to you.
      </div>
      <div class="p-mb-2">
        We all move within a circle of life that includes thoughts, feelings and
        behavior, each affecting the other. Harmony is created by blending those
        elements, making a soup of delicious ingredients in a perfect balance.
        Part of the struggle to maintain that balance is keeping your focus on
        that which you can control and not letting your mind be distracted by
        those elements of life beyond your control—those ingredients that are
        going to be part of the soup stock of your life no matter what.
      </div>
      <div class="p-mb-2">
        It is essential to remember that what you pay attention to—what you
        focus on—tends to grow in importance in your mind and in your world. In
        many ways, it is through this attention that you validate the existence
        of your feelings and make the past a part of your future. One of the
        ways that happens involves how you react to events in your life. And it
        involves how you react to your reactions.
      </div>
      <div class="p-mb-2">
        We all have that voice within us that evaluates our actions and passes
        judgment on those actions. That voice also has a major influence on
        future actions by talking to you as you prepare for those actions.This
        self-talk can be a positive force, but more often for most of us, it is
        a negative factor. It is up to you to manage your self-talk. One of the
        most fundamental skills in life is to learn how to manage the conscious
        mind. That voice affects all we do. Scattered and distracted self-talk
        creates a scattered and distracted golf swing.
      </div>
      <div class="p-mb-2">
        Denying the existence of self-talk is a futile activity, just as it is
        absurd to deny tension or fear. It’s there, it exists and it is not
        going to go away. The key is managing that talk, not eliminating it.
        That sometimes not-so-little voice in your head that can create doubt
        and confusion exists—pure and simple. You have to acknowledge that it is
        nearly impossible to quiet it completely for long periods of time. This
        is a trained skill very few can do. But you need to train yourself to
        sense instead of think for the few seconds you are in the Play Box. You
        can replace the destructive noise with a positive song. The importance
        of being fully engaged with all your senses while in the Play Box cannot
        be overemphasized. You must be alive in the moment when in the Play Box
        and not living in your head.
      </div>
      <div class="p-mb-2">
        Debra Oberg is a businesswoman from New Jersey with a single-digit
        handicap. She is also a cancer survivor who has overcome doubts and
        fears that were not only whispered into her ear but also shouted at her
        in the cruelest voice life allows. Through VISION54, Debra gained a new
        perspective, not just on golf, but on how to handle those seeds of doubt
        our mind loves to sow in our confidence and optimism.
      </div>
      <div class="p-mb-2">
        I had faced cancer and divorce after fifteen years of marriage just
        prior to meeting Lynn and Pia, said Debra, who was told about our school
        by two-time New Jersey State Women’s Amateur champion Donna Young, a
        friend of hers. VISION54 transformed my life, my golf and my heart,
        Debra said. VISION54 gave me the tools to overcome my bad tendencies and
        not allow those bad thoughts to creep in. Note that Debra said, not
        allow those bad thoughts to creep in, and did not say, made those bad
        thoughts go away. That is a hugely important level of understanding on
        her part. One way to manage negative self-talk is to replace it with
        something.
      </div>
      <div class="p-mb-2">
        There is science to what we are saying. Beliefs are nothing more than
        specific neural patterns in your brain, thoughts that are so ingrained
        they have become automatic. They exist not because they are the truth
        but because someone put them there often enough or in dramatic enough
        fashion that they have stuck. They have become your truth. We are all
        constantly bombarded by a huge number of stimuli. Since we can’t handle
        it all, we establish a way to filter through it all and decide what to
        save. The decisions made by that filter are crucial to who you are.
      </div>
      <div class="p-mb-2">
        That filter in the brain is called the reticular activating system
        (RAS). The way you talk to yourself helps decide what the RAS lets
        through and the amount of importance it will be assigned. If you say, I
        always play the twelfth hole badly often enough, that will become your
        truth. We are not saying you should say, I always play number twelve
        great, because that is not the truth. But you can say: On number twelve,
        I will swing with perfect tempo and hold my finish. That would be way
        more useful.
      </div>
      <div class="p-mb-2">
        There are only two categories of information that get through the RAS
        filter. The first is information that is valuable to you right now. You
        never noticed all those car ads until your car broke down and you needed
        to buy a new one. Now those ads seem to be everywhere. You notice them.
      </div>
      <div class="p-mb-2">
        The second category is information that alerts you to an imminent threat
        or danger. Initially when you played the par-3, you hardly noticed the
        water that hugs the right edge of the green, and you made a par. But
        then you hit three balls into the water, and now the pond looks like an
        ocean when you stand on the tee. Your self-talk obsesses on the water,
        and so your RAS makes you focus on the potential danger. When you step
        up to hit your tee shot, all you are thinking about is the water and not
        your decision about the shot, to which you need to be totally committed.
        You can imagine the results.
      </div>
      <div class="p-mb-2">
        That voice shouting inside you programs the RAS to see the world in a
        certain way. That’s why you need to take ownership of your self-talk. To
        create a positive program inside of you, you need to talk to yourself in
        a positive way, or at least in a neutral or factual way. The past
        doesn’t have to be your future. Here’s the deal: Your self-talk has a
        direct impact on your performance, and we all have self-talk. We can’t
        change those two realities. But what we can do is learn how to manage
        self-talk. Thoughts create feelings and feelings create behavior
        patterns. When you think something often enough, it becomes your
        reality.
      </div>
      <div class="p-mb-2">
        There are five kinds of nonproductive self-talk to avoid. They all
        undermine your ability to be totally present when it is time to perform
        in the Play Box:
      </div>
      <div class="p-mb-2">
        1. Focusing on the past or future. Don’t think about the poor chip on
        the last hole or the demanding tee shot coming up three holes from now.
        Play this hole; hit this shot.
      </div>
      <div class="p-mb-2">
        2. Focusing on outcomes. Don’t add up your score until it is time to add
        up your score. Don’t think about how others are going to judge your
        performance.
      </div>
      <div class="p-mb-2">
        3. Focusing on things that are not under you control. So what if your
        playing partner is annoying? So what if it is starting to rain? You
        can’t control those things. Focus on what you can control—like keeping
        your focus.
      </div>
      <div class="p-mb-2">
        4. Focusing on what you are not good at or demanding perfection from
        yourself. Play your game with your skills and you will perform to your
        potential.
      </div>
      <div class="p-mb-2">
        During a round, each time you notice nonproductive thinking creeping
        into your inner monologue, write a note that includes which of the five
        categories the thinking belongs to. We have two strategies for managing
        self-talk. The first is to learn how to quiet your mind. This is a skill
        you can practice. You can learn to be present in the Play Box without
        thoughts. But if you can’t quiet your mind, that’s where the second
        strategy comes in. This strategy has two components: You can distract
        the mind by humming, singing, counting or some other activity; or you
        can learn how to manage the voice. This requires that you analyze your
        self-talk and learn its nature. Do you have a tendency to whine and feel
        sorry for yourself? Take notes on how you talk to yourself and read them
        over. Challenge your self-talk. Ask, Is that true? Get rid of words like
        should and must and need to. You write the script for that voice inside
        you.
      </div>
      <div class="p-mb-2">
        The way you talk to yourself is important, but so is what you choose to
        talk about. How many times have you sabotaged a good round by letting
        your mind race ahead and think something like: Gee, if I play the last
        five holes two over par I will break 80? Or have you lost your focus by
        remembering a bad shot from the past? Other pitfalls include thinking
        only about the outcome of the shot rather than the process and
        sabotaging yourself with an unreasonable demand for perfection, or
        letting your mind obsess on factors beyond your control, like the
        annoying behavior of your opponent.
      </div>
      <div class="p-mb-2">
        Since a round of golf lasts more than four hours, it is impossible not
        to have self-talk pop up at some point. In some stressful situations, an
        effective short-term solution is to simply distract the self-talk. We’ve
        had great success with this. Brittany Lincicome sang to herself between
        shots when she won the 2009 Kraft Nabisco Championship, and so did Yani
        Tseng when she captured the 2010 Ricoh Women’s British Open. Song-Hee
        Kim constantly said to herself: Vanilla yogurt. Kevin Streelman simply
        asks himself, Where is the target? Annika Sorenstam would say, Fairways
        and greens, fairways and greens. What are you going to use as a
        distractor, if needed?
      </div>
      <div class="p-mb-2">
        Pia Davis was at home in England watching golf on TV when she heard
        Mickey Walker, a great player who had become a commentator, mention that
        Suzann Pettersen had started winning tournaments after she began working
        with Lynn Marriott and Pia Nilsson. Pia Davis quickly did a Google
        search and found our first book, and that started her on her VISION54
        quest.
      </div>
      <div class="p-mb-2">
        I read it from cover to cover, getting really excited, including reading
        long passages out loud to my patient husband, said Pia, who plays to an
        8 handicap. Then I found that Lynn and Pia did programs—and I found a
        way to get to one in Arizona. And what she found at our school was the
        Essential that had the greatest impact on creating a peak-performance
        state for her in the Play Box.
      </div>
      <div class="p-mb-2">
        I learned that golf is much less about ‘the result’ and much more about
        how I manage each shot and manage myself during the round, Pia says. It
        is not easy. I find I have to be quite disciplined to manage myself for
        a complete round.
      </div>
      <div class="p-mb-2">
        Pia worked on managing her self-talk by concentrating on being neutral
        in her reaction after disappointing shots. She learned breathing
        exercises that helped her silence the voice of doubt or anger that would
        pop up inside her, and she became aware of her tendency to feel
        victimized by her play.
      </div>
      <div class="p-mb-2">
        One way Pia learned to manage her self-talk was to write down the
        negative things she said to herself and figure out how to rephrase what
        she said in a more positive way. Another important one for me is to keep
        asking, ‘Is that true?’ when dealing with issues, she says about a
        self-talk management skill. I have to say that this is all a work in
        progress, Pia continues. The more I work on these concepts and skills,
        the more there is to discover and then work on further. Pia, Lynn and
        the team are all incredible people. They are genuinely committed to
        helping people fall back in love with playing the game of golf.
      </div>
      <div class="p-mb-2">
        We asked Pia Davis for an example of how she edits her self-talk, and
        she told us about a long par-4 hole at her home course. Pia said she
        would find that voice inside her saying she needed to hit a great drive
        on this hole and hit the green in two strokes in order to have any
        chance of making a par. What she learned to do through developing an
        understanding of self-talk was to rewrite her inner monologue to where
        she was saying: I have gotten up-and-down for par on this hole recently,
        so it is just not true that I have to be on the green in two strokes. In
        fact, the second shot is more important than the drive because there are
        times when it is a better strategy to leave my approach shot short and
        in the right place for a better angle for the chip to the pin. Besides,
        this is a great driving hole! It is huge and wide open!
      </div>
      <div class="p-mb-2">
        Long-term, it is about creating productive self-talk. Many people simply
        do not know what their self-talk is like. A good way to start is to say
        to yourself aloud what that voice inside you says during a round of
        golf. Write it down and look it over. Is that the way you want to talk
        to yourself? Instead of being overrun with negative thoughts, happy
        golfers have habits that allow them to respond more easily from their
        higher brain center, the neocortex. From all of our experiences, we have
        found the most successful and the happiest golfers don’t believe
        everything they think.
      </div>
      <div class="p-mb-2">
        These happy golfers are more skeptical of their negative thoughts. They
        question the alarms and override them when necessary. They don’t fight
        with their negative thoughts. They know that these are often just
        by-products of their negativity bias and that they can go beyond the
        mind and let the thoughts go. Successful golfers register their positive
        thoughts more deeply and savor their positive experiences more
        intensely.
      </div>
      <div class="p-mb-2">
        You can create a mantra that drowns out self-talk. You can create a
        distractor to the distractor. Once the self-talk starts in a bad
        direction, it creates a loop that is difficult to escape. You literally
        talk yourself into poor play. Don’t let that happen. Recognize it and
        replace it. You control the noise. You don’t have to listen when that
        voice has a prophecy of doom and tells you that you can’t do something.
      </div>
      <div class="p-mb-2">
        I constantly remind myself of a playing focus that is positively stated
        and specific, Debra says about the way she silences those voices. One
        mantra that she repeats to herself is: What you want can be under your
        control. She also uses the title of our first book to control her
        self-talk. The fact that ‘every shot must have a purpose’ keeps you
        present.
      </div>
      <div class="p-mb-2">
        This is all about taking control and deciding the soundtrack for your
        life. You get to be both the composer and the singer. Brainwashing is
        nothing more than repeated self-talk. That’s perhaps the most negative
        way in which self-talk exists. Your entire perception of reality can be
        changed if you are repeatedly told the world is different from the way
        you are seeing it.This is how people can be manipulated by fear into
        acting against their own best interests. That is the way cults work. But
        you have the opportunity to create whatever cult you want.You can create
        the cult of great golf.
      </div>
      <div class="p-mb-2">
        When we are young, others determine our self-talk culture. Jack Nicklaus
        grew up in an Attaboy! culture. He was constantly told: You can do it.
        So was Tiger Woods. Not everyone is as fortunate. But as we become
        adults, we have the power to alter the self-talk culture surrounding us.
        If you lacked positive reinforcement as a child and are shadowed by
        constant doubt, you can change that. Self-talk is the power train of
        your thoughts. You have to get hold of it, or it is running you.
      </div>
      <div class="p-mb-2">
        Talk to yourself about things that are under your control. Write down
        productive self-talk sentences. We all have a tendency to surrender to
        what Dan Baker called a VERB culture in his book What Happy People Know.
        What do we mean by this? It is the phenomenon that when something bad
        happens, we run from the responsibility for our actions. Here’s how we
        distance our self:
      </div>
      <div class="p-mb-2">
        Victim: I get all the bad breaks. Entitlement: I deserve better out of
        life. Rescue: I want to hit the lottery rather than work to get rich.
        Blame: I am not responsible for the situation I am in.
      </div>
      <div class="p-mb-2">
        Victim, Entitlement, Rescue, Blame—VERB. Debra had all of these
        tendencies rattling around in her mind, and for good reasons—reasons
        that extended far beyond golf. Her life was going through a major
        restructuring, and VISION54 helped provide her with the tools she needed
        to get through it.
      </div>
      <div class="p-mb-2">
        The fears I had about golf shots that used to overcome me were nothing
        compared to the fears of losing my life, Debra says. Although I tried to
        play competitively while going through radiation and chemotherapy, I
        realized that people let golf get in the way of their spirit, mind, body
        and soul. Their performance state is compromised by the shots they might
        not take, not the shot that is presented to them at the time. They live
        golf, not life.
      </div>
      <div class="p-mb-2">
        That is an important realization. Life is lived right now. And that’s
        how golf must be played. Right now. Debra learned to focus on what is
        going on this instant, and not on what might be or could be or should
        be. Debra left our school with a full toolbox of skills. Dreams are
        great motivators for the future, and regrets about the past can be
        learning devices, but to achieve peak performance, you have to be right
        here, right now.
      </div>
      <div class="p-mb-2">
        Their teachings are about life, and golf is just the beneficiary, says
        Debra about VISION54. Pia and Lynn are life where there is fear. They
        are hope when there is none. They breathe, live and exude pure
        potential. I left them with a heart filled with promise, love, joy,
        imagination and belief in the possibilities of life and golf.
      </div>
      <div class="p-mb-2">
        You can actively create a non-VERB culture by learning to manage your
        self-talk. You can replace a VERB culture with a GOLF culture.
      </div>
      <div class="p-mb-2">
        G: Gratitude for the Game. Pay attention to what you do that is Good and
        Great.
      </div>
      <div class="p-mb-2">
        O: Opportunity. Every shot is an Opportunity. The next shot might be
        your greatest.
      </div>
      <div class="p-mb-2">L: Love the game and let it make you Laugh.</div>
      <div class="p-mb-2">F: Fun and Focus will make you perform better.</div>
      <div class="p-mb-2">
        Research conclusively shows that peak performance is more likely to
        occur when there is no self-talk with you in the Play Box. This state
        very rarely happens naturally, but you need to learn how to reach that
        state in the Play Box to play your best golf. We are asking for
        something very simple from you: Be fully engaged with your mind and body
        for just a precious few seconds, with no self-talk and no to-do list.
        Those athletes who do reach such a level describe eerily similar states
        that are virtually out-of-body experiences: a basketball shooter who
        can’t miss; a baseball hitter to whom the ball appears to be the size of
        a pumpkin; a golfer who doesn’t even remember the sensation of swinging
        the club and for whom the cup appears to be the size of a trash can.
      </div>
      <div class="p-mb-2">
        The time between your Play Box experiences is inevitably filled with
        self-talk; sometimes you have a debate involving an entire committee
        raging in your head. We will have that noise in our head as long as we
        are on this side of the grass. And in golf, self-talk is even more of a
        challenge than in other sports because you have so much time between
        shots—the vast majority of the time you are on the golf course—in which
        that voice has an opportunity to speak to you. Learn how to make friends
        with that voice.
      </div>
      <div class="p-mb-2">
        Believing in possibilities has transformed my life and game, says Debra.
        Before, my mind would wander. Now I decide, commit and play. The
        teachings of Pia and Lynn are such that they recognize the power of mind
        over body. One of the greatest lessons I have learned is that if you
        take the time to find out what thoughts lead your game astray, you can
        pull out your VISION54 toolbox and create DHEA. I even write DHEA in
        purple on my glove to remind me of the positive.
      </div>
      <div class="p-mb-2">
        Debra attended our school twice, and after the second session, she had
        her best year ever as a competitor, winning her club championship and
        several statewide and regional tournaments. Her handicap fell to 5.5.
        The competition was stunned, Deba said, and when they asked me my
        secret, I told them VISION54.
      </div>
      <div class="p-mb-2">
        There are many ways to deal with self-talk. For one, when you hear that
        voice, learn how to get back to being present to the task at hand, which
        is hitting the golf ball. Look at the golf ball and talk to it about the
        journey it is about to embark upon. Listen to the sounds of nature all
        around you and make those the sounds in your mind rather than that
        voice. Feel your breathing; listen to your heart; imagine the ball
        rolling into the cup.When that voice tries to take your mind off the
        shot you are about to hit, sing to it; hum a tune or talk to others. We
        know a player who will toss a ball in the air and catch it between shots
        so his mind focuses on catching the ball rather than on his self-talk.
      </div>
      <div class="p-mb-2">
        Why do you think a monk chants during meditation? The repetitive sound
        quiets the mind. Tension and fear are held at bay by the sweet sound of
        nothing. A quiet mind is capable of greatness, capable of achieving peak
        performance.
      </div>
      <div class="p-mb-2">
        The VISON54 tools are with you everywhere and all you need is to go with
        the journey, says Debra. It is the journey, not the destination. Every
        time I struggle with the tendencies that lead my game astray and tighten
        up, I think of my time with Pia and Lynn and I believe in the
        possibilities.
      </div>
      <div class="p-mb-2">
        While changing your outlook will change the nature of your self-talk,
        developing a playing focus that is under your control will distract you
        from that voice inside you. Question that voice; challenge it. Hear your
        best friend’s voice inside you, encouraging you. Create your own
        Attaboy! culture and take it with you wherever you go. You never have to
        lose an argument with that voice inside you again.
      </div>
    </div>
  </Panel>
</template>
