<template>
  <Panel header="Variability and Golf" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        It is a truism of golf that the only thing that is constant is
        variability. Not only is the environment always changing, but human
        movements are always slightly variable. Therefore, situations requiring
        state control during will occur in every round, and changing feels in
        the shot routine are to be expected.
      </div>
      <div class="p-mb-2">External environment always variable</div>
      <div class="p-mb-2">
        Different lies, bounces, pins, greens. Different weather, partners,
        tension levels
      </div>
      <div class="p-mb-2">Human movement always slightly variable</div>
      <div class="p-mb-2">
        Movements are designed to be similar but not exactly reproducible.
        Survival value so that we can respond ‘well enough’ to and be successful
        with all the viscitudes of external variability. Motor system designed
        for flexibility not to do exactly the same thing over and over.
        (Designed to be improvisational and slightly inexact). Accept that swing
        will be slightly different and feel slightly different from day to day.
        Best to practice in a way that replicates golf reality, that is,
        continually from different lies to different targets in different
        conditions with different shot shapes.
      </div>
      <div class="p-mb-2">
        During play, can manipulate mental skills, can’t change technical skills
        (develop a low draw), or physical skills ( flexibility, coordination,
        fitness). Thus, mental skill manipulation is all we have to respond to
        internal and external variability.
      </div>
      <div class="p-mb-2">
        Best way to become ‘consistent’, is to embrace variability and work on
        mental skills as the only variable one can control during play. Further,
        can work on technique under variable conditions to improve it for real.
      </div>
    </div>
  </Panel>
</template>
