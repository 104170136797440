<template>
  <div class="container">
    <!-- HEADER -->
    <div class="header">Choose a note to review, edit, post</div>
    <!-- RADIO BUTTONS -->
    <div class="radioTrustIt">
      <!-- First Column Trust It radio buttons -->
      <form @submit.prevent>
        <div><b>INTER SHOT ROUTINE </b></div>
        <div>
          <input
            type="radio"
            v-model="chosenNote"
            value="PlayerInterShotRoutine"
            name="notes"
          />
          <label>Peak Performance State (vision)</label>
        </div>
        <div>
          <input
            type="radio"
            v-model="chosenNote"
            value="FullSwingTrustIt"
            name="notes"
          />
          <label>Emotions: Build and Maintain Resilience</label>
        </div>
        <div>
          <input
            type="radio"
            v-model="chosenNote"
            value="PartialTrustIt"
            name="notes"
          />
          <label>Thoughts: Drown out self-talk</label>
        </div>
        <div>
          <input
            type="radio"
            v-model="chosenNote"
            value="BunkerTrustIt"
            name="notes"
          />
          <label>External: Soc, Environ, Nutr & Hydr</label>
        </div>
        <div><b>SHOT ROUTINE </b></div>
        <div><b>See It </b></div>
        <div>
          <input
            type="radio"
            v-model="chosenNote"
            value="PlayerSeeIt"
            name="notes"
          />
          <label>Control Arousal / Decide and Commit</label>
        </div>
        <div><b>Feel It </b></div>
        <div>
          <input
            type="radio"
            v-model="chosenNote"
            value="BttFeelIt"
            name="notes"
          />
          <label>Balance Tempo Tension Awareness</label>
        </div>
        <div>&nbsp;&nbsp;<b>shots</b></div>
        <div>
          <input
            type="radio"
            v-model="chosenNote"
            value="FullSwingFeelIt"
            name="notes"
          />
          <label>Full Swing</label>
        </div>
        <div>
          <input
            type="radio"
            v-model="chosenNote"
            value="PartialFeelIt"
            name="notes"
          />
          <label>Distance Wedge</label>
        </div>
        <div>
          <input
            type="radio"
            v-model="chosenNote"
            value="PitchChipFeelIt"
            name="notes"
          />
          <label>Pitch/Chip</label>
        </div>
        <div>
          <input
            type="radio"
            v-model="chosenNote"
            value="PuttingFeelIt"
            name="notes"
          />
          <label>Putting</label>
        </div>
        <div>
          <input
            type="radio"
            v-model="chosenNote"
            value="PlayerLetItGo"
            name="notes"
          />
          <label>Bunker/Lob</label>
        </div>
        <div><b>Trust It </b></div>
        <div>
          <input
            type="radio"
            v-model="chosenNote"
            value="PlayerTrustIt"
            name="notes"
          />
          <label>Kinesthetic Awareness</label>
        </div>
        <div><b>Enjoy Analyze Remember It </b></div>
        <div>
          <input
            type="radio"
            v-model="chosenNote"
            value="PlayerEARIt"
            name="notes"
          />
          <label>ear</label>
        </div>
        <div><b>Let It Go </b></div>
        <div>
          <input
            type="radio"
            v-model="chosenNote"
            value="PitchChipTrustIt"
            name="notes"
          />
          <label>lig</label>
        </div>
      </form>
    </div>
    <!-- Second Column Feel It radio buttons -->
    <!--  <div class="radioFeelIt">
      <form @submit.prevent>
        <div>
          <input
            type="radio"
            v-model="chosenNote"
            value="NotPlayer"
            name="notes"
          />
          <label>NotPlayer</label>
        </div>
        <div>
          <input
            type="radio"
            v-model="chosenNote"
            value="BunkerTrustIt"
            name="notes"
          />
          <label>Bunker TrustIt</label>
        </div>
        <div>
          <input
            type="radio"
            v-model="chosenNote"
            value="PuttingTrustIt"
            name="notes"
          />
          <label>PuttingTrustIt</label>
        </div>

        <div>
          <input
            type="radio"
            v-model="chosenNote"
            value="BunkerFeelIt"
            name="notes"
          />
          <label>Bunker Feel It</label>
        </div>

        <div>
          <input
            type="radio"
            v-model="chosenNote"
            value="AbnStanceFeelIt"
            name="notes"
          />
          <label>Abn Stance Feel It</label>
        </div>
        <div>
          <input
            type="radio"
            v-model="chosenNote"
            value="AbnLieFeelIt"
            name="notes"
          />
          <label>Abn Lie Feel It</label>
        </div>
        <div>
          <input
            type="radio"
            v-model="chosenNote"
            value="InterShotRoutine"
            name="notes"
          />
          <label>InterShotRoutine</label>
        </div>
        <div>
          <input
            type="radio"
            v-model="chosenNote"
            value="ShotRoutine"
            name="notes"
          />
          <label>ShotRoutine</label>
        </div>
      </form>
    </div> -->
    <!-- EDITOR -->
    <div class="editor">
      <Editor v-model="editorData" />
    </div>
    <!-- BUTTONS -->
    <div class="getButtons">
      <Toast />
      <Button label="getLatestNote" @click="getLatestNote" />
      <Button label="Move To Editor" @click="moveToEditor" />
    </div>
    <div class="postButtons">
      <Button label="Post Chosen Note" @click="postNote" />
    </div>

    <div class="clearButtons">
      <Button label="Clear Latest Note" @click="clearLatestNote" />
      <Button label="Clear Editor" @click="clearEditor" />
    </div>
    <div></div>
    <!-- v-html for retrieved note -->
    <div
      class="retrievedNote"
      v-html="retrievedNoteContent"
      style="border: solid 1px; margin: 1.5em auto 1.5em auto; text-align: left"
    ></div>
  </div>
</template>

<script>
import { ref } from "vue";
import store from "../store";
import { useToast } from "primevue/usetoast";
export default {
  name: "Ed",
  setup() {
    let editorData = ref("");
    const chosenNote = ref("");
    const retrievedNoteContent = ref("");
    const note = {
      text_code: "",
      text_content: "",
    };
    const toast = useToast();
    async function getLatestNote() {
      try {
        let text_code = chosenNote.value;
        console.log(`text_code: ${text_code}`);
        const response = await store.dispatch("note/getLatestNote", text_code);
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: `Latest "${text_code}" note retrieved`,
          life: 3000,
        });
        retrievedNoteContent.value = response.text_content;
        chosenNote.value = response.text_content;
        // console.log(`response.text_content: ${response.text_content}`);
      } catch (error) {
        toast.add({
          severity: "error",
          summary: "Error Message",
          detail: `Error: ${error}`,
          life: 3000,
        });
        console.log(`Error: Editor.vue getLatestNote(): ${error}`);
      }
    }
    function moveToEditor() {
      editorData.value = chosenNote.value;
    }
    // Dispatch function to Store
    async function postNote() {
      try {
        note.text_code = chosenNote.value;
        note.text_content = editorData;
        // GET THE _rawValue part of the editorData object
        note.text_content = note.text_content._rawValue;
        await store.dispatch("note/postNote", note);
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: "Note Created",
          life: 3000,
        });
        clearLatestNote();
        clearEditor();
      } catch (error) {
        toast.add({
          severity: "error",
          summary: "Error Message",
          detail: `Error: ${error}`,
          life: 3000,
        });
        console.log(`Error: Editor.vue postNote(): ${error}`);
      }
    }
    function clearLatestNote() {
      retrievedNoteContent.value = "";
      chosenNote.value = "";
    }
    function clearEditor() {
      editorData.value = "";
    }

    return {
      chosenNote,
      retrievedNoteContent,
      editorData,
      note,
      postNote,
      getLatestNote,
      moveToEditor,
      clearLatestNote,
      clearEditor,
      toast,
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 700px;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  row-gap: 1em;
  // border: solid 1px lightgrey;
}
.header {
  grid-column: 1/3;
  font-weight: bold;
  font-size: x-large;
}
.radioTrustIt {
  grid-column: 1/3;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  text-align: left;
  align-items: center;
  font-size: large;
  //background: yellow;
}
.radioFeelIt {
  grid-column: 2/3;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  text-align: left;
  font-size: large;
  //background: red;
}
.editor {
  grid-column: 1/3;
}
.getButtons {
  grid-column: 1/3;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
}
.postButtons {
  grid-column: 1/3;
}
.clearButtons {
  grid-column: 1/3;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
}
.retrievedNote {
  grid-column: 1/3;
}
</style>
