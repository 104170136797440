<template>
  <Panel header="Putting" :toggleable="true" :collapsed="true">
    <Panel header="Block Training" :toggleable="true" :collapsed="true">
      <Panel
        header="Skill1: Starting ball On Line (feedback)"
        :toggleable="true"
        :collapsed="true"
      >
        <skill1Feedback />
      </Panel>
      <Panel
        header="Green-Reading Skill Training"
        :toggleable="true"
        :collapsed="true"
      >
        <img
          class="img4"
          src="@/assets/images/sieckmannPutting/greenReadingArt3.png"
          alt="Sieckmann Putting"
        />
        <div class="accent1 fontSmall p-mb-2">
          Unguided practice drills such as the Star Drill are critical to your
          green-reading development because they best replicate what you’ll
          experience when you play. Here I’m visualizing the late break from the
          opposite side of the hole as in step 2 of my green-reading process.
        </div>
        <img
          class="img4"
          src="@/assets/images/sieckmannPutting/greenReadingArt4.png"
          alt="Sieckmann Putting"
        />
        <div class="accent1 fontSmall p-mb-2">
          Aiming at the apex of the ball’s curve is a popular technique, but
          it’s inherently flawed. As you can tell from this diagram, the correct
          aim line on a breaking putt points well above the apex of the curve.
          Looking at the apex instead of down the correct starting line will
          result in a low miss.
        </div>
        <div class="fontSmall taLeft">
          <div class="p-mb-2">
            The effectiveness of your training is the key to unlocking the great
            green-reader within. To help you develop a training plan, I suggest
            a mixture of both guided and unguided trials. This combination
            confirms that what you’re doing is correct while also giving you the
            freedom to grow from your mistakes.
          </div>
          <div class="p-mb-2">
            Guided Training Jump-start your visual training by investing a bit
            of time with a feedback device that confirms your ability to start
            the ball on line, such as the Pelz Putting Tutor or similar device
            (check out the Slot Trainer at eyelinegolf.com), and visualizing
            your three points from both behind the ball and as you stand at
            address (here). This is a form of developmental ophthalmology, which
            involves teaching your eyes and your brain to communicate
            effectively when assessing objects in space. Recall that Tom
            Pernice, Jr. performs a minimum of ten successful repetitions using
            the Pelz Tutor for left-to-right, right-to-left, and straight putts
            on his training days. Even though he reads the green once from each
            location, he visualizes and walks in on all thirty putts, feeding
            his brain a steady diet of green-reading truth. The certainty of
            that truth helps train his eye and build confidence in what he sees.
          </div>
          <div class="p-mb-2">
            Unguided Training You need repetitions without a guide or feedback
            to train your green-reading process and improve your skill. Don’t
            expect perfection here—this type of training is all about growth. I
            developed an exercise called the Star Drill to make it easy for you.
            Here’s how it works.
          </div>
          <div class="p-mb-2">
            Star Drill Place five balls randomly around a cup anywhere between
            three and ten feet from the hole (five points of a star with the cup
            in the middle). Choose a putt to read, and start your green-reading
            process (as always) from the opposite side of the cup. Use the
            green-reading process outlined here (or your own version) by
            locating the fall line, then look left and right of your approximate
            line and imagine the coins on the green to see the late break.
            Continue your process, choose the line, and stroke the putt. If you
            miss, don’t turn away in disgust or berate yourself. Use the outcome
            to help you correct your read. Although it’s impossible without
            feedback to know precisely what you did incorrectly, you can make
            the corrections in a general sense. If you read the putt as “left
            edge” and it broke across the cup, use a post-shot process to make a
            positive correction. Begin by revisiting your visual and kinesthetic
            cues, then internally state the solution, i.e., “a bit more break.”
            Then take a few seconds to reimagine the putt breaking more. The
            whole process shouldn’t take more than a few seconds, but with each
            repetition, your green-reading skills will grow.
          </div>
          <div class="p-mb-2">
            Move on to the next ball in the star and repeat. The great thing
            about this drill is that there’s always a ball lying opposite the
            hole, so you can get right into the process of reading the next putt
            with just a few quick steps in one direction. The drill is complete
            after you’ve executed your full process for all five putts.
          </div>
          <div class="p-mb-2">
            Training in a random fashion such as this emphasizes the “judging”
            aspect of green-reading—every putt in the Star Drill is different,
            so you’re seeing it for the first time. If you decide to run through
            more than one repetition of the drill, make sure you do it at a
            completely different location on the green so you can up the
            green-reading challenge. The PGA Tour players I coach all make the
            Star Drill a regular part of their training routine. They know that
            each green-reading “miss” is an opportunity to learn and grow.
          </div>
          <div class="p-mb-2">
            Careful! Whenever you read a putt, don’t look for the “apex of the
            break” as some instructors or high-profile players suggest. Putts
            often break early, especially those that follow a significant slope,
            which means you have to start the ball well up the hill from the
            apex to get the ball to roll over it on its way to the hole. If you
            only visualize the top of the curve, you’re not looking at the
            correct aim line and you’ll probably miss low.
          </div>
        </div>
      </Panel>
    </Panel>
    <Panel header="Random Training" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
      </div>
    </Panel>
    <Panel header="Games" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
      </div>
    </Panel>
  </Panel>
</template>

<script>
import skill1Feedback from "@/components/sieckmann1Putting/skill1Feedback";

export default {
  name: "putting",
  components: {
    skill1Feedback,
  },
};
</script>
