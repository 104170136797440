<template>
  <h1>Technical Game</h1>
  <Panel :toggleable="true" header="Image: Technical Game">
    <div class="p-col-12 ta-center">
      <div class="p-grid">
        <div>
          <img
            src="../assets/images/home/technique1.png"
            alt="Technical"
            class="img9"
          />
        </div>
      </div>
    </div>
  </Panel>
  <div class="line-page"></div>
  <Panel header="Full Swing (One Plane)" :toggleable="true" :collapsed="true">
    <HardyPlaneTruth />
    <HardyRelease />
    <FullSwingAdaptations />
  </Panel>

  <Panel
    header="Distance Wedges (30 - 100 yds)"
    :toggleable="true"
    :collapsed="true"
  >
    <SieckmannWedges />
  </Panel>

  <Panel
    header="Short Game: Utley: Art of the Short Game. Chip, Pitch (< 30yds)"
    :toggleable="true"
    :collapsed="true"
  >
    <UtleyShortGame />
  </Panel>

  <Panel
    header="Short Game: Sieckmann. Finess Wedges(< 30yds)"
    :toggleable="true"
    :collapsed="true"
  >
    <Sieckmann1Finesse />
  </Panel>

  <Panel
    header="Putting: Utley. The Art of Putting"
    :toggleable="true"
    :collapsed="true"
  >
    <UtleyPutting />
  </Panel>

  <Panel
    header="Putting: Sieckmann. Your putting solution"
    :toggleable="true"
    :collapsed="true"
  >
    <Sieckmann1Putting />
  </Panel>

  <Panel
    header="Balance, Tempo, Tension Awareness"
    :toggleable="true"
    :collapsed="true"
  >
    <img
      class="img4"
      src="@/assets/images/training/balanceTempoTension1.png"
      alt="Pia"
    />
    <div class="accent1 fontSmall p-mb-2">
      Balance Tempo and Tension Awareness are PreRequisites for Technique to
      Work
    </div>
    <BalanceTempoTension />
  </Panel>
</template>

<script>
import HardyPlaneTruth from "@/components/hardyPlaneTruth/HardyPlaneTruth";
import HardyRelease from "@/components/hardyRelease/hardyRelease";
import FullSwingAdaptations from "@/components/fullSwingAdaptations/fullSwingAdaptations";
import SieckmannWedges from "@/components/sieckmannWedges/distanceWedges";
import Sieckmann1Finesse from "@/components/sieckmann1Finesse/Sieckmann1Finesse";
import Sieckmann1Putting from "@/components/sieckmann1Putting/sieckmann1Putting";
import BalanceTempoTension from "@/components/piaBalanceTempoTension/balanceTempoTension";
import UtleyShortGame from "@/components/UtleyShortGame/UtleyShortGame";
import UtleyPutting from "@/components/UtleyPutting/utleyPutting";

export default {
  name: "Technical",
  components: {
    HardyPlaneTruth,
    HardyRelease,
    FullSwingAdaptations,
    SieckmannWedges,
    Sieckmann1Finesse,
    Sieckmann1Putting,
    BalanceTempoTension,
    UtleyShortGame,
    UtleyPutting,
  },
};
</script>
<style lang="scss">
@import "@/assets/styles/images.scss";
</style>
