<template>
  <Panel
    header="Rethinking Illogical (mainly low confidence)"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Changing negative self-talk is much more difficult, however, for those
        who, usually without realizing it, have habits of reasoning, deducing,
        and thinking of others and themselves in ways that are highly illogical.
      </div>
      <div class="p-mb-2">
        Can use techniques below, but remember you will find it very helpful to
        detach and reduce your involvement with faulty thoughts. I.e., just use
        distraction or reject if simpler and more effective. (an additional
        benefit the rephrase is simply that it stops the negative meanings)
      </div>
      <div class="p-mb-2">
        Magical thinking is not real. Don’t do it, or laugh at it as a personal
        absurdity.
      </div>
      <div class="p-mb-2">
        Life is not fair or just. There is no divine reward for goodness or
        retribution for badness. But do the right thing because it will make
        your emotions positive. You won't have to deal with guilt or shame. Your
        mental game will be stronger so chances of future good shots (‘reward’)
        translating into a low score are improved.
      </div>
      <div class="p-mb-2">
        Remember that in the past good scores have included bad shots/ holes,
        periods of negative emotions, and bad luck. The quicker you can get the
        mental game back on track (all you can control, technique is what it
        is), the quicker hell golf can become good golf - perhaps the golf gods
        will smile on you with a few birdies.
      </div>
      <div class="p-mb-2">
        Not enjoying this, feeling weak, I want it to be over: This is the only
        REAL opportunity to test mental skills, find out what works, build
        mental muscle. If I can succeed in this state, I can create a great
        positive memory which will increase confidence for the future. I will
        then be able to better cope with this situation even remove these
        feelings - which is what I want.
      </div>
      <div class="p-mb-2">
        This hole is dangerous but beautiful. This is an opportunity to execute
        a great shot routine, and really make a positive memory of a great shot
        on a dangerous hole.
      </div>
      <div class="p-mb-2">
        I can still make par, after dumping 3rd shot into bunker.
      </div>
      <div class="p-mb-2">He can make a birdie on any hole in the world</div>
    </div>
  </Panel>
</template>
