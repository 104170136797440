<template>
  <Panel header="Introduction" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        The same thing that makes a short-game practice area so great—acres of
        nice, flat turf with an unlimited supply of balls (that don't count
        against your score)—is also its biggest limitation. Because, as I'm sure
        you've discovered the hard way, you don't always get perfect lies when
        you're out playing on the course.
      </div>
      <div class="p-mb-2">
        Ironically, this is actually more true for the average guy than it is
        for a tour player, at least in terms of things like divots and hardpan.
        When I play in a PGA Tour event, I'm going to see every golf course in
        the best condition it's going to be in all year. The grass is almost
        always perfect, and the only “trouble” shots I'm going to have to deal
        with are usually ones that I get myself into—rough and nasty lies in the
        corners of bunkers. Look at what Phil Mickelson did at the PGA
        Championship in 2005. In the final round, he hit his second shot on the
        par-5 18th hole into deep grass next to the green. He had a tough lie,
        but he was able to hit a nice 50-foot explosion shot from the grass to
        two feet and win the tournament.
      </div>
      <div class="p-mb-2">
        But in real golf, the kind you play every week at your home course,
        you're going to have to deal with a variety of less-than-perfect
        situations. Rough is the most basic, if you miss the green in deep
        grass. A flat lie from the rough is challenging enough, and you'll
        certainly have to deal with uphill, downhill, or sidehill lies from that
        grass. You've also got bare lies, where the grass hasn't grown
        completely in, or hardpan in the summer. Divots are an especially
        frustrating problem, because it usually feels like you didn't do
        anything wrong to deserve the bad break. I've been in tournament
        situations where I walked up to my ball 20 yards short of the green in
        fairway grass and saw it sitting down in a giant divot hole. That will
        challenge your ability to keep cool.
      </div>
      <div class="p-mb-2">
        Bunkers present their own kinds of diabolical opportunities to get into
        trouble. Like I said in the chapter on bunker play, the basic, flat,
        good lie in the bunker is one of the most favorable places you can be
        aside from on the green, because of your ability to control the spin on
        the ball out of sand. But if the ball settles in its own pitch mark in
        the bunker, or worse yet, buries, you've got some extra work to do. You
        also need to know how to deal with a situation when your ball comes to
        rest up near the edge of the bunker and you have to either get the ball
        up in the air fast or stand with one foot in and one foot out of the
        bunker.
      </div>
    </div>
  </Panel>
  <Panel header="Deep Rough" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        When I say deep rough, I'm talking about grass where you're not going to
        be able to make clean contact between the club and the ball. The
        standard cut of rough you'd see just off the green at the average public
        course doesn't qualify, unless the ball is really nestled down in the
        grass. (Remember, if you can make decent contact with the ball, you can
        hit the same basic chip or pitch shots I've been telling you about over
        the last few chapters.)
      </div>
      <div class="p-mb-2">
        From the deeper grass, I play a blast shot that's virtually identical to
        a sand shot we talked about in the last chapter. The concept is the
        same—sending the bounce of the club skidding through the grass (instead
        of sand) so that the ball pops out in a cloud of grass shavings. The
        only difference is a subtle one—when I take it back in the bunker, my
        backswing is slightly steeper. I swing a little shallower from the grass
        because the ground doesn't give as much as the sand does. Just like with
        the bunker shot, my stance gets way, way wider than for a chip. My feet
        are at least a foot and a half apart—maybe even two and a half feet
        apart if I have to get really low to blast one out of a nasty lie. My
        hand position is really low, down at knee level, and I move a lot
        farther from the ball. You need to start with your left wrist cupped,
        and bring it back with a lot of wrist cock, right away. The downswing is
        simple. You're throwing the clubhead as hard as you can, with the goal
        of getting it to pass the grip end through impact. In the bunker, the
        goal is to hit an inch or two behind the ball, but on the deep-grass
        shot, I'm trying to hit three or four inches behind and power the
        clubhead through the grass. Grass provides more resistance than sand, so
        you need some speed on the clubhead, and there's definitely a lot of
        speed on the clubhead relative to how far you're trying to hit the ball.
        You're trying to swing with loft so that the club cuts through the grass
        but still hits a high, soft shot that doesn't require much spin to stop.
        When you get 15 or 20 yards away with this shot, you're going to need
        almost three quarters of full clubhead speed. I might hit a 10- or
        15-yard shot with the volume of swing that I'd make from a good lie 50
        or 60 yards away.
      </div>
      <img
        src="@/assets/images/utleyArtShortGame/adaptations1.png"
        class="img3"
        alt="utley basics"
      />
      <div class="accent1 fontSmall p-mb-2">
        If the ball sits on top of the rough, you can get spin on the shot, but
        you havve to be careful not to swing under the ball.
      </div>
      <img
        src="@/assets/images/utleyArtShortGame/adaptations2.png"
        class="img3"
        alt="utley basics"
      />
      <div class="accent1 fontSmall p-mb-2">
        When the ball nestles down in the rough, you can't catch the back of it
        cleanly with your wedge. As a result, the ball won't spin very much, and
        you'll have to stop it with loft. This material may be protected by
        copyright.
      </div>
      <div class="p-mb-2">
        Mickelson is just tremendous at these shots, because he's got both the
        technique and the courage to hit it under pressure. I've seem him hit a
        20-foot pitch from deep grass with a swing that would hit the ball 75
        yards from a good lie in the fairway. Miss that a little too close to
        the ball and he's looking at hitting it over the gallery and into the
        next fairway.
      </div>
      <div class="p-mb-2">
        Consider yourself lucky if your ball comes to rest on top of the deep
        grass instead of nestled down in it. You'll be able to get a club on the
        ball and use a more conventional chip or pitch shot, but you still have
        to be careful. There's a cushion of grass under the ball, and if you
        swing too deeply into the grass, you can easily swipe the club
        completely under the ball. It compounds your problem, because the ball
        will usually drop back down into the divot you just made. If you're
        going to make a mistake, it's better to catch this shot a little bit
        thin. It will spin a bit more, which controls some of the distance.
      </div>
      <img
        src="@/assets/images/utleyArtShortGame/adaptations3.png"
        class="img3"
        alt="utley basics"
      />
      <img
        src="@/assets/images/utleyArtShortGame/adaptations4.png"
        class="img3"
        alt="utley basics"
      />
      <div class="accent1 fontSmall p-mb-2">
        To hit a lofted shot from deep rough, set up the same as you would for a
        standard bunker shot with a wide stance and the ball in the middle (1).
        Open the face slightly. I keep my weight left as I turn into a virtual
        reverse pivot, and my right elbow slides back along my side (2). My
        shoulders stay level as I release the clubhead aggressively, hitting the
        grass two or three inches behind the ball (3). The ball will pop up just
        like a bunker shot. You need to swing the clubhead with speed to make
        sure the club cuts through the grass.
      </div>
    </div>
  </Panel>
  <Panel header="Playing From a Divot" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">xxx</div>
      <img
        src="@/assets/images/utleyArtShortGame/adaptations5.png"
        class="img3"
        alt="utley basics"
      />
      <div class="accent1 fontSmall p-mb-2">
        If the ball is down in the divot, as it is here, you need to play more
        of a chip shot and hit the ball first.
      </div>
    </div>
  </Panel>
  <Panel
    header="Lofted Shot from Uneven Lie"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        You're obviously not always going to have a perfectly flat lie around
        the green. Subtle uphill, downhill, and sidehill lies aren't going to
        change the way you plan and execute a pitch shot, but you need to make
        some adjustments if you're forced to play a lofted shot from an uphill
        or downhill lie. From a downhill lie, your first choice would always be
        to hit something lower, along the ground. But if you do have to hit
        something in the air, it's crucial to keep your weight forward and your
        shoulders on the same tilt as the slope. What usually happens is you
        tilt your shoulders and spine back trying to get the ball up, which
        leads to fat and thin shots. I like to drop my right foot back, or
        closed, and kick my right knee in slightly, toward the ball, when I
        setup, because this helps me keep my weight left and allows me to swing
        back inside or on plane. Then I make sure to release the clubhead
        aggressively and finish with the clubhead low and moving down the slope.
        Remember, the ball will have a lower trajectory than normal, because the
        downhill slope effectively reduces the loft on your clubface.
      </div>
      <img
        src="@/assets/images/utleyArtShortGame/adaptations6.png"
        class="img3"
        alt="utley basics"
      />

      <img
        src="@/assets/images/utleyArtShortGame/adaptations7.png"
        class="img3"
        alt="utley basics"
      />

      <img
        src="@/assets/images/utleyArtShortGame/adaptations8.png"
        class="img3"
        alt="utley basics"
      />
      <div class="accent1 fontSmall p-mb-2">
        On a downhill lie, my shoulders match the angle of the hill, I drop my
        right foot back from the target line and kick my right knee in toward
        the ball slightly (1) to make sure my weight stays left and to give me
        room to swing around in the backswing. On the backswing, I aggressively
        cock my wrists and keep my weight left (2), then unload the club with
        speed through the ball (3).
      </div>
      <div class="p-mb-2">
        On an uphill lie, the hill is working in your favor to help you get the
        ball in the air, but you have to work harder to keep your weight left.
        It'll actually feel like you're making a reverse pivot on your
        backswing, as you push yourself left against the slope of the hill. The
        mechanics of the shot are similar to a buried lie in the sand. I set the
        clubhead with my hands and wrists in the backswing and then squeeze the
        ball into the hill with the clubface, with a slight pivot and release of
        my wrists. My finish actually stops in the hillside. The slope will
        produce extra loft on your club, so you'll want to practice this shot to
        get a feel for how you need to set the clubface at address to produce
        the desired trajectory.
      </div>
      <img
        src="@/assets/images/utleyArtShortGame/adaptations9.png"
        class="img3"
        alt="utley basics"
      />

      <img
        src="@/assets/images/utleyArtShortGame/adaptations10.png"
        class="img3"
        alt="utley basics"
      />
      <div class="accent1 fontSmall p-mb-2">
        The key here on this uphill flop shot is to keep my weight on my left
        side, even though the hill wants to push me to the right (1). To
        emphasize the weight staying left, I make almost a reverse pivot (2) on
        the backswing. Notice how my hips and shoulders stay level to the angle
        of the slope. I “aggressively cock and uncock my wrists to produce speed
        on the clubhead end (3). And I make a full turn through, while my hands
        stay in front of my body (4).
      </div>
    </div>
  </Panel>
  <Panel header="Chip From Collar" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        When the ball comes to rest just on shorter grass but up against the
        collar of longer grass, it kind of fits into the same bad-luck category
        of finding yourself in a divot in the middle of the fairway. If your
        ball was either on the short grass or the collar grass, you'd have an
        easy shot. But when it's actually up against the collar, it's a little
        trickier. I decide what shot to use based on how much of the ball I have
        access to and the distance to the hole. Using a putter from this lie is
        often the safest option, especially if at least half the ball is above
        the long cut of rough and there isn't too much fringe to go through
        before you reach the green. When making this play, you will want to
        swing your putter up in the backswing and then down on the ball more
        than you would for a normal stroke, in order to make good contact with
        the back of the ball.
      </div>
      <div class="p-mb-2">
        My first choice for these shots has always been to blade my sand wedge
        with a putting stroke, since the bottom of my sand wedge is rounded and
        the clubhead easily slides through the rough. I simply stay focused on
        meeting the equator or center of the ball with the leading edge of my
        wedge. The ball will come out rolling like a putt. If your sand wedge
        has a rounded leading edge, you might want to use your pitching wedge,
        which is straighter. Practice putting with your sand wedge on the green
        first, then try it with a lie against the collar.
      </div>
      <div class="p-mb-2">
        Another popular way to play this shot is to use a hybrid or fairway
        wood. I still use a putting stroke with these clubs, but instead of
        blading the ball, like I do with a sand wedge, I hit down on the ball
        enough to catch it with the face of the club. These clubs are a good
        choice when you are farther from the hole.
      </div>
      <img
        src="@/assets/images/utleyArtShortGame/adaptations11.png"
        class="img3"
        alt="utley basics"
      />
      <div class="accent1 fontSmall p-mb-2">
        When the ball rests against the collar, it's easy to get a sand wedge on
        the back of it cleanly. You can also consider hitting this shot with
        your putter or playing a chip with your hybrid or fairway wood.
      </div>
    </div>
  </Panel>
  <Panel header="Burried Lie in Bunker" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        In the same way my standard bunker technique was probably a radical
        departure for you, my technique for getting out of a buried lie in the
        bunker is just as different. You want to use this method when at least
        two-thirds of the ball is buried beneath the sand. First, set up with a
        medium-width stance (feet about hip-width apart), and open your stance
        slightly. You need to tilt your upper body extremely left, which will
        put most of your weight toward the hole. Your ball position for this
        shot is going to be off of your left heel—which is slightly more forward
        than it would be for a normal bunker shot. The really unique part of the
        setup is that you will open the clubface dramatically and then take your
        grip. That runs counter to what you've probably heard about closing the
        face to hit from a buried lie.
      </div>
      <div class="p-mb-2">
        Instead of angling the shaft away from the hole, like you would in my
        standard bunker setup, you'll have it pushed slightly toward the
        hole—mostly because of the steep left tilt of your body. The real
        objective for this shot is to simply get the ball to pop up from the
        buried lie and land as soft as possible. In order for impact to create
        this result, the swing has to be mostly up and down, instead of back and
        through. I set the clubhead very early in the backswing with my wrists
        and my elbows, keeping my hands close to my body and only turning my
        hips and shoulders slightly. The downswing is simply an unhinging motion
        of my wrists and elbows. A key element of the downswing is to keep your
        body leaning left and staying quiet. You really don't want to shift away
        from the ball on the backswing or toward the target on the downswing.
        Remember, the goal is to get the ball to pop up, so we're trying to
        create loft more than we are distance.
      </div>
      <div class="p-mb-2">
        At impact, you're going to hit down on the ball and really dig the
        clubhead into the sand to pop it out. You're going to almost feel as
        though you're recoiling the club back after impact—it'll only travel
        four or five inches past the ball. Learning this recoiling feeling is
        critical, and it can be a challenge for players who are used to trying
        to help the ball out of a buried lie by scooping and lifting with the
        hands. I know that if you've had a lesson on hitting buried-lie shots,
        you probably learned to play them with a closed clubface. That's an
        effective way to get the ball out, but that shot will have a lot of run
        on it, and it's going to be tougher to control. It really only works
        when you “have a lot of green to work with between you and the flag. I
        think hitting with my technique and an extremely open clubface will give
        you more loft and control. It's also good to go and practice, because as
        soon as you get the technique down, you'll see that you don't have to be
        as aggressive as you think. It doesn't take a big, violent swing to get
        the ball to pop out of that lie. Tom Pernice is an absolute master at
        this shot, and he's the one who showed it to me when I first came out on
        tour.
      </div>
    </div>
  </Panel>
  <Panel
    header="Unbalanced Stance in Bunker"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        If you play at a course that has facings built into the bunkers, then
        you know what happens when the ball is in the bunker but your feet
        aren't. In that situation, I bend over much more from my waist and bend
        my knees more. You aren't in a very stable position that way, so you
        have to go at the shot with more arm action than body rotation. I'm
        mostly trying to make good contact with the sand and simply get the ball
        out. If you have one foot in the bunker and one out, it's a much easier
        situation to have the right foot out of the bunker, since you're trying
        to keep your weight forward anyway. If your left foot is out, you need
        to exaggerate the tilt of your shoulders forward, toward the target, to
        compensate for your weight getting pushed back because of the unbalanced
        stance.
      </div>
      <div class="p-mb-2">
        If the ball is up close to the front lip, it becomes a matter of hitting
        it high enough to clear it. I actually got it out of the Road Hole
        bunker at St. Andrews from about three feet behind the front lip. I
        probably had to hit that shot with 75 or 80 degrees of loft. You don't
        do that by laying the face open and cutting across the ball. The
        simplest way is to widen your stance dramatically, lower your hands and
        play the shaft leaned back, away from the target. The lower you get your
        hands, the more you increase the effective loft of the club—without
        making much change to your aim. I don't open my stance at all to hit it
        that high. I'm aimed straight at my target.
      </div>
    </div>
  </Panel>
  <Panel header="Hardpan" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        A lot of players feel some anxiety about hardpan—or even a really tight
        lie in “the fairway—because they get the feeling that the club is going
        to bounce into the ball. First of all, if you play the boring, basic
        chip shot I teach, you're going to hit the ball before you hit the
        ground, which removes bouncing the club into the center of the ball as a
        worry. Where most players get into trouble is when they lean back in an
        effort to create loft. You've heard from teachers that you need to lower
        your right shoulder to add loft to a shot. That can work with a driver,
        where the ball is up on a tee, because what you're really doing by
        tilting back is changing where the bottom of your swing is. If you move
        the bottom of your driver swing back an inch, you're actually putting
        yourself in a position to hit slightly up on the ball through impact,
        which is a good thing. But if you hit an inch behind a chip shot and hit
        up on the ball, you're going to hit some really, really bad chips. When
        the bottom of the swing goes back on this shot, you're dead. That's when
        you're bouncing the club off the hardpan and kicking the club up into
        the center of the ball. From hardpan, really concentrate on keeping 80
        percent of your weight on your left foot and simply pivoting around your
        left leg. You'll get a little more backspin on this shot than on a
        regular chip, because there's no grass between the ball and the face of
        your wedge.
      </div>
    </div>
  </Panel>
  <Panel header="Parking Lot" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        One of the questions I get a lot about my chipping and pitching method
        is about whether or not using a lot of bounce makes you prone to blading
        shots. Well, if you don't hit the shot with good mechanics, you're going
        to blade some. But if you use the method I teach, you won't have any
        problems with blading shots. How do I know? I can take you out in the
        parking lot and show you how to hit pitch shots from pavement.
      </div>
      <div class="p-mb-2">
        To hit it off pavement (or a cart path), you need to be more precise
        where you contact the ground. Instead of hitting behind it, you're
        trying to make contact with the ball and the ground at the same time.
        You do that by keeping your weight to the left and using a lot of wrist
        action to release the club. If you just try to swing the arms back and
        through with locked wrists, you're going to bang the bounce hard off the
        pavement too far behind the ball, and you'll blade it.
      </div>
      <div class="p-mb-2">
        I actually hit about twenty shots from the parking lot at Grayhawk to
        get “the photo you see here. I'll give you this as an ex-treme shot, but
        I hit several that turned out good. It is hard on your wedge trying this
        one, so my main point is getting you to understand how to hit it, not
        how to master it.
      </div>
      <img
        src="@/assets/images/utleyArtShortGame/adaptations12.png"
        class="img3"
        alt="utley basics"
      />
      <div class="accent1 fontSmall p-mb-2">
        “As long as you keep your weight left and release the club, you can use
        a club with bounce, even off cement.
      </div>
    </div>
  </Panel>
</template>
