<template>
  <Panel
    header="Peak Performance Training: Thought-checking (my54, not54)"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">THOUGHT CHECKING (my54 not54) EXERCISES</div>
      <div class="p-mb-2">
        Write down your great qualities as a golfer. Ask somebody who knows your
        game to help with this.
      </div>
      <div class="p-mb-2">
        For three minutes, imagine what it is like to play your best golf. See
        it, feel it and hear it, the more detail the better. Write down what you
        experienced.
      </div>
      <div class="p-mb-2">
        Write down the things that you know you do, think, see, feel and hear
        when you are confident and play well. This is your MY54!
      </div>
      <div class="p-mb-2">
        List what would make sense for you to do in your warm-up to access your
        MY54 state.
      </div>
      <div class="p-mb-2">
        Write down what you need to believe in order to be a confident golfer.
      </div>
      <div class="p-mb-2">
        Write down the things that you know you do, think, see, feel and hear
        when you are not confident and don’t play well. This is your NOT54.
      </div>
      <div class="p-mb-2">
        Ask someone who knows your game well to list things you do when you play
        great and what you do when you get in your own way.
      </div>
      <div class="p-mb-2">
        Write down three beliefs that hinder you from being confident as a
        golfer. Reframe what you wrote so it can be turned into a positive
        statement about you as a golfer.
      </div>
      <div class="p-mb-2">
        For an entire practice session, have the body posture and presence of a
        world champion. How would you stand, talk, walk, prepare, focus and
        commit?
      </div>
      <div class="p-mb-2">
        Write down three tools to use to shift your state from NOT54 to MY54.
        Use these tools the next time you play. If they don’t work, pick other
        ones and try them. Keep doing it until you have found the tools that
        work for you.
      </div>
      <div class="p-mb-2">
        Alternate between hitting shots that you are comfortable with and shots
        that you are uncomfortable with. These can be with different lies,
        clubs, trajectories or situations. Pay attention and make notes about
        your Play Box, Decision and Commitment, Balance, Tempo and Tension
        Awareness for each shot. Any differences? What can you improve on when
        you hit the uncomfortable shots?
      </div>
    </div>
  </Panel>
</template>
