<template>
  <h1>Training Skills</h1>
  <Panel :toggleable="true" header="Image: All Training Skills">
    <div class="p-col-12">
      <div>
        <img
          src="../assets/images/training/trainingAll.png"
          alt="Training"
          class="img7"
        />
      </div>
    </div>
  </Panel>
  <Panel header="Mental" :toggleable="true" :collapsed="true">
    <Panel
      header="Shot Routine (Nielsson)"
      :toggleable="true"
      :collapsed="true"
    >
      <SeeIt />
      <BTT />
      <TrustIt />

      <Remember />
    </Panel>
    <Panel
      header="Inter-Shot Routine (Nielsson)"
      :toggleable="true"
      :collapsed="true"
    >
      <ThoughtChecking />
      <SelfTalk />
      <Enjoy />
    </Panel>
    <Panel header="Brain BioFeedback" :toggleable="true" :collapsed="true">
    </Panel>
  </Panel>
  <Panel header="Technique" :toggleable="true" :collapsed="true">
    <TrainingSkills />
    <FullSwing />
    <Panel
      header="Partial Swings (distance, finesse)"
      :toggleable="true"
      :collapsed="true"
    >
      <DistanceWedges />
      <FinesseWedges />
    </Panel>

    <Putting />
  </Panel>
  <Panel header="Physical" :toggleable="true" :collapsed="true"> </Panel>
</template>

<script>
import TrainingSkills from "@/components/trainingSkills/trainingSkills";
import FullSwing from "@/components/trainingSkills/fullSwing";
import DistanceWedges from "@/components/trainingSkills/distanceWedges";
import FinesseWedges from "@/components/trainingSkills/finesseWedges";
import Putting from "@/components/trainingSkills/putting";
import BTT from "@/components/trainingSkills/balanceTempoTension";
import SeeIt from "@/components/trainingSkills/seeIt";
import TrustIt from "@/components/trainingSkills/trustIt";
import Enjoy from "@/components/trainingSkills/enjoy";
import Remember from "@/components/trainingSkills/remember";
import SelfTalk from "@/components/trainingSkills/selfTalk";
import ThoughtChecking from "@/components/trainingSkills/thoughtChecking";

export default {
  name: "Training",
  components: {
    TrainingSkills,
    FullSwing,
    DistanceWedges,
    FinesseWedges,
    Putting,
    BTT,
    SeeIt,
    TrustIt,
    Enjoy,
    Remember,
    SelfTalk,
    ThoughtChecking,
  },
};
</script>

<style lang="scss">
@import "../assets/styles/images.scss";
</style>
