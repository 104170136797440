<template>
  <Panel header="The Champion Golfer" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Tough-mindedness,as we refer to it here,involves several things,
        including: 1. The skill or ability to accept and prepare for challenges
        over which you have little or no control, such as gamesmanship, poor
        conditions, or slow play. The skill or ability to detach emotionally
        from outside variables, such as the bad play of a close friend and
        playing partner or a desire to please others with the quality of your
        play or even unrelated problems tied to business, family, or friends.
        Tough-M indedness o A 3. The skill or ability to remain somewhat selfish
        in your approach to competition, placing priority on your own needs and
        concerns while competing.
      </div>
      <div class="p-mb-2">
        Champion golfers have a remarkable ability to enter competition using
        above average tough mindedness and detaching themselves emotionally from
        the many adversities that can diminish their focus. They retain this
        mental approach in spite of the distractions they might bring to the
        course and the distractions they encounter on the course'
      </div>
      <div class="p-mb-2">
        Off the golf course they have the equally remarkable ability to be more
        tender and caring with family and friends' giving themselves the
        stability needed to endure the "ups and downs" of life itself.
      </div>
      <div class="p-mb-2">
        One of the distinguishing aspects of champions is their understanding of
        how much being tough'minded can influ- ence their play. They also
        understand what they must do to stay tough-minded. As with all aspects
        of the mental Same' the first itup to becoming tough-minded is to become
        aware of tendencies.
      </div>
      <div class="p-mb-2">
        When we test for the tough minded vs.tenderminded tendencies of our
        clients, we measure with a one-to ten scale using one for tough-minded
        and ten for tender'minded' Champions are in the three-to-four range,
        having above-average tendencies toward being tough-minded when they
        compete. Those who test to be very tender-minded almost without
        exception, find their games improve as they take a more tough-minded
        approach toward competition. Those who test to be very tough-minded
        often find that the quality of their relationships and personal lives
        improve as they take a more tender-minded approach to relating with
        ftiends and loved ones. To their surprise, this often enhances their
        golf as well.
      </div>
      <div class="p-mb-2">The Tough-Minded Golfer</div>
      <div class="p-mb-2">
        If you are a tough-minded individual, you will likely find yourself more
        naturally a tough-minded golfer. If so, you will more readily say ,'no'
        to someone with whom you do not want to play or to someone who wants to
        interrupt your practice or schedule with their own needs or agenda. you
        will also find it much easier to temporarily detach yourself from your
        personal and family problems while playing golf, thereby allowing you to
        conserve your mental energy for the game. In addition, you will find it
        much easier to detach yourself from gamesmanship or the painfully poor
        play of a likable playing partner. Such characteristics can enhance your
        competitiveness in that they make it less likely you will play with a
        busy or troubled mind.
      </div>
      <div class="p-mb-2">
        As we mentioned, these same characteristics can have a detrimental
        affect on your personal life, making it difficult to establish or
        maintain healthy relationships with significant others. This can lead to
        less stable marriages, strained relationships with children, or
        difficulties keeping true friends. While you may not see or feel daily
        effects on your performance, over the long haul, you will likely
        experience periodic ups and downs in your play when personal problems
        occur. Rather than use golf as an escape from these common problems, you
        will help yourself and those you care about (and your golf) by making
        solving them the top priority.
      </div>
      <div class="p-mb-2">
        While the tender-minded characteristics are not wellsuited to playing
        golf successfully, they can help you attain the peace of mind necessary
        to play your best. It appears that champion golfers with the greatest
        longevity and fewest "lows" in their careers are also the players who
        have dedicated time toward maintaining quality relationships with their
        wives and families.
      </div>
    </div>
  </Panel>
  <Panel
    header="Techniques to create attitude for Competition - Graham"
    :toggleable="true"
    :collapsed="true"
  >
    <img
      class="img40"
      src="@/assets/images/mental/toughMinded3.png"
      alt="Tough Minded"
    />
    <div class="accent1 fontSmall p-mb-2">Imaginary Bubble</div>
    <Panel
      header="Pre-competition - Guided Imagery"
      :toggleable="true"
      :collapsed="true"
    >
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          The attitude you take into competition has a great deal to do with
          your ability to maintain a dominant and competi_ tive approach to your
          play. Before your round, take a few moments to check your attitude. If
          you find yourself feeling fear, dread, Iow confidence, or other
          inhibitors, use relaxation and imagery to create the attitude you want
          for competition.
        </div>
        <div class="p-mb-2">
          Identifying thoughts and feelings during your best play can be
          invaluabre to you for creating your desired attitude for competition.
          Follow these steps:
        </div>
        <div class="p-mb-2">
          1.. Draw or trace a circle or oval in the middle of a large, blank
          page so that the circle or oval takes up one-half to two-thirds of the
          available space.
        </div>
        <div class="p-mb-2">
          2. Now take a few moments to fully relax, closing your eyes if
          necessary, and reflect on your last best competi- tive tournament,
          round, or several holes of golf. Try to imagine as vividly as possible
          what you were thinking and feeling, how you were walking and talking,
          and the circumstances surrounding your good play.
        </div>
        <div class="p-mb-2">
          3. Once you have your image, write inside your circle the positive and
          specific words or phrases that describe your thoughts and feelings
          before and during your play that day. Write as many items as possible,
          whether trite, redumdant, or strange. If you have trouble remembering
          or describing how you felt, read through this sample list of items we
          most frequently hear from golfers of all levels and use those to which
          you can relate.
        </div>
        <div class="p-mb-2">
          4. After completing your list, take a few moments to practice creating
          your attitude for competition, iust as you would do prior to the first
          tee shot of your next round. Get in as relaxed a state as possible.
          Lie or sit in a comfortable position with your neck relaxed, arms and
          legs uncrossed. Using progressive relaxation-deep breathing or another
          favored form of relaxing-release all tension from your body.
        </div>
        <div class="p-mb-2">
          5. Once fully relaxed, vividly imagine yourself feeling all the things
          listed inside your circle. For example, with the word "confident," try
          to see yourself walking and talking with confidence; hear yourself
          giving yourself compliments; feel the pride and comfort that comes
          with believing in yourself and trusting your skills.
        </div>
        <div class="p-mb-2">
          6. Now imagine yourself driving to the course, warming-up, practicing,
          and playing the first hole with these same feelings and thoughts.
        </div>
      </div>
    </Panel>
    <Panel
      header="Pre-and-During-competition: Imaginary Bubble"
      :toggleable="true"
      :collapsed="true"
    >
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          A second GolfPsych technique we use is called "the imagi- nary
          bubble." It is designed to stop intrusive thoughts or excessive
          worries that make it difficult to keep your competitive attitude.
        </div>
        <div class="p-mb-2">
          Imagery can be a very powerful tool not only for hetping you create a
          competitive attitude but also for keeping it, regardless of the
          challenges. Using an imaginary bubble not only will help increase your
          awareness of current distractions and faulty thinking, but it also
          will help you manage them rather than letting them manage you.
        </div>
        <div class="p-mb-2">
          Let's start by identifying all the thoughts, feelings, and
          circumstances that are likely to interfere with your desired attitude.
          Think about your last most discouraging round and try to identify the
          thoughts and feelings that you think interfered with your ability to
          play your best. Go back to your notes for creating a positive attitude
          and retrieve your circle. Using the space on the outside of your
          circle, write down the thoughts problems, or circumstances that
          interfered with your play.
        </div>
        <div class="p-mb-2">
          As you write, consider things outside your control, such as rain
          delays, slow play, pairings, tee times, and so forth. Also include bad
          habits of thought, such as self-criticism, self- doubt, fearing the
          worst, thinking about the opinions of others, analyzing mechanics, and
          the like. These distractions can also come in the forms of feeling
          excessive responsibility for helping someone else enioy their round;
          wanting to impress someone with your game or your personality; feeling
          inadequate and self-conscious in the presence of another player or
          players; or taking responsibility for the slow play or bad manners of
          another.
        </div>
        <div class="p-mb-2">
          Compare your notes to these more specific examples, shown below, of
          common distractions to thinking like a champion. Those you can relate
          to should go outside your bubble.
        </div>
        <div class="p-mb-2">
          Now take a few moments to practice using your imaginary bubble. Follow
          the earlier'steps for creating your attitude with regards to relaxing
          your body and imagining the positive attitude that is reflected inside
          your circle. Once you have created your attitude, imagine pulling
          around you a safe, clear, protective bubble. Your bubble is to help
          you contain your champion attitude and repel the items outside your
          circle that can pull you into being average. Imagine yourself going to
          the course, practicing, and playing a hole or two with your bubble in
          place.
        </div>
        <div class="p-mb-2">
          Test your ability by reviewing your list of possible distractions then
          mentally pushing the distractions away from your bubble. They will try
          to get in. You must imagine keeping them out. Reflecting on a recent
          frustrating hole or round can illustrate this. First imagine playing
          he hole as you did, being mindful of everything that could pull you
          out of your bubble. Now play the hole as you would prefer, this time
          using your bubble to help deflect the faulty thinking. This imagery
          practice will prepare you to use your bubble in your nextround. It
          will provide you a strong positive, confident, and peaceful space in
          which you will execute your shots and putts. Before beginning your
          competitive round, challenge yourself to hit as many shots as possible
          from inside your circle.
        </div>
        <div class="p-mb-2">
          We have a few old Star Trek fans that find it more fun to use an
          imaginary "force field" instead of a bubble. Others relate to simply
          putting themselves in their "golf circle". The objective is the same:
          To hit as many shots as possible while thinking like a champion. That
          means refusing to give in to the factors that so readily interfere.
          Most find it helpful to rewrite thier notes on a smaller paper or
          index card to put in their pocket, bag, or yardage book for easy
          reference when they play.
        </div>
        <div class="p-mb-2"></div>
      </div>
    </Panel>
    <Panel
      header="Using Adversity as a 'CUE'"
      :toggleable="true"
      :collapsed="true"
    >
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          Mike Hulbert, John Schroeder, and Dan Forsman are fine examples of
          other players who have worked successfully toward being more
          tough-minded in competition, each addressing different tender-minded
          tendencies in the process. One of the techniques they have
          incorporated is learning to use adversity as a cue to relax and
          improve their focus on the shot at hand. This simple technique is very
          effective for turning frustrations into tools.
        </div>
        <div class="p-mb-2">
          Here are some examples of adverse experiences that can be overcome: *
          Fearing bad shots and putts. *Remaining self-conscious after hitting
          bad shots or putts. * Feeling hurt, sensitive, or angry after bearing
          the brunt of a negative comment or action of another. * Being
          hyperaware of your swing and game when shooting an especially low or
          high score.
        </div>
        <div class="p-mb-2">
          In each of these situations, you can furn the bad experi_ ence around
          to strengthen rather than weaken your mentar game. Follow these simple
          steps:
        </div>
        <div class="p-mb-2">
          1. Identify "distracting experiences": List all the experiences that
          are most likely to detract from your tough-minded play, such as mental
          mistakes, physical mistakes, low scores, large galleries,
          gamesmanship, noise, perceived or real judgment of others,
          controversial comments.
        </div>
        <div class="p-mb-2">
          2. Choose an "anchor": These arc key words or phrases that will remind
          you to return to the clear focus you experience when hitting your best
          shots. Examples include smooth tempo, definite target, clear
          visualization of a shot or putt.
        </div>
        <div class="p-mb-2">
          3. Convert "detractors" to "cues": put yourself in a very relaxed
          state and imagine yourserf in a past situation where a detracting
          experience interfered with your tough-mindedness and focus. Now use
          your imagery to practice using this detractor as a cue to relax and
          use your anchor. Mentally replay your shot with the strong image of
          using your anchor. Each time you have one of your detracting
          experiences in competition, use it to cue your anchor.
        </div>
      </div>
    </Panel>
  </Panel>
  <Panel
    header=" Tough Minded Competitor: 'I'-of-storm"
    :toggleable="true"
    :collapsed="true"
  >
    <img
      class="img3"
      src="@/assets/images/mental/toughMinded1.png"
      alt="Tough Minded"
    />
    <div class="accent1 fontSmall p-mb-2">'I'-of-the-Storm</div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        To wield the tough-minded skills, place your awareness into the
        I-of-the-storm. The I-of-the-storm is a calm, controlled, unhurried
        space where your awareness exists comfortably. The ‘storm’ is all the
        stuff going on around the calm space that ‘I’ doesn't want to have
        anything to do with. The storm includes destructive stuff that can be
        internal like thoughts or emotions, or external like outcomes, player
        antics, weather, or course conditions.
      </div>
      <div class="p-mb-2">
        The I-of-the-storm is surrounded by a tough dynamic membrane which
        performs three functions. Firstly, it forms a barrier to keep out the
        damaging stuff flying around in the storm. Secondly, it identifies
        unwanted thoughts. These include abstract thinking’s not-allowed
        thoughts (previous or past holes, outcome, negative self talk, mechanics
        other’s opinions), low confidence’s faulty thoughts (all-or-nothing,
        magnification, defensiveness, mind-reading, magical thoughts), and
        arousal’s emotion-generated thoughts (rushed, can’t think clearly,
        not-in-control, not want to be here, dread, anxiety, excitement).
        Thirdly, if an unwanted thought or emotion crosses the membrane into
        awareness, the membrane will initiate counter measures to deal with the
        invader. These include cognitive (distraction, rethinking or removal) or
        somatic skills (diaphragmatic breathing, body language manipulation or
        muscular relaxation).
      </div>
    </div>
  </Panel>
</template>

<script>
export default {
  name: "ToughMinded",
  components: {},
};
</script>
