<template>
  <div class="fontSmall taLeft">
    <div class="p-mb-2">
      We will start in this chapter with Balance. No matter what swing you have,
      if you make that swing in better balance, you will get better—and more
      consistent—results. You cannot make a golf swing that your body cannot
      execute. That’s just a simple fact. The key is to find the swing that
      works for you—your one, true golf swing—and then develop the tools that
      enable you to repeat that swing. Being in balance is your running start to
      greatness.
    </div>
    <div class="p-mb-2">
      Simply put, the physical foundation of your golf swing is balance. Think
      of your swing like a toy top set in motion by the pulling of a string. The
      top is most graceful in its movements when it is totally centered, in
      complete balance. As it slows down, that balance is lost and it starts to
      wobble. Eventually it topples over and the string must be pulled again.
      That process is like making the golf swing over and over.
    </div>
    <div class="p-mb-2">
      The goal is to find that balance. And a key understanding is that you can
      always return to that balance even when you have lost it.You can always
      pull the string and set the top back in motion. Remember, focus on what
      you can control and ignore what you can’t control. This is critical mental
      triage. You can’t control your score, but you can control many of the
      things that contribute to your score, and establishing and maintaining
      balance is definitely within your control.
    </div>
    <div class="p-mb-2">
      Physical balance is crucial,” says Topper. “Poised, pinpoint balance
      naturally and automatically contributes to even, steady tempo, allowing
      your core body rotation to carry the hands smoothly on their path. Precise
      balance allows you to move with an unhurried and focused feeling. Balance
      is a meta-fundamental that can be exploited to great advantage.
    </div>
    <div class="p-mb-2">
      Think of that feeling when you hit a perfect shot. It is almost as if time
      stands still. Everything flows together as one for you. Whether the
      perfect shot is produced by the long, fluid swing of Sam Snead or by the
      eye-blink quick one of Ben Hogan, balance is maintained throughout. For
      many of the players we work with, one of the most important aspects of
      their warm-up routine is nurturing balance. Smart players realize they are
      not going to change anything on the warm-up tee, but they can find
      something: balance.
    </div>
    <div class="p-mb-2">
      Balance is like fresh produce: It can go bad very easily. It also changes
      from day to day. You should practice in order to access and maintain a
      good baseline level of balance. We are dynamic, changing human beings, and
      we will swing well more often if we do regular balance exercises as
      maintenance. Many players practice balance by standing on one foot or
      using different balance tools at the gym.
    </div>
    <div class="p-mb-2">
      For the sake of your golf, it’s very important that you are able to
      transfer stationary balance to rotational balance. That is what you need
      for your golf swing. We see many players who are able to stand on one leg
      with their eyes closed for thirty seconds, but then when we ask them to
      rotate at the same time, they fall over right away. When you make the golf
      swing, your body must rotate. You must be able to maintain balance not
      just when you are stationary, but when you are in motion as well. The
      swing is a dynamic action.
    </div>
    <div class="p-mb-2">
      If you can step onto the first tee with a sense that you are centered like
      that spinning top, you will bring a calm with you into the Play Box that
      will greatly increase your chances of repeatedly making great swings. And
      you do not have to let that balance be a hope thing, but rather it can be
      a sure thing. You can make it happen; this is one of the things under your
      control.
    </div>
    <div class="p-mb-2">
      A key first step is to realize that balance can be disrupted by a wide
      variety of things and that these disruptive factors vary from person to
      person. What throws your game out of balance? Anger? Fear? Not warming up
      properly? Not having a Playing Focus to engage your mind? Not eating
      right? The simple fact that you are out of sorts on this particular day?
      The first key to maintaining your balance is to understand yourself.
    </div>
    <div class="p-mb-2">
      Anytime we think too much and all our energy is up in our head, it affects
      our balance. We all remember the way Tiger Woods struggled in 2010 after
      difficulties in his personal life became public. Even someone as talented
      and mentally focused as Tiger is vulnerable to the delicate nature of
      balance. He was distracted. And what is the mental image you have of Tiger
      when he hits a wild shot? He ends up out of balance, often dropping his
      club or letting go with one hand.
    </div>
    <div class="p-mb-2">
      Yes, Woods had swing issues in 2010, but those issues were magnified by
      the fact that he wasn’t completely engaged in the process of playing golf.
      That was shown by his erratic short game, which had nothing to do with the
      head dip in his swing but rather with the dip in his concentration level.
      Tiger had lost his balance, he had lost his way.
    </div>
    <div class="p-mb-2">
      To some degree, this happens to all of us. The balance of the swing is a
      physical thing wedded to our mental and emotional state. “Emotional
      realities drive the physical bus,” Topper says. “Running off the track is
      a physical manifestation, but the driver must be considered, especially
      when the emotion rises. Getting through to the truth and meaning of how
      you feel emotionally is utterly key to realizing the athletic functioning
      required to swing the golf club properly.
    </div>
    <div class="p-mb-2">
      Topper had become an angry player and was not even aware of it. That led
      to his becoming an unhappy player. And there is no way you can play your
      best golf in that state. When Topper realized this and learned some of our
      tools to maintain his emotional balance, he dropped his handicap to 0.2
      and said, “My ambition is to get it lower still.” He is back in balance on
      the golf course in every sense of the word.
    </div>
    <div class="p-mb-2">
      One of the questions we like to ask players is this: “If you had only five
      minutes to warm up for your round, what single thing could you work on
      that would have the greatest impact on how you play?” For Topper, it is
      definitely balance. And by doing some simple exercises that focus on
      physical balance, he is improving his mental balance by taking control of
      the situation and doing everything within his control to get ready for the
      round of golf ahead. Working on his physical balance strengthened his
      mental belief in his ability to perform.
    </div>
    <div class="p-mb-2">
      Some of the exercises we give you below may take you out of your comfort
      zone, and some of them may get you some odd looks on the practice range.
      Don’t let that inhibit you. These work. They are part of the great
      assortment of tools we have developed to help you stay in balance and
      regain your balance when you lose it. Many are also tools you can take
      onto the course with you.
    </div>
  </div>
</template>
