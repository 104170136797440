<template>
  <Panel
    header="Abstract Thinking Personality Type"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Abstract Thinking: is how good at abstract thinking you are. Low scorers
        prefer common sense, high scorers prefer abstract thinking.
      </div>
      <div class="p-mb-2">
        The second of the eight personality traits of champion golfers-abstract
        thinking-is different from the others in that it is not a measure of
        temperament. It is a measure of ability. Abstract thinking is a golfer's
        ability to analytically reason problem-solve, leam, and adapt
        intellectually. That said it is affected by traits involving
        temperament, because all of the eight traits are part of an overall
        formula.
      </div>
      <div class="p-mb-2">
        A golfer who scores high in abstract thinking generally is a fast
        learner who processes complex information rapidly. This is not always
        good for golf, however. Abstract thinkers sometimes process so much
        information that they overwhelm themselves by overcomplicating or
        overanalyzing swing problems as they try to fix mechanical flaws. This
        prevents them from playing intuitively. As a result, they lose
        confidence and find themselves stuck between an old swing they can't get
        back to and a new swing they can't master.
      </div>
      <div class="p-mb-2">
        Someone who is lower on the abstract thinking scale is re- ferred to as
        a "concrete" thinker. A concrete thinker will tend to be a slower and
        more deliberate learner, processing information more simply and
        directly, sometimes oblivious to the many techniques available to
        improve play. These techniques can be physical and mental, such as
        adjusting for a sidehill lie or neglecting to change a game plan should
        the wind shift.
      </div>
      <div class="p-mb-2">
        Because abstract thinking is an ability greatly influenced by genetics,
        we do not expect to increase or decrease the ab- stract thinking of our
        clients. Rather, we teach golfers to use their abilities to their
        greatest advantage. If a player scores high on the abstract-thinking
        scale, we can safely assume he will need to learn techniques for
        simplifying his thoughts and quieting his mind over the ball. If a
        player scores lower on the abstract-thinking scale, we will likely need
        to encourage him to take more time to preplan his strategy, target, and
        club se- lection. Again, we can't influence the basic abstract skills
        with which he was born, but we can teach him to maximize his in- herent
        skills with planning and effort.
      </div>
      <div class="p-mb-2">
        Players at both ends of the scale improve by learning to channel more of
        their mental energy away from outcome- oriented thoughts-such as score,
        position, or simple but ex- cessive concern about where a ball might
        go-and toward the process of their play.
      </div>
    </div>
  </Panel>
  <Panel
    header="The abstract thinking skills of champions."
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        As implied, players at the high and low ends of the abstract- thinking
        scale tend to sabotage their own play, but because such thinking comes
        naturally to them, they are generally unaware of the mistakes they make.
      </div>
      <div class="p-mb-2">
        The players who think most like champions, on the other hand, are
        average to high-average abstract thinkers who are skilled at using their
        ability in ways most appropriate to golf. They avoid problems brought on
        by overthinking the me- chanics and fundamentals of the game. They are
        also less likely to succumb to under-thinking a given situation, such as
        neglecting to consider all the variables when deciding on club, target,
        and type of shot. They compete with natural and trained abilities rather
        than getting immersed in mechanics and fundamentals. They think through
        a strategy for playing any given course that gets the most from their
        abilities. They quickly assess and overcome problems so they can put
        posi- tive mental energy into the shot at hand. And, they usually
        channel their attention toward a quality routine for the next shot. This
        makes them consistently good at simplifying their thoughts over the ball
        and at managing their thoughts between shots.
      </div>
      <div class="p-mb-2">
        A good abstract thinker probably has an easier time deter- mining good
        strategy for playing a course. They understand the variables involved
        with shot selection (club lengths, lofts, yardages, wind direction and
        strength, firmness of greens, dif- fering lies, and so on). This enables
        them to commit to clubs, targets, and types of shots. They also
        understand the "cause and effect" of shot-making. They know that if
        their ball is going left, for example, they should first check their
        mental routine -especially their commitment to the shot and the tempo of
        their swing-and then check mechanical variables like grip, stance, and
        ball position.
      </div>
      <div class="p-mb-2">
        If you do not naturally have champion abstract abilities, you are not
        doomed to play frustrating (excessive abstract thinkers) or lackluster
        (excessive concrete thinkers) golf. You can learn the simple mental
        skills that have helped golfers of all levels and that can help you play
        better and more enjoyable golf. The skills include: 1. Recognizing
        tendencies to underthink or overthink while competing and while
        preparing for competition. 2. Leaming simple methods for getting the
        most out of your abstract abilities while playing.
      </div>
    </div>
  </Panel>
  <Panel
    header="Difficulties Involved in Managing Thoughts"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Even with great abstract-thinking skills, managing your thoughts while
        playing competitive golf can be difficult for those with other
        particular personality traits, temperaments, and habits. But the overall
        formula can be improved as long as you are aware of the problem areas
        and are prepared to work on them. Here are some obstacles that lie in
        the way of good abstract thinking and some solutions.
      </div>
      <div class="p-mb-2">
        Lack of discipline: If you are one of the many people who tend to
        procrastinate or be unorganized, you may not be making good use of your
        abstract abilities while on the golf course. Before teeing off, take
        time to think through at least a basic plan for playing the course.
        During competition, don't estimate yardages so much-instead walk them
        off-but do estimate conditions more often; in other words, take more
        time to assess such factors as wind strength and direction and the
        direction in which the grain on a green may be running. This is all part
        of course management, and you'll find more course management suggestions
        later in this chapter.
      </div>
      <div class="p-mb-2">
        Tension: There is not a single champion trait that is not affected by
        tension. A golfer compromises his abstract abilities when tension causes
        his mind to race and his mind becomes cluttered with thoughts having
        little or nothing to do with the execution of the shot at hand.
      </div>
      <div class="p-mb-2">
        Lack of confidence: Low confidence leaves a player with poor trust in
        his abstract-thinking abilities, regardless of how good they may be.
        This, of course, leads to a lack of commitment, poor play, and a lot of
        second-guessing.
      </div>
      <div class="p-mb-2">
        Emotions: As a player gets more emotional, he naturally gets less
        rational. Instead of using his good abstract abilities, the player is
        more prone to make decisions by reacting emotionally rather than
        rationally. We want the golfer to be more methodical when calculating
        what shot to hit. Thereafter, vi- sualization and feel are important in
        helping the player react rationally or intuitively to the shot.
      </div>
      <div class="p-mb-2">
        A “group” mentality: Some players - especially those who are heavily
        coached - come to the game conditioned to be more group-oriented than
        self-sufficient. Their good abstract abilities can be compromised until
        they practice making their own decisions, especially difficult ones,
        under the pressure of play.
      </div>
      <div class="p-mb-2">
        Other factors: The obvious ones - drugs, alcohol, fatigue, illness, and
        personal conflict - will muddle your thinking no matter how focused or
        committed you think you are.
      </div>
      <div class="p-mb-2">
        If you feel your abstract abilities are weak, strong, or underutilized,
        for any of the reasons mentioned above, you’ll benefit from learning
        skills to improve them for competitive golf.
      </div>
    </div>
  </Panel>
  <Panel
    header="(Skill) Basic Preparation for all Abstract Thought."
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        This helps the concrete thinker be better prepared and helps the
        abstract thinker simplify thoughts. It involves taking time to preplan
        your rounds and to monitor your golfing strengths and weaknesses.
      </div>
      <div class="p-mb-2">
        Write down a basic plan for playing the course before you hit your first
        shot. With your knowledge of the course, your score card, and any other
        available information, decide on a strategy for playing the course that
        will help you get the most out of your game. * List the clubs and shots
        you currently feel are your strongest. * Develop a plan for each hole,
        starting on the green.
      </div>
      <div class="p-mb-2">
        The plan you come up with should reflect your current skills rather than
        how someone else would play the hole or how you perceive the hole is
        designed to be played. Starting with the first hole, divide the green
        into quadrants of four logical parts. Choose the quadrant(s) you can use
        as a target that will give you more reward than risk. Working backward,
        choose a position in the fairway - or on the tee of a par three-that
        will give you your best shot into the quadrant. Select the club and type
        of shot you would ideally use from that target assuming the most likely
        conditions. Repeat this exercise until you are back on the tee. Your
        target and club selection on the tee will now be based on your strategy
        for playing the hole. You might be surprised to find how often you
        determine that the driver is not the club to hit from the tee.
      </div>
    </div>
  </Panel>
  <ManageBadThoughts />

  <Panel
    header="Identifying Your 'Not-Allowed' Thoughts"
    :toggleable="true"
    :collapsed="true"
  >
    <abstractThoughtNotAllowed />
  </Panel>
</template>
<script>
import abstractThoughtNotAllowed from "@/components/mental/abstractThoughtNotAllowed";
import ManageBadThoughts from "@/components/mental/manageBadThoughts";

export default {
  name: "ToughMinded",
  components: {
    abstractThoughtNotAllowed,
    ManageBadThoughts,
  },
};
</script>
