<template>
  <img src="@/assets/images/hardy1/hardyFinish1.png" alt="Hardy Posture" />
  <div class="accent1 fontSmall p-mb-2">
    The right arm is fully extended across your chest. Your right hand should be
    above your left shoulder and the left arm should be folded upward at the
    elbow. The club should be behind the player's upper back, with the clubhead
    only slightly lower than the butt end of the club,
  </div>
  <Panel header="Introduction" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        <span class="accent1"
          >A graceful, well-balanced follow-through is very desirable, because
          it indicates that a well-balanced and well-timed swing preceded
          it.</span
        >
        You'll rarely see a very good player with an ungainly follow-through,
        nor will you often see a poor golfer with a beautiful finish to the
        swing.
      </div>
      <div class="p-mb-2">
        Some people may argue that the follow-through is not important because
        when you reach this point in the swing, the ball is long gone. That's
        true, but the follow-through is a mirror of what went before it. As
        such, it can be very instructive to look at. When we talk about the
        follow-through, let's make sure that one thing is clear:
        <span class="accent1"
          >We are discussing the natural positions of the body and the golf club
          at the completion of the swing. We are not talking about an artificial
          pose that the golfer should try to assume at the end of his or her
          swing.</span
        >
        I will often see amateurs who, halfway into a poorly balanced,
        out-of-position follow-through, will actually adjust themselves into
        what they think is a picture finish. It's as if they think that
        correcting their pose is going to help correct their shot. Of course it
        can't, but I guess you can't blame them for trying. This attempt at a
        correction shows that, at least on an instinctive level, they know they
        are ending up in an unbalanced position. But a belated fix of the
        follow-through does them no good. Swinging directly into a good
        follow-through would mean a lot more. Let's look at the correct
        follow-through positions of the one- and two-plane players. Though they
        both should look fluid, well balanced, and under control, you'll see
        that they are actually quite a bit different from one another.
      </div>
      <div class="p-mb-2">
        It's important to note that when I describe the various body and club
        positions I will be describing them for the follow-through of a full
        swing with a driver.
        <span class="accent1"
          >The player who is hitting a controlled shot with a lofted iron will
          not swing the body and the club as far around as described here.
          Likewise, the follow-through will vary somewhat depending on whether
          the player is trying to hit a specialty shot such as a low hook, a
          slice, a punch shot, or some other shot with an altered
          trajectory.</span
        >
      </div>
      <div class="p-mb-2">
        One more thing: There will be no review segment for the one-plane and
        two-plane swing follow-through positions simply because this part of the
        swing is a result of what came before. Still, it's important to learn
        the elements of a good follow-through so you'll be more apt to achieve
        one, again provided your swing is technically sound prior to that point.
      </div>
    </div>
  </Panel>
  <Panel header="Shoulders" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        You may have heard or read that in the follow-through the front of your
        body should be facing the target. I feel that this is, at best,
        incomplete information. In fact, for the one-plane swinger in
        particular, this is erroneous instruction because it does not
        differentiate between the position of the shoulders and the position of
        the hips. At the finish,
        <span class="accent1"
          >the one-plane player's shoulders should be fully and completely
          unwound to the left,</span
        >
        or counterclockwise, for the right-handed player. You
        <span class="accent1">cannot turn your shoulders too far </span>
        into the follow-through. However, the amount that they turn will
        obviously vary depending on your torso's strength and flexibility. Watch
        Michelle Wie or Ernie Els when they follow-through on a drive,
        particularly if it is on a long par-five where they know they must hit
        the ball extra hard to get home in two. Both of these great one-plane
        swingers will turn their shoulders far beyond the point where their
        shoulders face the target. Wie and Els can turn their shoulders nearly
        90 degrees beyond where they face the target, or, to put it another way,
        they turn their shoulders nearly 180 degrees beyond where they were at
        the address position. But these are two of the most flexible and
        powerful players in golf. They turn their shoulders hard enough through
        the downswing that the shoulders just want to keep on going, and they
        are both flexible enough to let that happen.
      </div>
      <div class="p-mb-2">
        You will also see many junior players who are flexible enough to attain
        a huge shoulder turn. Chances are, if you're an adult weekend amateur,
        your shoulders will not turn this far.
        <span class="accent1"
          >The point is, though, that they should turn as far as they can.</span
        >
        If your shoulders can reach the point where they are slightly beyond the
        point where they face the target—or, say, 100 degrees past their
        original square position at address—you've made a good turn to the
        finish.
      </div>
      <div class="p-mb-2">
        When viewed either from up or down target line, a line across your
        shoulders
        <span class="accent1"
          >should be horizontal or nearly horizontal to the ground.</span
        >
        This is
        <span class="accent1"
          >a result of the spine angle starting to straighten up just after
          impact and continuing to straighten as the shoulders unwind in the
          follow-through.</span
        >
      </div>
    </div>
  </Panel>
  <Panel
    id="FollowThroughArms"
    header="Arms"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        At the finish, the arms have been swung around by the force of your
        shoulder and torso turn and by the release of the right arm over the
        left arm. Instead of trailing the body, they now have been flung across
        the front of the chest and over to the left side. Your arm position is
        not quite a mirror image of your position at the top of the backswing.
        Because your spine is now erect as it nears the end of the
        follow-through,
        <span class="accent1"
          >your right arm (for the right-handed player) is fully extended across
          your chest. Your right hand should be above your left shoulder and the
          left arm should be folded upward at the elbow.</span
        >
        Drill: <a href="#ElbowsUpArmsAcross">Elbows Up Arms Across.</a>
      </div>
    </div>
  </Panel>
  <Panel header="Hips" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        The hips should be
        <span class="accent1">fully rotated toward the target.</span> Again,
        precisely how much your hips turn depends on your flexibility.
        <span class="accent1">Ideally, they will face the target</span>
        (which means your hips would be turned 90 degrees beyond their position
        at address) or even be turned slightly beyond or to the left of the
        target. When you compare the amount your hips are turned with your
        shoulder turn at the finish, your shoulders should be turned farther
        than your hips have turned.
      </div>
    </div>
  </Panel>
  <Panel header="Spine Angle" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        When viewed <span class="accent1">from up-target </span>(that is, from
        the target looking back to the player), the player's spine angle should
        be fairly erect. It is
        <span class="accent1"
          >acceptable for the spine to be tilted slightly to the player's right,
          or toward the target line.</span
        >
        This slight tilt is acceptable for the one-plane player because this
        player's shoulders turn on a steeper plane than the two-plane swinger's,
        whose shoulder plane is nearly horizontal.
      </div>
      <div class="p-mb-2">
        I'd like to add one important point about the spine angle at the finish,
        and this applies equally to the two-plane player. When viewed
        <span class="accent1">from face-on</span> the players spine at
        follow-through <span class="accent1">should appear errect.</span> It
        should not be tilted backward, that is, the top of the spine should not
        be farther from the target than the base of the spine. Such a backward
        tilting of the spine indicates that an exaggerated sliding move by the
        hips and the legs occurred during the swing. Such a move would be
        especially destructive to the action of a one-plane swing. Perhaps more
        importantly, this backward spine tilt is the basis for what is known as
        the "reverse C" finish position, which I have discussed previously. Over
        time, swinging into the reverse C will put enormous strain on your lower
        back. It may not start hurting until you're older, but believe me, at
        some point you will develop back problems. Tom Kite and Peter Jacobsen
        are perhaps the best example of players who developed from two-plane
        swingers into wonderful one-plane swingers and who, in the process, went
        from having a big reverse C to having a very erect spine at the finish.
      </div>
    </div>
  </Panel>
  <Panel header="Weight Distribution" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        At the finish, the one-plane player's weight should be
        <span class="accent1"
          >almost entirely on the left or forward foot. The left leg should be
          straight while the right leg is slightly bent. The right knee should
          be slightly bent, with the right lower leg angled forward and the
          player up on the right toe.
        </span>
      </div>
    </div>
  </Panel>
  <Panel header="Club" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        At the finish, the hands and the club will come around in a slightly
        lower position for the one-plane swinger than the two-planer. This is
        consistent with the somewhat flatter overall swing plane of the
        one-plane player.
        <span class="accent1"
          >This player's hands should be just above and slightly outside the
          point of the left shoulder. The club should be behind the player's
          upper back, with the clubhead only slightly lower than the butt end of
          the club,</span
        >
        which is the same as saying that the club shaft is nearly horizontal to
        the ground.
      </div>
      <div class="p-mb-2">
        A note here, particularly for you serious golfers who like to use video
        or photography. Just after impact, in the early part of the
        follow-through, <span class="accent1">the clubhead,</span> when viewed
        from down the target line, should first appear in photographs to
        <span class="accent1"
          >come left of the body just under or below the left shoulder.</span
        >
        This is a very important point because it shows the arms and club have
        been slung around the torso to the left, and not out and up away from
        it. If the arms and club are not slung around like a baseball bat, with
        the right arm releasing over the left arm, they will drift out and away
        from the torso. The club will break the arc and stay on too straight a
        line, going too far down the target line. This fault will result in
        pushes and hooks or, if the hands are blocking to hold off the hook,
        heel or shank shots.
      </div>
      <div class="p-mb-2">
        A great deal of misinformation exists regarding swinging the club down
        the target line as long as possible. This couldn't be farther from the
        truth. Remember, you are playing a side-on game, and all golf swings,
        regardless of one- or two-plane, must move in an arc from inside the
        target line to the target line and then back to the inside again. The
        two-plane swing will do this less than the one-plane swing because of
        its more upright nature. However, I would strongly advise you to ignore
        any golf instruction that suggests swinging on a straight line toward
        your target. That's good information for an on-line game such as darts
        or croquet, but it is disastrous for any side-on game such as baseball,
        tennis, or golf.
      </div>
    </div>
  </Panel>
</template>
