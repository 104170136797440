import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import PrimeVue from "primevue/config";
import Editor from "primevue/editor";
import Button from "primevue/button";
import ToastService from "primevue/toastservice";
import Toast from "primevue/toast";
import Panel from "primevue/panel";
import Fieldset from "primevue/fieldset";

import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "primeflex/primeflex.css";

const app = createApp(App);

app.use(store);
app.use(router);
app.use(PrimeVue);
app.use(ToastService);

app.component("Editor", Editor);
app.component("Button", Button);
app.component("Toast", Toast);
app.component("Panel", Panel);
app.component("Fieldset", Fieldset);

app.mount("#app");

// createApp(App).use(store).use(router).mount("#app");
