<template>
  <Panel
    header="Feel It:  Balance, Tempo, Tension Awareness Training"
    :toggleable="true"
    :collapsed="true"
  >
    <Panel header="Balance" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">BALANCE EXERCISES</div>
        <div class="p-mb-2">• Hit five shots with your feet together.</div>
        <div class="p-mb-2">• Hit five shots, standing on your right foot.</div>
        <div class="p-mb-2">• Hit five shots, standing on your left foot.</div>
        <div class="p-mb-2">• Hit five shots with your eyes closed.</div>
        <div class="p-mb-2">
          • Hit five shots using only your left arm and standing on your right
          foot.
        </div>
        <div class="p-mb-2">
          • Hit five shots using only your right arm and standing on your left
          foot.
        </div>
        <div class="p-mb-2">• Hit five shots barefooted.</div>
        <div class="p-mb-2">• Hit five shots, standing on your left toes.</div>
        <div class="p-mb-2">• Hit five shots, standing on your right toes.</div>

        <div class="p-mb-2">
          Do each exercise with a variety of clubs and see how many swings you
          can finish in balance. If it is difficult, start with small swings and
          a slower tempo. Pick the exercises that match your skill level, and as
          you get better, move on to the others.
        </div>
        <div class="p-mb-2">
          • Hit a chip shot standing on your right foot and using your left arm.
          Alternate by standing on your left foot and swinging with your right
          arm. Do this five times.
        </div>
        <div class="p-mb-2">
          • Hit putts alternating between standing on the right foot only, left
          foot only, on the toes of the right foot, on the toes of the left
          foot, and eyes closed and feeling a low center of gravity.
        </div>
        <div class="p-mb-2">
          • Hit ten consecutive bunker shots in which you finish with your
          weight on your front foot. Vary each shot to different targets
        </div>
        <div class="p-mb-2">
          • Hit five shots left-handed if you are a right-handed player, and
          right-handed if you are a left-handed player. Do it with a 7-iron
          turned around. Do the same on the chipping green.
        </div>
        <div class="p-mb-2">
          • Juggle with three golf balls for four minutes.
        </div>
        <div class="p-mb-2">Functional Balance:</div>
        <div class="p-mb-2">
          “You are able to hit ten consecutive shots with your feet together,
          five on only the left foot, and five on only the right foot, all
          finishing in balance.”
        </div>
        <div class="p-mb-2">Masterful Balance:</div>
        <div class="p-mb-2">
          “You can hit ten consecutive shots with your feet together, ten on
          your right foot only, ten on your left foot only, five with your eyes
          closed, five standing on the toes of your right foot, and five
          standing on the toes of your left foot, all finishing in balance.”
        </div>
      </div>
    </Panel>
    <Panel header="Tempo" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">TEMPO EXERCISES</div>
        <div class="p-mb-2">
          Here are the steps to reliable tempo. If you practice them, you can
          control your tempo when you need to on the golf course. Learn to
          recognize how 25 percent tempo, 50 percent, 75 percent and 100 percent
          feel for the full swing. Do the same for chipping, pitching and
          putting. Keep the tempo constant for the entire swing or stroke. Were
          you able to keep the tempo constant? Can you sense the difference?
          What tempo feels best for you with different clubs for different shots
          and for different days? Practice and nurture the awareness of tempo
          and the ability to adjust the tempo. Develop the ability to
          efficiently adjust your tempo.
        </div>
        <div class="p-mb-2">
          • Hit five drives with 50 percent tempo, five 7-irons at 75 percent
          and five half swings with your wedge.
          <div class="p-mb-2">
            • Hit a full 9-iron. Identify the spot where it landed. Now hit
            every club longer than your 9-iron and make them land on the same
            spot. Use full swings and vary your tempo.
          </div>
          <div class="p-mb-2">
            • Hit a three-quarter 6-iron with 75 percent tempo; hit a half swing
            with your 6-iron at 100 percent tempo. Do the same with a longer
            club and a shorter club.
          </div>
          <div class="p-mb-2">
            • Practice different pitching distances and vary the tempo. How do
            you hit a twenty-five-yard pitch shot with four different tempos? Do
            this with four different distances.
          </div>
          <div class="p-mb-2">
            • Putt three-footers with four different tempos. Do this four times.
          </div>
          <div class="p-mb-2">
            • Hit a drive as hard as you can while finishing in balance; hit the
            softest lob shot possible; hit the hardest 5-iron you can while
            finishing in balance; hit the softest three-quarter 9-iron possible.
            How must you manage yourself between the shots to create the
            different tempos?
          </div>
          <div class="p-mb-2">
            • Make full swings with any club and vary the tempos of your
            backswing and forward swing: Take it back at 25 percent tempo and
            swing forward at 75 percent; now try 100 percent back and 25 percent
            forward. Try many different combinations. Now hit three shots,
            keeping the preferred tempo throughout your entire swing.
          </div>
          <div class="p-mb-2">
            • Putt three three-footers, alternating between dropping the ball in
            the front of the cup, charging to the back of the cup and falling in
            the middle of the cup. Do this four times.
          </div>
          <div class="p-mb-2">
            • Hit five drives with the fastest tempo you can while still being
            able to finish in balance.
          </div>
        </div>

        <div class="p-mb-2">Functional Tempo:</div>
        <div class="p-mb-2">
          You can feel the difference between four different tempos, with five
          different clubs, and make good contact with the ball.
        </div>
        <div class="p-mb-2">Masterful Tempo:</div>
        <div class="p-mb-2">
          For eighteen holes, you can be keenly aware of your tempo and adjust
          it efficiently when needed.
        </div>
      </div>
    </Panel>
    <Panel header="Tension Awareness" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">TENSION AWARENESS EXERCISES</div>
        <div class="p-mb-2">
          • Practice the Tai Chi golf swing. Make the entire swing in extremely
          slow motion, taking two to three minutes to complete one full swing.
          Pay attention to tension. Where in the swing do you feel tension?
        </div>
        <div class="p-mb-2">1. Your grip?</div>
        <div class="p-mb-2">2. Your back?</div>
        <div class="p-mb-2">3. Your shoulders?</div>
        <div class="p-mb-2">4. Your legs during the weight shift?</div>
        <div class="p-mb-2">
          • If you discover unwanted tension in any area of the swing, what can
          you do to reduce it?
        </div>
        <div class="p-mb-2">
          • Hit full shots with four different grip pressures, from extremely
          light to extremely firm. Which grip pressure works best for your
          swing? Do the same for putting shots.
        </div>
        <div class="p-mb-2">
          • Focus on the jaw, neck and shoulders. Hit three shots with three
          different tension levels. Hit with a slack jaw, a tense jaw and a
          normal jaw. When you are trying to relax your jaw, keep your tongue
          behind your lower front teeth. This will help. Which is best for you?
          Do the same for putting shots.
        </div>
        <div class="p-mb-2">
          • Hit chip shots with an alternating range of grip pressure, going
          from one (as light as you can grip the club) to four (the tightest).
          Keep your grip pressure constant during the swing. Which is best for
          you?
        </div>
        <div class="p-mb-2">
          • Hit chip shots alternating between tight tension in your arms and
          shoulders, average tension and very loose. Do this three times. What
          tension level makes you hit more solid chip shots?
        </div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2">
          • Hit four shots with your tongue behind your lower front teeth. Hit
          four shots with arms that feel like cooked spaghetti. Hit four shots
          while you feel your breathing.
        </div>
        <div class="p-mb-2">
          • Next time you play, consciously do physical movements like
          stretching or yawning or deep breathing to release body tension
          between shots.
        </div>
        <div class="p-mb-2">
          • Hit shots with different tension levels in your lower body. Hit
          shots with a tension level of one to four in your thighs. Hit shots
          with a tension level of one to four in your toes. What do you notice?
        </div>
        <div class="p-mb-2">Functional Tension Awareness:</div>
        <div class="p-mb-2">
          “You know where you have a tendency to create tension in your body.
          You can sense where it starts and lives.
        </div>
        <div class="p-mb-2">Masterful Tension Awareness</div>
        <div class="p-mb-2">
          “You know what tension level works for you. You know where you have a
          tendency to create tension. You know how to change it before a round
          and during a round.
        </div>
      </div>
    </Panel>
  </Panel>
</template>
