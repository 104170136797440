<template>
  <Panel header="Basics" :toggleable="true" :collapsed="true">
    <Panel header="Introduction" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          A half dozen times a year, I speak to large groups about short-game
          and putting basics. And when I give my talk about the things I teach
          in chipping and putting, I get most of my confused looks when I talk
          about how those parts of your game really aren't any different than
          your full swing, at least in terms of fundamentals.
        </div>
        <div class="p-mb-2">
          But wait. You've always been taught a different setup for your chip
          shots, right? I know that when I ask most average weekend players to
          show me what they do to hit a chip shot, they do the same thing: Turn
          to an open stance and play the ball way back. As you've heard me say
          in both The Art of Putting and here already, I don't think there's any
          reason to make any shot harder than it has to be. Taking the club
          outside the target line and chopping down on it with a 7-iron can
          work, if you practice a lot and usually have decent lies. But why
          would you want to do it that way when there's an easier way—a way that
          you can learn in a weekend?
        </div>
      </div>
    </Panel>
    <Panel header="Grip" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          One thing you'll learn right away from me is that with the exception
          of a few high-loft specialty shots, the basics of hitting a chip or a
          pitch shot aren't any different than what you'd use for a full swing.
          In other words, you can apply what you already do—and what your
          full-swing teacher is probably already telling you—to your short game.
          I simply don't believe the short game is some separate and distinct
          cousin to the full swing. I play every shot around the green with my
          normal grip. As I get closer to the green, my stance gets
          narrower—from the same as a middle-iron shot on a full pitch to where
          my heels are about four inches apart. Like I said, I'm not coming up
          with anything new for these shots.
        </div>
        <div class="p-mb-2">
          I put my hands on the club with the grip down in my fingers, not
          across my palms—which is a common mistake I see a lot of players make,
          on both full-swing and short-game shots. If you get the club up in
          your palms, it restricts the swing of the clubhead. It doesn't let you
          hinge and unhinge the club properly. You also have more feel in your
          fingertips than you do in your palms, and I'm interested in getting
          the most sensitive part of your hands on the club as much as possible,
          because your feel for distance is going to be better. The club should
          run along the crease that is created by the top of your palm and the
          bottom of the first knuckles of your fingers. The grip is running
          parallel to the top of your palm, not diagonally across the palm,
          which is how I'd tell you to hold your putter. Because your wrists
          don't hinge on a putt, the shaft of your putter should run in line
          with your forearms. On shots that require a wrist hinge—which is
          basically every shot besides a putt—the club points below the line of
          your forearms.
        </div>
        <img
          src="@/assets/images/utleyArtShortGame/basics1.png"
          class="img3"
          alt="Utman basics"
        />
        <div class="accent1 fontSmall p-mb-2">
          I use this grip for all of my short-game shots. It's neutral, meaning
          that the creases created by my thumbs and the sides of my hands are
          parallel to each other and point up toward my right collarbone.
        </div>
        <img
          src="@/assets/images/utleyArtShortGame/basics2.png"
          class="img3"
          alt="Utman basics"
        />
        <div class="accent1 fontSmall p-mb-2">
          I use an overlapping grip, and I make sure to hold the handle in my
          fingers, not down in my palms.
        </div>
        <img
          src="@/assets/images/utleyArtShortGame/basics3.png"
          class="img3"
          alt="Utman basics"
        />
        <div class="accent1 fontSmall p-mb-2">
          Because my grip is in my fingers, the shaft hangs below the angle of
          my forearms. This promotes a good wrist hinge.
        </div>
        <img
          src="@/assets/images/utleyArtShortGame/basics4.png"
          class="img3"
          alt="Utman basics"
        />
        <div class="accent1 fontSmall p-mb-2">
          Notice how the fingertips of my right hand make good contact with the
          grip. This is a key component of feel.
        </div>
        <img
          src="@/assets/images/utleyArtShortGame/basics5.png"
          class="img3"
          alt="Utman basics"
        />
        <div class="accent1 fontSmall p-mb-2">
          Compare the short-game grip with my putting grip. To putt, I use a
          reverse overlap, with the grip running up the lifelines of both hands,
          versus down in the fingers. This grip is designed to get the shaft
          running up the forearm plane.
        </div>

        <div class="p-mb-2">
          You're probably already familiar with the concept of weak, neutral,
          and strong grips. Let me tell you what my definition of those terms
          are so that we don't get confused. If you set your grip in front of
          you and hold it up in front of your chest, the V's created by the
          sides of your hands and your thumbs will point to your right
          collarbone in a neutral grip. In a weak grip, the V's point almost
          straight up, toward your chin. In a strong grip, the V's turn more to
          the right, so that they're aimed at your right shoulder. (Remember to
          reverse all of these directions if you're a left-hander.) You can also
          have your hands independently turned to different degrees of weak and
          strong. For example, if you turned your left hand out toward the
          target and your right out away from the target, your left hand would
          be set up really weak, and your right would be really strong.
        </div>
        <div class="p-mb-2">
          I like to see a grip that's more neutral than anything else. My right
          hand is in a neutral position, while my left hand is a touch strong.
          One of the key elements of what I teach in the short game is how to
          use your forearm rotation to create different shots—rotating to
          de-loft the face of the club for a low shot and holding the forearms
          off to hit a high shot. I believe that when you start with a neutral
          grip, you have all of those shots available to you just by making a
          relatively simple adjustment in your forearm rotation. If you use a
          weak grip, you will need a lot of forearm rotation to lower your ball
          flight. Your misses will likely come from scooping the ball at impact.
          Whatever you do, you want to avoid a grip that's too weak. If you set
          up with a grip that's too strong, essentially you're starting out with
          the forearms already rotated. “If you rotate any more, you're going to
          hit the ball low and left. A strong grip requires little forearm
          rotation. You'll be good at the low shots and struggle to hit high,
          soft ones.
        </div>

        <img
          src="@/assets/images/utleyArtShortGame/basics6.png"
          class="img3"
          alt="Utman basics"
        />
        <div class="accent1 fontSmall p-mb-2">
          In a strong grip, the creases created by my thumbs and the sides of my
          hands point away from the ball, toward my right shoulder. With this
          grip, you'll use less forearm rotation to play low shots.
        </div>
        <img
          src="@/assets/images/utleyArtShortGame/basics7.png"
          class="img3"
          alt="Utman basics"
        />
        <div class="accent1 fontSmall p-mb-2">
          In a weak grip, the creases point toward my chin. It's hard to rotate
          the forearms correctly with this grip, and you'll tend to hit higher
          shots.
        </div>
        <div class="p-mb-2">
          If a person has a good grip, and the club is straight in front of him,
          he should be able to hinge the club up and down in front of him, and
          the leading edge should move straight up and down. If you hinge that
          club up and down and the toe of the club leans left of the heel, then
          your grip is too strong. If it leans to the right, then your grip
          would be too weak.
        </div>
        <img
          src="@/assets/images/utleyArtShortGame/basics8.png"
          class="img3"
          alt="Utman basics"
        />
        <div class="accent1 fontSmall p-mb-2">
          If a person has a good grip, and the club is straight in front of him,
          he should be able to hinge the club up and down in front of him, and
          the leading edge should move straight up and down. If you hinge that
          club up and down and the toe of the club leans left of the heel, then
          your grip is too strong. If it leans to the right, then your grip
          would be too weak.
        </div>
        <div class="p-mb-2">
          If you set up with a strong grip, this is what happens to the clubface
          when you rotate your forearms through impact—it shuts down, and you
          hit the shot left.
        </div>
        <div class="p-mb-2">
          Does that mean you can't hit great shots with a strong grip? Of course
          not. Paul Azinger is one of the greatest short-game artists of all
          time, and he's been phenomenal with a strong grip. But Paul uses a
          different technique than “I do. He's awesome at hitting his short-game
          shots without rolling his arms. To hit a chip shot, he takes loft off
          his wedge by bowing his wrists rather than rotating his forearms, like
          I teach. Paul plays to his strengths. He has always played more low
          shots. If you have a strong grip like Paul, you have to recognize that
          you'll close the clubface on the backswing. This reduces loft and
          eliminates the club's bounce. Starting from a more fundamental grip
          will speed up your ability to learn different shots around the green.
        </div>
        <div class="p-mb-2">
          I need to make an important point about your short-game grip: Pay
          regular attention to it. Personally, when I was playing full time, I
          hit hundreds and hundreds of pitches and chips every week, as do the
          tour players I teach. I know my fundamentals—even my grip—can drift on
          occasion. Since the grip is your connection to the club, it can play a
          big role in your consistency—or lack of it—without you noticing any
          problem. I make almost a dozen trips a year to tour events around the
          world to give the guys I teach “checkups,” and a lot of the time, we
          solve most of the problems right away with simple adjustments in
          fundamentals that have drifted a little bit out of place. The grip is
          the easiest one to lose track of, especially if you've made a recent
          change. Be sure to keep watch on it.
        </div>
      </div>
    </Panel>
    <Panel header="Stance and posture" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          When it comes to short-game stance and posture, I want you to feel
          like what you're doing in your short game is related to your
          full-swing setup, not something completely foreign and different.
          After all, I believe you should be making a real pivot in these
          swings—smaller than you'd make for a full shot, but still a noticeable
          pivot—so you should feel athletic.
        </div>
        <div class="p-mb-2">
          If you don't do anything to change your short-game setup, trust me on
          this one thing: Tension and stiffness is a feel killer. Feel is what
          lets you make sensitive, subtle judgments about how hard to hit a shot
          and make it go a certain distance. However you set up to the ball, if
          you get yourself into a rigid, stiff position, with your arms locked
          or the muscles in your legs tensed up, you're never going to have a
          good short game. Watch any athlete performing a “touch” maneuver—a
          basketball player shooting a free throw, a baseball player turning the
          double play at second base, a quarterback lofting a pass perfectly
          into a receiver's hands in the back corner of the end zone—and you
          won't see anything stiff or mechanical. All those moves are fluid and
          graceful. I want you to develop that same sense of fluidity and feel
          in your short game.
        </div>
        <div class="p-mb-2">
          For greenside shots, I narrow my stance, which makes it easy to rotate
          my lower body instead of rocking or swaying. As you'll read about in
          the chapters on chipping and pitching, I don't want you to feel like
          your lower body is encased in concrete. You need to make a pivot (not
          a sway) and a narrower stance helps that. What's the difference
          between a pivot and a sway? A sway is when the hips slide straight
          back away from the target, as if you were trying to avoid getting
          tagged in touch football. In a pivot, the hips turn instead of shift
          back, as they would if you were tossing something underhanded. If you
          look at the way the knees move, you can see the clear difference: In a
          sway, the knees stay in the same position relative to each other, but
          both move back, away from the target, and then back toward it. In a
          pivot, the right knee moves away from the target line, while the left
          moves toward it. If you picture a long metal bar superimposed over
          your spine and anchored in the ground, you pivot around the fixed bar.
          In a sway “ the knees stay in the same position relative to each
          other, but both move back, away from the target, and then back toward
          it. In a pivot, the right knee moves away from the target line, while
          the left moves toward it. If you picture a long metal bar superimposed
          over your spine and anchored in the ground, you pivot around the fixed
          bar. In a sway, the bar itself moves back and forth.
        </div>
        <div class="p-mb-2">
          In terms of aiming my body, I set up square to the target line or even
          slightly closed to it with my feet. In other words, if you held a
          shaft on my shoulders and another one on my hips, both shafts would
          point parallel to the target line (the line between the ball and where
          I want the ball to go), or slightly to the right of it. Many, many
          players are taught to set up open with the shoulders, hips, or both
          (so that the shafts would point to the left of the target). I don't
          know why you would want to compensate for where your body is aiming. I
          want to aim everything down the line I want my ball to travel on. I
          flare my toes out slightly to allow my hips more mobility, but I still
          keep my feet aligned square or slightly closed to the target line. The
          one thing to watch for when you flare your feet out is that you don't
          let it distort your sense of ball position. We'll cover more of this
          in chapters 4 and 5.
        </div>
        <div class="p-mb-2">
          One principle you're going to hear over and over again when I talk
          about chipping, pitching, and bunker play is that you need to keep
          your weight and spine tilt left throughout the swing. On most of my
          short shots, my weight starts out 65 to 70 percent on my left side,
          and that balance doesn't change throughout the swing. If you get your
          weight favoring the left like that, I'll bet you're going to tell me
          it feels like 90 percent of your weight is left. But that's only
          because so many players are taught to tilt back away from the target
          at address to get the ball up in the air.
        </div>
        <div class="p-mb-2">
          That tilt to the right is by far the most common mistake I see in the
          setup, and it has the most damaging consequences. With your right
          shoulder lower than your left, it feels like you get more loft on your
          shots, but it really just makes it easier for you to blade it or dig
          the club into the ground a couple inches behind the ball, especially
          if you're making common mistake number two, which is playing the ball
          too far back in the stance. People have been taught to make a
          descending blow on the ball, so it would seem that moving it back in
          the stance would help you hit it with a descending blow. True, but
          that's really doing it the hard way, and making it very difficult to
          make consistent contact. If you play the ball in the middle of your
          stance with your weight favoring your left instead of your right side,
          your club will be making contact with the ground at the lowest portion
          of the swing, versus coming in too steep.
        </div>
        <div class="p-mb-2">
          I'm not in favor of taking an open stance to hit your chip or pitch
          shots, because it encourages you take the club back outside the target
          line, away from your body. The only way to hit a good shot from there
          is to scoop with your hands or block it toward the target. I think
          people have been told it's easier to turn through and be in a good
          impact position if you set up open. I understand those thoughts. But
          my short shots don't require a lot of follow-through. My club is done
          two to three feet past the ball. I want to set up so that it's
          extremely easy to reach my backswing position, so I'm in a good
          position to start down. Just like putting, my backswing varies
          depending on how far I want to hit the shot, but my follow-through
          stays pretty similar, unless I need to hit a real high shot. By
          setting up square, it's much easier to get the club into an on-plane
          position in the backswing, so all I have to do is pivot through.
        </div>
        <div class="p-mb-2">
          I see so many players set up with the right shoulder low, the ball too
          far back and the stance open and aimed to the left. I'll even see some
          players get the ball too far forward in the stance, but it happens
          without them even knowing it. You can see this if you set up a shot
          with your left foot very open to the target line. Turn your foot back
          to square without moving your heel and you'll see what I mean. The
          ball will usually be an inch or two in front of your left foot (toward
          the target), way out in front, and in a difficult spot to hit
          consistently with good contact. My left toe might be slightly open
          when I set up to a shot, but my toe line—the imaginary line you draw
          in front of your feet—is definitely parallel to the target line or
          pointing slightly to the right of it.
        </div>
        <div class="p-mb-2">
          Another common mistake is starting with the shaft leaning too far
          forward—toward the target. That usually happens because of a
          combination of other mistakes. If your ball position is too far back
          and your stance is open, you pretty much have to have your hands
          forward to be able to hit the ball without crashing the club into the
          ground six inches behind the ball. The concept of de-lofting the club
          is good—it helps you play shots hitting the ball first, which is
          required for hitting low shots, as opposed to the ground. But to me,
          it puts the player in the opposite position to the one I like.
        </div>
        <div class="p-mb-2">
          Let me go into some detail to explain what I mean. Your golf swing
          happens on a plane. If you looked at it from behind, so you were
          looking toward the target, you could draw an angled line on the shaft
          of your club that would represent a plane. I basically try to swing my
          club in a circle and have the shaft remain on this angled line, or
          plane. As the club swings on the plane, it has two elements. One, it
          goes around, and the other, it goes up and down. If the around and up
          and down happen at the same pace, the shaft stays on-plane. The way I
          move the club with my hands, wrists, and arms affects the plane. Also,
          I can change the plane with the tilt of my spine, both up and down and
          side to side.
        </div>
        <div class="p-mb-2">
          Leaning your body and tilting to the left creates more of an
          up-and-down move in the swing, while leaning to the right creates more
          of an around move. When you pick up the clubhead with your hands and
          wrists early in the backswing, you create a lot of up and down. If you
          make a backswing by allowing the arms to rotate around the body with
          little wrist hinge, you create a more “around” swing. Most poor
          chippers and pitchers use the wrong combination of these elements.
          They lean the spine to the right (back away from the target) at
          address. This creates an around move, and they use their hands and
          wrists to lift the club on the backswing. They often tilt back more on
          the downswing and then have to use the wrists to bring the club down
          sharply back to the ball. This is why they hit it fat and thin.
        </div>
        <div class="p-mb-2">
          I teach my students to tilt their spine slightly toward the target at
          address and ask them to maintain that tilt through the swing. Leaning
          left helps you maintain the bottom of your swing in the same place all
          the time, without any need for manipulating the club with your hands.
          Swinging the club more around with your arms while tilting this way
          lets the clubhead swing along the ground longer, giving you more room
          to hit the ball and still make solid contact and come up with a
          reasonable shot.
        </div>
        <div class="p-mb-2">
          When you start out with the shaft leaning forward and the ball back,
          you pretty much have to chop down on the ball to hit the shot
          successfully. There are situations where chopping straight down on it
          is a good play—like when you have to blast the ball from some kind of
          divot. But for the most part, I think using that kind of technique for
          every chip leaves very little room for error. If you don't hit the
          ball first, you're going to skull it or stick the club in the ground.
          But by bringing the club in more shallow, you have more room to make a
          mistake without it turning into a horrible shot.
        </div>
      </div>
    </Panel>
    <Panel header="Movement" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          Before we get too carried away here (and before we get to the chapters
          that deal more specifically with each shot), let me tell you about how
          I define the different kinds of shots you hit around the green.
        </div>
        <div class="p-mb-2">
          You might hear a golf announcer talk about a chip shot or a pitch shot
          a player is facing, and either the difference between those shots
          isn't clear or else the announcer is using the terms interchangeably.
          Some people believe that the difference between a chip and a pitch has
          to do with how far away from the hole the shot is played from—a chip
          being something 5 or 10 yards off the green, and a pitch something
          from a longer distance. Other people believe that a chip rolls more
          than it flies, while a pitch flies more than it rolls.
        </div>
        <div class="p-mb-2">
          For me, the distinction is a lot simpler. In a chip shot, you're
          reducing the loft of the club through impact and hitting the ball
          first, and then the ground with the leading edge of the club. In a
          pitch shot, you're playing the shaft in a more neutral position and
          hitting the ground with the bounce on the bottom of the club, and then
          the ball. That's it. The difference is in the kind of impact you
          create. It doesn't have anything to do with distance. You can hit a
          long chip shot that's longer than a short pitch, and vice versa. And
          the flying-versus-rolling description doesn't really work, either,
          because of the variety of the different green conditions you face. You
          might have to bump a chip shot into the face of a hill and have it
          just trickle up to the flag, and you might hit a long pitch to a flag
          that's below you, so it runs out more.
        </div>
        <div class="p-mb-2">
          But in reality, the distinction is academic, because what I'm going to
          teach you for both kinds of shots is pretty similar. The only changes
          are what you do with your left wrist. The left wrist is what really
          controls the clubface, and “therefore the shot. You're maintaining a
          consistent setup and making a shallow swing. That leads to consistent
          impact. Learning how to vary the shot comes from understanding how the
          left wrist works. We're going to talk about those differences in the
          left wrist, and how you can use them to pick the perfect shot for each
          situation.
        </div>
        <div class="p-mb-2">
          Aside from the differences in the left-wrist position, my body moves
          the same way for both shots. I pivot back with my knees and hips as I
          set the club with my wrists and right elbow. When I turn through the
          ball, I lead with my lower-body pivot. What I'm doing is starting with
          my lower-body pivot, and everything above my waist follows along
          behind. It's a chain reaction all the way to my shoulders. I want my
          shoulders and club lagging so that they come to impact from the inside
          of the target line (or in other words, down the swing plane).
        </div>
        <div class="p-mb-2">
          The first time I explain this to people, they often make the mistake
          of simply pivoting their upper body from the top of the backswing. The
          lower body stays really passive. If you do that—just turn your
          shoulders back and through, without any lower-body pivot—you're going
          to come around over the top and hit from the outside of the target
          line. It's the same flaw a lot of players have in the full swing.
          They've never felt how you lead the lower body and lag the upper body.
          At impact, my thighs and hips are slightly open to the target, but my
          shoulders are square. I don't want my shoulders way open at impact,
          which they would be if I was just turning back and forth with my upper
          body.
        </div>
        <div class="p-mb-2">
          Once you get the feel for activating your lower body back and through,
          some exciting things will start to happen. First, the chain reaction
          of your lower body working with your lagging upper body will help you
          hit the ball much farther with much less effort and arm swing. Your
          impact condition—how “pure” and cleanly you hit the ball—will improve
          dramatically, and when you start to sense that the ball is going a
          consistent distance with a given amount of effort, you're going to get
          really good, really fast, at judging how hard to hit a chip or a
          pitch. And that's without any real conscious effort to “read”
          distance. Players ask me all the time how I know how to hit a pitch
          shot 15 yards as opposed to 19 yards. It's a feel, not some kind of
          calculation. Once your impact starts getting clean time after time,
          you'll know how hard you need to swing to hit it 15 yards. Do you
          think about how hard to toss a “ball of paper into a trash can? Or how
          much force you need to use to lift a glass of water? Your brain is
          pretty smart, and it's wired to figure these things out automatically.
          You just have to let it. Consistent and efficient mechanics make that
          much easier to do.
        </div>
        <div class="p-mb-2">
          One other piece of the short-game puzzle is the same for every shot
          you hit—and it's just as important as the mechanics we've been talking
          about. It's your strategy. It's how you think of the shot you're going
          to hit, not just how you execute it. It's easy to make fun of
          “visualization” or any of those other fuzzy words teachers like to
          talk about. But I'm here to tell you that you can get significantly
          better with your short game by having a consistent process you use for
          every shot. Even if you don't hit your shots particularly well, you'll
          have a better sense for what's about to happen and how to prepare for
          it.
        </div>
        <div class="p-mb-2">
          I've had conversations with hundreds of amateur players about what
          they're thinking before they hit a short-game shot. I wish I could say
          that most of them were actually thinking the wrong thing. It would
          mean that they had some kind of decision-making process that they were
          using, even if it was one I didn't really love. But most average
          players are standing up there without thinking about much at all.
          Usually, it's some version of “I hope I can just get up there on the
          green somewhere.” I'm not a big fan of being obsessed with mechanics
          when you're standing over a real shot during a real round of golf, but
          even thinking about mechanics would be more productive and positive
          than thinking “I just hope I can get it up there somewhere.”
        </div>
        <div class="p-mb-2">
          Without going into the actual mechanical preparation steps I take
          before every shot just yet, let me tell you about how I think about
          each short-game shot I hit. The first thing I do when I walk up to the
          ball near the green is to run a checklist of all the possibilities
          that the lie and the shot give me. Can I hit it high or low? Do I have
          to carry some kind of trouble in front of me, or some kind of tier on
          the green? How is my ball going to behave when it hits the green? Once
          I've got an idea of the options, I pick the one that I think is the
          best choice for the situation. Now, this is where the train goes off
          the tracks for a lot of players. You need to feel comfortable with the
          choice you make. Just because a situation dictates some kind of high
          flop shot, if you need to make a bogey to win your hole and you're
          more comfortable hitting a lower-running chip to 10 feet from the
          hole, you should be picking the shot that makes you comfortable.
        </div>
        <div class="p-mb-2">
          Once I pick my shot, I get totally into hitting that particular shot.
          I know that's easy for me to say, but there's really no other way to
          put it. You have to put the decision-making process behind you, stop
          second-guessing at that point, and feel confident about the shot
          you're going to hit. Once you read the next few chapters on how to hit
          those shots, this is going to be easier to do, trust me.
        </div>
        <div class="p-mb-2">
          When I'm making two or three brisk practice swings, I'm doing two
          things. First, I'm visualizing the actual flight of the shot I'm about
          to hit. If it's a chip shot, I'm picturing the ball hitting and
          rolling right up to the hole. If it's pitch, I'm watching it fly up on
          the green and roll out, too. I'm also equating what I'm visualizing
          with the practice swings I'm making, to help me get a feel for how
          hard I want to swing on my real shot. Before I implemented a
          purposeful pre-shot routine, I know I used to leave this step out some
          of the time. Getting a picture of what you want to do in your head
          before you try to do it is one of the most effective ways I've found
          of focusing before hitting a shot.
        </div>
        <div class="p-mb-2">
          Once I make my practice swings and visualize the shot, I move right in
          and hit it, without fooling around or freezing over it. When you take
          extra time between your practice swings and hitting the real shot,
          only bad things can happen. First, if you're under pressure at all,
          that anxiety just builds and makes it less likely that you're going to
          execute your mechanics smoothly. On an even more basic level, the
          longer you take between your practice swings and visualization and the
          actual swing itself, the more separation you get between your feel and
          your actual swing. As I've said time and time again, your brain is
          really smart, and it wants to help you. If you go through the trouble
          to visualize the shot you're trying to hit, don't waste that effort by
          “forgetting” the visualization by spending a lot of time fidgeting
          around (or freezing) over the ball before you pull the trigger. I'm a
          big believer in the idea that your athletic instincts are good ones,
          and you should trust them.
        </div>
        <div class="p-mb-2">
          Of course, it helps to have the right clubs—and the right mechanics—to
          go with those athletic instincts we all have. Let's get those things
          in order now.
        </div>
      </div>
    </Panel>
  </Panel>
</template>
