<template>
  <Panel
    header="Check for somatic anxiety"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">Body Checks</div>
      <div class="p-mb-2">
        Take a few moments several times a day to scan your body for tension.
        Remain particularly aware of locations where you are most likely to feel
        tension, such as the shoulder, neck, abdomen, jaw, face, back. you also
        can scan for some of the physical signs mentioned in anxiety arousal,
        such as drawn shoulders, clenched fists, tight face, or furrowed brow.
      </div>
      <div class="p-mb-2">
        On the golf course, the best times to scan your body are on the first
        tee, as you approach particularly difficult shots, around the greens, or
        anytime you are in a situation that is challenging to you.
      </div>
    </div>
  </Panel>
  <Panel header="Diaphragmatic Breathing" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Diaphragmatic breathing increases O2 reduces CO2. It initiates a
        ‘diaphragmatic reflex’ which relaxes muscles, produces calm and slows
        down thinking.
      </div>
      <div class="p-mb-2">
        Once you have identified areas of tension, stretch and relax these
        areas. Next, take some deep "diaphragmatic" breaths. A diaphragmatic
        breath is drawn from the abdominal areas, as opposed to a "thoracic"
        breath, which is drawn from the chest. Thoracic breathing is most common
        when we are over aroused. As our body tightens our breathing gets
        shallower and more rapid, and our heart rate increases to overcome the
        reduction in oxygen. This triggers our stress response. Diaphragmatic
        breathing, on the other hand, comes naturally to us in our relaxed
        states-it's how we breathe when we sleep. Air is pulled deep into the
        lungs as the diaphragm contracts and is exhaled as it relaxes. This is
        said to trigger our relaxation response.
      </div>
      <div class="p-mb-2">
        Many golfers breathe deeply before or during their pre shot routines,
        especially on days when tension is a persistent challenge. Those with
        putting problems should consciously take a full, deep breath the moment
        their feet make contact with the putting surface. Becoming more aware of
        your breathing and learning to use more diaphragmatic breaths are among
        the easiest and most effective techniques you can use to reduce your
        arousal both on and off the course.
      </div>
    </div>
  </Panel>
  <Panel header="Body Language" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Mimicking the body language of success or confidence results in somatic
        and kinesthetic afferents that feed up into the limbic lobe where they
        entrain the above emotions. This process reduces others emotions present
        - anxiety, fear, excitement and so on.
      </div>
      <div class="p-mb-2">
        Walk and talk with confidence. Any dysphoric emotions are to be replaced
        with walking and talking with confidence, including keeping shoulders
        back, chin up, and moving to your natural rhythm with arms swinging.
      </div>
      <div class="p-mb-2">
        Remove head down, torso bent forward, grimace on face, arms held tight
        to side, fists clenched. These will result in somatic and kinesthetic
        afferents that entrain anxiety and fear in the limbic system.
      </div>
    </div>
  </Panel>
  <Panel
    header="Progressive Muscular Relaxation"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Players who experience chronic tension in their bodies find that
        incorporating some method of relaxation each day really helps them
        manage their arousal and their health. There are several ways to go
        about this:
      </div>
      <div class="p-mb-2">
        Systematic contraction then relaxation of all muscle groups in body.
        Hold contraction for five seconds, feel the tension in the area, then
        relax, feel the relaxation in the area. Focus your attention on the
        following areas, being careful to leave the rest of your body relaxed.
        Concentrate on, and learn to recognize what each group feels like when
        it is tense and when it is relaxed. This is just as important as being
        able to do the contraction-relaxion of each group. Ideally do some
        abdominal breathing to relax mind before starting
      </div>
      <div class="p-mb-2">
        * Hands: clench fingers into tight fist then relax * Wrists and
        Forearms: Extend forearm and bend wrist (palmer- and dorsi-flexion) *
        Biceps and upper arms: Clench fingers into fist, flex elbow and contract
        biceps. * Shoulders: Raise toward ear * Forehead: Wrinkle into deep
        frown, then raise eyebrows as far as possible * Eyes: Close eyelids
        tightly * Cheeks: Smile as widely as possible * Jaw: Open mouth very
        wide. Then clench jaw * Lips: Press together tightly (no tension in rest
        of face - just lips) * Neck-Extension: Press back of head as far
        back/down as possible (relax jaw) * Neck-Flexion: Touch chin to chest
        (relax head and back of neck) * Chest: Deep abdominal breath and hold *
        Back: (extension) Arch entire back, head rest on back, and push scapula
        toward each other * Back: (flexion) Flex entire back, chin rest on
        chest, and pull scapula away from each other * Stomach: Suck in * Hips &
        Buttocks: Press buttocks together tightly * Thighs: Clench together
        tightly * Lower legs: Point feet & toes toward face, then point feet
        away and curl toes under
      </div>
      <div class="p-mb-2">Hands: clench fingers into tight fist then relax</div>
      <div class="p-mb-2">
        Wrists and Forearms: Extend forearm and bend wrist (palmer- and
        dorsi-flexion)
      </div>
      <div class="p-mb-2">
        Biceps and upper arms: Clench fingers into fist, flex elbow and contract
        biceps.
      </div>
      <div class="p-mb-2">Shoulders: Raise toward ear</div>
      <div class="p-mb-2">
        Forehead: Wrinkle into deep frown, then raise eyebrows as far as
        possible
      </div>
      <div class="p-mb-2">Eyes: Close eyelids tightly</div>
      <div class="p-mb-2">Cheeks: Smile as widely as possible</div>
      <div class="p-mb-2">Jaw: Open mouth very wide. Then clench jaw</div>
      <div class="p-mb-2">
        Lips: Press together tightly (no tension in rest of face - just lips)
      </div>
      <div class="p-mb-2">
        Neck-Extension: Press back of head as far back/down as possible (relax
        jaw)
      </div>
      <div class="p-mb-2">
        Neck-Flexion: Touch chin to chest (relax head and back of neck)
      </div>
      <div class="p-mb-2">Chest: Deep abdominal breath and hold</div>
      <div class="p-mb-2">
        Back: (extension) Arch entire back, head rest on back, and push scapula
        toward each other
      </div>
      <div class="p-mb-2">
        Back: (flexion) Flex entire back, chin rest on chest, and pull scapula
        away from each other
      </div>
      <div class="p-mb-2">Stomach: Suck in</div>
      <div class="p-mb-2">Hips & Buttocks: Press buttocks together tightly</div>
      <div class="p-mb-2">Thighs: Clench together tightly</div>
      <div class="p-mb-2">
        Lower legs: Point feet & toes toward face, then point feet away and curl
        toes under foot
      </div>
    </div>
    <Panel header="PMR again" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">Progressive Muscle Relaxation Steps:</div>
        <div class="p-mb-2">
          Find a quiet place free from distractions. Lie on the floor or recline
          in a chair, loosen any tight clothing, and remove glasses or contacts.
          Rest your hands in your lap or on the arms of the chair. Take a few
          slow even breaths. If you have not already, spend a few minutes
          practicing diaphragmatic breathing.
        </div>
        <div class="p-mb-2">
          Now, focus your attention on the following areas, being careful to
          leave the rest of your body relaxed.
        </div>
        <div class="p-mb-2">
          Forehead: Squeeze the muscles in your forehead, holding for 15
          seconds. Feel the muscles becoming tighter and tenser. Then, slowly
          release the tension in your forehead while counting for 30 seconds.
          Notice the difference in how your muscles feel as you relax. Continue
          to release the tension until your forehead feels completely relaxed.
          Breathe slowly and evenly.
        </div>
        <div class="p-mb-2">
          Jaw: Tense the muscles in your jaw, holding for 15 seconds. Then
          release the tension slowly while counting for 30 seconds. Notice the
          feeling of relaxation and continue to breathe slowly and evenly.
        </div>
        <div class="p-mb-2">
          Neck and shoulders: Increase tension in your neck and shoulders by
          raising your shoulders up toward your ears and hold for 15 seconds.
          Slowly release the tension as you count for 30 seconds. Notice the
          tension melting away.
        </div>
        <div class="p-mb-2">
          Arms and hands: Slowly draw both hands into fists. Pull your fists
          into your chest and hold for 15 seconds, squeezing as tight as you
          can. Then slowly release while you count for 30 seconds. Notice the
          feeling of relaxation.
        </div>
        <div class="p-mb-2">
          Buttocks: Slowly increase tension in your buttocks over 15 seconds.
          Then, slowly release the tension over 30 seconds. Notice the tension
          melting away. Continue to breathe slowly and evenly.
        </div>
        <div class="p-mb-2">
          Legs: Slowly increase the tension in your quadriceps and calves over
          15 seconds. Squeeze the muscles as hard as you can. Then gently
          release the tension over 30 seconds. Notice the tension melting away
          and the feeling of relaxation that is left.
        </div>
        <div class="p-mb-2">
          Feet: Slowly increase the tension in your feet and toes. Tighten the
          muscles as much as you can. Then slowly release the tension while you
          count for 30 seconds. Notice all the tension melting away. Continue
          breathing slowly and evenly.
        </div>
        <div class="p-mb-2">
          Enjoy the feeling of relaxation sweeping through your body. Continue
          to breathe slowly and evenly.
        </div>
      </div>
    </Panel>
  </Panel>
  <Panel
    header="Other Relaxation Techniques"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Players who experience chronic tension in their bodies find that
        incorporating some method of relaxation each day really helps them
        manage their arousal and their health. There are several ways to go
        about this:
      </div>
    </div>
    <Panel header="Yoga" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          Yoga: With its meditation, breathing, physical postures, and
          exercises, yoga has long been used not only to increase flexibility
          and muscle tone but also as a stress reducer. Studies have found that
          doing yoga exercises each day can lower blood pressure and heart rate,
          reduce pain, and improve memory, motor skills, metabolic rates, and
          respiratory function.
        </div>
      </div>
    </Panel>
    <Panel header="Massage and Stretching" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          Therapeutic massage and stretching: Massage can have a sedative effect
          on the nervous system as well as promote muscle relaxation. Various
          types of stretching exercises, particularly those recommended for
          golfers, are also helpful.
        </div>
      </div>
    </Panel>
    <Panel header="Biofeedback" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          Biofeedback: Since it is important to maintain a relaxed heart rate
          during a round of golf, here’s an example of a technique we use to
          achieve that goal: First practice lowering your heart rate during a
          practice session by taking a moment to take a deep breath, relax your
          body, and imagine yourself lying in bed, just about to fall asleep.
          Having done that, record your pulse again. Repeat until you have
          successfully lowered the rate. Now imagine going to the first tee at
          Augusta National to play the first shot of the final round of the
          Masters. If your imagery is good, your pulse will be higher. Now relax
          by using your imagery of falling asleep and check your pulse again.
          With practice you will be able to use this or other relaxing imagery
          of your choice to lower your heart rate and arousal before and during
          a competitive round.
        </div>
      </div>
    </Panel>
  </Panel>
</template>
