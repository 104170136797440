<template>
  <Panel
    header="Focused  Internal awareness"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2"></div>
      <div class="p-mb-2">
        Thought checks involve taking a few conscious moments to assess the
        quality of your thoughts. As a general rule, when you are feeling
        unsettled or tense, you are likely wasting mental energy in
        non-productive areas, especially worrying about things that are out of
        your control. You should check your thoughts to see if they are
        productive and positive.
      </div>
      <div class="p-mb-2">
        For example, before going into a round, are you thinking productively of
        things over which you have control, such as focusing on ideal targets,
        types of shots you want and feel you can hit, and strategies for
        handling adversity? Or are your thoughts unproductive and stressful,
        such as worrying about something you cannot change or that has not yet
        happened? Are you worrying about how difficult the course is playing,
        how hard the wind might blow, how low you need to shoot, or how well
        your opponents might play?
      </div>
      <div class="p-mb-2">
        Before you start your round, make a list of items over which you have
        control that truly deserve your mental energies that day, such as
        committing to targets, visualizing all shots, feeling great tempo, deep
        breathing, taking mental breaks between shots, or using positive
        self-talk. Anytime.you find yourself feeling over aroused or unsettled
        during the round, check your thoughts and replace them with something
        from your list that offers you more control and productive use of your
        mental energies.
      </div>
    </div>
  </Panel>
</template>
