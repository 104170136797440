<template>
  <div class="fontSmall taLeft">
    <div class="p-mb-2"></div>
    <div class="p-mb-2">
      St. Andrews is a tiny university town of somber stone buildings that
      clings to the east coast of Scotland with a fierceness that mirrors the
      pride of the people who created and have defended a culture there for more
      than a thousand years. Of the many roles the Old Grey Toon plays in
      history, none surpasses its place in golf. While there may be disagreement
      as to where the first golf ball was struck, there is no doubt that St.
      Andrews is where the game was formalized. The first rules were written
      there in 1754, three centuries after King James II banned golf because it
      distracted men from archery practice, a skill needed to defend the
      kingdom. Without dispute, the Old Course at St. Andrews is the Home of
      Golf.
    </div>
    <div class="p-mb-2">
      So it was that the 2007 Ricoh Women’s British Open was a celebration. For
      the first time, women professionals would compete on the Old Course and
      have access to the clubhouse of the male-only Royal & Ancient Golf Club.
      The streets of St. Andrews were packed that week. Tables at restaurants
      were difficult to find, and making your way to the bar to get a pint at
      the Dunvegan Hotel took a wee bit of work.
    </div>
    <div class="p-mb-2">
      The pros virtually floated above the cobblestones, made weightless by the
      thrill of participating in history. Everyone wanted to be the first woman
      to win at St. Andrews, a place where Mary Queen of Scots was said to have
      played golf in the 1500s. Among the star-studded field was
      twenty-two-year-old Ai Miyazato, who had already won more than a dozen
      tournaments in her native Japan but was looking for her first triumph on
      the LPGA Tour.
    </div>
    <div class="p-mb-2">
      Miyazato had joined the LPGA a year earlier, arriving in America
      accompanied not only by the high hopes of her country, but also by a media
      contingent numbering in the dozens that reported on her every
      round—virtually on her every shot. The expectations were that Ai would
      conquer America just as she had Japan. But while aspiration provides the
      vision upon which the road map for success is drawn, unreasonable
      expectations can saddle the individual with excess emotional baggage that
      bogs down the journey.
    </div>
    <div class="p-mb-2">
      Miyazato had, by any reasonable measuring stick, a very successful rookie
      LPGA season in 2006, finishing number twenty-two on the season-ending
      money list. But more was expected. The question began to be whispered:
      “What’s wrong with Ai?” More disturbingly, some began to question whether
      she was good enough to ever win an LPGA tournament. The tempo of Ai’s
      game—of her life—had been thrown out of whack by external forces.
    </div>
    <div class="p-mb-2">
      By the time Miyazato got to St. Andrews, she was midway through another
      winless season, but was feeling good about her game. She had high hopes,
      especially after an opening round 70 left her just three strokes off the
      lead. Then eighteen months of pressure came crashing down upon her. An 80
      in the second round followed by 77 and 79 on the weekend caused Ai to
      tumble to a tie for fifty-eighth place. She left St. Andrews in tears,
      breaking down during questioning by the Japanese media. A downward spiral
      began in which she would fail to finish her next five tournaments and
      finish fiftyseventh or worse seven times in her next eight starts.
    </div>
    <div class="p-mb-2">
      During that difficult week at St. Andrews, Ai’s father, Masuru Miyazato,
      who had quite successfully coached Ai and her two brothers, both also
      professionals, asked if we would formally get involved in Ai’s coaching.
      She knew about VISION54. In fact, Ai wrote the foreword to the Japanese
      version of Every Shot Must Have a Purpose, our first book. We had been
      watching her for some time, and we knew that the technical problems she
      was having were tied to the pressure on her to win.
    </div>
    <div class="p-mb-2">
      One trademark of Ai’s game is her smooth, consistent tempo. We have seen
      people literally gasp in admiration when they first see her graceful pass
      at the golf ball. Ai’s swing is slow, remarkably fluid and never finishes
      out of balance—pure poetry in motion. That even-keel approach also
      characterizes her demeanor. Her emotional tempo is as consistent as her
      swing. Ai reminded us of one of our earlier students, Annika Sorenstam.
      Physically and emotionally she was always under control. That control—both
      of the swing and of her emotions—had gotten lost.
    </div>
    <div class="p-mb-2">
      Because of the enormous expectations, Ai had lost her tempo. She wasn’t
      any less talented; it’s just that the components of golf had come unstuck
      in the confusion and doubt around her. Ai had lost contact with her own
      uniqueness within the game. And part of what happens when you become
      unstuck is that you lose perspective; every problem becomes magnified in
      your mind and every obstacle thrown in your path appears to be twice as
      high as it really is. The tempo of your life is lost. You are out of
      rhythm.
    </div>
    <div class="p-mb-2">
      Among the reasons why there is no such thing as a onesize-fits-all golf
      swing is that we all feel the rhythms of life differently. We all hear a
      different tune and we all feel a different beat. Tempo is not something
      that can be explained to you, it is something you have to experience; it
      is something you have to feel. But tempo is also something you can learn
      to control. What happened with Ai was that she had let outside forces
      distract “her from her own, true tempo. She had let the expectations of
      others disrupt the rhythm of her life—and her golf game.
    </div>
    <div class="p-mb-2">
      On the golf course I used to be so affected by how others were doing,” Ai
      says, looking back at that down period. “In my personal life too I get
      influenced often by other people.VISION54 has taught me what my strengths
      and assets are and to be confident about them. I’ve learned from VISION54
      that I need to imagine myself winning a tournament and playing well but
      doing so in as much detail as possible—what I see, what I feel, what I
      hear when I am playing great golf. And the more I practice this, the more
      I can connect myself with the golfer I aspire to be in a real-life,
      pressure-packed situation.” In short, she was rediscovering her tempo—the
      rhythm that works for her.
    </div>
    <div class="p-mb-2">
      Breakthroughs are always twofold: First there is the realization and then
      there is the actualization. Ai came to grasp that she had lost her way in
      the game by surrendering the rhythm and tempo of her life and game to the
      expectations of others. Once she realized this, she was ready to commit to
      several VISION54 disciplines that would help her address the
      manifestations of her “slump,” for lack of a better word to mean merely
      temporarily losing your way in the game.
    </div>
    <div class="p-mb-2">
      When I lost my tempo [in 2007], I could only think of hitting as many
      balls as I could and working on my mechanics,” Ai says. “But VISION54 gave
      me a variety of practice options to get it back. For example, swinging
      with varied tempo. I would start by hitting a shot at twenty-five percent
      of my normal tempo, and then fifty percent, and then seventy-five percent
      and finally my full tempo. I would swing with my eyes closed and do the
      Tai Chi swing, in which I would take about two minutes to complete one
      slow-motion swing. In this way, I found my tempo, the tempo that works for
      me.
    </div>
    <div class="p-mb-2">
      How different is this approach from the scrape-and-hit practice of
      mindlessly whacking balls, which many employ on the range? As Ai explored
      these VISION54 exercises, she not only rediscovered her swing, she gained
      a greater understanding of the forces that affect her on the golf course
      and disrupt her tempo. And as that understanding deepened, she was able to
      implement VISION54 techniques to help her maintain her tempo within a
      round of golf and under competitive pressure.
    </div>
    <div class="p-mb-2">
      “I feel that tempo and tension are things that are always connected,” Ai
      says. “During a round, tension can make your tempo quick. When that
      happens, I try to first focus on my tension to get my tempo back to where
      it should be.” Off the course, Ai works on improving her tempo by closing
      her eyes and trying to focus her attention inward, and even hitting some
      shots on the range with her eyes closed. She hits shots at varying tempos
      with different clubs as she tries to feel her tempo so she can dial it in
      on the golf course.
    </div>
    <div class="p-mb-2">
      There are many ways to get this feel. Kevin Streelman, a PGA Tour player
      we are working with, will hit shots with different tempos in practice and
      then check the facts on his TrackMan, a golf radar that measures spin
      rate, club speed, launch angle and much more. Kevin found that dropping
      his tempo down a notch made the launch conditions of his shots better.
      He’s the kind of person who likes to get scientific confirmation of the
      inner art of sensing tempo. For the elite player, tempo awareness is a
      great skill for controlling trajectories and spin rate. With slower tempo,
      the spin rate and the trajectory of the ball will be lower. Increase the
      tempo and they get higher.
    </div>
    <div class="p-mb-2">
      While everyone has his or her own unique tempo that works best for that
      player, it is really more like a unique range of rhythms in which each
      player best functions. Tempo is something you borrow rather than own. It
      is constantly in motion and changes depending on how your body and swing
      feel on any particular day. When it’s cold and windy, many swing too fast.
      In really hot and humid weather, some become sluggish. Self-talk can also
      affect tempo, as can your emotional resilience. Under tournament
      conditions, you might get an adrenaline rush or be bothered by the
      attention of the gallery. All these can affect tempo.
    </div>
    <div class="p-mb-2">
      But no matter what is making your tempo fluctuate, the player who learns
      to feel that fluctuation has the ability to adjust it during the round and
      get back on track.You can get into the rhythm of this particular day. The
      key is to discover your tempo, become friends with it, learn how to feel
      it, always nurture that ability and realize that your ideal tempo changes
      from day to day within a comfort zone that works best for you, and even
      during a round.
    </div>
    <div class="p-mb-2">
      Your tempo is your tempo. It defines you. More accurately, you define it.
      Your personality may demand a quick tempo or a slow tempo or any speed in
      between. Tom Watson’s tempo is very different from that of Ernie Els;Yani
      Tseng’s tempo is very different from Ai’s. They have all recognized what
      works for them. What tempo produces your best sequence of motion ?
      Learning the feel of your perfect tempo is a discovery process.
    </div>
    <div class="p-mb-2">
      Studies show that great ball strikers have the same sequence of motion at
      impact, meaning that the hips, upper body, arms and club reach the impact
      area one after the other and in that order. Many golfers find their most
      efficient sequence of motion, without having to change their body or
      swing, by exploring different tempos. We have found that many golfers who
      are restricted in their hips or shoulders start to strike the ball better
      if they downshift to 75 percent or 80 percent of full tempo. That is
      something you will find out through experimentation. Perhaps you can make
      a more functional golf swing and hit the ball farther with slower tempo.
    </div>
    <div class="p-mb-2">
      But the first task for you is to be able to recognize the feel of
      different tempos. Can you sense the difference between your full-tempo
      swing, three-quarter-tempo swing, half-tempo swing and one-quarter-tempo
      swing? Players lose their tempo and realize it after the round. They will
      say, “Man, I just got really quick out there.” But why don’t you know that
      and stop that when it is happening? Well, you can if you are able to feel
      your tempo. That’s a skill you can learn.
    </div>
    <div class="p-mb-2">
      The important thing about practicing tempo is that as you become more
      familiar with what tempo works for you, you will become better when you
      are getting out of whack at feeling it and stop it. Understanding
      tempo—feeling it—allows you to be better friends with your golf swing. You
      can’t take a metronome out on the course with you, but you can internalize
      tempo so you feel the rhythm that works best for you.
    </div>
    <div class="p-mb-2">
      Many players benefit greatly from doing a few tempo exercises in warm-ups.
      It helps wake up the life of the swing and it monitors what tempo feels
      good for that particular day. We said in the Play Box chapter (“Leave Your
      Mind Behind”) that Play Box mastery comes before all the other Essentials.
      Before you can access any mastery of tempo, you need first to have the
      skill of being present. If you are not present, you can’t sense the
      first-person experience of tempo.
    </div>
    <div class="p-mb-2">
      As Ai grew to better understand the physical impact that tension has upon
      her game, she also became more aware of some of the mental gymnastics to
      “which she was victim. Chief among those was the role self-talk played in
      disrupting her tempo. “Self-talk” is that voice in your head that can
      exhort you on to great accomplishments, but can also plant the seeds of
      doubt that undermine success. It’s a concept we explore more fully in
      Chapter 9, where we provide tools for managing it. But it is very
      instructive to take a look now at how that little voice disrupted Ai’s
      tempo.
    </div>
    <div class="p-mb-2">
      When things are going well, [that voice] can make good things happen
      naturally,” Ai says. “But when things are not going well, it can make
      everything go in the wrong direction, so it is scary. My self-talk is
      something that only goes on inside of me, so if I don’t treat it
      correctly, it can get uncontrollable.
    </div>
    <div class="p-mb-2">
      What Ai has come to understand is that the inner voice is not something to
      be afraid of. You own that voice. It is part of who you are. You can
      reason with it, argue with it, silence it and change its mind. “It’s very
      simple, but VISION54 has taught me the importance of self-talk,” says Ai.
      “It used to be something that ‘happened’ depending on the mood I’m in. But
      now I can recognize my self-talk and change it if I need to. I can stop it
      from changing my tempo.
    </div>
    <div class="p-mb-2">
      The techniques Ai has learned to control self-talk are both unique and
      universal. They are unique because she has adopted practices that address
      her specific experiences with self-talk. They are universal because we all
      have that voice inside us telling us to be afraid or preparing us for
      failure by reminding us of past mistakes. It is up to us to tell the voice
      that the past does not have to be the future.
    </div>
    <div class="p-mb-2">
      “I’ve gotten to analyze my self-talk depending on the type of emotion I’m
      feeling,” Ai says. “Because of that, I can be in a certain situation on
      the course, be able to analyze my self-talk, and stay calm. I try not to
      make my self-talk too exaggerated. I don’t tell myself, ‘OK, you’re going
      to birdie the next hole,’ because that kind of talk can put pressure on
      me. Instead, if I’m in a situation where I am irritated, I tell myself,
      ‘OK, Ai, talk to your caddie.’ Or when my habit of going into the future
      happens, I try to laugh it off by saying, ‘Are you going into the future
      again?’ and make it humorous.
    </div>
    <div class="p-mb-2">
      Working with Ai’s father, Masuru, we helped Ai back from that difficult
      stretch after the 2007 Women’s British Open at St. Andrews and through a
      2008 season that had more disappointments than it had successes. As we
      entered the 2009 season, all of the components of Ai’s game began to mesh.
      And again, by “components” we are talking about not just the physical
      activity of playing golf but also that big-picture approach entailing the
      whole person. Ai had found herself and was no longer a fractured golfer.
      She had put the pieces back together.
    </div>
    <div class="p-mb-2">
      In July, Ai finished sixth in the 2009 U.S. Women’s Open and then got her
      first LPGA victory in the next tournament, the Evian Masters in France.
      Over the next thirteen months, she won five more times on the LPGA Tour.
      Clearly, a crucial corner had been turned. She had silenced not only the
      doubts of those around her, but also the negative thoughts that sometimes
      shouted within her. What Ai found in VISION54 was not an external
      philosophy she applied but rather an internal understanding of who she is,
      which she employed on the golf course to maintain her tempo and maximize
      her potential.
    </div>
    <div class="p-mb-2">
      When Ai played in Japan, she knew who she was. She was in touch with her
      tempo and rhythm. She was in sync. She lost that identity when she came to
      play in the United States, but got it back through VISION54. “Lynn and Pia
      have always stressed about ‘Ai54’ and its journey and how to create it,”
      she says. “They have never compared me to anyone else. When I was in
      Japan, I created an Ai Miyazato that I wanted to be, but through VISION54,
      I feel more confident about myself and am more willing to present to
      everyone my true self.
    </div>
    <div class="p-mb-2">
      And it is only by recognizing and realizing your own true self that you
      are able to achieve success. For Ai, regaining her tempo—both physically
      and emotionally—was a matter of reclaiming the control she had surrendered
      to the expectations of others. By recognizing the role of self-talk, she
      was able to gain greater control over it, and that helped her regain the
      tempo of her swing.
    </div>
  </div>
</template>
