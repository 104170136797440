<template>
  <div class="p-d-flex-column">
    <Panel
      header="Skill 1: Starting ball On Line"
      :toggleable="true"
      :collapsed="true"
    >
      <Panel header="Physics" :toggleable="true" :collapsed="true">
        <skill1OnLinePreStroke />
      </Panel>
      <Panel
        header="Technique: Foundations"
        :toggleable="true"
        :collapsed="true"
      >
        <skill1OnLineInStroke />
      </Panel>
    </Panel>
    <Panel
      header="Skill 2: Distance Control"
      :toggleable="true"
      :collapsed="true"
    >
      <skill3TouchFeel />
    </Panel>
    <Panel header="Skill 3: Green-Reading" :toggleable="true" :collapsed="true">
      <skill2GreenReading />
    </Panel>

    <Panel
      header="Skill 4: Mental 'It' factor"
      :toggleable="true"
      :collapsed="true"
    >
      <skill4Mental />
    </Panel>
    <Panel header="Faults and Fixes" :toggleable="true" :collapsed="true">
      <sieckFaultsFixes />
    </Panel>
  </div>
</template>

<script>
import skill1OnLinePreStroke from "@/components/sieckmann1Putting/skill1OnLinePreStroke";
import skill1OnLineInStroke from "@/components/sieckmann1Putting/skill1OnLineInStroke";
import skill2GreenReading from "@/components/sieckmann1Putting/skill2GreenReading";
import skill3TouchFeel from "@/components/sieckmann1Putting/skill3TouchFeel";
import skill4Mental from "@/components/sieckmann1Putting/skill4Mental";
import sieckFaultsFixes from "@/components/sieckmann1Putting/sieckFaultsFixes";

export default {
  name: "SieckmannPutting",
  components: {
    skill1OnLinePreStroke,
    skill1OnLineInStroke,
    skill2GreenReading,
    skill3TouchFeel,
    skill4Mental,
    sieckFaultsFixes,
  },
};
</script>

<style lang="scss">
// GENERAL STYLES
.accent1 {
  font-weight: bold;
}
.accent2 {
  color: red;
  font-weight: bold;
}
.accent3 {
  color: red;
}
.fontSmall {
  font-size: small;
}
.fontSmaller {
  font-size: smaller;
}
.fontMedium {
  font-size: medium;
}
.fontLarge {
  font-size: large;
}
.taLeft {
  text-align: left;
  padding: 0 0.25em 0 0.25em;
}
</style>
<span class="accent1"></span>
<img src="@/assets/images/hardy1/hardyFinish1.png" alt="Hardy Posture" />
<div class="accent1 fontSmall p-mb-2"></div>
