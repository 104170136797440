<template>
  <div class="fontSmall taLeft">
    <img src="@/assets/images/hardy2/fig9.23.png" class="img10" alt="Alt" />
    <div class="accent1 fontSmall p-mb-2">
      FIG. 9.23. To analyze the RIT release you need to draw two plane lines at
      address. One line the shaft plane line, is drawn from the heel of the club
      straight up the shaft and through the body. The second line is a parallel
      line to the shaft plane line drawn across the top of the shouulders
    </div>
    <img
      src="@/assets/images/hardy2/mistakesText1.png"
      class="img10"
      alt="Alt"
    />
    <img
      src="@/assets/images/hardy2/mistakesText2.png"
      class="img10"
      alt="Alt"
    />
    <img
      src="@/assets/images/hardy2/mistakesText3.png"
      class="img10"
      alt="Alt"
    />
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
  </div>
</template>
