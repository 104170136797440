<template>
  <Panel header="Introduction" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        <div class="p-mb-2">
          Grow Your Vision of Greatness SWING KEY: Be your own best coach; you
          are the expert on you.
        </div>
        <div class="p-mb-2">
          Golf is a living relationship between the game and you. To play great
          golf is not merely a matter of mastering a series of mechanical moves,
          but also the art of surrendering to the sweet choreography of the
          dance you share with the game. At the heart of the VISION54 philosophy
          is the notion that great golf springs from that intricate
          relationship. The key to great performance has as much to do with
          passion, belief and trust as it does with arm angles, body positions
          and swing planes. You are not a golfer who happens to be a person, but
          rather a person who happens to play golf.
        </div>
        <div class="p-mb-2">
          Also at the heart of our thinking is the notion that
          everything—including schools of thought—grows, evolves and matures.
          This book represents not just the further refinement of the approach
          we described in Every Shot Must Have a Purpose and The Game Before the
          Game, this book also gives you specific tools we have developed to use
          in your pursuit of greatness on the golf course: the 8 Essential
          Playing Skills for Peak Performance—the EPS that is your GPS for
          golf—and the 2 Essential Practice Skills that will enable you to
          integrate the 8 Essentials into your game in the most efficient way.
        </div>
        <div class="p-mb-2">
          What is VISION54? It is our belief that what you achieve is greatly
          affected by what you believe you can achieve. Why make it your goal to
          par every hole? Why not make it your mission to birdie every hole—and
          shoot a 54! We want you to approach life with the attitude that
          anything is possible. We want you to go from the kind of
          one-dimensional thinking that creates paralysis by analysis on the
          golf course to the kind of multidimensional experiences that will help
          you play better. We want you to realize your potential. The ideas we
          touched upon in our first two books are amplified here. In these
          pages, you will find the skills needed to realize this vision.
        </div>
        <div class="p-mb-2">
          VISION54 will expand your belief in what's possible. As that belief
          strengthens, you will create a personalized road map that leads you to
          being a better player—your MY54—based on your experience with the
          game. You will also determine what keeps you from reaching your full
          potential. This is your NOT54—the bad habits and tendencies you have
          developed that consistently cause you to get in your own way. As you
          learn to distinguish between what is and what is not under your
          control, you will play better golf. Control what you can and let go of
          what you can't.
        </div>
      </div>
    </div>
  </Panel>
  <Panel
    header="8 Essential Playing Skills"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        The 8 Essential Playing Skills are the tools you need to realize
        VISION54. Through our first book, Every Shot Must Have a Purpose,
        players and teachers began to understand the importance of VISION54, and
        to realize the benefits of having a broader understanding of the game of
        golf. But many also felt lost, unsure of where to even begin the
        journey. The 8 Essentials in this book are the bricks needed for
        building a foundation of skills that lead you to becoming a better
        player. Every Shot Must Have a Purpose was the revelation; Play Your
        Best Golf Now is the realization.
      </div>
      <div class="p-mb-2">
        Certainly, the fundamentals of the game are important to master: grip,
        stance, posture, etc. But there is more than one correct way to swing a
        golf club, and we feel the traditional list of fundamentals does not go
        far enough. We are not subtracting from the conventional wisdom about
        golf—or questioning it—but rather adding to it and expanding its vision.
        Success in golf, “like any other activity, involves making clear,
        committed decisions and carrying that commitment with you when it comes
        time to act. In golf, that action is striking the ball. This it not
        negotiable; it is essential. You must commit to every shot. Every great
        player will tell you this is true.”
      </div>
      <div class="p-mb-2">
        You have hit good golf shots with your swing; you have hit great shots
        with your swing. Lower scores result from the repeatability of your
        swing when it is at its best. The Essential Playing Skills will enable
        you to repeat your best swing more often and hit good shots more often.
        Along the way, you will rediscover your love of the game, deepen your
        understanding of it and ultimately enjoy golf more. Remember, it is a
        game and you do play it. Don't fear the shot, live it!
      </div>
      <div class="p-mb-2">
        In our decades of work with players of all skill levels, we have come to
        understand the components that are absolutely essential to success in
        golf. We have distilled these 8 Essential Playing Skills from the
        hundreds of factors influencing your swing and game. When you have
        learned the Essentials, you will integrate them into your game to
        complement the technical and physical skills you already possess.
        Learning the Essentials gives you a starting template for a more
        complete game. One of the things we have learned from all of the golfers
        we have seen through the years is that the 8 Essentials maximize the
        return on your investment of the time you put into golf.
      </div>
      <div class="p-mb-2">“The 8 Essential Playing Skills”</div>
      <div class="p-mb-2">
        1. Leave your mind behind: This is about entering the Play Box, the
        sacred ground of golf. Learn to engage with the shot. Just make it
        happen.
      </div>
      <div class="p-mb-2">
        2. Decide and commit: Become a more confident player by learning to make
        clear decisions that you trust and stick with. Believe in great golf.
      </div>
      <div class="p-mb-2">
        3. Find your balance: Walk the tightrope to great play. Be centered
        physically and mentally.
      </div>
      <div class="p-mb-2">
        4. Feel your tempo and dance to its rhythm: Learn how your natural tempo
        feels and discover what tempo works best for you.
      </div>
      <div class="p-mb-2">
        5. Tame tension: There is no greater saboteur of the golf swing than
        tension. Recognize it and manage it. Balance, tempo and tension
        awareness (BTT) will make any swing better.
      </div>
      <div class="p-mb-2">
        6. Build emotional resilience: Learn how to turn disappointing results
        into stepping stones rather than stumbling blocks.
      </div>
      <div class="p-mb-2">
        7. Store memories: File away the good and neutralize the bad.The past
        doesn't have to be your future.You can learn from the past to make a
        better present.
      </div>
      <div class="p-mb-2">
        8. Drown self-talk in useful thoughts: Hear that voice in your head that
        raises doubts and fears? We all have it. Have a dialogue with it.That
        voice isn't always right. You can set it straight.
      </div>
      <div class="p-mb-2">
        In this book you will find exercises that will help you to master the 8
        Essentials. Through those exercises you will build a toolbox of skills
        that will make you more consistent in your performance and more
        confident in your execution of the golf swing. Remember, peak
        performance does not have to be an accidental state that you stumble
        upon only rarely. Instead, it should be an intentional destination you
        can learn how to access more frequently.
      </div>
      <div class="p-mb-2">“The 2 Essential Practice Skills:”</div>
      <div class="p-mb-2">
        1. Simulate golf: The time and effort you invest in practice yields the
        best return when it is most like real golf. Make practice like golf by
        inviting the pressure of performing onto the practice range with you.
        This is crucial to getting better.
      </div>
      <div class="p-mb-2">
        2. Integrate skills: Why are you practicing? Don't just hit balls,
        practice with a purpose. Have a plan. Hit each shot with an objective in
        mind.
      </div>
      <div class="p-mb-2">
        There are many people who hit golf balls for an hour and think they have
        practiced golf, when all they have done is hit golf balls for an hour.
        The Essential Practice Skills involve practicing with a purpose. The
        Essential “Playing Skills involve playing with a purpose. Success is
        built upon integrating these skills into your game. Our goal is to tear
        down the walls between practice and play, between theory and
        performance. We want you to learn not to be afraid of a golf shot but
        rather to be inspired by the challenge. This can only be achieved by
        experiencing real golf in practice—not by mindlessly scraping and
        hitting balls on the range.”
      </div>
    </div>
  </Panel>
  <Panel
    header="From Think Box To Play Box"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">“FROM THINK BOX TO PLAY BOX”</div>
      <div class="p-mb-2">
        We see the process of hitting a golf shot as a two-step activity,
        divided between the Think Box and the Play Box, and separated by an
        imaginary Decision Line. The Think Box is where you formulate your plan
        for the shot you are about to hit. The Play Box is where you execute
        that plan. Our observations have led us to realize that for many
        players, the Think Box has become a clutter of pre-shot
        routines—practice swings, overthinking, etc. We have found through trial
        and error that simplifying your Think Box routine helps to create a
        stronger state of Decision and Commitment. This way, you are able to
        step into the Play Box with the kind of trust you need to hit a
        successful shot. By making clear decisions and committing fully to those
        decisions, you will create a stronger sense of engagement and a more
        vibrant energy for the real moment of truth—executing the shot in the
        Play Box.
      </div>
      <div class="p-mb-2">
        We view this book as a celebration of the most sacred ground of
        golf—that moment when you cross the Decision Line from the Think Box
        into the Play Box to hit a golf shot. Our purpose is to help you perform
        better on the golf course—where it matters—and not on the practice
        range, where most players leave their best shots. We want your game to
        show up and hold up on the golf course. Through the first-person
        accounts in this book by players of all skill levels who have been
        helped by VISION54, you will learn the secrets needed to Play Your Best
        Golf Now.
      </div>
      <div class="p-mb-2">
        One of the joyous things about golf is that it is a highly
        individualized sport, while at the same time being extremely social. In
        what other athletic activity can playing partners, or even opponents,
        spend time walking and talking together while competing? In what other
        sport is the venue on which the game is played as much a part of the
        experience as the game itself? In what other game can players of varied
        skill levels compete against one another as equals? It is important to
        incorporate that physical, social and spiritual connection into how you
        approach golf. This game is far too beautiful to be reduced to just
        swing theory. Immerse yourself in golf, become one with it and then
        watch your scores drop.
      </div>
      <div class="p-mb-2">
        At the core of the beauty of the game is the understanding that golf is
        an integrated and a holistic experience in which you celebrate your
        uniqueness in an extremely social setting. It's an individual activity
        played out on a collective stage. Golf presents the same challenges as
        life: finding and preserving your identity amid outside pressure to be
        someone else. Central to VISION54 is the belief that you will play your
        best golf when you are being most true to yourself, when you have
        integrated who you are with how you play.
      </div>
      <div class="p-mb-2">
        We are baffled when we see people overlook their own unique character as
        they try to learn the game. Often, in searching for answers, players try
        to become someone else—swing like someone else, think like someone else,
        behave like someone else—rather than pursue their full potential by
        learning to be themselves.
      </div>
      <div class="p-mb-2">
        Not everyone swings like Mickey Wright, nor should they, and not
        everyone thinks like Ben Hogan, nor should they. Part of what makes
        Tiger Woods a great player is that he is totally himself on the golf
        course, right down to his occasional expletives that go uncensored.
        Woods knows that to break the records of Jack Nicklaus he need not try
        to become the Golden Bear, but rather must find his own Tiger. He
        embraces his unique spirit of the game.
      </div>
      <div class="p-mb-2">
        Our approach acknowledges, first and foremost, that you are an
        individual. We don't teach you, we help you learn. In a world cluttered
        with a myriad of specialized instructors, we view ourselves as coaches
        of the complete game of golf. Our emphasis is on first-person experience
        rather than third-person observation. We are not offering a gimmicky
        quick fix—although some of “our students have seen astonishingly fast
        results—but rather a permanent foundation for greatness, a functional
        baseline for long-term excellence that will last as long as you play the
        game.”
      </div>
      <div class="p-mb-2">
        We do not want to teach you someone else's swing; we want you to learn
        to find your own. Because of this approach, VISION54 is compatible with
        any swing theory, with any personality and with any skill level. It will
        make anyone better. We help our students enhance their performance, in
        part, by enabling them to enjoy the game more. We do this by helping you
        learn the tools that tame tension, reduce self-doubt and lead to full
        commitment. You can't play your best when you are playing scared.
        Whatever your approach to the game, the Essential Playing Skills will
        help you get more out of it.
      </div>
      <div class="p-mb-2">
        In our previous books, we established the framework of the VISION54
        philosophy for play and practice. This book reflects the continued
        maturation of that thought process. You will learn about the physical,
        technical, mental, emotional and social elements of the game, but you
        will also learn how those components exist as overlapping circles of
        experience within the larger circle representing the spirit of the game.
        Those elements defined in our earlier works will become accessible to
        you through the skills you will learn from this book.
      </div>
      <div class="p-mb-2">
        Having a great swing is not playing great golf. That is only part of the
        puzzle of peak performance. Our goal is to unify the elements of a game
        that have become splintered into swing coaches, long-game coaches,
        short-game coaches, putting coaches, mind coaches and more. We are
        performance coaches. The point of the game is to get the ball in the
        hole in as few strokes as possible. We facilitate that process. We don't
        obsess over your faults, but rather we focus on the long-term and
        lasting impact of your possibilities. You will become a better player by
        learning how to get out of your own way. And we can help you learn how
        to do that.
      </div>
    </div>
  </Panel>
  <Panel
    header="Performance Coaches: Achieve Peak Performance"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        In the pages that follow, you will meet some wildly diverse people who
        share two things:They love the game of golf, and they have learned to
        explore that love more fully through our school or books. Oh, yeah,
        there is a third element they share: They all enjoyed the game more and
        performed better after learning the 8 Essentials. The students profiled
        in this book include PGA professionals, a touring pro, businessmen and
        -women, a dairy farmer from Australia, an F-16 fighter pilot and an
        opera singer. They have high handicaps and low, and they share with you
        what they learned that has enabled them to play the game more
        successfully and enjoy it more completely. Each person's story focuses
        on a different Essential Skill, and each chapter ends with exercises you
        can use—both on and off the golf course—to help you Play Your Best Golf
        Now.
      </div>
      <div class="p-mb-2">
        Make no mistake about it:We are not anti-instruction, nor are we
        anti-analytical. It's just that we believe no component of the game
        exists in isolation. It can be just as destructive to be totally
        obsessed with swing theory and swing mechanics as it can be to be
        obsessed with visualization, strength training or any other piece of the
        game. We reduce the game to its simplest, most accessible—and therefore
        most attainable—elements, and it is these elements, when combined, that
        produce great golf.
      </div>
      <div class="p-mb-2">
        What results in low scores? Good shots and great putts. Those good shots
        and great putts come from swings that are good enough and putting
        strokes that are good enough, which are the result of functional
        technique. That functional technique is the sum total of your mastery of
        the physical, technical, mental, emotional and social elements of the
        game, unified within its spirit. Many view golf only through the
        micro-lens of technique. We want you to use a wide-angle lens, seeing
        all the components that influence your technique.
      </div>
      <div class="p-mb-2">
        Have you ever hit a good shot with your swing? Have you ever hit a great
        shot with your swing? The answer for all of us is yes. To score, you
        need to have a good-enough swing and a good-enough putting stroke, but
        you don't need to be perfect. In fact, the obsession with perfection can
        be detrimental to performance. You need to have functional technique,
        and to maximize the way your technique functions, you need to go one
        step further “and ask this question: What influences technique? You'll
        find the answers in this book; you will learn routines to help you keep
        your technique performing at its highest level. You will learn to
        identify what you can control, and you will learn tools that will help
        you master that control.
      </div>
      <div class="p-mb-2">
        There have been many great players throughout the history of golf, and
        they have had many different swings. Their success comes from the
        ability to make their swing repeat, especially under pressure. We will
        help you learn ways to greatly improve the odds of repeatability. The
        reason we place our emphasis on facilitating your learning, rather than
        on imposing our teaching, is to help you understand your game better. In
        that understanding is found sustainable success. That understanding will
        be with you when we are not. The 8 Essentials will help you get the most
        out of your relationship with golf, and that leads to lower scores.
      </div>
      <div class="p-mb-2">
        What do we mean by getting to know your game better? The idea is simply
        to understand that you are more consistent than you think. How are you
        when you are great? And how are you when you mess up? There are patterns
        to how you play. By recognizing these patterns, you can learn to make
        your confidence less fragile. You can learn to maximize your performance
        by getting out of your own way.
      </div>
      <div class="p-mb-2">
        When reduced to its basics, golf is a simple game: Get good equipment,
        make a good swing, and you'll get good results. In recent years, players
        have also come to realize the value of being in good shape. But many
        players sense there is something missing from that equation for
        successful golf. Those who get the most out of their ability seem to
        possess something others lack, something that seems almost magical.
      </div>
      <div class="p-mb-2">
        What is the missing piece of the puzzle? Belief is a huge part of the
        magic. When Ben Hogan said, “The secret is in the dirt,” he meant he
        played so well under pressure because he had learned to believe in his
        ability to execute. He had learned how to create confidence. We take the
        mystery out of the magic and transform implicit knowledge into explicit
        skills for you. Anyone can have that belief in him- or herself.
      </div>
      <div class="p-mb-2">
        Many players have come to understand that peak-performance golf involves
        a whole-game way of looking at the sport. They understand some of the
        elements that affect scoring but intuitively know there must be others
        not yet apparent to them. They want to know where to start the search
        and how to identify the right answers. The answers are in our
        Essentials. We have drawn you a road map for mastering these Essentials
        in order to maximize your skills and get the most out of your game.
      </div>
      <div class="p-mb-2">
        No matter who you are or at what level you play, you need these
        Essentials to reach your full potential. No matter how often you play,
        what skill level you possess or what swing theory you believe, you will
        be better with these Essentials, and you cannot attain your full
        potential without them. The 8 Essential Playing Skills are the real
        deal. They are for every golfer on the planet. They are derived from an
        exhaustive understanding of the human experience of playing golf.The 2
        Essential Practice Skills will support how you integrate the 8
        Essentials into your game.
      </div>
      <div class="p-mb-2">
        The Essential Playing Skills stem from all aspects of understanding.
        Some come from the irrefutable science of the most current research.
        Others derive from a more intuitive understanding of the game that
        evolved from our experiences with golf and golfers. Our mission is to
        transcend swing theories and in that way help all swing theories. We
        want to support other teachers and enable them to maximize the impact of
        their instruction by helping them access the Essentials. The most
        inspiring thing about the Essentials is that they are available to
        everyone. They are free. They live inside you. They do not depend on
        your skill level. You just have to learn how to bring them out.
      </div>
      <div class="p-mb-2">
        The Navajo people have a wonderful phrase to describe an insight that is
        not brought fully to life: “floating clouds.” You can see the vision,
        you can marvel at its beauty, you can describe its shape and even some
        of its substance. Then—poof—it's gone because you did nothing to
        transform the vision into reality.
      </div>
      <div class="p-mb-2">
        Let there be no more Floating Clouds in your golf game. The 8 Essential
        Playing Skills are the rope of understanding with which you can lasso
        your potential. The Essentials available to you in this book can change
        your game. You will learn routines you can use to get more out of your
        practice, and you will learn lessons you can take onto the golf course
        to Play Your Best Golf Now. As you begin your journey with the Essential
        Playing Skills, ask yourself this: If you had a magic wand, what would
        you really want from the game? Dream big and make that vision your
        reality.
      </div>
    </div>
  </Panel>
</template>
