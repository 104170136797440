<template>
  <Panel
    header="Learning Theory and Golf Progress"
    :toggleable="true"
    :collapsed="true"
  >
    <img
      class="img3"
      src="@/assets/images/mental/learningTheory1.png"
      alt="Tough Minded"
    />
    <div class="accent1 fontSmall p-mb-2">
      Typical sinusoidal pattern of motor learning.
    </div>

    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Sinusoidal - honeymoon, crash/confusion/complex, decision-point,
        stick-with-it, realization, mastery. If always try something new at
        decision point then continual honeymoon/crash cycle.
      </div>
      <div class="p-mb-2">
        Critical issue is whether to ‘stick-with-it’ at the decision point. Need
        to be sure that the technique I am trying to learn is the one I want.
        Not always easy to know. If I am reasonably sure it is, then at decision
        point, try to simplify and find the core. If this leads to success the
        core finding becomes the realization, and greater level of mastery
        results. This realization then should be added to the appropriate
        physical or mental skill set in this approach.
      </div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
    </div>
  </Panel>
</template>
