<template>
  <Panel header="Emotional Stability" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Emotionally Stable; is how in control of your emotions you are. Low
        scorers are reactive emotionally, changeable, affected by feelings,
        emotionally less stable, easily upset. High scorers are emotionally
        stable, adaptive, mature, and face reality calmly.
      </div>
      <div class="p-mb-2">
        The mental skill that enhances emotional stability is to evaluate your
        golf performance from a process oriented rather than a outcome-oriented
        perspective.
      </div>
      <div class="p-mb-2">
        This allows you to evaluate yourself on actions over which you have
        control and not on phenomena over which you have no control. This
        minimizes emotional investment in these phenomena. Therefore, if the
        phenomena are negative, your minimal emotional involvement makes it easy
        to remain emotionally stable.
      </div>
      <div class="p-mb-2">
        That said, enjoying the result of a good shot has benefits, see memory
        control.
      </div>
      <div class="p-mb-2">
        The above-average ability to stay emotionally uninvolved with results
        during a round of golf is another of the traits that distinguishes
        champions from average golfers. This is not to say that all emotion is
        bad. Emotions enrich our golf and our lives with meaning, purpose, and
        passion. Emotions feed the unrelenting desire to hit a longer drive, a
        more accurate approach, and a more certain putt. The emotions we plan to
        address here, however, inhibit performance on the course. These are the
        emotional extremes-the higher levels of anger, frustration,
        disappointment, stress, fear, insecurity, and even some of the more
        positive emotions of excitement, elation, and anticipation as well.
        Extreme emotions -no matter what part of the emotional spectrum they're
        on alter your play by impairing your ability to use your mental and
        physical skills properly.
      </div>
      <div class="p-mb-2">
        Here are examples of how all seven of the other eight traits of champion
        golfers can be impaired by extreme emotions. * Focus: Your ability to
        narrow your focus over the ball is reduced as your emotions elevate,
        thereby compromising your mental routine. * Abstract Thinking: Rational
        allowed thoughts and well planned strategy are lost to irrational
        not-allowed thoughts and emotional reaction. * Dominance: Competitive
        and moderately aggressive play is made too aggressive by anger or too
        tentative by fear. * Tough-Mindedness: The strong, tough, resilient
        competitor is reduced to one who is sensitive, uncertain, and easily
        distracted. * Self-Confidence: Emotions can lead to negative and
        degrading self-talk, which erodes both self-confidence and confidence in
        your game. * Self-Sufficiency: Even good decision-makers have a more
        difficult time committing to clubs, targets, and types of shots when
        experiencing extreme anger, fear, frustration, and disappointment. *
        Optimum Arousal: Tension levels usually climb as your emotions do; it is
        difficult to manage one without managing the other.
      </div>
    </div>
  </Panel>
  <Panel header="The Champion Golfer" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Our champion golfers are above average in emotional stability. They
        seldom let their emotions climb to a level at which their rational
        thought and control start to be compromised'. However, it's not that
        they mask or suppress their emotions. Instead, they understand their
        emotions and manage them. Some personalities find it easier to recover
        from an emotional outburst while others find it easier not to react in
        the first place. Most of us, though, remain vulnerable to experiencing
        the emotional roller coaster in our competitive rounds of golf.
      </div>
    </div>
  </Panel>
  <Panel
    header="Challenges to Good Emotional Stability"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        We have found that even players who have above average emotional
        stability can lose their cool when faced with one or more
        emotion-provoking situations. Let's call those situations challenges.
        Your ability to manage your emotions will be influenced by your skill at
        handling the following challenges.
      </div>
      <div class="p-mb-2">Expectations.</div>
      <div class="p-mb-2">
        Lose a tournament when you are defending champion because expectations
        make you unable to control your emotions. This is an example of how it
        is common for golfers to record bad rounds when their ball-striking and
        putting are good. For most, this is a result of conscious or
        subconscious expectations placed on their games. Expectations become
        emotional traps, setting the players up for frustration and
        disappointment.
      </div>
      <div class="p-mb-2">
        Expectations do not only concern hitting the ball well. They could be
        rooted in your playing a course you especially like or have played well
        before; practicing many long and hard hours; getting physically fit and
        diet conscious; or a combination of these and other seemingly positive
        influences. As these influences subtly stir emotions of desire, longing,
        and anticipation, you become primed for almost instant frustration,
        disappointment, and anger when your shots aren't hit exactly as you
        expected, and your scores aren't as low as you hoped.
      </div>
      <div class="p-mb-2">
        The best players prepare themselves for the trap of not living up to
        expectations by making themselves aware of any expectation that can do
        damage-because acknowledging your expectations softens their impact on
        your emotions and, therefore, your play. One good exercise is simply to
        write them down. This will increase your awareness and even allow you to
        file them away as a symbolic gesture of leaving them at home.
      </div>
      <div class="p-mb-2">Perfectionism</div>
      <div class="p-mb-2">
        This personality trait takes expectations to the maximum level. When
        players go into competition feeling they should hit no less than perfect
        shots and perfect putts, ball-striking can unwittingly become more
        important than actually playing the game. With this mindset, players are
        doomed to experience floods of emotion, at least partially because they
        have chosen a sport that cannot be perfected. And a perfectionist doing
        something that cannot be perfected is a classic formula for frustration,
        anger, and disappointment. It is important to remember that in golf
        diligent work does not always ensure perfect play.
      </div>
      <div class="p-mb-2">
        To compensate, perfectionists must accept less-than-perfect
        performances, less-than-perfect shots, less-than-perfect results. They
        must change their attitude about what they should be able to do, and try
        to do only what they realistically can do. Later in this chapter you
        will see how we help per-fectionists accomplish this goal by teaching
        them to techniques: Process vs. Outcome and Margins for Error.
      </div>
      <div class="p-mb-2">Pain</div>
      <div class="p-mb-2">
        pain can increase irritability, reduce patience, and elevate negative
        emotions. Unfortunately, playing with pain, particularly shoulder, hip,
        and lower_back pain, is a common problem among golfers. But pain can be
        managed by (1) doing physical exercises (for both prevention and
        treatment), (2) taking lessons to correct faulty mechanics, and (3)
        learning mental techniques that minimize the intensity of pain.
      </div>
      <div class="p-mb-2">Mental fatigue</div>
      <div class="p-mb-2">
        Mental fatigue leaves you with little or no patience, hinders you from
        making and committing to decisions, and makes managing emotions nearly
        impossible. Its causes include practicing too much, playing too much,
        remaining too mentally involved with your round between shots,
        overanalyzing your play, being out-of-balance in your life away from the
        course (working too hard, stress, sleep shortages, irregular meals), or
        any combination of the above.
      </div>
      <div class="p-mb-2">
        The first step to avoiding mental fatigue is to recognize that allowing
        yourself to overanalyze, worry, and experience emotions only prevents
        you from playing the best golf possible. You must learn to expend your
        mental energy efficiently. You can do this by taking mental breaks
        between shots or just finding the time to relax during your round. You
        should resist wasting mental energy on not-allowed thoughts. Taking care
        of yourself with a proper diet and rest are equally important.
      </div>
      <div class="p-mb-2">Medical conditions</div>
      <div class="p-mb-2">
        depression, HDAD, anxiety, hypoglycemia, suppressed anger/frustration
        etc.
      </div>
      <div class="p-mb-2">Hypoglycemia</div>
      <div class="p-mb-2">
        It is important to try to have a well-baranced meal before playing a
        round of golf and at least one nutritious snack on the course. These
        snacks are especially important to the play_ ers who are too nervous to
        eat a full meal before teeing off. Hypoglycemia from not having adequate
        food. In addition to emotional instability, other symptoms can include
        restlessness, fatigue, anxiety, dizziness, headaches, irritability,
        depression, a craving for sweets, or impaired memory.
      </div>
    </div>
  </Panel>
  <Panel
    header="Process Oriented not Outcome oriented"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        This is the emotionally-stable mental skill We often point out to our
        clients that the greatest differences between champions and average
        golfers is the way they think. And one of the biggest differences is
        rooted in whether the player is oriented more toward the outcome of his
        play or the process of his play.
      </div>
      <div class="p-mb-2">
        Being outcome-oriented is the more natural approach to the game. The
        game itself encourages emphasis on end results, quality of shots and
        strokes, perfection, and accomplishment. We often hear television
        commentators say “Its not how it’s how many” Being process-oriented, on
        the other hand is more about how - and that comes less naturally to
        most. It emphasizes keeping a quiet and peaceful mind, and accepting all
        adversity as a challenge to staying relaxed and focused on hitting one
        shot at a time. “Let it happen” seems to be the unspoken motto of most
        of the greatest golfers. If you just concentrate on the process and put
        in the practice to make your process as good as you can, then in the end
        when you add up your score, it will be about as good as you can do.
      </div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2">Some Steps for Effective process-Thinking</div>
      <div class="p-mb-2">
        Here are several simple steps to help you shift from outcome-thinking to
        process-thinking. How will you know if they're working? When you feel
        less pressure, the game gets simpler, and your rounds become a lot more
        fun.
      </div>
      <div class="p-mb-2">1. List Outcome Challenges</div>
      <div class="p-mb-2">
        Before you begin your round, identify all the outcome oriented thoughts
        you are likely to battle on that given day. An important key in making
        this list is to keep in mind the things you truly cannot control: the
        weather, bad bounces, poor conditions, pairings, tee times, what other
        players do, and bad luck. These concerns will control you if you let
        them. They can push you into outcome-thinking, which is related to the
        not-allowed thoughts from abstract thinking. This includes over concern
        with score, position, bets, money, fear of failure, what others think of
        you when you fail, and the like. All players have these thoughts, but
        champions are better prepared to recognize them under the pressure of
        play and replace them with what they can control: the process.
      </div>
      <div class="p-mb-2">2. Set Process Goals</div>
      <div class="p-mb-2">
        Before each round, set goals that channel your mental energies into the
        process. Such goals support your emphasis on the techniques we learned
        earlier: your sound mental routine and being able to regulate your
        thoughts. Here are some process goals: * Be fully committed to your
        shots and putts before stepping up to the ball. If you are not
        committed, back off, recommit, and start again. * Get a definite target
        for every shot and a definite line for every putt. * Relax enough to
        visualize every shot and putt before stepping up to the ball. * Feel a
        smooth tempo for every shot and putt in your practice swing or stroke
        and in your real swing or stroke. * Walk and talk with confidence
        between shots. * Take mental breaks from your round between shots. *
        Stay with your game plan, regardless of your score.
      </div>
      <div class="p-mb-2">3. Use Emotions as a Cue</div>
      <div class="p-mb-2">
        Prepare yourself to use your emotions as a cue, or reminder, to adjust
        from outcome-thinking to process-thinking both before and during
        competition. These steps will organize your efforts: * Try to recognize
        immediately when emotions are rising-fear, dread, and worry in
        particular. * Recognize that these emotions are a result of
        nonconstructive outcome-thinking and identify the source. * Use these
        feelings as a friendly reminder to shift your emphasis from the outcome
        back to the process. Recommit to your mental goals. * Judge your
        process, not your results.
      </div>
      <div class="p-mb-2">4. Shots and Putts</div>
      <div class="p-mb-2">
        Refuse to judge your shots and putts by where they stop. Instead, assess
        your process, such as the quality of your mental routine. If you
        conclude that any part of the mental routine was weak, challenge
        yourself to make it better on the next shot.
      </div>
      <div class="p-mb-2">5. Scores and Positions</div>
      <div class="p-mb-2">
        Judge your rounds not by your score but by your commitment to your
        mental goals. After you've played, decide what you could improve
        physically and incorporate that into your practice. Then decide what you
        can improve mentally and use that as a goal for your next competitive
        round.
      </div>
    </div>
  </Panel>
  <Panel
    header="Process Orientation as part of Shot Routine: (Memory Control)"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        (important component of building deep confidence)
      </div>
      <div class="p-mb-2">
        The memory control skill is a) to actively store positive memories of
        good shots, and have a neutral reaction and analysis of poor shots, and
        store the ‘analysis lesson’ if useful. b) To be able to recall these
        memories appropriately, to aid emotional control, decisiveness and
        trust, self-talk control and build confidence.
      </div>
      <div class="p-mb-2">
        Useful techniques for actively storing good shots. Slow the process down
        - hold finish, twirl club, experience, in the first person, the
        trajectory, direction, landing, rollout and proximity to target.
        Actively enjoy it and actively commit it to memory. The hippocampus is
        important in converting these memories from short to long term, so that
        they can be recalled in the future. The fusiform gyrus may play a role
        in encoding/storing positive memories. The shot routine and confidence
        deepening rely heavily on long term recall of good shots.
      </div>
      <div class="p-mb-2">
        To understand the reason behind the technique for responding to a poor
        shot, you need to know a little about amygdaloid function. Functions of
        the amydgala include storing memories that have a negative emotion, and
        recognizing similar situations and responding with anxiety and avoidance
        behaviour. We store negative memories more easily and deeply than
        positive as a survival mechanism. A snake that bit you and caused pain
        becomes a memory associated with a negative emotion. Next time you come
        across a snake, the amygdala remembers the previous episode, and will
        become activated and initiate several effects. You will experience fear
        and execute avoidance behaviour. (About 3 positive to one negative
        memory offsets natures natural bias).
      </div>
      <div class="p-mb-2">
        Therefore, if after missing a 3ft sliding putt, you allow yourself to
        feel shame, embarrassment, and indulge in self talk that includes self
        ridicule, the amygdala will dutifully encode the miss and the negative
        emotions into memory. The next time you stand over a similar putt, your
        amygdala will recognize the similar situation, become activated and make
        you anxious, including shaking hands, an inability to focus and you will
        likely miss the new putt. This subsequent miss will deepen the negative
        memory.
      </div>
      <div class="p-mb-2">
        The technique for analyzing a poor shot is designed to minimize the
        storage and effect of negative memories in the amygdala. View the shot
        neutrally from a clinical third person perspective, don’t get
        emotionally involved. Genuinely try to identify what caused the bad
        shot. I.e,. wrong club/shot, no commitment, decelerating putt, no swing
        feels. Try to understand why it happened now and how not to do it in the
        future. Commit these neutral facts with the hippocampus. In this way you
        learn without a negative memory. The next time you have a similar shot,
        there is no negative memory waiting to activate the amygdala and
        sabotage the current shot.
      </div>
    </div>
  </Panel>
</template>
