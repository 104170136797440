<template>
  <h1>Playing Skills</h1>
  <Panel
    :toggleable="true"
    header="Image: During-Round, Before- and After-Round"
  >
    <div class="p-col-12 ta-center">
      <div class="p-grid">
        <div>
          <img
            src="../assets/images/home/during-round.png"
            alt="DuringRound"
            class="img10"
          />
        </div>
      </div>
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        PIA: “The secret to playing well more often is to better understand how
        you do things when you do play well. What are you aware of during the
        putting stroke or the full swing? What do you do between shots? Do you
        chat or are you in your own world? If you are in your own world, what
        are you thinking about? What is your tempo? How do you commit to
        decisions? The greater clarity you have about the 8 EPS (Playing Skills
        - Play Box, Think box, Balance, Tempo, Tention awareness, Memory, Self
        talk, Emotion - with the ideal vision held in Peak Performance), the
        more in touch you will be with your MY54 (Peak Performance). How do you
        warm up when you have your best rounds? What do you eat and drink?”
      </div>
    </div>
  </Panel>
  <Panel
    header="During Round: Inter-Shot Routine"
    :toggleable="true"
    :collapsed="true"
  >
    <div>
      <img
        src="@/assets/images/mental/interShotRoutine1.png"
        alt="playing"
        class="img7"
      />
      <div class="accent1 fontSmall p-mb-2">Inter-Shot Routine</div>
    </div>
    <Panel
      header="Peak Performance State (the vision)"
      :toggleable="true"
      :collapsed="true"
    >
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          <div v-html="PlayerInterShotRoutine"></div>
        </div>
      </div>
    </Panel>
    <Panel
      header="Emotions: Build and maintain resilience"
      :toggleable="true"
      :collapsed="true"
    >
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          <div v-html="FullSwingTrustIt"></div>
        </div>
      </div>
    </Panel>
    <Panel
      header="Thoughts: Drown out self-talk"
      :toggleable="true"
      :collapsed="true"
    >
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          <div v-html="PartialTrustIt"></div>
        </div></div
    ></Panel>
    <Panel
      header="External: Social, Environmental, Nutrition/ Hydration"
      :toggleable="true"
      :collapsed="true"
    >
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          <div v-html="BunkerTrustIt"></div>
        </div>
      </div>
    </Panel>
  </Panel>
  <Panel
    header="During Round: Shot Routine"
    :toggleable="true"
    :collapsed="true"
  >
    <div>
      <img
        src="@/assets/images/playingSkills/shotRoutine1.png"
        alt="shotRoutine"
        class="img8"
      />
      <div class="accent1 fontSmall p-mb-2">
        See It; Feel It; Trust It; Enjoy/Analyze Remember It; Let It Go
      </div>
    </div>

    <Panel
      header="See It: Think Box (i) Control Arousal (ii) Decide and Commit"
      :toggleable="true"
      :collapsed="true"
    >
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          <div v-html="PlayerSeeIt"></div>
        </div>
      </div>
    </Panel>
    <Panel
      header="Feel It: Balance, Tempo, Tention awareness, Shot Type"
      :toggleable="true"
      :collapsed="true"
    >
      <Panel
        header="Balance, Tempo, Tension Awareness"
        :toggleable="true"
        :collapsed="true"
      >
        <div class="fontSmall taLeft">
          <div class="p-mb-2">
            <div v-html="BttFeelIt"></div>
          </div>
        </div>
      </Panel>
      <Panel header="Shot Type" :toggleable="true" :collapsed="true">
        <div class="fontSmall taLeft"></div>
        <Panel header="Full Swing" :toggleable="true" :collapsed="true">
          <div class="fontSmall taLeft">
            <div class="p-mb-2">
              <div v-html="FullSwingFeelIt"></div>
            </div>
          </div>
        </Panel>
        <Panel
          header="Distance Wedge: 30yd-100yd:"
          :toggleable="true"
          :collapsed="true"
        >
          <div class="fontSmall taLeft">
            <div class="p-mb-2">
              <div v-html="PartialFeelIt"></div>
            </div>
          </div>
        </Panel>
        <Panel header="Pitch/Chip: <30yd" :toggleable="true" :collapsed="true">
          <div class="fontSmall taLeft">
            <div class="p-mb-2">
              <div v-html="PitchChipFeelIt"></div>
            </div>
          </div>
        </Panel>
        <Panel header="Putting" :toggleable="true" :collapsed="true">
          <div class="fontSmall taLeft">
            <div class="p-mb-2">
              <div v-html="PuttingFeelIt"></div>
            </div>
          </div>
        </Panel>
      </Panel>
    </Panel>
    <Panel
      header="Trust It: Kinesthetic Awareness"
      :toggleable="true"
      :collapsed="true"
    >
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          <div v-html="PlayerTrustIt"></div>
        </div>
      </div>
    </Panel>
    <Panel
      header="Enjoy/Analyze Remember It"
      :toggleable="true"
      :collapsed="true"
    >
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          <div v-html="PlayerEARIt"></div>
        </div></div
    ></Panel>
    <Panel header="Let It Go" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          <div v-html="PlayerLetItGo"></div>
        </div>
      </div>
    </Panel>
  </Panel>
  <Panel header="Before Round" :toggleable="true" :collapsed="true">
    <BeforeRound />
  </Panel>
  <Panel header="After Round" :toggleable="true" :collapsed="true">
    <AfterRound />
  </Panel>
</template>

<script>
import { onMounted, computed } from "vue";
import store from "../store";
import BeforeRound from "@/components/playingSkills/beforeRound.vue";
import AfterRound from "@/components/playingSkills/afterRound.vue";
export default {
  name: "Playing",
  components: {
    BeforeRound,
    AfterRound,
  },
  setup() {
    const PlayerInterShotRoutine = computed(() => {
      return store.state.note.PlayerInterShotRoutine.text_content;
    });
    const FullSwingTrustIt = computed(() => {
      return store.state.note.FullSwingTrustIt.text_content;
    });
    const PartialTrustIt = computed(() => {
      return store.state.note.PartialTrustIt.text_content;
    });
     const BunkerTrustIt = computed(() => {
      return store.state.note.BunkerTrustIt.text_content;
    });
    const PlayerSeeIt = computed(() => {
      return store.state.note.PlayerSeeIt.text_content;
    });
    const PlayerTrustIt = computed(() => {
      return store.state.note.PlayerTrustIt.text_content;
    });
    const PlayerEARIt = computed(() => {
      return store.state.note.PlayerEARIt.text_content;
    });
    const PlayerLetItGo = computed(() => {
      return store.state.note.PlayerLetItGo.text_content;
    });
    const BttFeelIt = computed(() => {
      return store.state.note.BttFeelIt.text_content;
    });
    const FullSwingFeelIt = computed(() => {
      return store.state.note.FullSwingFeelIt.text_content;
    });
    const PartialFeelIt = computed(() => {
      return store.state.note.PartialFeelIt.text_content;
    });
    const PitchChipFeelIt = computed(() => {
      return store.state.note.PitchChipFeelIt.text_content;
    });
    const PuttingFeelIt = computed(() => {
      return store.state.note.PuttingFeelIt.text_content;
    });
    async function getLatestNote(text_code) {
      try {
        await store.dispatch("note/getLatestNote", text_code);
      } catch (error) {
        console.log(`Error: Home.vue getLatestNote(): ${error}`);
      }
    }
    onMounted(() => {
      getLatestNote("PlayerInterShotRoutine");
      getLatestNote("FullSwingTrustIt");
      getLatestNote("PartialTrustIt");
      getLatestNote("BunkerTrustIt");
      getLatestNote("PlayerSeeIt");
      getLatestNote("PlayerTrustIt");
      getLatestNote("PlayerEARIt");
      getLatestNote("PlayerLetItGo");
      getLatestNote("BttFeelIt");
      getLatestNote("FullSwingFeelIt");
    });
    return {
      getLatestNote,
      PlayerInterShotRoutine,
      FullSwingTrustIt,
      PartialTrustIt,
      BunkerTrustIt,
      PlayerSeeIt,
      PlayerTrustIt,
      PlayerEARIt,
      PlayerLetItGo,
      BttFeelIt,
      FullSwingFeelIt,
      PartialFeelIt,
      PitchChipFeelIt,
      PuttingFeelIt,
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
}
</style>
