<template>
  <Panel header="Introduction" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        SWING KEY: The perfect golf shot begins with a clear and congruent
        decision and a full commitment to that decision.
      </div>
      <div class="p-mb-2">
        Unlike frequently traveled highways, turning points in life are rarely
        heralded by road signs. At best, they are a fleeting glimpse in the
        rearview mirror, recognized only after you fully experience and
        recognize the importance of the change in direction. The right path is
        often less a landmark beckoning to you than it is a feeling whispering
        to you. Sometimes, the smartest thing you can do is to listen.
      </div>
      <div class="p-mb-2">
        Such was the case for Marlo Stil, an investment advisor who made not
        only a midlife career change but, through golf and our school,
        discovered a whole new path and passion. When Marlo found golf, it was
        like turning the corner of a city block and running into a total
        stranger who becomes your best friend. When she found VISION54, it led
        her to an understanding of the game, through which she realized that the
        new friend stumbled upon was actually the love of her life.
      </div>
      <div class="p-mb-2">
        When Marlo decided to leave her corporate job and work for herself, she
        was able to create more time for golf. Soon after reinventing herself,
        Marlo used hard work and lots of practice to get her handicap down to
        14—and then in 2002 she came to one of our programs, the first of many
        she has attended. Within a year, she had her handicap down to 2. How can
        such startling improvement be possible?
      </div>
      <div class="p-mb-2">
        Part of Marlo's breakthrough as a golfer was the realization that great
        shots are born in belief. For Marlo, Decide and Commit was the Essential
        Playing Skill that had the most impact on her game.The ability to make a
        clear decision and commit to it is an activity that takes place in the
        Think Box, but the nature of this routine for you is determined by your
        particular needs in the Play Box. The ability to decide and commit in
        the Think Box frees you to perform in the Play Box.
      </div>
    </div>
  </Panel>
  <Panel header="(i) Control Arousal" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        The Play Box is golf, while the Think Box is the dress rehearsal for
        golf. We have come to realize the importance of developing a Think Box
        routine that gets you to the Play Box quickly in a confident and
        committed state of mind. This is a case where working backward is the
        most forward-looking approach. The key to developing a good Think Box
        routine lies in understanding what preconditions you need to achieve
        peak performance once you are in the Play Box.
      </div>
      <div class="p-mb-2">
        The Think Box has two elements. The first is to figure out what you need
        to do to make it easier to be in the right state to swing at your best
        when you step into the Play Box. For some, this means making practice
        swings to feel the shot; for others, it involves visualizing the shots;
        still others need to exhale and calm down; while yet others need to find
        a way to amp up before crossing the Decision Line.
      </div>
    </div>
  </Panel>
  <Panel
    header="(ii) Decide and Commit - control thoughts"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        The second Think Box element is to make a clear, informed decision about
        the shot. That is what this chapter is about: You need to learn how to
        make a decision and commit to that decision—and this is a learned skill.
        We find that too many players spend too much time in the Think Box,
        delaying the real activity of golf—hitting the shot. When you leave the
        Think “Box and step into the Play Box, you have to be completely
        committed to the decision you have made about the shot you are about to
        hit. Get the facts you need (yardage, etc.), then decide on a strategy
        for the shot based on an honest evaluation of your ability and the level
        of trust you have at that moment. That commitment to your decision is
        all you need to find in the Think Box.”
      </div>
      <div class="p-mb-2">
        One of the great things about sports is that they speed up the clock of
        human existence. You can learn a lot about yourself, or someone else, in
        an extremely compressed period of time by playing games. In a round of
        golf, you can learn how you handle failure or success and how you deal
        with disappointment or bad luck. And you learn to what extent you are
        willing to formulate a plan and commit to that plan. Part of what Marlo
        learned was that she needed a greater resolve to carry total commitment
        into the Play Box. “Through VISION54, I have learned the power of
        putting complete attention on my intentions,” says Marlo. “I have also
        learned the importance—the necessity, really—of making clear, complete
        and congruent decisions.” When Marlo started to do that, her scores
        began to drop.
      </div>
      <div class="p-mb-2">
        One of the keys to being able to Decide and Commit is making your Think
        Box routine efficient. Simplify your routine to the basics of what you
        need to prepare for a shot. This varies from person to person. Some
        people play based on total instinct, and the Think Box is not an
        Essential for them. They look at the shot, commit to a decision, and
        step up and hit the ball. Some players we have worked with found it
        liberating when they reduced their thinking and just focused on being
        committed in the Play Box. Overthinking can lock you up both mentally
        and physically. Unfortunately, the overintellectualization of the game
        has led many to believe that slowing down is being careful when, in
        fact, it is being careless.
      </div>
      <div class="p-mb-2">
        So let's talk about the decision-making process. Every shot demands that
        you make multiple decisions: about your club, your target, the wind, the
        lie, your strategy, etc. Then you have to learn to commit to those
        decisions. There is no way to know in advance if you are making the
        right decision, but you still have to commit to that decision. Evaluate
        the shot after you hit it; don't worry about the outcome before you hit
        it. When Tiger Woods was struggling in 2010 after he switched
        coaches—and was trying to change his swing—he was asked to explain why
        the new swing worked sometimes and not other times, and he gave a
        one-word answer: “Commitment.” The full commitment wasn't always there.
      </div>
      <div class="p-mb-2">
        Not every decision works out and not every shot is struck solidly, but
        the more committed you are to your decision, the better your misses will
        be. And as six-time major champion Nick Faldo once said: “Golf is not
        about the quality of your good shots; it is about the quality of your
        bad shots.” Faldo was excellent at commitment, and one of his great
        strengths as a player was that his bad shots were never far off. He
        consistently beat players more physically talented than he was.
      </div>
      <div class="p-mb-2">
        The trust that you develop in your decisions is a product of both your
        head and your heart. Your plan, the decision, is mostly the job of the
        head. Then you have to decide to jump on board and commit—that's the job
        of the heart. While Decision and Commitment come from the intellect,
        they should also be intuitive and instinctual. Don't make this Essential
        merely a mechanical recording of yardage and wind direction. Feel your
        belief. Breathe your confidence.
      </div>
      <div class="p-mb-2">
        The decision-making process involves what you say to yourself and how
        you say it. Some people need very little information to make their
        decision, while others need precise yardages, practice swings and caddie
        talk. Again, figure out what works best for you.The research shows that
        many golfers take in too much information, and by the time they enter
        the Play Box, they have no energy for the shot and their performance is
        undermined by confusion.
      </div>
      <div class="p-mb-2">
        The greatest decision makers in any activity get a baseline of
        information and then listen to their instincts. How do you know what is
        good for you? Figure out what is absolutely essential for you in the
        decision-making process and what is optional. Try playing with minimal
        or no information “and gradually add more information until you have
        everything possible. Where on that spectrum do you perform the best—bare
        bones or jam-packed?”
      </div>
      <div class="p-mb-2">
        We want you to be who you are on the golf course, not a programmed robot
        trying to be someone else. If you know you play your best by making
        mostly gut decisions, then do that. Players like this frequently just
        say, “I got it,” when asked about their decision about the shot. Then
        there are those who need more time before a shot, which can either be
        external—measuring yardage, wind, etc.—or internal—a vision of the swing
        they want to make. Remember, it is you finding what works for you. For
        many players, decisions are a combination of inner awareness and an
        outer connection to the target.
      </div>
      <div class="p-mb-2">
        Now that you have decided about the shot, how do you communicate that
        decision to yourself? This is a crucial step. This is how you develop
        trust for the shot. Try using a video camera so you can see how you look
        during the decision process. How is your body language? How are your
        eyes? Watch tape of great players like Jack Nicklaus or Annika
        Sorenstam. Their level of commitment to their decisions almost screams
        at you in their body language and facial expressions. They believed in
        what they were doing. Tom Lehman once said that Seve Ballesteros played
        with a body language that shouted, “This next shot is going to be the
        greatest shot you have ever seen.
      </div>
      <div class="p-mb-2">
        Great players have a positive voice in their head; so should you. Here
        is the kind of conversation you might have with yourself: “The pin is on
        the back-right part of the green and it is one hundred and sixty yards
        away. I am going to hit a 6-iron with a left-to-right ball flight so my
        target is the tall tree behind the left-center of the green, and I will
        swing with eighty percent of my normal tempo. The ball will start out on
        a line for that tree and then fade to where the hole is located.
      </div>
      <div class="p-mb-2">
        Notice that you do not say, “I think it is a 6-iron” or “I am going to
        try to cut it” or “I hope it will fade to where the hole is.” Clear.
        Concise. Positive. Know what you want to do with the shot and trust that
        you are going to do it. In our school, we pair off students, and when
        they play, we have them actually say to each other what their intention
        is for every shot they hit. For many players, verbalizing their
        intentions for the shot amplifies the commitment to the shot.
      </div>
      <div class="p-mb-2">
        Try this: For several shots, say out loud what you would normally say to
        yourself. Is your voice clear and congruent or are you using vague words
        like “try” or “maybe” or “think” or “hope”? The chances of executing
        your decision successfully are greatly increased if you have
        communicated that decision to yourself with clarity and certainty.
      </div>
    </div>
  </Panel>
  <Panel header="Anecdotes" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Several years ago we had a woman at our school whose technical skills
        were a lot better than her handicap. After seeing her on the golf
        course, we realized one of the main reasons for this discrepancy was her
        inability to make congruent decisions. We had her do an exercise in
        which she would say her decisions about the shot aloud before stepping
        into the Play Box. Her voice, her body language and her words were
        extremely tentative and weak. She would say: “I will try to hit it over
        the hill, but I don't like this hole and I have never played this hole
        well.
      </div>
      <div class="p-mb-2">
        Lynn challenged her by saying: “Come on now, get clear and tell me, tell
        yourself and tell everyone else here what you want to do with the shot
        in a way that we will believe you.” The straw hat the woman had been
        wearing came flying off, and she pointed her finger and said in a strong
        voice: “OK, this is what I'm going to do: I'll aim at the tree behind
        the green and swing with confidence to the finish.” She then proceeded
        to hit her best shot ever on the hole, and proceeded to stay with that
        commitment the rest of the round. About two months later, she told us
        her game had turned around, her handicap had dropped, and she was
        enjoying golf more. She thanked us for the “scream therapy.” She had
        learned how to develop clear, confident decisions.
      </div>
      <div class="p-mb-2">
        Three younger men playing mini-tour golf came to us for coaching. They
        had great swings but were not happy with how they performed in
        competition. We started checking their VISION54 performance foundations,
        and early on became suspicious of their commitment level on the course.
        We “suggested that at the next tournament they put a check mark on the
        scorecard each time they committed to a decision on the course. All
        three of them averaged being fully committed on only about 40 percent of
        their shots. Should they be working on their mechanics or should they be
        working on improving the percentage of shots to which they were fully
        committed? Improve the commitment and the technique will get better! A
        great swing with a tentative hope in the Play Box does not lead to great
        golf shots.”
      </div>
      <div class="p-mb-2">
        One interesting observation we have made is that different ways of
        speaking to yourself work for different people. For example, you could
        say: “I will hit a high shot landing ten feet short of the hole.” That
        works for some, but others feel like they are being told what to do, and
        don't respond well to that direction. If you ask yourself the question,
        “Will I hit a high shot, landing ten feet short of the hole?” that
        requires you to internally answer the question and own that answer
        before stepping into the shot. That approach works for others.
      </div>
      <div class="p-mb-2">
        Annika Sorenstam was one of the best in the history of golf at making
        decisions and then having the courage to carry them out. But this was
        not an easy thing for her to learn. Early in her career, Annika would
        write on her visor, “Face your fear,” as a reminder of the commitment
        she needed to carry with her into the Play Box. Ai Miyazato started to
        win LPGA tournaments when she found a similar level of belief in her
        decisions. You can literally talk yourself into being a better player.
      </div>
      <div class="p-mb-2">
        On the final hole of the 2009 Samsung World Championship, Ai was leading
        by one stroke over Na Yeon Choi. With 205 yards left to a green guarded
        by water and with a so-so lie, Ai decided to hit a 5-wood instead of
        laying up. The ball landed on the front of the green and rolled back
        into the water, and Ai finished second by one stroke. We were very
        curious as to how she was going to handle this disappointing outcome.
      </div>
      <div class="p-mb-2">
        When we saw her, the first thing Ai said was, “It was a five,” and she
        didn't mean a 5-wood. Ai had been training herself to fully commit to
        her decisions. She rates herself after shots on a scale of one to five,
        with five meaning complete commitment to the shot. “I knew the distance
        and the lie, and felt that I could totally trust going for it,” she
        said. “I want to practice being an aggressive player in these
        situations, and since I didn't know how Na Yeon would play the hole,
        being a hole behind me, I went for it.” Ai left the tournament not with
        disappointment, but with a feeling of mastering the skill of Decision
        and Commitment. She knew this skill would lead to future victories.
      </div>
      <div class="p-mb-2">
        The second LPGA event of the 2010 season was played in Singapore. Ai was
        once again leading by one stroke going into the final hole. After a
        great drive, she discussed the club selection with her caddie, who gave
        her the yardage and said he thought it was a 6-iron. But Ai's gut told
        her it was a 5-iron. No one else except the player hitting the shot can
        know what is going on inside of that player. Ai took the 5-iron and with
        full commitment hit it twelve feet left of the hole and won the
        tournament.
      </div>
      <div class="p-mb-2">
        When making shot decisions, it is crucial that you be honest with
        yourself. In the 1993 Masters, Chip Beck came to the par-5 fifteenth
        hole at Augusta National three strokes behind Bernhard Langer. Beck had
        236 yards to carry the water in front of the green, and the shot was
        playing into a slight breeze. A massive gallery lined the fairway and
        surrounded the green, and millions more were watching on TV. An eagle 3
        would get Beck right back in the hunt. The tension level was enormous.
      </div>
      <div class="p-mb-2">
        After careful consideration, Beck reached into his bag, pulled out a
        5-iron and lay up short of the water, thinking he could hit a wedge
        close with his third shot, make a birdie and pick up one stroke without
        the risk of hitting the ball into the water and losing all chance at
        victory. A murmur of surprise rippled through the gallery. On TV, a
        measure of disbelief was expressed. Why was Beck not being more
        aggressive in such a do-or-die situation?
      </div>
      <div class="p-mb-2">
        Unfortunately, Beck ended up making par on the hole, because he hit a
        poor third shot, and ultimately finished in second place, four strokes
        behind Langer. For years, Beck has been second-guessed about laying up
        when he had a chance to win the Masters. And for years, he's defended
        his shot selec“tion. “I didn't want to throw away my chances to win, and
        that shot would have been stupid,” Beck explained.”
      </div>
      <div class="p-mb-2">
        Shortly after the Masters, Byron Nelson, one of the great players and
        great gentlemen of the game, was asked about Chip's decision. “I would
        never second-guess another man in a situation like that,” Nelson said.
        “Maybe the shot didn't fit his eye. Maybe he wasn't comfortable with the
        lie. Maybe he wasn't sure he could pull it off. Only he knows what he is
        feeling.
      </div>
      <div class="p-mb-2">
        Nelson raises great points about the process that goes into making
        Decision and Commitment judgments. Beck never explained the shot in a
        broader context than simple strategy—he could make birdie by laying up
        and not risk the penalty stroke of going into the water—and left unsaid
        was the clear implication that he did not completely believe in his
        ability to pull off the shot. In that regard, he made the proper
        decision.
      </div>
      <div class="p-mb-2">
        <b>This is a crucial point.</b> The decision you commit to should take
        into consideration your state in the Play Box. How have you been hitting
        the ball today? What is your energy level like for the challenges of
        this shot? How are you handling the pressure of the situation right now
        in your match? This is a time to be totally honest with yourself. To do
        otherwise is denial and courting disaster.
      </div>
      <div class="p-mb-2">
        You must have a well-grounded knowledge of your game and your ability.
        That's a difficult task for many players. Most recreational players make
        their shot selection based on their best shot, not their average shot.
        Honesty under pressure is a valuable commodity. In that regard, Chip
        Beck probably did the right thing in laying up at that time. But if Beck
        had possessed a broader range of skills to use in pressure situations
        like that, he might have reached the decision that this was the time to
        go for the green, and he might have been able to commit to that decision
        with the complete confidence he could pull off the shot.
      </div>
      <div class="p-mb-2">
        Confidence must be practiced,” says Marlo Stil. “And it is best
        practiced under the specific circumstances and conditions where it will
        be needed. My confidence is built through trust, and trust in myself is
        gained by keeping committed to working on things I can control that will
        help me be a better player down the road.
      </div>
      <div class="p-mb-2">
        When we work with players, we hardly ever add to their Think Box
        routine. Mostly, we take things out and try to simplify the process.
        Players who overanalyze their shot options and endlessly rehearse their
        swing are less committed to their decisions and less satisfied with the
        results. Remember, as with all aspects of the game, everything you do in
        the Think Box should have a purpose, and that purpose is to get ready
        for the Play Box. Eliminate pointless actions. That will also make your
        playing partners happy as your pace of play improves!
      </div>
      <div class="p-mb-2">
        A lot of the learning that goes on at our school happens away from the
        range or the golf course, often in relaxed conversation. One of the
        questions we like to ask to get our players thinking is this: If five
        frogs are on a lily pad and one decides to jump into the water, how many
        frogs are left? Most people say four, but the answer is five. Because
        the frog decided to jump doesn't mean he jumped. He might not have been
        committed to his decision.
      </div>
      <div class="p-mb-2">
        Commitment is not a teeth-grinding, muscle-up experience. You can be
        fully committed and still do something very soft with a shot. It's all
        about fully trusting the decision you have made and putting it into
        action. You can train trust and your ability to face up to the shot. The
        best place to practice this skill is on the golf course. That's where
        the lily pads are. Put a mark on the scorecard for each shot you hit
        with 100 percent commitment. No matter what your handicap is, you can
        learn to master this skill. Decide. Commit. Jump!
      </div>
    </div>
  </Panel>
</template>
