// function openElement(id) {
//   var target = document.getElementById(id);
//   target.style.display = "block";
// }
export default function toggleElement(id, id2) {
  var target = document.getElementById(id);

  // if (target.style.visibility == "hidden") {
  //   target.style.visibility = "visible";
  // } else {
  //   target.style.visibility = "hidden";
  // }
  // if (typeof id2 !== "undefined") {
  //   var target2 = document.getElementById(id2);
  //   if (target2.style.visibility == "hidden") {
  //     target2.style.visibility = "visible";
  //   } else {
  //     target2.style.visibility = "hidden";
  //   }
  // } else {
  //   // console.log(`id2 from function toggleElement.js is undefined`);
  // }

  if (target.style.display == "none") {
    target.style.display = "block";
  } else {
    target.style.display = "none";
  }
  if (typeof id2 !== "undefined") {
    var target2 = document.getElementById(id2);
    if (target2.style.display == "none") {
      target2.style.display = "block";
    } else {
      target2.style.display = "none";
    }
  } else {
    // console.log(`id2 from function toggleElement.js is undefined`);
  }
}
