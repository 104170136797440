<template>
  <h1>Personality Traits (7 temperaments and an ability)</h1>
  <div>
    <Panel header="Image:" :toggleable="true">
      <div class="p-grid">
        <div class="p-col-6">
          <div>
            <img
              src="../assets/images/intraPersonal/intraPersonal1.png"
              alt="Mental"
              class="img9"
            />
          </div>
        </div>
        <div class="p-col-6">
          <div>
            <img
              src="../assets/images/intraPersonal/intraPersonal3.png"
              alt="Mental"
              class="img9"
            />
          </div>
        </div>
      </div>
    </Panel>
  </div>

  <Panel
    header="Abstract Thought (Graham - self-talk)"
    :toggleable="true"
    :collapsed="true"
  >
    <abstractThought />
  </Panel>
  <Panel
    header="Emotional Stability (Graham)"
    :toggleable="true"
    :collapsed="true"
  >
    <EmotionalStability />
  </Panel>
  <Panel
    header="Arousal -Anxious (Graham)"
    :toggleable="true"
    :collapsed="true"
  >
    <anxious />
  </Panel>
  <Panel header="Tough Minded (Graham)" :toggleable="true" :collapsed="true">
    <toughMinded />
  </Panel>
  <Panel header="Confidence (Graham)" :toggleable="true" :collapsed="true">
    <confidence />
  </Panel>
  <Panel header="Dominance (Graham)" :toggleable="true" :collapsed="true">
    <dominant />
  </Panel>
  <Panel
    header="Decisive (Graham Self-Sufficiency)"
    :toggleable="true"
    :collapsed="true"
  >
    <decisive />
  </Panel>

  <Panel
    header="Mental Theory (Background)"
    :toggleable="true"
    :collapsed="true"
  >
    <Panel
      header="Introduction: Personality Traits in Shot/Inter-Shot Routines"
      :toggleable="true"
      :collapsed="true"
    >
      <introduction />
    </Panel>
    <Panel header="Emotion" :toggleable="true" :collapsed="true">
      <emotion />
    </Panel>
    <Panel header="Learning" :toggleable="true" :collapsed="true">
      <learning />
    </Panel>
    <Panel header="Variability" :toggleable="true" :collapsed="true">
      <variability />
    </Panel>
    <Panel header="Resilience" :toggleable="true" :collapsed="true">
      <resilience />
    </Panel>
  </Panel>
</template>

<script>
import abstractThought from "@/components/mental/abstractThought";
import introduction from "@/components/mental/introduction";

import EmotionalStability from "@/components/mental/emotionalStability";
import anxious from "@/components/mental/anxious";
import toughMinded from "@/components/mental/toughMinded";
import confidence from "@/components/mental/confidence";
import dominant from "@/components/mental/dominant";
import decisive from "@/components/mental/decisive";

import emotion from "@/components/mental/emotion";
import learning from "@/components/mental/learning";
import variability from "@/components/mental/variability";
import resilience from "@/components/mental/resilience";

export default {
  name: "InterPersonal",
  components: {
    abstractThought,
    toughMinded,
    EmotionalStability,
    anxious,
    confidence,
    dominant,
    decisive,

    introduction,
    emotion,
    learning,
    variability,
    resilience,
  },
};
</script>

<style lang="scss">
@import "../assets/styles/images.scss";
.green {
  background-color: green;
}
.red {
  background-color: red;
}
</style>
