<template>
  <Panel header="Introduction" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Dominance Dominant is how assertive you are when dealing with people.
        Low scorers are deferential, cooperative, avoids conflict, submissive,
        humble, obedient, easily led, docile and accommodating. High scorers are
        dominant, forceful, assertive, aggressive, competitive, stubborn and
        bossy.
      </div>
      <div class="p-mb-2">
        lf a persistent and overwhelming urge to hit the ball as far as you can
        sometimes, or worse, always, interferes with strategy, or if a desire to
        force opportunities displaces patience, you probably are one of those
        golfers who plays the game with too much "dominance”. Being a very
        aggressive golfer does have one tempting advantage-it can lead to really
        low scores on days when you are magically lucky. Unfortunately the trade
        off is not worth it because more often than not, too much dominance can
        result in some very high numbers.
      </div>
      <div class="p-mb-2">
        On the other hand, there are those who play the game very cautiously,
        who swing the club tentatively to protect a good start or squander
        opportunities by refusing to take any risks. This approach has its
        advantages in that it reduces the odds of making reckless mistakes. But
        it also significantly reduces scoring opportunities. Such players are
        too submissive and are unlikely to reach their potential because they
        never shoot very low scores
      </div>
    </div>
  </Panel>
  <Panel
    header="Challenges to Maintaining Dominance"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Some of the challenges to maintaining competitive dominance include
        emotional stability, confidence, and tension. Let's take them one by
        one.
      </div>
      <div class="p-mb-2">
        Emotional stability: A lack of emotional stability in a golfer can lead
        to more dominance as the rounds progress. He would become more
        aggressive trying to make up shots and that only increased the intensity
        of his emotions. By the time he'd have completed his round, he would
        have abandoned his game plan, quickened his swing, and lost his ability
        to make smart decisions.
      </div>
      <div class="p-mb-2">
        First he must become aware of how his emotions are hampering his
        competitive dominance. Then he will come to understand that habits of
        thought influence his emotions, whereupon his emotions led to increased
        aggression, a faster swing, high-risk decisions, and poor play.
      </div>
      <div class="p-mb-2">
        Confidence: When confidence goes, even the most aggressive players will
        find themselves holding onto their shots, steering clubs, and guiding
        strokes. When you lose confidence in part of your game, you tend to
        respond based on your natural level of dominance. For example, if your
        putting is off and you are more dominant, you probably will work harder
        to get on the greens in regulation and closer to the hole. This can lead
        to shooting at "sucker" pins and trying to hit impossible shots. If you
        are more submissive, you probably will emphasize lagging every putt near
        the hole to avoid three putting.
      </div>
      <div class="p-mb-2">
        Tension: High tension seems to take submissiveness and dominance to
        their respective extremes. Whatever is most natural for you is
        exaggerated with tension. For those with dominance ratings above the
        champion levels, tension will cause them to be more aggressive with
        every shot or putt, trying to play shots the best players in the world
        might not even try. Those with Iow dominance ratings, the submissive
        player, will go into careful and deliberate mode, making choices that
        are sometimes ridiculously safe
      </div>
    </div>
  </Panel>
  <Panel
    header="Dominance and Course Management"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        COURSE MANAGEMENT The Mental skill associated with dominance We believe
        that, no matter what your handicap, you will have a better game plan for
        managing the courses you play if you maximize your competitive
        dominance. The game plan is your game with a champion level of
        dominance.
      </div>
      <div class="p-mb-2">
        Champion Level Of Dominance * The champion golfer strives to maintain a
        moderately aggressive approach to his play. He is capable of the
        following: * Developing a game plan for managing the course that rewards
        both patience and assertive play. * Implementing a strategy for playing
        the course that both challenges and maximizes his physical skills. *
        Sticking to the plan regardless of the possibility of shooting a very
        high or very low score. * Striving to maintain the tempo of the swing
        and stroke that is characteristic of his game. * Using normal, full, and
        confident swings regardless of the score or circumstances. * Feeling his
        score adequately represents his physical abilities most of the
        time-assuming his mental skills are strong.
      </div>
      <div class="p-mb-2">
        As soon as a golfer we deal with has the skills to play a full round of
        golf and is truly interested in getting the lowest score possible, we
        encourage him to begin developing his game plans. Often we are surprised
        by the lack of time even some advanced players designate to this part of
        their game. Learning or reminding yourself of some of the basics of good
        course management can get you started: * Knowledge of how far you hit
        the ball with each club when good contact is made. * The ability to use
        scorecards and yardage markers to determine distances to targets, as
        well as to the front, middle, and backs of greens. * An awareness of the
        speed and firmness of greens. * The ability and discipline to choose
        targets, clubs, and types of shots for the course you are playing that
        are based on your skills and not on emotions, impulse, what you think is
        expected by others, ego, or overly-concerned with results.
      </div>
      <div class="p-mb-2">
        As your skills evolve, so should your basic tools for course management.
        They might include: * Developing confidence in the clubs you will need
        for the course you are playing. * Knowing the different characteristics
        of the various grasses on golf courses, such as bent, Bermuda, Kikuyu,
        and so on. * Planning for possible conditions, such as wind, moisture,
        and temperature changes. * Estimating likely pin placements. * Deciding
        on a strategy that maximizes the use of your evolving skills while still
        ensuring a better than fifty-fifty chance of successfully executing the
        shots you have chosen.
      </div>
      <div class="p-mb-2">
        Once you have a real plan for playing the course that is moderately
        aggressive and based on your skills, strive to remain as committed to
        that plan as possible. Change your plan only when weather and conditions
        require it.
      </div>
      <div class="p-mb-2">
        Most good players solidify their game plan for competition during their
        practice rounds. Because this may require some trial and error, you may
        find it more productive to forget about scoring or betting during such a
        round. Confirm your plan for playing every hole with the probable
        conditions. Establish a firm commitment to your clubs, targets, and the
        types of shots for each hole, paying special attention to the difficult
        ones. And remember-your goal is to make choices that match your skills
        and maximize your opportunities on each hole.
      </div>
      <div class="p-mb-2">
        Champions are very aware of their personal tendencies in specific
        situations and develop their game plans accordingly. You should do
        likewise.
      </div>
      <div class="p-mb-2">
        If you are a dominant and aggressive person, you should resist choosing
        any shot that you could not successfully execute at least six out of ten
        times on the practice tee. Also, because the dominant player tends to
        have a fast, aggressive swing, when between clubs you should choose less
        club and just swing normally. By doing this, you're avoiding the
        situation in which you feel you have too much club. A situation where
        dominant players swing more guardedly and unnaturally. But most
        important, resist trying to aggressively make up for lost strokes by
        changing your game plan or by swinging harder and faster.
      </div>
      <div class="p-mb-2">
        If you are a submissive player, choose one or two holes a round that you
        will play a bit more aggressively, but still within your control. When
        between clubs, take more club and maintain a smooth, rhythmic swing.
        When your score is low, don't get careful and protective; stay committed
        to your game plan, your swing, and your mental routine.
      </div>
      <div class="p-mb-2">
        To stay competitive, it is vital that you commit to the following before
        each round:
      </div>
      <div class="p-mb-2">
        Give the process of your play a greater priority than the outcome of
        your play. In other words, remain committed to your routine, tempo, and
        game plan rather than let yourself get overly involved with the results
        of shots, score, and position. This will allow you the peace of mind to
        remain effectively competitive. In addition, having great tempo on every
        shot and putt is the key to making natural, full, uninhibited, and
        unforced swings.This will prevent you from getting pro- tective,
        careful, guided, or forceful in the way you swing the club.
      </div>
      <div class="p-mb-2">
        Regulate your thoughts between shots. These mental breaks help keep you
        from getting overly aggressive or tentative in an effort to make the
        best use of your skills.
      </div>
    </div>
  </Panel>
</template>
