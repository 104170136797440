import axios from "axios";

// Automate baseURL selection
var connectURL = "";
if (process.env.NODE_ENV == "development") {
  connectURL = "http://localhost:3000/";
} else {
  connectURL = "https://mjm-g-data-api.onrender.com";
}
console.log(`connectURL: ${connectURL}`);

const apiClient = axios.create({
  // baseURL: "http://localhost:3000/", // URL for local postgres db
  // baseURL: "https://mjm-g-data-api.herokuapp.com/", // To herokuapp
  // baseURL: "https://mjm-g-data-api.onrender.com", // URL to Render
  baseURL: connectURL,
  withCredentials: false,
  headers: {
    Accept: "application/json", // Any authentication & configuration
    "Content-Type": "application/json", // that the API server may need - see axios docs
  },
});

export default {
  // Rounds
  async postNote(note) {
    try {
      // console.log("IN EVENTSERVICE");
      return await apiClient.post("/api/note-data", note);
    } catch (error) {
      console.log(`EventService.js postNote error: ${error}`);
    }
  },
  async getLatestNote(text_code) {
    try {
      console.log(`EventService text_code: ${text_code}`);
      return await apiClient.get(`/api/latest-note/${text_code}`);
    } catch (err) {
      console.log(`EventService.js getAllPlayers error: ${err}`);
    }
  },
};
