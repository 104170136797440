<template>
  <Panel header="Full Swing" :toggleable="true" :collapsed="true">
    <Panel
      header="BlockTraining: Hardy Introduction"
      :toggleable="true"
      :collapsed="true"
    >
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          Swing exercises you can do at home or on the driving range, designed
          to help you groove the proper on-plane movements and correct common
          faults
        </div>
        <div class="p-mb-2">
          Swing exercises you can do at home or on the driving range, designed
          to help you groove the proper on-plane movements and correct common
          faults
        </div>
        <div class="p-mb-2">
          I can understand any unwillingness that you might have, because if you
          have played the game for any length of time, you have probably heard
          of and tried a great many drills. And I will certainly admit that
          there have been quite a number of drills that various teachers have
          professed to be cure-alls for an "ill" golf swing. The irony is that,
          in many cases, these drills have actually done more harm than good to
          the swings of many golfers. But if you will bear with me for a few
          moments, no matter which type of swing you decide to employ, I will
          convince you that drills can be both fun and useful in learning a new
          swing or fixing a faulty one.
        </div>
        <div class="p-mb-2">
          The first thing I'd like you to do is realize that, if you want to
          improve your golf swing, you have to work at it. I don't necessarily
          mean that you have to go to the practice range and hit 500 balls every
          day. Even if you wanted to do that, it's highly unlikely that you
          could. Chances are that you are an amateur golfer who plays the game
          as often as your schedule permits, given the responsibilities of your
          occupation, family, and other meaningful activities. Some of you may
          be able to play three rounds a week and hit balls a couple of times a
          week. Other readers may only play 15 to 20 rounds a year, much as you
          might like to play more. But regardless of your individual
          circumstances, if you want to improve your game, you need to take
          specific steps to permanently ingrain into your swing the various
          movements and positions that I have previously described.
        </div>
        <div class="p-mb-2">
          Still, at this point you might be asking, "Do I have to do these
          drills, and, if so, how often?" My answer is this: You should only do
          these drills if you want to hit better, more solid, more accurate golf
          shots, which will translate into substantially lower golf scores. I
          think that this is probably what you are hoping to accomplish;
          otherwise, you would not have read up to this point. Spending time on
          these drills will help.
        </div>
        <div class="p-mb-2">
          Once you understand which fundamentals belong in your swing, you
          should see immediate improvement. The proof will be the improvement in
          the flight of the ball. One thing that I firmly believe is that
          whenever a student takes a formal lesson, at the end of that lesson,
          the player should be striking the ball better than when he or she
          arrived. I am not one of those teachers who tells players that their
          shots are going to get worse before they eventually get better. If the
          quality of your shots is not better after you try to implement what
          the teacher has communicated to you, then at least one of three things
          is not right: 1. You do not fully understand what the teacher is
          trying to communicate. 2. You are not doing exactly what the
          instructor has told you to do. 3. What the instructor has told you to
          do was not correct to begin with.
        </div>
        <div class="p-mb-2">
          Practicing the drills or practice exercises contained in this chapter
          will certainly help you to understand what I'm trying to communicate.
          They are all clear and simple to execute, so you will have no trouble
          following my directions. I am certain that after working steadfastly
          on these drills and then starting to hit actual shots as you ingrain
          each vital move into your new one- or two-plane golf swing, your
          progress will be accelerated.
        </div>
        <div class="p-mb-2">
          It will encourage you greatly to know that a vast majority of the
          players on the PGA Tour have several favorite practice drills that
          they use whenever the opportunity arises. I mentioned, previously, how
          one-plane swingers Scott McCarron and Peter Jacobsen, among others,
          take a towel or a head cover and hold it between their left upper arm
          and chest as they make a practice swing. Let me assure you, the best
          players in the world are willing to use any drill that helps them feel
          the correct movements in the swing or fix a fault in their one- or
          two-plane technique. There isn't a player on the PGA Tour who thinks
          he is too good to work on swing improvement drills, including Tiger
          Woods, Vijay Singh, and Phil Mickelson. After you study the following
          drills and think about them a bit, I think you will agree that they
          are simple and sensible in what they will help you accomplish and are
          certainly worth a try.
        </div>
        <div class="p-mb-2">
          Four of the following eight drills are directed at the one-plane
          swinger, and four are meant for the two-planer. Do not practice all
          eight of the drills. If you are trying to produce a one-plane swing,
          do only the four designed to ingrain the specific movements of your
          personal action. If you opt for the two-plane swing, work only on the
          four drills designed to improve that swing type.
        </div>
        <div class="p-mb-2">
          Within each set of four drills, you will see that there are two drills
          that help you learn the correct body-turn movements for the one-plane
          and two-plane swings. Then there are two drills that help develop the
          correct arm swing for each method. The purpose of each drill is
          described, along with the procedure itself and a note on the type of
          ball-flight flaw or flaws that the drill will help you correct.
        </div>
      </div>
    </Panel>
    <Panel
      header="Block Training: Hardy Plane Truth"
      :toggleable="true"
      :collapsed="true"
    >
      <Panel
        id="On Plane Shoulder Turn"
        header="On Plane Shoulder Turn "
        :toggleable="true"
        :collapsed="true"
      >
        <img
          src="@/assets/images/hardy1/hardyDrillShoulder1.png"
          class="img10"
          alt="Hardy Posture"
        />
        <div class="accent1 fontSmall p-mb-2">
          On-Plane Shoulder Turn Drill: Step One (left); Step Two (center); Step
          Three (right). position, but not more than the 48-inch zone outside
          the ball. Thus, you know that, at impact, your shoulders have turned
          on plane.
          <span
            >&ensp;<a href="#BackswingShoulderTurn"
              >(Back to Backswing-Shoulder Turn)&ensp;</a
            ></span
          >
        </div>
        <div class="fontSmall taLeft">
          <div class="p-mb-2">
            Purpose: To develop a turn of the shoulders that stays on plane
            throughout the swing. This drill specifically checks whether the
            shoulders are on plane at the top of the backswing and at the point
            of impact.
          </div>
          <div class="p-mb-2">
            Procedure: You will need an assistant to help you with this drill,
            which is best performed with a long iron. While standing erect, hold
            the club with your left hand so that the clubhead is against the top
            of your sternum. The grip end of the club should extend horizontally
            outward, across and beyond your right shoulder. Because a long iron
            is between 38 and 40 inches long, the butt of the club should extend
            some 28 to 30 inches beyond the shirt seam on your right shoulder.
          </div>
          <div class="p-mb-2">
            Next, bend your spine forward so that your body (except for your
            arms) is in a normal address posture for, say, a medium or long
            iron. Your goal is to make a backswing and downswing shoulder turn
            that is within the parameters of the desired plane for the one-plane
            swinger. This is where your assistant comes in. He or she should
            stand in front of you and hold either a middle or long iron at arm's
            length, with the grip end pointing vertically downward. The grip end
            should be directly over the spot at which the ball would be located.
            The middle of the grip should be approximately knee height. To
            execute this drill correctly, it is critical that this vertical club
            be positioned precisely as described. That's because this club will
            act as an indicator of whether your shoulders are turning on plane.
            If this indicator is not positioned correctly, then the feedback you
            get from this drill will also be inaccurate.
          </div>
          <div class="p-mb-2">
            Okay, you are in your address posture, holding your own club
            horizontally across your right or rear shoulder. From here, simply
            turn your shoulders as you would in making a normal backswing, while
            keeping your club in position against the right shoulder. From this
            top-of-the-backswing position, an imaginary line extension of the
            club you are holding should point through the left shoulder and into
            the imaginary four-foot zone outside the ball, as discussed earlier.
            From here, turn your shoulders freely and fully down and through an
            imaginary impact and follow-through. As you do this, the grip end of
            your club should strike the grip end of the club your partner is
            dangling above the ball's position. It will only do so if your
            shoulders have turned along the acceptable plane line for the
            one-plane player, which we discussed earlier—that is, a plane that
            runs down from the shoulders into the zone just outside the ball.
            You see, the club that you're holding beyond your right shoulder
            serves as an extension or a visible indicator of the plane line your
            shoulders are turning on. At the simulated point of impact, if your
            grip contacts the grip end of the club dangling above the imaginary
            ball, you can see that if the line formed by the club were extended
            to the ground, it would touch the ground at a point in the zone that
            is outside the ball's
          </div>
          <div class="p-mb-2">
            Conversely, if your club's grip does not hit the grip your partner
            is holding, you know you are not swinging on the desired plane. If
            your club hits your partner's club on the club shaft, above the
            grip, it indicates that you've turned your shoulders on too flat of
            a plane, too horizontally for a one-plane swing. If the opposite
            occurs—that is, your club passes below the grip on your partner's
            club—it means your shoulders are tilting or turning too steeply or
            in too much of an upright manner.
          </div>
          <div class="p-mb-2">
            There's a good tip to be learned here, especially if you feel as if
            you turned your shoulders at right angles to your spine, yet your
            club still didn't strike the vertical club in its grip area: It's
            possible that you did make a turn that was at a right angle to your
            spine. However, your spine angle at address was incorrect. You may
            simply need to tilt your spine more forward or less forward at
            address, and then make exactly the same movement to create a
            shoulder turn that is perfectly on plane.
          </div>
          <div class="p-mb-2">
            If your spine angle at address was correct and your club's grip is
            still not contacting the grip end of the dangling club, this tells
            you that you are tilting your shoulders in some fashion during the
            swing, rather than turning them. If you dip your right shoulder at
            the start of the downswing, which many golfers do, your shoulder
            turn coming into the ball will be too steep and your club will pass
            underneath the vertical club. If you move your head forward on the
            downswing and lunge at the ball, which is another very common fault,
            your right shoulder will move farther outward and higher than it
            should be, so your club will hit the vertical club somewhere up on
            the shaft. So this drill will also indicate if you're not holding
            your head steady throughout the swing motion. Keeping the head
            steady, I should note, is something that is required to successfully
            complete every one-plane drill I will show you.
          </div>
          <div class="p-mb-2">
            The On-Plane Shoulder Turn Drill immediately shows you if something
            is amiss either in your setup posture or in the shoulder turn
            itself. If you have trouble making grip-to-grip contact, ask your
            partner for feedback as to which of the flaws I've described is the
            likely culprit. Then, make the adjusted shoulder-turn motion. Once
            you have your shoulders turning on plane, repeat the drill at least
            10 times. This entire process should take you no more than two
            minutes or so, even if you are not turning on plane at the start.
            Once you have this drill down pat, I recommend that you repeat it
            periodically, preferably in front of a mirror, at least once per
            week, to make sure you are continuing to make a correct, on-plane
            shoulder turn and have not fallen back into any previous bad habits.
          </div>
          <div class="p-mb-2">
            Ball-Flight Correction: Learning how to turn your shoulders in a
            one-plane swing is especially helpful to golfers who come over the
            top. These golfers tend to hit either pulled shots or pull-slices.
            An over-the-top, outside-in path also can cause skied shots because
            this swing path is inherently steeper than it should be. With an
            on-plane shoulder turn, your clubhead will move on a path from
            inside to along the target line at impact, then return to the inside
            after impact. The result will be a shot that starts on your target
            line or a shade right of the target with a straight flight or a
            slight right-to-left draw.
          </div>
        </div>
      </Panel>
      <Panel
        id="ObliqueAbsStrengthener"
        header="Oblique Abs Turn - Strengthener"
        :toggleable="true"
        :collapsed="true"
      >
        <div class="fontSmall taLeft">
          <div class="p-mb-2">
            Purpose: This is an excellent combination drill-exercise designed to
            strengthen your body turn both away from the ball and down and
            through the impact zone, thus adding speed and power to your swing.
            <span
              >&ensp;<a href="#BackswingShoulderTurn"
                >(Back to Backswing: Shoulder Turn)</a
              >&ensp;<a href="#DownswingShoulders"
                >(Back to Downswing: Shoulders)&ensp;</a
              ></span
            >
          </div>
          <div class="p-mb-2">
            Procedure: The muscles you will be focusing on are the oblique
            abdominal muscles, which are the patches of muscle located on either
            side of your abdomen. These are the muscles that rotate the middle
            and upper torso.
          </div>
          <div class="p-mb-2">
            There are exactly two ways to do this drill and any number of
            options on how much weight, if any, you want to use. So, any way
            that you do this exercise, you will be able to measure just how much
            you have improved your oblique abdominal strength.
          </div>
          <div class="p-mb-2">
            The first way to do this exercise is on a gym machine specifically
            designed to work the oblique abdominal muscles. If you happen to be
            a member of a local gym, you are probably quite familiar with it.
            With the standard oblique abdominal machine, you will begin in a
            sitting position. The machine will have two pads attached to levers
            on either side of you at about ear height. You place your hands on
            the outsides of these pads and then push them together, so that your
            hands are in front of you, palms facing.
          </div>
          <div class="p-mb-2">
            From here, your task is to simply turn your body to your right, from
            the waist up, as a right-handed player would turn for the backswing.
            Make sure to turn as far as you can. You will feel tension in your
            oblique abdominal muscles from resistance coming from the weight of
            the apparatus. From there, turn back through your original starting
            position, turning as far as you can to your left. This motion mimics
            the downswing and follow-through. Make sure to make each turn fully
            and slowly. As you turn left, you'll again build up tension in the
            oblique abs.
          </div>
          <div class="p-mb-2">
            I should mention that, before you begin working out, you should set
            the machine to add any amount of weight that you want to the
            resistance to your turn. The weights are usually connected to the
            apparatus by means of a pulley system. On most machines you can
            start with no added weight if you wish or add as much as you can
            handle in 10-pound increments. It's a great exercise machine.
          </div>
          <div class="p-mb-2">
            A good question you might have is, "How much weight should I use?"
            If you have never used an oblique abdominal machine before, I
            recommend that you begin with no weight added on, get yourself
            settled into the machine correctly, take the position described, and
            then do one complete turn in each direction. If it's obvious that
            this is not a sufficient amount of resistance, start adding some
            weight, but do so very gradually. Everybody is different. Some
            people find that adding 10 to 20 pounds is plenty at the start, and
            there may be others who can do this exercise using 70, 80, even 100
            pounds of resistance. My advice is to find a weight level at which
            you can do 12 full repetitions (turning in both directions)
            comfortably. Then do three to five sets of 12 reps. If and when this
            starts to feel rather easy, increase the weight by 10 pounds. But
            it's much easier to do more repetitions with less weight than to
            strain with too much weight and risk injury.
          </div>
          <div class="p-mb-2">
            There is a simple way to do this exercise at home, without the
            benefit of an ab machine in the gym. Here's how.
          </div>
          <div class="p-mb-2">
            Put your hands against your abs and lower ribs, with your fingers
            pointing downward and your elbows pointing out. Bend forward from
            the waist by about 30 degrees, much as you would at address. Next,
            simply make the same moves that you would when using the abdominal
            machine, turning first to the right, then back as far as you can to
            the left. Again, do 12 full, slow repetitions.
          </div>
          <div class="p-mb-2">
            If you find that doing the exercise in this manner is not taxing
            enough, you can up the ante, so to speak, by holding a flat plate
            weight against your chest or by holding hand weights in each hand
            while still resting your hands on the sides of your torso. This will
            toughen the exercise considerably, so you probably will not need
            much weight. Peter Jacobsen does this drill while holding a 10-pound
            dumbbell in each hand. That is a lot of weight to carry, not only
            for the strain it puts on your abs but also on your hands and
            forearms. I would recommend that if you need some weight, start with
            a five-pound plate weight or a two-to five-pound dumbbell in each
            hand. Make the same full, slow turns in both directions, and do
            three sets of 12 repetitions. If you find you can increase the
            weight over time, that's fine. But don't push it. You might move up
            to a seven- or eight-pound weight in each hand and perhaps
            eventually 10 pounds. Just keep doing plenty of repetitions and
            you'll add plenty of speed to your turn through the ball.
          </div>
          <div class="p-mb-2">
            Ball-Flight Correction: This drill does not alter any curvature of
            the ball per se. What it does is add explosiveness to your swing
            and, with it, increased clubhead speed for a longer carry with any
            club in the bag.
          </div>
        </div>
      </Panel>
      <Panel
        id="ElbowsUpArmsAcross"
        header="Elbows Up, Arms Across Drill"
        :toggleable="true"
        :collapsed="true"
      >
        <img
          src="@/assets/images/hardy1/hardyDrillArms1.png"
          class="img10"
          alt="Hardy Posture"
        />
        <div class="accent1 fontSmall p-mb-2">
          From this position, simply turn back then through, feeling how the
          elbows work during the Elbow Up, Arms Across Drill, and, of course,
          during the swing.
        </div>
        <img
          src="@/assets/images/hardy1/hardyDrillArms2.png"
          alt="Hardy Posture"
        />
        <div class="accent1 fontSmall p-mb-2">
          To get a good feel for how the left arm and right arm work during the
          Elbows Up, Arms Across Drill and during a short compact swing,
          practice swinging back (left) then through (right) with a club in your
          hand. Concentrate on how the elbows hinge and the arms move across
          your chest—first the right elbow and left arm on the backswing, then
          the left elbow and right arm on the downswing.
          <span
            >&ensp;<a href="#BackswingArms"
              >(Back to Backswing: Arms)&ensp;</a
            ></span
          >
          or
          <span
            >&ensp;<a href="#FollowThroughArms"
              >(Back to Follow Through: Arms)&ensp;</a
            ></span
          >
        </div>
        <div class="fontSmall taLeft">
          <div class="p-mb-2">
            Purpose: The purpose of this drill is to teach you precisely how the
            arms should move during the one-plane swing. The movements of the
            arms are the opposite of each other in what each does during the
            backswing and the downswing.
          </div>
          <div class="p-mb-2">
            Procedure: There are several parts or options to this drill. The
            first part is to train both arms in a slightly exaggerated manner as
            to how they will bend during the swing. With a bent-over spine angle
            and your arms hanging down in front of you, lift your upper arms
            while simultaneously bending the elbows. Your upper arms should be
            angled back with both elbows pointing straight behind you. In
            addition to your upper arms moving up and behind you, make sure that
            the elbows have moved to or just past the seams at the sides of your
            shirt. Also make sure that there is an inch or two between the
            inside of each biceps and the sides of your chest. This is important
            because in the actual swing, you will need the arms slightly away
            from the body to facilitate your freedom of movement.
          </div>
          <div class="p-mb-2">
            This up-and-back thrust you have made is precisely how your arms
            should work on either side of the ball. On the backswing, your right
            arm will be making this up-and-back movement. Then, starting just
            before impact and continuing into the follow-through, it will be the
            left arm that goes up and back. Move your arms behind you in this
            fashion 10 times, making the motion fairly slowly, holding the
            position for one second and then lowering your arms back in front of
            you.
          </div>
          <div class="p-mb-2">
            The second part of this drill is to practice what the opposite arm
            is doing when one arm is folding. As your right arm folds on the
            backswing, your left moves horizontally across your chest and
            rotates so the left forearm is somewhat facing the sky. From a
            standing position, simply swing your left arm up and rotate it
            across your chest. As you make this move, your left biceps muscle
            should press firmly against your left pectoral (chest) muscle. Move
            your left arm up in this fashion, hold for one second, and then
            return it to your side. Repeat this exercise 10 times. Then reverse
            this drill, extending and rotating your right arm horizontally
            across your chest, with the right biceps pressing against your right
            pectoral muscle. This is how your right arm should move just beyond
            the impact zone and into the follow-through, while your left arm is
            folding up and back. Again, repeat this right-arm-across-the-chest
            action 10 times.
          </div>
          <div class="p-mb-2">
            In addition to practicing these singular arm positions as described,
            you should also practice the Elbows Up, Arms Across Drill while
            holding a club. Just fold your right arm up and back as described on
            the backswing, with your left arm rotating and pressing horizontally
            against your chest. Then reverse the move for the downswing, with
            your right arm rotating and crossing the chest while the left arm
            folds up and back.
          </div>
          <div class="p-mb-2">
            Finally, once you have started feeling comfortable with these
            slightly exaggerated arm movements, you can practice this drill
            while hitting some chip and pitch shots. As you get the feel of the
            movement in miniature, so to speak, you can gradually incorporate
            these movements into some very compact punch-type shots. Use a
            seven- or eight-iron. As you hit these shots, focus solely on the
            correct arm movements. Make sure to keep your hands and wrists very
            firm throughout this short swing.
          </div>
          <div class="p-mb-2">
            Ball-Flight Correction: By teaching one arm to bend upward and
            backward while the opposite arm is swinging across the chest, this
            drill is very helpful to the golfer who keeps both arms too much in
            front of the body throughout the swing. Again, keeping the arms in
            front of the chest is desirable only if you are striving to develop
            a two-plane rather than a one-plane swing. A player bent over in a
            one-plane posture, keeping his or her arms on front of their chest,
            will have a narrow swing arc—that is, the club will move up from and
            back down to the ball on a steep angle. When the arc is too narrow,
            flush contact is very hard to obtain, and both fat and thin shots
            tend to be the result. The Elbows Back, Arms Across Drill teaches
            the golfer to develop the correct one-plane arm action. This, in
            turn, makes it easier for the golfer to drive the clubhead squarely
            into the back of the ball, providing maximum distance as well as
            more consistent distances with every club.
          </div>
        </div>
      </Panel>
      <Panel
        id="OnePlaneRelease"
        header="One-Plane Release Drill"
        :toggleable="true"
        :collapsed="true"
      >
        <img
          src="@/assets/images/hardy1/hardyDrillRelease1.png"
          class="img10"
          alt="Hardy1 Release1"
        />
        <div class="accent1 fontSmall p-mb-2">
          To educate your hands and train them to work correctly in the
          one-plane swing, practice moving them over what I refer to as the
          swing's "inner circle."
        </div>
        <img
          src="@/assets/images/hardy1/hardyDrillRelease2.png"
          alt="Hardy1 Release2"
        />
        <div class="accent1 fontSmall p-mb-2">
          The more advanced stage of the One-Plane Release Drill involves
          simultaneously swinging the hands along the inner circle and the club
          along the outer circle.
          <span><a href="#ImpactArms">Impact Arms</a></span>
        </div>
        <div class="fontSmall taLeft">
          <div class="p-mb-2">
            Purpose: The purpose of this drill is to train your hands and arms
            to work correctly through the impact zone in the one-plane swing.
            The arms are somewhat active during the impact zone while the hands
            are essentially passive in the one-plane swing. However, the hands
            and arms must describe a specific arc through the impact zone. If
            the arc of the hands and arms is consistent, then the clubhead will
            also follow a consistent arc or path to meet the ball squarely.
          </div>
          <div class="p-mb-2">
            Procedure: Before we get started with the movements in this drill,
            you need to understand a few terms regarding the movement of your
            hands, arms, and the club through the impact zone. We are going to
            discuss the path of the hands and arms along what I call the "inner
            circle" and the clubhead along what I call the "outer circle."
          </div>
          <div class="p-mb-2">
            Imagine that, when you're over the ball, there's a curved line on
            the ground in front of your feet. This line begins a bit behind your
            right foot then curves outward in front of your feet by several
            inches. Then the line curves back around to the outside of your left
            foot. This is the inner circle of the impact zone. Your hands should
            move directly over this imaginary portion of a circle.
          </div>
          <div class="p-mb-2">
            Without using a club, bend your spine and place your hands and arms
            opposite your right side. Your right elbow should be positioned
            behind your right hip, against your right side, not in front of your
            right hip. Practice moving your hands smoothly above this inner
            circle. Actu­ally, your hips, shoulders, and arms will supply some
            of the energy to move your hands along the path of the inner circle.
            Make sure that in all parts of this drill you keep your hands and
            wrists very firm. Keep moving your hips, shoulders, arms, and hands
            back and forth over this imaginary line. Notice that, at the start,
            with your hands opposite your right side, you can get your hands
            back around almost to impact by just turning your hips and
            shoulders. Notice also how, at the point just before impact, your
            arms start to move across your torso from the right side to the left
            while you still turn your body and your hands still stay over the
            inner circle. While your arms are moving around and across your
            torso, your hips and shoulders continue to turn.
          </div>
          <div class="p-mb-2">
            The particular part of the arms moving across your torso that
            deserves attention is the left biceps, the left forearm, and the
            right arm. At the top of the backswing and during the downswing, the
            left biceps is approximately opposite the middle of your chest. Just
            before impact it begins to move left, across your chest toward the
            left side of your torso. Its ultimate position is discussed in the
            Elbows Up, Arms Across Drill. While this is occurring, the left
            forearm continues to rotate and the right arm straightens.
          </div>
          <div class="p-mb-2">
            Does this movement of your hands feel exactly like it does when you
            are making your normal swing? Chances are it won't. Most golfers
            tend to use too much independent wrist action in the impact zone.
            The body and arm motion, beyond what is mentioned above, will be the
            amount needed when you have a club in your hands to bring the
            clubface "square" and out onto the outer circle. Your hands are now
            moving along a circular path. There will be some movement down and
            then back up again, as you move down and then up the plane from the
            right to the left on the circle. The majority of golfers will move
            their hands down and through impact on a straighter line than the
            inner circle I've described. If your hands move through impact on a
            straighter line, it means that the clubhead will be forced to move
            into impact either off plane or in the wrong direction.
          </div>
          <div class="p-mb-2">
            Next, take a middle iron in hand and set up in the one-plane address
            position. Move your hands back to be opposite your right side with
            your right elbow behind your hip, not in front. Then turn through,
            letting your arms release across your torso, all the while keeping
            your hands above the inner circle. Repeat this movement at least a
            dozen times. As you get the feel of this, note how the clubhead is
            moving. You'll see that it's moving on an arc that is of the same
            shape as the inner circle but is well outside your hands. The arc on
            the ground underneath where your clubhead is moving is what I call
            the outer circle. As you move your hands the relatively short
            distance described over the inner circle, the clubhead will
            automatically travel along the much larger arc of the outer circle.
            In actual play, all you have to do is bend over sufficiently and
            stand the correct distance from the ball for the shot at hand (which
            is another way of saying the ball would be located at or near the
            top of the outer circle). Then simply turn your hips and your
            shoulders on plane and release your arms and hands along the inner
            circle, and your clubhead will move much more quickly through impact
            with the ball. Once again, there must be enough rotation of the left
            forearm that the shaft moves out in front of the arms and the
            clubface, as it reaches the start of the outer circle, is square to
            that portion of the circle. Any left-arm rotation from that point on
            is just to ensure that your clubhead stays on the outer circle and
            that you maintain a square clubface to the arc, not to the target.
            The clubface that is square to the arc will be moving from open to
            closed in relation to the target. If there is insufficient left-arm
            rotation in an actual swing, the club will be held too far behind
            the arms, with the clubface too open. As the arms approach impact,
            the right arm begins to straighten and swing on the arc to the left.
          </div>
          <div class="p-mb-2">
            Practice this movement of your arms and the club as often as you
            can. Keep an eye peeled on the clubhead as you do this to make sure
            it is staying on that outer circle. Keep working your arms and the
            club until the movement begins to feel natural.
          </div>
          <div class="p-mb-2">
            The movement of your arms and the clubhead I've described is really
            a simple matter of physics. Your arms are acting as the inner or
            centripetal force. They are closer to the hub of the swing (your
            torso) and as such are moving with as much speed as you can muster.
            However, the speed of your arms is just a small fraction of the
            speed imparted to the clubhead. The clubhead receives the outer or
            centrifugal effect from the centripetal force. As your arms swing
            and release around and inward across your torso, the clubhead is
            thrown outward and into the ball at tremendous speed. The action is
            much like that of a Ferris wheel or a merry-go-round, where the
            energy imparted from the inner force is distributed and greatly
            multiplied in the outward effect (the clubhead).
          </div>
          <div class="p-mb-2">
            Again, move your hips, shoulders, and arms as quickly as you can.
            Note that as you make this move, your right hand should not
            completely cross over your left. Keep the hands firm. In the
            one-plane swing, the crossover does occur, but it should not take
            place until farther into the follow-through, when your hands are
            above waist height. (As we'll see shortly, the two-plane swing has a
            much more active release.)
          </div>
          <div class="p-mb-2">
            Finally, once you have the feel of the one-plane release with a club
            in your hands, go ahead and hit some short shots using this move.
            You'll start to see that the contact between the clubface and the
            ball will become more consistent the more you do this drill.
          </div>
          <div class="p-mb-2">
            Ball-Flight Correction: While the One-Plane Release Drill will help
            you consistently make square contact with the ball, it is
            particularly helpful to amateurs who have trouble controlling the
            direction of the clubface at impact (an open clubface at impact
            causing a push or slice; a closed clubface at impact contributing to
            a pull or hook). By setting up squarely to your target and then
            ingraining the one-plane release, you can expect to hit all your
            full shots with a ball flight that is very close to straight. It is
            also a very good way to learn the low, driven punch shot that is
            particularly useful in the wind.
          </div>
        </div>
      </Panel>
      <Panel
        id="OnePlaneRelease"
        header="One-Plane Release Drill"
        :toggleable="true"
        :collapsed="true"
      >
        <img
          src="@/assets/images/hardy1/hardyDrillRelease1.png"
          class="img10"
          alt="Hardy1 Release1"
        />
        <div class="accent1 fontSmall p-mb-2">
          To educate your hands and train them to work correctly in the
          one-plane swing, practice moving them over what I refer to as the
          swing's "inner circle."
        </div>
        <img
          src="@/assets/images/hardy1/hardyDrillRelease2.png"
          alt="Hardy1 Release2"
        />
        <div class="accent1 fontSmall p-mb-2">
          The more advanced stage of the One-Plane Release Drill involves
          simultaneously swinging the hands along the inner circle and the club
          along the outer circle.
          <span><a href="#ImpactArms">Impact Arms</a></span>
        </div>
        <div class="fontSmall taLeft">
          <div class="p-mb-2">
            Purpose: The purpose of this drill is to train your hands and arms
            to work correctly through the impact zone in the one-plane swing.
            The arms are somewhat active during the impact zone while the hands
            are essentially passive in the one-plane swing. However, the hands
            and arms must describe a specific arc through the impact zone. If
            the arc of the hands and arms is consistent, then the clubhead will
            also follow a consistent arc or path to meet the ball squarely.
          </div>
          <div class="p-mb-2">
            Procedure: Before we get started with the movements in this drill,
            you need to understand a few terms regarding the movement of your
            hands, arms, and the club through the impact zone. We are going to
            discuss the path of the hands and arms along what I call the "inner
            circle" and the clubhead along what I call the "outer circle."
          </div>
          <div class="p-mb-2">
            Imagine that, when you're over the ball, there's a curved line on
            the ground in front of your feet. This line begins a bit behind your
            right foot then curves outward in front of your feet by several
            inches. Then the line curves back around to the outside of your left
            foot. This is the inner circle of the impact zone. Your hands should
            move directly over this imaginary portion of a circle.
          </div>
          <div class="p-mb-2">
            Without using a club, bend your spine and place your hands and arms
            opposite your right side. Your right elbow should be positioned
            behind your right hip, against your right side, not in front of your
            right hip. Practice moving your hands smoothly above this inner
            circle. Actu­ally, your hips, shoulders, and arms will supply some
            of the energy to move your hands along the path of the inner circle.
            Make sure that in all parts of this drill you keep your hands and
            wrists very firm. Keep moving your hips, shoulders, arms, and hands
            back and forth over this imaginary line. Notice that, at the start,
            with your hands opposite your right side, you can get your hands
            back around almost to impact by just turning your hips and
            shoulders. Notice also how, at the point just before impact, your
            arms start to move across your torso from the right side to the left
            while you still turn your body and your hands still stay over the
            inner circle. While your arms are moving around and across your
            torso, your hips and shoulders continue to turn.
          </div>
          <div class="p-mb-2">
            The particular part of the arms moving across your torso that
            deserves attention is the left biceps, the left forearm, and the
            right arm. At the top of the backswing and during the downswing, the
            left biceps is approximately opposite the middle of your chest. Just
            before impact it begins to move left, across your chest toward the
            left side of your torso. Its ultimate position is discussed in the
            Elbows Up, Arms Across Drill. While this is occurring, the left
            forearm continues to rotate and the right arm straightens.
          </div>
          <div class="p-mb-2">
            Does this movement of your hands feel exactly like it does when you
            are making your normal swing? Chances are it won't. Most golfers
            tend to use too much independent wrist action in the impact zone.
            The body and arm motion, beyond what is mentioned above, will be the
            amount needed when you have a club in your hands to bring the
            clubface "square" and out onto the outer circle. Your hands are now
            moving along a circular path. There will be some movement down and
            then back up again, as you move down and then up the plane from the
            right to the left on the circle. The majority of golfers will move
            their hands down and through impact on a straighter line than the
            inner circle I've described. If your hands move through impact on a
            straighter line, it means that the clubhead will be forced to move
            into impact either off plane or in the wrong direction.
          </div>
          <div class="p-mb-2">
            Next, take a middle iron in hand and set up in the one-plane address
            position. Move your hands back to be opposite your right side with
            your right elbow behind your hip, not in front. Then turn through,
            letting your arms release across your torso, all the while keeping
            your hands above the inner circle. Repeat this movement at least a
            dozen times. As you get the feel of this, note how the clubhead is
            moving. You'll see that it's moving on an arc that is of the same
            shape as the inner circle but is well outside your hands. The arc on
            the ground underneath where your clubhead is moving is what I call
            the outer circle. As you move your hands the relatively short
            distance described over the inner circle, the clubhead will
            automatically travel along the much larger arc of the outer circle.
            In actual play, all you have to do is bend over sufficiently and
            stand the correct distance from the ball for the shot at hand (which
            is another way of saying the ball would be located at or near the
            top of the outer circle). Then simply turn your hips and your
            shoulders on plane and release your arms and hands along the inner
            circle, and your clubhead will move much more quickly through impact
            with the ball. Once again, there must be enough rotation of the left
            forearm that the shaft moves out in front of the arms and the
            clubface, as it reaches the start of the outer circle, is square to
            that portion of the circle. Any left-arm rotation from that point on
            is just to ensure that your clubhead stays on the outer circle and
            that you maintain a square clubface to the arc, not to the target.
            The clubface that is square to the arc will be moving from open to
            closed in relation to the target. If there is insufficient left-arm
            rotation in an actual swing, the club will be held too far behind
            the arms, with the clubface too open. As the arms approach impact,
            the right arm begins to straighten and swing on the arc to the left.
          </div>
          <div class="p-mb-2">
            Practice this movement of your arms and the club as often as you
            can. Keep an eye peeled on the clubhead as you do this to make sure
            it is staying on that outer circle. Keep working your arms and the
            club until the movement begins to feel natural.
          </div>
          <div class="p-mb-2">
            The movement of your arms and the clubhead I've described is really
            a simple matter of physics. Your arms are acting as the inner or
            centripetal force. They are closer to the hub of the swing (your
            torso) and as such are moving with as much speed as you can muster.
            However, the speed of your arms is just a small fraction of the
            speed imparted to the clubhead. The clubhead receives the outer or
            centrifugal effect from the centripetal force. As your arms swing
            and release around and inward across your torso, the clubhead is
            thrown outward and into the ball at tremendous speed. The action is
            much like that of a Ferris wheel or a merry-go-round, where the
            energy imparted from the inner force is distributed and greatly
            multiplied in the outward effect (the clubhead).
          </div>
          <div class="p-mb-2">
            Again, move your hips, shoulders, and arms as quickly as you can.
            Note that as you make this move, your right hand should not
            completely cross over your left. Keep the hands firm. In the
            one-plane swing, the crossover does occur, but it should not take
            place until farther into the follow-through, when your hands are
            above waist height. (As we'll see shortly, the two-plane swing has a
            much more active release.)
          </div>
          <div class="p-mb-2">
            Finally, once you have the feel of the one-plane release with a club
            in your hands, go ahead and hit some short shots using this move.
            You'll start to see that the contact between the clubface and the
            ball will become more consistent the more you do this drill.
          </div>
          <div class="p-mb-2">
            Ball-Flight Correction: While the One-Plane Release Drill will help
            you consistently make square contact with the ball, it is
            particularly helpful to amateurs who have trouble controlling the
            direction of the clubface at impact (an open clubface at impact
            causing a push or slice; a closed clubface at impact contributing to
            a pull or hook). By setting up squarely to your target and then
            ingraining the one-plane release, you can expect to hit all your
            full shots with a ball flight that is very close to straight. It is
            also a very good way to learn the low, driven punch shot that is
            particularly useful in the wind.
          </div>
        </div>
      </Panel>
    </Panel>
    <Panel
      header="Block Training: Hardy Release"
      :toggleable="true"
      :collapsed="true"
    >
      <Panel header="3 Finger Throw" :toggleable="true" :collapsed="true">
        <img src="@/assets/images/training/drt1.png" class="img10" />
        <div class="accent1 fontSmall p-mb-2">
          DRT-1. Hold the club in only the last three fingers of your right hand
          keeping the thumb and index finger off the club.
        </div>
        <img src="@/assets/images/training/drt2.png" class="img10" />
        <div class="accent1 fontSmall p-mb-2">
          DRT-2. Throw the clubhead past the handle in a
          half-sideard/half-underarm motion with NO ROLLOVER of the forearm.
        </div>
        <img src="@/assets/images/training/drt3.png" class="img10" />
        <div class="accent1 fontSmall p-mb-2">
          DRT-3. Note the exaction position of Ben Hogan's wrists and hands. The
          right wrist is in full flexion and the left wrist is in full
          extension. The forearms have not turned over and the back of his right
          hand is facing the target.
        </div>
        <img
          src="@/assets/images/training/threeFingerThrowText1.png"
          class="img10"
        />
        <img
          src="@/assets/images/training/threeFingerThrowText2.png"
          class="img10"
        />
        <img
          src="@/assets/images/training/threeFingerThrowText3.png"
          class="img10"
        />
        <img
          src="@/assets/images/training/threeFingerThrowText4.png"
          class="img10"
        />
      </Panel>
      <Panel header="Throw and Drop" :toggleable="true" :collapsed="true">
        <img src="@/assets/images/training/drt5.png" class="img10" />
        <div class="accent1 fontSmall p-mb-2">
          DRT-5. The RIT release requires a parallel drop from the top of
          backswing plane onto the address shaft plane.
        </div>
        <img src="@/assets/images/training/drt6.png" class="img10" />
        <div class="accent1 fontSmall p-mb-2">
          DRT-6. Thes photos illustrate a right wrist throw without the arms
          moving.
        </div>
        <img src="@/assets/images/training/drt7.png" class="img10" />
        <div class="accent1 fontSmall p-mb-2">
          DRT-7. These photos illustrate a left arm downward and inward drop
          without any throw.
        </div>
        <img src="@/assets/images/training/drt8.png" class="img10" />
        <div class="accent1 fontSmall p-mb-2">
          DRT-8. Shows a correct coordination of throwing and dropping the left
          arm at the same time.
        </div>
        <img src="@/assets/images/training/drt9.png" class="img10" />
        <div class="accent1 fontSmall p-mb-2">
          DRT-9. Shows an incorrect coordination where the right wrist throwing
          motions has happened more rapidly than the left arm dropping motion.
        </div>
        <img src="@/assets/images/training/drt10.png" class="img10" />
        <div class="accent1 fontSmall p-mb-2">
          DRT-10. Shows an incorrect coordination where the left arm is dropping
          but the right wrist isn't sufficiently throwing the clubhead outward
          and around.
        </div>
        <img src="@/assets/images/training/drt11.png" class="img10" />
        <div class="accent1 fontSmall p-mb-2">
          DRT-11. These photos illustrate the correct coordination of throw and
          drop and allowing the clubhead to return to the ground.
        </div>
        <img src="@/assets/images/training/drt12.png" class="img10" />
        <div class="accent1 fontSmall p-mb-2">
          DRT-12. This position shows a good left arm drop but insufficient
          outward clubhead throw.
        </div>
        <img src="@/assets/images/training/drt13.png" class="img10" />
        <div class="accent1 fontSmall p-mb-2">
          DRT-13. This position shows a correct throw but insufficient left arm
          downward and inward drop.
        </div>
        <img src="@/assets/images/training/drt14.png" class="img10" />
        <div class="accent1 fontSmall p-mb-2">
          DRT-14. This position shows neither enough left arm drop or right hand
          throw. In fact this is a classic LOP position where the left arm has
          pulled outward and downward while uncocking the left wrist.
        </div>

        <img src="@/assets/images/training/throwDropText1.png" class="img10" />
        <img src="@/assets/images/training/throwDropText2.png" class="img10" />
        <img src="@/assets/images/training/throwDropText3.png" class="img10" />
      </Panel>
      <Panel header="Knife In The Groin" :toggleable="true" :collapsed="true">
        <img src="@/assets/images/training/drt4.png" class="img10" />
        <div class="accent1 fontSmall p-mb-2">
          DRT-1. Hold the club in only the last three fingers of your right hand
          keeping the thumb and index finger off the club.
        </div>
        <img
          src="@/assets/images/training/knifeInGroinText1.png"
          class="img10"
        />
      </Panel>
      <Panel header="Underarm Throw" :toggleable="true" :collapsed="true">
        <img src="@/assets/images/training/drt15.png" class="img10" />
        <div class="accent1 fontSmall p-mb-2">
          DRT-15. Hold a golf ball in the fingers of the right hand and make an
          underhand motion to toss the ball out towards an imaginary ball on the
          target line.
        </div>
        <img src="@/assets/images/training/drt16.png" class="img10" />
        <div class="accent1 fontSmall p-mb-2">
          DRT-16. While holding a club in only the right hand toss the clubhead
          in an underhand motion outward in an effort to hit the ball one
          handed.
        </div>
        <img
          src="@/assets/images/training/underarmThrowText1.png"
          class="img10"
        />
      </Panel>
      <Panel header="Anti-Hip Thrust" :toggleable="true" :collapsed="true">
        <img src="@/assets/images/training/drt17.png" class="img10" />
        <div class="accent1 fontSmall p-mb-2">
          DRT-17. These photos illustrate a hip thrust. Note the right hip has
          thrusted outward and the clubshaft dropped downward too much to the
          inside.
        </div>
        <img src="@/assets/images/training/drt18.png" class="img10" />
        <div class="accent1 fontSmall p-mb-2">
          DRT-18. As the hip thrust reaches impact the spine angle hasw
          vertically moved upward.
        </div>
        <img src="@/assets/images/training/drt19.png" class="img10" />
        <div class="accent1 fontSmall p-mb-2">
          DRT-19. Too often a golfer with a hip thrust will finish the swing in
          a back injury causing reverse "C" position.
        </div>
        <img src="@/assets/images/training/drt20.png" class="img10" />
        <div class="accent1 fontSmall p-mb-2">
          DRT-20. At address set up about a fist away from the railing. In the
          backswing put the right hip against the railing. During the transition
          and first part of the downswing continue to keep the right hip back
          against the rail and slide it a few inches towards the target and at
          the same time bring the left hip back to it so both hips for a moment
          in time are against the rail. As the hips continue to turn into and
          through impact the right hip will come off the railing BUT KEEP THE
          LEFT HIP AGAINST IT AS LONG AS YOU CAN.
        </div>
        <img
          src="@/assets/images/training/antiHipThrustText1.png"
          class="img10"
        />
        <img
          src="@/assets/images/training/antiHipThrustText2.png"
          class="img10"
        />
      </Panel>
    </Panel>
    <Panel header="Random Training" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
      </div>
    </Panel>
    <Panel header="Games" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
      </div>
    </Panel>
    <Panel header="Images - Hardy mainly" :toggleable="true" :collapsed="true">
      <Panel header="Henrik Sentson RIT" :toggleable="true" :collapsed="true">
        <img src="@/assets/images/training/swingLines1.png" alt="SwingLines" />
        <div class="accent1 fontSmall p-mb-2">Henrik Stenson RIT release</div>
      </Panel>
      <Panel header="Adam Scott RIT" :toggleable="true" :collapsed="true">
        <img src="@/assets/images/training/swingLines2.png" alt="SwingLines" />
        <div class="accent1 fontSmall p-mb-2">Adam Scott RIT release</div>
      </Panel>
      <Panel header="Suzann Pettersen RIT" :toggleable="true" :collapsed="true">
        <img src="@/assets/images/training/swingLines3.png" alt="SwingLines" />
        <div class="accent1 fontSmall p-mb-2">Suzann Pettersen RIT release</div>
      </Panel>
      <Panel header="Jim Furyk RIT hybrid" :toggleable="true" :collapsed="true">
        <img src="@/assets/images/training/swingLines4.png" alt="SwingLines" />
        <div class="accent1 fontSmall p-mb-2">Jim Furyk RIT hybrid release</div>
      </Panel>

      <div class="fontSmall taLeft">
        <div class="p-mb-2"></div>
      </div>
    </Panel>
  </Panel>
</template>
