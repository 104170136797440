<template>
  <Panel
    header="Partial Swing: Distance Wedges (30-100yds)"
    :toggleable="true"
    :collapsed="true"
  >
    <Panel header="Block Training" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
      </div>
    </Panel>
    <Panel header="Random Training" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
      </div>
    </Panel>
    <Panel header="Games" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
      </div>
    </Panel>
  </Panel>
</template>
