import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Playing from "../views/Playing.vue";
import IntraPersonal from "../views/IntraPersonal.vue";
import InterPersonal from "../views/InterPersonal.vue";
import Spirit from "../views/Spirit.vue";
import Technical from "../views/Technical.vue";
import Mental from "../views/Mental.vue";
import Training from "../views/Training.vue";
import Editor1 from "../views/Editor1.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/playing",
    name: "Playing",
    component: Playing,
  },
  {
    path: "/intraPersonal",
    name: "IntraPersonal",
    component: IntraPersonal,
  },
  {
    path: "/interPersonal",
    name: "InterPersonal",
    component: InterPersonal,
  },
  {
    path: "/spirit",
    name: "Spirit",
    component: Spirit,
  },
  {
    path: "/technical",
    name: "Technical",
    component: Technical,
  },
  {
    path: "/mental",
    name: "Mental",
    component: Mental,
  },
  {
    path: "/training",
    name: "Training",
    component: Training,
  },
  {
    path: "/editor1",
    name: "Editor1",
    component: Editor1,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
