<template>
  <Panel header="Thought Checking:" :toggleable="true" :collapsed="true">
    <Panel header="Technique" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">PIA</div>
        <div class="p-mb-2">
          Next to developing a good mental routine, learning to regulate your
          thoughts between shots is probably the most important performance
          enhancement technique you can learn, not only because it can help you
          quiet your mind to play more intuitively but also because it can help
          you manage your emotions, tensions, and confidence. Regulating your
          thoughts involves becoming aware of, and replacing, thoughts you have
          during your round that are sure to cause you to lose strength in your
          eight champion traits. For example, if you are constantly assessing
          mechanical flaws between shots, you are likely to be too tired
          mentally before the end of your round.
        </div>
        <div class="p-mb-2">
          Unmanaged thoughts typically raise tension and reduce emotional
          stability. For example, if you spend time between shots getting
          frustrated at an earlier mishit, you will prevent yourself from
          preparing properly for the next shot. This will raise tension, cause
          you to feel an emotion such as anger, and probably encourage you to
          change your game plan by making it more aggressive. You will become
          more score- and outcome oriented. All of these reactions to a mishit
          shot will detract from your ability and give you less chance of
          playing like a champion.
        </div>
        <div class="p-mb-2">
          The hardest part of learning to manage your thoughts, however, is
          becoming aware of them. Once you can identify the thoughts that hurt
          your performance, you then can take steps toward stopping them and
          changing them to thoughts that support your efforts. It sounds easy,
          but it can be quite a challenge to catch and replace these thoughts
          before they stir your emotions, raise your tension, and clutter your
          focus. I.e., Not-Allowed thoughts, Hyper Arousal thoughts, Low
          Confidence thoughts.
        </div>
        <div class="p-mb-2">GRAHAM</div>
        <div class="p-mb-2">
          The next step in regulating your thoughts is to identify positive,
          constructive, and peaceful thoughts to think about between shots to
          help edge out the not-allowed thoughts. The logic is similar to that
          of counting sheep to help you fall asleep. The goal is to displace the
          stressful thoughts that are keeping you awake with thoughts involved
          enough to keep you minimally mentally engaged - like counting - and
          thoughts simple, peaceful, and relaxed enough to keep you at ease -
          like imagining sheep gliding over a fence. Soon you relax and drift
          off to sleep.
        </div>
        <div class="p-mb-2">
          You can only think of one thing at a time, so that one thing should be
          something that helps create a mental state suited to what you are
          trying to do. On the golf course, you should aspire to: * remain
          relaxed and peaceful; * clear your mind; * imagine what you want to
          feel and do; * give yourself ,”mental breaks,” between shots
        </div>
        <div class="p-mb-2">
          The last objective surprises many golfers. They say, “you mean I'm not
          supposed to be thinking of my round the whole time I'm out there?” And
          we say, “Absolutely not. If you do, you will likely finish your round
          mentally exhausted."
        </div>
        <div class="p-mb-2">
          Some thoughts for mental breaks: * (Object meditation). Outlining
          trees: he looks for the biggest tree in sight and begins outlining it
          in his mind' He slowly takes his eyes around the entire profile of the
          tree' When he's finished' his body is relaxed and his mind clear' You
          can do this with any object' not just a tree. We call this technique
          "object meditation" and we will explain it further in chapter 8 *
          Daydreams * Past great shots
        </div>
        <div class="p-mb-2">
          Breathing: finds it relaxing, both physically and mentally, to
          concentrate on smooth, relaxed breathing when walking the fairway in
          anticipation of a difficult shot or putt.
        </div>
        <div class="p-mb-2">
          Nature: Scan the surroundings for wildlife that is likely to be found
          in the area rabbits, turtles, fish, birds, snakes, and alligators.
        </div>
        <div class="p-mb-2">
          Good body language: Walk and talk with confidence. Any not-allowed
          thoughts were to be replaced with walking and talking with confidence,
          including keeping her shoulders back, her chin up, and moving to her
          natural rhythm.
        </div>
        <div class="p-mb-2">MINE</div>
        <div class="p-mb-2">
          Secondly, the ‘membrane’ executes appropriate cognitive skills. These
          include:
        </div>
        <div class="p-mb-2">
          Distraction refocuses the ‘I’s’ awareness onto something non-harmful.
          (i) Focus internally - thought checking, guided imagery, mental
          rehearsal, take a mental break (daydreams, hobby/interests, count
          steps, external focus may be easiest). (ii) Focus externally (type of
          mental break) - object meditation, scan nature/surroundings, talk to
          partner.
        </div>
        <div class="p-mb-2">
          Reshaping/Rethinking makes a semantic change to the damaging thought
          converting that which was wrong or harmful, into something neutral or
          helpful.
        </div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
      </div>
    </Panel>
    <Panel header="Thought Categories" :toggleable="true" :collapsed="true">
      <Panel header="Positive" :toggleable="true" :collapsed="true"> </Panel>
      <Panel header="'Allowed'" :toggleable="true" :collapsed="true"> </Panel>
      <Panel
        header="Controllable: Process not Outcome oriented"
        :toggleable="true"
        :collapsed="true"
      >
        <div class="fontSmall taLeft">
          <div class="p-mb-2">
            This is the emotionally-stable mental skill We often point out to
            our clients that the greatest differences between champions and
            average golfers is the way they think. And one of the biggest
            differences is rooted in whether the player is oriented more toward
            the outcome of his play or the process of his play.
          </div>
          <div class="p-mb-2">
            Being outcome-oriented is the more natural approach to the game. The
            game itself encourages emphasis on end results, quality of shots and
            strokes, perfection, and accomplishment. We often hear television
            commentators say “Its not how it’s how many” Being process-oriented,
            on the other hand is more about how - and that comes less naturally
            to most. It emphasizes keeping a quiet and peaceful mind, and
            accepting all adversity as a challenge to staying relaxed and
            focused on hitting one shot at a time. “Let it happen” seems to be
            the unspoken motto of most of the greatest golfers. If you just
            concentrate on the process and put in the practice to make your
            process as good as you can, then in the end when you add up your
            score, it will be about as good as you can do.
          </div>
          <div class="p-mb-2"></div>
          <div class="p-mb-2">Some Steps for Effective process-Thinking</div>
          <div class="p-mb-2">
            Here are several simple steps to help you shift from
            outcome-thinking to process-thinking. How will you know if they're
            working? When you feel less pressure, the game gets simpler, and
            your rounds become a lot more fun.
          </div>
          <div class="p-mb-2">1. List Outcome Challenges</div>
          <div class="p-mb-2">
            Before you begin your round, identify all the outcome oriented
            thoughts you are likely to battle on that given day. An important
            key in making this list is to keep in mind the things you truly
            cannot control: the weather, bad bounces, poor conditions, pairings,
            tee times, what other players do, and bad luck. These concerns will
            control you if you let them. They can push you into
            outcome-thinking, which is related to the not-allowed thoughts from
            abstract thinking. This includes over concern with score, position,
            bets, money, fear of failure, what others think of you when you
            fail, and the like. All players have these thoughts, but champions
            are better prepared to recognize them under the pressure of play and
            replace them with what they can control: the process.
          </div>
          <div class="p-mb-2">2. Set Process Goals</div>
          <div class="p-mb-2">
            Before each round, set goals that channel your mental energies into
            the process. Such goals support your emphasis on the techniques we
            learned earlier: your sound mental routine and being able to
            regulate your thoughts. Here are some process goals: * Be fully
            committed to your shots and putts before stepping up to the ball. If
            you are not committed, back off, recommit, and start again. * Get a
            definite target for every shot and a definite line for every putt. *
            Relax enough to visualize every shot and putt before stepping up to
            the ball. * Feel a smooth tempo for every shot and putt in your
            practice swing or stroke and in your real swing or stroke. * Walk
            and talk with confidence between shots. * Take mental breaks from
            your round between shots. * Stay with your game plan, regardless of
            your score.
          </div>
          <div class="p-mb-2">3. Use Emotions as a Cue</div>
          <div class="p-mb-2">
            Prepare yourself to use your emotions as a cue, or reminder, to
            adjust from outcome-thinking to process-thinking both before and
            during competition. These steps will organize your efforts: * Try to
            recognize immediately when emotions are rising-fear, dread, and
            worry in particular. * Recognize that these emotions are a result of
            nonconstructive outcome-thinking and identify the source. * Use
            these feelings as a friendly reminder to shift your emphasis from
            the outcome back to the process. Recommit to your mental goals. *
            Judge your process, not your results.
          </div>
          <div class="p-mb-2">4. Shots and Putts</div>
          <div class="p-mb-2">
            Refuse to judge your shots and putts by where they stop. Instead,
            assess your process, such as the quality of your mental routine. If
            you conclude that any part of the mental routine was weak, challenge
            yourself to make it better on the next shot.
          </div>
          <div class="p-mb-2">5. Scores and Positions</div>
          <div class="p-mb-2">
            Judge your rounds not by your score but by your commitment to your
            mental goals. After you've played, decide what you could improve
            physically and incorporate that into your practice. Then decide what
            you can improve mentally and use that as a goal for your next
            competitive round.
          </div>
        </div>
      </Panel>
      <MentalRest />
    </Panel>
  </Panel>
</template>
<script>
import MentalRest from "@/components/mental/mentalRest";

export default {
  name: "manageBadThoughts",
  components: {
    MentalRest,
  },
};
</script>
