<template>
  <Panel
    header="Confidence. Self-assured personality type"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Self-Assured: is how troubled you are. Low scorers are self-assured,
        unworried, complacent, secure, free of guilt, confident and self
        satisfied. High scorers are apprehensive, self-doubting, worried, guilt
        prone, insecure, worrying and self blaming.
      </div>
      <div class="p-mb-2">
        A major pillar of confidence is remembering (preferably with emotion)
        that you have done the shot before. Positive memory control fills up
        your data bank with the necessary memories.
      </div>
      <div class="p-mb-2">
        The other pillars of confidence are knowing that you have a mental game
        that will allow you to win when you are playing well, will give you
        resilience when you are playing poorly, and allow you to live with
        outcome failure that was out of your control. Good self esteem knowing
        that you have worked hard on your technique, and have fitted equipment
        also helps.
      </div>
      <div class="p-mb-2">
        Some of the most charmed lives-and golf games-belong to those who are
        blessed with great confidence. It is a key to success in everything we
        do. The way we carry ourselves, communicate, experience relationships,
        work, relax, and play golf are all very much influenced by the way we
        think and feel about ourselves.
      </div>
      <div class="p-mb-2">
        Confidence comes easily to some yet remains a lifelong pursuit for
        others. It is one of the more challenging traits to strengthen, but it
        can be done. And it certainly is worth the effort.
      </div>
      <div class="p-mb-2">
        We encourage the awareness and development of two types of confidence:
        personal confidence and performance confidence. You will find it helpful
        to understand the difference between the two and the importance of each.
      </div>
    </div>
  </Panel>
  <Panel header="Personal Confidence:" :toggleable="true" :collapsed="true">
    <img
      class="img4"
      src="@/assets/images/mental/confidence2.png"
      alt="Tough Minded"
    />
    <div class="accent1 fontSmall p-mb-2">Personal Confidence</div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Personal confidence is considered to be in large part learned and at
        least some part inherited. It is a measure of your basic self worth, how
        happy you are with yourself as a person. The truly confident person
        loves himself, believes in himself, and can give himself compliments
        along with criticism. He is not so wlnerable to the daily ups-and-downs
        that can leave the not-so-confident feeling empty, worthless, or
        incompetent.
      </div>
      <div class="p-mb-2">
        It is believed that much of personal confidence is learned through life
        experiences, especially those of our early years. Parents may
        unintentionally teach us to think of ourselves in ways that undermine
        confidence by the approach they use to instruct and discipline us. For
        example, if a parent frequently reprimands a child by saying, "You are
        so lazy; can't you do better than that?" the child is likely to think of
        himself in a negative fashion as he matures. On the other hand, if a
        parent makes a comparable statement using a more positive slant, such
        as, "You're a great athlete; keep trying and you will do better!" he
        will have a more positive influence on a child's personal confidence.
      </div>
      <div class="p-mb-2">
        Many other things encountered during someone's life-.. time can have a
        considerable effect on personal confidence. Peers, friendships,
        relationships, one's birth order, teachers, coaches, illnesses,
        iniuries, learning disabilities, physical disabilities, and varied
        emotional experiences all contribute to a perion's confidence or lack
        thereof.
      </div>
    </div>
  </Panel>
  <Panel header="Performance Confidence:" :toggleable="true" :collapsed="true">
    <img
      class="img4"
      src="@/assets/images/mental/confidence3.png"
      alt="Tough Minded"
    />
    <div class="accent1 fontSmall p-mb-2">Performance Confidence</div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Performance confidence relates more to learned skills and involves a
        strong belief in your abilities to play golf at a high level. On the
        rare day when you have good performance confidence in all areas of your
        game, you will be amazed by the sport's sudden simplicity. You may even
        have moments when you feel certain you will never play poorly again!
      </div>
      <div class="p-mb-2">
        Having this level of performance confidence in all areas of the game is
        rare and, unfortunately, impossible to retain. Most players find it more
        common to feel a lack of performance confidence in at least some aspect
        of their game. Then there are those especially challenging times when
        performance confidence in all arcas is lost, leaving you wondering if
        you will ever play well again. while this is an extreme feeling it is
        not uncommon-even among the top professionals. It is especially common
        after playing in exceptionally tough conditions with very poor results.
      </div>
      <div class="p-mb-2">
        Still, no matter what the conditions, one of the realities of golf is
        that performance confidence always will ebb and flow. Most players only
        occasionally lose confidence in all areas of their game. It is more
        common to lose confidence in one area of your game as you find it in
        another. Severe loss of confidence can occur in one or all areas of your
        game even after only one devastating experience. I.e., mental or
        physical meltdown in a competition, or poor conditions etc. Usually the
        loss and the cause are more insidious.
      </div>
    </div>
  </Panel>
  <Panel
    header="The importance of confidence for competitive golf:"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        If you have played golf for a while you already know that it can be
        brutal on confidence. But the endless array of physical and mental
        challenges faced by golfers makes confidence one of the most important
        traits for peak performance and, as we have said, one of the most
        difficult to maintain. Yet, doing so is vital because of the many ways
        confidence contributes to the quality of your play.
      </div>
      <div class="p-mb-2">
        Having confidence in yourself and your game allows you to get the most
        out of your skills; to play without the inhibiting, self-conscious
        concerns about what others think of you or your game; and to separate
        your identity from your game so that you can respect yourself in spite
        of poor play.
      </div>
      <div class="p-mb-2">
        Let's examine the confidence of the champions in this sport. Champions
        walk, think, and talk with a level of confidence far above that of the
        average person. Whether they always feel it or not, they strive to
        project confidence in the ways they move and carry themselves, in the
        ways they think of themselves before and during competition, and in the
        ways they talk to themselves and others. More specifically, champions do
        the following: <br />*
        <span class="accent1"
          >They seek to dwell on their strengths and involve themselves with
          things they can control</span
        >
        rather than on weaknesses and things outside their control. <br />*
        <span class="accent1"
          >They refuse to let self-doubts keep them from confidently swinging
          the club or stroking the ball</span
        >
        to the best of their abilities. <br />*
        <span class="accent1"
          >They channel mental energies into the process of confidently focusing
          on the shot at hand</span
        >
        rather than wasting mental energies wrestling with concerns about how
        their swing or how their play will be perceived by others. <br />*
        <span class="accent1"
          >They find every opportunity to compliment themselves on their skills,
          luck, or play</span
        >
        to help counter the confidence-draining aspects of the game. <br />*
        When they hit bad shots or play bad holes or rounds,
        <span class="accent1">
          they quickly look for the positive lessons to carry into their next
          play</span
        >.
      </div>
      <div class="p-mb-2">
        Now let's look at players with average-to-low confidence, Such players
        often display their lack of confidence with drooped shoulders; lowered
        chins; shuffling feet; or hyper, unsettled, and fidgety behavior. Seldom
        acknowledging it, the thoughts of these players are commonly negative,
        filled with dread, worry, or anticipation of poor play. Self-talk is
        critical and discouraging with regard to themselves, their
        circumstances, or their play. Without full awareness, their imagery
        often drifts to visions of how they do not want to play rather than how
        they do want to hit a shot. More specifically, players with
        average-to-low confidence do the following: * They dwell on their
        weaknesses and involve themselves with things they cannot control. This
        quickly becomes habitual, and the related bad thoughts and behaviors
        inhibit their play. * They let self-doubt and apprehension force them
        into steering, guiding, and forcing shots, rather than confidently
        swinging the club or stroking the ball. * They channel mental energies
        into second guessing their abilities as well as worrying about poor
        results and how they will be perceived by others, rather than
        confidently focusing on the shot at hand and committing to what they can
        control. * They will give in to urges to berate themselves or their play
        and are oblivious to opportunities to compliment themselves on their
        skills, their luck, or their successes. * They take bad shots, bad
        rounds or criticism of their play personally. Poor play can negatively
        affect mood and attitude for hours or even days, negating any ability to
        learn from their mistakes.
      </div>
      <div class="p-mb-2">
        If you recognize any of these tendencies, your next step is to determine
        whether your weakness is in personal confidence, performance confidence,
        or a combination of the two. The following charts will help. They
        describe the extremes of confidence and apprehension for personal and
        performance confidence. Estimate where you score on all items relative
        to the one-to-ten scale.
      </div>
    </div>
  </Panel>
  <Panel
    header="Improving Personal Confidence"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        This technique involves a number of simple and effective steps you can
        take to begin strengthening your personal confidence. These steps are
        intended to increase awareness of inhibiting thoughts and to reduce your
        preoccupation with them. But remember, you are changing thinking habits,
        and as it would be with changing physical habits, it will take time and
        persistence to achieve.
      </div>
      <div class="p-mb-2"><span class="accent1">Affirmations:</span></div>
      <div class="p-mb-2">
        These are compliments you bestow on yourself. confident people freely
        and easily affirm themselves, while people with low personal confidence
        find it a difficult task that can feel awkward and even uncomfortable.
      </div>
      <div class="p-mb-2">
        We often ask participants in our seminars and workshops to list five
        qualities they truly value about themselves. People with low confidence
        are lucky to eke out one or two items in the allotted time, sometimes
        scrunching their faces with discomfort as they try. The very confident
        participants frequently and easily provide even more than the requested
        five affirmations, in part because affirmations are an integral part of
        their daily thoughts. You can condition yourself to do more of the same
        with these steps.
      </div>
      <div class="p-mb-2">
        Begin<span class="accent1">
          creating a list of your own affirmations</span
        >
        Compile at least fifty items that positively describe your strengths and
        good qualities with respect to
        <span class="accent1"
          >personality, values, skills, accomplishments, looks,</span
        >
        and so on. If you find the task overwhelming, challenge yourself to
        identify one or more per day over a period of several weeks. Suggestions
        from others are acceptable only if you also truly believe they are
        attributes.
      </div>
      <div class="p-mb-2">
        Then take a few minutes each day to review your list' choose at least
        one item and reflect on some recent evidence of that strength. For
        example, if the affirmation is patience, reflect on a recent
        demonstration of patience on the golf course, in traffic, or with a
        family member. Throughout the day, look for opportunities to mentally
        give yourself compliments' For example, privately compliment your own
        honesty by acknowledging you let a cashier know you received too much
        change.
      </div>
      <div class="p-mb-2">
        Be prepared to
        <span class="accent1"
          >accept affirmations and compliments from others graciously</span
        >, without sarcasm or discrediting statements. For example, if someone
        compliments you on your swing, graciously accept; resist an urge to
        identify flaws and shortcomings or to question that person's ability to
        judge a golf swing.
      </div>
      <div class="p-mb-2"><span class="accent1">Thought checks:</span></div>
      <div class="p-mb-2">
        As we have said, low self-confidence is reinforced by bad habits of
        thought. But just as we have the ability to change physical habits, we
        also have the ability to change mental habits. In both cases, change
        starts with awareness. Thought checks will help you gain greater
        awareness and understanding of the bad habits of thought that fuel your
        low self-confidence.
      </div>
      <div class="p-mb-2">
        A thought check involves taking a few conscious moments to assess the
        quality of your thoughts. Are they
        <span class="accent1">productive and positive,</span> such as offering
        you <span class="accent1">positive solutions and compliments</span>, or
        are they destructive and negative, such as second-guessing and berating?
        Challenge yourself to do thought checks at various times of day,
        especially in those situations where you feel your confidence is at its
        lowest, such as around certain people, dealing with certain
        responsibilities, pondering certain problems, and so on. Your goal in
        using thought checks is to identify and change bad habits of thought
        related to negative self-talk and faulty thinking.
      </div>
      <div class="p-mb-2">
        Once your thought checks have helped you identify habits of negative
        self-talk, its time to change those habits. Your first objective is to
        counter every negative thought with a positive one. If, for example, you
        feel angst because you hit a shot well but used the wrong club, give
        yourself a couple of seconds to react, then compliment yourself on how
        well you hit the shot.
      </div>
      <div class="p-mb-2">
        Your next obiective is to get out of the past tense as quickly as
        possible. This involves <span class="accent1">intercepting</span> the
        self-talk that begins with
        <span class="accent1">"I should have'' or "I could have."</span>
        Instead, opt for self talk that is more positive and future-oriented.
        For example, you can berate yourself for not having fully committed to
        your shot before you hit it, or you can
        <span class="accent1"
          >confidently assure yourself that on your next shot you will fully
          commit to your club and your target</span
        >. Always choose the latter.
      </div>
      <div class="p-mb-2">
        <span class="accent1">Faulty (illogical) thinking:</span>
      </div>
      <div class="p-mb-2">
        Changing negative self-talk is much more difficult, however, for those
        who, usually without realizing it, have habits of reasoning, deducing,
        and thinking of others and themselves in ways that are highly illogical.
      </div>
      <div class="p-mb-2">
        Faulty thinking is detrimental to confidence because it wastes
        considerable mental energy; it interferes with good, peaceful focus; it
        can be totally distracting; and it is almost always negative,
        unproductive, and inhibiting.
      </div>
      <div class="p-mb-2">
        Here are some examples of faulty thinking.<br />
        <span class="accent1">* Mind-reading:</span> Some players spend a lot of
        time wondering and assuming what other people are thinking of them,
        their games, looks, abilities, clothing, hairstyle, or any other
        personal attributes. An example in golf would be the man on the first
        tee who finds his attention is not focused on tempo, commitment,
        picture, and feel, but rather on who is watching and what they will see
        and think about him. Or it could be the golfer who, when playing or
        practicing near someone with a lower handicap or greater reputation, can
        only concern himself with how they are being perceived by that player.
        <br /><span class="accent1">* All or nothing:</span> Players with low
        confidence frequently think of themselves in all-or-nothing terms. For
        example, if they made six great putts and three poor putts, they are
        more likely to state at the end of a round that they "putted terrible
        today" instead of "I putted great today with the exception of three
        difficult ones." If they play well overall but have one bad hole, all-or
        nothing thinkers will declare they are terrible golfers. <br /><span
          class="accent1"
          >* Magnification:</span
        >
        Similar to all-or-nothing thinking, magnification leads players to
        quickly draw some dramatic, negative deductions about themselves and
        their games. Magnifiers see themselves at the extreme of any problem
        they encounter. For example, after a bad round he is likely to harbor
        fears that his game is falling apart. If he plays poorly on the first
        hole, he is sure he will play poorly the rest of the day. If someone
        criticizes one shot, he feels he must be a terrible golfer.
        <br /><span class="accent1">* Defensiveness:</span> Many with low
        confidence tend to be very defensive and take much of what happens
        around them very personally. If they are warned about slow play, they
        are certain they are being harassed. If other players move or talk
        during their swings, they are convinced it is intentional. If they get
        an unlucky bounce, the gods are against them. They allow people and
        circumstances to negatively influence their personal confidence.
      </div>
      <div class="p-mb-2">
        So what can be done to solve faulty thinking? First, make a list of all
        the issues that negatively affected your mood in the past several days.
        Write down your thoughts related to those issues. Where possible,
        compare your thoughts to the examples of faulty thinking above and label
        the ones you feel you experienced. Now try to write alternative thoughts
        that you would consider to be more rational and productive in relation
        to each issue. Do this exercise several times a week until you can catch
        and replace faulty thinking as it occurs. On the golf course, you will
        find it very helpful to use the bubble exercise described in
        Tough-Mindedness to detach and reduce your involvement with faulty
        thoughts.
      </div>
      <div class="p-mb-2">
        The suggestions above are meant to increase self-awareness and
        strengthen your thinking habits. If these techniques don't work, you
        will likely benefit from good personal counseling. With the assistance
        of a qualified and licensed therapist, you will be able to resolve past
        issues and current conflicts, change subconscious perceptions of
        yourself, and identify possible biochemical influences that compromise
        your personal confidence.
      </div>
      <div class="p-mb-2">
        Strengthening your personal confidence may well be the best thing you
        will ever do for yourself as a golfer and a person.
      </div>
    </div>
  </Panel>
  <Panel
    header="Improving Performance Confidence"
    :toggleable="true"
    :collapsed="true"
  >
    <img
      class="img4"
      src="@/assets/images/mental/confidence1.png"
      alt="confidence"
    />
    <div class="accent1 fontSmall p-mb-2">
      The higher your skill level, the more you will benefit from rating
      specific types of shots within these groupings; such as use of specific
      clubs, abilities on certain grasses, effectiveness on various lies,
      abilities in certain conditions, or effectiveness hitting various partial
      shots.
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Even with great personal confidence, performance confidence can be
        fleeting. By the same token, a lack of personal confidence can undermine
        the greatest performance confidence in the world. The steps we recommend
        here will help you maintain this important trait.
      </div>
      <div class="p-mb-2">
        First, let's identify areas of low moderate, and high performance
        confidence. Using a simple one-to-ten scale, with I representing very
        low confidence and 10 representing very high confidence, periodically
        rate your performance confidence in these playing areas.
      </div>
      <div class="p-mb-2">
        As we have mentioned, fluctuation in performance confidence is natural.
        But as you periodically rate your confidence in these areas, you may
        begin to find a specific area or areas that have been low for a while.
        Make it your goal to sort out why, so that you can take definite steps
        toward change. Performance confidence really rests on all the golf
        skills - mind, technique, equipment.
      </div>
      <div class="p-mb-2">Strong Mental Skills:</div>
      <div class="p-mb-2">
        We have found that when players with moderate to high skills begin
        working on their games, they should incorporate the mental game as soon
        as possible, especially when their games have been sabotaged by an
        injury or an illness. We suggest this because time after time we see
        that as the fundamentals of a strong mental game - commitment,
        visualization, tempo, rhythm, timing, feel-improve, so do the mechanics.
      </div>
      <div class="p-mb-2">
        Building performance confidence can indeed start by correcting swing
        mechanics, but you risk spending unproductive time and making
        unnecessary changes if you do not first address your mental problems.
      </div>
      <div class="p-mb-2">
        High tension is the perfect example. High tension creates faulty swing
        mechanics that are often cured with a more relaxed body and mind. We
        have seen numerous advanced players treat symptoms of mental weaknesses
        by putting in diligent hours, weeks, and months of hard work to
        incorporate physical changes only to return to competition with weak
        mental skills that still inhibit performance confidence.
      </div>
      <div class="p-mb-2">
        So we strongly encourage you to make sure that at least the fundamentals
        of your mental game-particularly your mental routine-are strong before
        assessing, changing, and working on your physical skills
      </div>
      <div class="p-mb-2">Strong Physical Skills:</div>
      <div class="p-mb-2">
        Your ability to hit reasonably predictable shots is vital to your
        performance confidence. Keep in mind that having a perfect swing is not
        as important as having a swing you trust and can count on under
        pressure. Getting this often requires the assistance of a qualified
        teaching professional who has a good eye for your swing and can
        communicate in a style to which you relate. It's not always easy to find
        someone, but it's worth the effort.
      </div>
      <div class="p-mb-2">
        You should try to establish a relationship with an instructor who can
        teach you the fundamentals in a style that matches your personality and
        your 8ame. Use him or her to help monitor your progress and get you back
        in position when you inevitably stray from the basics. Even at the peak
        of his career, Jack Nicklaus made a point of visiting his lifelong
        teacher, Jack Grout, at least once a year. Together they would check out
        his grip, setup, and all the fundamentals, making corrections to ensure
        Jack's practice increased his confidence.
      </div>
      <div class="p-mb-2">
        Your personality will influence the quantity and type of practice that
        best suits you. For example, feel players seem to prefer less practice
        and more play, with their practice empha- sizing touch, timing, and
        feel. Mechanical players seem to prefer more practice and less play,
        with their practice empha- sizing drills and positions. To be honest,
        most players can ben- efit from including a bit more of what they least
        prefer.
      </div>
      <div class="p-mb-2">
        Two tempting but bad habits most players with Iow per_ formance
        confidence will face are: over practicing to the point of fatigue or
        poor concentration and allocating most of their practice to areas of
        their games in which they are already confident. But in building and
        maintaining performance confi- dence, it is vital to organize quality
        practice for the areas of your game that need it the most. Quality
        practice is designed to improve your weakest skills in the most
        effective way.
      </div>
      <div class="p-mb-2">Equipment:</div>
      <div class="p-mb-2">
        As confidence in your physical skills improves, you will be able to
        assess whether changes in your equipment will further strengthen your
        performance confidence. players have the most performance confidence
        when they feel good about their clubs, and there are several reasons
        they do: * The clubs fit in terms of length, loft, lie, and stiffness
        and flex point of the shafts. * The player hits the "sweet spot"
        consistently. * A club or putter looks and feels good in the player's
        hands. * The player hits shots he likes in terms of height, shape, and
        spin reasonably consistently.
      </div>
      <div class="p-mb-2">
        Advances in the technology of golf equipment over the years have been
        remarkable, to the advantage of both amateurs and professionals. Paying
        too much attention to equipment, however, often does not solve the real
        problem and can be detrimental to performance confidence at any level of
        play. Tinkering can become a habit, and continually changing equipment
        affords no time to build confidence. As Lee Trevino would say, "It's not
        the arrow it's the Indian."
      </div>
      <div class="p-mb-2">
        To ensure your equipment helps build your personal confidence, you
        should make sure that your clubs are properly fitted and that they look
        and feel good to you. That done, commit to your equipment for two months
        or until you feel you have given yourself every chance to gain
        performance confidence through your mental and physical skills.
      </div>
      <div class="p-mb-2">
        During this trial period, evaluate your performance with each club and
        with the whole set. Then if you feel you should change your equipment,
        do so because of your own evaluation and not on a whim or suggestion
        from a friend. And certainly not because of an ad you saw in a magazine
        or on television or because a favorite Tour pro is playing a certain
        model.
      </div>
    </div>
  </Panel>
</template>
