<template>
  <div class="fontSmall taLeft">
    <img src="@/assets/images/hardy2/fig9.25.png" class="img10" alt="Alt" />
    <div class="accent1 fontSmall p-mb-2">
      FIG. 9.25. In this in-to out mistake both ends of the club are wrong. The
      hanle is too far above the shaft plane line due to the left arm not
      dropping down and in. The clubhead has been unhinged/uncocked below the
      line instead of being thrown outward.
    </div>

    <img
      src="@/assets/images/hardy2/mistakesText7.png"
      class="img10"
      alt="Alt"
    />
    <img
      src="@/assets/images/hardy2/mistakesText8.png"
      class="img10"
      alt="Alt"
    />
    <img
      src="@/assets/images/hardy2/mistakesText9.png"
      class="img10"
      alt="Alt"
    />
    <img src="@/assets/images/hardy2/fig9.26.png" class="img10" alt="Alt" />
    <div class="accent1 fontSmall p-mb-2">
      FIG. 9.26.You will feel a much lower and flatter follow through
    </div>
    <img src="@/assets/images/hardy2/fig9.27.png" class="img10" alt="Alt" />
    <div class="accent1 fontSmall p-mb-2">
      FIG. 9.27. An ouward hip thrust shoves the pelvis towards the ball and
      forces the spine to dramatically rise.
    </div>

    <img
      src="@/assets/images/hardy2/mistakesText10.png"
      class="img10"
      alt="Alt"
    />
    <img
      src="@/assets/images/hardy2/mistakesText11.png"
      class="img10"
      alt="Alt"
    />

    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
  </div>
</template>
