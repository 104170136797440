<template>
  <Panel header="Introduction" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">LEAVE YOUR MIND BEHIND</div>
      <div class="p-mb-2">
        SWING KEY: Celebrate the sacred ground of golf. The cockpit of an F-16
        fighter jet is both cramped and complicated, a phone booth–size cocoon
        of dials and instruments providing endless information, from air speeds
        that can reach more than 1,600 mph and altitudes as high as fifty
        thousand feet, to navigational data and information on the mechanical
        functioning of the plane. One gauge tells the pilot about his target,
        while another warns him when he has become the target of someone else.
        To function successfully, the human and the machine must merge as one.
        This total integration is not an option; it is a necessity.
      </div>
      <div class="p-mb-2">
        Trust, and the seamless transition into action that trust allows, is as
        much the key to executing a perfect F-16 mission as is the mechanical
        functioning of the plane, the skill of the pilot and the data that goes
        into the pilot’s decision-making. The pilot climbs into the cockpit, is
        strapped into a harness, hears the disembodied voices of his base and
        his fellow pilots through his headset, and takes control of the plane.
        He is a small part of the sophistic“ated marriage of man and machine,
        but he is its most important component. As with any area of human
        achievement that involves peak-performance activity, success here
        entails a complicated combination of surrender and control. You can
        control how prepared you are; you can control the level of your trust
        and commitment; and then you have to completely engage with the task
        that has to be done.”
      </div>
      <div class="p-mb-2">
        All the clever computer calculations that pour into an F-16 on a combat
        mission are meaningless without the well rehearsed actions of the
        individual at the controls. When the moment of truth arrives, instinct
        takes over; decisions are not made but rather acted upon. There is no
        time to think about what you are doing; you must merely do it. This is,
        literally, a matter of life and death that rests upon the quality of the
        preparation and the depth of the engagement of the individual. There is
        no other moment that matters but right now. For success, there can be no
        doubt in your mind.
      </div>
      <div class="p-mb-2">
        This perfect integration of the individual into the action is not a
        matter of acting without thought, but rather a matter of understanding
        the task at hand so well that it can be carried out instinctively,
        without hesitation, reservation or doubt—with complete commitment.
        Thousands of hours of training and experience translate into the series
        of split-second judgments, actions and reactions a fighter pilot needs
        to carry out his mission. There is, as in all areas of life, a time for
        thought and a time for action—a time to be in the Think Box and a time
        to be in the Play Box. Being in the cockpit of an F-16 fighter jet is
        all about being in the Play Box.
      </div>
      <div class="p-mb-2">
        Every human activity has its Play Box—that moment of truth when you
        cross the line from thinking about doing something to actually doing it.
        Whether it is piloting an F-16, performing brain surgery, singing an
        aria or striking a golf ball, there is one nonnegotiable requirement for
        achieving the peak-performance state that allows greatness to occur: You
        have to be 100 percent present through your senses. Most golfers are
        trained to think while they are performing. But all the research on peak
        performance shows that to maximize outcome, you need to surrender to the
        moment and be right here, right now, with all of your senses. Golf is
        all about those few seconds you spend in the Play Box. Mastery of the
        Play Box is mastery of golf.
      </div>
      <div class="p-mb-2">
        Major Dan Rooney has been an F-16 pilot since enlisting in 1998, and
        served three tours of duty in Iraq. He is also the founder of Patriot
        Golf Day, which raises money for the Folds of Honor, a foundation that
        provides scholarships to the children of servicemen and -women who have
        been killed or disabled. And he is a PGA of America professional who
        teaches in Oklahoma and has studied our books. Dan started playing golf
        when he was five years old with his father, a professor at Oklahoma
        State University.
      </div>
      <div class="p-mb-2">
        I had four cut-down clubs and a Tony Pena 5-wood, and I was allowed to
        play with my dad and his friends as long as I didn’t fall behind,” Dan
        remembers. “So I’d hit the ball and run, hit the ball and run in the
        hundred-degree Stillwater, Oklahoma, heat. My dad got the love of the
        game in me. I told my father when I was twelve that I knew I wanted to
        be a professional golfer and a fighter pilot. He told me, ‘Just find
        your passion in life and go do that.’"
      </div>
      <div class="p-mb-2">
        Dan turned pro right out of the University of Kansas in 1996 and spent a
        couple years on the mini tours with some success. But he also knew the
        cutoff age to train to be a fighter pilot was twenty-seven, so he
        decided that if he didn’t get his card at PGA Tour qualifying school
        late in 1997, he would enlist. “I said to myself that if I didn’t make
        it I would go chase my other dream,” says Dan, who majored in sports
        psychology in college and learned things in the military that prepared
        him to understand VISION54.
      </div>
      <div class="p-mb-2">
        Dan says the concept of the Play Box articulates perfectly what is going
        on when he is in the cockpit of an F-16—instinct and total engagement
        with the action take over. While hitting a golf ball pales in importance
        to flying an F-16 into combat, there are similarities between the state
        needed to fly a perfect mission and that needed to strike a perfect golf
        shot. Both are peak-performance activities—or at least should be.
      </div>
      <div class="p-mb-2">
        To be good at flying an F-16 requires extensive preparation and hundreds
        of hours of training,” Dan says. “But once you are in the pilot’s seat,
        being good at it—being great at it—requires that you be one hundred
        percent “present in the cockpit. This incredibly complex activity
        becomes entirely sensory based. I have a half dozen tasks to carry out
        with my right hand and just as many to do with my left. If I were
        thinking about the process instead of being totally engaged in the
        process, the outcome would be disastrous.”
      </div>
      <div class="p-mb-2">
        Through our books, Dan came to understand how this experience relates to
        golf. We know what to do, but when the time comes to execute the swing,
        we must trust our preparation. This point cannot be emphasized enough:
        When you cross the Decision Line from the Think Box to the Play Box, the
        most important things to take with you are trust and commitment. In the
        Play Box you must be totally present and through your senses completely
        engaged with your swing and the shot. That’s where you will find trust.
        The most important things to leave behind are clutter and doubt, yet
        that is the baggage most golfers lug with them into the Play Box.
      </div>
      <div class="p-mb-2">
        I have no doubt that if I had flown a fighter first and then played
        professional golf, I would have had a better chance at making it on
        tour,” Dan says. “Being a fighter pilot is athletic, it involves
        eye-hand coordination, and everything happens very fast. Both a fighter
        pilot and a golfer are in the business of the target. When you have
        troops on the ground—the real heroes—shouting that they need air cover
        right now, you have to become the calming voice; you have to become the
        instrument of stability and focus,” he explains.
      </div>
      <div class="p-mb-2">
        The complexity of piloting an F-16, the pressures of combat and the fact
        that the lives of others depended on him provided Dan with a foundation
        upon which to build an expanded vision he applied to golf. “What I
        learned in flying an F-16 is how to block out distractions. In golf, it
        is a water hazard or out of bounds. The external stimuli in an F-16 are
        way more intense than on a golf course. You have to become totally
        engaged in the process and block out everything else. We have a fear of
        failure. I’d rather be shot down than mess up.
      </div>
    </div>
  </Panel>
  <Panel
    header="Requires 'Four Stages of Learning'"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        When you are in the Play Box, you have to be fully engaged in hitting
        the golf shot at hand. And that is why the first of the 8 Essential
        Playing Skills for Peak Performance is the concept of the Play Box. This
        Essential tops your list of priorities. The other seven are of equal
        importance to one another, but it is through mastering the Play Box
        skills that peak performance is achieved. This is where golf happens.
        There are tools you can learn and practice to help you reach that full
        engagement. Remember, merely understanding the importance of the Play
        Box is not enough. You have to master a routine for functioning in the
        Play Box. We can’t stress enough the importance of doing the exercises
        at the end of this chapter and then taking them onto the golf course
        with you.
      </div>
      <div class="p-mb-2">
        We have come to realize that to be successful in the Play Box requires
        FOUR STAGES OF LEARNING. FIRST, you need to know the difference between
        thinking and sensing. You can think about dancing, or you can dance. You
        can think about the correct grip, or you can actually feel it. You don’t
        want to be distracted from the performance by still mentally preparing
        for the performance while you are in the Play Box. That approach
        wouldn’t work in an F-16, and that approach doesn’t work on the golf
        course. Peak performance is achieved by totally immersing yourself in
        the experience. Research shows that for peak performance to occur, you
        need to be in touch with the right side of your brain, where intuition,
        imagery, perception and heightened sensory awareness reside. The best
        performers are more left brain in the Think Box and access more of the
        right brain in the Play Box.You have to learn to make that shift happen.
        That’s getting lost in the moment; that’s creating an at-one state that
        produces great golf shots.
      </div>
      <div class="p-mb-2">
        The SECOND stage for a successful Play Box is for you to learn how to
        stay present with your senses for those precious few seconds you are
        actually in the Play Box. This takes training. Some players lose their
        focus as soon as the swing starts. Their mind races into the future
        and/or it obsesses on some technical thought. Don’t let your mind
        wander! Staying present is challenging but highly achievable. We are not
        asking you to go to Tibet and meditate for ten years; all we are asking
        is for you to be fully present to something useful for the few seconds
        you are in the Play Box.
      </div>
      <div class="p-mb-2">
        THIRD, you need to learn what works best for you to be sensory present
        in the Play Box. This is a trial-and-error process ; explore and
        discover what works for you. Tiger Woods says that when he’s playing his
        best, he feels the shot in his hands. Annika Sorenstam says she sees the
        shot, then feels the shot in her stomach and then steps into the Play
        Box with that feeling and executes. No one can tell you what works best
        for you in the Play Box. That is your own personal voyage of discovery.
        The exercises at the end of this chapter will help you figure out what
        works for you. Find a sensory awareness you can stay present to for the
        entire swing or stroke, then develop a few options that work for you.
      </div>
      <div class="p-mb-2">
        FOURTH, how engaged can you be for every shot? To play your best, you
        have to cultivate the feeling that every shot is the most important shot
        you will ever hit. Tom Weiskopf once said of Jack Nicklaus: “Jack never
        hit an indifferent shot in his life.” Nicklaus played every shot he ever
        hit as if it were on the final hole of the U.S. Open. Some players fade
        in and out of focus during a round; others don’t do a good job of
        conserving energy and lose their Play Box intensity late in the round.
        You want to have your full focus in your Play Box experience for every
        shot, not a faded copy. You need to show up for every shot! How do you
        get good at this concentration ? Practice.
      </div>
      <div class="p-mb-2">
        When you swim, you don’t think about how to swim; when you ride a bike,
        you don’t think about how to ride a bike; and when you walk, your mind
        is not focused on the mechanics of walking.You swim, you ride, you walk.
        Make it the same for golf. Don’t think—play! One of the players we work
        with came up with the acronym QDANT to remind herself how she performs
        her best: Quick, Decisive, Aggressive, No Thoughts. Every Play Box is a
        brand-new experience beckoning you to show up fully present. Part of
        what made Nicklaus, Woods and Sorenstam great is that winning never got
        old for them, playing never got old for them; they were fully engaged
        with golf all the time and relished the new challenge that comes with
        every shot.
      </div>
      <div class="p-mb-2">
        We want golf to become a sport again. It is not a chess match. It’s more
        like flying an F-16. It should be thrilling. Much golf instruction is
        about cognitive thinking over the ball—my hands should do this; my legs
        should do that; etc. That goes against all we know about peak
        performance. It is virtually impossible to try to hit a golf ball with a
        “to-do” list in your mind. It would be like trying to go through such a
        checklist when you are strapped into the cockpit of an F-16 flying at
        more than the speed of sound. You know what to do—just do it. The Play
        Box is where your skill level plus your mental, physical and emotional
        state—the awareness you bring to the task—equals performance. You don’t
        bring fear or doubt or hesitancy with you into the Play Box when you
        dance or swim or ride a bike; don’t bring fear or doubt or hesitancy
        with you when it comes time to hit a golf ball.
      </div>
      <div class="p-mb-2">
        It is all about committing to a process,” Dan Rooney says. “The routine
        in the fighter business is the rock everything is built on. To go
        through this process and never deviate from the process with all the
        external stimuli that are going on around you. You have to block out
        everything except the target and the process. Mentally, as a golfer, I’m
        a thousand times better than I was. The process is the Think Box and the
        target is the Play Box.
      </div>
    </div>
  </Panel>
  <Panel
    header="Peak Performance: Being 'In The Present' has to be 'Sensory Based'"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        After your pre-shot routine—the Think Box—you should step into the Play
        Box committed to your decisions about the shot. In the Play Box, you
        establish your aim and alignment—you commit to the target—and then
        perform a committed swing. The Play Box is about learning the difference
        between preparing and performing. The golf swing is not a thought; it is
        a physical experience. To perform your best, you have to be present
        through your senses. This is the moment of truth. What do we mean by “be
        present”? We mean that you are having a first-person experience. You are
        not concerned about what happens if you miss the putt; you are not
        calculating what you need to do to shoot your best score; you are not
        ruminating on that last drive that went left. You are right here, right
        now. There is only one of you. If you are having a conversation with
        yourself about anything, that means there are two of you in the Play
        Box—and neither is focused on hitting the shot.
      </div>
      <div class="p-mb-2">
        What do we mean when we say your presence in the Play Box has to be
        “sensory based”? We mean you are experiencing the task at hand through
        your senses. Some play their best when they see the target in the Play
        Box, or the trajectory of the ball; others perform better when they hear
        the swing or hum a tune in the Play Box; yet others feel a sense of
        rhythm or tempo while they swing or are more tactile and focus on grip
        pressure through the swing.
      </div>
      <div class="p-mb-2">
        In all cases, you aren’t thinking; you are experiencing the shot, you
        are living the shot. That rich quality of complete engagement cannot be
        maintained forever. So the less time spent in the Play Box, the better.
        We find that for most good players, the Play Box routine lasts between
        four and nine seconds from the time you step into the shot until you
        begin the swing. Taking more time increases the chance that you will
        distract yourself and that self-talk will start. Taking less time might
        rush your technique. You also want to keep the Play Box time consistent
        from the first hole to the last shot of the match. Don’t slow down or
        speed up under pressure. That is a very common occurrence. To be more
        consistent, you must grasp that being fully engaged for every Play Box
        is at the heart of consistency.
      </div>

      <div class="p-mb-2">
        When you cross that Decision Line into the Play Box, you should feel a
        sense of liberation, not hesitation. The hard work has been done; the
        decisions have been made; the practice hours have been recorded. You
        have committed to the shot. Now comes the fun! It’s time to play golf!
        There is no reason for fear, tension or doubt. Enter the sacred ground
        and play! We have all experienced that thrilling sensation of a
        perfectly struck shot. Most players, especially recreational golfers,
        believe that that state is fleeting. For many, when success happens,
        there is a part of them that immediately undermines it. That little
        voice inside is already issuing warnings that the good shots can turn
        bad at any time.
      </div>
      <div class="p-mb-2">
        Peak performance, for most, is a fragile state of confidence. But that
        does not have to be the case. Peak performance is a state that can be
        learned and practiced so it can be created more often and last longer.
        Confidence doesn’t just happen—it is learned. Confidence is under your
        control. All those practice swings when you are over the ball are just
        cramming for the final exam that is the golf shot. Get rid of them! You
        can’t change your skill level in the Play Box, you can’t change your
        level of preparation, but you can be fully present and completely
        engaged with the shot and target. You can feel better about the shot,
        and that will lead to a better shot.
      </div>
      <div class="p-mb-2">
        How you play is a combination of your skill and your ability to create a
        peak-performance state. Most golfers practice their skills but leave
        their mental state up to chance. You need to be competent in both.
        Better technique or better conditioning won’t help if you can’t create a
        good state for every Play Box on the course. You won’t be able to
        execute. You can only play with the skills you have. If those skills
        need to be refined, do it during practice and not in the moment of
        performance. The Play Box is where you are a performer. You have to be
        clear about your peak-performance state. Otherwise the Play Box becomes
        the Hope Box.
      </div>
      <div class="p-mb-2">
        Remember when we talked about distinguishing between what you can
        control and what you can’t control? Being present, being focused—being
        there when it is time to hit a golf shot—is something totally under your
        control. To be present, you need to be present to something; if not, the
        Play Box becomes a Nothing Box. Paying attention is a skill you can
        learn. As we said, you can’t go into the Play Box with a checklist of
        swing thoughts, but it is absurd to think you can go into the Play Box
        with nothing in your mind. The human brain simply doesn’t work that way.
        Nature hates a vacuum and immediately tries to fill it. But you can
        control what you are paying attention to. You can train yourself to have
        a quiet mind, and if your mind is not easily quiet, you can train
        yourself to distract the distracter. You can write the script for your
        inner monologue.
      </div>
      <div class="p-mb-2">
        By staying engaged and busy with your Play Box Awareness your mind is
        occupied, and nonproductive self-talk fades into the background. Most
        players need the support of a functional Play Box Awareness; they need
        something to help turn down the volume of unproductive thoughts. Even
        players who can create a quiet mind in the Play Box might start hearing
        the voices “under pressure or when they get tired. They need to learn
        what to do to get back to the here and now, which is when and where the
        shot is going to be hit.”
      </div>
      <div class="p-mb-2">
        If you allow a void to exist in your mind, it will be filled by
        self-talk, and self-talk is always unproductive, telling you what you
        can’t do or reminding you of how you messed up the last time you were in
        a similar situation or worrying about the future or trying to remember
        the last swing tip. To make every Play Box visit an engaging sensory
        experience, you need to learn tricks—or develop tools, as we like to
        say—that allow your mind to push aside distractions and focus on hitting
        the shot. You need to be proactive; you need to be the one who
        determines what is going on in your mind when you step into the Play
        Box.
      </div>
      <div class="p-mb-2">
        While the thought process is left on the other side of the Decision
        Line, the swing can never go fully on automatic unless you create a
        situation in which your mind can be free from distraction and doubt.
        Don’t think about what you should be doing; you already know that. But
        you want to pay attention to something sensory based to allow your body
        to be free to swing the club. Instead of approaching a shot with
        trepidation, treat every shot as if you are greeting a long-gone friend
        returning home—embrace the moment, celebrate it, enjoy it.This is the
        ability to be totally present.
      </div>
    </div>
  </Panel>
  <Panel
    header="What is Play Box Awareness"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Your golf experience is an ongoing process. It’s not a destination at
        which you arrive but a road upon which you travel. You must be
        constantly engaged, from round to round, from hole to hole, from shot to
        shot. But that does not mean you have to grind yourself down by thinking
        for five hours. Learn how to take vacations between shots so you save
        energy for each time you hit a shot. The need to establish the
        discipline to go in and out of this state of readiness is what makes
        golf a different and more demanding challenge than most other sports.
      </div>
      <div class="p-mb-2">
        Frequently, by the time you get to the fifteenth hole, your play box has
        lost its shine; it’s weak, like a copy of a copy of a copy. The joy that
        comes from playing golf has gone from a tingly feeling on the first tee
        to a distracted collection of jumbled thoughts by number fifteen. Your
        focus is fading. Your Play Box has become passive, not active. That’s
        why the key to success in the Play Box is to develop your Play Box
        Awareness. This is how you stay engaged. This is how you remain
        constantly integrated with the process of playing golf.
      </div>
      <div class="p-mb-2">
        What is Play Box Awareness? It’s what you are sensing and experiencing
        when you step into the Play Box to hit a shot. It could be something you
        see, feel or hear. Your Play Box Awareness is something that keeps you
        present and sensory based, the criteria for any peak-performance state.
        And your Play Box Awareness is something completely under your control.
        It is the attention to this Awareness that keeps distracting or negative
        thoughts out of your head and allows your swing—the swing that has
        produced great golf shots in the past—to repeat. Play Box Awareness is
        totally individualized. Not only does it vary from person to person, it
        can vary for you from round to round—sometimes it can even vary within a
        round. That’s why you need a full toolbox of choices.
      </div>
      <div class="p-mb-2">
        Perhaps your Awareness takes the form of your concentration on feeling
        grip pressure, or you count to clear the clutter out of your mind, or
        you hum a tune. What your Awareness is not is a bunch of thoughts about
        what you should be doing with your swing. You have to find what works
        for you. The Play Box Awareness is your mantra for success. “Mantra”
        means “a place to rest the mind.” When Yani Tseng won the 2010 Women’s
        British Open at the age of twenty-one, we had her singing songs to
        herself to keep the self-talk away between shots, and we had her keep
        her Play Box routine to five seconds so the self-talk wouldn’t have time
        to start up when she was over the ball.
      </div>
      <div class="p-mb-2">
        How well you play depends on how successful you are at creating a
        performance state—a state of total engagement—in the Play Box. The
        intellect loves to bring stuff into the Play Box. Don’t let it. Silence
        it. The awareness you take into the Play Box will be something you
        determine works best for you. When you have found a series of Play Box
        Awareness choices, they “will need to be practiced like any other skill
        if you want to play your best golf. There is a difference between having
        a swing awareness in the Play Box and cluttering the Play Box with a
        to-do list.You might want to feel a certain weight shift in your swing,
        for example, but that is a physical connection to the swing and not an
        abstract intellectualization of it.”
      </div>
      <div class="p-mb-2">
        There are many tools you can use to develop complete engagement in the
        Play Box. Some seem counterintuitive since they involve bringing
        thoughts other than golf into the Play Box. But what you are creating is
        not a to-do list but rather a just-do-it experience. Think of your Play
        Box Awareness not as something you have to do but as something that
        allows you to do what you already know how to do—hit a golf ball.
        Remember, great golf is all about creating repeatability, and
        repeatability of the swing is a physical action. The intellect will
        always want to get more information. That is the job of the intellect.
        You have to tame it. More information is not always a good thing.The
        swing is not an idea—it is an action. Let it flow!
      </div>
      <div class="p-mb-2">
        Here are exercises to help you learn what Awareness works best for you
        in the Play Box. Remember, golf is all about the individual. Experiment,
        figure out what works for you. Also, remember that knowing the tools is
        not the same as using them. This is a process with which you must be
        constantly engaged. No cruise control allowed. Knock knock ... who’s
        there? You! The shot! The target! Show up!
      </div>
    </div>
  </Panel>
</template>
