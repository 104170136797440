<template>
  <div class="fontSmall taLeft">
    <img src="@/assets/images/hardy2/fig9.29.png" class="img10" alt="Alt" />
    <div class="accent1 fontSmall p-mb-2">
      FIG. 9.29. With the handle too high above the shaft plane line there is
      too high a ROC to hit repetitive ball flights for the RIT release.
    </div>
    <img
      src="@/assets/images/hardy2/mistakesText14.png"
      class="img10"
      alt="Alt"
    />
    <img
      src="@/assets/images/hardy2/mistakesText15.png"
      class="img10"
      alt="Alt"
    />
    <img
      src="@/assets/images/hardy2/mistakesText16.png"
      class="img10"
      alt="Alt"
    />
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
  </div>
</template>
