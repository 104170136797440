<template>
  <div class="accent1 fontSmall p-mb-2">
    Mental Skills and Traits Important in the Shot Routine and Performance
    Confidence
  </div>
  <img
    class="img3"
    src="@/assets/images/mental/mentalIntro1.png"
    alt="MentalIntro"
  />
  <div class="accent1 fontSmall p-mb-2">
    Cognitive and Physical Skills Important in the Between Shots Routine
  </div>
  <img
    class="img3"
    src="@/assets/images/mental/mentalIntro2.png"
    alt="MentalIntro"
  />
  <div class="fontSmall taLeft">
    <div class="p-mb-2">
      Our thoughts, emotions, bodily responses and actions are largely driven by
      external events and situations. However, the same external event causes
      different thoughts, different emotions, different bodily responses and
      different behaviours in different people. Some of these responses are
      helpful to playing golf successfully under pressure, and some are harmful.
    </div>
    <div class="p-mb-2">
      It is helpful to understand why these different responses occur. Our
      thoughts, emotions and bodily responses are largely shaped by our
      personality traits. These in turn result from our genetic makeup, personal
      histories, environment and culture.
    </div>
    <div class="p-mb-2">
      Personality traits are by definition stable and difficult to eradicate.
      The effects of these traits can however be successfully managed. The first
      step is to recognize that one is having unhelpful responses in a given
      situation. Armed with that knowledge one can learn techniques to replace
      the harmful responses with more productive ones, resulting in improved
      actions and performance. Such techniques can be broadly divided into
      cognitive skills, somatic skills, and a miscellaneous group i.e.,
      nutrition.
    </div>
    <div class="p-mb-2">
      Personality Traits The big five personality traits are the best accepted
      and most commonly used model of personality in academic psychology:
      extraversion, neuroticism, agreeableness, conscientiousness and openness
      to experience. However, these tend to not be popular in consumer focused
      personality assessment because in those situations, the feedback of the
      model seems relatively basic and unhelpful.
    </div>
    <div class="p-mb-2">
      In contrast, research using the Cattel 16 personality trait questionnaire
      (16pf) has been found to give useful insights and practical guidance in
      many different working environments.
    </div>
    <div class="p-mb-2">
      Dr. Debora Graham evaluated LPGA golfers for her masters thesis using
      factor analysis of the Cattel 16 personality trait questionnaire. Using
      factor analysis Graham found that 8 of the 16 traits predicted champion
      from non-champion LPGA golfers at p less than 0.05. She and her husband
      (Jon Stabler) wrote the book “The 8 traits of Champion Golfers”, and both
      now run GolfPsych online & courses https://www.golfpsych.com/. Her husband
      has patented a biofeedback device ‘Mind Meter’ (band around chest and
      android device $500) for quantifying arousal. They have discovered that
      every golfer has unique and different optimal arousals for long-game
      (higher arousal) vs chipping & putting (lower arousal). The biofeedback
      device helps train the golfer to manipulate his arousal level on the
      course for optimal performance.
    </div>
    <div class="p-mb-2">
      Having biofeedback on arousal - Mind Meter (adrenaline) probably would
      compliment the biofeedback on beat-to-beat variability ‘Inner Balance’
      (calm, positive emotions). See arousal/emotion grid in emotions.
    </div>
    <img
      class="img10"
      src="@/assets/images/mental/mentalIntro3.png"
      alt="MentalIntro"
    />
    <div class="accent1 fontSmall p-mb-2">
      https://golfsciencelab.com/8traits/#more-3026 Cattel 16 personality Factor
      Questionnaire. 8 Champion Traits re Deborah Graham
      https://openpsychometrics.org/
    </div>
    <div class="p-mb-2">
      Graham’s 8 Champion Personality Traits The mental skills associated with
      four of the traits play major roles in the shot routine (Self-sufficiency,
      Dominance, Focus, and Emotional Stability), while the mental skills
      associated with the other four traits play major roles in the between shot
      routine (Tough-mindedness, Abstract Thinking, Personal Confidence and
      Arousal). Performance confidence results from diligent work on, and
      proficiency in, all of golf’s skill sets (Playing, Technical, Mental,
      Training, and Physical)
    </div>
  </div>
</template>
