<template>
  <Panel
    header="Origin/Definition of Anxiety"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Catel’s arousal personality factor: Relaxed, Low Arousal: is how driven
        you are, crossed with impatience. Low scorers are relaxed, placid,
        tranquil, torpid, patient, composed low drive. High scorers are tense,
        high energy, impatient, driven, frustrated, over wrought and time
        driven.
      </div>
      <img
        class="img3"
        src="@/assets/images/mental/anxious1.png"
        alt="Tough Minded"
      />
      <div class="accent1 fontSmall p-mb-2">RAS and Anxiety</div>

      <div class="p-mb-2">
        Arousal is a brain state induced by action in the Reticular Activating
        System. When the RAS fires it releases neurotransmitters into the
        hypothalamus, thalamus and cortex (including the limbic system). There
        are many types of arousal and they can vary in intensity from mild to
        severe (consciousness arousal (unconscious-to-conscious), sexual arousal
        (platonic-to-sexually aroused) ‘personality arousal’ (laid
        back-to-driven), anxiety arousal (bored-to-alert-to-fight/flight).
        Anxiety-arousal occurs when the RAS responds to a stressor and activates
        the anxiety spectrum of emotions. When the intensity is high, this
        results in a fully fledged fight or flight response. This is what Graham
        is talking about.
      </div>
      <div class="p-mb-2">
        "Arousal," as we refer to it here, describes several things related to
        anxiety-arousal including: muscular states i.e., tension in muscle
        groups physiological states i.e., heart rate, blood pressure, skin
        temperature, brain waves and breathing psychological states i.e.,
        anxiety, worry, dread, excitement, and fear.
      </div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
    </div>
  </Panel>
  <Panel
    header="Peak Performance: Optimum Arousal Level"
    :toggleable="true"
    :collapsed="true"
  >
    <img
      class="img3"
      src="@/assets/images/mental/anxious2.png"
      alt="Tough Minded"
    />
    <div class="accent1 fontSmall p-mb-2">
      Yerkes-Dodson law: Task performance is best when arousal levels are in a
      middle range, with difficult tasks best performed under lower levels of
      arousal and simple tasks best performed under higher levels of arousal.
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Golf, like every sport, requires that you maintain what is referred to
        by most sports psychologists as an "optimum level of arousal" for peak
        performance' It is one of the most important of the eight champion
        traits because, without it, all of the other physical and mental skills,
        no matter how strong they may be, are diminished.
      </div>
      <div class="p-mb-2">
        The key to managing your arousal is your ability to manage
        stress-related tension (the muscular expression).
      </div>
      <div class="p-mb-2">
        In describing rounds, our tour clients often refer to "good tension" and
        "bad tension." Without the butterflies and excitement of the good
        tension that gives them the needed focus, tempo, and desire to compete,
        players find themselves playing underaroused, with a lax swing, little
        drive and poor focus. Playing with bad tension, on the other hand,
        leaves players feeling stiff, rushed, and bound by a tight body and an
        overwrought mind. The big difference? Good tension is an arousal level
        that you can control. Bad tension is an arousal level that is
        controlling you.
      </div>
      <div class="p-mb-2">
        Our bodies are designed to alter functions so that we have the best
        chance of survival when faced with life threatening situations - a
        ‘fight or flight’ response. Physiological changes are triggered by
        hormones our bodies release in the face of danger.
      </div>
      <div class="p-mb-2">
        The key here, however, is that the body does not know the difference
        between real and perceived danger. For example, your life is not truly
        in danger when you hit your first tee shot of a major tournament in
        front of a crowd.
      </div>
      <div class="p-mb-2">
        Several things happen when you encounter real or perceived danger:
      </div>
      <div class="p-mb-2">
        1. You release hormones: Initially epinephrine (adrenaline) and
        norepinephrine are released to jump start your system by producing more
        blood sugar and raising your heart rate. Later, endorphins are released
        to numb pain and injury. in the early stages you feel energized, perhaps
        even having to club down a bit to adjust for the extra length you
        suddenly possess. But unless you manage your arousal, your clubs will
        come to feel like unwieldy sticks in your hands and your brain and/or
        body will feel exhausted, sometimes before the completion of your round.
      </div>
      <div class="p-mb-2">
        2. Your heart races: As pulse and blood pressure climb, more fuel is
        carried to your important muscles and major organs, especially your
        brain. Prolonged arousal can make your hands and feet feel cold, stiff,
        or numb since the blood that normally would have flowed there has been
        rerouted. This, along with muscle tension or alignment, leads players to
        feel uncomfortable with their setup, usually without really knowing why.
      </div>
      <div class="p-mb-2">
        3. You need to go to the toilet: As your metabolism speeds up, you want
        to relieve yourself more often.
      </div>
      <div class="p-mb-2">
        4. You feel nauseous: Some players find it difficult to eat before and
        during the round. Again the body is diverting energy normally used for
        digestion to functions for survival (large muscles/brain not GIT)
      </div>
      <div class="p-mb-2">
        5. Your mouth dries up: As energy for digestion is diverted, the
        production of saliva is slowed. Later, because of the lack of proper
        digestion, constipation.
      </div>
      <div class="p-mb-2">
        6. Your muscles tense up: They receive a signal from the brain that
        danger is at hand and receive a surge of oxygen rich blood. At the
        instant your muscles are alerted, your impulse is either to run away as
        fast as possible or to fight, but golf allows neither. So this muscle
        tension must instead be managed, and all energies must be channeled into
        concentration and focus. The tension also must be released because
        holding on to it inhibits the swing. Releasing it by aggression,
        however-destroying tee markers, kicking bags, breaking clubs---can
        actually leave the player with a shaky “adrenaline hangover," a sore
        toe, and if that player is a touring professional, a hefty fine.
      </div>
      <div class="p-mb-2">
        7. Your breathing quickens and gets shorter: As muscle tension builds,
        chest muscles can constrict, causing you to take shorter quicker
        breaths. Overly aroused players will often unknowingly hold their breath
        as they execute a shot or putt, usually resulting in an awkward steering
        and a mis-hit.
      </div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
    </div>
  </Panel>
  <Panel
    header="Causes and Recognition of Hyper-arousal"
    :toggleable="true"
    :collapsed="true"
  >
    <anxiousRecognizeSomatic />
  </Panel>
  <Panel header="The Champion Golfer" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        As part of our effort to determine optimum arousal levels,we measured
        the tension levels of professionals of varying abilities. On a scale of
        one-to-ten, 1 being relaxed and 10 being tense, the champion golfers
        measure in the middle. More importantly, they are able to maintain the
        same or slightly higher levels of tension when they compete
      </div>
      <div class="p-mb-2">
        Most sports require range levels. As a general rule, tasks that require
        more Gross motor skills-such as your long game-require higher arousal
        and those that require more fine motor skills - like your short
        game-require lower arousal. For the long game, you will want to manage
        your arousal to around a 6. Your arousal should be at its lowest, at
        around a 4, when executing your short game, to maintain your feel and
        the use of fine motor skills.
      </div>
      <div class="p-mb-2">
        Many, many golfers play when over aroused. "My physical game is as good
        as it can be . . . I can hit about any shot I need to hit....What I need
        to be able to do better is turn off my brain and calm myself when I'm in
        position”. They rush to the first tee with nary a break in stride, whisk
        through a quick warm-up, and start playing without ever settling
        themselves. It's not unusual for them to play the entire round over
        aroused, sometimes never recognizing the tension that is sabotaging
        their play. If they do not take steps to manage their arousal before
        they tee off, no matter how much time they may have, there is little
        chance they will manage their arousal while they play. They end up
        gripping their clubs tighter and swinging faster. Their minds race. They
        have too little touch and feel and little or no patience.
      </div>
    </div>
  </Panel>
  <Panel
    header="Mx with Tough-Minded Cognitive Techniques"
    :toggleable="true"
    :collapsed="true"
  >
    <FocusedExternalAwareness />
    <FocusedInternalAwareness />
    <GuidedImagery />
    <MentalRest />
  </Panel>
  <Panel
    header="Mx with Tough-Minded Somatic Techniques"
    :toggleable="true"
    :collapsed="true"
  >
    <anxiousSomatic />
  </Panel>
  <Panel
    header="Mx with 'External' Techniques"
    :toggleable="true"
    :collapsed="true"
  >
    <nutrition />
  </Panel>
</template>

<script>
import anxiousSomatic from "@/components/mental/anxiousSomatic";
import nutrition from "@/components/mental/nutrition";
import anxiousRecognizeSomatic from "@/components/mental/anxiousRecognizeSomatic";
import FocusedExternalAwareness from "@/components/mental/focusedExternalAwareness";
import FocusedInternalAwareness from "@/components/mental/focusedInternalAwareness";
import GuidedImagery from "@/components/mental/guidedImagery";
import MentalRest from "@/components/mental/mentalRest";

export default {
  name: "Anxious",
  components: {
    FocusedExternalAwareness,
    FocusedInternalAwareness,
    GuidedImagery,
    MentalRest,
    anxiousSomatic,
    nutrition,
    anxiousRecognizeSomatic,
  },
};
</script>
