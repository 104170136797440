<template>
  <div id="nav">
    <router-link :to="{ name: 'Home' }">Home</router-link> |
    <a href="https://mjm-g-data.onRender.com" alt="mjm-g-data">mjm-g-data</a> |
    <router-link :to="{ name: 'Editor1' }">Editor1</router-link> |
  </div>
  <router-view />
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
