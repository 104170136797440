<template>
  <Panel
    header="Abstract Thought Training: Drown self-talk with Useful Ideas"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">SELF-TALK EXERCISES</div>
      <Panel
        header="1. To have a quieter mind:"
        :toggleable="true"
        :collapsed="true"
      >
        <div class="fontSmall taLeft">
          <div class="p-mb-2">1. To have a quieter mind:</div>
          <div class="p-mb-2">For one minute, feel your breathing.</div>
          <div class="p-mb-2">
            For one minute, in your mind’s eye see your ball roll in the cup
            from three feet.
          </div>
          <div class="p-mb-2">
            For one minute, grip a club, close your eyes and feel your hands on
            the grip. If you get distracted by your self-talk, start over.
          </div>
          <div class="p-mb-2">
            For one minute, look at an object such as a tee or a golf ball. Can
            you stay curious the entire time without having a conversation with
            yourself?
          </div>
          <div class="p-mb-2">
            For one minute, listen to the sounds around you.
          </div>
          <div class="p-mb-2">For one minute, count your breaths.</div>
          <div class="p-mb-2">
            Be silent for an entire practice session. Don’t talk to other people
            or comment on your own shots. Total silence. Also challenge yourself
            to have as much inner silence as possible. If you start talking to
            yourself, what are the tools you can use to silence the voice?
          </div>
        </div>
      </Panel>
      <Panel
        header="2. To become aware and to create the self-talk you want:"
        :toggleable="true"
        :collapsed="true"
      >
        <div class="fontSmall taLeft">
          <div class="p-mb-2">
            2. To become aware and to create the self-talk you want:
          </div>
          <div class="p-mb-2">
            Write down what you want to believe in order to be a confident
            person and golfer.
          </div>
          <div class="p-mb-2">
            For an entire practice session or a round of golf, stay aware of
            your self-talk. Keep ten tees in your right pocket. Every time you
            notice an unproductive thought, move one tee to the left pocket. Can
            you finish a practice session with all ten tees still in your right
            pocket?
          </div>
          <div class="p-mb-2">
            Write down three positive statements you believe about yourself as a
            golfer.
          </div>
          <div class="p-mb-2">
            Pay attention to your self-talk during the practice session. If you
            notice a thought that is not productive, replace it with one of the
            positive statements about yourself as a golfer.
          </div>
          <div class="p-mb-2">
            On the course, notice how you talk to yourself for three holes.
            Write down those words or use a tape recorder.
          </div>
          <div class="p-mb-2">
            Write down ten self-talk sentences that you would like to have as
            your inner monologue. Keep reading the list and sense what it feels
            like inside of you.
          </div>
          <div class="p-mb-2">Write down three goals for your golf.</div>
          <div class="p-mb-2">
            Write down three beliefs you have that support your goals.
          </div>
          <div class="p-mb-2">
            Write down three self-talk sentences that support the goals and
            beliefs.
          </div>
          <div class="p-mb-2">Functional Self-Talk:</div>
          <div class="p-mb-2">
            You can catch yourself having negative or unproductive self-talk and
            rephrase it.
          </div>
          <div class="p-mb-2">Masterful Self-Talk:</div>
          <div class="p-mb-2">
            All of your self-talk during a round is productive.You are present
            with no self-talk or instruction to yourself in the Play Box.
          </div>
        </div>
      </Panel>
    </div>
  </Panel>
</template>
