<template>
  <Panel header="QuickReferenceGuide" :toggleable="true" :collapsed="true">
    <Panel header="Introduction" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          You've gone through the book and made some changes to your putting
          stroke. That's great—but it's just the first step. If golf was as
          simple as learning a skill and then repeating it over and over again,
          professional golf would be really boring to watch, with 125 guys
          shooting 63 every day. Golf—and putting—is about making adjustments.
          We aren't machines, and fundamentals get out of whack for even the
          best players. That's why I have mostly ongoing relationships with tour
          players, not just one-time fix sessions.
        </div>
        <div class="p-mb-2">
          The moral of this story is that you shouldn't get discouraged if you
          go through some rough patches with your new putting stroke. It happens
          to the best players, and the only way out of it is to go back to the
          fundamentals and rebuild your confidence. This quick reference guide
          will help you do that. It's designed to remind you of the things we've
          talked about and worked on in this book, and to help you get back on
          track if you start to struggle.
        </div>
        <div class="p-mb-2">
          The putting stroke is pretty simple and straightforward. But it's also
          precise. Hit a tee shot with a slightly open face on your driver and
          you might miss in the light rough on the right. Hit a putt that way
          and you might miss a big five-footer to win a match. The good news is,
          by checking your fundamentals regularly and sticking to the basics,
          you can maintain your stroke and avoid slumps. When I haven't been
          playing a lot of golf and need to get back into tournament putting
          form, I essentially start from the beginning and go over the elements
          on this checklist, point by point, to rebuild my feel. It really is
          true that an hour invested on the practice green once or twice a month
          during your own golf season can pay bigger dividends than time spent
          working on any other part of your game. That's because solid,
          consistent putting can bail you out during a sketchy ball-striking
          day—and turn a good ball-striking day into a great scoring round.
        </div>
      </div>
    </Panel>
    <Panel header="Basics" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">Grip:</div>
        <ul>
          <li>
            The putter grip should run up the lifelines, not down across the
            first joints of the fingers.
          </li>
          <li>
            In a good putting grip, the shaft runs in a line parallel to the
            forearms—not under them as it would for a full shot.
          </li>
          <li>
            I recommend the reverse overlap grip, where the index finger of the
            left hand extends over the top of the fingers of the right hand.
          </li>
          <li>
            Grip pressure should be light, emphasizing feel in the
            fingertips—the part of your body responsible for touch.
          </li>
          <li>
            Both thumbs should be parallel, on top of the shaft. This sets the
            hands up parallel to each other and square to the target line at
            address.
          </li>
          <li>
            If you decide to use a nonconventional grip (cross-handed, claw), it
            is still important to set the hands so that the top of the forearms
            are square to the target line.
          </li>
          <li>
            A narrow stance is the most natural—you'd stand that way if we were
            having a conversation.
          </li>
        </ul>

        <div class="p-mb-2">Stance:</div>
        <ul>
          <li>Tilt from the hips, don't slouch the shoulders.</li>
          <li>Tilt from the hips, don't slouch the shoulders.</li>
          <li>Tilt from the hips, don't slouch the shoulders.</li>
          <li>
            Weight should be balanced across your feet from left to right and
            front to back.
          </li>
          <li>
            I like a square setup, with the shoulders, hips, knees and feet
            square to the target line.
          </li>
          <li>
            It's okay to set up with the feet a little open to the line as long
            as the primary alignment—the top of the forearms—stays square to the
            target line.
          </li>
          <li>
            The putterface should be at the center of your stance, with the ball
            just ahead of that.
          </li>
          <li>
            The shoulders and elbows should be relaxed and soft. The elbows
            should be soft and resting against your sides, not stiff and
            extended.
          </li>
          <li>
            The shaft should be at ninety degrees at address, or leaning a
            little bit toward the target. It's hard to hit the ball solid if you
            start with the shaft leaning back.
          </li>
          <li>
            Hold the putter in your dominant hand and use your dominant eye
            first when getting into your setup position. You will align yourself
            more accurately to the target.
          </li>
        </ul>
        <div class="p-mb-2">Stroke:</div>
        <ul>
          <li>
            The stroke comes from the shoulders turning around your spine, not
            the rocking of the shoulders up and down.
          </li>
          <li>
            The stroke is simple—the right elbow folds and the left arm extends,
            and then the right arm extends while the left folds.
          </li>
          <li>
            The putterhead should look like it is opening on the backswing and
            closing through impact, but this isn't something you do by flipping
            or turning your hands. It happens with shoulder turn and a slight
            forearm rotation.
          </li>
        </ul>

        <div class="p-mb-2">Swing Thoughts:</div>
        <ul>
          <li>
            Feel the swing of the putterhead in the takeaway by allowing the
            right elbow to soften.
          </li>
          <li>
            Release the tension in your shoulders before you make a practice
            stroke. Tense shoulders destroy feel.
          </li>
          <li>
            Release the putter by extending your right elbow rotating the
            forearms, not by flipping your wrists.
          </li>
          <li>
            Allow your shoulders to work around your spine tilt, not against it.
          </li>
        </ul>
        <div class="p-mb-2">PUTTING PROBLEMS:</div>
      </div>
    </Panel>
    <Panel header="Problems and Fixes" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">Pulls:</div>
        <ul>
          <li>
            Check your ball position. A pull can come from the ball being too
            far forward in your stance—where the natural arc of the stroke sends
            the ball left.
          </li>
          <li>
            Make sure the right forearm isn't higher than the left in your
            setup. This can be caused by a too-weak grip (hands turned toward
            the target) or a shoulder tilt (right shoulder higher than left).
          </li>
          <li>
            A closed stance (feet, hips, shoulders turned to the right of the
            target line) can actually cause a pull. You'll subconsciously yank
            the putter back to the left during the stroke to compensate for the
            right alignment.
          </li>
          <li>
            A too-long putter can cause you to stand too far from the ball,
            which lifts the toe of the putter and causes you to aim left.
          </li>
          <li>
            A putter with a too-upright lie angle will cause you to aim left.
          </li>
        </ul>
        <div class="p-mb-2">Pushes:</div>
        <ul>
          <li>
            Rotate the shoulders, don't rock them. Rocking causes the putter to
            go back closed and come through open, which causes the push.
          </li>
          <li>
            Make sure the left forearm isn't higher than the right. This can be
            caused by a strong grip (hands turned away from the target) or too
            much shoulder tilt (left shoulder higher than right).
          </li>
          <li>
            Stiff elbows and shoulders will promote a push—the clubhead can't
            release with all the tension.
          </li>
          <li>
            Ball position too far back can cause a push, but it's uncommon for
            players to set up with the ball back.
          </li>
          <li>
            A putter with a too-flat lie angle will cause you to aim right.
          </li>
        </ul>
        <div class="p-mb-2">Feel:</div>
        <ul>
          <li>
            A tight grip with the handle deep in the fingers takes the sensitive
            fingertips out of the stroke—a mistake. Keep the grip in the
            lifeline, with the fingertips more on the grip than the palms.
          </li>
          <li>
            Losing the mix of shoulder turn and arm swing is devastating to
            feel. A stiff, shoulders-only swing doesn't work well. You wouldn't
            toss something underhanded with just a shoulder turn.
          </li>
          <li>
            Feel comes from a flowing mix of shoulder turn, arm swing and
            forearm rotation.
          </li>
        </ul>
        <div class="p-mb-2">Distance Control:</div>
        <ul>
          <li>
            The best way to improve distance control is to improve your
            fundamentals. If you hit it solid, you'll have an incredibly natural
            sense for how far it will go.
          </li>
          <li>
            Grain impacts speed on all greens, not just those with Bermuda
            grass. On bentgrass greens, look for the shine. If, from behind your
            ball, the grass looks dark and almost shadowed, you're hitting into
            the grain and need to give it more speed. If the grass looks shiny,
            you're with the grain and the putt will be faster.
          </li>
        </ul>
        <div class="p-mb-2">Green Reading:</div>
        <ul>
          <li>
            Walk from your approach shot to the green with your eyes open—it
            gives you an overall feel for the tilt of the green.
          </li>
          <li>
            Examine your putt first from behind the ball, then from behind the
            hole and then from the low side of the break halfway between the
            ball and the hole.
          </li>
          <li>
            Most amateurs drastically underestimate the amount of break. If in
            doubt, play more break, especially on intermediate length putts
            (fifteen to twenty-five feet)
          </li>
          <li>
            Be conscious of aiming at your target—the apex of the break—and not
            cheating your aim toward the hole.
          </li>
          <li>
            Grain will impact your read most dramatically on fast downhill and
            sidehill putts.
          </li>
        </ul>
      </div>
    </Panel>
  </Panel>
</template>
