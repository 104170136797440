<template>
  <Panel header="Introduction" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Cool Narrow Focus: is how nice to people you are. Low scorers are
        impersonal, distant, cool, reserved, detached, formal and aloof. High
        scorers are outgoing, attentive to others, kindly, easy-going,
        participating and like people.
      </div>
      <div class="p-mb-2">
        You might be surprised to discover that the most common mental problem
        we encounter when profiling professional and amateur golfers is "focus"-
        or rather, an inability to focus. Yet being able to focus is the
        foundation of your mental game.
      </div>
      <div class="p-mb-2">
        All golfers appreciate the enormous mental discipline required to play
        the game well, yet so many obstacles and challenges interfere. The irony
        is that, while these challenges render the game frustrating and
        difficult, they also provide the stimulation and allure that attract so
        many people to the game in the first place-not to mention keeping those
        already playing in constant search of excellence and improvement.
        Conquering golf is to the golfer what the unclimbed mountain is to the
        mountaineer.
      </div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
    </div>
  </Panel>
  <Panel
    header="Focus and Golf's Inherent Challenges"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">Golf is inert</div>
      <div class="p-mb-2">
        The ball just sits there, waiting for you to make the first move. In
        momentum sports, such as tennis, you react quickly to the action of a
        competitor or a ball, and often both. In tennis, your reactive response
        is to move to the ball and hit it to where your opponent is not. It's
        much easier to focus when you have to react than when you have time to
        deliberate'
      </div>
      <div class="p-mb-2">
        Golf provides no such momentum to help you narrow your focus. You are
        left entirely to your own devices to cope with internal and external
        distractions. If you start thinking about swing mechanics, your score,
        how other players are playing, how they're scoring, who might be
        watching you and what they think of your game, you won't give yourself a
        chance of focusing'
      </div>
      <div class="p-mb-2">
        Unfortunately, you are left to your own devices to develop the mental
        skills necessary to react to a stationary ball.
      </div>
      <div class="p-mb-2">Golf has too much “Down Time”</div>
      <div class="p-mb-2">
        Even when played fast, in four hours or less, golf allows much time
        between shots for contemplating the game' For many' this is more than
        enough time to mentally sabotage them' selves. consider the tennis
        player again, who has little time to do more than react to the next
        rapidly approaching ball' When you think about it, the only time a
        tennis player gets to contemplate the stroke is when he or she is about
        to make the serve.
      </div>
      <div class="p-mb-2">
        The golfer, however, has minutes between every shot' and during a round
        those add up to hours of worrying about the next shot or the holes
        ahead. A golfer can unwittingly muddy his focus by giving himself swing
        lessons between shots. He may berate himself for mistakes or concern
        himself with what others are doing. He might ruminate on the problems in
        his life off the golf course or worry about hitting his next shot out of
        bounds. He can easily lose his focus thanks to a variety of random,
        distracting thoughts.
      </div>
      <div class="p-mb-2">
        There seems to be no limit on what you must do to hit a Perfect Shot
      </div>
      <div class="p-mb-2">
        The more the golfer knows about the game, the worse off he can be.
        There's that old saying that "a little knowledge is a dangerous thing."
        Well, that saying especially applies to golf.
      </div>
      <div class="p-mb-2">
        During a round, the golfer constantly faces different lies, grasses,
        grains, inclines, obstacles, winds, and other weather conditions. There
        are seemingly endless physical variables, including grip, stance,
        posture, ball position, swing planes, and so on, that must all be in
        perfect synch for the shot to be executed properly. The golfer must sort
        through these many details and quickly clear his mind of all but the
        simplest "swing thought" for the shot at hand. Lack of commitment, and
        the poor focus that results, can be caused by either underthinking or
        overthinking these variables. The golfer, in essence, walks a fine line.
      </div>
      <div class="p-mb-2">
        The Time it takes to play a round of golf can be Mentally and Physically
        Draining
      </div>
      <div class="p-mb-2">
        A round of golf requires four hours or more. So it is vital that the
        player learns to manage and conserve his mental and physical energies
        before and, especially, during the round. It is a common problem for a
        player at any level of the game from professionals who play many
        consecutive three- and four-day events to weekend amateurs who spend
        most of their time working or taking care of the kids-to lose focus
        because of mental and physical fatigue.
      </div>
      <div class="p-mb-2">
        These four factors common to all golfers, along with our own individual
        mental frailties, make golf one of our most mentally challenging sports.
      </div>
    </div>
  </Panel>
  <Panel
    header="A Champion Golfer and Focus"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">What is a Champion Golfer</div>
      <div class="p-mb-2">
        Our study found that players who test above average in terms of being
        cool and detached, as opposed to warm and outgoing, are best at
        narrowing their focus over the ball'
      </div>
      <div class="p-mb-2">Individual Challenges to “Good Focus”</div>
      <div class="p-mb-2">
        Being able to concentrate well is no guarantee you will be able to use
        such a talent under pressure, particularly if you have one or more of
        the other personality traits that can interfere with concentration
        skills-low confidence, high emotions, high tension, indecisiveness, and
        a tendency to overthink. But whether you have these other inhibiting
        traits or not, you will improve your ability to focus by learning a
        strong mental routine. Let's discuss some of these individual challenges
        to good focus before I share with you the same three-step routine I
        taught Dave Stockton
      </div>
      <div class="p-mb-2">
        Are you too intelligent to focus? Consider the player examples listed:
        Tom Byrum, Bruce Crampton, Phil Blackmar, and bave Stockton all have
        above average abstract-thinking skills. Each found that the steps
        recommended in this chapter for strengthening their mental skills came
        quite naturally to them, yet each found it difficult to use them at
        times because of high ibstract abilities that led to a very "busy" mind'
        Within seconds of hitting a shot, their minds would be racing away to
        their position in a tournament or to all the possible mechanical
        variables that went into a shot that may have been hit offJine. They
        each showed strong abilities to concentrate, but if they did not manage
        their tendency to overthink on the golf course, they could not maximize
        their powers of concentration.
      </div>
      <div class="p-mb-2">
        Are you too friendly to focus? The extroverted players
        mentioned,MichelleMcGann,TaylorSmith,BradBryant,andPaul Azinger, are apt
        to notice much more of what is going on around them and therefore have
        to work more at eliminating external distactions to narrow their focus
        over the ball and concentrate on the task at hand.
      </div>
      <div class="p-mb-2">
        Are you too tense to focus? Some players, no matter how narrow or wide
        their focus may be, are more tense than average. Their minds race, and
        they're far too busy to concentrate properly. Their focus iumps randomly
        and quickly to numerousthingsthatdonotinvolveiusthittingtheirshots.Many
        pros we profiled, such as Woody Austin, Mark McCumber' and john
        Schroeder, are, for various reasons inherent and learned' more tense.
      </div>
      <div class="p-mb-2">
        Are you too relaxed to focus? This is more common in social golf than in
        competitive golf, but it also leads to reduced concentration. Golfers
        who are too relaxed find their minds wandering, doing some of the
        "mental daisy picking" to which Bobby Jones refers.
      </div>
      <div class="p-mb-2">
        The ability to effectively regulate your focus is the foundation of a
        strong mental game. Because it is an important and necessary starting
        point for players of all levels' we always teach very simple and
        reliable skills for managing concentration and focus. From this
        foundation, other mental skills can be more easily assessed, taught, and
        reliably monitored so you can play at your peak more often.
      </div>
    </div>
  </Panel>
  <Panel header="Shot Routine and Focus" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        The primary mental skill of focus Using three simple steps, we teach you
        to willingly regulate your focus Do you have a mental routine? "Yes, I
        always set up two inches from the ball and take three good waggles
        before I look down the fairway, set my feet, and hit my shot." But that
        isn't a mental routine. That's a physical routine. We then ask about the
        "mental steps" taken while preparing to execute a shot. Soon the player
        recognizes that he probably doesn't even have a mental routine. His
        reasoning? "After it becomes a habit," he'll say, "I can be thinking of
        just about anything as I prepare to hit my shots." But the golfer
        shouldn't be thinking just about anything; he should be thinking only
        about hitting the shot. Our first goal is to initiate a pattern of
        reliable thoughts that will help you treat every shot the same. The
        thoughts will clear your mind, relax your body, and help you react to
        the ball.
      </div>
      <div class="p-mb-2">The Three-Step Mental Preshot Routine</div>
      <div class="p-mb-2">
        Like many players, you may recognize that you already use the following
        three steps to some degree. But your focus will improve dramatically
        when you effectively use all three steps as part of your whole preshot
        routine for every shot and putt.
      </div>
      <div class="p-mb-2">
        After each step, take a few moments to relax and practice the imagery
        that is requested. you,ll be amazed at how much more effectively you
        execute your difficult shot the next time you play the hole.
      </div>
      <div class="p-mb-2">Step 1: Calculations and Commitment</div>
      <div class="p-mb-2">
        Completing all of your analysis of the shot is the first step. This
        involves gathering all the information-wind, slope, lie, grain, yardage,
        target, and so on-necessary to make definite decisions about how to hit
        the shot.
      </div>
      <div class="p-mb-2">
        Your focus will begin to narrow as you make a firm commitment to your
        club, your target, and the type of shot you prefer.
      </div>
      <div class="p-mb-2">
        Which club? Pick the club to which you feel you can make a full
        commitment--one which you have at least a 50 percent probability of
        successfully hitting, based on the conditions, the type of shot you have
        chosen, your current physical skills, your level of tension, and the
        confidence in your physical game at that moment.
      </div>
      <div class="p-mb-2">
        What is the target? Choose as definite a target as possible, starting
        with an area in which you want your ball to come to rest. Then match it
        with something in the distance-such as a tree or building-that will help
        you take aim. Intermediate spots or features that are in line with your
        distant target can be helpful for some players. you must also adjust
        your target for crossing winds and the shape of the shot you choose.
      </div>
      <div class="p-mb-2">
        Which type of shot to hit? Select the type of shot that is appropriate
        to the situation and within your abilities to successfully execute 50
        percent of the time' There may be many options, especially if you are
        skilled enough to work the ball confidently. You could choose a draw a
        fade, a high shot' a low shot, a knockdown, and so on.
      </div>
      <div class="p-mb-2">
        For putts, the first step of the mental routine is to calculate and
        commit to two choices.
      </div>
      <div class="p-mb-2">
        What is the line? It's the one you choose after you have considered the
        length of the putt, the grain of the grass' the slope of the green, the
        speed of the green, the wind' and so on.
      </div>
      <div class="p-mb-2">
        What is the speed of the putt? Choose the speed you will roll the ball
        so that it follows the line you have chosen' You must consider all the
        things you assessed when picking the line. Resist thinking about whether
        this is a birdie putt or bogey putt because, except in certain
        situations, such as match play or a scramble format, it does not matter.
      </div>
      <div class="p-mb-2">
        Refuse to make decisions--on any shot-based on what others are doing or
        on how the course is "meant" to be played' Instead, choose only clubs,
        targets, types of shots' lines' and speeds that allow you to maximize
        your skills by fully commit' ting and giving yourself at least a 50
        percent chance of executing the shot or putt successfully.
      </div>
      <div class="p-mb-2">
        Once you are fully committed to all of your choices' you can move to the
        second step of the three-step process' If you find this commitment
        difficult, remember that you will get more from your game when committed
        to the wrong decision than you will when not committed to the right
        decision' In fact, we have had many amateur and professional clients
        lower their stroke averages doing nothing more than fully committing to
        their decisions-whatever they may be.
      </div>
      <div class="p-mb-2">Step 2: Visualization</div>
      <div class="p-mb-2">
        To further narrow your focus and to encourage reactive rather than
        analytical play, take a brief moment behind the ball making sure that
        you are very relaxed-to clearly visualize the shot or putt to which you
        have committed. Visualization ranges from a general impression of the
        desired ball flight to a very clear mental picture of the flight and
        landing.
      </div>
      <div class="p-mb-2">
        If you are putting, try to see your line from the ball to the hole. This
        is far easier for "feel" players than it is for "mechanical" players,
        but the effort enhances the performance of both. Mechanical players will
        want to find a spot on this imaginary line over which to roll the ball.
        Imaginative players will prefer to see the entire line. Either works, so
        long as you have a clear picture of what you want the ball to do.
      </div>
      <div class="p-mb-2">
        If you find visualization difficult or if negative thoughts interfere,
        you probably need to take another deep breath and fully relax your body,
        then try again. This step of your routine will later serve as a great
        signal for letting you know when you are overly tense or fatigued.
      </div>
      <div class="p-mb-2">Step 3: Feel</div>
      <div class="p-mb-2">
        In order to further narrow your focus and promote reactive play, it is
        important to clearly feel the swing or stroke to which you have
        committed.
      </div>
      <div class="p-mb-2">
        Either when behind the ball or beside the ball, try to feel your swing
        with a good waggle, a partial swing, or, preferably, a smooth full
        swing. Your goal is to incorporate one simple swing thought that allows
        you to feel the swing to which you have committed. The best swing
        thoughts tend to be the simplest and are most likely to center on the
        feel and tempo of your swing. "Smooth back, smooth through," "inside
        back, smooth through," "tempo," and so on are some swing thoughts that
        you might use. As you execute your shot, repeat this feel using your
        swing thought.
      </div>
      <div class="p-mb-2">
        When putting, take a practice stroke to feel the rhythm of your stroke.
        As with a full shot, this may be done either behind or beside the ball.
        If you have trouble feeling your stroke, relax, take a few deep breaths,
        and practice your stroke either with your eyes closed or while looking
        at the hole. You can also try giving your stroke a simple rhythmical
        count, such as "one back, two through."
      </div>
      <div class="p-mb-2">
        As you execute the putt, your emphasis should be on the rhythm of your
        stroke or the smooth roll of the ball down your intended line and not on
        the importance of making the putt.
      </div>
      <div class="p-mb-2">
        You should settle on one swing thought while you warm up and stay
        committed to it for at least an entire round. you can even write down
        those you like and refer to them on days when you find yourself
        searching.
      </div>
      <div class="p-mb-2">
        Your ultimate goal is to treat every shot the same by using the same
        mental routine for every shot and putt. you should challenge
        yourself_because it may not come easy_to use the same routine for
        two-foot putts or twenty-footers, on easy par fives and tough par fours,
        on sand shots or simple chips, on the first and the last shot of the day
        (and all those in between), and against easy and tough competitors. Not
        a single situation should move you away from your mental routine.
      </div>
      <div class="p-mb-2">
        Make note when your routine was weak or nonexistent, which you will
        typically recognize on shots or putts you executed poorly. Once
        identified, challenge yourself to hit these shots making your routine
        more important than the actual results of the shot. In other words, let
        the results happen.
      </div>
      <div class="p-mb-2">
        It is very important to practice your mental routine just as you would
        any other part of your game. On the practice tee, choose a target that
        closely resembles one you would use for a particularly difficult shot on
        the course you will be playing. Go through all the steps of your
        routine, iust as you would during a round. This practice is especially
        important for players with a naturally wider, external focus or for any
        player who has a pattern of playing several holes before settling into a
        round.
      </div>
      <div class="p-mb-2">
        You should warm up your mental routine as much as you do your swing.
        This can be accomplished in several ways: 1. Use your full routine on
        every fifth ball you hit on the Practice tee. 2. Take at least ten
        minutes of your warm-up to "create" various shots on the practice tee or
        around and on the greens. 3. Use mental rehearsal to play every shot for
        the first several holes on the range, choosing targets' committing to
        clubs, visualizing shots, feeling swing thoughts.
      </div>
      <div class="p-mb-2">
        Your routine also becomes a much more effective tool if, after each
        round, you assess how well you used it. A few accompanying notes about
        circumstances such as your mood, the conditions, playing partners, the
        significance of the round, will give you great insight to your
        tendencies and when you must make a concerted effort to use your
        routine.
      </div>
      <div class="p-mb-2">
        A good mental routine accomplishes several important goals. It allows
        you to play more intuitively, as opposed to manufacturing a swing. It
        also helps to displace irrelevant thoughts, allowing you to think only
        about the task at hand. This serves to quiet the mind and relax the
        body, which, of course, enhances your play. The mental routine helps you
        make good decisions and helps you commit fully to the club in your hand,
        your target, and the shot you want to hit, thereby maximizing your
        physical skills. It also gives you the power of "self-fulfilling
        prophecy," which, simply put, is the ability to increase the odds of
        hitting the shot you want by first imagining you will.
      </div>
    </div>
  </Panel>
  <Panel header="Signs of Poor Focus" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        "I play the tough holes better than the easy ones."
      </div>
      <div class="p-mb-2">
        Don't you hear that sort of comment from an awful lot of golfers? Many
        players notice that they hit better shots on tight fairways and through
        small openings or that they make more of the difficult putts than the
        easy ones. What's actually happening is that, without the golfers
        knowing it, the tougher circumstances are either lowering expectations
        and narrowing focus to targets, clubs, and types of shots or they are
        encouraging the golfers to get good pictures of the shots or putts
        they're about to hit. No wonder they're doing better. Without realizing
        it, they're doing things right!
      </div>
      <div class="p-mb-2">
        Most golfers have inconsistent focus. They are not controlling their
        focus and are throwing strokes away because they are not treating all
        shots on every hole the same. No matter what the circumstance, always
        give each shot the same mental routine and focus.
      </div>
      <div class="p-mb-2">
        "I always seem to 'choke' when I'm playing well or when it really means
        something."
      </div>
      <div class="p-mb-2">
        Many players have what some call a "comfort zone." When they find
        themselves in position to shoot an all-time low score, in contention, or
        in a position to win a bet, their play quickly goes from great to
        terrible. Increased tension and emotions can wreck the focus and the
        fun. But strong and practiced routines serve as "anchors," giving them
        reliable thought processes that will relax them and allow them to treat
        every shot the same regardless of its perceived importance.
      </div>
      <div class="p-mb-2">
        "Before the round is over, I'm exhausted. I feel like I'm working too
        hard out there."
      </div>
      <div class="p-mb-2">
        As we stated earlier, many players---especially the very intelligent
        ones-tend to overthink and overwork on the golf course. It's not unusual
        for them to unknowingly play an entire round of golf very mechanically
        and analytically. They never stop analyzing their mechanics, what they
        need to shoot, how they'll play holes ahead, or why they missed previous
        shots or putis. Th"y .r.u", release their trained and natural skills by
        visualizing, feeling, and reacting to their shots' When encouraged
        to1rust their skills by visualizing and feeling' their first .Jrporr.
        is, "I feel like I'm not trying hard enough!" But if they remain
        committed to the full routine long enough' golf begins to feel more like
        a game than a job' Play improves and they finish their rounds with much
        more energy and enthusiasm.
      </div>
      <div class="p-mb-2">
        While using a good mental routine may sound easy' it takes discipline,
        practice, and patience to use one consistently and effectively. A strong
        mental routine represents one of the most distinctive differences
        between champion golfers and average golfers, on both the amateur and
        professional levels' In fact-, the strength and consistency of your
        mental routine will determine your success as a competitive golfer
      </div>
    </div>
  </Panel>
  <Panel header="Other notes of mine" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">‘Being present’ or ‘In the Moment’</div>
      <div class="p-mb-2">
        Being focused is a state of being inside your body with all your
        attention tunnelled down on internal feels. During a round the ‘shot
        routine’ and ‘state control’ are good examples of using focus.
      </div>
      <div class="p-mb-2">Shot Routine</div>
      <div class="p-mb-2">
        The feel of the shot, and the directional feel to the target. There is
        no attention directed toward activity in the external environment, nor
        inward to self-talk or emotions. This is one way of ‘being present’, or
        being ‘in the moment’.
      </div>
      <div class="p-mb-2">
        You can think of focus as a) the awareness in your body of the required
        movement (premotor cortex trigger initiating the basal
        ganglia-cerebellar learned swing), and then, b) the kinesthetic feel
        during the swing as the real movements take place. c) the directional
        feel toward the target.
      </div>
      <div class="p-mb-2">
        Thus, focus during the swing is not cognitive, but body feelings that
        initiate and then occur with the swing and point to the target.
      </div>
      <div class="p-mb-2">
        Focus begins during the shot routine as you funnel your attention and
        awareness down to internal feels with the practice swing in ‘feel it’.
        Your focus then deepens as you move into the ball to execute the shot in
        ‘trust it’.
      </div>
      <div class="p-mb-2">The Funnel of Focus:</div>
      <div class="p-mb-2">
        The shot routine is the mechanism one uses to narrow focus (funnel
        focus), from ‘see it’ through hitting shot (‘trust it’). It is designed
        to; (i) reduce anxiety (being in present) and distractions, (ii) prevent
        an inverted funnel (increased distractions) leading to a sympathetic
        outflow (weakness, palpitations, shaking, anxiety), and (iii) promote a
        mindset where the ‘just right’ swing will be applied to the ‘just right’
        shot (enhanced probability of success - goal of capacity approaching
        capability in competition).
      </div>
      <div class="p-mb-2">Vigilance skill:</div>
      <div class="p-mb-2">
        The mental skill used for the ‘between shots’ routine, is primarily an
        exercise in focus - cognitive focus in this case. The skill involves
        ignoring distractions at appropriate times during a round and performing
        focused scans of body, emotion, cognition and environment to determine
        if any interventions are required.
      </div>
    </div>
  </Panel>
</template>
