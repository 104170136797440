<template>
  <Panel
    header="Technique (Sieckmann: Short Game Solution)"
    :toggleable="true"
    :collapsed="true"
  >
    <Panel header="Introduction" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          If you’re going to take advantage of the par 5s and short par 4s, and
          be able to play smart when you get in trouble and still score, it’s
          critical to be a great distance wedge player.
        </div>
        <div class="p-mb-2">
          A distance wedge is essentially a mini full swing using a power
          sequence. Recall that we eliminated the use of the archaic terms
          “chip” and “pitch” way back in Chapter 4 and replaced them with
          “finesse wedge” and “distance wedge.” It makes sense to define
          short-game shots by their kinematic sequence, because the sequence, to
          a large degree, is responsible for producing the optimal contact, ball
          flight, spin rate, and power output required for all wedge shots.
        </div>
        <div class="p-mb-2">
          A distance wedge shot normally carries in the range of 35 to 120
          yards. The actual range will vary from player to player based on
          strength and skill, but this is a pretty good average. The top end of
          your distance wedge range should equal the carry distance of your
          pitching wedge on a full swing. Near the bottom end of the range, both
          finesse- and distance-wedge techniques are available to you. Base your
          decision as to which swing to use on shots from 30 to 50 yards on what
          produces the appropriate ball flight and spin rate for the shot you’re
          facing.
        </div>
        <div class="p-mb-2">
          For example, a 40-yard finesse wedge shot, hit with the right
          technique, launches relatively higher and with less spin than the same
          40-yard shot hit using the distance wedge technique. On the finesse
          wedge shot, the ball hits the green and reacts and rolls more like a
          putt, while a distance wedge shot of the same distance produces that
          sexy sizzle of the ball checking on the green that you so often see on
          TV. In any case, as a short-game master, it’s great to have choices
          that allow you to be in total control of your ball.
        </div>
        <div class="p-mb-2">THE VALUE OF DISTANCE WEDGES TO SCORING</div>
        <div class="p-mb-2">
          Distance wedge shots represent a huge opportunity when it comes to
          scoring. When you consider the percentage of putts you make from each
          distance on the green, there are plateau levels of performance when it
          comes to scoring. If you currently average a 30-foot putt following a
          distance wedge shot, and through hard work improve this average to 25
          feet, it’s likely that your scores won’t change, even though you’ve
          improved your distance wedge accuracy by almost 17 percent! Why?
          Because there’s very little difference in your putting average from
          those two distances. Of course, you don’t hit all of your distance
          wedge shots your average distance all the time. You hit good ones and
          bad ones, the aggregate of which give you your average. Although the
          stat “Average Proximity” is the current matrix used to rank players’
          distance wedge skill on the PGA Tour, it doesn’t begin to measure how
          these shots truly affect score. A better measure of skill would be the
          percentage of time a player can pull off a wedge shot that’s so
          fantastic that it gains him or her a stroke compared to the
          competition, as well as the percentage of time he or she hits a shot
          that’s so horrible that it causes him or her to lose a stroke to the
          competition (i.e., missing the green). It won’t be long before there’s
          a Strokes Gained statistic for distance wedges, which will be really
          helpful for me as I work with my Tour players.
        </div>
        <div class="p-mb-2">
          The goal, as it has been throughout this book, is to make consistently
          solid contact in order to eliminate bad misses, as well as to develop
          the skills needed to control distance so that there’s a high
          probability that you’ll convert the putt. During practice, I try to
          quantify these plateau levels for my clients by having them think of a
          shot that ends up within two steps of their number as a birdie, within
          three to ten steps as a par, and within ten or more steps as a bogey.
          These are high standards, but they accurately reflect scoring reality.
          As such, it’s important that you embrace that challenge.
        </div>
        <div class="p-mb-2">Having a Plan</div>
        <div class="p-mb-2">
          One of the things that Dave Pelz taught me when I worked for him was
          that almost every player can hit the ball reasonably straight when
          they have a lofted wedge in their hand, but the determining factor for
          success is being able to control distance. The main error in the short
          game is hitting the ball too far or too short; in the power game it’s
          too far to the left or right. For this reason, distance control in
          your short game is the key variable. A properly struck 60-yard shot
          that flies 70 yards into a back bunker is still an awful result,
          regardless of how much you enjoyed it and how good it looked while it
          was in the air.
        </div>
        <div class="p-mb-2">
          To be great, you must have a simple system that allows you to pick the
          correct shot, and to control both spin and trajectory so that you can
          consistently hit the ball the correct distance. If you don’t like the
          thought of having a “system” because you’re a “feel” player, to me
          that’s just code for, “I don’t have a clue what to do, and being a
          great distance wedge player is going to take too much focus and
          effort, so I’ll hide behind the ‘feel player’ moniker.” Of course you
          play by feel—we all do! The trick is to own a plan that complements
          your feel and that helps you develop touch so you can execute at the
          highest level. Not only does my system work, it’s Tour-proven: Nick
          Watney, Tom Pernice Jr., and Kevin Chappell have all been at the top
          of one of the three distance wedge stat categories on the PGA Tour in
          recent years. They’ve mastered the system, and so can you.
        </div>
      </div>
    </Panel>
    <Panel header="Distance Wedge Matrix" :toggleable="true" :collapsed="true">
      <img
        src="@/assets/images/sieckmannShortGame/matrix1.png"
        class="img4"
        alt="Sieckmann Wedge"
      />
      <div class="accent1 fontSmall p-mb-2">Sample Distance Wedge Matrix</div>
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          In Chapter 3, I recommended a wedge-set makeup of two, three, or four
          wedges based on the carry number of your full pitching wedge, with
          yardage gaps of no more than 20 yards. Let’s say you carry four
          wedges: pitching wedge, gap wedge, sand wedge, and lob wedge. You
          already have four known full-swing numbers. It’s important to note
          that these numbers represent carry yardages on properly struck shots,
          not the total distance the ball travels. You need to be very precise
          to get the ball up and in, and thinking in terms of total distance
          doesn’t cut it.
        </div>
        <div class="p-mb-2">
          In addition to your four known full-swing numbers, you should create
          two more for each wedge, giving you a total of twelve. (If you carry
          three wedges, you’ll have nine.) Get them by shortening your
          backswings with a slightly more rhythmic pace to impart less energy to
          the ball. How short? Using the image of a clock, I’ve always picked
          nine o’clock and 7:30 to represent the lead-arm position at the top of
          the backswing. I.K. Kim calls her backswing benchmarks
          “three-quarters” and “one-half.” I have students who prefer to use
          their armpit and belly button as guides. I really don’t care what you
          call them or what you use as long as you can repeat them consistently.
          Your distance wedge backswing benchmarks must feel so comfortable that
          you can hit them in your sleep. After doing this, your numbers might
          look something like this:
        </div>
      </div>
    </Panel>
    <Panel
      header="Distance Wedge Technique"
      :toggleable="true"
      :collapsed="true"
    >
      <img
        src="@/assets/images/sieckmannShortGame/distanceWedge1.png"
        class="img4"
        alt="Sieckmann Wedge"
      />
      <div class="accent1 fontSmall p-mb-2">
        SETUP: Ball positioned in the middle, weight slightly favoring lead leg.
      </div>
      <img
        src="@/assets/images/sieckmannShortGame/distanceWedge2.png"
        class="img4"
        alt="Sieckmann Wedge"
      />
      <div class="accent1 fontSmall p-mb-2">
        BACKSWING: Hitting the nine o’clock benchmark, head position hasn’t
        moved at all.
      </div>
      <img
        src="@/assets/images/sieckmannShortGame/distanceWedge3.png"
        class="img4"
        alt="Sieckmann Wedge"
      />
      <div class="accent1 fontSmall p-mb-2">
        PHASE 1 OF DOWNSWING: Three to four inches of lateral shift as arms
        swing down in front of the body.
      </div>
      <img
        src="@/assets/images/sieckmannShortGame/distanceWedge4.png"
        class="img4"
        alt="Sieckmann Wedge"
      />
      <div class="accent1 fontSmall p-mb-2">
        PHASE 2 OF DOWNSWING: Rotary body motion (employing his verticals so he
        can strike the ball with his hands ahead).
      </div>
      <img
        src="@/assets/images/sieckmannShortGame/distanceWedge5.png"
        class="img4"
        alt="Sieckmann Wedge"
      />
      <div class="accent1 fontSmall p-mb-2">
        MID–FOLLOW-THROUGH: Energy flowing to the pin while covering the ball
        with his trail side.
      </div>
      <img
        src="@/assets/images/sieckmannShortGame/distanceWedge6.png"
        class="img4"
        alt="Sieckmann Wedge"
      />
      <div class="accent1 fontSmall p-mb-2">
        WORLD-CLASS FINISH: Smooth, positive acceleration up to the finish line.
      </div>
      <img
        src="@/assets/images/sieckmannShortGame/distanceWedge7.png"
        class="img4"
        alt="Sieckmann Wedge"
      />
      <div class="accent1 fontSmall p-mb-2">
        Your distance wedge finish position is a constant. End every swing with
        your hands near the logo on your shirt.
      </div>

      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          How Distance Wedge Swings Differ from Full Swings
        </div>
        <div class="p-mb-2">
          To produce your known numbers on demand, it’s not enough to just
          shorten your backswing. You need to be locked in and consistent with
          both your downswing effort level and your finish position. Effort
          level is subjective, so you need to quantify it. When I coach, I
          always have my players make a normal-paced full swing (i.e., carry
          their pitching wedge its full distance), then say to them, “That
          represents a 9-out-of-10 effort for you. You could hit it a few yards
          farther if you had to (that would be a 10 out of 10), but don’t,
          because you’ll sacrifice control and consistency.” I’ll then instruct
          them to make a nine o’clock backswing and finish at their lead
          shoulder with a 7-out-of-10 effort. It might take a few repetitions
          focusing on downswing energy before they can lock onto the smooth,
          positive effort level I’m looking for. Being consistent with the
          effort is critical to my system. Without it, backswing length won’t
          have the desired relevance to the distance you want the ball to travel
          and your touch will be inconsistent. Make evaluating your effort level
          part of your post-shot routine.
        </div>
        <div class="p-mb-2">
          Other than effort level and the use of benchmarks, your distance wedge
          swing shouldn’t be that different than your full swing, with three
          notable exceptions:
        </div>
        <div class="p-mb-2">
          1. A narrower stance. Set up with your feet square to the target line
          and no more than 12 inches apart, with the ball in the middle of your
          stance.
        </div>
        <div class="p-mb-2">
          2. More weight over your front leg at address. 55 percent will do.
          This is especially critical for players with a higher ball flight and
          those who struggle with fat and thin contact.
        </div>
        <div class="p-mb-2">
          3. A shift-free backswing. As you swing to the top, maintain the
          weight distribution that you established at address. In a full swing,
          it’s quite common for players to load into their trail leg on the
          backswing by letting their shoulder rotation push their upper body and
          head behind the ball, but you won’t see them do this from 70 yards.
          There’s simply not enough time to turn behind the ball on a little
          swing and then shift to get your center back on top of it by impact.
          If you do turn and load behind the ball, not only will your trajectory
          be too high, you’ll also struggle with fat and thin misses.
        </div>
        <div class="p-mb-2">
          Understanding Distance Wedge Ball Flight and Swing “Verticals”
        </div>
        <div class="p-mb-2">
          To maximize control and consistency in varying conditions, the best
          distance wedge players typically “flight” the ball down a bit while
          creating enough spin to stop it quickly on the green despite the lower
          trajectory. This is done by using the correct kinematic sequence to
          produce power, properly delivering the club into the ball, and
          creating sufficient clubhead speed and friction between the clubface
          and the ball. You need sharp grooves and a clean lie to create maximum
          spin, which is why professional players change wedges each year. (Take
          note and consider doing likewise when the groove edges start to become
          noticeably worn.)
        </div>
        <div class="p-mb-2">
          It’s intuitive that more loft and more speed creates more spin, but
          how do you flight a lofted wedge? Remember that as the goals
          pertaining to the use of the club change, so does the technique. If
          you used your finesse sequence on a shot from 75 yards, not only would
          it be next to impossible to hit a lofted club that far, that movement
          would produce an extremely high ball flight that would be at the mercy
          of the vagaries of the wind. A stronger, more penetrating flight is
          more effective. To accomplish this, slightly deloft the club at impact
          by having the handle lead the clubhead through the ball. A power
          sequence and the proper blend of both lateral and rotary motion from
          your body in the downswing are necessary to deliver the club this way.
          The lateral shift not only moves the low point of your swing arc
          forward, but buys time for you to swing the handle back down in front
          of your body, which is key to getting the shaft to lean slightly
          forward at impact.
        </div>
        <div class="p-mb-2">
          This is essentially the first phase of the downswing. The second phase
          is the rotary motion of the body through impact and the continuation
          of the arm swing. This motion creates what I call “verticals” in your
          swing. Think about the club’s interaction with the turf for a minute:
          Your hands are ahead at impact and your weight is moving into your
          lead leg. It seems like this arrangement has created the potential for
          you to “dig a trench” with your wedge, and although taking a small
          divot is fine, this isn’t what you see on TV. Why? When your body
          rotates in the second phase of the downswing, two things happen: 1)
          your lead leg begins to straighten (upward movement) as the hip turns
          back over the lead heel; and 2) the handle of the club ever so
          slightly follows suit as the lead shoulder rotates up and back around
          your head. Both of these verticals shallow your angle attack, allowing
          you to have your hands ahead of the clubhead at impact and your swing
          center forward without being too steep and digging a trench.
          Essentially, you’ve paired two steepening elements (stacked backswing
          and lateral downswing shift) with the two shallowing elements embedded
          in the verticals. The net effect is perfect contact and a controlled
          ball flight.
        </div>
        <div class="p-mb-2">
          Watch PGA Tour Player Ben Crane explain how he hits a perfect nine
          o’clock distance wedge in a special video. Visit
          jsegolfacademy.com/index.php/ben-crane.
        </div>
      </div>
    </Panel>
    <Panel
      header="Create Your Known Numbers"
      :toggleable="true"
      :collapsed="true"
    >
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          With the preceding guidance on technique, you’re ready to establish
          your known numbers. You’re going to need feedback on how far each ball
          travels, so you’ll need decent balls that are similar to those you
          typically play. Follow these steps:
        </div>
        <div class="p-mb-2">
          Step 1: Go to the end of the range (or even a field in your
          neighborhood) and walk out targets every 10 yards, starting at 30
          yards out up to your full pitching-wedge carry number. (The players I
          coach on Tour all travel with lightweight, stackable agility cones for
          this purpose.) I’m aware that this will require some planning and
          effort, but good players are willing to do anything and everything
          that’s necessary to improve. Make it happen.
        </div>
        <div class="p-mb-2">
          Step 2: After you warm up through a full 7-iron, make five great
          swings to your nine o’clock benchmark, giving each one 7-out-of-10
          effort through the ball and consistently finishing up to your lead
          shoulder. Throw out the outliers and note your average carry numbers.
        </div>
        <div class="p-mb-2">
          Step 3: Repeat the process for all of your wedges and write the carry
          yardages down on the back of the shaft with a Sharpie. Place a piece
          of Scotch Tape over the numbers so they don’t wear off. Also note them
          in your journal in the distance wedge section. Eventually, it should
          look something like the sample Distance Wedge Matrix shown earlier in
          this chapter.
        </div>
        <div class="p-mb-2">
          Step 4: Repeat steps 2 and 3 using your 7:30 benchmark.
        </div>
        <div class="p-mb-2">
          These numbers and swing distances are only reference points. They’re
          not and shouldn’t be your only swing choices. Almost every shot you
          face will ultimately “feel” a little more or a little less than a
          stock benchmark shot, which will give you tens if not a hundred
          swings. Regardless of which swing you use, finish in the same place
          every time. End your swing with your hands near the logo on your lead
          chest with a 7-out-of-10 effort. Keeping your finish consistent allows
          you to add or subtract distance from any known number by simply
          adjusting your backswing length.
        </div>
        <div class="p-mb-2">
          As with all shots where the ball is compressed, ground and atmospheric
          conditions affect the distance a ball will fly. Your base yardages are
          based on normal conditions, but if it gets extremely hot or you find
          yourself at elevation, you’ll need to note how far your base shots fly
          that week in practice and adjust accordingly. The pros I coach check
          their numbers on the Tuesday of a tournament week as they travel from
          one environment to another. Knowing exactly how much the conditions
          affect their distances gives them a competitive advantage over those
          that don’t put in the work and just guess.
        </div>
      </div>
    </Panel>
    <Panel
      header="Choosing the Correct Shot"
      :toggleable="true"
      :collapsed="true"
    >
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          The centerpiece of my plan is to control both the trajectory and spin
          so that your ball will “one hop and stop” when it lands on the green.
          A ball that either rolls forward or sucks back makes it difficult to
          stop the ball pin high, because it adds another layer of judgment.
        </div>
        <div class="p-mb-2">
          If you look at the sample Distance Wedge Matrix, you’ll notice that
          the smallest backswing with a pitching wedge produces the
          lowest-trajectory, lowest-spinning shot, and a full swing with the
          most-lofted wedge produces the highest-trajectory shot with the most
          backspin. Within this matrix you can always hit two or sometimes three
          different clubs the same carry distance, but with completely different
          trajectories and spin rates. Picking the right shot, then, is nothing
          more than getting a feel for which shot will one-hop-and-stop on the
          green given the conditions (hard or soft greens, into the wind, off a
          downslope, etc.). Having this feel or sense is a skill that must be
          developed. It’ll come with intelligent practice. Choosing the correct
          shot and the ability to add or subtract energy from your known numbers
          is the art of distance wedge play, and will allow you to outperform
          your old self and those around you.
        </div>
        <div class="p-mb-2">Distance Wedge Strategy</div>
        <div class="p-mb-2">
          Based on PGA Tour ShotLink data, your strategy should prioritize the
          following: 1. Laying up in the fairway. 2. Pushing the ball up as
          close to the green as is comfortable, as long as it doesn’t involve
          unnecessary risks, bringing hazards into play, or losing quality
          position. Obviously, there are situations when laying back to create
          more spin is the smarter play (tough pins on firm greens, playing
          downwind, etc.), but these are exceptions to the rule.
        </div>
        <div class="p-mb-2">Process Is King</div>
        <div class="p-mb-2">
          As with finesse wedges, the process of hitting and evaluating a shot
          needs to be more important than the shot itself. Define the process
          and own it. It should look something like this:
        </div>
        <div class="p-mb-2">
          1. Assess the lie, the wind, and the quality position to the pin so
          you can identify a precise finishing target: I want to stop this shot
          hole high but one yard left of the pin.
        </div>
        <div class="p-mb-2">
          2. Get the total yardage to that target: It’s 84 yards.
        </div>
        <div class="p-mb-2">
          3. Choose the club and shot that will one-hop-and-stop on the green:
          It’s a sand wedge.
        </div>
        <div class="p-mb-2">
          4. Given this shot and the conditions, announce the perfect carry
          number: I’m going to carry it 80 yards.
        </div>
        <div class="p-mb-2">
          5. Adjust your perfect carry number to one that represents how far it
          will actually play given the conditions: Because it’s downhill and
          downwind, I think it’ll play more like 74 yards.
        </div>
        <div class="p-mb-2">
          6. Get a plus or minus yardage relative to one of your known numbers
          with that club: My nine o’clock shot carries 70 yards, so this is just
          four yards more.
        </div>
        <div class="p-mb-2">
          7. Pull your sand wedge and rehearse the feel of the shot. If you have
          a swing key based on a needed technique change, go ahead and focus
          internally and rehearse this key away from the ball. Internalize its
          feel and then put it in its proper place, which is nothing more than a
          gentle awareness from this point forward (awareness, not thought).
        </div>
        <div class="p-mb-2">
          8. Face the target and visualize the shot you’ve chosen using external
          focus. Walk in clear to that picture, with 100 percent commitment to
          your strategy.
        </div>
        <div class="p-mb-2">9. Address the ball.</div>
        <div class="p-mb-2">
          10. See and do. “Tie your shoe.” Trust. You’ve practiced hard and
          smart. The swing needed to produce a great shot is inside. Let it go.
        </div>
        <div class="p-mb-2">
          11. If it’s a good shot, embrace it. Show some emotion. Smile, if
          nothing else, and grow your S.I. If it’s a poor shot, objectify it
          without emotion and ask yourself what the solution to that shot would
          be if you had a do-over. After becoming aware of it, replay it
          correctly in your mind and then be done. Self-coach in a positive way.
          View it as an opportunity to grow and to make the late, great John
          Wooden proud. This is something you can control!
        </div>
        <div class="p-mb-2"></div>
      </div>
    </Panel>
  </Panel>
</template>
