<template>
  <Panel header="Distraction" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Notice trees, grass, birds, wind internal comment if necessary
      </div>
      <div class="p-mb-2">
        Object meditation: Outlining trees: Look for the biggest tree in sight
        and begin outlining it in your mind. Slowly take your eyes around the
        entire profile of the tree. When finished your body will be more relaxed
        and your mind more clear. You can do this with any object, not just a
        tree.
      </div>
      <div class="p-mb-2">
        Nature: Scan the surroundings for wildlife that is likely to be found in
        the area rabbits, turtles, fish, birds, snakes, and alligators.
      </div>
    </div>
  </Panel>
</template>
