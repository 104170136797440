<template>
  <Panel header="The Art of Putting" :toggleable="true" :collapsed="true">
    <Panel header="Introduction" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">What is the putting stroke, anyway?</div>
        <div class="p-mb-2">
          Sure, the idea is to get the ball in the hole. You can do that a lot
          of different ways. To use some current examples, take Brad Faxon and
          Billy Mayfair. Brad has a gorgeous putting stroke. I could watch him
          hit putts all day. Billy's stroke is different, with the heel of the
          putter up in the air and an outside-to-outside loop. I'm not
          criticizing it, because Billy has won a lot of money with that stroke.
          But I know how hard Billy has to work to keep his stroke sharp. Brad
          Faxon can get out of bed and stroke the ball great. Like I said, you
          can stroke the ball a lot of different ways, but I believe that it's
          easier to consistently be good when you have a fundamentally sound
          stroke.
        </div>
        <div class="p-mb-2">
          There are two schools of thought when it comes to the fundamentals of
          the putting stroke. One way to putt is to try to keep the face of the
          putter square to the target line through the entire stroke.
          Face-balanced putters are designed to work with this type of stroke,
          and Dave Pelz has done very well teaching that style. Loren Roberts
          has made miles of putts using that technique, too. But I believe the
          square-to-the-target-line method works counter to what your body wants
          to do, and counter to what you do with the rest of your clubs on full
          shots. It also takes a lot of practice to maintain. That's fine for
          tour players, who spend hours every week on the practice green, but a
          challenge for the recreational player.
        </div>
        <div class="p-mb-2">
          In my opinion, the putting stroke shouldn't be fundamentally different
          than a full shot, just a smaller version of it. I love to use the
          phrase “golf is a side-on game,” which I learned from instructor Jim
          Hardy. You stand to the side of the ball when you hit it, and the
          shafts in your clubs, putter included, don't run straight up and down
          from the clubheads. Because you're to the side of the ball when you
          hit it, the swing has to happen in a round or circular motion. The
          tilt of that circle is dictated mostly by the shaft angle at setup.
          This swinging in a circle at a tilt is what causes the swing plane
          you've seen in so many golf instruction diagrams.
        </div>
        <div class="p-mb-2">
          Here's a better way to illustrate what I'm saying. If I swung really,
          really fast with my putter—say ninety miles per hour—but I hit the
          ball with my method, it would still go straight. If I did that with
          the straight-back-straight-through method, that ball would slice, and
          I'll explain why in a second. I believe that the
          straight-back-straight-through method is based on the premise of
          swinging on an axis that is fairly vertical. The eyes are over the
          ball, and the shoulders rock up and down the target line in order to
          make the swing work. To me, that doesn't make sense when you're
          standing to the side of the ball with a club that doesn't swing on an
          axis that is straight up and down.
        </div>
        <div class="p-mb-2">
          Without going into too much physics mumbo jumbo, the clubface on a
          full swing moves in a way that's square to the arc, not square to the
          target line. Since I believe the putting stroke is simply a smaller
          version of a full swing, that means the putter should follow the arc
          back and through, and stay square to the arc the same way. It will
          swing slightly inside the target line on the way back, and slightly
          inside on the way through. It will look like the face of the putter is
          opening and closing, but that's happening because of the natural
          rotation of your body, the arms and shaft around the swing plane, not
          by any conscious twisting or flipping of your hands.
        </div>
        <div class="p-mb-2">
          But wait. You're probably thinking that swinging the putter inside the
          target line can't possibly be as precise as bringing it straight back
          and straight through on the target line, right? If the putter is doing
          all this “opening” and “closing,” that can't be as good as keeping it
          square to the target line, right?
        </div>
        <div class="p-mb-2">Actually, I believe that's wrong.</div>
        <div class="p-mb-2">
          If you're standing to the side of the ball to hit a putt, to make the
          putter go straight back and straight through along the target line,
          you have to use the small muscles in your hands to close the putter
          face on the backswing, and then open it again on the forward swing, in
          relation to your body. That's the opposite of what you do for anything
          else in this game, from a driver swing to a short pitch, and it's also
          not the best way to do something consistently, time after time,
          without a lot of practice.
        </div>
      </div>
    </Panel>
    <Panel header="The Putting Stroke" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          Letting the putterface move in a path square to the arc is what will
          make the ball go where you're aiming, with a bigger margin for error
          and less need for practice. That's why Brad Faxon can walk onto a
          practice green, drop a ball and immediately start rolling it great.
          He's working with physics and the mechanics of his body, not against
          them. When I give a putting lesson to somebody, the first thing I
          usually hear is, “Hey, this is the same thing my full-swing teacher is
          telling me about the rest of my game.” It all comes from the same
          fundamentals. As I said earlier, I don't believe the putting stroke
          should work any differently than the swing you'd use for your other
          clubs. It's just smaller.
        </div>
        <div class="p-mb-2">
          To get the putter following that nice path, you need to feel the way
          the shoulders and arms work in the putting stroke. You're trying to
          get away from rocking the shoulders up and down and toward turning
          them around your spine, like they do in a full swing. In other words,
          if you're a right-hander, it should feel like your left shoulder is
          moving around toward your chin on the backswing, not down toward your
          left foot.
        </div>
        <div class="p-mb-2">
          The stroke itself is simple: You always want to start the putterhead
          back first, because the toe of the putter has the farthest to swing on
          the arc. To make your stroke with a relaxed arm swing, you want to
          feel your left arm push the putterhead away while allowing the right
          elbow to soften and fold close to your side. The through swing is
          generated mostly by lengthening your right arm through impact, in
          order to have your shaft end up even with or slightly leaning ahead at
          impact. Your left elbow must respond to this by softening and folding
          along your left side. If your tendency has always been to work the
          face square to square, you will need to feel your forearms rotate
          during the stroke so the toe end of your putter leads the heel in the
          backswing and then releases through on the arc and leads the toe again
          past impact. Remember when we talked about keeping the arms and
          shoulders soft and tension-free? This is where that softness comes
          into play.
        </div>
        <div class="p-mb-2">
          Most players have too much tension in the shoulder and elbow joints
          when they putt—sometimes to the point of total lockdown. Even if you
          know what you're supposed to do with the putting stroke, it's
          impossible to actually do it when you've got that kind of tension. In
          a lot of the lessons I give, the first thing I start with is tapping
          the player on the shoulders with the grip of my putter to get him or
          her to “let the air out” and relax. You'll see a person's shoulders
          drop three or four inches after that.
        </div>
        <div class="p-mb-2">
          When you have that kind of tension in your shoulders and arms, the
          only way to hit the ball is to use a lot of body movement and release
          the putter in a flip move with the hands. That causes a tremendous
          amount of inconsistency—basically, you're making a different stroke
          every time you hit a putt. It's probably not a surprise to hear that
          inconsistency like that translates into poor speed control and missed
          putts. You can guess what happens next: The missed putts build
          anxiety, which creates more tension in the shoulders and arms. It's a
          vicious cycle. From there, it doesn't take long to get completely
          locked up over putts, especially those important five-footers. You're
          standing over them all tense, with no real idea of what you're
          supposed to do. There's no feel in that stroke, and no flow.
        </div>
        <div class="p-mb-2">
          Other players might have that tension in their putting stroke because
          of their personality. If you're a precise, detail-oriented person, you
          might think that locking down the muscles in your chest, shoulders and
          arms gives you more precision and control in the stroke. Actually, the
          opposite is true. You need the softness to make it happen. Loose,
          relaxed muscles in the chest, shoulders and arms will let you respond
          to the weight of the putterhead moving, and you'll quickly notice how
          naturally this folding and unfolding takes place. This relaxed,
          flowing stroke is the best way to hit consistent, accurate putts.
        </div>
        <div class="p-mb-2">
          If you have trouble visualizing what I mean by soft, responsive arms,
          think about tossing a ball underhanded toward a target on the floor
          five feet in front of you. If you stiffened your arm and tried to toss
          the ball just using your shoulder, you wouldn't get much power on the
          toss and you certainly wouldn't have much accuracy. I really like the
          underhanded toss analogy, because it works for the grip as well as for
          the feeling of softness in the stroke. If you were going to toss that
          ball, you would hold it in your fingertips, because that's where your
          feel is. You wouldn't hold it in your open palm, or grab it in a
          tight, hand-dominated grip like you would something you were trying
          not to drop. Your putting grip is the same way. With the underhanded
          toss, your fingertips are helping your brain automatically judge how
          much energy to put into the job of getting the ball to move, without
          too much conscious interference from you. If you have a good grip and
          keep that softness in your arms and shoulders, you're doing the same
          thing—letting your brain's powerful natural instincts take over.
        </div>
        <div class="p-mb-2">
          Once you relax and make the stroke I've been describing, you'll
          definitely notice that the putterhead looks like it's opening and
          closing—not staying square to the target line back and through. That's
          great, because I believe that's the best path for a putter to travel
          if you're looking for consistency and a feel for distance. Check out
          the sequence pictures of my putting stroke in the color insert section
          and see for yourself. Again, that comes from turning around your spine
          tilt, on plane, and from the blending of forearm rotation in the
          backswing and follow-through, not from your hands flipping or turning
          the grip end like a doorknob.
        </div>
        <div class="p-mb-2">
          Explaining how the forearms work may give you more of an idea of what
          I'm talking about. At address the two bones in your forearms are
          stacked on top of each other. The top bone is the called the humerus,
          and the bottom one is called the ulna. In the backswing, make sure
          you've got the top bones in your forearms—the humeruses, for all you
          orthopedic surgeons out there—leading “the way both back and through.
          If you rock the shoulders instead of turning them, or close the
          putterface going back, you'll immediately see the bottom bones in the
          forearms, the ulnas, leading. Again, the stroke is a combination of
          shoulders turning, arms swinging and forearms rotating—not wrist
          action—so maintain the angle you had in your right wrist at address
          and don't let the left wrist break down toward the target through
          impact. If you haven't picked up a putter and tried the stroke I teach
          yet, that might sound like a difficult thing to do. Your impulse might
          be to get the hands and wrists involved in the stroke—especially at
          impact—to help generate enough power to get the ball to the hole. It
          doesn't work that way. Trust me. Forearm rotation and a little arm
          swing will give you all the power you need. In fact, when you try my
          method, you'll probably smack the first few putts quite a bit past
          your target. That's okay. It just means that you can now take a
          smaller, smoother and more controlled stroke on every putt. You'll
          quickly get the feel and be able to recalibrate your distance.
        </div>
        <div class="p-mb-2">
          To get the ball to roll nicely, you need to move the putterhead end of
          the club the most in your stroke, not the grip end. The energy in your
          stroke needs to be primarily in the putterhead through impact—for
          consistency and efficiency. To start the stroke, the shoulders turn
          slightly, the arms have a little swing to them, the grip end stays
          relatively quiet, and the putterhead swings away long enough to create
          the energy to roll the putt the proper distance. Problems start to
          creep into the stroke when you turn the shoulders too much, or jerk
          the grip end of the putter back. You're taking away energy from the
          end of the club that will be doing all the work, and this will destroy
          your consistency. If you do move the grip end too much and too early,
          you have to speed up the clubhead end of the putter down through
          impact to compensate. The only way to generate that speed at that
          point in the stroke is to do it with your wrists and hands. The left
          wrist breaks down, the hands flip toward the target and it gets really
          hard to make a solid, consistent strike on the ball. You've probably
          felt those kinds of misses in your hands—a thin, clacking kind of
          impact toward the bottom of the putterface, which often leads to putts
          ending up short of the cup.
        </div>
        <div class="p-mb-2">
          By far the hardest habit to break is that of tilting the shoulders up
          and down during the stroke instead of letting them turn around the
          spine slightly, like they're supposed to. It's one of the fundamental
          differences between making a stroke that's square to the target line
          (the stroke that teachers like Dave Pelz endorse) and one that's
          square to the arc, as I prefer. Don't get frustrated if you struggle
          with that early on in this process, using a mix of your old stroke and
          the new one we're working on. A physical therapist explained to me
          that our bodies fall into a physical pattern when we perform a certain
          movement time after time—like putting the wrong way—and it's hard to
          break that pattern. My job is to help you do that demolition work—to
          build a set of positive, solid fundamentals to replace the old habits.
          Some players I work with can change their pattern in twenty minutes or
          so. It might take you a day or a week, but it'll certainly happen. We
          can all create the change given the right information and a little
          encouragement.
        </div>
      </div>
    </Panel>
    <Panel
      header="Player Examples: Mechanics and Attitude"
      :toggleable="true"
      :collapsed="true"
    >
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          We've covered mechanics and stroke pretty thoroughly, but I think the
          “art” of putting covers something more than just physical stuff.
          People ask me all the time, “What do good putters do, and what do they
          think?” Sure, the technical aspect of putting is part of being great—I
          can't think of an all-time great with terrible mechanics, or a stroke
          that wasn't repeatable. But good putters also have this attitude about
          what they do. They have confidence that they're good, and that they're
          going to make the next putt, even if the last one did a 180-degree
          horseshoe and stayed out. We're going to talk more about that in
          Chapters 6 and 7. Here, I want to talk about some of the great putters
          I've seen, both from a mechanical and confidence perspective.
        </div>
        <div class="p-mb-2">
          If I were making a list of the best putters of all time, the guys on
          it would all have good technique, but they certainly wouldn't have
          identical strokes. Jack Nicklaus was the absolute best—nobody made
          more putts with big tournaments on the line. Did he look relaxed and
          in the flow like a Ben Crenshaw or a Brad Faxon? No—he got into that
          familiar crouch, kept everything still and basically pistoned his
          right elbow to make the stroke. He had a very repeatable stroke, even
          if it had some unique things about it. More than anything, Jack had an
          incredible intensity about his putting. He was tremendously focused,
          and spent a lot of time analyzing his line. That might not be the best
          method for some guys, but it fit with the way he played the game. And
          he made a lot of putts. There's a generation of players from about
          1965 to 1980 that would give Jack the nod if it ever came down to
          picking a guy to make a putt to save your life. He'd put a good stroke
          on it, and you know the pressure wouldn't get to him.
        </div>
        <div class="p-mb-2">
          I heard Jack say recently that he never missed a short putt he needed
          to win a tournament. It's just a given in a long tournament career
          like his that he missed some here and there. As good as he was, nobody
          is perfect. But he really believes that he didn't miss any big ones,
          and I think that's because he was so strong mentally that he has
          blocked out those negative memories. It was one more thing that helped
          him to be completely focused on the putt he had in front of him—not on
          something that happened yesterday or last week.
        </div>
        <div class="p-mb-2">
          Guys like Crenshaw and Faxon are at the other end of the spectrum,
          both in terms of stroke and intensity level. Both guys are really
          artists—lots of graceful flow and pure athletic ability in the stroke.
          They have such good strokes that it looks like the ball can't help but
          go in, and they also have the confidence to trust their strokes and
          let it go. I really learned a lot by watching both of those guys—not
          so much from their technique, but from their approach. Ben Crenshaw
          has mastered getting out of the way of his athletic ability and
          trusting his reads. Faxon has so much confidence in his stroke that he
          almost doesn't have to practice. Watching either of them is like
          watching a really talented musician play up on stage.
        </div>
        <div class="p-mb-2">
          Tiger Woods falls somewhere in the middle—he has what I call a very
          efficient stroke, with great fundamentals, but with the intensity and
          focus Jack had. If you look at the guys who are considered good
          putters on tour now, Tiger is the guy who has the skill, but also
          backs it up by doing it consistently under pressure. Tiger's mechanics
          might not look as graceful as Faxon's, but I wouldn't put Brad or
          myself on the line under pressure instead of Tiger. He stands over
          big-time Augusta ten-footers with a foot of break as though he's
          playing a practice round at Isleworth. Of course, it's a bit of a
          self-fulfilling prophecy—Tiger has lots more chances to hit pressure
          putts because he hits it and putts it so well in the first place. All
          of that big-moment experience gives him such an advantage compared to
          a lot of guys on tour. They're standing over five-footers thinking
          about what it would be like to win a major for the first time. He's
          standing over the same putt thinking about nothing but making it. And
          if he doesn't make that one, he's completely convinced he'll make the
          next one. That's a rare, rare skill.
        </div>
        <div class="p-mb-2">
          It's impossible to overestimate how important attitude is to putting.
          I know for a fact that I miss putts even when I'm making a great
          stroke if my attitude is bad. If I stand over the ball resigned to
          “the fact that I'm probably not going to make it, I won't unless it
          happens by accident. It's incredible how much of an impact your
          mindset can have. Even when I have a day where it's hard to see the
          line, or I just don't feel right, I can stand over the putt and trust
          my stroke. My goal here is to give you the foundation of
          putting-stroke repeatability. That's really the secret to building
          confidence—feeling like you're doing something on purpose with your
          putting stroke. Learning the mechanics and stroke in this book will
          help you feel like you can do the same thing with your putting
          stroke—and have a reasonable idea where the ball is going to go—time
          after time. Once that happens, you'll start to experience something
          kind of cool. Instead of getting discouraged, you'll be mad about
          missing putts you should have made. That's part of the attitude I'm
          talking about. Guys like Brad Faxon really stand over putts thinking
          they're going to make every one, and are honestly surprised when they
          miss, even though they totally understand the reality that they won't
          all go in. You might not quite get to that point — after all, Brad's
          about as good as you can get with the putter — but it's a great goal
          to shoot for.
        </div>
      </div>
    </Panel>
  </Panel>
</template>
