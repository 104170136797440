<template>
  <div class="p-d-flex-column">
    <Panel
      header="Techniqe  (Hardy: The Release: Golfs Moment of Truth)"
      :toggleable="true"
      :collapsed="true"
    >
      <Panel
        header="RIT  ARMS: Downswing, Release, Follow-through"
        :toggleable="true"
        :collapsed="true"
      >
        <hardy2RITdownswing />
      </Panel>
      <Panel
        header="RIT  BODY MOTOR: Downswing, Release, Follow-through"
        :toggleable="true"
        :collapsed="true"
      >
        <ritBodyRelease />
      </Panel>
      <Panel
        header="Swing Variability and Hybrid Swings"
        :toggleable="true"
        :collapsed="true"
      >
        <swingVariability />
      </Panel>
    </Panel>
    <Panel
      header="Incorrect RIT Releases: Arms/Handle Mistakes and Shaft/CLubhead Mistakes"
      :toggleable="true"
      :collapsed="true"
    >
      <Panel header="Introduction" :toggleable="true" :collapsed="true"
        ><mistakesIntroduction
      /></Panel>
      <Panel header="Wrist Throw Mistakes" :toggleable="true" :collapsed="true">
        <mistakesWristThrow />
      </Panel>
      <Panel
        header="Shaft/Clubhead Mistake: In-To_Out"
        :toggleable="true"
        :collapsed="true"
        ><mistakesSCinToOut
      /></Panel>
      <Panel
        header="Arms/Handle and Shaft/Clubhead Mistake: In-To_Out"
        :toggleable="true"
        :collapsed="true"
        ><mistakesAHandSCinToOut
      /></Panel>
      <Panel
        header="Shaft/Clubhead Mistake: Out-To-In"
        :toggleable="true"
        :collapsed="true"
        ><mistakesSCoutToIn
      /></Panel>
      <Panel
        header="Arms/Handle Mistake: Clubhead on Plane, Handle Above"
        :toggleable="true"
        :collapsed="true"
        ><mistakesAHcOnPlaneHabove
      /></Panel>
    </Panel>
  </div>
</template>

<script>
import hardy2RITdownswing from "@/components/hardyRelease/hardy2RITdownswing";
import ritBodyRelease from "@/components/hardyRelease/ritBodyRelease";
import swingVariability from "@/components/hardyRelease/swingVariability";
import mistakesWristThrow from "@/components/hardyRelease/mistakesWristThrow";
import mistakesIntroduction from "@/components/hardyRelease/mistakesIntroduction";
import mistakesSCinToOut from "@/components/hardyRelease/mistakesSCinToOut";
import mistakesAHandSCinToOut from "@/components/hardyRelease/mistakesAHandSCinToOut";
import mistakesSCoutToIn from "@/components/hardyRelease/mistakesSCoutToIn";
import mistakesAHcOnPlaneHabove from "@/components/hardyRelease/mistakesAHcOnPlaneHabove";
export default {
  name: "hardyRelease",
  components: {
    hardy2RITdownswing,
    ritBodyRelease,
    swingVariability,
    mistakesWristThrow,
    mistakesIntroduction,
    mistakesSCinToOut,
    mistakesAHandSCinToOut,
    mistakesSCoutToIn,
    mistakesAHcOnPlaneHabove,
  },
};
</script>
<style lang="scss">
// GENERAL STYLES
.accent1 {
  font-weight: bold;
}
.accent2 {
  color: red;
  font-weight: bold;
}
.accent3 {
  color: red;
}
.fontSmall {
  font-size: small;
}
.fontSmaller {
  font-size: smaller;
}
.fontMedium {
  font-size: medium;
}
.fontLarge {
  font-size: large;
}
.taLeft {
  text-align: left;
  padding: 0 0.25em 0 0.25em;
}
</style>
<span class="accent1"></span>
<img src="@/assets/images/hardy1/hardyFinish1.png" alt="Hardy Posture" />
<div class="accent1 fontSmall p-mb-2"></div>
