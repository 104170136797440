<template>
  <h1>MENTAL GAME</h1>
  <Panel :toggleable="true" header="Image: Mental Game">
    <div class="p-col-12 ta-center">
      <div class="p-grid">
        <div>
          <img
            src="../assets/images/mental/mentalSkills.png"
            alt="DuringRound"
            class="img9"
          />
        </div>
      </div>
    </div>
  </Panel>
  <div class="p-d-flex-column">
    <Panel
      header="Mental Skills important in Inter-Shot Routine"
      :toggleable="true"
      :collapsed="true"
    >
      <Panel header="Image: Inter-Shot Routine" :toggleable="true">
        <div>
          <img
            src="../assets/images/mental/interShotRoutine1.png"
            alt="DuringRound"
            class="img9"
          />
        </div>
      </Panel>
      <Panel
        header="Image: Emotion Brain Overlay"
        :toggleable="true"
        :collapsed="true"
      >
        <div>
          <img
            src="../assets/images/mental/brainEmotion1.png"
            alt="DuringRound"
            class="img9"
          />
        </div>
      </Panel>

      <Panel
        header="Peak Performance: (Pia: my54/not54)"
        :toggleable="true"
        :collapsed="true"
      >
        <ThoughtChecking />
        <Panel
          header="Recognize NOT Peak Performance"
          :toggleable="true"
          :collapsed="true"
        >
          <Panel
            header="Recognize cognitive Symptoms"
            :toggleable="true"
            :collapsed="true"
          >
            <Panel
              header="Anxiety and Hyper Arousal"
              :toggleable="true"
              :collapsed="true"
            >
              <div class="fontSmall taLeft">
                <div class="p-mb-2">
                  Emotion-generated thoughts of hyper-arousal: Rushed, can’t
                  think clearly, out-of-control, not wanting to be here, dread,
                  fear, or excitement, anticipation.
                </div>
                <div class="p-mb-2">
                  Apprehension /excitement of impending success: Fear of losing
                  advantage (conservative thoughts), tremor in hands, agitation,
                  excitement.
                </div>
              </div>
            </Panel>
            <Panel
              header="Not allowed Thoughts (self talk, abstract thinking)"
              :toggleable="true"
              :collapsed="true"
            >
              <abstractThoughtNotAllowed />
            </Panel>

            <Panel header="Low Confidence" :toggleable="true" :collapsed="true">
              <div class="fontSmall taLeft">
                <div class="p-mb-2">
                  Faulty-thinking of low personal confidence: All-or-nothing,
                  magnification, defensiveness, mind-reading, magical thinking.
                </div>
              </div>
            </Panel>
          </Panel>
          <Panel
            header="Recognize Somatic Signs"
            :toggleable="true"
            :collapsed="true"
          >
            <anxiousRecognizeSomatic />
          </Panel>
        </Panel>
        <PeakPerformanceTraining />
      </Panel>
      <Panel header="Abstract Thought" :toggleable="true" :collapsed="true">
        <SelfTalk />
        <SelfTalkTraining />
      </Panel>
      <Panel header="Emotions" :toggleable="true" :collapsed="true">
        <Panel
          header="Emotions:  -Resilience (Nielsson)"
          :toggleable="true"
          :collapsed="true"
        >
          <EmotionalResilience />
        </Panel>
        <EmotionalResilienceTraining />
      </Panel>
    </Panel>
    <Panel
      header="Mental skills important in Shot Routine"
      :toggleable="true"
      :collapsed="true"
    >
      <Panel header="Image: Shot Routine" :toggleable="true">
        <div>
          <img
            src="../assets/images/mental/shotRoutine1.png"
            alt="DuringRound"
            class="img9"
          />
        </div>
      </Panel>
      <Panel
        header="See It: Think Box (i) Control Arousal (ii) Decide and Commit (Pia)"
        :toggleable="true"
        :collapsed="true"
      >
        <div>
          <img
            src="@/assets/images/playingSkills/seeIt.png"
            alt="playing"
            class="img7"
          />
          <div class="accent1 fontSmall p-mb-2">
            See It: Think Box: (i)Control Arousal, (ii) Decide and Commit (focus
            thoughts)
          </div>
        </div>
        <Panel
          header="See It: Decide and Commit: Pia"
          :toggleable="true"
          :collapsed="true"
        >
          <SeeIt />
        </Panel>
        <SeeItTraining />
      </Panel>
      <Panel
        header="Feel It: Balance Tempo Tension Awareness"
        :toggleable="true"
        :collapsed="true"
      >
        <div>
          <img
            src="@/assets/images/playingSkills/feelIt.png"
            alt="playing"
            class="img7"
          />
          <div class="accent1 fontSmall p-mb-2">Feel It</div>
        </div>
        <BalanceTempoTension />
        <BTTtraining />
      </Panel>
      <Panel
        header="Trust It: 'play box' Sensory Awareness"
        :toggleable="true"
        :collapsed="true"
      >
        <div>
          <img
            src="@/assets/images/playingSkills/trustIt.png"
            alt="playing"
            class="img7"
          />
          <div class="accent1 fontSmall p-mb-2">Trust It; Play Box</div>
        </div>
        <TrustIt />
        <TrustItTraining />
      </Panel>
      <Panel
        header="Enjoy/analyze, Remember"
        :toggleable="true"
        :collapsed="true"
      >
        <div>
          <img
            src="@/assets/images/playingSkills/enjoyAnalyze.png"
            alt="playing"
            class="img7"
          />
          <div class="accent1 fontSmall p-mb-2">
            Enjoy or Analyze and Commit to Memory
          </div>
        </div>
        <Panel
          header="Memory Control (Nielsson)"
          :toggleable="true"
          :collapsed="true"
        >
          <MemoryControl />
        </Panel>
        <EnjoyAnalyzeRememberTraining />
      </Panel>
      <Panel header="Let It Go" :toggleable="true" :collapsed="true">
        <div>
          <img
            src="@/assets/images/playingSkills/letItGo.png"
            alt="playing"
            class="img7"
          />
          <div class="accent1 fontSmall p-mb-2">Let It Go</div>
        </div>
      </Panel>
    </Panel>

    <Panel
      header="Techniques to facilitate Peak Performance and Reverse Problems"
      :toggleable="true"
      :collapsed="true"
    >
      <Panel header="Image: Inter-Shot Routine" :toggleable="true">
        <div>
          <img
            src="../assets/images/mental/techniques1.png"
            alt="DuringRound"
            class="img9"
          />
        </div>
      </Panel>
      <Panel header="Cognitive Techniques" :toggleable="true" :collapsed="true">
        <ManageBadThoughts />
        <FocusedExternalAwareness />
        <GuidedImagery />
        <Rethinking />
        <Panel header="Focused (Graham)" :toggleable="true" :collapsed="true">
          <focused />
        </Panel>
      </Panel>
      <Panel header="Somatic Techniques" :toggleable="true" :collapsed="true">
        <anxiousSomatic />
      </Panel>
      <Panel
        header="'External' Techniques"
        :toggleable="true"
        :collapsed="true"
      >
        <social />
        <environment />
        <nutrition />
        <hydration />
      </Panel>
    </Panel>

    <Panel
      header="External Factors Affecting Mental State"
      :toggleable="true"
      :collapsed="true"
    >
      <InterPersonal />
    </Panel>
    <Panel
      header="Internal Factors Affecting Mental State"
      :toggleable="true"
      :collapsed="true"
    >
      <IntraPersonal />
    </Panel>
  </div>
</template>

<script>
import focused from "@/components/mental/focused";
import PeakPerformanceTraining from "@/components/trainingSkills/thoughtChecking";

import SeeIt from "@/components/playingSkills/seeIt.vue";
import SeeItTraining from "@/components/trainingSkills/seeIt.vue";
import BalanceTempoTension from "@/components/piaBalanceTempoTension/balanceTempoTension";
import BTTtraining from "@/components/trainingSkills/balanceTempoTension";
import TrustIt from "@/components/playingSkills/trustIt.vue";
import TrustItTraining from "@/components/trainingSkills/trustIt.vue";

import abstractThoughtNotAllowed from "@/components/mental/abstractThoughtNotAllowed";
import anxiousRecognizeSomatic from "@/components/mental/anxiousRecognizeSomatic";

import FocusedExternalAwareness from "@/components/mental/focusedExternalAwareness";
import GuidedImagery from "@/components/mental/guidedImagery";

import anxiousSomatic from "@/components/mental/anxiousSomatic";
import nutrition from "@/components/mental/nutrition";
import hydration from "@/components/mental/hydration";
import social from "@/components/mental/social";
import environment from "@/components/mental/environment";

import ManageBadThoughts from "@/components/mental/manageBadThoughts";
import SelfTalk from "@/components/mental/selfTalk";
import SelfTalkTraining from "@/components/trainingSkills/selfTalk";
import ThoughtChecking from "@/components/mental/thoughtChecking";
import Rethinking from "@/components/mental/reThinking";

import EmotionalResilience from "@/components/mental/emotionalResilience";
import EmotionalResilienceTraining from "@/components/trainingSkills/enjoy";
import MemoryControl from "@/components/mental/memoryControl.vue";
import EnjoyAnalyzeRememberTraining from "@/components/trainingSkills/remember";
import IntraPersonal from "@/views/IntraPersonal";
import InterPersonal from "@/views/InterPersonal";

export default {
  name: "Mental",
  components: {
    focused,
    PeakPerformanceTraining,
    SeeIt,
    SeeItTraining,
    BalanceTempoTension,
    BTTtraining,
    TrustIt,
    TrustItTraining,
    MemoryControl,
    EnjoyAnalyzeRememberTraining,

    abstractThoughtNotAllowed,
    anxiousRecognizeSomatic,

    FocusedExternalAwareness,
    GuidedImagery,
    anxiousSomatic,
    nutrition,
    hydration,
    social,
    environment,
    ManageBadThoughts,
    SelfTalk,
    SelfTalkTraining,
    ThoughtChecking,
    Rethinking,

    EmotionalResilience,
    EmotionalResilienceTraining,

    IntraPersonal,
    InterPersonal,
  },
};
</script>

<style lang="scss">
@import "../assets/styles/images.scss";
.green {
  background-color: green;
}
.red {
  background-color: red;
}
</style>
