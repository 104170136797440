<template>
  <div class="p-grid nested-grid main-container">
    <!-- ********** ONE COLUMN ALL SCREENS -->
    <!-- <div class="p-col-12"> -->
    <div class="p-col-12 ta-center">
      <h1>Approach Overview</h1>
    </div>
    <!-- Multi Picture -->
    <Panel :toggleable="true">
      <template #header>
        <router-link :to="{ name: 'Spirit' }">Spirit of the game</router-link>
      </template>
      <div class="p-col-12 ta-center">
        <div class="p-grid">
          <div>
            <img
              src="../assets/images/home/everything1.png"
              alt="EveryThing"
              class="img10"
            />
          </div>
        </div>
      </div>
      <div class="center">
        <router-link :to="{ name: 'Playing' }">Playing</router-link
        >&nbsp;&nbsp;&nbsp;&nbsp;
        <router-link :to="{ name: 'Mental' }">Mental Game</router-link
        >&nbsp;&nbsp;&nbsp;&nbsp;
        <router-link :to="{ name: 'Technical' }">Technical Game</router-link
        >&nbsp;&nbsp;&nbsp;&nbsp;
        <router-link :to="{ name: 'Training' }">Training</router-link>
      </div>
    </Panel>
  </div>
  <br />
  <Panel
    header="Pia's Vision: Play Your Best Golf Now"
    :toggleable="true"
    :collapsed="true"
  >
    <PiaVision />
    <PiaConclusion />
  </Panel>
  <Panel
    header="*** NotPlayer - current ***"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        <div v-html="NotPlayer"></div>
      </div>
    </div>
  </Panel>
</template>

<script>
import { onMounted, computed } from "vue";
import store from "../store";
import toggleElement from "@/modules/ToggleElement.js";
import PiaVision from "@/components/home/piaVision.vue";
import PiaConclusion from "@/components/home/piaConclusion.vue";
export default {
  name: "home",
  components: {
    PiaVision,
    PiaConclusion,
  },
  setup() {
    const NotPlayer = computed(() => {
      return store.state.note.NotPlayer.text_content;
    });
    const FullSwingTrustIt = computed(() => {
      return store.state.note.FullSwingTrustIt.text_content;
    });
    const PartialTrustIt = computed(() => {
      return store.state.note.PartialTrustIt.text_content;
    });
    const PitchChipTrustIt = computed(() => {
      return store.state.note.PitchChipTrustIt.text_content;
    });
    const BunkerTrustIt = computed(() => {
      return store.state.note.BunkerTrustIt.text_content;
    });
    const PuttingTrustIt = computed(() => {
      return store.state.note.PuttingTrustIt.text_content;
    });
    const FullSwingFeelIt = computed(() => {
      return store.state.note.FullSwingFeelIt.text_content;
    });
    const PartialFeelIt = computed(() => {
      return store.state.note.PartialFeelIt.text_content;
    });
    const PitchChipFeelIt = computed(() => {
      return store.state.note.PitchChipFeelIt.text_content;
    });
    const BunkerFeelIt = computed(() => {
      return store.state.note.BunkerFeelIt.text_content;
    });
    const PuttingFeelIt = computed(() => {
      return store.state.note.PuttingFeelIt.text_content;
    });
    const AbnStanceFeelIt = computed(() => {
      return store.state.note.AbnStanceFeelIt.text_content;
    });
    const AbnLieFeelIt = computed(() => {
      return store.state.note.AbnLieFeelIt.text_content;
    });

    async function getLatestNote(text_code) {
      try {
        await store.dispatch("note/getLatestNote", text_code);
      } catch (error) {
        console.log(`Error: Home.vue getLatestNote(): ${error}`);
      }
    }
    // getLatestNote;
    onMounted(() => {
      getLatestNote("NotPlayer");
      getLatestNote("FullSwingTrustIt");
      getLatestNote("PartialTrustIt");
      getLatestNote("PitchChipTrustIt");
      getLatestNote("BunkerTrustIt");
      getLatestNote("PuttingTrustIt");
      getLatestNote("FullSwingFeelIt");
      getLatestNote("PartialFeelIt");
      getLatestNote("PitchChipFeelIt");
      getLatestNote("BunkerFeelIt");
      getLatestNote("PuttingFeelIt");
      getLatestNote("AbnStanceFeelIt");
      getLatestNote("AbnLieFeelIt");
    });
    return {
      toggleElement,
      getLatestNote,
      NotPlayer,
      FullSwingTrustIt,
      PartialTrustIt,
      PitchChipTrustIt,
      BunkerTrustIt,
      PuttingTrustIt,
      FullSwingFeelIt,
      PartialFeelIt,
      PitchChipFeelIt,
      BunkerFeelIt,
      PuttingFeelIt,
      AbnStanceFeelIt,
      AbnLieFeelIt,
    };
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  max-width: 700px;
  margin: 0 auto 0 auto;
  font-size: small;
  text-align: left;
  // background-color: yellow;
}
.bigPicture {
  border: solid 1px lightgrey;
}
fieldset {
  border: solid 2px lightgrey;
}
.center {
  text-align: center;
}
// GENERAL STYLES
.bold {
  font-weight: bold;
}
.fontSmall {
  font-size: small;
}
.fontMedium {
  font-size: medium;
}
.fontLarge {
  font-size: large;
}
.textAlignLeft {
  text-align: left;
  padding: 0 0.25em 0 0.25em;
}
.ta-center {
  text-align: center;
}
.borderRight {
  border-right: solid 1px lightGrey;
}
.red {
  color: red;
}
.noBullets {
  list-style-type: none;
}
</style>
