<template>
  <Panel header="Introduction" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Self-sufficiency Self-Sufficient: is how contained your needs are. Low
        scorers are group-oriented and affiliative. High scorers are
        self-reliant, solitary, resourceful, individualistic and
        self-sufficient.
      </div>
      <div class="p-mb-2">
        Part of golf's allure is the endless and varied decisions that must be
        made as a player continually assesses and strategizes. To many, this can
        be mentally stimulating, but for others those who find it difficult to
        make decisions-golf can be mentally exhausting. Ironically, the greater
        the player's intellect and understanding of the game, the more
        overwhelming the decision-making can be.
      </div>
      <div class="p-mb-2">
        Psychologists have determined that self-sufficiency is largely learned
        through life experiences, both as a child and as an adult. So while
        genetics plays a small role, you can increase your self-sufficiency by
        initiating steps for change.
      </div>
      <div class="p-mb-2">
        Many of the circumstances that determine our self sufficiency are rooted
        in childhood. Experts in personality have found that most people with
        low self-sufficiency, also referred to as "group orientation," had
        parents who either indulged and spoiled them; who did not encourage and
        reward their independence; or who were unable to provide their basic
        dependency needs, making them more "needy" than normal. Those with
        extremely high self-sufficiency were found to have felt secure in their
        early home environments; they were not overly protected; and had parents
        who encouraged healthy initiative. Players who are extreme in this trait
        usually found some type of security in their early home environments
        where decision making was encouraged. For example, they likely felt
        loved and protected by their parents (or parent), but were given
        considerable opportunity to exercise free will-picking clothes,
        selecting friends, deciding on what food to eat, and such.
      </div>
      <div class="p-mb-2">
        Decisiveness in The Champion Golfer You do not need to be above average
        in self-sufficiency to enjoy golf socially, but the more competitive you
        are, the more important your decision-making skills become.
      </div>
      <div class="p-mb-2">
        The champions are above average, though seldom extreme, in
        self-sufficiency. They are very good at making many difficult decisions,
        even under pressure. They not only like making their own decisions, they
        prefer it. Yet, they do leave themselves somewhat open to suggestions or
        help.
      </div>
      <div class="p-mb-2">
        For some of them, self-sufficiency and decision-making come quite
        easily, while for others they may take considerable effort. There also
        is a smaller percentage of players who have relatively too much
        self-sufficiency for golf, meaning it is unlikely they will seek advice
        or instruction from others. Too much or too little can compromise
        competitive skills. And both appear to be influenced by our past and
        present circumstances.
      </div>
      <div class="p-mb-2">
        Making and committing to decisions (decisiveness) is at least as
        important in some ways as improving your swing. In some ways, it is even
        more important since it can influence your preparation. More
        specifically, above average self-sufficiency enables the champion to
        make decisions and commit to the following items:
      </div>
    </div>
  </Panel>
  <Panel
    header="Decisiveness in shot routine"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        During ‘see it’, after consulting game plan (dominance) and analyzing
        all the factors necessary to decide what shot to hit, you need to be
        decisive. You need to decide on a shot that your intellect and your gut
        tells you is right and that is within your capabilities and that you
        fully commit to and trust.
      </div>
      <div class="p-mb-2">
        Even though they do not always make the correct decisions, champion
        golfers know the importance of making a decision before executing a
        shot. Sometimes it is difficult to feel decisive, such as when playing
        in strong gusty winds. But the best players strive to commit themselves
        to at least 90 percent of their shots no matter what the conditions.
        This is not to say that the best players do not question themselves and
        their choices, because they do. Rather, they are adamant about not
        hitting shots before they fully commit to some choice. As we have said
        before, and as we often remind our players, you will get more out of
        your game by committing to wrong decisions than you will by hitting
        shots when you aren't committed to the right decisions.
      </div>
    </div>
  </Panel>
  <Panel
    header="Decisiveness in other areas"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Fundamentals; A highly self-sufficient player chooses and commits to
        techniques that are best suited to his game. While he is open to
        suggestions from qualified teachers, he is not easily influenced to take
        the advice of everyone who offers it.
      </div>
      <div class="p-mb-2">
        Equipment: He remains true to his equipment but also stays on top of
        technological changes. He resists vacillation, and when put in a
        position to make a decision about his equipment, he does so without
        being heavily swayed by others.
      </div>
      <div class="p-mb-2">
        Course management: He determines a plan for managing the course and
        remains committed to it, regardless of how others choose to play it.
        He's adaptable but decisive in handling necessary changes that he
        decides to make.
      </div>
      <div class="p-mb-2">
        Clubs, targets, and types of shots: He strives to be as fully committed
        as possible to clubs, targets, and types of shots before striking the
        ball and resists being strongly influenced by the choices of others.
      </div>
      <div class="p-mb-2">
        Practice: He independently determines a purpose for his practice and
        organizes it to meet his goal for the session, the day, the week, or the
        year.
      </div>
    </div>
  </Panel>
  <Panel
    header="Process Techniques to improve Decisiveness"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Once you start to address the problems that mimic low self-sufficiency,
        you will have a better idea of how self-sufficient you really are. And
        you will be ready to implement the following techniques to help you
        emulate a champion's self-sufficiency.
      </div>
      <div class="p-mb-2">
        Practice making your own decisions on and off the course. When faced
        with choices, large or small, assess all available information and
        options before seeking assistance. You'll find that you will become more
        capable of making decisions.
      </div>
      <div class="p-mb-2">
        Organize your practice sessions. Identify goals to meet during practice
        and identify the steps you must take to reach them.
      </div>
      <div class="p-mb-2">
        “Journal” your golf. Many golfers have improved their decision-making
        skills by incorporating the simple task of keeping an account of their
        practice and play. This involves taking a few moments after each round
        to chronicle your current mental strengths and weaknesses and your
        current physical strengths and weaknesses. You can use a notebook,
        diary, personal planner, or computer to do this.
      </div>
      <div class="p-mb-2">
        Strengthening your physical weaknesses becomes your goal for practice.
        Strengthening your mental weaknesses becomes your goal for play.
      </div>
      <div class="p-mb-2">
        In addition to writing down your strengths and weaknesses, jot down your
        goals and make a few notes on how you can best accomplish them, such as
        choosing drills, devices, practice games, or lessons to address the
        physical weaknesses or, to address the mental ones, choosing definite
        targets, visualizing shots, and improving tempo.
      </div>
    </div>
  </Panel>
  <Panel
    header="Managing What Harms Decisiveness"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        It is not unusual for self-sufficiency to fluctuate, since weaknesses in
        other areas can reduce your ability to make decisions. Here are some of
        the many other factors that can reduce your decision-making skills along
        with descriptions and suggestions for strengthening each.
      </div>
      <div class="p-mb-2">
        Low personal or performance confidence: Without confidence, it will be
        difficult to trust your own decisions no matter how good they are,
        leaving you looking for validation elsewhere. Go to Performance. You can
        improve performance confidence by identifying specific aspects of your
        game that need improvement, establishing a plan for doing so, and
        practicing. You can improve personal confidence by changing some
        internal beliefs about yourself, improving your self-talk, and becoming
        more familiar with your strengths and attributes.
      </div>
      <div class="p-mb-2">
        Tension: Golfers are typically unaware of rising tension until it is
        beyond control, but there are tell-tale signs. Your mind gets busier,
        for example. You feel less comfortable with your alignment and you're
        less trusting of first impressions, both of which make committing to
        your shots much more difficult. Suggestions: Read more about tension in
        Chapter g to learn techniques for managing physical and mental tension.
        Physical techniques include such things as deep breathing, shoulder
        rolls, and stretching. Mental techniques include thought-stopping,
        meditation, and positive imagery
      </div>
      <div class="p-mb-2">
        Emotional instability: If you experience high levels of emotions such as
        anger, fear, disappointment, excitement, frustration, and the like when
        you play, it's much more difficult to assess options rationally and make
        effective decisions. Suggestions: Review Chapter 3 to control your
        emotions better. You should emphasize managing your thoughts and taking
        mental breaks between shots. Keep in mind that unresolved personal
        issues make these tasks difficult.
      </div>
      <div class="p-mb-2">
        Reduced mental alertness and fatigue: As with tension, few golfers are
        fully aware of reduced mental alertness and fatigue until its onset.
        Three clear indicators are indecisiveness, reduced visualization skills,
        and impatience. There can be many causes besides simply pushing too
        hard, including inadequate sleep, nutritional deficiencies, excessive
        worry, drugs, alcohol, medication, and low blood sugar. Suggestions:
        Begin with the obvious-take good care of yourself. Balance your life;
        im_ prove your diet and sleeping habits; reduce or eliminate caffeine,
        nicotine, alcohol, and drugs; identify and change things in your life
        that are out of order. Players who notice a pattern of feeling less
        decisive after making the turn often are experiencing a drop in blood
        sugar. It is important for these golfers to eat a balanced meal before
        play and cany balanced snacks to eat on the course.
      </div>
    </div>
  </Panel>
  <Panel
    header="Decisiveness in Special Situations"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">Decision-Making with a Caddie:</div>
      <div class="p-mb-2">
        It is important to stay in charge when you employ a caddie. Even if your
        caddie is your Parent, your friend, your sponsor, your boss, or even
        your spouse, you must be both committed to and responsible for your own
        decisions. A knowledgeable caddie can be a great asset to you in
        competition as he or she gathers needed information, points out things
        you may miss, and supports you in your decisions. But the caddie's input
        should not go beyond that. There may be instances in which you may be
        playing a course with which you're not familiar. Even here the caddie
        should be a provider of information. If the caddie tells you, for
        example, to hit to a certain spot, determine why and input your
        knowledge about your own game before committing to the shot.
      </div>
      <div class="p-mb-2">
        We see the greatest conflicts between self-sufficient, dominant players
        and self-sufficient, dominant caddies. Both want to be boss. We see the
        poorest commitment from players who allow their caddies to make the
        decisions or let them instill doubt about the golfers' own choices as
        they prepare to hit their shots. The greatest working relationships are
        those in which the player is receptive to a competent caddie's
        information, yet is ready to make his own decisions.
      </div>
      <div class="p-mb-2">
        Because most players do not have the luxury of working with professional
        caddies who have had several years to get to know the players game, many
        often find commitment comes easier when their caddies remain prepared to
        give advice but say nothing unless asked. But whatever method of
        involvement you choose, iust make sure it allows you to be fully
        committed to your shots.
      </div>
      <div class="p-mb-2">Self-Sufficiency and Teaching:</div>
      <div class="p-mb-2">
        Group-oriented players often are drawn to multiple teachers or can be
        too reliant on a single instructor. Interestingly, the greatest
        instructors have always encouraged and taught more self-reliance, so you
        should seek one who will help you learn to sort out your own problems,
        organize your own practice, and strategize your own game plans.
        Self-sufficiency comes easier to the player who is committed to his
        fundamentals for a long time-and to the instructor who teaches them.
      </div>
      <div class="p-mb-2">
        That said, the journal mentioned earlier can help reduce your dependency
        on an instructor. You may be surprised at how good you can get at
        "tuning up" your own skills, allowing you to use your teacher for the
        more complex problems and the further development of your game. The
        resulting self-reliance will be very valuable to you when you need it
        the most.
      </div>
      <div class="p-mb-2">
        If you think you are too self-sufficient, remember: Allow yourself to be
        coached. Even the best players in the world need and benefit from the
        assistance of others. To make this easier, carefully screen who's
        available and select someone who you respect and who best understands
        you and your style of play. It's much easier to listen to advice from
        someone who'll adapt his or her teaching style to your playing style,
        rather than the reverse.
      </div>
      <div class="p-mb-2">
        Make a great effort to remain open-minded. Pretending you are a neutral
        observer when hearing the information often helps. Imagine the
        suggestions are being made to someone else before responding to them.
      </div>
      <div class="p-mb-2">
        Resist withdrawing from others while stressed. The self sufficient
        person at times feels such a strong need to resolve his own problems he
        will often withdraw from others. But this makes the self-sufficient more
        likely to bury issues than resolve them. This then triggers increased
        tension, increased emotions, and an inability to focus. Suggestion: Seek
        out at least one person with whom you can discuss problems and
        frustrations. This is imperative. Your confidant does not have to be a
        golfer. If you cannot find this in a friend, seek the assistance of a
        licensed therapist.
      </div>
    </div>
  </Panel>
  <Panel
    header="Presentation of Indecisiveness"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Now let's look at players with too little self-sufficiency. Players with
        less self-sufficiency find golf more fun when it's played with a partner
        or as part of a team. Including, or even seeking, someone else to be
        responsible in decision making comes quite naturally to this golfer.
      </div>
      <div class="p-mb-2">
        Golfers with less self-sufficiency will try to organize most of their
        practice and play with either a teacher or friend. Some even prefer that
        someone else tell them when to practice, what to practice, how to play a
        course, or even how to hit particular shots. A number of the players
        who've worked with us have done so because they were finding it
        difficult to make the transition from team-oriented college golf to
        professional golf. They felt overwhelmed with the self-sufficiency
        required for managing their own time, planning their own schedules,
        traveling alone, organizing their own travel and practice, not to
        mention maintaining and adapting their games for the various courses and
        conditions.
      </div>
      <div class="p-mb-2">
        Players with too little self-sufficiency are likely to feel indecisive
        about some or all of the following: Fundamentals; The player with little
        self-sufficiency rooks to others for all direction because he has not
        developed or does not trust his own abilities to sort out swing
        problems.
      </div>
      <div class="p-mb-2">
        Equipment: Others can easily convince him that equip_ ment should be
        changed. He may choose equipment based on what is being used by peers or
        he may accept too much advice regarding clubs, shafts, grips, balls, and
        so forth.
      </div>
      <div class="p-mb-2">
        Course management: He too easily questions his decision making
        abilities. He often abandons his own strategy for playing the course to
        adopt strategies of other players.
      </div>
      <div class="p-mb-2">
        Clubs, targets, and types of shots: This golfer does not have his own
        game plan or abandons his to choose clubs or targets being used by
        others. If he is using a caddie, he will allow him to make too many of
        the decisions.
      </div>
      <div class="p-mb-2">
        Practice: He finds it difficult to decide on a practice regimen or
        schedule that benefits his game. He can easily make socialization more
        important than the quality of his practices.
      </div>
    </div>
  </Panel>
  <Panel
    header="Presentation of over decisiveness"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Now let's examine those with the opposite situation. Players with
        extremely high self-sufficiency can encounter problems of a different
        type. They are too resistant to ask for help, advice, or support from
        others. In times of stress very self-sufficient players are more likely
        to withdraw and try to sort out problems on their own. They are also
        more likely to suppress problems, rather than talk them out, thereby
        magnifying their impact.
      </div>
      <div class="p-mb-2">
        Here are the signs of extreme self-sufficiency: Fundamentals: The
        extremely self-sufficient golfer will take longer than others to work
        out problems because he prefers to sort things out on his own.
      </div>
      <div class="p-mb-2">
        Equipment: He may reject all suggestions regarding equipment, preferring
        to gather his own information and draw his own conclusions.
      </div>
      <div class="p-mb-2">
        Game plan: He is much less likely to discuss strategy with others,
        thereby losing possible advantages gained through their experiences or
        insights.
      </div>
      <div class="p-mb-2">
        Clubs, targets, and types of shots: He is likely to decline the
        suggestions of a caddie, teacher, or coach-even when he needs the
        assistance.
      </div>
    </div>
  </Panel>
</template>
