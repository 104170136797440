<template>
  <div class="fontSmall taLeft">
    <img
      src="@/assets/images/hardy2/swingVariabilityText1.png"
      class="img10"
      alt="Alt"
    />
    <img src="@/assets/images/hardy2/fig7.1.png" class="img10" alt="Alt" />
    <div class="accent1 fontSmall p-mb-2">
      FIG. 7.1. Top swing sequence is an example of a one plane swing where the
      arms and club swing more around the body and onto somewhat the same plane
      as the shoulders turn. The bottom swing sequence is a two plane swing
      example where the arms and club stay in front of the body and swing more
      upright than the plane of the shoulder turn.
    </div>

    <img
      src="@/assets/images/hardy2/swingVariabilityText2.png"
      class="img10"
      alt="Alt"
    />
    <img src="@/assets/images/hardy2/fig7.2.png" class="img10" alt="Alt" />
    <div class="accent1 fontSmall p-mb-2">
      FIG. 7.2. Top one plane swing sequence swings the shaft upward onto a
      plane parallel to the original address shaft plane and returns the shaft
      at impact back to the shaft plane line. Bottom two plane swing sequence
      swings upward onto a more upright plane drawn from the ball through the
      top of the shoulders and returns the shaft at impact on or just under the
      line which is considerably more upright than the shaft line at address.
    </div>
    <img
      src="@/assets/images/hardy2/swingVariabilityText3.png"
      class="img10"
      alt="Alt"
    />
    <img src="@/assets/images/hardy2/fig7.3.png" class="img10" alt="Alt" />
    <div class="accent1 fontSmall p-mb-2">
      FIG. 7.3. From top sequence to bottom sequence they are: low arm one plane
      swing of Matt Kuchar, slightly more upright one plane swing of Hendrik
      Stenson, low arm two plane swing of Louis Oosthuizen and the higher more
      upright two plane swing of Bubba Watson.
    </div>
    <img
      src="@/assets/images/hardy2/swingVariabilityText4.png"
      class="img10"
      alt="Alt"
    />
    <img src="@/assets/images/hardy2/fig7.4.png" class="img10" alt="Alt" />
    <div class="accent1 fontSmall p-mb-2">
      FIG. 7.4. The two plane hybrid golfers employ two plane upright backswings
      and then shift to a flatter RIT release around them into the finish.
      Examples of this from top to bottom are Robert Allenby, Inbee Park and
      Vijay Singh.
    </div>
    <img
      src="@/assets/images/hardy2/swingVariabilityText5.png"
      class="img10"
      alt="Alt"
    />
    <img src="@/assets/images/hardy2/fig7.5.png" class="img10" alt="Alt" />
    <div class="accent1 fontSmall p-mb-2">
      FIG. 7.5. The problem for a one plane hybrid using a LOP release is the
      club shaft comes into impact more upright than the forces of the swing
      with the clubface very open. As the more horizontal one plane swing forces
      hit the upright shaft they spin it closing incredibly fast much like a
      loose rudder on the end of a boat. With the high ROC clubface whirling
      from open to closed with such uncontrollable speed repitive shots are
      almost impossible to achieve.
    </div>
    <img
      src="@/assets/images/hardy2/swingVariabilityText6.png"
      class="img10"
      alt="Alt"
    />
    <img
      src="@/assets/images/hardy2/swingVariabilityText7.png"
      class="img10"
      alt="Alt"
    />

    <img
      src="@/assets/images/hardy2/swingVariabilityText8.png"
      class="img10"
      alt="Alt"
    />
    <img src="@/assets/images/hardy2/fig7.6.png" class="img10" alt="Alt" />
    <div class="accent1 fontSmall p-mb-2">
      FIG. 7.6. The one plane hybrid uses a shut face with the LOP release and
      eliminates the very high ROC by blocking the club's release and holding
      the clubface square to the plane/path.
    </div>
    <img
      src="@/assets/images/hardy2/swingVariabilityText9.png"
      class="img10"
      alt="Alt"
    />
    <img
      src="@/assets/images/hardy2/swingVariabilityText10.png"
      class="img10"
      alt="Alt"
    />
    <img
      src="@/assets/images/hardy2/swingVariabilityText11.png"
      class="img10"
      alt="Alt"
    />
    <img
      src="@/assets/images/hardy2/swingVariabilityText12.png"
      class="img10"
      alt="Alt"
    />
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
  </div>
</template>
