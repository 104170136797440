<template>
  <Panel header="Introduction" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Starting the ball on line does little good if you can’t predict the
        correct line to begin with, and unfortunately, it’s a skill most players
        aren’t very good at.
      </div>
      <div class="p-mb-2">
        I spend a significant portion of most days watching people roll putts,
        and it’s been this way for twenty straight years. As you might imagine,
        I’ve seen it all—a constant variety of motions, both good and bad. Every
        player is unique, and the differences in body type and function,
        ingrained motor programs, physical and mental experiences, and
        personality make sorting through it all both challenging and fun. My
        vantage point and objectivity often allow me to notice the small things
        in players’ strokes that are hidden even to them. But despite this
        melting pot of motions, there are several commonalities, one of which
        truly defies logic: Most amateur players have no idea how much putts
        actually break, even after hitting them.
      </div>
      <div class="p-mb-2">
        Take the following example: Me: “Read this putt and tell me your
        strategy.” Student: “I read it as a little left-to-right, so I’m going
        to aim at the left edge of the cup.” At this point I’m thinking, “I’d
        play more break,” but of course I say nothing. The student strikes a
        pretty good putt on their stated line or even a bit higher, but the ball
        breaks across the hole and misses low. Student: “I pushed it.” Me: “Slow
        down a second. That’s not what happened. You hit a nice putt, it just
        broke more than you thought it was going to. Pushing the putt wasn’t the
        problem. Judging the break was.”
      </div>
      <div class="p-mb-2">
        The above exchange is extremely typical. Most amateurs blame misses on
        mechanics, not reads, which is a huge roadblock to long-term success.
        Poor results and our innate desire for immediate success tempt many of
        us to fiddle with our method instead of digging deeper. We search
        without any direct knowledge regarding how our changes impact the
        individual variables that affect skill, which is a well-worn path into
        the wilderness. For the player in the above exchange, the real issue is
        that “he doesn’t know that he doesn’t know” how to read greens.
      </div>
      <div class="p-mb-2">
        One of the strengths of my putting system is that once you prove to
        yourself that you have the skill to start the ball on line—whether it’s
        simply hitting the coin in the 25-Ball Dime Test or using one of the
        training confirmations in Chapter 5—you’re free from second-guessing
        your stroke fundamentals after a miss (unless a painfully obvious
        pattern arises). From now on, when you miss a putt, the blame falls on
        either your judgment about the read, the energy transferred to the ball
        at impact, or the process you went through that did or did not allow
        complete focus and commitment.
      </div>
      <div class="p-mb-2">
        THE PHYSICS OF ROLL: There are some hardcore physics at work as your
        ball traverses the green, so it’s best to consult science to gain a
        better understanding of break—undisputed facts will make you a better
        green-reader. This foundational understanding expedites the learning
        process and takes the mystery out of this important skill. At the end of
        the day, however, green-reading is an art. Beyond accounting for the
        slope and speed, you have to be able to sense the appropriate line and
        matching energy for the putt at hand, which may be influenced by other
        factors, such as wind, grain (the direction in which the grass is
        growing), the moisture content of the green, and how you feel physically
        and emotionally at the moment. In short, to be an exceptionally skilled
        green-reader, you need to embrace both worlds. The first order of
        business is to understand the basic science behind why balls roll the
        way they do. Then you can move on to the art: the touch, feel, and
        creativity that expert green-reading demands.
      </div>
    </div>
  </Panel>
  <Panel
    header="The Science of Green-Reading"
    :toggleable="true"
    :collapsed="true"
  >
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/greenReadingScience1.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      Fact 1: There’s only one aim point for all putts of the same distance on a
      planar surface.
    </div>
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/greenReadingScience2.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      Once you determine the aim point, you can use it for all remaining putts
      (I’ve shown five, but picture hundreds). If you hit the putt with the
      correct speed on pure surface conditions, it has to go in.
    </div>
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/greenReadingScience3.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      Rolling balls on the green with the Perfect Putter. Objective tests like
      these make discerning break easy and fun.
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        The scientific approach to green-reading began in earnest in 1984, when
        a United States Air Force colonel with a passion for golf, H. A.
        Templeton, showed in a self-published book entitled Vector Putting how
        two variables, gravity and friction (green speed), could be used to
        compute break on a green. The motivation for his groundbreaking
        research—beyond helping his own game—was to create a computer model that
        television networks could use to predict the break of putts, which he
        felt would make telecasts more interesting to watch. Considering the
        state of the computer technology available to him at the time, this was
        a monumental task. Clearly, he was ahead of his time.
      </div>
      <div class="p-mb-2">
        Decades later, Mark Sweeney, the founder of AimPoint Technologies,
        applied modern computer standards to Templeton’s discoveries, then
        partnered with the Golf Channel to use predictive putt technology to
        power a live graphical insertion of an optimal putt overlay into their
        broadcasts starting in 2007. If you’ve seen AimPoint’s predictive putt
        technology in action, you know its calculations are very accurate. But
        because it relied on precise laser measurements of the greens as well as
        speed and slope charts, it was, in my opinion, too complicated and
        time-consuming to be useful for all but elite players. Subsequently,
        Sweeney developed a more practical way to implement his scientific
        approach called AimPoint Express. Personally, I’m a fan; anything that’s
        simple to use and helps players at any level improve their skill is a
        winner.
      </div>
      <div class="p-mb-2">
        I’m not a certified Vector Putting or AimPoint Express teacher, and I
        don’t feel the need to elaborate much on either method except to share
        some basic facts that I think you’ll find helpful. If you’re interested
        in learning more about their methods, a quick Internet search will yield
        a long list of PGA professionals who are certified and would be glad to
        help. My interest is solely to open your eyes to a few undisputable
        green-reading facts discerned from science that you can implement into
        an artistic process in order to improve your skill. It’s a process that
        I’ve shared with all my Tour clients, and I’m confident it will help you
        as much as it has helped them—probably more.
      </div>
      <div class="p-mb-2">
        GREEN-READING FACTS Thanks to research by Templeton, Sweeney, and
        others, golfers can now separate fact from fiction when it comes to
        understanding roll.
      </div>
      <div class="p-mb-2">
        Fact No. 1: On any planar surface (with the slope only in one
        direction), every putt that starts the same distance from the hole
        shares a common aim point. Rolling your putt at this point with the
        correct speed will result in a make, regardless of where the ball lies
        on the green.
      </div>
      <div class="p-mb-2">
        To find that one point, imagine you’re putting on an oversized pool
        table raised up on one end (creating a two-degree slope) with a hole cut
        in the middle. In this scenario, on a green of average speed, read the
        putt for a ball sitting by the side pocket, taking into account two
        factors: the amount of tilt (slope) and speed of the table. Whatever
        point you align to above the cup from this position is the same point
        you’d align to from any other location on the table of equal distance.
      </div>
      <div class="p-mb-2">
        Fact No. 2: The more the putting surface is tilted, the higher the
        common aim points shifts above the hole, and vice versa. This is a
        no-brainer. The greater the slope, the greater the break.
      </div>
      <div class="p-mb-2">
        Fact No. 3: The faster the green (the less friction acting on the ball
        to hold it on line), the more you must move the aim point toward the
        high end of the slope.
      </div>
      <div class="p-mb-2">
        Picture the Masters. Putts hit on Augusta National’s greens break
        dramatically, partly because the putting surfaces during tournament week
        run so fast. Downhill putts (because they’re faster) break a bit more
        than uphill putts across the same grade, so on extremely fast greens
        (with less friction) the area where maximum break occurs moves slightly
        uphill (up to thirty degrees) above the side pocket.
      </div>
      <div class="p-mb-2">
        Fact No. 4: There are at least two “fall lines” on every green. Balls
        putted from anywhere on these lines must start at the middle of the hole
        to go in. In our pool table example, the fall lines are directly north
        and south of the cup.
      </div>
      <div class="p-mb-2">
        In the real world these lines curve or meander with the slope, but the
        overall read for balls resting on them remains straight.
      </div>
      <div class="p-mb-2">
        Fact No. 5: Maximum break generally occurs when your ball lies directly
        between neighboring fall lines. going back to the pool table example,
        maximum break occurs at or slightly above the side pockets.
      </div>
      <div class="p-mb-2">
        On a true planar surface, anything pin high is going to break a lot.
      </div>
      <div class="p-mb-2">
        Knowing these five facts will enhance what you see and feel and will
        help you make sense of why balls roll the way they do. Of course, greens
        are rarely perfect planar surfaces—they roll, flatten out, and change
        direction at the fancy of the architect, which requires you to add a
        good measure of feel to your reads.
      </div>
      <div class="p-mb-2">
        There are two additional variables beyond slope and speed that can
        influence the roll of a ball: the grain (direction in which the grass
        lies) and the wind. The strength and influence of the grain is
        species-dependent. On bent-grass greens, which are common in colder
        climates, the grain tends to influence speed more than it does line.
        With Bermuda and other thicker-blade grasses, the effects on line are
        greater, although still not as prominent as the effect on speed.
        Improved green-maintenance processes and equipment have reduced grain’s
        effect on the ball’s roll in general, which has removed a layer of
        judgment. You’re lucky. When I played on the Asian Golf Circuit from
        1989 to 1993, courses such as Wack Wack Golf & Country Club outside
        Manila featured grain so strong it could literally “push” putts up a
        slight slope. This made choosing the correct line extremely difficult,
        especially for a guy who grew up putting in Nebraska.
      </div>
      <div class="p-mb-2">
        Regardless, my advice is to think mainly in terms of speed, not line,
        when assessing grain. Look down the line of your putt and determine if
        the grass looks shiny or dark in hue. If it’s shiny, you’re putting
        down-grain—the putt will be fast and break more. If the grass looks
        darker in color or flat, you’re putting into the grain, and the ball
        will stop more quickly and break less (and break late).
      </div>
      <div class="p-mb-2">
        Wind, on the other hand, can have a much more dramatic effect on line.
        My testing with the Perfect Putter (pictured opposite) indicates that a
        20 mph crosswind can move the ball up to four inches on a straight
        20-foot putt on a green measuring 11 on the Stimpmeter. That’s almost a
        full cup! My studies also show that the faster the speed of the green
        (that is, the less friction there is), the more dramatic the wind
        effect. Of course, you should also account for the wind’s effect on
        speed, which can be significant, especially if the greens are fast. Note
        that if the grain, wind, and slope are all going in the same direction,
        the overall effect is tripled. Expect the ball to move a lot!
      </div>
      <div class="p-mb-2">
        Clearly, there’s a lot to consider when predicting the line of a putt,
        including how you feel at the moment, which ultimately makes the act of
        green-reading an artful exercise. Science is a solid base, but in the
        end, sensing just how much break to play is a right-brain activity—there
        is definitely need for added touch and feel. The question is, how do you
        learn art?
      </div>
    </div>
  </Panel>
  <Panel header="The Art of Green-Reading" :toggleable="true" :collapsed="true">
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/greenReadingArt1.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      The green gives you all the clues you need to dial in the perfect read.
      Having an organized process to discern them is key to your success.
    </div>
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/greenReadingArt2.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      Aligning your ball to the target line only makes sense if your initial
      conscious read is consistently correct, or if you tend to second-guess
      your aiming ability.
    </div>

    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        THE ART OF GREEN-READING If the thought of assembling all of the pieces
        of the green-reading puzzle sounds daunting, you should feel even more
        confident. The fact that it’s difficult means that most players won’t
        make the effort to master it, giving you a competitive advantage when
        you apply these concepts correctly and consistently. Second, I believe
        meaningful learning is self-learning, and that it ultimately takes place
        in the subconscious. I don’t want you overthinking or “doing math” on
        the greens—I want you to react to what you see and feel. Tom Pernice,
        Jr. and Brad Faxon could easily recite the scientific facts of
        green-reading, but that’s not what makes them great. Elite green-readers
        can simply sense what’s required, nothing more. Michelangelo was a great
        scientist, and he used that knowledge to enhance his art, but he didn’t
        paint by numbers.
      </div>
      <div class="p-mb-2">
        Skills develop incrementally over time, not all at once. This one’s no
        different. Any incremental improvement in your ability to see and feel
        putting lines as an artist will show up on your scorecard. Growth is the
        key, not perfection.
      </div>
      <div class="p-mb-2">
        HOW TO BUILD AN EFFECTIVE GREEN-READING PROCESS Every shot needs a
        process—an action plan that takes you from cluelessness to a clear and
        committed strategy that gets you ready to play. On the putting green,
        you’ll execute two processes, one that starts when you walk onto the
        green to determine the break and speed of the putt, and another to keep
        you focused as you walk into the ball. Your green-reading steps (Process
        1) should be organized and well-defined, so that the manner in which you
        look for and feel the cues that the green yields unfolds in the same
        order and features the same actions every time. The continuity of this
        process, along with your base knowledge of green-reading facts, will
        allow you to improve your green-reading skills with each experience.
        (We’ll get to Process 2 in the next chapter.)
      </div>
      <div class="p-mb-2">
        When building your green-reading process, keep in mind that you should
        read the green or decide on the line only once. As simple as this
        sounds, it’s not common. Many players look from behind the hole and read
        the putt as right edge, and then walk behind the ball and see the same
        putt as a cup out. Now what? Clearly this is a bad strategy. A better
        alternative is to gather facts in a step-by-step fashion until you have
        all the information you need to read the putt—then read it once. Now you
        can step in clear and committed to your vision.
      </div>
      <div class="p-mb-2">
        If your green-reading cues are defined and accounted for in each step,
        you won’t forget to look and feel for them on any green in any round.
        The information gathered will allow you to tap into your subconscious
        instinct and give you a definitive aim line, putt shape, and speed. This
        continuity is critical. Meaningful experiences build on one another
        until they become second nature. At some point, you’ll simply sense what
        to do—your subconscious will take over. This is the art of putting.
      </div>
      <div class="p-mb-2">
        The following is the green-reading process I teach my Tour clients.
        Consider adopting it as your own, or edit it as you see fit. As was the
        case with building a block-practice station, anything you come up with
        that works for you works for me.
      </div>
      <div class="p-mb-2">
        The Your Putting Solution Green-Reading Process Step 1: As you walk up
        to the green complex, locate the fall lines. All putts are ultimately
        straight along this axis. Step 2: Walk so you can see your upcoming putt
        from behind the hole. Look at the surface just beyond the cup, and in
        your mind’s eye, place a quarter two feet to the right of your
        approximate line and a penny two feet to the left. Scan horizontally
        between the two coins and compare their elevations. Note which coin is
        lower and by how much. This is the direction the putt will break as it
        approaches the cup. Reading what happens near the hole first is
        critical, because this is where the ball will be rolling the slowest
        and, as a result, where it will be affected the most by the slope. Also,
        it’s harder to see this area around the hole when you’re standing behind
        the ball, because you’re at the farthest point from the cup. Many
        players also make the mistake of looking vertically from the hole to the
        ball. I like that gaze to be horizontal in nature, from coin to coin.
        Step 3: Walk toward your ball and stop at the halfway point between the
        ball and hole. Did you walk uphill or downhill? If you sensed more
        pressure under one foot than the other, it’s a strong clue that the
        green is tilting in that direction. “Listen” to your feet—sometimes your
        eyes can deceive you. Step 4: Walk behind your ball and picture its
        speed as it rolls toward the hole. The speed of the ball determines the
        line and must be considered prior to deciding on an aim point. If you
        feel like allowing the putt to “crawl” in, then choose a slightly higher
        line. If you feel like rolling the putt firmly and taking out some of
        the break, go with a more aggressive line. Step 5: Squat down and run
        your eyes horizontally (coin to coin) along the full length of your putt
        (photo, here). Since you’ve already determined how the putt will behave
        as it approaches the hole in Step 2, you should have a pretty clear idea
        about the overall shape as you put the pieces together. Step 6: If you
        read any break, gently shift your vantage point toward the low side of
        the slope until you’re looking directly up the start line of the putt.
        Listen to your subconscious—it will take care of you. Once you decide on
        a start line, quantify it. You can either point the line on your ball
        toward the aim point (see below), pick a discernable mark on the green
        on which to focus your aim, or use the hole as reference (for example,
        “two cup-widths left of the hole”). I prefer the first two options.
      </div>
      <div class="p-mb-2">
        Your green-reading process is complete. Again, this is an example of how
        many of my Tour students read putts, but don’t be afraid to develop your
        own version. We’re all different. The key is that you have one (your
        competitors don’t) and that you stick to it and master it. Continuity
        will breed success.
      </div>
      <div class="p-mb-2">
        SHOULD I ALIGN THE BALL TO MY TARGET? This is an interesting question,
        and my answer differs depending on your current skill set. If you’re a
        good green-reader (meaning your conscious read is often the correct
        read), then go for it. Point the line on the ball down your aim line and
        commit to it. Also, if you’re constantly second-guessing your aim,
        aligning the ball will help you because it removes doubt. However, if
        you’re like most players and your subconscious reads tend to be more
        accurate than your conscious reads, place your ball down on the green
        without using a line and feel your way to the correct alignment. You may
        be best served by lining up your ball on relatively simple short putts
        and not on longer big breakers, which can require a more intuitive feel.
      </div>
      <div class="p-mb-2">
        Since one of the goals of this book is to make you a highly skilled
        green-reader, at some point you’ll be lining up the ball like Tom
        Pernice, Jr., Tiger Woods, and hundreds of other Tour pros. No need to
        rush the process. You’ll know the time is right when you’re passing the
        10-Ball Green-Reading Test (here) with flying colors. In the meantime,
        continue to develop your skills.
      </div>
    </div>
  </Panel>
  <Panel header="AimPoint Green Reading" :toggleable="true" :collapsed="true">
    <Panel header="Feeling Tilt" :toggleable="true" :collapsed="true">
      <img
        class="img4"
        src="@/assets/images/sieckmannPutting/aimPoint1.png"
        alt="Sieckmann Putting"
      />
      <div class="accent1 fontSmall p-mb-2">
        Using Feet to determine direction and severity of slope
      </div>
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          The ball breaks on its way to the hole because it is tilted to the
          side. The more it’s tilted, the more it breaks. In order to apply the
          Express process correctly we have to put a value on the amount of side
          tilt that we feel.
        </div>
        <div class="p-mb-2">
          We are only putting a value on the amount of tilt side-to- side, not
          front-to-back. Under practical circumstances the slope value will
          rarely exceed 7. Most putts will have between 0% and 3% tilt.
        </div>
      </div>
    </Panel>
    <Panel header="Short Putts" :toggleable="true" :collapsed="true">
      <img
        class="img4"
        src="@/assets/images/sieckmannPutting/aimPoint2.png"
        alt="Sieckmann Putting"
      />
      <div class="accent1 fontSmall p-mb-2">x</div>
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          The process for an AimPoint Express Read on short putts (3-6 feet)
          should be to feel your slope value with your toes at the ball while
          facing the hole.
        </div>
        <div class="p-mb-2">
          1. Stand directly facing the hole and choose a slope value.
        </div>
        <div class="p-mb-2">
          2. On “1” slope values play just inside the hole on the high side.
        </div>
        <div class="p-mb-2">
          3. For slopes greater than 1, hold up the same number of fingers as
          the slope value with the edge of your fingers on the lower edge of the
          hole.
        </div>
        <div class="p-mb-2">
          4. Aim on the outside of the furthest finger above the hole.
        </div>
      </div>
    </Panel>
    <Panel header="Single Breaks" :toggleable="true" :collapsed="true">
      <img
        class="img4"
        src="@/assets/images/sieckmannPutting/aimPoint3.png"
        alt="Sieckmann Putting"
      />
      <div class="accent1 fontSmall p-mb-2">x</div>
      <img
        class="img4"
        src="@/assets/images/sieckmannPutting/aimPoint4.png"
        alt="Sieckmann Putting"
      />
      <div class="accent1 fontSmall p-mb-2">x</div>
      <img
        class="img4"
        src="@/assets/images/sieckmannPutting/aimPoint5.png"
        alt="Sieckmann Putting"
      />
      <div class="accent1 fontSmall p-mb-2">x</div>
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          On single breaking putts longer than 6 feet, we get the biggest slope
          value in the middle section of the putt rather than at the ball. This
          is because the slope can begin to change on longer putts and the
          middle section controls the overall break. We also move our hand so
          that it is aligned with the center of the hole rather than the lower
          edge.
        </div>
        <div class="p-mb-2">
          1. Begin behind the ball to feel the low side of the putt.
        </div>
        <div class="p-mb-2">
          2. Walk on the low side of the putt and check the slope ⅓ and ⅔ of the
          way to the hole. Use the biggest value.
        </div>
        <div class="p-mb-2">3. Align your hand to the center of the hole.</div>
      </div>
    </Panel>
    <Panel
      header="Green Speed Calibration"
      :toggleable="true"
      :collapsed="true"
    >
      <img
        class="img4"
        src="@/assets/images/sieckmannPutting/aimPoint6.png"
        alt="Sieckmann Putting"
      />
      <div class="accent1 fontSmall p-mb-2">x</div>
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          As green speed increases the break will increase. In order to account
          for this we bend our arm at faster green speeds. At stimp 7 and for
          all short putts inside 10 feet, stand directly over the ball. At
          faster speeds you can move roughly one step back behind the ball.
        </div>
        <div class="p-mb-2">
          There is some room for personal calibration, find the distance behind
          the ball and arm bend which best corresponds to the correct break and
          continue standing at that distance, but bending your arm will be
          necessary at stimp of 9 and faster. The faster the stimp, the more you
          should bend your arm.
        </div>
        <div class="p-mb-2">
          Remember we are calibrating to break, so adjust arm bend as necessary
          after hitting your putt. If the ball breaks more than expected, bend
          your arm more, if it breaks less than expected, straighten your arm.
        </div>
      </div>
    </Panel>
    <Panel
      header="Double Breaks (Simple Read - Read from the Center Point)"
      :toggleable="true"
      :collapsed="true"
    >
      <img
        class="img4"
        src="@/assets/images/sieckmannPutting/aimPoint7.png"
        alt="Sieckmann Putting"
      />
      <div class="accent1 fontSmall p-mb-2">x</div>
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          When reading a double-breaking putt, the simplest method is to take
          the slope value from exactly halfway between the ball and the hole and
          play that all the way from the ball. There is still some room for feel
          on this read if one slope seems dramatically bigger than the other,
          but if you want more accuracy you’ll have to learn how to split the
          putt into two sections.
        </div>
        <div class="p-mb-2">
          1. Visually determine where the exact center of the putt is.
        </div>
        <div class="p-mb-2">2. Determine the slope value at that point.</div>
        <div class="p-mb-2">
          3. Play that slope value for the whole putt, reading from the ball.
        </div>
      </div>
    </Panel>
  </Panel>
</template>
