<template>
  <div class="fontSmall taLeft">
    <div class="p-mb-2">
      “I use memories, but do not allow memories to use me.” Shivas Sutras
    </div>
    <div class="p-mb-2">
      SWING KEY: Store your good shots so you can revisit them.
    </div>
    <div class="p-mb-2">
      Do you remember that time when you were a child and touched the hot stove,
      your hand recoiling in pain? Mom ran over, gently kissed the tip of your
      finger and gave you some ice to ease your discomfort. Everything about
      that experience became part of who you are and what you know about the
      world around you. Without having to consciously file away the information,
      you learned that hot stoves hurt and that your mother will always be there
      for you.
    </div>
    <div class="p-mb-2">
      Emotional memories like the one above get stored in the amygdala part of
      the brain. That’s the way we all are wired. Filed away was the fact that
      Mom loves you. Also filed away was the fact that you shouldn’t touch the
      stove when it is turned on. Everything that happened was automatically
      recorded in your memory bank as an essential survival skill. Filing away
      your great golf shots is an Essential Playing Skill, as is managing the
      memory of your poor shots. The better you connect to your positive
      memories, the more “likely you are to create great new positive memories.
      The saying “Confidence breeds confidence” is literally true—if you store
      positive memories.”
    </div>
    <div class="p-mb-2">
      While the stove incident is an impulse memory based on pain and out of
      your control, there are memories that are more voluntary, that you can
      control. This is where you establish the mechanics of confidence. Your
      brain is wired to remember the bad experiences more easily than the good
      as a survival skill. To reach your potential in golf, you have to learn to
      store your good shots by establishing an emotional connection with them.
      This is how to create a “go” signal when you are walking up to a shot.
    </div>
    <div class="p-mb-2">
      Beth Brown is the director of curriculum and research at The First Tee,
      the youth development program supported, in part, by golf ’s governing
      bodies, as well as an LPGA teaching professional with a PhD in education.
      Like many of us, her passion for golf is tied to childhood memories. When
      she was twelve years old, Beth would walk eighteen holes in the morning
      with her red vinyl bag, work at her dad’s Dairy Queen for four hours in
      the middle of the day and then play another eighteen holes before dark.
    </div>
    <div class="p-mb-2">
      But when she came to our school in 2008 she had lost that emotional
      connection to the game. She was no longer having fun on the golf course.
      “My first ‘aha’ moment occurred when Lynn and Pia asked me, ‘Why do you
      play golf?’” Beth says. “I responded that I was still trying to remember
      what I loved about the game. I thought if I could rediscover that passion
      I had as a child, that joy, then surely I would fall in love with the game
      again.
    </div>
    <div class="p-mb-2">
      When Beth thought about it, she realized that at least since college she
      had linked her enjoyment of the game to her results. If she didn’t play
      well, she didn’t have as much fun on the golf course. The golf memories
      Beth had stored when she was twelve were about process—the pure joy of the
      game. The stored memories since college had been about results—the
      pressure of the competitive game. She was not the same person as the
      twelve-year-old Beth and she had to learn to find her love of the game in
      a different way.
    </div>
    <div class="p-mb-2">
      From that moment forward, it was about being in the present and exploring
      what I love now,” Beth says about her “aha” breakthrough. She set about
      trying to learn who the now-Beth is and what makes her most happy on the
      golf course. “I know I play my best golf when I slow down and let go,”
      Beth says.“I learned I need to spend less time in the Think Box; create a
      peaceful connection to the target; and maintain my target connection all
      the way through the swing.
    </div>
    <div class="p-mb-2">
      This is a very different person from the twelve-year-old Beth who was
      happy just to be out on the golf course walking thirty-six holes. That was
      pure and simple play. The adult Beth has a much more complicated
      relationship with the game. Beth came to understand that as a child she
      had successfully stored those glorious memories that were the basis for
      her connection to the game—her love of the game. But her current memory
      bank had become much different.
    </div>
    <div class="p-mb-2">
      When you are twelve and the game is as simple as “hit the ball, go find it
      and hit it again,” it is easy to store positive memories. For one thing,
      there are fewer negative memories to compete with the positive memories,
      since the game is pretty much all about play and not about work or
      competition. The adult Beth had to learn that her connection to the game
      is different now and that she needs to actively work on storing different
      positive memories.
    </div>
    <div class="p-mb-2">
      Part of what we do at our school is get everyone out on the course, where
      the game is really played. We were walking with Beth during a round one
      day, and when she would hit a good shot, one of us would ask, “How was
      that experience?” If she said it was a good experience, we would then ask,
      “Did you store that shot inside of you?” This process occurred several
      times during the round.
    </div>
    <div class="p-mb-2">
      Then Pia decided to challenge me,” says Beth. “I guess she wasn’t buying
      my story. This time she said, ‘Did you really ?’ I suddenly stopped
      walking off the tee and realized I had seen and felt the shot, but it was
      more like someone else had done it. I had observed it, but I hadn’t
      experienced it.
    </div>
    <div class="p-mb-2">
      Beth realized that this practice of living life as a third-person observer
      rather than experiencing life as a first-person participant was not unique
      to her golf. “I’m certain I do this in all areas of my life,” Beth says.
      “When I do “something well or when others compliment me, I see it, hear it
      and know that I did it, but I don’t really take it in, absorb it,
      experience it inside myself and lock it away in my memory.”
    </div>
    <div class="p-mb-2">
      Beth needed to learn the Essential of storing positive memories—how to
      take ownership of her great shots and her good shots, how to make them
      live in her memory like the touch of a hot stove—only this time as
      positive experiences that can be relived over and over rather than as a
      bad experience to be avoided. Storing positive memories is a skill that
      can be learned. As with all of the Essentials, this is something under
      your control. That is why it is an Essential. Beth developed her own
      personal tools.
    </div>
    <div class="p-mb-2">
      The result on the golf course has been that after good shots I anchor them
      in my memory by twirling my club, admiring the shot and just slowing down
      and enjoying the moment,” Beth says. “I recall these shots much more
      readily now because so many more are first-person experiences that I have
      stored away.” Think of great players. They all have a way of stamping a
      great shot into their memory bank, whether it is a fist pump, a raised
      club or even a hop and a skip. Juli Inkster used to dance when she made a
      key putt.
    </div>
    <div class="p-mb-2">
      Your emotions store events as memories, and these memories affect your
      future performance. So your challenge is to control your emotional
      reaction to your shots. You want to have a strong emotional reaction to a
      good shot and a neutral emotional reaction to a bad shot. One exercise we
      like to have players do is to say aloud something positive or factual
      about every shot they hit—no matter the result. Can you hit a big slice
      and then say: “I was totally committed to my playing focus” or “Gee, it’s
      a beautiful day”? Try it! You’ll find it more challenging than you
      think—and extremely useful. You can learn not to get angry over your bad
      shots.
    </div>
    <div class="p-mb-2">
      When you are on the golf course, feel all the things you do well, so you
      can store those memories. Live the great shots as first-person
      experiences, but analyze the shots that don’t work from a third-person
      objective perspective. That way you can learn from those experiences
      without storing them in your memory bank. For every shot, you can either
      store the experience or view it objectively. That is something that is
      under your control.
    </div>
    <div class="p-mb-2">
      Some people are demonstrative with their post-shot reactions and it is
      very easy to see if they are pleased, angry or disappointed with the
      result. Others are more difficult to read and might look calm on the
      outside while they are really experiencing the same turmoil as the
      extroverted person. When it comes to storing memories or establishing
      emotional resilience, the impact is the same no matter if your reaction is
      extroverted or introverted.
    </div>
    <div class="p-mb-2">
      We originally met Beth because of her involvement with the First Tee, for
      whom we were consulting. A large part of the great work being done by the
      First Tee is emphasizing to young people the values and life skills
      learned through golf—including honesty, decision-making and respect. Beth
      learned another life skill to use in her teaching—remembering the good
      shots and neutralizing the bad. And, like all of the Essential Playing
      Skills, this truly is a life skill.
    </div>
    <div class="p-mb-2">
      The result in my life, away from golf has been significant as well,” Beth
      says about learning how to manage her memories. “I catch myself glossing
      over a positive experience—observing it from outside myself—and I just
      stop and say something to myself like, ‘Hey! You did that! Stop and enjoy
      it! That was incredible.’ Also, when I get compliments or positive
      reinforcement from others, I sometimes share those comments with my
      husband. Saying the comment out loud helps me store it away in my
      long-term memory.
    </div>
    <div class="p-mb-2">
      Some players set such high standards for themselves that they store very
      few shots in their memory bank because, to them, very few shots are
      “perfect.” Ben Hogan used to say he hit only one or two shots a round that
      went exactly the way he planned. The key is to store those shots that are
      “good enough” and not let an obsession with perfection cloud your memory
      bank. Once, in discussing the overuse of the word “great,” the inimitable
      Fred Couples said: “Hey, I’m not great, I’m good; but good’s not bad.”
      Exactly. Recognize those “good enough” shots with an emotion, embrace that
      emotion and store that positive memory. This is not about “becoming cocky;
      it is about becoming confident. In fact, it’s how you create confidence.”
    </div>
    <div class="p-mb-2">
      When you are standing over a shot, you want to be remembering the times
      you pulled off such a shot, not the times you didn’t. For that reason, it
      is just as important to learn how to stay neutral when you hit a shot that
      you don’t like as it is to celebrate those you do like. To overreact to
      poor shots is to store a memory that can come back to haunt you at the
      most inopportune time. Embrace the good; be clinical about the bad.
    </div>
    <div class="p-mb-2">
      There is no doubt this is a lot easier said than done. “I wish my pre-shot
      routine was the end of the story,” Beth says. “But for me what happens
      after the shot is critical. My nature is to be very hard on myself and
      expect nothing less than perfection.” Certainly, expecting perfection is a
      recipe for disaster. How you react when you have a result that is less
      than satisfying will have a major impact on how you will handle a similar
      shot in the future. If you overreact—get angry, throw a club or just
      berate yourself internally—you are more likely to remember—and repeat—that
      shot next time you are in a similar situation.
    </div>
    <div class="p-mb-2">
      Don’t deny that you have hit an unsatisfying shot; just learn how to
      control your emotional response to it. We can’t control what happens to
      us, but we can control how we react to what happens to us. Every shot you
      hit has two possibilities of storage. You can either store, or stay
      neutral to the outcome of the shot or how you did with the process of the
      shot. When a poor shot occurs, instead of focusing on the outcome, focus
      on how you did with your Play Box state or how you did with your
      commitment to your decision.
    </div>
    <div class="p-mb-2">
      The stored memory works in this way: When you come to a similar situation
      in the future, your amygdala searches for matches on a subconscious level
      (a hot stove, a three-foot putt with a left-to-right break). If it finds a
      match that was negative, it will give you a physiological warning in the
      form of apprehensive thoughts, tension, a pounding heart. This is great in
      terms of your survival, but not so much in terms of that three-foot putt.
    </div>
    <div class="p-mb-2">
      There is so much talk in golf about pre-shot routines, and there is no
      question they are important (although sometimes overly exaggerated). But
      just as important—and this is key to remember—is your post-shot routine.
      How you react to a shot determines how your brain will store the memory of
      that shot. This is simple science. We know this to be true.
    </div>
    <div class="p-mb-2">
      Dr. Barbara Fredrickson, a social psychologist and the Kenan Distinguished
      Professor of Psychology at the University of North Carolina at Chapel Hill
      and author of the book Positivity , discovered that experiencing positive
      and negative emotions in a three-to-one ratio leads people to a tipping
      point beyond which they naturally become more resilient to adversity and
      effortlessly achieve what they once could only imagine. Her extensive
      research concluded that positive emotional memories carry far more
      benefits than most of us suspected.
    </div>
    <div class="p-mb-2">
      She categorizes two classes of these benefits into what she calls the
      broaden-and-build theory. First, when we experience a positive emotion,
      our vision literally expands, allowing us to make creative connections,
      see our oneness with others, and face our problems with clear eyes (the
      broaden effect). Second, as we make a habit of seeking out these pleasing
      states, we change and grow, becoming better versions of ourselves,
      developing the tools we need to make the most out of life (the build
      effect).
    </div>
    <div class="p-mb-2">
      And strikingly, these twin benefits of positive emotions obey a tipping
      point: When positive emotions outnumber negative emotions by at least
      three to one, these benefits accrue, yet below this same ratio, they
      don’t. Simply put, you can learn to bypass your amygdala’s automatic
      default operations, in much the same way you choose to tap different
      buttons on a computer to go to a different screen. React in the default
      mode and your amygdala can heat up a situation by placing you in a far too
      sensitive mood, flooding your brain with cortisol and causing you to
      overreact. You have to learn ways to efficiently stay neutral to those
      memories.
    </div>
    <div class="p-mb-2">
      Caught under attack, you’ll respond accordingly, whether the attack is
      real or perceived, unless you intervene to help your brain. Because of
      your amygdala, you can develop and use different strategies to create calm
      under pressure, and as you build emotional patterns for dealing with
      stressors, you begin to see the usefulness of the patterns. Pragmatically,
      you learn to deal more calmly with the undesirable outcomes and bad
      bounces. You don’t have to be a victim of your stored fear response.
      Embrace the three-to-one ratio as your baseline and you’ll start to tame
      the hot amygdala and rewire your post-shot responses to something that is
      productive and performance enhancing.”
    </div>
    <div class="p-mb-2">
      We know players who have a short fuse, panic easily and generally make
      mountains out of molehills. People with the hottest amygdalas are the
      drama queens, rage-a-holics, worrywarts and chronic complainers among us.
      When the amygdala gets stuck in overdrive, it widens the negative neural
      pathways in our brain. Our minds become overrun with negative thoughts and
      we worry, picturing over and over what we don’t want to happen, creating
      anxiety and unhappiness in our lives and our golf games. How do you think
      you perform in such a state?
    </div>
    <div class="p-mb-2">
      The hot amygdala is also how the yips are created neurologically. Studies
      done by Dr. Richard Davidson, director of the Laboratory for Affective
      Neuroscience at the University of Wisconsin–Madison, demonstrate that
      thinking new and different thoughts creates new neural pathways. When we
      change our thinking to support our happiness, the negative neural pathways
      shrink and the positive neural pathways widen. This makes it easier and
      more automatic for us to think more positively.
    </div>
    <div class="p-mb-2">
      You can learn to have far greater control over what you remember. Have you
      ever had a discussion with a high school friend about some long-past event
      that you remember as being either extremely positive or extremely
      negative? The memory is crystal clear in your mind. You can recall every
      detail of the incident—down to what you were wearing, how the room smelled
      and what music was playing. Then you find out that your friend barely
      remembers what you are talking about.
    </div>
    <div class="p-mb-2">
      What’s the explanation? Certainly, the event occurred. But the event had a
      bigger emotional impact on you at the time—embarrassment or joy—than it
      had on your friend. As a result, the memory lives in each of you far
      differently. For you, it is written in indelible ink; for your friend, it
      exists as a faded pencil mark. Perhaps one of you overreacted or one of
      you underreacted, but the emotional impact of the event at the time
      determined the degree to which you each stored it in your memory.
    </div>
    <div class="p-mb-2">
      When we are neutral, factual or objective in our post-shot reaction, the
      brain won’t store the memory of the shot in the amygdala as strongly.
      That’s a good way to react to bad shots, and a bad way to react to good
      shots. Some players need to be better at storing positive shots; others
      need to learn to manage their reaction to their bad shots. Often the
      negative response is so automatic that it’s difficult to break the habit.
      Some golfers benefit from training themselves to say something factual
      after an undesirable outcome: If the ball missed the cup on the left edge,
      instead of saying, “I can’t putt,” you could respond with something like,
      “I stroked the ball really solidly.
    </div>
    <div class="p-mb-2">
      Others do better to start singing or humming right after they finish the
      swing or stroke. Suzann Pettersen, whom we coached for several years,
      would start counting immediately after a swing to break her anger habit.
      Sometimes at Tour events she would come up close to the ropes and say,
      “One thousand twenty-four, one thousand twenty-five, one thousand
      twenty-six”! Suzann, like many players, at first thought the emotional
      management skills were only for etiquette reasons. But when the players
      understand how it affects their performance, confidence, energy level,
      ability to swing well, decision-making and visualization—then it becomes a
      lot more interesting and important to them.
    </div>
    <div class="p-mb-2">
      Write your great golf shots in your memory in permanent ink. Tattoo them
      onto your brain. Have them live in you as an endless celebration of the
      joy this game can bring. The bad shots are something that happens—just a
      part of life, like a parking ticket or a rainy day. In no way should those
      shots ever be regarded as more important than the good shots. Remember,
      you didn’t stop baking cakes just because the stove burned you once. The
      mem“ory of how delicious the cake tasted lives too strongly in you to ever
      let that happen. Make your great golf shots your emotional dessert. Savor
      the flavor—and remember them.”
    </div>
  </div>
</template>

<script>
export default {
  name: "Memory Control",
  components: {},
};
</script>
