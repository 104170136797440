<template>
  <Panel
    header="Trust It: Play Box Training"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        PLAY BOX EXERCISES We have found that for most golfers, mastering the
        Play Box is a step-by-step process, starting with understanding and
        ending with execution. Here are four steps for mastering your Play Box:
      </div>
    </div>
    <Panel header="Step 1" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          Discover the difference between thinking and sensing. In other sports,
          this happens by itself. The ball comes at you and you catch it. In
          golf we need to create this performance state since the ball is
          waiting for us to initiate the action.
        </div>
        <div class="p-mb-2">
          Put a club or a string between the Play Box and the Think Box. Hit ten
          different shots and make sure you have stopped thinking when you step
          across the club or string into the Play Box.
        </div>
        <div class="p-mb-2">
          Toss a ball in the air. First think about doing it, then just do it.
          Next time you eat, think about using your fork for a few bites and
          then eat without thinking.
        </div>
        <div class="p-mb-2">
          Throw a few balls into a basket. Do this until you sense a freedom of
          motion. Now step into the Play Box and hit five shots with that
          physical and mental freedom. Make striking the ball a natural,
          instinctive act. Repeat one more time. Go to the putting green and do
          the same for a five-foot putt.
        </div>
      </div>
    </Panel>
    <Panel header="Step 2" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          Can you pay complete attention to something sensory based the entire
          time you are in the Play Box? Many golfers need to do Play Box
          Awareness exercises to learn to pay attention. Learn how to be totally
          present for the few seconds required to hit a shot.
        </div>
        <div class="p-mb-2">
          Hit three balls, seeing the trajectory in your mind as you swing.
        </div>
        <div class="p-mb-2">
          Hit three balls while humming a tune that makes you happy.
        </div>
        <div class="p-mb-2">
          Hit three balls while feeling a low center of gravity.
        </div>
        <div class="p-mb-2">Hit three balls, seeing a spot on the ball.</div>
        <div class="p-mb-2">Hit three balls, feeling a relaxed jaw.</div>
        <div class="p-mb-2">
          Hit three balls while counting or reciting a poem.
        </div>
        <div class="p-mb-2">Hit three putts, seeing the line of the putt.</div>
        <div class="p-mb-2">
          Hit three putts, listening to the sound of impact.
        </div>
        <div class="p-mb-2">
          Hit three putts, feeling softness in your shoulders.
        </div>
        <div class="p-mb-2">
          Hit three pitch shots, seeing a bright orange landing spot in your
          mind as you swing.
        </div>
        <div class="p-mb-2">
          Hit three pitch shots, listening to the silence inside your head as
          you swing.
        </div>
        <div class="p-mb-2">
          Hit three shots, feeling the tension level in your arms as you swing.
        </div>
        How well could you stay focused with the awareness? Was it easier with
        some shots than others? Take notes about what works and what doesn’t
        work for you to pay full attention in the Play Box. This is practicing
        being present.
      </div>
    </Panel>
    <Panel header="Step 3" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          What sensory awareness allows you to be most engaged with the shot? Is
          it visual, auditory, tactile or a combination ? The only way you will
          find out is to start exploring.
        </div>
        <div class="p-mb-2">
          Hit three shots, seeing the ball flight in your mind’s eye.
        </div>
        <div class="p-mb-2">Hit three shots, feeling the grip pressure.</div>
        <div class="p-mb-2">
          Hit three shots, listening to the impact. For the first three
          assignments, do the same in chipping and pitching.
        </div>
        <div class="p-mb-2">
          Hit three putts, seeing the ball fall into the cup in your mind’s eye.
        </div>
        <div class="p-mb-2">
          Hit three putts, feeling a low center of gravity.
        </div>
        <div class="p-mb-2">
          Hit three putts while singing or humming aloud.
        </div>
        <div class="p-mb-2">
          Hit three shots, counting backward during your swing.
        </div>
        <div class="p-mb-2">Hit three shots, feeling your feet.</div>
        <div class="p-mb-2">
          Hit three shots, seeing a green neon line moving to the target and
          back to your ball.
        </div>
        <div class="p-mb-2">
          Write down three Play Box Awareness choices that work best for you.
          Are they visual, auditory, tactile/kinesthetic or a combination? Is it
          the same for full shots, short-game shots and putts? This is a
          starting template, so keep being creative with more choices for your
          Play Box!
        </div>
      </div>
    </Panel>
    <Panel header="Step 4" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          How fully engaged can you be in the Play Box? If you are not near to
          100 percent engaged on every shot, you are greatly diminishing your
          chances of hitting good shots. Explore how much time is best for you
          to spend in the Play Box to remain fully engaged.
        </div>
        <div class="p-mb-2">
          How many consecutive shots can you hit being 100 percent engaged in
          the Play Box?
        </div>
        <div class="p-mb-2">
          Hit three shots with a three-second Play Box routine.
        </div>
        <div class="p-mb-2">
          Hit three shots with a six-second Play Box routine.
        </div>
        <div class="p-mb-2">
          Hit three shots with a nine-second Play Box routine.
        </div>
        <div class="p-mb-2">
          Play nine holes with only a Play Box routine and no Think Box routine.
          Pick the target and use your instincts to decide what club to hit and
          what shot to play. What is that like?
        </div>
        <div class="p-mb-2">
          Go on the golf course and choose a Play Box Awareness and notice how
          well you stay committed to it. How does your level of engagement vary
          from shot to shot and from hole to hole? It’s vital to take practice
          onto the course. Rate yourself on your level of commitment to each
          Play Box.
        </div>
        <div class="p-mb-2">
          You are a Play Box Master when: • You are 100 percent present for
          every Play Box on the golf course. • You have identified several
          choices for your best Play Box Awareness. • You have the skill and
          discipline to be engaged for the entire Play Box. • You are consistent
          with the time you spend in the Play Box on the course, and the time
          you spend in the Play Box is with full engagement.
        </div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
      </div>
    </Panel>
    <Panel
      header="Functiona/Masterful Play Box"
      :toggleable="true"
      :collapsed="true"
    >
      <div class="fontSmall taLeft">
        <div class="p-mb-2">Functional Play Box:</div>
        <div class="p-mb-2">
          You can separate thinking from sensing, and you know what to sense in
          the Play Box.
        </div>
        <div class="p-mb-2">Masterful Play Box</div>
        <div class="p-mb-2">
          For eighteen holes, you are able to be 100 percent present in the Play
          Box for every shot.
        </div>
      </div>
    </Panel>
  </Panel>
</template>
