<template>
  <Panel header="Importance" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        As your goals change from generating power to creating finesse, the way
        you use the club also changes, and therefore, so does your technique.
      </div>
      <div class="p-mb-2">
        One of the first things I noticed while digesting the videotape from the
        1994 Players Championship was that the pros’ setup and swing
        characteristics on short shots looked very different from their
        full-power swings. As I began to note all the differences, a thought
        struck me: Why would one setup and swing be the same when the goals for
        each type of shot are so dramatically different?
      </div>
      <div class="p-mb-2">
        Goals: Power Swings vs. Finesse Swings POWER: Maximize clubhead speed,
        Create a penetrating ball flight, Decrease loft at impact (with an
        iron), Take a divot in front of the ball (with an iron) FINESSE: Control
        clubhead speed, Create a soft ball flight, Use the loft and bounce of
        the club at impact, Brush the turf
      </div>
      <div class="p-mb-2">
        This revelation immediately cemented itself as the foundation for my
        Finesse Wedge System. In fact, understanding what’s optimal for power
        and what’s critical for finesse—and realizing that they diametrically
        oppose each other—is the first step toward attaining wedge greatness.
      </div>
      <div class="p-mb-2">
        If there’s just one skill in wedge play that trumps all others, it’s a
        player’s ability to make great contact. There are two types of contact,
        however: 1) ball/club; and 2) club/ground. In the finesse game, you want
        to optimize and be consistent with both. Without this ability, your
        focus, creativity, and confidence will ultimately suffer, and distance
        control becomes next to impossible. To master your ability to produce
        solid contact, you’ll need to absorb and practice the following five
        fundamentals. These are the non-negotiable pillars of my Finesse Wedge
        System. Your short-game lesson starts now.
      </div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
    </div>
  </Panel>
  <Panel header="Fundamental 1: Setup" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        If the goal on short wedge shots is finesse rather than power, and you
        want to optimize both contact and control, what’s the best setup to
        attain it? Check the photos below. This address position (for a standard
        10-yard wedge shot) is 100 percent engineered for finesse, because it
        establishes the low point of the swing arc in front of the ball without
        jeopardizing the way the club is meant to perform. As the first
        “domino,” so to speak, it is critical that we get this right. Let’s go
        through all the setup elements, starting at the bottom and working our
        way up.
      </div>
    </div>
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseSetup1.png"
      class="img3"
      alt="Sieckmann Finesse"
    />
    <div class="accent1 fontSmall p-mb-2">The finesse wedge setup</div>
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseSetup2.png"
      class="img3"
      alt="Sieckmann Finesse"
    />
    <div class="accent1 fontSmall p-mb-2">
      STANCE: Set up with your feet evenly balanced and close together, with no
      more than four or five inches separating your heels. Keep your back foot
      square to the target line, but pull your front foot back a few inches and
      flare it out toward the target. This “flared” lead-foot position will aid
      your balance in the finish and allow your shoulders to remain relatively
      level as they rotate through the ball, both of which are necessary for
      great finesse wedge play.
    </div>
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseSetup3.png"
      class="img3"
      alt="Sieckmann Finesse"
    />
    <div class="accent1 fontSmall p-mb-2">
      BALL POSITION: On a standard-trajectory shot, play the ball a few inches
      inside your back foot. You’ve gotten it right if, after soling your wedge
      on the ground with your arms hanging down just forward of center, the
      shaft leans 3 to 7 degrees toward the target. You’re probably leaning the
      shaft forward already, but only because you’ve made the mistake of
      straightening your lead arm, which creates both tension and shoulder tilt.
      Neither is good for consistent contact or a soft touch.
    </div>
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseSetup4.png"
      class="img3"
      alt="Sieckmann Finesse"
    />
    <div class="accent1 fontSmall p-mb-2">
      GRIP: Your trail-hand grip should be in your fingers and in a “weak”
      position on top of the club, similar to a standard putting grip. I call
      this a “Finesse Grip,” and yes, you should have a different grip for your
      wedge play around the greens than you do off the tee. Check that the V
      formed by the thumb and forefinger of your trail hand points straight up
      at your sternum.
    </div>
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseSetup5.png"
      class="img3"
      alt="Sieckmann Finesse"
    />
    <div class="accent1 fontSmall p-mb-2">
      ARMS: It should feel like your arms are dangling softly from your
      shoulders—so softly that a slight bend remains in your lead elbow. It’s
      also critical that there’s “extension” or a small cup near the top of your
      lead wrist (wrinkles are a good sign). The cup is evidence that your ball
      position, grip, and arm hang are correct. Having and maintaining this cup
      in the lead wrist is key to making a correct backswing.
    </div>
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseSetup6.png"
      class="img3"
      alt="Sieckmann Finesse"
    />
    <div class="accent1 fontSmall p-mb-2">
      SHOULDERS: Your finesse grip, along with the correct arm hang, should set
      your shoulders fairly level with the ground. Setting up behind the ball
      with the kind of shoulder tilt you would use with your driver is a huge
      red flag and will severely limit your performance.
    </div>
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseSetup7.png"
      class="img3"
      alt="Sieckmann Finesse"
    />
    <div class="accent1 fontSmall p-mb-2">
      CHEST: Much like your stance, your chest should also be slightly rotated
      open to the target. Setting up in this manner not only moves the low point
      of your swing arc forward and in front of the ball, it also helps you
      rotate the toe of the club skyward in your backswing, which optimizes the
      way the club interacts with the turf through impact. Picture a flashlight
      attached to the middle of your sternum, and try to shine its light on the
      ground a few inches in front of the ball.
    </div>
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseSetup8.png"
      class="img3"
      alt="Sieckmann Finesse"
    />
    <div class="accent1 fontSmall p-mb-2">
      HEAD: From a face-on vantage point, your head should sit squarely over the
      middle of your sternum, which in turn should sit directly above your belt
      buckle. If done correctly, you should be able to trace a straight line
      from your nose through your zipper and the center of the space between
      your ankles. I call this being “stacked up” at address, which creates an
      evenly balanced stance.
    </div>
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseSetup9.png"
      class="img3"
      alt="Sieckmann Finesse"
    />
    <div class="accent1 fontSmall p-mb-2">
      From a down-the-line perspective, check that your weight is over the balls
      of your feet, your legs are tall with just a minimum amount of flex, and
      that both your chest and the line formed by the top of your forearms are
      aligned similarly to your feet (open).
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Study these pictures and note what each feature looks like and, when you
        practice, what each feature feels like. It shouldn’t take you long to
        get them all right. My suggestion? Practice setting up in front of a
        full-length mirror, focusing on one setup element at a time and using
        these photos as a reference. Start with a face-on view (with the mirror
        directly in front of you), and then finish with a down-the-line view
        (with the mirror off to your immediate right if you’re a right-handed
        player, and to your immediate left if you’re left-handed). From the
        down-the-line perspective, check that your weight is over the balls of
        your feet, your knees are “tall,” with just a minimum amount of flex,
        and that you have enough hip hinge so that your arms hang directly down
        from your shoulders.
      </div>
      <div class="p-mb-2">
        Don’t be satisfied with nailing some of these setup fundamentals. You
        need them all. Each of them plays an essential role in the fundamentals
        to come and the overall functionality of both the club and your swing.
      </div>
    </div>
  </Panel>
  <Panel
    header="Fundametal 2: Swing on Plane and with Open Clubface"
    :toggleable="true"
    :collapsed="true"
  >
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseSwing1.png"
      class="img3"
      alt="Sieckmann Finesse"
    />
    <div class="accent1 fontSmall p-mb-2">
      Jeff Overton’s finesse backswing. Notice how both his hands and the
      clubhead travel on the shaft plane, and how his hips remain extremely
      stable. Check the logo on the side of his pants near his trail hip. It
      never moves.
    </div>
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseSwing2.png"
      class="img3"
      alt="Sieckmann Finesse"
    />
    <div class="accent1 fontSmall p-mb-2">
      STEP 1: Get into your finesse address position (you can perform this drill
      without a ball). Take your trail hand off the grip and put it on your
      trail hip. Check that your lead wrist is still cupped. Swing the club
      straight back and up with your lead arm. As you do, keep an eye on your
      lead wrist and maintain its cup the whole way. This is what correct
      lead-arm movement feels like in a finesse swing. STEP 2: Go back to your
      setup. Reverse the drill by placing your lead hand on your lead thigh
      while gripping the club with your trail hand only. Swing your trail hand
      back up the plane circle without allowing your elbow to separate from your
      side. You’ll sense that your elbow must fold and your arm must rotate
      (clockwise) to keep the clubhead on the circle with the toe pointing
      skyward. Congratulations! You just experienced external rotation and the
      perfect trail-arm movement for a finesse backswing.
    </div>
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseSwing3.png"
      class="img3"
      alt="Sieckmann Finesse"
    />
    <div class="accent1 fontSmall p-mb-2">
      Champions Tour player Tom Pernice Jr. nailing an important checkpoint in
      the finesse wedge swing. When the shaft is horizontal in the backswing, it
      points parallel to the target line, with the toe pointing up and the
      clubhead “hiding” his hands.
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Swinging the club on plane—that is, on a circle tilted the same number
        of degrees as the lie angle of your wedge (just imagine a circle resting
        on top of the shaft)—is the single most effective way to deliver the
        club for both finesse and control. The clubhead and your hands must both
        travel on this circle during your backswing and as you deliver the club
        into impact. Swinging on plane like this, minus any egregious errors,
        produces the ideal angle of attack for pure contact.
      </div>
      <div class="p-mb-2">
        To show you what an on-plane backswing looks like, I’ve enlisted the
        help of PGA Tour player and former Ryder Cupper Jeff Overton, a student
        of mine since 2012. (Several of my Tour clients will help demonstrate my
        systems throughout this book.) Notice in the picture below how Overton’s
        hands and clubhead stay very close to the circle from the start of his
        takeaway to the end of his backswing, and how he allows the clubface to
        rotate open to the point where the toe of the club is pointing toward
        the sky when he reaches the top. Combining an on-plane backswing with a
        neutral to open clubface is the key to being able to release the club
        correctly on the downswing (more on that in Fundamental No. 4). The net
        effect of this motion is greater versatility and better use of the
        bounce in tough lie conditions.
      </div>
      <div class="p-mb-2">
        Your Arm Swing Is Key To nail the backswing and get both the handle and
        the head of the club moving up the plane simultaneously with the toe
        rotating open, you need to come to terms with two specific arm
        movements. The first is to maintain the cup that you establish in your
        lead wrist at address all the way to the top. If it disappears, you’ll
        likely be “under” plane and in danger of poor contact. The second is to
        allow your trail arm to fold at the elbow as it externally rotates,
        which is clockwise if you play right-handed. Everything is essentially
        moving on the circle: the clubhead, your hands, and your trail elbow.
        These moves may sound complicated, but that’s only because you’re
        unfamiliar with the terminology. The following drill will make it seem
        like second nature.
      </div>
      <div class="p-mb-2">
        Single-Arm Swing Drill. Of course, you need both arms to work correctly
        and in unison when you swing. An effective way to blend their respective
        actions is to practice in front of a full-length mirror. Set up to an
        imaginary ball with any of your wedges and the mirror positioned to your
        right (or your left if you’re a left-handed golfer), giving you a
        down-the-line view of your finesse setup and swing. Then, turn your head
        toward the mirror. Note the shaft’s position in the reflection and then
        slowly swing back. If your arm movements are correct, the club will
        crawl up the shaft line and the toe of the club will rotate toward the
        sky. If either the handle or the clubhead deviate from the shaft line,
        start over. Keep at it—this is invaluable feedback. A great checkpoint
        occurs when the shaft is parallel to the ground. Here, it should look
        like the shaft is pointing parallel to the target line, with the toe up
        and the clubhead hiding your hands.
      </div>
      <div class="p-mb-2">
        Matters of Style I’ve coached PGA Tour Players Tom Pernice Jr. and
        Charlie Wi since 1996. I’ve armed them with the same information, same
        practice drills, and same fundamentals, but their finesse wedge swings
        don’t look all that similar (at least to the untrained eye). That’s
        because they’ve applied their own style to their finesse swing, which is
        certainly acceptable as long as you don’t deviate from the five
        fundamentals. You can turn your upper body very little in the backswing
        and therefore have a narrower arc and more wrist hinge (like Pernice,
        and Seve back in the day), or you could favor more upper-body rotation
        so that your arms stay in front of you (like Wi). The first style looks
        a little free and wristy, while the second looks more dead-handed, but
        it’s possible to trace the ideal plane and deliver the club correctly
        with either. It’s simply a matter of what makes you feel most
        comfortable and in control.
      </div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
    </div>
  </Panel>
  <Panel
    header="Fundamental 3: Use Finesse Sequence"
    :toggleable="true"
    :collapsed="true"
  >
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseSequence1.png"
      class="img3"
      alt="Sieckmann Finesse"
    />
    <div class="accent1 fontSmall p-mb-2">Power sequence.</div>
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseSequence2.png"
      class="img3"
      alt="Sieckmann Finesse"
    />
    <div class="accent1 fontSmall p-mb-2">Finesse sequence.</div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Great setups and backswings fuel the potential for success, but there’s
        still the matter of your downswing, and without the right information,
        it’s easy to fall off track. I’ll start your downswing lesson by quoting
        a paragraph from my Short Game Notebook: “The downswing necessitates
        nothing more than letting the club fall back to the ground in a circle
        as if pulled by gravity, and letting your chest rotate to support the
        motion of the swing. There’s a natural weight shift, or energy flow,
        toward the target as the lower body remains level and relatively quiet
        until the club passes it, allowing the clubhead to release under its own
        momentum.”
      </div>
      <div class="p-mb-2">
        Sounds pretty tame, doesn’t it? That’s because it is! It’s finesse—the
        smooth-flowing sequence of downswing events that makes great wedge
        players great and the rest just mediocre. It’s what I saw in the video
        from the Players Championship and what I’ve preached every day since.
        Having a feel for the right sequence of movement in order to get the
        club and ball to do what you want them to do is critical to being great
        around the greens.
      </div>
      <div class="p-mb-2">
        As mentioned in Chapter 1, the Titleist Performance Institute has used
        computer-mapping technology to measure what, when, and how fast
        everything moves in relation to everything else during the swing.
        Science has quantified exactly what the best players in the world do to
        both generate power and great ball flight, not to mention produce those
        magical shots around the green. Swing sequence is critical because it
        affects the speed of your swing, the location of the bottom of your arc,
        and the effective loft and bounce of your club. Even though different
        sequences do exist in the short game, the ones recognized as the best of
        the best (i.e., Luke Donald, Tom Pernice Jr., Padraig Harrington, Corey
        Pavin, and Steve Stricker) all have the exact same 3-D kinematic
        sequence pattern. You can’t argue it—it’s fact.
      </div>
      <div class="p-mb-2">
        In an efficient power swing (see photo below), the hips reach maximum
        speed first and then decelerate to pass their energy to the torso. Then
        the torso decelerates, passing energy to the arms, which decelerate to
        ultimately pass energy to the hands and the clubhead. This kinematic
        sequence is undisputed in golf teaching and research. In contrast, a
        finesse downswing begins with a slight casting motion in which the club
        moves earlier and faster than the arms, and then the torso and arms
        immediately outpace the hips. This is the opposite of what you do in
        your full swing.
      </div>
      <div class="p-mb-2">
        Notice in the finesse sequence how I initiate my downward movement by
        moving the club first, before my body. Think of it as a gentle casting
        motion or, to quote my Notebook, “letting gravity be your friend.” You
        can see how my hips don’t really turn until after the club gets back in
        front of my body.
      </div>
      <div class="p-mb-2">
        In a finesse swing the feeling you’re after is that your arms are soft
        and relaxed as they smoothly swing the clubhead past your lower body
        while your chest catches their momentum and rotates toward the target at
        the same pace.
      </div>
      <div class="p-mb-2">
        The role of your lower body changes completely for a short shot. It’s no
        longer a power generator, but a provider of stability and balance. If
        you hit your driver this way, the ball would go nowhere. A finesse
        sequence is inefficient for creating speed, but optimal for controlling
        it. Essentially, great wedge players are weak on purpose.
      </div>
      <div class="p-mb-2">
        The lesson: reverse your swing sequence for better contact and touch
        around the greens and save your power sequence for the tee box.
      </div>
      <div class="p-mb-2">
        The Lexicon of Wedge Play Thanks to outfits such as Titleist Performance
        Institute, science has proven that power and club performance are
        largely determined by how you sequence your downswing. Therefore, I
        think we can do the golf world a favor and ditch the archaic terms
        “chipping” and “pitching.” To me, these words entice players to use
        different arm swings to control distance and trajectory—there’s only one
        correct arm swing movement. Plus, they say nothing about sequence. My
        suggestion is to replace chipping with “finesse wedge” and pitching with
        “distance wedge.” You’re either going to be in a finesse mode or a power
        mode, depending on the speed requirement of the shot and what you want
        the ball to do. By using these terms you’ll automatically prioritize the
        correct movement. Everybody’s ability to generate speed is different,
        but let’s use 30 yards to the green as a rough cutoff point; anything
        inside 30 yards is a finesse wedge and anything 30 yards or longer is a
        distance wedge or mini-power swing.
      </div>
    </div>
  </Panel>
  <Panel
    header="Fundamental 4: Let the Clubhead Release"
    :toggleable="true"
    :collapsed="true"
  >
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseRelease1.png"
      class="img3"
      alt="Sieckmann Finesse"
    />
    <div class="accent1 fontSmall p-mb-2">
      Like all great wedge players, PGA Tour player James Driscoll allows the
      clubhead to pass his hands through impact. Along with an on-plane
      delivery, this so-called “mistake” is one of the secrets to producing
      crisp, dig-free contact.
    </div>
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseRelease2.png"
      alt="Sieckmann Finesse"
    />
    <div class="accent1 fontSmall p-mb-2">
      Tom Pernice Jr. demonstrating a picture-perfect finesse wedge finish. The
      clubhead has passed his hands, his lead elbow has folded and moved around
      his side, and his lead wrist has increased its cup.
    </div>
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseRelease3.png"
      alt="Sieckmann Finesse"
    />
    <div class="accent1 fontSmall p-mb-2">1. Get into your finesse setup.</div>
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseRelease4.png"
      alt="Sieckmann Finesse"
    />
    <div class="accent1 fontSmall p-mb-2">
      2. Take your lead hand off the grip and rest it on your front thigh.
    </div>
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseRelease5.png"
      alt="Sieckmann Finesse"
    />
    <div class="accent1 fontSmall p-mb-2">
      3. Swing your trail arm back, keeping the club and handle on the shaft
      plane. Remember to rotate your arm clockwise and fold your elbow!
    </div>
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseRelease6.png"
      alt="Sieckmann Finesse"
    />
    <div class="accent1 fontSmall p-mb-2">
      4. From the top, get the clubhead moving earlier and faster than your
      arms. Support this movement with chest rotation and ultimately by allowing
      your hips to turn.
    </div>
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseRelease7.png"
      alt="Sieckmann Finesse"
    />
    <div class="accent1 fontSmall p-mb-2">
      5. Continue to swing your right arm past your lower body and smoothly
      accelerate the clubhead past your hands. It’s critical that you turn your
      chest through impact to support the arm swing and allow the club to stay
      in front of you. Freeze your finish position. You know you did it
      correctly if the grip points at your belly and the face is slightly open.
    </div>
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseRelease8.png"
      alt="Sieckmann Finesse"
    />
    <div class="accent1 fontSmall p-mb-2">
      6. Lastly, regrip the club with your lead hand and take note of how this
      requires you to both fold your lead elbow and move it around the side of
      your torso. When you’re done, you’ll notice that your lead wrist is
      cupped, just like it should be.
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Most players know that bounce is a design feature intended to prevent
        the clubhead from digging into the turf, but they aren’t really sure how
        to make it work for them. That’s because they incorrectly believe that
        they’re supposed to keep their hands ahead of the clubhead throughout
        the downswing. Unfortunately, this unnatural move ruins the way the club
        interacts with the turf, causing it to dig and creating a domino effect
        of other technical problems. I’m amazed at the widespread use of this
        technique, and the alarming number of teachers who preach it.
      </div>
      <div class="p-mb-2">
        On more than one occasion, Titleist master wedge designer Bob Vokey has
        told me, “Bounce is your friend.” The only way to unlock its benefits is
        to let the clubhead pass your hands through impact so that more of the
        sole comes in contact with the turf.
      </div>
      <div class="p-mb-2">
        Now, before you start flipping the club like you’re cooking flapjacks on
        a Saturday morning, understand that letting the clubhead release is more
        the result of gravity than effort. The correct mindset and motion to
        effectively use the bounce is as follows: 1. Remain tension-free in your
        setup and throughout your swing. Let the club feel “heavy” in your
        hands. 2. As you transition from backswing to downswing, do it slowly
        and let gravity and the weight of the club conspire to drop the clubhead
        down the shaft plane. 3. Through impact, swing the clubhead naturally
        and past your hands into a great finish position.
      </div>
      <div class="p-mb-2">
        To see what the proper release and finish position look like, check the
        sequence of PGA Tour player and student James Driscoll below. Notice in
        frame 1 how the shaft points at the middle of a triangle formed by his
        shoulders and arms just past impact. Here, the clubhead is even with his
        hands, but in the next frame it’s way ahead—gasp! Also, notice that the
        clubface is open or pointed skyward in the finish (frame 3). This is
        evidence that the trail arm has rotated clockwise through impact, which
        is the dead opposite of what happens in the power swing. This
        “open-to-open” release of the clubhead allows Driscoll to simply brush
        the grass under the ball because the sole contacts the turf, not the
        leading edge. If you can do this, you can say good-bye to the
        debilitating chili-dip for good.
      </div>
      <div class="p-mb-2">
        Using the bounce of the club produces minimal ground interference (no
        divot necessary in a finesse swing), which allows you to swing down into
        the turf without concern. Even if you accidently hit a little behind the
        ball, it will still go up in the air and end up next to the hole,
        increasing your margin of error and decreasing your stress level.
      </div>
      <div class="p-mb-2">
        Good wedge players never look unnatural or “flippy” because they turn
        their chest to support the release of the club. As they move into the
        finish, they remain tension-free, allowing their lead elbow to fold and
        rotate around their side, and their lead wrist to extend or cup.
        Remember those wrinkles.
      </div>
      <div class="p-mb-2">
        The Best Drill I Know Looking at pictures of the correct release is one
        thing, but you have to get a feel for it in order to reap its benefits.
        One of my favorite exercises—for this fundamental and for finesse wedge
        play in general—is to hit short shots using only your trail arm. I call
        it the Trail-Arm-Only Drill. I know it sounds simplistic, but it’s a
        powerful drill. Hitting solid shots this way automatically creates the
        correct sequence and release in your swing, without any thinking on your
        part. Follow these steps:
      </div>
    </div>
  </Panel>
  <Panel
    header="Fundamental 5: Let your Energy Flow Toward the Target"
    :toggleable="true"
    :collapsed="true"
  >
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseEnergy1.png"
      alt="Sieckmann Finesse"
      class="img3"
    />
    <div class="accent1 fontSmall p-mb-2">
      The head movement and forward energy flow of Jeff Overton is typical of
      elite wedge players. You can see how much his head moves forward between
      address and the top of his backswing, and how it basically stays in place
      (yet remains forward) all the way into his release.
    </div>
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseEnergy2.png"
      class="img3"
      alt="Sieckmann Finesse"
    />
    <div class="accent1 fontSmall p-mb-2">
      DRILL: Energy Toss Start in your finesse setup while holding a ball in
      your trail hand next to the grip. Let the club fall to the ground and
      place your lead arm behind your back. While keeping your lower body
      stable, swing your trail arm back. Use your athleticism to toss the ball
      underhanded toward the target—just a few yards will do. My guess is that
      you won’t lean back and simply flip your wrist, but rather use your body
      to create momentum in the direction of your arm swing. Check where your
      weight is as you complete the motion. This is what it feels like to have
      your energy flowing toward the pin.
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        One of the things I remember most about practicing wedge shots as a
        struggling mini-tour player was trying to keep my head still. I had been
        told this was important and it kind of made sense, but the video from
        the Players Championship showed the opposite. Good wedge players don’t
        keep their head steady at all. They move it toward the target a few
        inches during the backswing in what’s essentially a reverse weight
        shift; then they either keep their head still from that point on or move
        it even farther forward in the downswing. Obviously, if their head is
        moving that way, their weight is also shifting in the same direction.
      </div>
      <div class="p-mb-2">
        Why would they do this? I didn’t have an answer immediately, but after
        years of teaching and accumulating video of all types of players, a
        pattern became evident. Players who suffer poor contact often move their
        head away from the target when they swing, moving the low point of their
        swing arc along with it, an error that leads to fat and thin mis-hits.
        Conversely, players who strike the ball properly reverse-shift in the
        backswing, which steepens the delivery and gets their weight flowing
        toward the pin. This moves the swing’s low point forward, producing
        ball-first contact.
      </div>
      <div class="p-mb-2">
        Watch how PGA Tour Player Jeff Overton finesses the ball close to the
        pin in a special video. Visit jsegolfacademy.com/index.php/jeff-overton.
      </div>
      <div class="p-mb-2">
        I’ve never heard another coach teach this, but the video evidence is
        indisputable: Energy flowing to the pin during a finesse swing is a
        fundamental key for creating a crisp, controlled strike. For decades or
        longer, those with great touch around the green have developed this
        athletic move innately. A good way to get a feel for this forward energy
        flow is to toss a ball underhanded. Follow these steps.
      </div>
      <div class="p-mb-2">
        Moving energy toward the target by allowing your head to drift forward
        during your swing is an essential motor program on finesse shots. Always
        check that the majority of your weight is over your front foot when you
        complete your swing. If it isn’t, your energy error will severely limit
        the quality of your contact.
      </div>
    </div>
  </Panel>
  <Panel header="X" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
    </div>
  </Panel>
</template>
