<template>
  <Panel header="Introduction" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        The downswing in golf—at least the part of the swing that starts at the
        top and ends at impact—only takes about one-fifth of a second.
        Consequently, even the most talented golfers have a difficult time
        bailing out a bad backswing and still returning the club squarely and
        solidly to the ball.
      </div>
      <div class="p-mb-2">
        Make no mistake, the downswing is largely a reflexive action. Some
        players do, however, claim that they are very aware of concentrating on
        starting the downswing by replanting their left heel or by using a
        trigger involving the arms, shoulders, or hips—either rotating or
        clearing them in a counterclockwise direction or shifting them laterally
        toward the target.
      </div>
      <div class="p-mb-2">
        I do accept that players can consciously trigger the start of the
        downswing action. However, I would rather have players tell me that they
        practiced all the vital downswing movements, using drills to groove
        individual actions until they became so automatic that even the trigger
        itself operated on automatic pilot.
      </div>
      <div class="p-mb-2">
        One thing that can really cause a player to falter and hit a bad shot
        after making a technically sound backswing is bad timing, tempo, and
        rhythm. When the beat of the downswing is off, it negatively affects the
        sequencing of the body and club. This is particularly true in the case
        of a two-plane swinger who must depend more on the timing between the
        turning of the body and the sequencing of the arms to hit good golf
        shots.
      </div>
      <div class="p-mb-2">
        Another thing that can disrupt the continuity of the downswing is
        allowing, say, a two-plane downswing fundamental to be incorporated into
        a one-plane action or vice versa. For example, at the start of the
        downswing a one-plane swinger should turn the left hip and left
        shoulder. However, if that same one-plane golfer triggers the downswing
        by shifting the hips laterally, as a two-plane golfer should do, he or
        she is likely to fail to return the club squarely to the ball and will
        hit an offline shot as a result.
      </div>
      <div class="p-mb-2">
        Just from this one example, I think you will understand how important it
        is for you to marry one-plane downswing fundamentals with one-plane
        backswing fundamentals and two-plane downswing fundamentals with
        two-plane backswing fundamentals. This is not rocket science, but it is
        to be taken seriously if you want to evolve as a player and hit good
        shots consistently.
      </div>
      <div class="p-mb-2">
        Read the following instructions that refer to the start of the
        downswing, impact, and follow-through so thoroughly that you can feel
        the motion and clearly see it coming to life in your head. Only then
        will you be equipped to swing into the proper positions virtually
        automatically.
      </div>
    </div>
  </Panel>
  <Panel
    id="DownswingShoulders"
    header="Shoulders"
    :toggleable="true"
    :collapsed="true"
  >
    <img
      src="@/assets/images/hardy1/hardyDownswingShoulders1.png"
      alt="Hardy Posture"
    />
    <div class="accent1 fontSmall p-mb-2">
      In studying this photograph of the start of the one-plane downswing, you
      can see
      <span class="accent2"
        >how hard the hips and shoulders are initiating the downswing.</span
      >
      The arms are very passive. To increas power of turn try
      <a href="#ObliqueAbsStrengthener">Oblique Abs Strengthener</a> Drill.
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        The motor or
        <span class="accent1"
          >power generator in the one-plane downswing is your torso (shoulders,
          chest, and stomach).</span
        >
        You must
        <span class="accent1"
          >turn your shoulders (and your entire torso with them) along with your
          left hip to start your downswing.</span
        >
      </div>
      <div class="p-mb-2">
        Now, I am sure that a lot of you are asking yourself, "Isn't the
        downswing supposed to start with some movement of the lower body?
        Shouldn't I hold back the upper body until later in the downswing? And
        if I don't hold the shoulders back, won't they automatically jut outward
        (over the top) so that I hit a big pull or a slice?"
      </div>
      <div class="p-mb-2">
        If this is what you are thinking, you've done a great job of remembering
        your previous golf lessons. Yes, you have probably heard that you should
        start the downswing with some form of lower-body movement, which there's
        no need for me to describe in detail here. But let's remember, we're
        making the one-plane swing here, and the parts of the one-plane swing
        are different from those of the two-plane swing, just as a Lexus's fuel
        pump is different from that of a Mercedes. And in the one-plane swing,
        the part that starts the downswing is your torso and your left hip.
        <span class="accent1"
          >The secret is to turn the torso as hard as possible while maintaining
          maximum body control and balance.</span
        >
        Incidentally, by the <span class="accent1">term torso,</span> I mean
        <span class="accent1"
          >both the upper torso (particularly the left shoulder), the middle
          torso (controlled by the oblique abdominal muscles), and the
          hips.</span
        >
      </div>
      <div class="p-mb-2">
        It's true that if you turn your shoulders rapidly at the start of a
        two-plane downswing you will go over the top in relation to your target
        line. But you don't have to worry about going over the top in the
        one-plane downswing (providing you execute correctly). Why won't you go
        over the top? Because, as you'll remember, in the one-plane golf swing
        the shoulders and arms are turning on the same single plane, a plane
        running from the top of your shoulders down to the ball or in a zone
        slightly outside of the ball, as described earlier.
        <span class="accent1"
          >As long as your spine angle is bent over, your shoulders are turning
          on the correct plane well within the zone (never flat beyond the
          zone), and your arms and the club are correctly positioned across your
          chest, you cannot possibly go over the top no matter how hard or how
          fast you turn your shoulders.</span
        >
      </div>
      <div class="p-mb-2">
        Your shoulders and torso cannot turn fast enough as long as they are
        turning within the zone. Incidentally, on the downswing as on the
        backswing, the bottom of your spine is the hot spot, so to speak, that
        your shoulders and torso will unwind around. You'll be releasing the
        pent-up tension you built on the backswing. Then, well after impact on a
        full swing, your torso will keep winding in the follow-through until you
        actually start to feel that tension in the lower spine again.
      </div>
      <div class="p-mb-2">
        In the one-plane downswing (as well as in the backswing)
        <span class="accent1"
          >your shoulders must dominate because they need to turn much farther
          than your lower body does in that span of time from the top to
          impact,</span
        >
        which is a mere fraction of a second. Remember,
        <span class="accent1">at the top</span> of the backswing,
        <span class="accent1"
          >you turn your shoulders roughly twice as much as your hips</span
        >
        — 90 degrees or more for your shoulders versus approximately 45 degrees
        for your hips. <span class="accent1">At impact,</span> depending on your
        flexibility, your hips should be open (anywhere from 35 to 50 degrees)
        and somewhat facing the target. Your shoulders should be only 15 degrees
        or so behind the hips, with the left arm just starting to move across
        the chest. In other words,
        <span class="accent1"
          >your shoulders have nearly caught up with the hips.
        </span>
        To do this, your upper torso <span class="accent1">(shoulders)</span>,
        which was wound up against the hips and lower torso,
        <span class="accent1"
          >must explosively unwind faster than the hips to achieve the ideal
          impact position.</span
        >
      </div>
      <div class="p-mb-2">
        As you make this aggressive shoulder turn back to the ball, I want to
        make sure that you understand the difference between turning your
        shoulders and tilting them. The difference is critical.
        <span class="accent1"
          >When you turn your shoulders, they revolve around your spine at a
          perfect 90 degree angle.</span
        >
        I recommend that you practice making this pure turn of your
        shoulders—from the top of the downswing to the point of impact—in front
        of a full-length mirror. For now, don't give any thought to your arms,
        the club, or anything else. Just try to ingrain that pure turn of the
        shoulders and you'll also notice that the club shaft is moving along the
        same plane as your shoulders, at a 90-degree angle to your spine. If you
        simply retain the more bent-over spine angle we discussed earlier and
        turn your shoulders around the spine, you'll
        <span class="accent1">stay in the 48-inch zone</span>
        and never come over the top.
      </div>
      <div class="p-mb-2">
        The majority of amateurs (and even many professionals) do not make this
        pure rotation of the shoulders around the bent-over spine. Instead of
        turning, they tilt their shoulders from the top by dropping the right,
        or rear, shoulder straight down and raising the left shoulder straight
        up. The instant you tilt your shoulders, your spine will also rise up
        from its original position. How much of a problem is this? Well, it is
        one that even the greatest golfers in the world struggle with mightily
        on many occasions. Let's try a little experiment here to show you what
        is likely to happen when you tilt rather than turn. First, set up in a
        one-plane address and then take the club up to the top. From here, turn
        your shoulders on a plane, at right angles to your spine, until your
        shoulders are back to a square position. Don't do anything with your
        arms or the club but notice where they are. With your shoulders back to
        square, your arms will be about waist high and the grip end of the club
        shaft will be pointing at the target line. You only need to keep turning
        your hips and torso while your left arm rotates and naturally starts to
        move across your chest to deliver the club smoothly and powerfully
        through impact.
      </div>
      <div class="p-mb-2">
        Next, try this. Take the club up to the top of the one-plane backswing.
        Instead of turning the shoulders from here, though, tilt your shoulders,
        so the right drops straight down and the left rises straight up. Notice
        how when you tilt instead of turn, your right elbow drops down to the
        top or in front of your hip bone. The club shaft, meanwhile, is still
        way behind you, the grip end pointing somewhere outside the target line.
        You now have the club positioned in the infamous "stuck­behind-you"
        position. From here, the most likely result will be that you will come
        through impact too much from the inside and with the clubface pointing
        right of target, so you'll hit well right of target. What can also
        happen is that you will sense that you're positioned to hit the ball to
        the right, so you'll make a last-instant attempt to get the clubface
        back to square by flipping your hands through the impact zone. Result? A
        quick hook.
      </div>
    </div>
  </Panel>
  <Panel header="Arms" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        The action of your arms in the one-plane downswing is very similar to
        that of a baseball swing. That is, at the top of the backswing, your
        left arm is across your chest with your right arm behind you, folded
        with the elbow pointing slightly behind you. Now, if you look at any
        great power hitters in baseball, you'll see that, from this position,
        they turn their shoulders and upper torso as hard and as fast as they
        can toward the coming pitch. The torso is like an inner or centripetal
        force whose movement activates the bat, which receives the outer or
        centrifugal effect. When this force and its effect are applied
        correctly, the bat comes through with crashing speed to meet the ball.
      </div>
      <div class="p-mb-2">
        It's the same principle in the one-plane golf swing. You
        <span class="accent1">don't throw your arms and club at the ball;</span>
        rather,
        <span class="accent1"
          >they get thrown by the turn of the shoulders and torso.</span
        >
        There
        <span class="accent1"
          >is some rotation of the left forearm in the downswing through
          impact,</span
        >
        which I will discuss shortly. But beyond that left-arm rotation, your

        <span class="accent1"
          >arms (and your hands) will actually remain very passive until just
          before impact.</span
        >
        The arms, until that moment, should
        <span class="accent1"
          >simply stay in position, with your left arm rotating but still
          staying across your chest and your right arm behind the body ("rt
          elbow same flex as at address").</span
        >
        As your
        <span class="accent1"
          >torso turns around a steady, bent-over spine, it will carry your
          arms, hands, and the club down along the correct plane line toward
          impact.</span
        >
      </div>
      <div class="p-mb-2">
        To most of you, I'm sure that the concept of not using your arms and
        hands actively must seem puzzling. "How will the club get back to the
        ball?" you ask. Well, if you turn your shoulders to dominate the
        downswing while keeping your spine at its original angle, your shoulder
        turn will be in the 48-inch zone. As a result, your arms will swiftly
        and surely be carried in front of you. You do not have to force them
        toward the ball. In fact, to do so would be a critical mistake. If your
        arms were to move in front of your chest as you started down, and then
        you turned your shoulders as hard as you could, you would indeed come
        over the top. Trust me on this for the moment, because we will be
        examining the impact position in detail shortly and everything should
        start coming together for you.
      </div>
      <div class="p-mb-2">
        I want to point out here what I consider to be an erroneous instruction
        that was brought up in Ben Hogan's Five Lessons: The Modern Fundamentals
        of Golf regarding the arms, specifically the right elbow. Hogan's book
        indicated that when starting the downswing, the right elbow should lead
        the arm and drop downward so that it rests in front of the right hip. I
        guess Hogan will never know that in so saying he helped create one of
        the early 21st century's biggest buzz phrases in golf
        instruction—"getting the club stuck behind you."
      </div>
      <div class="p-mb-2">
        Later in his career, however, Hogan gradually got his right arm more
        behind him and against his right side in the downswing, instead of so
        much on the front of his hip. This, in turn, placed the shaft less
        inside and more on plane with a much squarer clubface. I believe this to
        be the reason he was able to develop a downswing in which his torso turn
        could whip his arms and the club through impact without his having to
        work the clubface overly with his hands through impact.
      </div>
      <div class="p-mb-2">
        There are a couple of terms I should clarify that relate to your arms
        (and the club) as you approach impact. These terms are
        <span class="accent1">getting underneath the ball</span> and
        <span class="accent1">getting on top of the ball.</span> Getting
        underneath refers to a position in which your
        <span class="accent1"
          >arms and the club are approaching the impact zone on too flat a
          plane, usually with the clubface too open and the entire swing too
          much from inside the target line</span
        >. This is a direct result of one or more of
        <span class="accent1">three mistakes</span>. The most common mistake is
        <span class="accent1">tilting the right shoulder down</span> at the
        start of the downswing instead of turning the shoulders. The second
        mistake is the one I have just mentioned, getting the
        <span class="accent1"
          >right elbow too far in front of the right hip</span
        >
        in the downswing. The third mistake is for the
        <span class="accent1"
          >left arm to fail to rotate back, counterclockwise, either the right
          amount or at the right time</span
        >
        to have the club on plane in the downswing.
        <span class="accent1"
          >The amount the left arm rotates in the downswing is entirely
          dependent on your grip and the amount you rotate (pronate) it in the
          backswing.</span
        >
        It is a usual rule of thumb that the stronger the grip, the less you
        have to rotate the left arm in the backswing and, thus, in the
        downswing. Conversely, the weaker the grip, the more rotation is
        required in both the backswing and the downswing. Therefore,
        <span class="accent1"
          >whatever rotation occurs in the backswing must occur in the
          downswing, and it should occur throughout the downswing as the left
          arm is being swung down the plane by the shoulders into the back of
          the ball.</span
        >
        If you rotate the arm
        <span class="accent1"
          >either insufficiently or too late, the club will be held under the
          plane, with the face too open and approaching the ball too much to the
          inside.</span
        >
      </div>
      <div class="p-mb-2">
        By contrast, when you make an on-plane shoulder turn (rather than a
        tilt) coming down, your arms and the club will be pulled around and down
        on plane as well. There should be no conscious dropping of the arms,
        only a rotation of the left arm. The arms just go along for the ride as
        the shoulders turn on plane.
        <span class="accent1"
          >The overall sense of how your arms work in the one-plane swing is
          that they are constantly rotating and revolving around the body.</span
        >
        However,
        <span class="accent1"
          >because your upper body is bent over, the arms, as they move around
          the torso, ("without consciously being lifted or dropped in relation
          to body, and flexion in rt elbow maintained") are moving up and down
          and then back up a plane line.</span
        >
        If you are a golfer who has been used to making a conscious downward
        movement with your arms in relation to your body, this passive arm
        element will make the downswing feel as though you're coming over the
        top. What you are actually doing is getting the clubhead more out and on
        top of the ball—that is, the clubhead is a little more off the ground
        and much closer to the target line as it enters the impact zone.
      </div>
    </div>
  </Panel>
  <Panel header="Hips" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        If you've heard or read a great deal of golf instruction, you might
        expect to hear about what your hips should be doing in great detail
        here. Actually, that's not the case in the one-plane downswing.
        Remember, your
        <span class="accent1">torso ("shoulders") is the motor</span> of your
        downswing. The
        <span class="accent1">hips do turn but are not the main engine.</span>
        If you recall, your hips should have turned only half as much as your
        torso on the backswing. So they
        <span class="accent1">have only half as far </span>to return on the
        downswing in the same amount of time that the torso makes its much
        larger downswing turn. If <span class="accent1">too much focus</span> is
        placed on the hips, they
        <span class="accent1">can easily overpower the shoulders,</span>
        resulting in
        <span class="accent1">the swing being thrown off-plane</span>. Having
        said that, the
        <span class="accent1">hips do have one important move</span> to make,
        one that serves to
        <span class="accent1">trigger the entire downswing movement.</span> As
        your torso just reaches its position at the top, or
        <span class="accent1"
          >even the slightest fraction before the backswing is completed, start
          turning your left hip back to the left.</span
        >
      </div>
      <div class="p-mb-2">
        Think of the swing movement in terms of counting to three. Ordinarily,
        you'd think of the swing as a one-two movement—that is, you'd count
        "one" for the backswing and "two" for the downswing. In the one-plane
        swing, you should think of the swing as having three parts:
        <span class="accent1">"One"</span> for the
        <span class="accent1">backswing,</span> then
        <span class="accent1">"two"</span> as you
        <span class="accent1">set weight onto your left foot</span> and
        <span class="accent1">slightly begin to turn the left hip</span>
        back to the left, and <span class="accent1">"three"</span> as you
        <span class="accent1">turn your upper body ("shoulders")</span> and hips
        as quickly as possible back down to the ball.
      </div>
      <div class="p-mb-2">
        You may ask, "Why must I start the left hip during 'two,' a fraction
        before I complete the backswing turn?" Here's the important reason why:
        If you recall, in the one-plane backswing, your shoulders and upper back
        are turning on a far more bent over, vertical plane than the hips. As
        you reach the top, you're building tremendous torque between the upper
        body and the hips. When you start turning your left hip even slightly to
        the left as your backswing turn is reaching its completion, you increase
        the tension to such a degree that your torso just has to snap back. The
        hip turn is thus the trigger for your left shoulder to begin the
        downswing. This small, almost imperceptible move is evident in the
        baseball swing as well as the golf swing. Ted Williams, still considered
        by many the best hitter ever, said that he shifted his right hip toward
        the pitcher (Williams was a left-handed hitter) while still turning away
        from the pitcher a fraction of a second before his shoulder and arms
        took his bat all the way back. In this way, Williams triggered the
        release of the pent-up energy in his torso for a full, free cut at the
        ball. It's the same thing in the golf swing.
      </div>
      <div class="p-mb-2">
        Many golfers might argue that they have heard that they should have a
        pronounced pause at the top of the backswing. A pause is great for a
        two-plane swing, but does not belong in a one-plane swing. The reason is
        a one-plane swing has a more constant loading and unloading of body
        torque. I can assure you that any player who exhibits a noticeable pause
        at the top of his or her backswing is not utilizing one of the key
        principles of the one-plane swing—namely, to employ an on-plane, full
        turn of your shoulders against the more horizontal, much lesser turn of
        the hips. If you do this, and then make that slight turn of the left hip
        just as your shoulders get to the top, you can let your shoulders turn
        and rotate through the ball to the finish. In short, you won't be able
        to pause.
      </div>
      <div class="p-mb-2">
        Other than this slight turning of the left hip during the transition and
        the rotation of the shoulders during the downswing, there's really not
        much else you need to concentrate on. As the downswing hurtles toward
        and then past impact, the hips will continue to turn but only in
        response to the powerful in-the-zone turning of your torso and at a much
        slower rate.
      </div>
    </div>
  </Panel>
  <Panel header="Spine Angle" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Ideally,
        <span class="accent1">keep your spine bent forward</span> during the
        downswing at the same fairly steep angle
        <span class="accent1">(approximately 35 to 45 degrees)</span> that you
        started with at address. Remember that it's
        <span class="accent1">okay</span> for the one-plane swinger to
        <span class="accent1"
          >increase the angle slightly during the swing ("transition")</span
        >, to bend slightly more toward the ball. This allows you to make an
        even steeper shoulder turn. But you must make certain that you never
        rise up out of your posture—that is, lift your spine. Doing so in the
        downswing flattens your shoulder turn and puts your shoulder above and
        in front of the correct plane. Once this happens, you'll be forced to
        make any number of last-ditch corrections to get the clubface squarely
        through the ball. Unfortunately, these seldom work.
      </div>
      <div class="p-mb-2">
        When viewed <span class="accent1">from a face-on</span> perspective,
        your spine should remain
        <span class="accent1">as close to centered as possible,</span> as
        opposed to the two-plane swinger's inverted K position, which causes the
        spine to lean back to the right.
      </div>
    </div>
  </Panel>
  <Panel header="Weight Distribution" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        When you turn your left hip just an instant before the start of the
        downswing,
        <span class="accent1"
          >between 50 and 60 percent of your weight should be shifted onto your
          forward foot.</span
        >
        As stated above, this must happen
        <span class="accent1"
          >in the "two" or transition period before the downswing begins, not
          during the downswing.</span
        >
      </div>
      <div class="p-mb-2">
        A problem I see with many amateurs is that they make a big lateral slide
        to the left with their hips and legs on the downswing. They seem to
        think that nearly all their body weight has to get onto their left side.
        This isn't true. That big lateral slide is very helpful for the
        two-plane swing, as I shall point out shortly, but it is disastrous for
        the one-plane. It takes the club off its turning arc and puts it on too
        straight a line for a one-plane swing. The results are usually pushes
        and hooks. It is also very hard on the back, as it forces you into the
        "Reverse C" position that was in vogue some 30 years ago. So avoid any
        hard, jolting moves onto the left side in an attempt to transfer more
        weight than you need to.
      </div>
    </div>
  </Panel>
  <Panel header="Club Movement" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        The fashionable fault in golf today, if there is such a thing, is
        getting the club stuck behind your body as you approach the ball from
        too far inside the target line with the clubface open. Many golfers have
        feared this will happen in the one-plane downswing, particularly in
        light of the fact that you are doing very little with your hands and
        arms. The implication is that if you don't do something with the arms
        they will fall way behind. Let me reiterate: If you execute the
        one-plane downswing correctly, you are never going to get the club stuck
        behind you.
        <span class="accent1"
          >If you turn your shoulders on plane, actively and correctly, and
          rotate your left arm correctly, the club will be carried out and
          around ("over the ball") on the correct plane toward the ball.</span
        >
        You do not need to be concerned about where the club is. Just keep
        turning your shoulders on plane while holding the right elbow behind you
        and rotating your left forearm and the club will take care of itself.
      </div>
    </div>
  </Panel>
  <Panel header="Review" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Start of the One-Plane Downswing: The start of the downswing is a
        critical segment of the golf swing. You must make the right start-down
        move depending on your type of swing, because it is extremely difficult
        to correct your mistakes once you're into the downswing. In order to be
        certain you are clear about what to do to get either the one-plane or
        the two-plane downswing started correctly, a summary of the above
        discussion follows.
      </div>
      <div class="p-mb-2">
        <span class="accent2">Shoulders:</span> Your shoulders and upper torso
        should begin to unwind immediately on the same plane or on a slightly
        steeper plane they turned along on the backswing. It is best to key in
        on the left shoulder turning up and to the left.
        <span class="accent1"
          >The turn of the shoulders must be the dominant move starting the
          downswing,</span
        >
        because the shoulders were coiled much more than the hips and thus have
        the farthest to travel.
        <span class="accent1"
          >Keep the shoulders turning at right angles to your spine.</span
        >
        WARNING: Do not tilt the shoulders at the start of the downswing.
        Tilting the right shoulder straight downward or the left shoulder
        straight upward leaves the club stuck behind the right side of your
        body, which will make the club off plane. Also, tilting instead of
        turning robs power from your main power source.
      </div>
      <div class="p-mb-2">
        <span class="accent2">Arms:</span> The arms must remain
        <span class="accent1">as passive as possible</span>
        at the start of the one-plane downswing. The
        <span class="accent1">left arm remains in position</span> across and
        against the chest.
        <span class="accent1"
          >It rotates counterclockwise as the shoulders and upper torso turn
          around the spine.</span
        >
        The
        <span class="accent1">right arm remains in its folded and back</span>
        position,
        <span class="accent1"
          >with the elbow on your right side ("at same flexion as
          address")</span
        >
        and not in front of it. As the
        <span class="accent1">torso ("shoulder") turns,</span> it
        <span class="accent1"
          >carries the arms down along the desired plane line.</span
        >
        WARNING: Do not make any active movement with your arms other than the
        rotation of the left forearm during the downswing. Your arms and the
        club will be whipped down and through the ball in response to the
        turning force of the shoulders and upper torso. Swinging the arms away
        from the chest at the start of the downswing will push the club outside
        the target line—"over the top."
      </div>
      <div class="p-mb-2">
        <span class="accent2">Hips:</span> The hips should ideally
        <span class="accent1"
          >begin (very slightly) to turn to the left an instant before the
          downswing begins. This move in effect TRIGGERS the downswing movement
          of the upper body.</span
        >
        This very slight hip movement is
        <span class="accent1">the "two" movement</span> in the one-two-three
        count of the swing (backswing, transition hip move, downswing). WARNING:
        Do not try to pause before starting the downswing. While not in and of
        itself a problem, a pause is an indicator that you have not coiled your
        upper body sufficiently against the base of your spine, which in turn
        means that your downswing will lack explosive power.
      </div>
      <div class="p-mb-2">
        <span class="accent2">Spine Angle:</span> The spine should ideally
        <span class="accent1"
          >stay tilted forward from the waist at the same angle (about 35 to 45
          degrees)</span
        >
        as you started with at address. A
        <span class="accent1">slight increase</span> in the angle on the
        downswing is not a flaw for the one-plane swinger. When viewed from the
        front, the spine should
        <span class="accent1">remain centered over the hips.</span> WARNING: Do
        not raise the spine angle at any point in the one-plane swing. Raising
        the spine angle puts the shoulders flat and off plane and forces the
        arms to separate downward from the body in an effort to hit the ball.
      </div>
      <div class="p-mb-2">
        <span class="accent2">Weight Distribution:</span> Weight should be
        <span class="accent1"
          >distributed evenly between the two feet or slightly favor the
          left</span
        >
        or forward leg. The left foot should be flat on the ground as the
        downswing commences. WARNING: Do not make an excessive lateral slide
        toward the target, with a major shifting of weight onto the left foot.
        Doing so puts the club's downward arc too much on a straight line,
        defeating the rotary in-to-in benefits of a one-plane swing.
      </div>
      <div class="p-mb-2">
        <span class="accent2">Club:</span> The club remains passive as the
        downswing starts. Essentially,
        <span class="accent1"
          >it will be carried to impact by the rotation of the left arm and by
          the turning of the torso</span
        >
        along the desired plane line. WARNING: Don't try to manipulate the club
        with your hands as you start down.
      </div>
    </div>
  </Panel>
</template>
