<template>
  <div class="fontSmall taLeft">
    <div class="p-mb-2">
      IN REVIEW: THE FOLLOWING POINTS ARE ESSENTIAL TO A CORRECT RIT RELEASE:
      <br />
      1) There is no pulling with the left arm during the downswing portion; it
      simply drops downward and inward until vertical to the ground. <br />2)
      The right forearm arm drops downward AND SLIGHTLY OUTWARD towards the
      shaft plane line to get to the release zone while keeping the right elbow
      along the right side of the body, NOT IN FRONT of it. <br />3) The right
      wrist begins throwing/flexion prior to the release zone, and prior to the
      clubhead dropping below the no fly zone, creating outward centrifugal
      motion. The left arm's continuing movement downward and inward while the
      right forearm swings half sidearm/half underarm across the torso creates
      centripetal inward force. <br />4) There is NO rotation of the right
      forearm or the clubhead through the impact zone. <br />5) During and just
      after impact the left wrist goes into extension, allowing the right wrist
      to complete its throw/flexion as the right forearm comes around very close
      to the body. <br />6) The left arm folds and gets pushed out of the way;
      inward, around, upward and leftward.
    </div>
    <div class="p-mb-2">
      First let's look at the poto of the RIT player at the top of the
      backswing. Notice the plane of his left arm and clubshaft is not on plane
      with the ball (fig. 9.9). In other words, unlike the LOP release position
      at the top, which is on plane to hit the ball, the RIT at the top of the
      baskswing is on a plane that WILL MISS THE BALL COMPLETELY. It is on a
      plane roughly parallel to the shaft plane that was established at address.
      You do not pull downward and outward at the ball to fix this problem of
      not being on plane to hit the ball. Instead you must undergo a "plane
      shift" or plane drop onto the shaft plane.
    </div>
    <img src="@/assets/images/hardy2/fig9.9.png" class="img4" alt="Alt" />
    <div class="accent1 fontSmall p-mb-2">
      FIG. 9.9. The top of backswing position with the left arm and club for the
      RIT release is not pointed at the ball. It is on a plane roughly parallel
      to the shaft plane line.
    </div>

    <div class="p-mb-2">
      The forces in the RIT release swing are lined up parallel to or on hte
      shaft plane line and MUST REMAIN that way. From the top of the backswing
      there should be ABSOLUTELY NO LEFT ARM PULLING. The entire downswing to
      the zone and through the zone must be dominated by the actions of the
      right arm and the downward and inward dropping of the left arm. Theleft
      arm use is limited to only one issue: to drop downward and inward out of
      the way to the right arm can become the plane arm. The left arm's the
      plane arm starting the downswing and must relinquish that role as soon as
      it can. Any other use of it can only interfere with rhe RIT release. (fig
      9.10).
    </div>

    <div class="p-mb-2">
      The right arm has two duties it must fulfill: to throw the shaft onto the
      right forearm and to become the plane arm. Ther right arm is looking to
      become the downswing hero. As Hogan claimed, he wished he had three right
      hands/arms. The actual motion with the right arm from the top to the zone
      is coordinated with a turning motion of the body. If the body thrusts you
      cannot correctly execute the RIT. More on theis later. But as the body
      turns, the right forearm drops downward and only very slightly outward
      towards the shaft plane line. Be careful NOT TO ALLOW THE RIGHT ELBOW TO
      LEAD IN FRONT OF YOU. This is very important because if it does you cannot
      set up the correct spacing for the radius of the RIT release. We covered
      this in earlier chapters and I suggest you review the material if you need
      tor refresh your memory.
    </div>

    <div class="p-mb-2">
      The right elbow should stay back somewhat aligned with the sedam in a
      shirt running down from the armpit to the waist (fig 9.10). Keeping it
      there while you swing the forearm down and jsut barely otu towards or even
      onto the shaft plane line allows the room for the throwing centrifugal and
      inward centripetal motions to occur. The elbow will move forward away from
      the shirt seam right at impact and just after as the right arm extends as
      the plane arm into the follow through. Howerver, during the initial stages
      of the throw and drop the right elbow stays even with the shirt seam to
      allow for the proper RIT radius. Again the left arm must drop downward and
      inward to vertical while the right arm is carrying out the above motions.
    </div>
    <img src="@/assets/images/hardy2/fig9.10.png" class="img4" alt="Alt" />
    <div class="accent1 fontSmall p-mb-2">
      FIG. 9.10. As the downswing progresses it is vital that the right elbow
      holds tight to your right side at apporximately the seam of the shirt and
      not move in front of the body.
    </div>
    <div class="p-mb-2">
      Now that the swing is entering the release zone notice in the pootos the
      turning of the body, the downward/inward drop of the left arm and the down
      and slightly outward drop of the righ forearm have done their respective
      jobs so the club is parallel to or on the shaft plane line. From here to
      past impact you do not change your efforts in any way from what you did in
      the earlier waist high to waist high drill; keep throwing AND flexing the
      right wrist while getting the left arm down and swinging the right forearm
      and handle inward in a half sidearm/half underarm motion. The left arm is
      going to be pinned as the inside arm beccause of the right wrist throw,
      the left arm drop and the swinging across the body of the right forearm.
    </div>
    <div class="p-mb-2">
      In review again, just after entering the release zone you can notice in
      the photos how the left arm drops downward and inward until it is
      vertical, pointing straight down. At that point the right firearm is
      pointed at the ball. ALso note while the left arm drops downward and
      inward the clubshaft keeps moving outward (fig 9.11). The combination of
      the two motions results in forming a plane pointed at the ball and on the
      shaft plane line.
    </div>
    <img src="@/assets/images/hardy2/fig9.11.png" class="img10" alt="Alt" />
    <div class="accent1 fontSmall p-mb-2">
      FIG. 9.11. Note the left arm dropping down and inward until vertical
      allowing the right forearm and clubshaft to point at the ball.
    </div>
    <div class="p-mb-2">
      Before going further I want to review some and expand a little on the
      right wrist throwing motion and exactly what it does. Even though the
      throw is one CONTINUOUS motion, the throw actually can be best understood
      by dividing it in two halves. The first half is from when you initiate the
      throw before entering the release zone to around impact. That throwing of
      the right wrist does several things: 1) it sends the club outward from
      behind the right arm and body (remember thae part earlier about the "club
      stuck behind you"); 2) it puts the clubshaft onto or "aligned with" the
      right arm plane; 3) it squares the clubface woth the plane/path and 4) it
      starts the clubhead on an outward corcular orbit.
    </div>
    <div class="p-mb-2">
      About the only thing the throw/flexion didn't accomplish was to make the
      right forearm the plane arm. That happens when the left arm completes its
      drop to vertical so the right forearm is pointed at the target line. The
      second part of the throw also does several things: 1) it adds and releases
      speed as it sends the clubhead whipping past the handle; 2) it swings the
      clubhead immediately inwawrd off the target line; 3) it closes the
      clubface to the target but keeps it square to the path/plane and 4) it
      makes the clubhead move in a circular path around and upeward on the shaft
      plane to the left. To allow the right wrist to FULLY finish its flexion,
      the left wrist has to break down and the left arm to fold as the right
      wrist throws and the arms move INWARD, around leftward and upward (fig
      9.13). All these things in the RIT release can be seen (fig. 9.12 and fig
      9.13).
    </div>
    <img src="@/assets/images/hardy2/fig9.12.png" class="img10" alt="Alt" />
    <div class="accent1 fontSmall p-mb-2">
      FIG. 9.12. As the right wrist continues in flexion the clubhead moves on
      an arc around the golfer. The clubface closes to the target but stays
      square to the arc.
    </div>
    <img src="@/assets/images/hardy2/fig9.13.png" class="img10" alt="Alt" />
    <div class="accent1 fontSmall p-mb-2">
      FIG. 9.13. The left arm and wrist must break through impact to allow the
      right wrist to reach full flexion. Note the lack of any roll-over of the
      right forearm
    </div>
    <div class="p-mb-2">
      Allow me to go over this part again. During this second phase, from impact
      to the finish you just keep throwing/flexing the right wrist. This modion
      will cause the left wrist to go into extension (fold backward) through
      impact. Do not fight this!! It must happen in a swinging motion. You may
      have heard this extension or bredaking down of the left wrist is wrong.
      That may be true for an LOP release but it simply is not true for the RIT
      release (fig 9.14). You will be surprised as you view the accompanying
      potos of great players throwing the club through impact. The speed and
      accuracy produced by this throwing/hwipping is tremendous. I cannot
      overstate this. This is the SECRET of the RIT release. It prduces both
      power and accuracy. The power is obvious. The completion of the throw is
      translated into whipping the centripetal force and centrifugal result into
      great speed. This is not leverage, it is speed, and without allowing the
      left wrist to breakdown/extension with the full flexion/throw of the right
      wrist simply "checks" this speed like someone check swinging a baseball
      bat at a pitch he dowsn't like.
    </div>
    <img src="@/assets/images/hardy2/fig9.14.png" class="img10" alt="Alt" />
    <div class="accent1 fontSmall p-mb-2">
      FIG. 9.14. Excellent view of the left wrist going into extension as the
      right wrist fully throws.
    </div>
    <div class="p-mb-2">
      In the LOP release the completion of the rollowver of the forearms during
      impact whips the clubhead past the hands. In the RIT release it's the
      extension of the left wrist and the FULL flexion of the right wrist that
      whips the club by allowing the centripetal and centrifugal motions to
      continue through the zone. Without it you either check the speed or you
      roll over and hit terrible hooks. The throw must complete itself through
      the impact zone without any rollover. The rollover occurs af waist high in
      the follow through. If it happens earlier then you affect both the
      ompletion and the accuracy of the release. The throw keeps the club mofing
      around and square to that path. If you interrupt that throw with a
      rollover you lose BOTH the arc and the square clubface to the arc. If you
      roll over you automatically stop the throw which stops the clubhead moving
      on an arc. Instead of continuing its swing on an arc the clubhead moves on
      a straighter line. At the same time the clubface ABRUPTLY closes to that
      straight line with resulting wild shots left.
    </div>
    <div class="p-mb-2">
      In the correct RIT release zone exit, notice how the clubshaft now shows
      up around to the left of your body either on or parallel to the shaft
      plane line (fig 9.16). Also notice how the clubface has not you been
      closed to the shaft plane line. These two positions are key positions and
      are very important to the correct RIT release.
    </div>
    <img src="@/assets/images/hardy2/fig9.16.png" class="img10" alt="Alt" />
    <div class="accent1 fontSmall p-mb-2">
      FIG. 9.16. The throw with no rollover coubled with the left arm drop
      allows the shaft to whip around the body on or parallel to the shaft plane
      line.
    </div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
  </div>
</template>
