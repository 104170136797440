<template>
  <h2>BEFORE ROUND</h2>
  <div>
    <img
      src="@/assets/images/home/before-round.png"
      alt="BeforeRound"
      class="img1"
    />
  </div>
</template>

<style scoped></style>
