<template>
  <div class="p-d-flex-column">
    <utleyBasics />
    <utleyTechnique />
    <Panel
      header="Adaptations (Utley: Art of the Short Game)"
      :toggleable="true"
      :collapsed="true"
    >
      <utleyAdaptations />
    </Panel>
  </div>
</template>

<script>
import utleyBasics from "@/components/UtleyShortGame/utleyBasics";
import utleyTechnique from "@/components/UtleyShortGame/utleyTechnique";
import utleyAdaptations from "@/components/UtleyShortGame/utleyAdaptations";

export default {
  name: "UtleyShortGame",
  components: {
    utleyBasics,
    utleyTechnique,
    utleyAdaptations,
  },
};
</script>
<style lang="scss"></style>
