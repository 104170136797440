<template>
  <Panel
    header="The Psychology of Putting"
    :toggleable="true"
    :collapsed="true"
  >
    <Panel header="Introduction" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          Tour players spend hours every week working on the fundamentals we've
          been talking about over the last few chapters, making sure they're
          comfortable with the stroke and getting ready to take it out on the
          course for competition. If you have decided to make some changes to
          your own stroke, you'll be doing the same thing on the practice green,
          hitting putts and experimenting with the things I've been talking
          about, finding out if they will work for you.
        </div>
        <div class="p-mb-2">
          But the practice green is different from the first green in a real
          round of golf—whether you're playing in the B-flight of your club
          championship or trying to win a professional tournament. The
          psychology of putting is all about getting your real-world putting
          stroke to look as close to your pressure-free practice green stroke as
          possible, and keeping it that way when the stakes are high. It's about
          understanding that a good stroke is the best you can do, and accepting
          whatever happens after that as a part of the game.
        </div>
        <div class="p-mb-2">
          You're probably thinking to yourself right now, “Wait a second. Isn't
          putting all about making more putts?” Yes and no. When I'm standing
          over a ten-footer for birdie, I'm definitely intending to make it. But
          I also know there are factors I can't control in the process. I could
          roll the ball perfectly, and a dent in the green could throw it off
          line. I've hit plenty of putts that have been—believe it or not—blown
          off line by the wind. If you set up a machine to roll ten identical
          twenty-foot putts along the ideal line at the ideal speed, one or two
          of them wouldn't go in, just because of those imperfections.
        </div>
        <div class="p-mb-2">
          If the sole measuring stick of your putting stroke is the number of
          putts you make, you're going to have some very frustrating days. You
          can hit great putts that just don't go in. You can also stroke the
          ball poorly on some days and slam them in off the back of the cup or
          bounce them in off a spike mark. It's dangerous to get too caught up
          in either kind of day. On the day you're stroking it good but not
          making them, it's tempting to start monkeying with your stroke to try
          to make things happen. That's how putting slumps get started. I work
          with plenty of tour players who are struggling with their putting
          simply because they just got away, bit by bit, from the solid
          fundamentals they used to have. And when you're hitting it poorly but
          making them, it's easy to get overconfident and miss the signals that
          you should be spending more time on the practice green.
        </div>
        <div class="p-mb-2">
          My treatment for the psychological ups and downs players inevitably go
          through in putting is to relegate the idea of making putts to
          secondary importance in the sequence of things to worry about in the
          pre-putt routine. When I look back on my own professional career, I
          see a common thread among the four professional events I won, and it
          stands out like a giant, glowing marker. Three of the four times I won
          a tournament, I had recently had a conversation with Dr. David Cook,
          my sports psychologist and good friend. I was really focused on
          process, not outcome.
        </div>
        <div class="p-mb-2">
          One of the main things David has taught me over the years is the idea
          that you need to immerse yourself in the process of hitting the putt,
          not the outcome of the putt itself or the consequences of it. That
          means building a physical and psychological routing that you go
          through for eery putt that starts from the time you finish your read
          and ends when the ball leaves the putter. If you've done the first
          part right, you're really just a spectator for the second part. I know
          my stroke is good, and if I can put myself in position to make that
          good stroke time after time, I'm going to make my share of putts.
        </div>
        <div class="p-mb-2">
          Dr. Cook's technique has two major advantages. First, it helps
          depressurize the entire putting experience. I don't care if you're
          playing a $2 Nassau with your friends or trying to make a putt to win
          a match in the club championship—you're going to be faced with a
          nerve-wracking putt. It's part of the game, and it's what makes this
          game great. The more you can immerse yourself in a process-focused
          pre-shot and putting routine, the more you can distract your mind from
          the consequences of making or missing and concentrate on making a good
          stroke.
        </div>
        <div class="p-mb-2">
          The second advantage is that it helps smooth out the peaks and valleys
          in your overall putting performance. If you've missed a batch of
          makeable putts early in a round, it's very, very easy to get
          discouraged and stand over the rest of your putts that day with some
          seriously negative thoughts. And trust me, if you don't think you're
          going to hit a good putt, you're almost always going to be right.
          Focusing on the process instead of the result liberates you from
          beating yourself up about something you can't control, and lets you
          make a much more realistic assessment of your performance.
        </div>
      </div>
    </Panel>
    <Panel
      header="The Process: Strategy, See It, Feel It, Trust It"
      :toggleable="true"
      :collapsed="true"
    >
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          Sounds great, you say. But how do I do it? It doesn't take anything
          more than concentration and practice—you don't need any other special
          skills. It starts with building a checklist to follow before you make
          every single putting stroke. How dedicated you are to following this
          checklist will determine how consistent your results will be because
          of it. Dr. Cook broke the checklist down into five parts for me, and
          I've found that it works very well. After observing the situation and
          deciding on your strategy, you then do three things over the
          stroke—See it, Feel it and Trust it.
        </div>
        <div class="p-mb-2">Strategy:</div>
        <div class="p-mb-2">
          First, observe the situation and come up with your strategy. Are you
          looking at a sixty-footer with ten feet of break? Would a lag up to
          three or four feet be a great effort? Is it a fifteen-footer straight
          up the hill that you can be a little more aggressive with? Your green
          reading (I'll show you how to read putts in the next chapter) and
          strategy process should happen very early, before you're even thinking
          about stroke.
        </div>
        <div class="p-mb-2">See It:</div>
        <div class="p-mb-2">
          Once you've committed to that strategy, get into your putting setup
          and make your practice stroke. Take the read you've just made and try
          to visualize the curving line your ball will actually take, and then
          the ball rolling along that line. It definitely takes a lot of
          discipline to do it on every single putt, but as it becomes an
          ingrained part of your process, you'll be able to block out
          distractions by immersing yourself in the routine. I can tell you from
          personal experience that when you're coming down the stretch and
          trying to win a tournament, the adrenaline gets pumping and everything
          starts to speed up. It's easy to lose control. Having a routine to go
          through before you putt slows things down and restores your comfort
          level.
        </div>
        <div class="p-mb-2">Feel It:</div>
        <div class="p-mb-2">
          Once I finish visualizing the path I want my ball to roll on, I plug
          in one or two of the swing thoughts I'm playing with on that day. What
          is a swing thought, and how do I pick one? Good question. It depends.
          In the process of learning the stroke I teach, you're going to come up
          with an array of thoughts and feels that help you roll the ball well.
          Every day I play, I'm applying one or two of those thoughts and feels
          as the swing key for my stroke. The thought or feel might feel great
          for two or three days, then start to lose its effectiveness, and then
          it's time to plug in another one. One day, I might be thinking about
          rotating the putter with my left forearm to start the putter back.
          Another time, I'm thinking about moving my left shoulder around toward
          my chin. I'm physically doing the same thing with my stroke every
          time. I'm just using a different thought to trigger it. I'm definitely
          not standing over the ball with a blank mind, stroking the ball toward
          the hole. I've heard that a few people try to do that —go completely
          blank—but my mind doesn't work that way. Keeping a single swing
          thought (or, at the most, two of them) in my head as I make the stroke
          reinforces my fundamentals and connects the psychological part of
          putting with the physical mechanics we've been talking about. It's
          also a great way to keep little mechanical flaws from growing in your
          stroke. My caddie might notice that I'm picking my putter up a little
          bit on my backswing. My key for that day might be concentrating on
          keeping the putter low, and my stroke is better for it.
        </div>
        <div class="p-mb-2">Trust It:</div>
        <div class="p-mb-2">
          The idea here is not to get overcome with the desire for the ball to
          go in the hole. We've all been there: Standing over an eight-footer
          that just has to go in. That desire can easily get in the way of a
          good stroke in the form of anxiety. By trusting your stroke, you're
          deciding to give the best effort you can on the mechanics and “let the
          rest just happen. Measure yourself by the mechanics of the stroke, and
          if you do the mechanical things well, you're going to make some putts.
          The whole “Trust it” idea also enforces a kind of honesty about your
          putting game. If you go through the entire routine I've been talking
          about and, in the end, trust your stroke, you're going to know pretty
          quickly if you've done enough work on the practice green to develop
          fundamentally sound mechanics. Poor contact on the toe or heel and bad
          misses aren't going to make you very happy, but you can use that
          feedback to get better.
        </div>
        <div class="p-mb-2">
          Does all this mean that you're going to be perfectly consistent with
          the putter? No, we're not machines, and we all go through hot and cold
          streaks. But having a process helps you understand when and why a
          streak—hot or cold—is happening, and what to do about it.
        </div>
      </div>
    </Panel>
    <Panel header="Anecdotes" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          The hottest putting streak I had in my career is so vivid in my mind.
          It’s like I was making the putts yesterday. It coincided with the week
          I won the Louisiana Open Nationwide Tour event in 1995. When I went
          through that visualization process I just described, the lines looked
          so obvious, and my stroke was pure. When those two things come
          together, look out. You’ll make them all. It’s called catching a case
          of the drains.
        </div>
        <div class="p-mb-2">
          On Sunday that week in Lafayette, I was eight shots behind going into
          the final round. The wind was blowing so hard that I couldn’t even
          wear a hat. In those conditions, I went out and shot a course-record
          62 and made every single putt I looked at—from forty-footers on down.
          I made ten birdies and had something like twenty-two putts for the
          day, and ended up beating Keith Fergus by two shots. Ultimately, when
          you get in the “zone” like that, your own success is what jars you out
          of it. You start to notice all the putts going in, and even if you try
          to get out of the way and just let it happen, you start to get tight
          thinking about trying to make the next one. The streak takes on a life
          of its own.
        </div>
        <div class="p-mb-2">
          The streaks of misses are obviously a little tougher to deal with than
          when you’re draining them all. I’ve had stretches of a few weeks where
          I thought I was stroking it well and they just would not go in. I can
          remember a few times when I needed to hole a four-footer to make the
          cut, and it just felt like there was no way the ball was going in.
          Sometimes, it’s as simple as going back over your fundamentals and
          discovering a flaw. For me, it’s usually tempo related. Life creeps
          into my stroke—when life is moving fast, and you’ve got lots of things
          to think about, it’s easy to get quick with the putter. When I’m going
          really good, everything slows down and the ball is just squeezing off
          the putter. For those tougher times, when I can’t seem to find the
          problem in my fundamentals, the way I break that spell is to literally
          quit trying to make putts. I completely exaggerate the process of
          disconnecting from the outcome and make a stroke. Let me give you a
          more specific example.
        </div>
        <div class="p-mb-2">
          I was caddying for my friend Lee Janzen at the Players Championship in
          2002 and he was going through a tough time getting putts to fall.
          After a round that left him in the middle of the pack on Thursday, he
          came to the turn on Friday really needing something to happen to make
          the cut. I could see how frustrated he was by all the near-misses and
          lip-outs, and I’m sure he was thinking that none of them were going in
          the rest of the day. On his ninth green, the eighteenth hole, I took a
          risk and told him to intentionally aim off to the corner of the hole
          and try to just miss past the edge of the cup. It seemed to relieve
          the “I have to make the next one” anxiety he was feeling, and darn it
          if he didn’t hit it right into the heart. He putted great the rest of
          the day, made the cut and had a good week.
        </div>
        <div class="p-mb-2">
          The things that happen to you in streaks just reinforce the overall
          idea that you should take care of your own business—what you’re
          thinking and how you stroke it—and let the putt take care of itself
          after you hit it. Go to a PGA Tour event and watch Brad Faxon on the
          practice green. He won’t even go to a hole or set up to any other
          target. He’s out there hitting putts to nothing because he’s not
          trying to make them. He’s trying to make sure he’s hitting it solid.
          He knows that if he does, they’ll go in. I really like another
          variation of that kind of practice, something I saw Jeff Brehaut do on
          the putting green. He sets up to hit a fifteen-footer to no specific
          target on the practice green, then hits five or six to see where the
          break actually is. Then he’ll use a tee to mark where he hit the putt
          from, drop a coin where the high point of the putt is, and put a
          plastic disc down where his first five or six putts ended up. Then
          he’ll practice that putt—to the high point and the coins and down to
          the plastic disc—making the same smooth stroke. He lets the green
          determine the break, then practices replicating his mechanics.
        </div>
      </div>
    </Panel>
  </Panel>
</template>
