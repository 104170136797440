<template>
  <Panel header="Introduction" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        The address-position fundamentals are designed to put you in the best
        possible position to start your putts on line. The ball, however, can’t
        start rolling by itself. That’s determined by how you generate the
        motion of your stroke.
      </div>
      <div class="p-mb-2">
        Once your setup fundamentals are in order, the act of stroking the ball
        on your intended line is nothing more than maintaining a suspension
        point and staying stable throughout your motion. See—I told you it was
        going to be easy! But as with anything in this book, it’s critical for
        you to understand why I’m asking you to maintain a suspension point
        (whatever that is) and remain stable. Coming to terms with these
        concepts and what it feels like to execute them is critical to the
        improvement process and allows for the best possible results. Let’s get
        started.
      </div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
    </div>
  </Panel>
  <Panel
    header="Pre-Stroke Foundation: Setup"
    :toggleable="true"
    :collapsed="true"
  >
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/setup1.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      Critical pre-swing fundamentals from a face-on perspective.
    </div>
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/setup2.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      Critical pre-swing fundamentals from a down-the-line perspective.
    </div>
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/setup3.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      Work on your pre-stroke fundamentals using a mirror at least once a week.
      Even Tour players fall into bad habits. Keep them at bay with a quick and
      easy weekly checkup.
    </div>
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/setup4.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      Style choices, like which grip you use, are not fundamentals—go with what
      gives you the most control.
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Now that the picture of your stroke and the key impact fundamentals are
        clear and complete, you need to come to terms with how you physically
        set up and execute your stroke to create the impact conditions that
        allow you to start the ball on your intended line without having to
        think about it. Although all fundamentals are interrelated to some
        degree, they’re easier to understand and work on when you separate them
        into two distinct parts: those that happen pre-stroke, and those that
        occur during the stroke. For the remainder of this chapter, I’ll focus
        on the pre-stroke fundamentals that fuel consistent impacts and accurate
        starting lines.
      </div>
      <div class="p-mb-2">
        Body and club alignment at address both affect in-stroke motion and,
        therefore, stroke shape. I like my students to be comfortable; the more
        relaxed you are at address, the smoother your stroke will be and the
        easier it will be to repeat. So get cozy—just make sure your posture
        adheres to the following structure:
      </div>
      <div class="p-mb-2">
        1. Set up so that the shaft of the putter is straight up and down, or
        perpendicular to the putting green, and your dominant eye is positioned
        two inches behind the ball (that is, away from the target). Most
        manufacturers build three to four degrees of loft into their putters
        because this creates an optimum amount of effective loft (static loft
        plus the strike angle of the putterhead) plus or minus shaft lean errors
        at impact. Manufacturers, however, assume that the user will set up with
        the shaft in a neutral position (straight up and down) at address and
        then return it to neutral at impact. If you feel more comfortable
        addressing the ball with your hands pressed forward or leaning back, you
        should have an equipment professional adjust the loft on your putter
        accordingly, so that you can maintain the ideal amount of effective
        loft. 2. Set up so your eyes are over an extension of the target line,
        or no more than one inch inside that line (in other words, no more than
        one inch closer to your body). This gives you the best vantage point to
        look down and see your intended line. 3. Set up so that the middle of
        your right hand sits directly under your right shoulder (or the shoulder
        on the same side of your body as the lowest hand on the grip). This will
        make it as easy as possible for you to swing the club on a neutral path.
        4. Set up so that an imaginary line between your shoulders is parallel,
        or square, to your intended start line. This alignment will allow your
        hands to swing naturally along the target line. Notice that I said I
        wanted your shoulders square, but that I didn’t say anything about your
        feet. That’s often a matter of eye dominance and comfort. Generally,
        left-eye dominant players (right-eye dominant if you’re a lefty) see the
        line better if they set up with a square stance and the ball slightly
        forward of center, so they can get their dominant eye just behind the
        ball. Right-eye dominant golfers (left-eye dominant if you’re a lefty)
        tend to feel more comfortable with an open stance; this allows them to
        cock their head a little toward their trail shoulder and look down the
        line more easily with their dominant eye. The ideal ball position for
        this type of golfer is typically dead center.
      </div>
      <div class="p-mb-2">
        MASTERING YOUR SETUP These four setup fundamentals are simple to
        understand and require no real special ability to master, but how do you
        know if you’re accurately executing them? Obviously, intent means
        nothing. There’s a huge difference between what we feel and what’s real.
        It can’t be subjective or up for debate—you need to know with 100
        percent certainty that you’ve got them down pat. My advice? Check your
        setup fundamentals using my Mirror-Setup Drill (explained below) at
        least once a week. It’ll take no more than a minute, and you’ll be using
        that time wisely.
      </div>

      <div class="p-mb-2">
        Mirror Set-Up Drill Step 1: Find a full-length mirror in your home and
        get into your address position with your body facing the mirror. From
        this vantage point, check that the shaft is sitting 90 degrees to the
        ground and that your dominant eye is approximately two inches behind the
        ball. Adjust both hand and upper-body positioning until you nail these
        important setup fundamentals. Step 2: Rotate to your left a full 90
        degrees so that you’re setting up to putt away from the mirror. (If you
        putt left-hand low, set up as though you’re putting into the mirror.)
        Check that your arms dangle freely from your shoulders and that the
        middle of your right hand sits directly below the center of your right
        shoulder. (If you putt left-hand low, make sure that the middle of your
        left hand sits directly below the center of your left shoulder.) If the
        image in the mirror doesn’t reflect this, adjust your hip hinge or arm
        position until you get it right. Step 3: Confirm that your shoulders
        look square to your intended line (when you look back into the mirror,
        your right shoulder should hide your left, and vice versa if you putt
        left-hand low). Adjust if necessary. Step 4: Make sure the ball is under
        your eyes, or just outside them, and confirm that your putter is soled
        flat on the ground. If the heel is down and the toe is up, your putter
        is too upright for your posture and you’ll need a fitting professional
        to adjust it for you. Obviously, the same can be said if your putter is
        too flat (toe down), or too long or too short. You’re better off
        adjusting the fit of your putter to your most comfortable posture and
        the proper setup fundamentals than adjusting your setup to a putter that
        doesn’t fit.
      </div>
      <div class="p-mb-2">
        Now that you’ve attained a fundamentally sound setup, measure the
        distance from the ball to your toe line by marking an alignment stick or
        using the length of your putterhead to create a measurement. For
        example, when he’s training, PGA Tour player Cameron Tringale puts a tee
        in the ground two and a half putterhead lengths from the inside of the
        ball to mark his toe line, or the distance he stands from the ball.
        Because he checks both this distance and his eye positions daily, his
        posture and body orientations never change—a key to mastery.
      </div>
      <div class="p-mb-2">
        THE POWER OF STYLE CHOICES Hopefully you realize that when it comes to
        the proper setup fundamentals, I’m not asking for much—you can nail them
        in a few minutes with the mirror drill. However, you may have address
        position questions I have yet to answer, such as “Which grip should I
        use?” or “How firmly should I grip the club?” I wouldn’t think of trying
        to answer those, because there’s a difference between fundamentals
        (things everyone should do) and style choices (things that you should do
        because they work for you). Make any style choices that suit you, just
        make sure that they help you achieve the body and club alignments
        discussed in this chapter. Your style choices will differ from mine and
        from most golfers you know. Tiger Woods uses his trail arm to dominate
        his stroke and grips the putter very lightly. His good friend, Steve
        Stricker, grips his putter with maximum pressure and swings it using his
        lead arm and hand. They’re completely opposite styles, but they both
        allow the user to comfortably control the club and, more important, to
        repeat the motion. Whether you hold the club in your fingers or palms,
        have a fat grip or a skinny one, putt cross-handed, traditionally, or
        with the claw—it doesn’t matter as long as those choices allow you to
        achieve the setup fundamentals we’ve discussed and give you a high
        measure of control over the club.
      </div>
      <div class="p-mb-2">
        With these criteria in mind, think through the style choices you’ve
        already made and the ones you’ve been thinking about adopting and commit
        to those that feel best. I, for example, feel more in control of the
        club with a skinny grip running through my fingers, which is the
        opposite of the current trend on Tour. Champions Tour player Tom
        Pernice, Jr. switched to a left-hand low, or cross-handed, grip a decade
        ago after constantly struggling to get his shoulders square at address
        with a traditional hand placement. This change has made a huge
        difference in his comfort level and consistency. Your style choices are
        unique to you. Make them for the right reasons, and then commit to them.
      </div>
    </div>
  </Panel>
  <Panel
    header="In-Stroke Foundation 1: Maintaining Suspension Point"
    :toggleable="true"
    :collapsed="true"
  >
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/suspensionPoint1.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      The rhythmic, consistent swing of a pendulum results from the
      establishment of a fixed suspension point, or fulcrum (F). In this
      diagram, H is the height of the swing (or stroke), D is the displacement
      (length of backstroke), B is the bob shape and mass (the putterhead) and L
      is the length of the pivot arm (arm hang plus shaft length).
    </div>
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/suspensionPoint2.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      Just like a swinging pendulum, your stroke must move back and through
      around a fixed point—a “virtual” suspension point that you establish at
      address and maintain throughout your stroke.
    </div>
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/suspensionPoint3.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      An imaginary line running up from the butt of the grip to your body should
      point at the same place on your sternum at address, at the end of the
      backstroke, at impact, and at the end of your forward-stroke. This ideal
      pendulum putting motion results from blending all of the moving parts in
      your stroke in a coordinated fashion.
    </div>
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/suspensionPoint4.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      Expert at Work! For additional clarification on the suspension-point
      fundamental, watch a special video detailing this concept at
      jsegolfacademy.com/index.php/james-suspension-point.
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        A great putting stroke is similar to a pendulum in that it moves around
        a fulcrum, or a fixed point. Your ability to establish and maintain the
        fulcrum, or suspension point, along your set up determines the shape of
        your stroke. Move the fulcrum and your stroke goes haywire.
      </div>
      <div class="p-mb-2">
        It’s easy to visualize the concept of a suspension point when a player
        is putting with a long or belly putter—the butt of the grip is touching
        the body, creating a fixed point about which the putter swings. Because
        the suspension point is physically fixed to the body with a belly or
        long putter, it’s much easier to coordinate the movement of the
        different body parts involved with executing a proper stroke. No doubt,
        belly and long putters have helped a lot of players better control the
        forces applied to the club and sink more putts, but since the USGA has
        deemed this advantage illegal (Rule 14.1b, effective January 1, 2016),
        you must now come to terms with this principle and learn correct
        movement using a traditional-length putter (or a belly or long model
        that isn’t fixed to your body).
      </div>
      <div class="p-mb-2">
        With a short putter in your hands and both arms swinging freely, the
        pivot arm of the pendulum is not actually an extension of the club
        itself, but rather a vertical line connecting a point on your upper body
        to your hands. The suspension point of your pendulum is more of a
        “virtual” one. As such, it’s trickier to control compared to putting
        with a belly or long putter. When assessing suspension point using
        video, I mark and extend the shaft upward with a vertical line in four
        separate frames: 1) setup position, 2) end of the backstroke, 3) impact,
        4) the completion of the stroke. If the player I’m analyzing properly
        maintains his or her suspension point, each of these lines will meet at
        a fixed point somewhere near the golfer’s sternum.
      </div>
      <div class="p-mb-2">
        With a short putter, maintaining the suspension point of your pendulum
        stroke is easier to understand than execute, especially if you’ve never
        felt the correct motion. A lot of accepted wisdom on how to accomplish
        this fundamental does more harm than good. Contrary to popular opinion,
        it’s not about “locking your elbows and wrists,” “rocking your
        shoulders,” “keeping your triangle,” or any version of “not breaking
        down.” Rather, maintaining your suspension point is achieved by
        coordinating and blending all the moving parts in your stroke and using
        them in the correct ratio to one another. (By moving parts, I mean
        everything above the belt save for the top of your spine, your head, and
        your eyes. More on that later in the chapter.) Put into even simpler
        terms, learning and mastering your suspension point is about being able
        to “feel” the correct motion on a consistent basis. Luckily, I have a
        magical drill to help you get it right.
      </div>
      <div class="p-mb-2">
        How to Properly Maintain Your Suspension Point Indoors, set up to an
        imaginary ball while facing a wall. Make sure you get into your address
        posture using the perfect body alignments described in the Mirror Setup
        Drill in Chapter 3. Next, shuffle your feet forward until the crown of
        your forehead rests gently against the wall. Once you’re set, slowly
        inch the puttershaft up through your hands until the butt of the grip
        touches the center of your sternum (or slightly left of center if you’re
        left-eye dominant or grip the putter left-hand low). Keep your arms
        hanging in the same position as you raise the shaft toward your body, so
        that the shaft remains perpendicular to the ground (resist the
        temptation to lift your hands outside your shoulders and get the
        putterhead under your eyes.) If you do it correctly, your hands will
        block your view of the putterhead.
      </div>
      <div class="p-mb-2">
        With the butt of the club fixed in position, swing your hands, arms, and
        chest in unison back and forth, keeping your elbows soft and your grip
        pressure constant (photos, here). Swinging in this manner with your
        forehead against the wall will not only give you the feeling of
        disassociating your shoulder and chest movement from your spine (no side
        bend), but will also create the proper blend of movement between your
        wrists, elbows, arms, chest, and shoulders.
      </div>
      <div class="p-mb-2">
        Take inventory of the sensations, because this drill gets every body
        segment moving in the correct sequence and with the correct speed and
        force relative to the others, allowing you to maintain the suspension
        point with very little effort.
      </div>
      <div class="p-mb-2">
        Executing this key fundamental results in great stroke shape and
        control, setting you well on your way to consistently starting your
        putts on line. After you get a feel for the motion, soften your hold on
        the shaft so the grip falls down into your hands. With your forehead
        still resting against the wall, assume your normal setup and repeat the
        drill. Continue as necessary or until you can transfer the motion to
        real strokes on the practice putting green or out on the course.
      </div>
      <div class="p-mb-2">SUSPENSION-POINT FAULTS AND FIXES</div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
    </div>
  </Panel>
  <Panel
    header="In-Stroke Foundation 2: Stability"
    :toggleable="true"
    :collapsed="true"
  >
    <Panel
      header="Engaging Core (hip and spine muscles)"
      :toggleable="true"
      :collapsed="true"
    >
      <img
        class="img4"
        src="@/assets/images/sieckmannPutting/stability1.png"
        alt="Sieckmann Putting"
      />
      <div class="accent1 fontSmall p-mb-2">
        Worm Stability Drill Step 1: Roll up a towel into a “worm” shape. Step
        2: Place the towel under the arches of both feet, then get into your
        address position and rock forward. Step 3: Rock backward toward your
        heels. Step 4: Settle your weight over your arches. Now you’re balanced
        and stable.
      </div>
      <img
        class="img4"
        src="@/assets/images/sieckmannPutting/stability2.png"
        alt="Sieckmann Putting"
      />
      <div class="accent1 fontSmall p-mb-2">
        Expert at Work! Dr. Greg Rose of the Titleist Performance Institute
        explains how to strengthen and train the muscles responsible for
        in-stroke stability in this special video at
        jsegolfacademy.com/index.php/rose-stability.
      </div>
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          The second in-swing fundamental that affects your ability to start the
          ball on line is stroke stability. For ultimate performance, you need
          to stabilize in two very different ways. The first is by engaging your
          core, a process that entails aligning your pelvis to your spine and
          holding everything in place as you putt. This is critical because it
          allows you to disassociate your upper and lower body, a necessary step
          for maintaining your suspension point. Any change in balance in any
          direction or lower-body rotation will wreak havoc on the shape of your
          stroke. As such, when you take your address, you must hinge at the
          hips and set your core in a manner that promotes stability.
        </div>
        <div class="p-mb-2">
          In Chapter 3, I mentioned that your weight should be evenly balanced
          between the heels and balls of each foot. This is important, because
          if you set up with your weight too far back toward your heels, it’s
          nearly impossible to get your hips and spine in a neutral alignment so
          you can properly engage your core. The telltale sign of this error is
          a rounded upper back at address, which inhibits arm swing and promotes
          recurring back pain during practice.
        </div>
        <div class="p-mb-2">
          The simplest way for me to help you learn the feel for the correct
          balance and hip stability at address (which will carry forward into
          your stroke) is to create instability and then let your mind and
          body’s natural inclination for balance take care of the rest. With
          that in mind, I created the following drill.
        </div>
        <div class="p-mb-2">
          Worm Stability Drill Take a towel and turn it into a worm-like roll
          approximately two or three inches in diameter. (If the towel lacks the
          necessary thickness, you may have to fold it once before rolling it to
          get the proper size.) Once you’ve made your “worm,” lay it on the
          putting green and grab your putter. Set up for a practice stroke while
          standing on the towel. Make sure the towel is directly under the
          arches of both feet. You’re now unstable. Rock onto your toes and
          you’ll immediately recognize that this position doesn’t feel strong or
          athletic. Rock in the opposite direction, all the way back to your
          heels, and you’ll experience even more instability. After a second,
          shift your balance to find the middle ground between your heels and
          toes, right over your arches, and then hold your pose. As your body
          fights for stability with that balance, it will automatically shift
          your pelvis to a position in which it aligns with your spine, and your
          core muscles will engage. This is the correct amount of hip hinge and
          posture for you. Finish the drill by making ten continuous strokes
          back and through to learn the true feel of disassociating your hips
          from your chest and shoulders.
        </div>
        <div class="p-mb-2">
          Hip and spine muscular stability are important if you want to be able
          to perform your fundamentals with ease. The specific muscles groups
          called into question are the gluteals (maximus and medius), deep core
          stabilizers (multifidus and tranverse abdominals), and outer core
          muscles (rectus abdominis, quadratus lumborum, erector spinae, etc.)
          If you’re going to leave no stone unturned on your way to putting
          greatness, then you should add a few simple exercises to your weekly
          routine to make it easy. The foremost expert in golf biomechanics, Dr.
          Greg Rose of the Titleist Performance Institute, shows you how in a
          special video. Watch the clip and get after it.
        </div>
      </div> </Panel
    ><Panel header="Eyes" :toggleable="true" :collapsed="true">
      <img
        class="img4"
        src="@/assets/images/sieckmannPutting/stability3.png"
        alt="Sieckmann Putting"
      />
      <div class="accent1 fontSmall p-mb-2">
        Three-Points Drill: On every putt, focus on the ball, the starting line,
        and the entry point, scanning your eyes back and forth between all three
        objects. This is the best way for your brain to know the precise
        location of the target. Visualizing three points is the only way to
        properly visualize line and speed. If you only look at two points, you
        may get the line right, but probably not the speed.
      </div>
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          HOW TO STABILIZE YOUR STROKE WITH YOUR EYES The second way to create
          in-stroke stability involves your eyes. How you use them before,
          during, and after your stroke is critical to performance on the
          greens. When most players think of vision’s role in putting, the first
          thing that comes to mind is quality of perception. Am I 20/20? Can I
          perceive the nuances and cues provided by the green in order to read
          it correctly? For the first thirty-three years of my playing and
          coaching career this was my view, but now I know that it’s more
          appropriate to think of vison in terms of how your eyes best
          communicate with your brain so that your muscles get the information
          they need to stroke the ball into the cup. There are tricks to it, and
          it’s a topic I broach with every student, because it’s often a clear
          line of demarcation between great and poor putters. Eye movements are
          both physical fundamentals and beacons of mental strength. And as
          proof that life is often stranger than fiction, I learned all this one
          sunny day on the way to the loo.
        </div>
        <div class="p-mb-2">
          It was around 2000, and I was at Colonial Country Club in Fort Worth,
          Texas, for what was then the PGA Tour’s MasterCard Colonial
          Tournament. My busiest day when I’m out on Tour is usually Tuesday,
          and I remember being out on the putting green with one of my clients,
          Skip Kendall, whom I started coaching in 1998. After finishing with
          Skip and before tracking down my next client, I walked into the
          player’s locker room and was met with the most unexpected sight: In
          the hallway, just outside the bathroom, was a man sitting at a small
          desk housing a sizable computer and several strange-looking gadgets.
          Next to him was a Tour player stroking putts on the carpeted floor
          wearing a weird-looking helmet with wires connecting it to the
          equipment on the desk. Generally, I’m a “mind my own business” kind of
          guy, but this setup was too bizarre to ignore. I walked over to the
          line to eavesdrop.
        </div>
        <div class="p-mb-2">
          Standing in front of me was PGA Tour player Loren Roberts, known as
          the “Boss of the Moss” for his legendary putting ability. Within
          minutes, a technician began applying sensors to various parts of
          Roberts’s scalp, then handed him the helmet. It looked like something
          a Hells Angel would wear, except for two small cameras mounted on top
          (think GoPro) and two small mirrors on each side that protruded out
          like the eyes of an insect. When given the green light, Roberts
          addressed a ball and stroked it into a fake cup approximately ten feet
          away. As he putted, an electroencephalogram (EEG) recorded his brain’s
          electrical activity on a scrolling piece of paper while the helmet
          cameras tracked and recorded his eye movements reflected in the
          mirrors. At the conclusion of the test, Roberts shed the helmet and
          wires and sauntered off. I was the only bystander left and since no
          one else was around, I did what anybody would do in that circumstance:
          I stepped in to take my turn.
        </div>
        <div class="p-mb-2">
          On went the sensors and helmet. I addressed the ball and hit my best
          putt. As the gear was removed, I glanced down at my EEG graph. It
          looked like it had just recorded an earthquake, with wild spikes
          running up and down across the paper readout. What interested me was
          that my EEG was completely different than Roberts’s. His readings were
          nearly flat, with just a few gentle waves. Not understanding the
          significance, I asked the man sitting at the desk what it all meant.
          He told me that when your eyes change focus from one object to
          another, when you blink, or when you take on a new thought, your brain
          generates neural electrical impulses that result in spikes in the EEG
          printout. An epiphany hit like a ton of bricks raining down from
          above: Great putters (and few are greater than Loren Roberts), putt
          with quiet eyes and a calm spirit, and despite 30-plus years of
          experience and immeasurable training, I had neither.
        </div>
        <div class="p-mb-2">
          Ironically, I chose to study and dedicate my professional coaching
          life to the short game, largely because when I played in college and
          on the Asian and South American tours, my struggle with this aspect of
          the game kept me from fulfilling my dreams. Deep down, I craved
          answers to my short-game problems. My stroke looked good, but I often
          played without courage, and never putted to Tour standards. Unlike the
          Boss of the Moss, I had a “noisy” brain. Perhaps that’s the real
          difference between those who are meant to play and those who are
          destined to coach: The former execute with internal coherence, the
          latter in complete chaos.
        </div>
        <div class="p-mb-2">
          Honestly, I don’t know the name of the organization responsible for
          the testing at Colonial. I never thought to ask, which seems a bit
          stupid now, but the only thing that mattered as I walked out of the
          locker room and back out onto the practice putting green was that
          there was a key attribute to putting that I knew nothing about. Once I
          returned home, I immediately sought out and read as much research as I
          could on vision and eye movement.
        </div>
        <div class="p-mb-2">
          The most interesting thing I came across was a process called the
          “Quiet Eye” technique. The term was coined in 1996 by Dr. Joan
          Vickers, head of the Neuro-Motor Psychology Laboratory at the
          University of Calgary. Quiet Eye refers to the gaze behavior just
          before, during, and after movement in aiming tasks such as shooting a
          basketball or putting. Almost all elite putters have a high efficiency
          in their gaze pattern, or what scientists call “saccades.” In an
          efficient saccade, the golfer scans toward the target, locates it,
          fixates, then turns back to the ball, settling his or her eyes at
          address for at least one second before starting the stroke. (In other
          sports such as tennis or basketball, a successful saccade lasts just
          fractions of a second—think Steph Curry hitting a fadeaway jumper.
          Most successful golfers are much more deliberate with their eye scans
          because they’re afforded the luxury of time.) The communication flow
          from the eyes to the brain and muscles is subconscious and clear,
          creating zero doubt about the target’s position in space as well as
          where the club is going to contact the ball.
        </div>
        <div class="p-mb-2">
          Once good putters settle their eyes, they react to the information
          sent to their brain. Their physical focus remains constant during the
          motion and for a count after. Poor putters, on the other hand, often
          have imprecise scan paths. Their gaze to the target is often too short
          in duration, inconsistent, and far too general. In addition, their
          physical focus often changes during their putting motion, usually
          moving toward their left foot as the putter nears impact. They’re not
          sure where the target is in space, and the neural electrical pulses
          generated by the change in focus can create involuntary muscular
          contractions at precisely the wrong time.
        </div>
        <div class="p-mb-2">
          The goal of the Quiet Eye technique is effective communication between
          your eyes and brain as they sense the target and the ball, as well as
          maintaining a quiet mind throughout your stroke. As such, I view the
          physical skills involved in utilizing the Quiet Eye technique to be
          synonymous with the mental attribute of having a “calm spirit,”
          because if you’re overly concerned or worried about a putt, it’s very
          difficult to keep your gaze quiet during your motion. The eyes are the
          window of the soul; liars often look down and to the left just after
          lying, and highly trained poker players can detect stress in their
          opponents just by watching their eyes. Clearly, it takes a measure of
          trust to send the ball on its way without changing your focus to
          anticipate impact or the impending result. More on this in Chapter 8.
        </div>
        <div class="p-mb-2">
          Note: At numerous times in this book, particularly when discussing eye
          movement, the physics of green-reading, and visual perception, I’ll be
          offering an amateurish summation of research done by extremely smart
          and highly trained people. For a more accurate and complete view of
          this scholarship, I suggest you search out and read the works of Dr.
          Joan Vickers, Dr. Debbie Crews, Léon Foucault, Joel Pearson, H. A.
          Templeton, Mark Sweeney, and Dr. Carol Dweck. My degree is from the
          School of Hard Knocks and three decades of coaching, not from a
          highfalutin graduate program. My advantage is that as a tournament
          player, I have both yipped putts under pressure and come through in
          the clutch, and as a coach my simplified understanding of academic
          research has helped me lead several players from a sense of helpless
          affliction on the greens to a rediscovery of greatness. If my
          summations are dumbed down a bit, it’s not to diminish the quality of
          these individuals’ research, but to help my students improve as easily
          as possible. Great coaching not only doesn’t have to be complicated,
          it can’t be.
        </div>
        <div class="p-mb-2">
          HOW TO TRAIN YOUR EYES FOR STABILITY: THREE-POINTS DRILL Find a gently
          breaking six-foot putt on the practice green. You’ll need a ball, a
          coin, a tee, and your putter for this exercise. Place the ball on the
          green. Next, read the putt and mark the line on which you think the
          putt should start with a coin, about a foot or two in front of the
          ball. Then, mark where you think the putt will enter the hole, and
          insert the tee into the lip of the cup on an angle so that it points
          toward the entry point. (I’m using a training aid called the Putt
          Pocket by SKLZ to mark the entry point in the photo below, but an
          angled tee works just as well.) You now have the three critical points
          for your eyes to scan and focus on: The “ball,” the starting-line
          coin, and the entry-point tee.
        </div>
        <div class="p-mb-2">
          Using binocular vision from behind the ball, visualize and connect
          your three points. Maintain this focus as you walk toward your ball
          and set up to it. Slowly turn your head and shift your gaze so that
          your eyes trace a line from the ball to the coin. Hold for a count,
          then continue your eye scan to the precise point at which the ball
          will enter the hole (the tee). Stare at the tee for one to three
          counts, or until you’re certain you’re locked onto the target. Next,
          reverse your eye scan, from the entry point to the coin and back to
          the ball. Once your eyes settle softly on the ball, make your stroke,
          reacting to the picture in your mind and maintaining that focus
          throughout your motion and for a full count after it ends. The drill
          is finished when you complete five strokes with perfect eye scans and
          quiet eyes. (If you scanned a right-to-left putt in this training
          session, switch to a left-to-right putt on the next one.) Not only
          will this drill give you laser-like focus, it’ll train you to calmly
          stroke putts, as Charley Hoffman and Tom Pernice say, “back and
          through past your head,” which is where your eyes are focused.
        </div>
        <div class="p-mb-2">
          The secret power of the Three-Points Drill is the addition of the
          third point—most drills and most coaches only require you to scan two
          (usually the ball and the start line). By connecting the ball, start
          line, and entry point, you’re defining not only your line, but also
          your intended speed, because there’s only one speed at which the ball
          will roll over both points on a breaking putt. If you only look at the
          ball and the start line, it’s extremely difficult to visualize—and
          produce—the correct speed; putts that start on line can still miss
          high or low if the speed of the ball is too fast or too slow,
          respectively. Getting the ball into the hole on breaking putts demands
          that you match the speed to the line. Likewise, if you only look at
          the ball and the hole (and ignore the start line), you’ll undervalue
          break and miss disproportionately to the low or “amateur” side of the
          cup.
        </div>
        <div class="p-mb-2">
          Vision Quest Students often ask what part of the ball they should look
          at. Dr. Vickers suggests the back, but the research appears to be
          inconclusive. I’ve had different students putt well looking at the
          back of the ball, the top, the target side, and even at a blade of
          grass in front of the ball with both a hard or a soft focus. The last
          choice works best for me, but experience tells me that it won’t
          necessarily work for you. Or it might. You’ll need to experiment with
          each to find the one that allows you to execute the correct
          fundamentals on a consistent basis.
        </div>
      </div>
    </Panel>
  </Panel>
  <Panel
    header="Finding the Perfect Putter"
    :toggleable="true"
    :collapsed="true"
  >
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/perfectPutter1.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      The best putter for you isn’t the one that looks the sharpest or the model
      used by your favorite Tour player; it’s the one designed to augment your
      in-stroke strengths and minimize your mistakes, including poor aim. If,
      for example, your putterface often rotates too much relative to the path,
      consider a face-balanced putter (far right). If the putterface either
      rotates closed in the backstroke or opens up in the through-stroke, a
      heel-shafted putter with more mass toward the toe (far left) may help you.
      Grip shape and size is an issue of comfort and control and needs to be
      worked through individually. As with any equipment choice, your best bet
      is to pay a visit to a trusted clubfitter.
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        GETTING POINTED IN THE RIGHT DIRECTION The scary thing about putting is
        that establishing and perfecting great fundamentals means nothing if you
        can’t aim. Starting the ball on line when the actual line is three
        degrees left won’t save you any strokes. In fact, it’ll drive you from
        the game. I offer three effective strategies in this regard. The first
        is to choose the right putter. Its design—head shape, hosel type, shaft
        offset, loft, alignment markings, etc.—has a tremendous effect on where
        your eyes tell you to aim the putter. If you have an aiming error
        tendency and a miss tendency in the same direction, finding a putter
        that you can aim is often the simplest and most effective fix.
      </div>
      <div class="p-mb-2">
        FINDING THE PERFECT PUTTER When choosing a putter, prioritize the
        following criteria in this order: 1. Your ability to aim it 2. Solidness
        of the hit 3. Proper length and lie angle for your setup fundamentals 4.
        Weight 5. Balancing (toward the toe, heel, or face)
      </div>
      <div class="p-mb-2">
        Recently, an aspiring Tour professional called me in a panic because of
        his bad putting leading up to the second stage of PGA Tour Q-School.
        Even though he was missing all of his putts to the right, I knew that
        his stroke fundamentals were relatively sound, because he had sent me
        some video days earlier. So when I arranged to meet him at the
        tournament site, I told him to bring every good putter he owned. Sure
        enough, four of the five putters he brought, including the one he had
        been using leading up to the event, biased his aim well to the right.
        The one that didn’t, ironically, was devoid of any alignment markings,
        the absence of which forced him to look at the face to aim instead of
        the line on the putterhead. By finding the right putter design, and
        making a slight change in his training to boost his confidence, he
        putted lights-out and made it through qualifying with flying colors.
      </div>
      <div class="p-mb-2">
        When choosing a putter, forget about what it looks like. Can you aim it?
        Test different designs until you find one that fits your eye by setting
        up to a straight-in six-footer and asking a buddy to stand behind you
        and give you feedback on your aim.
      </div>
      <div class="p-mb-2">
        The second strategy to improve aim is to actually practice it in a
        learning environment. Without intelligent practice, aiming errors can
        and will pop up, mostly as a response to poor stroke fundamentals. For
        example, if you have a stroke that consistently produces a left miss and
        you hit enough putts, you’ll subconsciously develop a right-aim error.
        Your subconscious is taking care of you, so to speak, but it’s a short
        short-term solution beset by long-term inconsistency. The good news is
        that you can effectively change the way you see targets and aim at them
        through proper training in as little as three weeks. You’ll be doing
        this as part of Your Putting Solution training plan (Chapters 9–11), so
        where you currently stand with this ability is irrelevant. Once you get
        going with your putting workouts, you’ll soon be aiming perfectly
        without any doubts.
      </div>
      <div class="p-mb-2">
        Lastly, when all else fails, you can mark your ball with a line, stand
        behind it and use your binocular vision (in other words, while looking
        at it with both eyes—this is how we see in our day-to-day life) to align
        it toward your intended line, and then set the putterface perpendicular
        to the ball line. This eschews the traditional aiming process
        altogether, and has both its pros and cons. We’ll get into this
        technique more thoroughly in Chapter 6 when we broach green-reading
        skills.
      </div>
    </div>
  </Panel>
  <Panel header="Journal work" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        JOURNAL WORK Now that you’ve worked through the fundamentals of starting
        the ball on the correct line, take a moment to write down pertinent
        technical keys and feels in your journal. Note how far you stand from
        the ball when you confirm your setup in a mirror, along with any other
        particulars in your stance. Describe the sensations you feel when you’re
        performing the Suspension-Point, Worm Stability, and Three-Points
        drills. In no time at all, you should have a bullet-point list of the
        keys that you’ll commit to mastering. Use the example below to help you.
        MY PUTTING SOLUTION TECHNICAL KEYS Stable athletic stance. Toe line two
        putter widths from inside of ball. Nose just behind putter. Relaxed
        arms, square shoulders, shaft straight up and down (and pointing at my
        sternum). Suspension-point maintenance: “feel as if my hands and chest
        work together.” Quiet eyes, precise three-point scan. I feel most calm
        looking softly at the top of the ball.
      </div>
    </div>
  </Panel>
</template>
