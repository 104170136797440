<template>
  <Panel
    header="Enjoy Analyze Remember It: Training"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">STORING MEMORIES EXERCISES</div>
      <div class="p-mb-2">
        Hit twenty different shots while being objective in your post-shot
        reactions.
      </div>
      <div class="p-mb-2">
        Hit twenty different shots while being very positive in your post-shot
        reactions, no matter the outcome. Find something about which you can be
        genuinely happy related to the outcome of the shot or the process of
        hitting the shot.
      </div>
      <div class="p-mb-2">
        After each shot during a practice session, if you did not like the
        outcome, say something aloud that is objective, neutral or simply
        factual about the shot.
      </div>
      <div class="p-mb-2">
        After each shot during a practice session that you hit great, good or
        good enough, spend fifteen extra seconds feeling genuine pleasure about
        it in your heart.
      </div>
      <div class="p-mb-2">
        Hit ten great, good or good-enough shots and feel a positive emotion in
        your post-shot routine.
      </div>
      <div class="p-mb-2">
        Play six holes and be 100 percent objective after you finish each shot.
        Talk like a scientist, count, skip, hum a song.
      </div>
      <div class="p-mb-2">
        Experiment with different anchors to store positive shots. An anchor can
        be an extroverted display or an introverted acknowledgment. Is it an
        inward smile? A fist pump? A warm feeling in the stomach? A verbal
        anchor? What works best for you to create a connection with your good
        shots in order to store their memory?
      </div>
      <div class="p-mb-2">Functional Storage of Memory:</div>
      <div class="p-mb-2">
        You are able to catch yourself reacting in a negative way and turn it
        around to a factual and objective perspective.
      </div>
      <div class="p-mb-2">Masterful Storage of Memory:</div>
      <div class="p-mb-2">
        For eighteen holes, you have either been objective/neutral or
        positive/happy in each post-shot reaction. Your criterion for being
        happy about a shot is when it’s good enough (not perfect!).
      </div>
    </div>
  </Panel>
</template>
