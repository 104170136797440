<template>
  <Panel header="The Art of Chipping" :toggleable="true" :collapsed="true">
    <Panel header="Importance" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          Of all the shots you're going to learn in this book, the basic chip
          shot is certainly the most useful. The average player uses a chip
          shot—which I define as a shot where you're reducing the loft on your
          wedge as opposed to trying to use the bounce on the bottom of the
          club—more than any other short-game shot aside from a putt.
        </div>

        <div class="p-mb-2">
          Improving your chipping skill—to where you're legitimately upset if
          you don't get up and down—is a very quick way to really reduce your
          scores. You can make a lot of routine pars by chipping well after just
          missing a green, and you can salvage great bogeys on those holes where
          you hit a sketchy pitch shot after punching out from the trees. The
          math is pretty simple.
        </div>

        <div class="p-mb-2">
          It's certainly a realistic goal for you to get up and down from around
          the green 50 percent of the time—regardless of whether you're trying
          to get up and down for par or bogey. If you can get up and down 50
          percent of the time instead of 10 percent of the time, you're going to
          take three to five shots off your handicap.
        </div>
      </div>
    </Panel>
    <Panel header="Technique" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          As I said in the chapter about basics, what I'm trying to do is to get
          your short game in line with what you do in a full swing—keep the club
          moving on plane, make the clubhead end move more than the grip end—and
          let you take advantage of your athletic sense. In chipping, that
          starts with the setup.
        </div>
      </div>
      <img
        src="@/assets/images/utleyArtShortGame/chipping1.png"
        class="img3"
        alt="Sieckmann Finesse"
      />
      <div class="accent1 fontSmall p-mb-2">
        STANCE: I play my chip shots from a square stance, with the ball just
        forward of middle. My toe line is square to the target line or even a
        little bit closed, and my heels are about four inches apart.
      </div>
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          A chipping stroke is simply the hitting area of a full swing. If you
          could see a video of your full swing from the waist down, during the
          time your hands are below your waist, you'd find the chipping move.
          The only modifications are a narrower stance and your weight
          distributed to your front foot for the entire shot. “When I stand over
          a full iron shot with my 7-iron, my feet are hip to shoulder-width
          apart. For a chip, my feet are usually about the width of a clubhead
          apart—four to five inches. More importantly, I don't open my stance at
          all, or turn my foot out toward the target.
        </div>
        <div class="p-mb-2">
          For some reason, a lot of players when confronted with the smaller
          swing that a chip shot requires get bunched up close to the ball and
          squeeze the grip tightly, as if that's going to add precision to the
          shot. Actually, the opposite is true. I like to give myself enough
          room to allow my arms and the club to fully release in front of me at
          impact. I stand taller over the shot and play the ball farther away
          from me, in the same place I'd play it for a regular shot. I want my
          arms to swing easily in front of me, just like they would for a full
          shot. You may need to correct your posture (tilt from the hips, not
          the waist) and stand farther from the ball.
        </div>
        <div class="p-mb-2">
          I actually use the pivoting of my lower body to regulate a large
          portion of the speed that controls how far the shot goes. You don't
          want to feel like your lower body is encased in concrete, which I see
          all the time. When you shoot a jump shot in basketball, it's your legs
          that are generating most of the power in the shot. A chip shot is
          exactly the same. The lower body is an active part of this shot,
          without question. I think of my legs as the thrust and my arms as the
          steering wheel.
        </div>
        <div class="p-mb-2">
          The first few times you try this—pivoting with your lower body and
          letting the clubhead swing—you're going to feel like you have too much
          power behind the shot. That's going to be scary, and you might get
          tentative on a few, but give it some time. Remember, you have been
          using all arms for energy in your short-game shots before, so until
          you can coordinate taking arm swing out and adding pivot, your swing
          will be a little strong.
        </div>
        <div class="p-mb-2">
          You might be wondering why I use my 58-degree club instead of
          something with less loft. I've never been a proponent of using lots of
          different clubs around the green—say, using a 7-iron for a long,
          running chip and a sand wedge for something closer that requires a
          high-lofted shot. I think it's easier to develop confidence and touch
          with one club and adapt your trajectory with your swing, versus
          learning the feel of half your set to play shots around the green. I
          also think the 58-degree club is more versatile than a regular iron.
          Its sole is designed to perform a variety of functions — <b>dig</b> in
          to the grass if you play with the leading edge forward,
          <b>skid</b> across if you play the sole more flat, or <b>bounce</b> if
          you play it more open. You've got way more choices. And once you get
          the feel for the chip shot I teach, you're not going to be intimidated
          by the extra loft.
        </div>
        <div class="p-mb-2">
          I want my club to bottom out past the ball on a chip, so I make sure
          to set up with my weight favoring my left side, and I keep it that way
          throughout the swing. I've found that if you shift back at all on a
          chip shot, the tendency is to either hang back during the swing (and
          hit behind the ball), or sway forward and move the bottom of your
          swing around inconsistently. But just because my weight is forward
          doesn't mean I'm not making a pivot with my lower body. I'm
          essentially pivoting around my left leg. The small hip turn on the way
          back helps get the club moving on the right plane, and then you pivot
          through and let the action of pivoting propel the club through the
          ball. You're not making any conscious effort to throw the club through
          because your body motion is now helping you support the shaft, or
          creating lag in your wrist and hands. One thing many average players
          struggle with is getting too active with the right hand in an effort
          to lift the ball into the air. Pivoting will help take away that
          impulse to fire the right hand too aggressively.
        </div>
        <div class="p-mb-2">
          The sensation I'm feeling during my swing can be described as
          <b> fold, turn, and hold.</b> What surprises most amateurs when they
          “watch me hit a chip is how little the grip end of the club actually
          moves. When I hit a chip, the butt end of the grip pretty much stays
          in place, while I set the clubhead with my hands and right elbow.
          Watching how my elbows work will really give you an idea of how this
          is different than the way you probably do it. Instead of moving the
          entire grip back toward my right hip, I slide my right elbow back
          along my side and make my turn back. The clubhead end moves quite a
          bit, especially compared to the grip end.
        </div>
        <img
          src="@/assets/images/utleyArtShortGame/chipping2.png"
          class="img3"
          alt="Sieckmann Finesse"
        />
        <img
          src="@/assets/images/utleyArtShortGame/chipping3.png"
          class="img3"
          alt="Sieckmann Finesse"
        />
        <img
          src="@/assets/images/utleyArtShortGame/chipping4.png"
          class="img3"
          alt="Sieckmann Finesse"
        />
        <div class="accent1 fontSmall p-mb-2">
          Set Turn Hold: Notice how I'm leaned left at address. My body will
          pivot quietly around my left leg all the way through the shot. Look
          how much the clubhead end moves compared to how little the grip end
          moves (1, 2, 3). You can also see that there's a definite pivot—look
          at how my knees move, even on a short shot like this. Heading toward
          impact, my left wrist is bowed toward the target (5). The club is
          beginning to de-loft, and my hips are starting to open. The clubhead
          never gets any higher than my knees.
        </div>
        <div class="p-mb-2">
          Once I ge to the "top" of the backswing - remember, we're “alking no
          more than hip high for most chips—it's just a matter of pivoting
          through with my lower body first and the shoulders trailing, and
          letting the club release to the ground at the low point, which I
          established with my setup. The release of the club simply comes from
          letting my right elbow lengthen through impact. The sensation of
          “hold” comes from feeling the angle in my right wrist lag as the club
          swings through impact. Although my hands are passive on this shot, my
          forearms rotate smoothly throughout the downswing to assist the
          de-lofting motion of my wrists. Later on, I'll tell you about some
          more lofted shots that require you to use a lot more hand action, but
          for this basic one, quiet hands are what you need.
        </div>
        <img
          src="@/assets/images/utleyArtShortGame/chipping5.png"
          class="img3"
          alt="Sieckmann Finesse"
        />
        <img
          src="@/assets/images/utleyArtShortGame/chipping6.png"
          class="img3"
          alt="Sieckmann Finesse"
        />
        <img
          src="@/assets/images/utleyArtShortGame/chipping7.png"
          class="img3"
          alt="Sieckmann Finesse"
        />
        <div class="accent1 fontSmall p-mb-2">
          Set Turn Hold: My feet are slightly closed compared to the rest of my
          body. Notice how my arms are in front of me—I'm not cramped in too
          close to the ball. There's room to swing my arms. You can really see
          the knee action from this angle (4). The pivot happens first, and then
          my shoulders and the club follow. You can also see the bow in my left
          wrist after impact (5).
        </div>

        <div class="p-mb-2">xxxx</div>
      </div>
    </Panel>
    <Panel header="Shot Routine and Lies" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          Before I take you through my own pre-shot process for every chip I
          hit, let me make one important point about your comfort level. You
          could well have a shot you like that doesn't fit with the fundamentals
          I'm talking about here. I don't want you to abandon that shot. I'm
          certainly not a fan of doing anything that limits your creativity
          around the green. My goal is just to give you a bunch more options—a
          chip shot to go with a fringe putt or a bump-and-run 7-iron. By
          learning a variety of other chip shots, you're armed with some
          different choices for every situation you face.
        </div>
        <div class="p-mb-2">
          And let's talk more about these different situations. How do you know
          what shot to hit and what factors to take into consideration in your
          planning? How do you see the options in front of you? I'll describe
          what I do, then give you some advice about how to develop your own
          process.
        </div>
        <div class="p-mb-2">
          As I approach my ball, I certainly take into consideration the lay of
          the land—if the flag is on the same level as the ball, uphill or
          downhill, or tucked with not much green to work with—but I'm mostly
          focused on my lie. Is the ball in the rough, the fringe cut, “or the
          fairway cut? If it's in the rough, is the ball sitting down in the
          grass, or up on top of the grass? The lie doesn't so much determine
          what shot I can play as it does what shots I can't play.
        </div>
        <div class="p-mb-2">
          What I'm hoping for is a lie in which I don't have to make any special
          adjustments to try to get clean clubface contact on the back of the
          ball. If I get it, I'm automatically in offensive mode. I'm trying to
          make the shot. I'll start by walking up from my ball to the hole to
          get a sense for how the green breaks. This shot is going to roll like
          a putt for more than half of its length, so when players simply aim it
          at the hole I think they're giving away a big source of advantage. An
          average chip might have three or four feet of break in it. Aiming
          correctly is the difference between leaving yourself a three-footer or
          a six-footer. At the tour level, that's the “difference between making
          85 percent of the putts you have left to making less than half. In
          other words, it's a big deal.
        </div>
        <div class="p-mb-2">
          On my walk to the hole (which I do pretty quickly, so I'm not holding
          up play), I'm paying particular attention to the area where I want my
          shot to land. Is the green soft or firm? Is my shot going to kick
          right? Kick left? Am I chipping into the grain or down grain? If I'm
          chipping with the grain, I'm going to hit the shot less hard than I am
          chipping into the grain. My goal is to find a spot to land the ball
          that's relatively flat and predictable. You don't always get that
          luxury, but it's what I'm shooting for.
        </div>
        <div class="p-mb-2">
          The landing spot I pick is the size of a basketball hoop—pretty
          precise, but not as precise as a particular blemish on the green or
          something like that. It's not a dot or a grain or a blemish on the
          green. From the spot I pick, I'll walk it off and count how many paces
          it is back to the ball. That's been a part of my routine for more than
          twenty years—counting the yards to the spot I want the ball to land.
          I'll literally say “three” to myself if it's 3 yards to the spot I
          pick and I'm trying to pitch it 9 feet to there. I believe this
          routine helps my feel on a subconscious level, instead of trying to
          measure my swing to a shot distance.
        </div>
        <div class="p-mb-2">
          Before I play a chip or pitch, I'll actually walk up to the hole from
          where my ball is to read the terrain and get a sense of where I want
          to land my shot.
        </div>
        <div class="p-mb-2">
          Once I have my measurement and my aim, I go into a pre-shot routine,
          just like I would for a full shot (or even a putt). As I said before,
          I'm chipping with an attitude. I'm intent on coming as close as
          possible to jarring the chip. At worse, I'm going to be just a foot or
          two from the hole. I think it helps to feel that kind of confidence.
          If you're getting upset when a shot ends up three feet from the hole,
          that's a sign your short game is getting a lot better. I'm thinking
          you'll be pretty happy about that.
        </div>
        <div class="p-mb-2">
          Taking on that attitude depends on your skill level, for sure. I'd
          estimate that I'm chipping it with the same attitude you'd have
          hitting a 10-foot putt. My internal success or failure rate has to do
          with whether or not it had a chance to go in the hole. If I hit it and
          the first bounce kicks it two feet left and it never has a chance,
          that's something that really bothers me. Either I read it wring or hit
          it wrong.
        </div>
        <div class="p-mb-2">
          One question I get an awful lot is whether to leave the pin in or take
          it out. I have a strong opinion that the pin does not help good shots.
          If I want the ball to end up in the hole, I take the pin out. If I can
          see the hole from where I am, I take the pin out. If I have a downhill
          shot, or something super hard where I'm going to have trouble managing
          or predicting the amount my shot is going to roll, I might leave it
          in. If a shot is really that scary, I might not even chip it, and
          instead play it with my putter and try to lag it up there.
        </div>
        <div class="p-mb-2">
          But if I think my ball is going to come up to the hole rolling like a
          putt, I want the pin to be out. I've seen lots of good shots dink off
          of the pin and end up a foot away, and that's certainly enough to
          drive you nuts. Put it this way: If you had a four-footer you
          absolutely had to make, with the choice to have the pin in or out,
          would you leave the pin in? I seriously doubt it. You want the ball to
          have the best chance to fall into the hole.
        </div>
        <div class="p-mb-2">
          If you're in the rough where there will be grass between the face and
          the ball at impact, and a chip shot is the obvious play, there are a
          couple of keys to remember. First, the clubhead is more likely to get
          caught up in the grass, so you have to keep your pivot moving strongly
          through impact. You also will be contacting the grass first from this
          lie, so you have to plan for the ball to release more than a cleanly
          struck chip. Many times from this lie, you will want to consider
          hitting a pitch shot, which we'll be covering in the next chapter.
        </div>
        <div class="p-mb-2">
          Since the contact you make from the rough makes controlling the spin
          on the ball very tough, this shot is less predictable, and you have to
          reduce your expectations a little. By far the most common mistake
          players make from thicker grass is to underestimate how much clubhead
          speed it takes to get the club “through the grass. The result is
          usually a fluffed chip that moves about three feet. The grass closes
          down the clubface and you just smother it.
        </div>
        <div class="p-mb-2">
          The best way to get a feel for this shot—and judging distance—is to go
          out to a practice green with a bucket of balls and hit shots. That
          sounds like obvious advice, but I'm not talking about doing what most
          people do when they “practice” short game. Standing in the same spot
          and hitting a bunch of shots aimlessly onto a green without any
          specific target, or any sense of feedback about whether or not the
          shot was successful, is only marginally better than not practicing at
          all. It's really exercise—or work on your tan—more than practice.
        </div>
        <div class="p-mb-2">
          You can get tremendous benefit from one practice session if all you do
          is hit three shots after picking a specific landing area and judging
          how well you did hitting each shot close to the eventual target, then
          changing to a different target and hitting three more shots, and so
          on. What you're doing by changing targets is developing the sense of
          distance control that separates decent chippers from great ones. By
          moving around the edges of the practice green, you're entering a lot
          of different lies and situations in your memory banks. When you face
          that shot out on the course, you won't be too surprised. Bare lie from
          mostly dirt to a pin on a tier? It's a lot less nerve-racking if
          you've experimented with that shot at the practice green.
        </div>
      </div>
      <div class="p-mb-2">
        Another thing I like to do before a round is take two balls and drop
        them into different lies around the practice green, then challenge
        myself to get up and down. Making a game out of it adds some pressure
        that isn't there when you're just out by yourself hitting practice
        shots. It's also fun to pair up with a buddy and have a little
        up-and-down challenge. It's amazing how your focus improves when
        bragging rights are involved.
      </div>
    </Panel>
  </Panel>
  <Panel header="The Art of Pitching" :toggleable="true" :collapsed="true">
    <Panel header="Importance" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          Your chipping skills are going to help you when the flag is closer to
          the center of the green, you've got plenty of room to work with, and
          you've got room to roll the ball up like a putt. As the pin moves more
          toward the sides of the green, or moves closer to some kind of
          obstacle like a bunker or a water hazard, your pitching game comes
          into play. The ability to play a shot with more loft gives you so much
          more flexibility in how you approach a short-game situation.
        </div>
        <div class="p-mb-2">
          First things first: I'd like you to stop thinking of a pitch as simply
          as a shot for a situation when you're faced with a longer distance to
          negotiate. It can be that, but that's not all it is. I define a pitch
          shot as a shot where you're using the bounce on the bottom of the club
          (instead of the leading edge) and then releasing your hands through
          impact to make the ball fly up in the air. You can hit a pitch shot
          from right next to the green to a pin that's only five or six yards on
          the putting surface, just like you could play a 30-yard chip shot from
          in front of the green to keep the ball under a really strong wind.
          It's a difference in how you hit the shot, not in how long it is.
        </div>
        <div class="p-mb-2">
          You'll probably hit more chip shots during a given round than pitch
          shots, but understanding how to loft the ball more in the air is
          certainly valuable. You're going to have them a lot on holes where you
          run into problems off the tee and have to lay up with your approach
          shot. If you can hit some of those pitches close, you're obviously
          going to take a lot of pressure off your putting game. You're not
          always going to have a direct path along the ground to hit a chip
          shot—you might have to carry a bunker, or go over a finger of rough
          that cuts between one corner of the green and the other. A pitch shot
          really comes in handy on greens that have a lot of undulation. I'll
          hit one and completely take out a slope or tier, so I don't have to do
          as much figuring about what a shot hit lower along the ground would do
          in terms of break. And just like the chip shot, I'm not trying to put
          backspin on the ball and control the shot with spin. I'm letting the
          loft do the work, so that the ball flies high and lands soft. It's a
          great weapon.
        </div>
      </div>
    </Panel>
    <Panel header="Technique" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          As we talked about in the basics chapter, your setup isn't going to be
          much different for a pitch shot than it is for a chip. As the shot
          gets longer, your stance gets a little bit wider. On a short pitch—say
          15 yards—my feet will be the same distance apart as they would be on a
          chip shot, about four inches. Once I get up to a full pitch shot of 40
          or 50 yards, my feet are going to be just a bit less than hip-width
          apart, and not much different than they'd be for a full shot with a
          wedge.
        </div>
        <img
          src="@/assets/images/utleyArtShortGame/pitching1.png"
          class="img3"
          alt="Ultman pitching"
        />
        <div class="accent1 fontSmall p-mb-2">
          STANCE: I play my chip shots from a square stance, with the ball just
          forward of middle. My toe line is square to the target line or even a
          little bit closed, and my heels are about four inches apart.
        </div>
        <div class="p-mb-2">
          These are some pretty small swings, and a consistent pivot point is
          going to help you bring the club back through the impact area
          consistently every time and give you a lot of precision. Pivoting
          consistently can be a challenge when you've got two legs to balance on
          and you're holding the club with two hands. That's why I like to start
          with my weight to the left, toward the target, and maintain that
          through my swing. I'm essentially establishing my left leg as a fixed
          pivoting point down to the ground, and turning around my left leg back
          and through. You don't need a big weight shift to develop power. We're
          talking about, at most, a 40-yard shot.
        </div>
        <div class="p-mb-2">
          For a pitch shot, my hands are just slightly ahead of the ball at
          address, and my feet are eight to ten inches apart. As the pitch shot
          gets shorter, my feet move closer together from shoulder width down to
          four inches. That makes it easier to keep the swing moving around the
          pivot.
        </div>
        <div class="p-mb-2">
          The main thing that will be different in your pitch setup compared to
          the chip setup is how you set the shaft at address. On a chip shot,
          your hands are further ahead of the ball, and you're hitting the shot
          with the grip end of the club leaning forward, with as much as 20
          degrees of de-loft toward the target. The club hits the ball first,
          and then the leading edge of the club comes in contact with the
          ground. On a pitch shot, the hands are still a bit ahead at address,
          but the shaft is closer to vertical. You're hitting the ground with
          the bounce on the bottom of the club and using the loft to send the
          ball up into the air.
        </div>
        <img
          src="@/assets/images/utleyArtShortGame/pitching2.png"
          class="img3"
          alt="Ultman pitching"
        />
        <img
          src="@/assets/images/utleyArtShortGame/pitching3.png"
          class="img3"
          alt="Ultman pitching"
        />
        <img
          src="@/assets/images/utleyArtShortGame/pitching4.png"
          class="img3"
          alt="Ultman pitching"
        />
        <img
          src="@/assets/images/utleyArtShortGame/pitching5.png"
          class="img3"
          alt="Ultman pitching"
        />
        <div class="p-mb-2">
          My pitching setup is neutral—there's no opening of my toe line or
          moving the ball back (1). My arms are hanging straight down, too. In
          frame 3, notice how the club is directly between my arms and parallel
          to the target line. Before impact, my hips open, but my shoulders stay
          closed (5). The swing follows the shoulder line, so it's going to the
          inside. In picture 8, it's obvious that I haven't hung back at all. My
          chest is up over my left leg.
        </div>
        <div class="p-mb-2">
          In the last chapter, we talked about fold, turn, and hold—the “feels”
          for a chip shot. On a pitch shot, you're going to feel the first two
          the same way, sliding the right elbow back along your side as you
          pivot back, but your hand and wrist action will become a much larger
          piece of the equation.
        </div>
        <div class="p-mb-2">
          Even though my arms are going to get up just above my waist, the butt
          end of the club is not going to travel very far from where it started
          when I was at address. I'm adding wrist cock—the hinging of my
          wrists—which increases the distance the clubhead end of the club
          travels.
        </div>
        <div class="p-mb-2">
          The top of the backswing on a chip is well below the waist. For a
          20-yard pitch, however, my arms are going to get waist high and the
          club will point pretty close to straight up in the air. Just like with
          the chip, it's then a matter of pivoting through with my lower body
          first and the shoulders trailing, and letting the club hit the ground
          at the low point, which I established with my setup. But instead of
          holding and turning, I'm going to release the club. You're probably
          asking, what does that mean, exactly?
        </div>
        <div class="p-mb-2">
          Let's talk a little bit about the concept of “release,” as it relates
          to pitch shots. On a chip shot, you're pretty much hitting the shot
          with your pivot, while your elbows simply slide along your sides. Your
          wrists don't actively unhinge in a big way through impact. On a pitch
          shot, your wrists work freely in a vertical hinging motion. My analogy
          is using a sledgehammer with both hands, or driving a nail one-handed.
          If you apply this wrist action along the right swing plane (I prefer a
          shallow plane), the clubhead will return back to impact squarely with
          plenty of speed.
        </div>
        <div class="p-mb-2">
          You will need to rotate your forearms during the takeaway in order to
          set your wrists and hands on-plane. The left wrist will be cupped at
          the end of your backswing, just as it would be if you raised a
          sledgehammer straight up in front of you. You want to feel the release
          of your wrists and clubhead early in the downswing. It may take you a
          few swings to coordinate your new release with your smooth lower-body
          pivot in the through swing.
        </div>
        <div class="p-mb-2">
          As the club comes through impact, from the face-on view, you'd see the
          shaft vertical or even tilted a little bit back, away from the target,
          on a very high shot. The bounce on your sand wedge will skip through
          the turf cleanly. After impact, your left wrist and elbow will both
          give way to fully release the clubhead. Your left wrist will cup, and
          hyour right palm will stay facing the sky, which means there's almost
          no forearm rotation in the through swing. “Your hands and arms will
          travel along your body as the left elbow folds just past impact. On
          most simple pitches, I prefer the hands to finish low as the club
          finishes left and up, higher than on a chip shot. At this point, I
          hope you are understanding the relationship between the left-wrist
          position at impact and the loft on the clubface.
        </div>
        <div class="p-mb-2">
          The biggest struggle I see for the average player comes from the
          impulse to move the arms faster and pull on the grip end of the club
          on the downswing in an effort to generate clubhead speed. A pitch shot
          isn't any different than a full swing in that pulling hard on the grip
          end doesn't necessarily speed up the clubhead. In fact, if you do move
          the grip end too fast, you'll probably come to impact with an open
          clubface, which will cause you to hit shots high and short.
        </div>
        <div class="p-mb-2">
          How does the grip end get to be moving too fast? Too much arm swing.
          If you don't turn the right way and use your pivot to develop speed at
          the clubhead end of the club, you compensate by making a bigger arm
          swing to try to find the energy to actually hit the ball. When you
          start making a big arm swing with no body turn, you pull the handle of
          the club more (and out of position), and—most importantly—you lose
          feel in your hands. When the body works well and you minimize your arm
          swing, it's incredible how responsive and sensitive your hands get.
        </div>
        <img
          src="@/assets/images/utleyArtShortGame/pitching6.png"
          class="img3"
          alt="Ultman pitching"
        />
        <img
          src="@/assets/images/utleyArtShortGame/pitching7.png"
          class="img3"
          alt="Ultman pitching"
        />
        <img
          src="@/assets/images/utleyArtShortGame/pitching8.png"
          class="img3"
          alt="Ultman pitching"
        />
        <img
          src="@/assets/images/utleyArtShortGame/pitching9.png"
          class="img3"
          alt="Ultman pitching"
        />
        <div class="p-mb-2">
          Aside from the narrower stance, this setup looks just like my setup
          for a full shot with a short iron (1). Notice how I'm extending my
          arms, not getting too close to the ball so that my arms brush against
          my chest. As the club moves back, notice how it stays on the plane
          line on which it started. The clubface in picture 3 isn't shut—it's on
          the same angle as my spine. The “eading edge is not supposed to be
          pointing at the sky. In picture 7, you can see that just past impact,
          my hands have moved farther left than the clubhead, meaning I've kept
          the angle in my wrist.
        </div>
        <div class="p-mb-2">xxx</div>
        <div class="p-mb-2">xxx</div>
        <div class="p-mb-2">xxx</div>
      </div>

      <img
        src="@/assets/images/utleyArtShortGame/pitching10.png"
        class="img3"
        alt="Ultman pitching"
      />
      <img
        src="@/assets/images/utleyArtShortGame/pitching11.png"
        class="img3"
        alt="Ultman pitching"
      />
    </Panel>
  </Panel>
</template>
