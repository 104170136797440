<template>
  <Panel
    header="See It: Think Box (decide and commit) Training"
    :toggleable="true"
    :collapsed="true"
  >
    <Panel
      header="Decision-making practice"
      :toggleable="true"
      :collapsed="true"
    >
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          Hit shots on the practice area or on the golf course and say your
          decision about the shot aloud before stepping into the Play Box. What
          do you say and how do you say it?
        </div>
        <div class="p-mb-2">
          While on the course or on the practice area, experiment with
          minimizing the amount of information you need before the shot. Start
          by looking at the target and intuitively choosing a club before
          hitting your shot, without checking the yardage. Begin again, and add
          more information before making the decision. Try this numerous times
          on a scale of one to four, with one being acting only on instinct and
          four being a flood of information. Do this a few times. What amount of
          information between one and four allows you to execute the best in the
          Play Box?
        </div>
        <div class="p-mb-2">
          Hit six putts from a distance of fifteen feet. Change your preparation
          level for each. On the first putt, play like a kid and do nothing in
          preparation. For each subsequent putt, add some more preparation. On
          the sixth putt, check everything. No stones unturned! Where in that
          spectrum did you putt the best?
        </div>
        <div class="p-mb-2">
          Hit ten different putts from fifteen feet. For each putt before
          stepping over the Decision Line, imagine in vivid detail how the ball
          will roll into the cup.
        </div>
        <div class="p-mb-2">
          Hit shots where you alternate between stating your decision with a
          weak and indecisive voice and body language, and doing it as if you
          were the most confident player in the world. What is the difference
          for you?
        </div>
      </div>
    </Panel>
    <Panel header="Commitment practice" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          Check your level of commitment. Physically create a separation between
          the Think Box and the Play Box by placing a string or a club on the
          ground behind the ball. Hit ten shots with different clubs, doing your
          full routine. What are you feeling when you cross this Decision Line
          from the Think Box into the Play Box? What percentage of shots did you
          hit while fully committed? Do the same on the putting green.
        </div>
        <div class="p-mb-2">
          For a full practice session on the range, short-game area and putting
          green, only hit shots when you are fully committed. If you feel any
          hesitation, step back across the line and reload. How often did you
          need to step back? Commitment is a skill that needs to be trained and
          maintained.
        </div>
        <div class="p-mb-2">
          Hit ten consecutive shots with full commitment. If any of the shots
          has less than 100 percent commitment, start over. Mix it up with
          different types of shots. Do your complete routine.
        </div>
        <div class="p-mb-2">
          Hit twelve consecutive putts where your commitment to the decision is
          100 percent.
        </div>
        <div class="p-mb-2">
          Hit three different tricky chip shots, three different tricky pitch
          shots, and three different tricky bunker shots. Did you have full
          commitment for each shot?
        </div>
      </div>
    </Panel>
    <Panel
      header="Decide and Commit practice"
      :toggleable="true"
      :collapsed="true"
    >
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          Hit shots on the range with a friend. Have the friend pick the target
          and tell you what kind of shot to hit. It could be a 5-iron low draw
          to a one-hundred-yard marker. How committed did you stay to the
          decision? Do this ten times, then make your own decisions and commit
          to them for another ten shots.
        </div>
        <div class="p-mb-2">
          Keep track of how well you commit to your decisions for each shot
          during a round. On what percentage of the shots did you fully commit?
        </div>
        <div class="p-mb-2">Functional Decide and Commit:</div>
        <div class="p-mb-2">
          You can commit fully to your decision for ten shots in a row on the
          course.
        </div>
        <div class="p-mb-2">Masterful Decide and Commit:</div>
        <div class="p-mb-2">
          For eighteen holes you are 100 percent fully committed to your
          decision for every shot.
        </div>
      </div>
    </Panel>
  </Panel>
</template>
