import EventService from "@/services/EventService.js";

export const namespaced = true;

export const state = {
  postNote: {}, // Round data that was posted NOT FROM A RESPONSE
  InterShotRoutine: {},
  ShotRoutine: {},
  PlayerInterShotRoutine: {},
  PlayerSeeIt: {},
  PlayerTrustIt: {},
  PlayerEARIt: {},
  PlayerLetItGo: {},
  Player: {},
  NotPlayer: {},
  FullSwingTrustIt: {},
  PartialTrustIt: {},
  PitchChipTrustIt: {},
  BunkerTrustIt: {},
  PuttingTrustIt: {},
  BttFeelIt: {},
  FullSwingFeelIt: {},
  PartialFeelIt: {},
  PitchChipFeelIt: {},
  BunkerFeelIt: {},
  PuttingFeelIt: {},
  AbnStanceFeelIt: {},
  AbnLieFeelIt: {},
};

export const mutations = {
  SET_POST_NOTE(state, note) {
    state.postNote = note;
  },
  SET_InterShotRoutine(state, note) {
    state.InterShotRoutine = note;
  },
  SET_ShotRoutine(state, note) {
    state.ShotRoutine = note;
  },
  SET_PlayerInterShotRoutine(state, note) {
    state.PlayerInterShotRoutine = note;
  },
  SET_PlayerSeeIt(state, note) {
    state.PlayerSeeIt = note;
  },
  SET_PlayerTrustIt(state, note) {
    state.PlayerTrustIt = note;
  },
  SET_PlayerEARIt(state, note) {
    state.PlayerEARIt = note;
  },
  SET_PlayerLetItGo(state, note) {
    state.PlayerLetItGo = note;
  },
  SET_NotPlayer(state, note) {
    state.NotPlayer = note;
  },
  SET_FullSwingTrustIt(state, note) {
    state.FullSwingTrustIt = note;
  },
  SET_PartialTrustIt(state, note) {
    state.PartialTrustIt = note;
  },
  SET_PitchChipTrustIt(state, note) {
    state.PitchChipTrustIt = note;
  },
  SET_BunkerTrustIt(state, note) {
    state.BunkerTrustIt = note;
  },
  SET_PuttingTrustIt(state, note) {
    state.PuttingTrustIt = note;
  },
  SET_BttFeelIt(state, note) {
    state.BttFeelIt = note;
  },
  SET_FullSwingFeelIt(state, note) {
    state.FullSwingFeelIt = note;
  },
  SET_PartialFeelIt(state, note) {
    state.PartialFeelIt = note;
  },
  SET_PitchChipFeelIt(state, note) {
    state.PitchChipFeelIt = note;
  },
  SET_BunkerFeelIt(state, note) {
    state.BunkerFeelIt = note;
  },
  SET_PuttingFeelIt(state, note) {
    state.PuttingFeelIt = note;
  },
  SET_AbnStanceFeelIt(state, note) {
    state.AbnStanceFeelIt = note;
  },
  SET_AbnLieFeelIt(state, note) {
    state.LieFeelIt = note;
  },

  CLEAR_ALL_DATA(state) {
    state.postNote = {};
    state.InterShotRoutine = {};
    state.ShotRoutine = {};
    state.PlayerInterShotRoutine = {};
    state.PlayerSeeIt = {};
    state.PlayerTrustIt = {};
    state.PlayerEARIt = {};
    state.PlayerLetItGo = {};
    state.NotPlayer = {};
    state.FullSwingTrustIt = {};
    state.PartialTrustIt = {};
    state.PitchChipTrustIt = {};
    state.BunkerTrustIt = {};
    state.PuttingTrustIt = {};
    state.BttFeelIt = {};
    state.FullSwingFeelIt = {};
    state.PartialFeelIt = {};
    state.PitchChipFeelIt = {};
    state.BunkerFeelIt = {};
    state.PuttingFeelIt = {};
    state.AbnStanceFeelIt = {};
    state.AbnLieFeelIt = {};
  },
};

export const actions = {
  async postNote({ commit }, note) {
    try {
      const response = await EventService.postNote(note);
      commit("SET_POST_NOTE", note); // only if await promise is resolved not rejected
      console.log(`note.js response: ${response.status}`);
    } catch (err) {
      console.log(`note.js postNote error: ${err}`);
    }
  },
  async getLatestNote({ commit }, text_code) {
    try {
      const response = await EventService.getLatestNote(text_code);
      const note = response.data[0];
      // console.log(`text_code: ${text_code}`);
      // console.log(`note.js note: ${note.text_content}`);
      switch (text_code) {
        case "InterShotRoutine":
          commit("SET_InterShotRoutine", note); // only if await promise is resolved not rejected
          break;
        case "ShotRoutine":
          commit("SET_ShotRoutine", note); // only if await promise is resolved not rejected
          break;
        case "PlayerInterShotRoutine":
          commit("SET_PlayerInterShotRoutine", note); // only if await promise is resolved not rejected
          break;
        case "PlayerSeeIt":
          commit("SET_PlayerSeeIt", note); // only if await promise is resolved not rejected
          break;
        case "PlayerTrustIt":
          commit("SET_PlayerTrustIt", note); // only if await promise is resolved not rejected
          break;
        case "PlayerEARIt":
          commit("SET_PlayerEARIt", note); // only if await promise is resolved not rejected
          break;
        case "PlayerLetItGo":
          commit("SET_PlayerLetItGo", note); // only if await promise is resolved not rejected
          break;
        case "NotPlayer":
          commit("SET_NotPlayer", note); // only if await promise is resolved not rejected
          break;
        case "FullSwingTrustIt":
          commit("SET_FullSwingTrustIt", note); // only if await promise is resolved not rejected
          break;
        case "PartialTrustIt":
          commit("SET_PartialTrustIt", note);
          break;
        case "PitchChipTrustIt":
          commit("SET_PitchChipTrustIt", note);
          break;
        case "BunkerTrustIt":
          commit("SET_BunkerTrustIt", note);
          break;
        case "PuttingTrustIt":
          commit("SET_PuttingTrustIt", note);
          break;
        case "BttFeelIt":
          commit("SET_BttFeelIt", note); // only if await promise is resolved not rejected
          break;
        case "FullSwingFeelIt":
          commit("SET_FullSwingFeelIt", note); // only if await promise is resolved not rejected
          break;
        case "PartialFeelIt":
          commit("SET_PartialFeelIt", note);
          break;
        case "PitchChipFeelIt":
          commit("SET_PitchChipFeelIt", note);
          break;
        case "BunkerFeelIt":
          commit("SET_BunkerFeelIt", note);
          break;
        case "PuttingFeelIt":
          commit("SET_PuttingFeelIt", note);
          break;
        case "AbnStanceFeelIt":
          commit("SET_AbnStanceFeelIt", note);
          break;
        case "AbnLieFeelIt":
          commit("SET_AbnLieFeelIt", note);
          break;
        default:
          console.log(`Not a recognized text_code: ${text_code}`);
      }

      console.log(`note.js response: ${response.status}`);
      return response.data[0];
    } catch (err) {
      console.log(`note.js getLatestNote error: ${err}`);
    }
  },
};

export const getters = {};
