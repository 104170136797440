<template>
  <Panel header="Physiology of Emotion" :toggleable="true" :collapsed="true">
    <img
      class="img4"
      src="@/assets/images/mental/physiology.png"
      alt="Tough Minded"
    />
    <div class="accent1 fontSmall p-mb-2">Physiology of Emotion</div>

    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Understanding the physiology of emotion is useful to understand
        Anxiety-Arousal and its management, and how process orientation works to
        enhance Emotional Stability
      </div>
      <div class="p-mb-2">
        Experiencing an emotion (limbic system activation) releases hormones &
        activates pathways (to neocortex and to periphery) that affect the
        brain, and physical function positively and negatively.
      </div>
      <div class="p-mb-2">
        Hormones such as adrenalin, or pathways from limbic system (serotonin,
        dopamine, opiods) can modulate any/all mental skills - decisiveness,
        focus, athleticism, and control of self-talk, memory, state monitoring.
        The effect on mental skills is enhancing with a positive emotion, and
        disruptive with a negative emotion.
      </div>
      <div class="p-mb-2">
        In addition, peripheral hormones ( neuroendocrine systems) and the
        autonomic nervous system can modulate any/all physical phenomena such as
        kinesthetic feel and motor control. These phenomena are facilitated by a
        positive, and impaired by a negative emotion.
      </div>
      <div class="p-mb-2">
        The brain, ANS, and neuroendocrine systems have strong homeostatic
        feedback effects on the limbic system. That is, changes in the lungs,
        gut, heart and muscles result in changes in the limbic system. Conscious
        modulation of breathing and muscles (relaxation/ contraction) makes use
        of these feedback loops to modify the limbic system, that is, to modify
        emotions. In turn this consciously elicited emotion feeds forward from
        the limbic system to modulate mental skills and peripheral sensory/motor
        function.
      </div>
      <div class="p-mb-2">
        Similarly, the cortex has many pathways feeding into the limbic system.
        Therefore conscious use of memory, commands, stories, sensory perception
        can affect limbic system function to cause positive or negative
        emotions. These elicited emotions can then affect any/ all golfing
        mental skills, and any/all peripheral sensory or motor skills.
      </div>
      <div class="p-mb-2">
        Conscious modulation of breathing, body language, and muscle tone cause
        rapid alterations of emotional tone usually of short duration.
        Biofeedback techniques such as control of cardiac bear-to-beat
        variability, affect hormonal levels that have a subacute onset and
        effects lasting minutes to hours.
      </div>
    </div>
  </Panel>
  <Panel header="Arousal Emotion Grid" :toggleable="true" :collapsed="true">
    <img
      class="img4"
      src="@/assets/images/mental/arousalEmotionGrid1.png"
      alt="Tough Minded"
    />
    <div class="accent1 fontSmall p-mb-2">
      Oversimplified for golf: consider an arousal-emotion grid
    </div>
    <img
      class="img4"
      src="@/assets/images/mental/arousalEmotionWheel.png"
      alt="EmotionWheel"
    />
    <div class="accent1 fontSmall p-mb-2">
      Oversimplified for golf: consider an arousal-emotion grid
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Arousal/Energy: Adrenaline rapidly and powerfully affects energy
        (internal/external event - limbic emotion - hypothalamus - symp nerves -
        adrenal cortex - adrenaline). Increasing adrenaline increases energy,
        and decreasing adrenaline decreases energy. Emotions: activation within
        the limbic system results in the experience of emotions - positive or
        negative, which then feedback to modulate brain and body function
        (hormonal and neural pathways). If energy level is high, positive
        emotion is experienced as joy and engagement and negative emotion is
        experienced as anger, frustration. If energy level is low, positive
        emotion is experienced as calm, composed and negative emotion as
        depression, apathy.
      </div>
      <div class="p-mb-2">
        Different hormones can have central and peripheral effects of different
        durations. An acute stress (dumping shot in water) causes a rapid rise
        in Adrenaline. Usually both central and peripheral effects of adrenaline
        are short-lived. Also breathing with exhale twice as long as inhale can
        rapidly bring down Adrenaline levels. By contrast, the adrenal
        corticoids (DHEA and cortisol), and other peptide hormones, usually only
        rise after longer stimulation, and their effects are more long lasting.
        Continual stress over a few holes will increase cortisol - this may have
        a negative effect on emotions lasting hours. Conversely, prolonged
        success (or biofeedback) may increase DHEA (and peptides) which may have
        a positive effect on emotions for hours. One resilience technique is to
        increase DHEA before a round with biofeedback, this would then
        counteract any cortisol (or other mechanisms) if the round produces
        prolonged stress. You literally have more chemical resistance to
        prolonged stress, and so will find it easier to be decisive, focused and
        trusting during prolonged stress. N.B., Taking synthetic DHEA is illegal
        as it is considered a performance enhancer.
      </div>
      <div class="p-mb-2">
        Vagal entrainment: 7 ways to stimulate vagus
        https://www.realwayoflife.com/en/2019/08/14/7-ways-to-stimulate-our-vagus-nerve-improve-our-emotional-responses-relationship-skills-and-favour-change/
        Non-invasive vagal nerve stimulation
      </div>
    </div>
  </Panel>
</template>
