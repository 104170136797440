<template>
  <span class="accent1"></span>
  <Panel header="Importance" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Many golfers underestimate the importance of the address, or starting
        position, in golf, believing that it's okay to be idiosyncratic when it
        comes to such elements as grip, stance, posture, and weight
        distribution. However, this is simply not the case. In golf, the
        <span class="accent1">address</span> is truly the engine room of the
        swing, because it ha such a
        <span class="accent1">tremendous effect</span> on the
        <span class="accent1">type of swing</span> you will employ. More
        importantly, because the mechanics of the one-plane and two-plane swings
        vary, it is not only <span class="accent1">essential</span> that you
        <span class="accent1">stick to the address fundamentals,</span> but it
        is also critical that you focus on the ones
        <span class="accent1"
          >that apply to your specific type of technique.</span
        >
        Mixing one- and two-plane fundamentals is like mixing oil and water.
        Therefore you need to wipe from the chalkboard of your mind those
        truisms previously listed as evergreen basics—neutral grip, square
        stance, and balanced weight distribution. It is possible that one or two
        of the so-called classic fundamentals will apply to your personalized
        swing. If that's the case, you are ahead of the game. However, what is
        more important is that you become a purist and adopt all the
        fundamentals for the one-plane swing or two-plane swing that I will now
        map out, starting with the proper way to grip the club when addressing
        the ball.
      </div>
    </div>
  </Panel>
  <Panel header="Grip" :toggleable="true" :collapsed="true">
    <div>
      <img src="@/assets/images/hardy1/hardyGrip1.png" alt="Hardy Posture" />
      <div class="accent1 fontSmall p-mb-2">
        One-plane swingers should choose to play with either a neutral grip
        (left) or a strong grip (right).
      </div>
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Before describing the positioning of the hands on the club, let's first
        make sure you understand the two types of grip that I consider
        acceptable. The <span class="accent1">overlap grip,</span> popularized
        by Harry Vardon, features a connection of the hands by the overlapping
        of the little finger of the right hand between the forefinger and middle
        finger of the left hand. The overlap is the most traditional of grip
        styles and is still used by the majority of better golfers. A very
        viable alternative is the
        <span class="accent1">interlock-grip</span> style. The only difference
        between these two options is that in the interlock grip, instead of the
        little finger of the right hand overlapping the left, that finger is
        interlocked between the left forefinger and middle finger. Some golfers
        who have shorter fingers feel that this grip unifies or knits the hands
        a bit more securely. As long as your hands remain unified, either style
        could work for you.
      </div>
      <div class="p-mb-2">
        Of greater importance than whether you overlap or interlock the fingers
        is the positioning of the hands in relationship to the club's handle.
        For the player intent on developing a one-plane swing, I advocate a grip
        that
        <span class="accent1"
          >places the hands in a neutral-to-strong position.</span
        >
        This means that, while the palms of the hands are facing each other,
        they are both turned slightly to the right, so that the back of your
        left hand is facing just slightly upward, as opposed to pointing
        directly at the target. To put it another way, the hands are considered
        to be in a neutral position if, when you look down at address, you can
        see <span class="accent1">two knuckles</span> of your left hand. If you
        turn both hands slightly more to the right so that you can see
        <span class="accent1">three knuckles</span> of your left hand, then your
        hands are in a strong position. In analyzing your grip, make sure you
        look straight down from the address position in order to assess your
        grip position correctly. So, when I say the one-plane player should have
        a neutral-to-strong grip, I mean that your hands should be positioned so
        that when you look down at address, you see at least two knuckles on
        your left hand, but never more than three knuckles.
      </div>
      <div class="p-mb-2">
        You probably want to know why the one-plane swinger should employ a
        neutral-to-strong grip position. You'll understand this instructional
        element more fully when we discuss the action of the swing itself.
        However, for now, all you need to understand is that a neutral-to-strong
        grip, with the hands turned slightly more to the right on the handle,
        encourages the clubface to be more closed in relation to the path of the
        swing and through impact than a weaker grip would. A clubface that's
        slightly closed is preferable with the wider and shallower swing arc
        that the one-plane swing tends to produce.
      </div>
    </div>
  </Panel>
  <Panel header="Stance" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        For all normal full shots, from the driver through the full pitching
        wedge, the one-plane swinger should adopt a stance in which the
        <span class="accent1">feet</span> are
        <span class="accent1">square to the target line</span> or,
        <span class="accent1">if anything, slightly closed</span> (right or rear
        foot is drawn back an inch or two from a line that runs parallel to your
        target line.) Your <span class="accent1">hips</span> should be
        <span class="accent1">square (parallel) or slightly open</span> to your
        target line. Your <span class="accent1">shoulders,</span> meanwhile,
        should be <span class="accent1">square</span> to the target line or,
        <span class="accent1">if anything, just a shade open</span> (pointing
        slightly left of target) for those who have adopted slightly open hips.
      </div>
      <div class="p-mb-2">
        Your stance should also be
        <span class="accent1">neutral to moderately wide.</span> This means that
        for a <span class="accent1">full driver,</span> the distance between
        your <span class="accent1">heels</span> should be at least as wide as
        the shirt seams at the
        <span class="accent1">points of your shoulders.</span> The stance will
        <span class="accent1">gradually become narrower </span>as you work your
        way down through the shorter irons, until the feet are
        <span class="accent1">approximately 12 inches</span> apart for a
        <span class="accent1">full wedge.</span> Keep in mind, of course, that
        the distance between the feet will always be somewhat wider for the tall
        player than for the golfer of shorter stature.
      </div>
      <div class="p-mb-2">
        I advocate a slightly wider stance for the one-plane swinger because it
        provides a more stable basis for the wider, flatter swing that results
        from the arms and shoulders turning on the same plane during the swing.
        The wider stance allows you to move the entire trunk or midsection as
        aggressively as you can, which is something you'll want to do as a
        one-plane swinger.
      </div>
      <div class="p-mb-2">
        An often-overlooked issue with regards to stance is the
        <span class="accent1">positioning of the feet.</span> For the one-plane
        swing, I recommend you
        <span class="accent1"
          >point your left foot outward, toward the target, at an angle of 30 to
          45 degrees.</span
        >
        Meanwhile, your <span class="accent1">right</span> or rear foot should
        be <span class="accent1">square to the target line.</span>
        Positioning your feet this way is consistent with the mechanics of the
        one-plane swing, which we'll discuss in detail later. For now, suffice
        it to say that in the one-plane swing you want to build tension between
        the turning of your shoulders and your hips during the backswing.
        Keeping your left foot turned out while the right foot is set square
        prevents your hips from turning too far back. In addition, this stance
        increases the tension between your upper and lower body on the
        backswing, which is a desirable power-building element in the one-plane
        swing.
      </div>
    </div>
  </Panel>
  <Panel header="Ball Position" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Most instruction regarding ball position tends to focus on the position
        of the ball between the feet, which we will discuss shortly. What
        teachers and students often overlook is the distance that the ball
        should be <span class="accent1">away from your feet.</span> This is an
        important element of your address position, which dictates the plane of
        your golf swing. Basically, the closer you stand to the ball, the more
        upright your plane will be. The <span class="accent1">farther</span> you
        stand from the ball, the
        <span class="accent1">flatter your swing plane.</span> In general, the
        one-plane swinger <span class="accent1">should stand farther</span> from
        the ball than the two-plane swinger. You'll understand why shortly when
        we discuss your <span class="accent1">posture</span>. There is no one,
        precise or universal distance that the ball should be from your toes. It
        depends upon both your height and the club you're hitting. The taller
        you are and the longer the club, the farther you should stand from the
        ball to swing comfortably and ultimately hit the ball solidly. Taking
        these things into consideration, the one-plane swinger should stand
        farther from the ball than the two-plane swinger
      </div>
      <div class="p-mb-2">
        Unlike the issue of distance, the
        <span class="accent1"
          >ball's position in terms of center alignment</span
        >
        in relation to the feet does not differ for one-plane and two-plane
        swingers. This does not mean that the ball position is the same for all
        shots, however. For a <span class="accent1">teed-up driver,</span> the
        ball should be positioned opposite a line drawn to your
        <span class="accent1">left heel</span> or just slightly ahead of it,
        opposite your left instep. This ball position allows you to
        <span class="accent1">sweep</span> the club through impact, making
        contact while the
        <span class="accent1">clubhead is just beyond its lowest point</span>
        and is beginning to ascend. As the
        <span class="accent1">clubs get shorter</span> and you want to strike
        the ball with a
        <span class="accent1">more descending blow, move the ball back</span> in
        your stance, until it is just about in the middle or
        <span class="accent1">slightly back-of-middle</span> of the narrowed
        stance for a <span class="accent1">full pitching wedge.</span> Keep in
        mind that the ball position may also vary depending on whether course
        conditions call for you to hit a lower or higher shot than normal or to
        draw or fade the shot. To hit a
        <span class="accent1">low shot or a right-to-left draw,</span> position
        the ball a <span class="accent1">little back</span> from where you
        ordinarily would. To hit a
        <span class="accent1">high shot or a fade,</span> you should position
        the ball a <span class="accent1">little more forward</span> in the
        stance than normal.
      </div>
    </div>
  </Panel>
  <Panel header="Posture" :toggleable="true" :collapsed="true">
    <div>
      <img src="@/assets/images/hardy1/hardyPosture1.png" alt="Hardy Posture" />
      <div class="accent1 fontSmall p-mb-2">
        The one-plane swinger should bend over more at address with the hands
        directly below the chin.
      </div>
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        In the one-plane swing, the shoulders will turn on a more upright plane
        than in the two-plane swing, in which the shoulders turn more
        horizontally while the arm swing is more upright. This is because the
        shoulders turn at a 90-degree angle to the spine, and the spine is much
        more bent over in the one-plane swing (creating a steeper turn) than in
        the two-plane swing.
      </div>
      <div class="p-mb-2">
        If you want to develop a one-plane swing, then at address you should
        bend over noticeably toward the ball. The taller you are, the more you
        should bend from the hips. Peter Jacobsen, who is 6'3" tall and who has
        beautifully adapted the one-plane setup and swing, uses a vivid mental
        image to remind himself to bend over at address. He says that once he
        gets his feet into position, he thinks about putting his nose on a table
        that's directly in front of him. You don't actually have to bend that
        much, but remember to bend enough so that your spine is well tilted, at
        least 35 to 45 degrees below vertical. Also make sure that you are
        slightly bent out. The more you bend out, even slightly toward the balls
        of your feet, the easier it will be to bring your arms in close to your
        body and then around your chest. Make sure that your hands are directly
        under your chin. Once you are in this position, you should be able to
        decide how far to stand from the ball.
      </div>
      <div class="p-mb-2">
        <span class="accent1">To determine correct balance:</span> Take your
        address position, then have a friend stand behind you along your target
        line with a yardstick in hand. He or she should then line up the top of
        the yardstick with the front of your shoulders and leave the bottom of
        the yardstick dangling. If you have bent your spine over and out enough
        at address, the
        <span class="accent1"
          >yardstick should dangle slightly outside the toes of your
          shoes.</span
        >
      </div>
      <div class="p-mb-2">
        Finally,
        <span class="accent1"
          >how do you know if you are bent over enough?</span
        >
        Start by addressing the ball, bent over with your
        <span class="accent1">hands under your chin</span>. Next, hold the
        yardstick across one shoulder so it is pointing out toward the ball.
        Position the yardstick so that it is at a
        <span class="accent1">90-degree angle to your spine.</span> Have your
        friend tell you if the yardstick is
        <span class="accent1"
          >pointing into a zone that is from the ball to within four feet
          outside the ball</span
        >
        (or approximately 48 inches). If the yardstick is
        <span class="accent1"
          >pointing to the middle of that zone ("24 in")</span
        >, then you are
        <span class="accent1">bending over the correct amount.</span> If it is
        pointing to the top of the zone or outside of the zone, then you are not
        bending over enough. Conversely, if the yardstick is pointing at the
        ball or out of the zone—to the inside—you are bent over too far.
      </div>
      <div class="p-mb-2">
        As a one-plane swinger, you should also
        <span class="accent1"
          >center your weight directly over the hips, with your spine perfectly
          centered</span
        >. You want to keep the spine centered at address because the tendency
        of the player who turns the arms and shoulders on a single plane is to
        employ a swing that is somewhat too wide and too flat. If your spine
        tilts to the right, you're going to have too much width in your swing.
      </div>
      <div class="p-mb-2">
        Finally, the one-plane swinger's
        <span class="accent1"
          >hands should be centered or just an inch or two ahead of the
          center</span
        >
        of the body. Depending on the club being used, at address the hands will
        be very close to even with the clubhead, neither ahead nor behind the
        clubhead. The one-plane swinger should never have his or her hands
        positioned very far in front of the clubhead for any normal full shot.
        You can check this by imagining a line extending out of the shaft and
        grip toward your torso. That line should always point somewhere between
        the left side of the fly of your pants and the pleat on your left pant
        leg. In doing this on <span class="accent1">drives</span> with the ball
        played forward in the stance, your hands will be
        <span class="accent1">just behind the clubhead.</span>
        Conversely, when hitting a
        <span class="accent1">wedge or short iron</span> with the ball played
        back in the stance, your hands will be
        <span class="accent1">ahead of the clubhead.</span>
      </div>
      <div class="p-mb-2">
        You may recall, incidentally, that when we discussed the stance, I
        mentioned that the shoulders should be square to slightly open, as
        opposed to the feet that should be square or slightly closed to the
        target line. You may have wondered why this would be the case. Well, the
        alignment of the shoulders is related to the positioning of your hands
        and arms at address. With your hands nearly centered in relation to your
        body and your right hand below your left on the grip, it stands to
        reason that your right shoulder might be pulled outward just slightly,
        toward the ball. This means that a line drawn across your shoulders will
        tend to be slightly open in relation to your target line, even though
        your feet should be aligned square to slightly closed. To put it another
        way, in order to have your shoulders aligned square to the target, you
        will have to slightly soften or bend your right arm so that the arms are
        not equally straight.
      </div>
    </div>
  </Panel>
  <Panel header="Weight Distribution" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        At address for all normal full shots, the one-plane player should
        distribute his or her
        <span class="accent1"
          >weight evenly, with 50 percent on each foot.</span
        >
        Shifting your weight slightly to the balls of your feet will help make
        it easier to achieve the correct amount of outward lean. This is the
        best way to stay in balance throughout the swinging motion.
      </div>
      <div class="p-mb-2">
        "I feel so bent over at address that it seems that my shoulders are out
        in front of my feet," says PGA Tour player Paul Azinger. "I visualize
        myself standing on top of a 50-story building and bending out over the
        edge as far as I can without losing my balance."
      </div>
    </div>
  </Panel>
  <Panel header="Review" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        <div class="p-mb-2">
          <b>Grip:</b>
          <span class="accent1"> Neutral-to-strong position,</span> with palms
          facing and in a position in which you can see at least two but no more
          than three knuckles on the left hand.WARNING: Do not attempt to
          develop a one-plane swing action while using a weak grip. A weak grip
          is one in which the back of the left hand directly faces the target.
        </div>
        <div class="p-mb-2">
          <span class="accent1">Stance:</span
          ><span class="accent1"> Moderate-to-wide</span> stance, with the
          distance between your <span class="accent1">heels</span> at least as
          great as the width of your
          <span class="accent1">shoulders ("tips")</span> when using the
          <span class="accent1">driver.</span>
          <span class="accent1"> ("about 12 in for full wedge"). </span>
          <span class="accent1">Feet</span> should be aligned and
          <span class="accent1">parallel </span> to the target line,
          <span class="accent1">or slightly closed. </span> The
          <span class="accent1">left foot </span> should be
          <span class="accent1">angled out 30 to 45 degrees </span> toward the
          target with the <span class="accent1">right foot </span>
          <span class="accent1"> set square</span> to the target line.
          <span class="accent1">Hips and shoulders</span> are
          <span class="accent1">square to slightly open.</span> WARNING Do not
          attempt to develop a one-plane swing action while playing from an open
          stance on full shots. This will force you to move the right hip in an
          incorrect pushing manner during the downswing as a means of starting
          the ball on-target. This hip movement at the expense of a free
          shoulder turn can quickly lead to back problems.
        </div>
        <div class="p-mb-2">
          <b>Ball Position:</b> The ball should be
          <span class="accent1">farther from the feet</span> for the one-plane
          player. Ball position
          <span class="accent1">in relation to the feet</span> should be
          opposite the <span class="accent1">left heel</span> or instep for a
          <span class="accent1">driver,</span> dead
          <span class="accent1">center or slightly ahead</span> of center for
          <span class="accent1">mid irons,</span> and middle or slightly right
          of center <span class="accent1">("back")</span> for
          <span class="accent1">short irons and wedges.</span> WARNING Do not
          position the ball too close to your feet. This will force you to swing
          your arms on an exaggeratedly steep plane, one that your shoulder turn
          could only match by tilting rather than turning.
        </div>
        <div class="p-mb-2">
          <b>Posture:</b> Bend over more from the spine, at least
          <span class="accent1">35 to 45 degrees forward from vertical</span>
          and somewhat out toward the ball. Taller golfers will bend more than
          shorter players. A
          <span class="accent1">line drawn down from the shoulders</span> should
          point <span class="accent1">just</span> outside of or
          <span class="accent1"
            >beyond the toes. ("line 90 degrees to spine angel points 24in
            beyond ball")</span
          >
          When <span class="accent1">viewed from the front,</span> the
          <span class="accent1">shoulders</span> should be
          <span class="accent1">directly over the hips</span>, and the
          <span class="accent1">spine</span> should appear perfectly
          <span class="accent1">straight</span>. WARNING Do not tilt your spine
          to the right at address. Doing so will unnecessarily create more width
          on the backswing.
        </div>
        <div class="p-mb-2">
          <b>Weight Distribution:</b> On full shots, keep your weight
          <span class="accent1">evenly balanced, 50 percent on each foot</span>
          with the weight
          <span class="accent1">toward the balls of your feet.</span> WARNING Do
          not put more than 50 percent of your weight on your left foot,
          particularly when playing the driver and fairway woods. Doing so will
          result in a steeper hit and cause power to be drained from your swing.
        </div>
      </div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
    </div>
  </Panel>
</template>
