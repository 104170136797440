<template>
  <h1>SPIRIT OF THE GAME: A microcosm of life</h1>
  <Panel
    header="What is the 'Spirit of the Game'"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Me: THE LAW OF GOLF: Golf is a game in which the laws of physics alone
        determine what happens to the ball after it is hit and how many shots it
        takes to get the ball into the hole. Neither the opinion of judges, nor
        the beauty of the swing or flight of the ball affect the score in any
        way. Further, it is a game of personal integrity and honesty in which
        players are expected to call penalties on themselves and post honest
        scores.
      </div>
      <div class="p-mb-2">
        Me: THE SKILLS OF GOLF: Playing golf requires mastery of its mental and
        technical games. Mental skills can be facilitated or diminished by
        external factors (social, environmental), or by internal factors
        (personality traits). Technical skills are enhanced by physical gifts
        and hindered by physical limitations. Learning the mental and technical
        skills requires a system of training skills. Execution of the mental and
        technical skills within a round requires integrating them into a
        between-shot routine and a shot-routine. These routines are the
        skillsets that maximize performance even under pressure.
      </div>
      <div class="p-mb-2">
        Me: THE SPIRIT OF GOLF: The 'spirit of golf' is a mental state you
        experience while embracing the physical laws and human rules of golf;
        while understanding and learning golf's skills; while executing the
        routines during a round; while identifying, enjoying or overcoming
        playing, environmental, social, physical or personality issues. The
        extent to which you experience the 'spirit of golf' is equal to the
        extent to which you have mastered your mind and your body, so that you
        are able to function with equal insight, grace, honesty and skill under
        conditions of excellence or conditions of adversity. Deep enjoyment and
        pride as you internalize your ongoing accomplishments is the reward of
        the spirit of golf.
      </div>
      <div class="p-mb-2">
        Me: Golf, in many respects is a microcosm of life. Consider the
        thoughts, theories and preparation that go into executing your job, your
        home life, your play, your love life, or your social interactions. Watch
        how a person lives through the vicissitudes of golf and you will know
        how that person lives his life.
      </div>
      <div class="p-mb-2">
        In our previous books, we established the framework of the VISION54
        philosophy for play and practice. This book reflects the continued
        maturation of that thought process. You will learn about the
        <b
          >physical, technical, mental, emotional and social elements of the
          game</b
        >, but you will also learn how
        <b
          >those components exist as overlapping circles of experience WITHIN
          THE LARGER CIRCLE REPRESENTING THE SPIRIT OF THE GAME.</b
        >
        Those elements defined in our earlier works will become accessible to
        you through the skills you will learn from this book.
      </div>
      <div class="p-mb-2">
        What results in low scores? Good shots and great putts. Those good shots
        and great putts come from swings that are good enough and putting
        strokes that are good enough, which are the result of functional
        technique. That
        <b
          >functional technique is the sum total of your mastery of the
          physical, technical, mental, emotional and social elements of the
          game, unified within its spirit.</b
        >
        Many view golf only through the micro-lens of technique. We want you to
        use a wide-angle lens, seeing all the components that influence your
        technique.
      </div>
      <div class="p-mb-2">
        One of the joyous things about golf is that it is a highly
        individualized sport, while at the same time being extremely social. In
        what other athletic activity can playing partners, or even opponents,
        spend time walking and talking together while competing? In what other
        sport is the venue on which the game is played as much a part of the
        experience as the game itself? In what other game can players of varied
        skill levels compete against one another as equals?
        <b
          >It is important to incorporate that physical, social and spiritual
          connection into how you approach golf.</b
        >
        This game is far too beautiful to be reduced to just swing theory.
        <b
          >Immerse yourself in golf, become one with it and then watch your
          scores drop.</b
        >
      </div>
      <div class="p-mb-2">
        Not everyone swings like Mickey Wright, nor should they, and not
        everyone thinks like Ben Hogan, nor should they. Part of what makes
        Tiger Woods a great player is that he is totally himself on the golf
        course, right down to his occasional expletives that go uncensored.
        Woods knows that to break the records of Jack Nicklaus he need not try
        to become the Golden Bear, but rather must find his own Tiger.
        <b>He embraces his unique spirit of the game.</b>
      </div>
      <div class="p-mb-2"></div>
    </div>
  </Panel>
</template>
