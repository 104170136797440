<template>
  <h2>AFTER ROUND</h2>
  <div id="after-round-div">
    <img
      src="@/assets/images/home/after-round.png"
      alt="AfterRound"
      class="img7"
    />
  </div>
</template>
