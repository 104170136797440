<template>
  <img
    class="img40"
    src="@/assets/images/mental/resilience1.png"
    alt="Athletic"
  />
  <div class="accent1 fontSmall p-mb-2">Resilience</div>

  <Panel header="resilience and grit" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Resilience is mainly a function of emotional control, and deep
        confidence ( see memory control). Deep confidence can be developed by
        (a) practicing hard with purpose - so you know you have tools for golf's
        external variability, and human internal variability. (b) Storing good
        shots, rounds and situations with positive emotion - so they are
        available to be re-lived when needed. And storing the bad analytically,
        to minimize repeating the errors. That is, minimizing amygdala generated
        fear and poor execution when a similar situation arises in the future.
      </div>
      <div class="p-mb-2">
        Rests heavily on good self esteem. Belief that are good enough not a
        fraud, that will not quit, that will do what it takes to win. Will
        engage full routine with tenacity and confidence because Know that have
        practiced hard, and developed good technique So can risk trusting it
        under pressure/stress. In the face of adversity need emotional
        engagement - not avoidance / deflection etc.
      </div>
      <div class="p-mb-2"></div>
    </div>
  </Panel>
  <Panel header="x" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
    </div>
  </Panel>
  <Panel header="x" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
    </div>
  </Panel>
</template>
