<template>
  <div class="p-d-flex-column">
    <Panel header="Adaptations" :toggleable="true" :collapsed="true">
      <img
        class="img40"
        src="@/assets/images/fullSwingAdaptations/adaptations1.png"
        alt="Tough Minded"
      />
      <div class="accent1 fontSmall p-mb-2">
        Full Swing Adaptations: Slopes, Lies and Shapes
      </div>

      <Panel header="Abnormal Slopes" :toggleable="true" :collapsed="true">
        <abnormalSlopes />
      </Panel>
      <Panel header="Abnormal Lies" :toggleable="true" :collapsed="true">
        <abnormalLies />
      </Panel>
      <Panel header="Shot Shapes" :toggleable="true" :collapsed="true">
        <shotShapes />
      </Panel>
    </Panel>
  </div>
</template>

<script>
import shotShapes from "@/components/fullSwingAdaptations/shotShapes";
import abnormalSlopes from "@/components/fullSwingAdaptations/abnormalSlopes";
import abnormalLies from "@/components/fullSwingAdaptations/abnormalLies";

export default {
  name: "fullSwingAdaptations",
  components: {
    shotShapes,
    abnormalSlopes,
    abnormalLies,
  },
};
</script>
<style lang="scss"></style>
