<template>
  <Panel
    header="Vision and Methods for Peak Performance"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Be yourself, everyone else is already taken. Oscar Wilde, Irish writer
        and poet
      </div>
      <div class="p-mb-2">
        SWING KEY: Golf is predictably unpredictable. The only constant is you.
      </div>
      <div class="p-mb-2">
        THOUGH CHECKING: Thought checking for 'my player', covers all of shot
        and inter-shot attributes/skills/routines. What are abstract thoughts,
        emotions, confidence level, are inter-shot techniques being used, are
        shot-techniques being used.
      </div>
      <div class="p-mb-2">
        The VISION54 approach evolved from a very specific concept of how to
        reach a peak-performance state on the golf course. When Pia was coaching
        the Swedish national team, she would ask her players if they had ever
        birdied the first hole on their home course. Invariably, they had. They
        were good players. Then she would ask the same question about every
        hole. When she had finished, she would smile and say, What fun, you’ve
        birdied every hole on the course at one time. Now why not make it your
        goal to birdie them all on the same day?
      </div>
      <div class="p-mb-2">
        To make eighteen consecutive birdies on a par-72 course would be to
        shoot a 54, so that became the vision— VISION54. In many ways, our
        approach embraces the phenomenon that surrounded the four-minute-mile
        barrier in track. Many in the sport, and many in science as well,
        believed it was impossible for a human being to run a mile in under four
        minutes. Then Roger Bannister broke the barrier on May 6, 1954. Just
        forty-six days later, John Landy went even lower, and on August 7, they
        both ran a mile under four minutes in the same race. By the end of the
        twentieth century, the record was nearly seventeen seconds below the
        unbreakable barrier.
      </div>
      <div class="p-mb-2">
        To a far greater extent than most people realize, what you achieve is
        affected by what you believe you can achieve. In golf, 60 is the magic
        number. Very few players have gone below it in competition. But when
        someone shoots 59, that makes 58 seem possible, and when someone shoots
        58, that makes 57 seem possible. You get the picture. Many of the
        barriers we perceive as physical or practical are really conceptual
        barriers. Someday we may have to talk about VISION53!
      </div>
      <div class="p-mb-2">
        While it is a very specific concept, VISION54 is also a metaphor. Having
        a belief that it is possible to birdie every hole is a way to get people
        to raise for themselves their visions and what they think is possible.
        In reality, VISION54 varies for everyone, based on ability, experience
        and other personal factors. If you have never broken 100, the concept of
        making a birdie on every hole may be too much to take on—for now. But
        how about making a bogey on every hole and shooting 90—shattering your
        barrier of 100? It’s all about creating a road map to better play, and
        the first step is always a belief based on vision.
      </div>
      <div class="p-mb-2">
        What we are asking you to do is to expand your belief in what is
        possible for you. In doing so, you will formulate your own VISION54
        goals and you will develop your own map for how to get there. This is
        when you make our skills yours. This is when you take ownership of your
        golf game. For a few minutes every day, imagine how you are when you
        play your greatest golf. Make it rich and beautiful. Prepare your
        nervous system for success! How good do you want to be? We can’t
        emphasize enough how important it is to keep on dreaming, even as an
        adult! The key is to keep looking for possibilities.
      </div>
      <div class="p-mb-2">
        To the day she retired, Annika Sorenstam believed she was going to make
        eighteen birdies someday. She always played with that belief. When she
        shot 59, she birdied the first eight holes. And even as Tiger Woods
        struggled with his game during the 2010 season, when asked if he thought
        he would still break Jack Nicklaus’s record of eighteen professional
        major championships, he responded with one word: Absolutely.
      </div>
      <div class="p-mb-2">
        For both Sorenstam and Woods, their dream, their vision, was the carrot
        attached at the end of the stick of greatness that they constantly
        chased. Among the things we have noticed is that this vision of
        greatness works best when you are feeling it inside you, viewing it with
        your own eyes as a first-person experience, rather than observing
        greatness as a third-person experience from the outside. Close your eyes
        and envision great golf for you. See, hear and feel the putts, the
        shots, your tempo and your commitment to your decisions. Greatness is a
        participatory sport, not a spectator sport.
      </div>
      <div class="p-mb-2">
        Peak performance is a universal state we can all achieve, but where it
        takes us is an intensely personal matter, and all of us have our own
        path to follow. It is all about you. MY54 is your own recipe for how you
        play great golf. Be curious and pay attention to what you do when you
        play well. The more intimate you are with your MY54, the more likely you
        are to create that state more often—or at least come closer to it.
      </div>
      <div class="p-mb-2">
        Playing golf without knowing what your MY54 is would be like driving and
        not knowing where you are going. All of us are unique as golfers, just
        like our fingerprints and signatures belong to us alone. You can’t play
        golf like Tiger Woods or Annika Sorenstam. You can learn from them, but
        you can’t play like them, because you are not them. You want to learn to
        be the best you, playing the game of golf.
      </div>
      <div class="p-mb-2">
        All of us have unique bodies, different tension levels, personalized
        tempos and private emotions. You have to learn to structure your golf
        game around who you are. The secret to playing well more often is to
        better understand how you do things when you do play well. What are you
        aware of during the putting stroke or the full swing? What do you do
        between shots? Do you chat or are you in your own world? If you are in
        your own world, what are you thinking about? What is your tempo? How do
        you commit to decisions? The greater clarity you have about the 8 EPS,
        the more in touch you will be with your MY54. How do you warm up when
        you have your best rounds? What do you eat and drink? Learning and using
        this information will take you from being a player who happens to
        perform well a few times a golf season, to being a golfer who can
        consciously create a state in which good golf occurs more often.
      </div>
      <div class="p-mb-2">
        Bob Currey is a successful businessman from Boston who runs his own
        risk-management consulting firm. He is also a scratch golfer who has won
        many club championships and competed in some of the most prestigious
        senior amateur events in the world. At one point, he was ranked number
        eleven among male senior amateurs in the United States. Bob’s
        relationship with golf is extremely complicated, and by the time he
        attended one of our schools with his son, his love/hate relationship
        with the game had tilted toward the negative side.
      </div>
      <div class="p-mb-2">
        When Bob started the search for his MY54, he was looking for something
        far different from what he found. He assumed the bad attitude he had
        developed about golf had to do with some disappointing outcomes in
        tournaments—something completely outside his control. But he learned
        that his feelings about the game were not the result of how he played,
        but rather how he reacted to how he played. And that reaction is
        completely within his control.
      </div>
      <div class="p-mb-2">
        Golf has hurt my feelings on a thousand occasions, Bob says, but I
        always come back for more. Truth be told, I love the game.
        Disappointments aside, it has enriched my life. For years, Bob was a
        solid club player, and then he took on tournament golf and had a lot of
        very good finishes his first few years of competition. But after a
        while, the travel and the tension began to wear on him. He also came to
        another realization.
      </div>
      <div class="p-mb-2">
        I have a confession, Bob says. For me, competitive golf was never fun.
        It was work. In fact, it was much worse than work. Unfortunately, I do
        not hit shots in competition with much joy, only fear and angst. Despite
        these limitations, I was able to become a very decent competitor. But he
        was not
      </div>
      <div class="p-mb-2">
        a happy player. Bob has since greatly reduced his tournament schedule
        and gone back to playing golf with his friends. Part of his renewed
        affection for the game comes from the fact that he now better
        understands his relationship with golf. He realizes that he was letting
        external forces determine his feelings about the game, rather than
        asserting control over those feelings himself.
      </div>
      <div class="p-mb-2">
        I am now completely satisfied with my limited competitive
        accomplishments, Bob says. Some of that peace of mind can be attributed
        to a conversation I had with either Pia or Lynn, I can’t remember which.
        She said: ‘Only you are responsible for your attitude about golf.’ That
        struck a chord with me. If I am in charge and I am a disciplined person,
        then I ought to be able to have fun no matter the outcome of a
        tournament or round or even shot. You can’t imagine how liberating that
        mind-set is for a type-A personality like me.
      </div>
      <div class="p-mb-2">
        Among the questions we ask players who come to our school is what they
        want to get out of the sessions. And among the most common answers is: I
        want to learn how to be more consistent. When we press them to elaborate
        on that answer, we usually find that what they mean is that they want to
        play their best golf more often. We also find that when they think about
        it, most players realize that they are actually more consistent than
        they perceive themselves as being—just not always in the way they
        desire.
      </div>
      <div class="p-mb-2">
        A key to playing better is understanding the ways in which you are
        consistent when you play well. This will lead you to your MY54. Make a
        list of how you are when you play well:
      </div>
      <div class="p-mb-2">
        * My legs feel light. * I smile and laugh a lot. * make clear decisions
        before hitting. * I start my warm-up by putting.
      </div>
      <div class="p-mb-2">
        One of the players we work with came to understand that when she was
        playing well, she would walk with her head held high and her eyes fixed
        on the horizon. This was an important realization because she learned
        that when her game went into a funk, she would look at her feet. She
        found that she could change her mood—and improve her play—by changing
        the focus of her eyes from the ground to the horizon. Literally, things
        were looking up.
      </div>
      <div class="p-mb-2">
        Discovering his MY54, Bob Currey reached two conclusions. Of all the
        pillars of the game, the one that really matters for me is the spirit of
        the game, Bob says. Without that, all the rest is wasted. I left their
        school committed to enjoying every round of golf no matter how badly I
        played or how poorly I was treated by the golfing gods. When I see
        players get angry, frustrated, play the victim or exhibit any of a host
        of other negative traits, I thank goodness I found those two nice ladies
        in Arizona.
      </div>
      <div class="p-mb-2">
        Now, on the golf course, Bob focuses on improving his attitude. He
        reminds himself how fortunate he is to be playing golf, and he embraces
        the sheer joy the game can bring if you are receptive to it. Bob’s prime
        focus now is maintaining that receptive state of mind. He has also
        changed the way he practices and prepares to play.
      </div>
      <div class="p-mb-2">
        When I was at the VISION54 school, I realized how important the short
        game is to shooting consistent scores, Bob says. Now, I spend eighty
        percent of my practice time around the green. I can hit it sideways and
        still post a decent score. I aspire to hit serviceable golf shots and
        great putts. Also, I practice like golf is played. One ball. By
        concentrating on his attitude—and his short game—Bob is playing great
        golf again and enjoying it more than ever. He also plays with more
        self-assurance.
      </div>
      <div class="p-mb-2">
        Good results don’t guarantee confidence, Bob says, referring to an
        element he learned at our school. Confidence comes from realizing that
        you can live with your failures, knowing that the sun will still rise in
        the morning, that your family and dog will still love you and that your
        golf results do not really matter one bit. I am not defined by my golf
        scores or my worst swings.
      </div>
      <div class="p-mb-2">
        The true test of who we are comes not when things are going well but
        when we hit choppy waters, as we all do. Some players think they should
        play MY54 golf every time they tee the ball up. If they don’t, they
        think something is wrong. We want to make sure you don’t carry that
        belief. Any great player will tell you that it is easy to play when you
        are playing well. Winning tournaments, they will tell you, often has a
        lot to do with how well you perform on those days when you have not
        entirely accessed your MY54.
      </div>
      <div class="p-mb-2">
        A few times during the year all the pieces of the puzzle are in place.
        Everything seems perfect. Your swing feels great, your touch is awake,
        your body feels alive, you are happy, you like the course and who you
        are playing with. But the reality of golf is that most days are not like
        that. Something is out of place. There can be thousands of things that
        upset your MY54. Maybe the pillow in the hotel gave you a stiff neck, or
        you have worries from work or home. As a result, you can’t find the
        speed on the greens, or your ball flight is a little funky.
      </div>
      <div class="p-mb-2">
        It happens to every golfer. The ones who learn to perform the best
        recognize this disruption early and do something about it. Just as you
        are consistent in what you do when you play well, you are consistent in
        what you do to get in your own way. We call that your NOT54. You want to
        be as aware of the ways in which you get in your own way as you are
        about the things you do when you play well.
      </div>
      <div class="p-mb-2">
        Do you get tight in your shoulders, or change your grip pressure, or
        start being critical of yourself, or stay over the ball longer before
        hitting? Too many golfers recognize these problems after the round—when
        it’s too late. They might say, I think I started swinging too fast on
        the eleventh hole and then I lost it. Write down all the situations that
        bother you: getting advice from others, arriving late to the golf
        course, slow play, others watching you, etc. What are you going to do
        about these situations? Practice them. Knowing your NOT54 makes you less
        vulnerable to the tweak of the week or your friends’ advice.
      </div>
      <div class="p-mb-2">
        Understanding your undesirable tendencies and having the ability to
        catch yourself mid-meltdown—your NOT54—is extremely beneficial. When you
        are able to say, Aha, here I go again, you can more easily and
        efficiently get yourself and your game back on track.
      </div>
      <div class="p-mb-2">
        Once you know your MY54 and your NOT54, you want to start exploring what
        tools you can use to shift back toward MY54 when you slip into the NOT54
        state. There are countless ways to get back nearer to your performance
        state, including breathing exercises, stretching, laughing, going to the
        happy place in your heart, and using a lighter grip pressure. The only
        way to find out if a state-shifting tool works for you is to use it on
        the golf course. Keep trying different things until you have a handful
        of choices.
      </div>
      <div class="p-mb-2">
        One of our favorite exercises is to write your strategies for MY54 on a
        piece of paper. On another piece of paper, write your NOT54 tendencies.
        Put them on the ground separate from each other and stick a tee through
        each piece of paper. Read the MY54 note and create that state while
        hitting shots or putts. Now walk to the NOT54 paper and hit shots or
        putts while you create the state of getting in your own way. Yes, we
        want you to practice being bad. After you have experienced the NOT54
        state, move back to the real you—MY54—and re-create that state. When you
        realize you can shift back to MY54, you will have the power to do it on
        the course. The fancy name for this process is having paradoxical
        intentions.
      </div>
      <div class="p-mb-2">
        Another great exercise is to write down all the things and situations
        that bother you—slow play, noise, wind, etc.—then create these
        situations in your practice. How does each situation alter your MY54
        state? Does it change your tempo, influence how present you are in the
        Play Box, impact the time you take to make a clear decision? What can
        you do differently to get closer to your best state in each situation?
        Knowing your MY54 and NOT54 allows you to manage your state so that you
        can play your best on this particular day, no matter what skill level
        you brought to the golf course with you on that day.
      </div>
      <div class="p-mb-2">
        Getting to know your MY54 is a process. Keep a notebook in your bag, and
        for a month keep track of how you feel, behave and play. What patterns
        do you notice? Are you the same person on the golf course that you are
        at home or at work? What makes you happy? What makes you angry? How do
        you react to those emotions? What is there that is consistently present
        when you play your best golf? You are empowering yourself to control
        your game, your life and your state on the golf course. That
        understanding left Bob Currey in a much better place.
      </div>
      <div class="p-mb-2">
        "I am more at peace, Bob says. Results do not define me, effort does. I
        still try hard in everything I do, but I accept that hard work does not
        guarantee good results. On my tombstone, they will write, ‘He Tried.’
        That says a lot. The joy, Bob learned, is in the trying. The joy is in
        the process. With that breakthrough, Bob came to realize that he can
        have a good time and have fun every time he steps on the golf course,
        not just when he wins or plays well. That’s a joy you can experience
        also.
      </div>
    </div>
  </Panel>
</template>
