<template>
  <div class="fontSmall taLeft">
    <div class="p-mb-2">Ball above Stance</div>
    <div class="p-mb-2">
      1. Grip down on the club. 2. Move the ball back in your stance slightly 3.
      Make a three quarter swing, staying balanced 4. Ball will tend to fly
      right to left
    </div>
    <div class="p-mb-2">Ball below Stance</div>
    <div class="p-mb-2">
      1. Stand closer to the ball. 2. Move the ball forward in your stance 3.
      Widen your stance, pigeon toe your feet in and flex your knees into the
      hill so that you can stay balanced through the shot. 4. Make a
      three-quarter balanced swing, choke up on club slightly 5. Ball will tend
      to fly left to right
    </div>
    <div class="p-mb-2">Uphill Lie</div>
    <div class="p-mb-2">
      1. Open your stance until your hips are level (righties should pull their
      left foot back) 2.Ball position middle of your stance 3. Lean into the
      hill and go with the shot don’t stay back on your rear foot 4. Make a
      three quarter balanced swing 5. The slope will add loft to the shot so
      take an extra club.
    </div>
    <div class="p-mb-2">Downhill Lies</div>
    <div class="p-mb-2">
      1. Take a more lofted club and choke down. 2. Move the ball to middle of
      your stance 3. Tilt your shoulders to match the slope, left shoulder lower
      than right 4. Close your stance to level your hips, pull your right foot
      back 5. Make a three-quarter balanced swing. 6 Swing down the slope of the
      hill don’t try to swing up on the ball.
    </div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
  </div>
</template>
