<template>
  <Panel header="Guided Imagery" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Many golfers find that imagery is not only a great technique for
        relaxing, but also a valuable, fast, and effective method for practicing
        various aspects of their games. Start by assuming a relaxed position and
        taking a few deep, relaxing breaths. Now imagine guiding or taking
        yourself to one of your most peaceful and relaxed places. For many this
        is a favorite vacation spot, a fondly remembered childhood location, or
        their own easy chair, bed or hammock. In as much detail as possible, see
        and experience the comfort and peacefulness of your chosen surroundings.
        Feel the comfort and peace you have experienced there for a few moments
        before getting up to resume your day.
      </div>
      <div class="p-mb-2">
        With practice, going to your special place should get easier and faster.
        Once it does, you will be able to use this relaxed state and further
        guide your imagery to prepare for golf. For example, imagine taking
        yourself into your next round with this same peaceful, relaxed feeling.
        See yourself warming up your mental routine and your swing with this
        same relaxed state. Endeavor to mentally play the first hole with a
        peaceful focus that allows you to be very committed, with great pictures
        and feel of your shots. Imagine using your best natural tempo for each
        shot. When you are able, repeat the imagery for any other shot or hole
        that you find particularly challenging.
      </div>
      <div class="p-mb-2">
        With enough practice, you will be able to go to your favorite place in
        the midst of one of your more aroused moments on the golf course and
        with your eyes wide open.
      </div>
      <div class="p-mb-2">MENTAL REHERSAL</div>
      <div class="p-mb-2">
        There are numerous players, who use guided imagery to mentally rehearse
        playing their entire round of golf before going to the first tee. They
        imagine their chosen targets and clubs and see the shots they want to
        hit on each hole. If, during their mental rehearsal, their arousal
        rises, and it frequently does, they also mentally rehearse relaxing in
        that situation using deep breathing or their chosen method of calming
        themselves.
      </div>
      <div class="p-mb-2">
        We've found that mental rehearsal helps a great deal when you're about
        to play a course you have had little opportunity to practice on or on
        which you are very uncomfortable and indecisive. It also helps when you
        are about to play in situations that likely will increase your arousal,
        such as an intimidating playing partner or while in contention or in the
        lead of a tournament.
      </div>
      <div class="p-mb-2">
        Once on the course, you can use mental rehearsal if you're uncomfortable
        with shot but have hit that type shot well in practice or competition.
        Just rehearse hitting that shot as you did in your previous
        circumstances before stepping up to the ball.
      </div>
    </div>
  </Panel>
</template>
