<template>
  <Panel header="Mental Rest" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Mental fatigue tends to skew your arousal. Physical fatigue can do the
        same, but you generally know when to rest your body. Two key signs of
        mental fatigue are impatience and a reduced ability to focus. That's why
        we often recommend that our pro golfer clients play no more than three
        to five weeks in a row, depending largely on how they balance their
        time.
      </div>
      <div class="p-mb-2">
        Mental fatigue also plays a role late in a round. If you have been
        working too hard mentally from the beginning of the round, you will not
        have enough mental energy to finish the round. Your level of arousal
        will have been too high for too long and you will make poor decisions
        and perform poorly. Don't be surprised. Most humans iust cannot stay
        focused for four hours and more.
      </div>
      <div class="p-mb-2">
        The solution is to take mental breaks between shots. Do any of the
        distractions - internal or external. Think about things that you find
        relaxing and interesting. Focus on your breathing. Do anything you can
        think of that will lower your arousal until it is time to play your next
        shot. Then, as you begin the calculation stage of your routine your
        focus will narrow and arousal will rise enough to play the shot. How
        long you should rcst mentally will become clear the more you do it. This
        differs from golfer to golfer.
      </div>
      <div class="p-mb-2">Some thoughts for mental breaks:</div>
      <div class="p-mb-2">
        Object meditation: 1Outlining trees: Look for the biggest tree in sight
        and begins outlining it in your mind. Slowly takes your eyes around the
        entire profile of the tree. When you are finished, your body will be
        more relaxed and your mind more clear. You can do this with any object,
        not just a tree.
      </div>

      <div class="p-mb-2">
        2 Daydreams 3 Past great shots 4 Breathing: finds it relaxing, both
        physically and mentally, to concentrate on smooth, relaxed breathing
        when walking the fairway in anticipation of a difficult shot or putt. 5
        Body Language: focus on shoulders back, head up, scanning horizon,
        relaxing face, jaw, back and hands. Move arms away from body and swing
        them when walking. 6 Nature: Scan the surroundings for wildlife that is
        likely to be found in the area rabbits, turtles, fish, birds, snakes,
        and alligators. 7 Feel and move toes inside shoes, 8 count steps, 9 say
        a mantra (fairway green, fairway green)
      </div>
      <div class="p-mb-2"></div>
    </div>
  </Panel>
</template>
