<template>
  <Panel header="Introduction" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        The short-game skill that most dramatically affects your ability to
        score is the ability to consistently putt the ball at the correct speed.
      </div>
      <div class="p-mb-2">
        An inability to control putt distance makes scoring next to impossible,
        especially on “difference-making” putts between eight and 20 feet, which
        require perfectly matching the line with the appropriate speed. To
        develop great touch, not only for these putts but from any distance, the
        first step is to come to terms with what touch actually is, and the best
        way to do that is to come to terms with what it isn’t. Touch isn’t the
        ability to hit the ball “hard” or “easy” depending on putt length or
        slope, yet that’s how most recreational players think of it and talk
        about it. This mentality is rife with fundamental ineptitude, and it’s
        the reason why many golfers struggle with touch and distance control
        despite obvious talent and years of experience.
      </div>
      <div class="p-mb-2">
        A healthier way to think about touch is to imagine the putterhead
        swinging back and through like a pendulum. (We touched on the dynamics
        of pendulum motion when discussing the value of maintaining a suspension
        point in Chapter 4, but it’s even more applicable to the skill of
        touch.) Picture a pendulum in your mind’s eye right now—think of a
        grandfather clock or one of those “perpetual motion” desk toys, and
        replace the bob (pendulum mass) with your putterhead and the fulcrum arm
        with your hands, arms, and puttershaft. If you focus hard enough, you’ll
        see that a pendulum: 1. Swings back and forth without stopping.
        (Theoretically, it would swing forever if it weren’t for damping.) 2. 3.
        Takes the same amount of time to complete each swing. This period for
        the bob’s oscillation is the same regardless of the length of the
        overall motion. Swings faster or slower depending on the length of the
        displacement (the backstroke). When the displacement is small, the
        putterhead moves comparatively slower than when the displacement is
        large. Although speed changes with displacement, the overall time it
        takes the bob (putterhead) to complete a full cycle is a constant.
      </div>
      <div class="p-mb-2">
        Picturing your stroke as a pendulum and copying its consistent
        oscillation period means you’re putting in rhythm, which is the key
        technical variable related to distance control. Your rhythm, which is
        particular to you, never changes regardless of circumstance, which means
        that the energy transferred to the ball (assuming a solid hit) is
        dependent solely on the time (period) and the distance of the backstroke
        (displacement). If that’s your picture, the desire to “hit” the ball is
        completely removed, because your lone directive is to match the length
        of your stroke to the energy you deem appropriate for the putt at hand
        and simply let the ball get in the way. Once again, this is the key
        concept to internalize: regardless of the putt you’re facing (uphill,
        downhill, short, far, fast, slow, etc.), there’s one perfect stroke
        length (made at your rhythm) for every situation. That’s touch!
      </div>
      <div class="p-mb-2">
        TOUCH: A picture in your mind or a sense of judgment for the perfect
        length stroke, at rhythm, given the conditions at hand. There’s no
        hitting “hard” or “easy.”
      </div>
    </div>
  </Panel>
  <Panel
    header="In-Stroke Foundation 3: RHYTHM"
    :toggleable="true"
    :collapsed="true"
  >
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/rhythm1.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      Matching backstroke and through-stroke lengths is essential to great
      rhythm and phenomenal touch.
    </div>
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/rhythm2.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      Swinging naturally at your optimal rhythm takes the least amount of effort
      but gives you the maximum benefit when it comes to touch and feel. Master
      it by practicing to the beat of a metronome.
    </div>
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/rhythm3.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      Expert at Work! Watch me demonstrate how swinging in rhythm makes distance
      control intuitive and easy in a special video available at
      jsegolfacademy.com/index.php/james-metronome.
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        We covered the first two in-stroke fundamentals earlier when discussing
        how to putt on the correct line. But the third fundamental, rhythm, is
        just as important. Any vibrating object has a resonant frequency, or
        state, that’s coherent and most efficient. This frequency is not only
        influenced by the length and weight of your putter, but by your whole
        being (some people like to do things fast, and others like to do things
        slow). We can define this natural state in several ways, but the one I
        like best for putting is “a comfortable rate that requires the least
        amount of muscular effort to swing the putter.”
      </div>
      <div class="p-mb-2">
        How to Find Your Perfect Rhythm: Making continuous periodic swings is
        the key to finding your natural resonance. Do this: Get into your
        address position without a ball and swing your putter back and through
        uninterrupted, mimicking the motion of a pendulum. Make your strokes
        equal in pace and length on both sides, keeping tabs on how comfortable
        your stroke feels and the muscular effort that you’re exerting to swing
        the club. After about ten swings or so, slow the putter down to a crawl.
        Notice how much extra muscular effort this requires. Strange, right?
        You’re actually working harder to swing the club slower. Now speed it
        up. As it did for the slow swings, this rapid motion requires quite a
        bit more effort. Pull back on the throttle and look for a rhythm
        somewhere between these extremes, searching for maximum comfort and
        minimal muscular tension. After a little trial and error, you’ll settle
        into a pace that requires the least amount of work and feels the most
        natural. Congratulations! You’ve found your optimal rhythm.
      </div>
      <div class="p-mb-2">
        Now that you’ve found your rhythm, you need to master it, which you
        should know by now necessitates quantifying it as well as having a way
        to ingrain the feel and confirm its execution. The best way to do this
        is with a metronome, which is a device musicians use to keep time. Both
        the Apple and Android stores offer dozens of metronome apps for your
        smartphone, many for free, which makes it a no-brainer. No
        excuses—download one now.
      </div>
      <div class="p-mb-2">
        Rhythm is measured in beats per minute (bpm), and almost all proficient
        players that I’ve coached have a putting rhythm that falls within the
        range of 70 to 85 bpm. Let’s assign a bpm value to your resonant rhythm
        so that you can practice it more easily and effectively. Here’s what to
        do: Step 1: Start your metronome app, set it to 70 bpm, and lay your
        phone on the green or the floor. Step 2: Address the phone as though
        it’s the ball, then take a half step back so you can make swings without
        hitting it. The position of your phone (metronome) should mark the
        bottom of your stroke. Step 3: Begin swinging your putter back and forth
        to the beat of the metronome, making sure that the putterhead passes in
        front of the phone on each audible “beep.” Keep your stroke equal in
        both pace and length on both sides of the “ball.” Step 4: Switch the
        setting on the metronome to 85 bpm and repeat Steps 1 through 3. Now
        that you’ve experienced both extremes of the standard rhythm scale,
        continue changing the beat of the metronome every few swings until it
        matches the optimal rhythm that you discovered in the previous drill.
        Your rhythm is now quantified. Start a “Touch” tab in the Technical Keys
        section of your journal and note the bpm number. Mine is 78 bpm, while
        PGA Tour player Charlie Wi’s, for example, is 72 bpm.
      </div>
      <div class="p-mb-2">
        Using Rhythm to Improve Your Touch Now that you have your rhythm number,
        the trick to mastering your touch is to make strokes to the beat of this
        count while varying your stroke length, and do it comfortably. This is
        my Resonant Rhythm Drill, which should be a mainstay in the
        block-practice portion of your training. There are several benefits to
        performing the drill regularly. First, you can do it just as easily
        indoors as out, and it takes less than a minute to complete, which means
        you can improve this foundation during a snowstorm, a mini-break from
        work, or during a commercial when you’re at home watching TV. Second, it
        ensures that you’ll stroke putts at the same rhythm next week, next
        month, and five years from now, and that continuity—along with the
        feedback provided by the drill in practice—will allow you to master it.
        Putting in rhythm will become second nature. Humans are rhythmic
        creatures, and the beat of the metronome will soak into your
        subconscious (a phenomenon known as entrainment), regulating your whole
        process so that it becomes smooth, graceful, and unrushed. I told you it
        was a no-brainer.
      </div>
      <div class="p-mb-2">
        The Resonant Rhythm Drill Set your metronome to your beat and lay it on
        the ground to mark the bottom of your stroke. Your goal is to make
        continuous swings with the putterhead passing the metronome on each
        audible beat. Start with relatively small strokes—think of the energy
        needed to sink a five-foot putt. Once you’re swinging in sync with the
        beat of the metronome, lengthen your stroke to create the energy
        required to sink a 15-foot putt, without stopping your motion. Notice
        how you must pick up the overall pace of your stroke to keep time with
        the beat of the metronome. After four or five swings at this stroke
        length, continue the process for a 25-foot putt and then a 40-foot putt.
        Finish the drill by returning to the stroke pace and length appropriate
        for the five-footer (think shorter and slower). This whole process
        should take less than a minute—not bad considering you’ve just nailed
        the key technical element that allows for great touch.
      </div>
      <div class="p-mb-2">
        The Resonant Rhythm Drill also is a great way to prepare yourself for an
        upcoming round. In fact, it’ll do you a lot more good than slamming a
        few drives on the range if you’re running late for your tee time. Good
        rhythm affects everything.
      </div>
    </div>
  </Panel>
  <Panel
    header="Implementing Rhythm into your Process"
    :toggleable="true"
    :collapsed="true"
  >
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/rhythmProcess1.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      A Tour-proven rhythm process. 1. Visualize your three points. 2. Walk in
      clear and committed. 3. Get into your putting posture. 4. Make practice
      strokes inside the ball with external focus, feeling the required energy.
      5. Settle your eyes back to your Quiet Eye spot.
    </div>
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/rhythmProcess2.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      If you feel like you lose your starting-line point when you rehearse your
      stroke standing to the side of the ball, rehearse it from behind the ball
      while facing the target before you step in to the ball. Make sure to hold
      the vision of your line in your mind’s eye until you complete your stroke.
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Your process is the ordered steps you go through to get yourself
        physically and mentally ready to play a shot, and having a great one is
        critical if you’re going to be consistently clear and committed when you
        execute. For putting, I suggest you follow the green-reading process
        already laid out for you in Chapter 5, with a few extra steps that will
        allow you to putt at rhythm. For starters, experiment with each of the
        following four process examples, then commit to using the one that has
        the greatest positive impact on both your stroke and results.
      </div>
      <div class="p-mb-2">
        Rhythm Process No. 1 After you read the green and create a mental
        picture of the putt’s roll, walk into the ball holding the image in your
        mind’s eye. (We’ll add a physical cue to begin your walk into the ball
        in Chapter 8, but for now let’s just worry about the overall process.)
        Set up just inside the ball, and swivel your head to mentally engage
        with the target. Begin rehearsing the appropriate-length stroke at
        rhythm, searching for the swing that your subconscious deems appropriate
        for the circumstance. (Remember, it’s easiest to find your resonant
        rhythm using continuous, pendulum-like swings.) After you lock in the
        feel, turn your eyes back to the ball. Align the putterhead behind it
        and reposition your feet. Scan down the start line to your predicted
        entry point and then back to the ball, finishing your scan by settling
        your eyes on your Quiet Eye spot (Chapter 4). Once you’re settled, react
        to the picture of the ball’s roll you created during your read and
        confirmed during your scan, and repeat the swing deemed appropriate by
        your subconscious during your practice strokes, letting the putterhead
        swing under and past your eyes. Process used by: Dozens of PGA Tour
        players, including Rickie Fowler, Cameron Tringale, and Henrik Stenson.
      </div>
      <div class="p-mb-2">
        Rhythm Process No. 2 Some golfers tend to lose the picture of their
        start line when they perform their eye scan if they wait until they’re
        next to the ball to begin making practice strokes. If this sounds like
        you, then alter Rhythm Process No. 1 by taking your rehearsal swings
        from behind the ball. Again, read the green and create your picture, but
        instead of walking in toward the ball, get into your putting address
        position while facing your aim line. This provides a binocular view of
        the target, which some golfers find helpful. As you look down your line
        and imagine your putt, start your continuous rhythmic swings and allow
        your stroke to match the energy deemed appropriate by your subconscious.
        Now walk in (still maintaining an image of the ball’s roll) and
        immediately align your putterhead to the start line. Set your feet, scan
        to the target, focus your gaze on your Quiet Eye spot and react. Used
        by: PGA Tour player and longtime student Charlie Wi, who finished five
        of the last six seasons ranked inside the top 25 in putts per round.
      </div>
      <div class="p-mb-2">
        Rhythm Process Nos. 3 and 4 Some players I coach add an auditory cue to
        their rhythm process in order to initiate and time their stroke. Once
        Tom Pernice, Jr. finishes his resonant rhythm practice swings and
        settles over the ball, he takes one hard look at the target. When he
        feels ready, he turns his gaze back to the ball and counts out “one” in
        his head. In rhythm, he looks back at the target on an internal count of
        “two.” On “three” he swivels his head back to the ball and on “four” he
        settles his eyes. He swings back during “five” and swings through to the
        finish during “six,” maintaining his resonant rhythm on all counts. Tom
        has putted this way for more than two decades. Not only does counting
        aid his rhythm, it ensures that he always feels ready when he pulls the
        trigger. Charlie Wi uses the words “perfect pace” to the cadence of his
        internal metronome to time his stroke. These cue words are
        terrific—they’re a positive affirmation of what’s about to happen.
        They’re also the correct cadence. Unlike Tom Pernice, Charlie counts a
        beat to start his backstroke and another to impact, which is why the
        first word is two syllables and the second word is one syllable. It
        works because his backstroke is half the distance of his total stroke.
        If he timed his motion from backstroke to finish like Pernice, he’d use
        something like “smooth-smooth.”
      </div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
    </div>
  </Panel>
  <Panel
    header="Putt Speed and Capture Rate"
    :toggleable="true"
    :collapsed="true"
  >
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/capture1.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      Expert at Work! PGA Tour player Brad Faxon demonstrates how he visualizes
      and works on line and speed in this special video at
      jsegolfacademy.com/index.php/faxon-three-speeds.
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        SPEED and CAPTURE RATE: Even when armed with an understanding of rhythm
        and a healthy grasp of touch, many students often ask, “What speed
        should the ball be traveling as it reaches the hole in order for me to
        sink the most putts?” Differences in speed (measured in revolutions per
        second) change the effective size of the hole and your overall make
        percentage. I could explain this with some complicated math, but the
        concept is easier to understand with a simple image. Think of the hole
        as a hungry animal, sitting patiently and wanting nothing more than to
        capture the ball for dinner once it nears its edge. If the ball is
        moving so fast that it finishes six feet past the hole on a green of
        average speed, it’s impossible for the critter to pull the ball into the
        hole even if it travels over its center. Gravity just isn’t strong
        enough in that circumstance to overcome the putt’s lateral velocity,
        making the effective size of the hole zero inches. Conversely, a ball
        crawling up to the hole with just enough energy to get more than half of
        it over the edge means it’s dinner time. Gravity has its way and the
        lack of linear speed makes the effective size of the cup its full 4.25
        inches.
      </div>
      <div class="p-mb-2">
        With these facts in mind, it certainly seems advisable to “die” every
        putt into the hole, but that’s not what you see the pros do on TV. Why?
        The answer is twofold. First, the judgment and skill needed to control
        distance within inches is impractical for humans, and the penalty for
        leaving the putt short of the hole is great. You’re better off giving
        yourself a reasonable margin for error by trying to roll the ball at
        least six inches past the cup, so that a small error in speed doesn’t
        reduce your chance of making the putt to zero. As the saying goes,
        “Never up, never in.” Secondly, slope and imperfections in the green
        caused most commonly by footprints—which are most substantial within
        three feet of the cup—exert greater influence on a slow-moving ball than
        they do on one moving with some pace. In the real world, when a ball
        dies just as it reaches the lip of the cup, it tends to fall off quickly
        to the low side or get bumped agonizingly off line. I always chuckle at
        the players who react in disbelief when this happens. Surfaces aren’t
        perfect, and if they had rolled the ball up to the hole with a little
        more speed, it probably would have dropped in, even with the slight
        reduction in the effective diameter of the cup.
      </div>
      <div class="p-mb-2">
        PUTT SPEED STRATEGY AND TRAINING On any putt, the goal is to find the
        middle ground between rolling the ball slow enough to keep the effective
        size of the cup at a maximum and rolling the ball fast enough to
        minimize bumps in the green. The ideal pace, however, varies depending
        on the overall speed of the green and the degree of slope. In essence,
        the perfect speed is one that propels the ball farther past the hole on
        fast greens and downhill putts than it does on slow greens and uphill
        putts. Barring extreme situations, here’s all you need to remember: ***
        If you’re putting on a slow green or uphill, roll the putt with enough
        speed to make it travel a foot past the hole if it misses. If you’re
        putting on a fast green or downhill, roll the putt with enough speed to
        make it travel two feet past the hole if it misses. ***
      </div>
      <div class="p-mb-2">
        PUTT-SPEED TRAINING SOLUTIONS 1. Matching Line and Speed The truth is,
        you can hole any breaking putt with at least three different speeds, as
        long as you pick a start line that matches the speed you’ve chosen.
        Distance control is the primary skill, but learning to see the matching
        line is its bedfellow. To train and improve your matchmaking skill, try
        PGA Tour player Brad Faxon’s Three-Speed Drill. He performs this
        exercise regularly, and is amazing at it. No wonder he’s one of the best
        putters in the professional game.
      </div>
      <div class="p-mb-2">
        Brad Faxon’s Three-Speed Drill Hit the practice green with three balls
        in hand and choose an eight-foot putt with significant break. The goal
        is to make three putts from the same place using three different speeds
        and lines. Roll the first putt with enough speed to make the ball travel
        four feet past the hole (maximum speed, minimum break). Roll the second
        putt on a pace that allows it to just drip over the high side of the
        hole (minimum speed, maximum break). Roll the last putt at your normal
        “playing” speed, so that it breaks somewhere between the first two
        putting lines. (To make it easier at first, place a tee on the aim line
        that indicates your middle-line, normal-speed putt.) Once you make all
        three putts, switch to the opposite side of the hole and repeat.
      </div>
      <div class="p-mb-2">
        2. Mid-Range Work When I first started working with PGA Tour player Skip
        Kendall in 1997, he told me that he putted great inside six feet but
        hadn’t holed a putt over twenty feet in a year. (Perhaps he was
        exaggerating, but I got his point.) You’d think that if your stroke was
        good enough to consistently start the ball on line from six feet and in,
        you’d make your share of putts from any reasonable distance, but it
        doesn’t necessarily work that way. Skip’s problem was that he used a
        slow pace for his backstroke for all putts, which matched well with his
        ideal rhythm on putts close to the hole, but forced him to lose rhythm
        on longer ones. Poor rhythm makes touch and returning the face to square
        at impact difficult, so not only was his touch inconsistent, he was
        missing his start lines as well.
      </div>
      <div class="p-mb-2">
        I consider making mid-range putts a numbers game. If you face ten putts
        from 15 to 25 feet in a round, and you roll all ten somewhere close to
        the hole with perfect speed, you’ll make more than your fair share. If,
        on the other hand, you roll only three out of ten with perfect speed,
        you’ll probably get shut out. The solution: Train your touch by
        regularly performing my 20-Foot Touch Drill. Skip bought into it, and he
        promptly went on a four-year putting tear.
      </div>
      <div class="p-mb-2">
        20-Foot Touch Drill Find a 20-foot putt with a bit of a slope and mark
        the starting point with a tee. Do the same on the opposite side of the
        hole. The goal is to hit ten great-speed putts in a row. A great-speed
        putt is one that goes in or finishes past the front edge of the cup but
        no further than a club length past the hole. If you leave a putt short
        or hit it more than a club length beyond the hole, you have to restart
        the drill. Roll the first two balls from the first tee and the next two
        from the second tee, alternating locations every two putts. If you have
        difficulty rolling ten great-speed putts in a row, simply keep track of
        the number of attempts it takes you to hit ten total, and log your
        results in your journal. (The Two-Hole Knockout Drill from Chapter 2 is
        another great drill to help you produce ideal speed on every putt.)
      </div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
    </div>
  </Panel>
</template>
