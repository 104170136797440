<template>
  <Panel header="Introduction" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Having the feeling that any putt you roll is destined to find the bottom
        of the cup is the master skill on the greens. This “It” factor isn’t
        mysticism or reserved for a chosen few, but is rather a collection of
        championship attributes that, with the right direction, any player can
        possess, cultivate, and develop.
      </div>
      <div class="p-mb-2">
        Putting is a simple act that requires little athleticism. It can be
        performed equally well by a child, a pro at the top of his or her game,
        or an ailing senior, which makes it even more aggravating for those who
        suffer its folly. It’s mainly a mental exercise, which explains why the
        simple act of rolling the ball into the cup has driven many to utter
        distraction.
      </div>
      <div class="p-mb-2">
        We’ve all heard stories about players “losing their marbles” on the
        greens. Maybe you have your own personal saga to tell. My favorite took
        place at Royal St. George’s on the southeastern coast of England at the
        1985 British Open. Sandy Lyle captured the Claret Jug that year, but
        most fans remember the event as the one where Peter Jacobsen tackled a
        streaker on the 18th green during the final round. A bizarre sight, for
        sure, but not as strange as the actions of South African Tour regular
        Simon Hobday, who had made it into the Open field that year.
      </div>
      <div class="p-mb-2">
        Hobday’s play itself was unremarkable (he missed the 36-hole cut). The
        putter he used for the week, however, was not; the putterhead was
        battered and bruised beyond recognition. The story goes that Hobday had
        tied it to the rear bumper of his car following a bout of bad putting at
        the Lawrence Batley International Golf Classic played at The Belfry in
        central England the week prior. According to Hobday, the putter needed
        to be “taught a lesson.” (Hobday had first snapped the putter over his
        knee before tying both pieces to his car.) He dragged the poor
        misbehaving putter all 198 miles between the Belfry and Royal St.
        George’s. Despite obvious damage, Hobday had the putter head reshafted
        upon his arrival at the Open and used it that week, only to repeat his
        abysmal performance on the greens. I assume that, like a petulant child,
        the putter had failed to learn its lesson.
      </div>
      <div class="p-mb-2">
        The lesson, of course, was not the putter’s to learn. When you consider
        the fact that the very best players in the world miss half of their
        putts from eight feet, and that each missed putt presents an opportunity
        for self-ridicule, emotional judgment, and a loss of confidence, it
        takes a mature mental approach to establish and maintain a healthy
        relationship with your putter. Without one, your experiences on the
        greens will make performing more difficult, not easier. Although it’s
        impossible to turn back the hands of time and unlearn the process that
        leads to fear and self-loathing, it’s very possible to use reason,
        knowledge, and self-discipline to reinvent yourself. You have the
        ability to develop the championship attributes that collectively make up
        the last essential skill in putting: confidence. As humans, volition
        (our ability to choose) arms us with the power to fully decide our
        perspective, attitude, mindset, daily habits, and what we deem
        important. The ultimate by-product of these choices is the
        aforementioned “It” factor, which will ultimately allow you to train
        with the intelligence of an adult but play like a child. If you want the
        hole to look as big as a bucket and feel engaged and relaxed over the
        ball, read on.
      </div>
      <div class="p-mb-2">
        THE ROOT OF MENTAL PUTTING PROBLEMS We all enter this world the same
        way: naked and unburdened by expectation. If we’re lucky, parental love
        and a safe home allow us to maintain this sense of innocence throughout
        our preschool years. Children do things simply for the sake of doing
        them, with little sense of outside judgment or opinion.
      </div>
      <div class="p-mb-2">
        An example: naked gymnastics. Let me explain. When my daughter was four
        years old, she went for a playdate with a boy her age down the street.
        After several hours, the little boy’s mother phoned my wife, her voice
        noticeably uneasy. She reported that she had gone upstairs to check on
        the kids and found them tumbling, twirling, and laughing with all of
        their clothes off—performing naked gymnastics. My wife and I thought it
        was adorable, although we appreciated that the boy’s mother demanded
        they put their clothes back on. What does this have to do with putting?
        Whatever my daughter’s skill level as a gymnast was at that point in
        time, she was unburdened by expectation, fear of judgment, or
        inhibition, and she performed up to her athletic potential. Can the same
        be said about you?
      </div>
      <div class="p-mb-2">
        Our loss of innocence starts when we are indoctrinated into the formal
        education process, which reels in our freedom to do things just for the
        sake of doing them. Suddenly, we’re scrutinized and judged at every
        turn, whether it’s a test score, a behavior, or the way we dress. The
        social structure of the lunchroom reigns supreme, and our evolution
        toward typical adult insecurities begins in earnest. The process of
        feeling judged forces us to consider and appreciate possible outcomes
        and their social ramifications. We stop thinking in the present and
        begin to either worry about how things are going to turn out, or fret
        about things that have already happened. After just a few years of this
        socialization process, it’s no longer okay to play naked gymnastics with
        your best friend, even if it’s innocent fun.
      </div>
      <div class="p-mb-2">
        It’s a simple yet sinister process. Negative social interactions lead to
        emotional insecurity, which shape our attitudes and perspective,
        creating a “warped” reality.
      </div>
      <div class="p-mb-2">
        At one point in your life you attempted putts for the pure enjoyment of
        it; now you attempt putts under perceived threat and duress. “What if I
        miss?” And because we’re programmed to avoid threats through the
        fight-or-flight response, our palms sweat, our hearts race, our hands
        tremble, and we approach the ball racked by anxiety.
      </div>
      <div class="p-mb-2">
        Of course, there’s nothing that takes place on a putting green that’s a
        literal threat. If you strip away the emotion, there’s only a ball, a
        hole, and a simple directive. The goal of this chapter is to help you
        relearn how to strip putting down to its basic act and eliminate all
        emotional judgment or consideration of consequence in the process. In
        other words, the goal is to help you putt like a child. The first step
        is to understand the genesis of the problem so that you can recognize
        harmful patterns. After this step, the solution is to choose to develop
        the six following championship attributes. They are the armor that’ll
        protect you from mental harm and allow you to perform up to your full
        athletic potential.
      </div>
    </div>
  </Panel>
  <Panel
    header="CHAMPIONSHIP ATTRIBUTE NO. 1: HEALTHY PERSPECTIVE"
    :toggleable="true"
    :collapsed="true"
  >
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/mental1.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      Expert at Work! Watch Ben discuss process and the “Pinnacle of Trust” in
      this special video at jsegolfacademy.com/index.php/crain-trust.
    </div>
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/mental2.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      Your process is one of the few things you can control. By making it the
      most important thing on every shot and scoring it, you will play in the
      present and try just the right amount. As your ability to execute the
      steps of your process improves, so will the results, and soon you won’t
      have to worry about your results at all; they’ll take care of themselves.
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Former First Lady Eleanor Roosevelt once said, “No one can make you feel
        inferior without your consent.” The problem, of course, is that most of
        us have not only consented to let everyone judge us, but somehow we’ve
        decided that if we beat ourselves up first when things go wrong, then
        the barbs slung by others won’t hurt as much. You simply can’t allow
        this. Championship putters are consistently building themselves up, not
        beating themselves down.
      </div>
      <div class="p-mb-2">
        The “It” factor starts with the proper perspective and a daily
        commitment to feed your psyche the positive nutrition it needs to live
        in the present. You must first realize and then commit to the fact that
        golf is something that you like to do and not who you are, and that
        someone’s perception of you or your putting outcomes doesn’t change the
        so-called “man in the mirror.” Holing a putt doesn’t make you a great
        person, and missing one doesn’t mean you’re a deadbeat. This perspective
        will allow you to protect your self-image and remain humble in victory
        and upbeat in defeat.
      </div>
      <div class="p-mb-2">
        Unfortunately, just “knowing it” or “thinking it” isn’t good enough; you
        have to “live it.” Here are two exercises you can perform daily to help
        you maintain a healthy perspective and stay out of your own way.
      </div>
      <div class="p-mb-2">
        Affirmations and Neural Linguistic Programming An affirmation is a
        positive or encouraging statement you make to yourself: “I love to
        putt.” Neural Linguistic Programming, in simple terms, is the use of a
        physical cue to bring about a strong positive emotion. It can be
        something as basic as shrugging your shoulders or a prolonged and deep
        cleansing breath at the start of your process to remind you to relax and
        accept the great things about to unfold. Using the two together gives
        your psyche the daily positive nutrition it needs to start a substantive
        change.
      </div>
      <div class="p-mb-2">
        Begin by defining how you would ideally like to feel over a short putt.
        Get your journal out, and in the Personal Growth section create an
        “Affirmations” heading. Write the answer to that question in one or two
        sentences. Refer to yourself as “I” and write your statement in the
        present tense, i.e., “I love the challenge that putting presents. I am
        calm, clear, and committed over the ball.” Another example: “The hole
        looks like a bucket and I’m great at trusting my natural gifts.” Be true
        to your goals. There are no wrong answers here, as long as they’re
        positive and in the present tense. Once you have a few to choose from,
        find a quiet place where you can sit or lie back. Take a few deep,
        cleansing breaths—inhale for five to seven seconds through your nose,
        then exhale for five to seven seconds. (If you can’t control your
        breath, how are you going to control your muscles and emotions when you
        putt?) After three breaths, use your physical cue to initiate the
        process and recite your affirmation calmly to yourself twice. As you do
        this, see yourself living the affirmation and rolling the ball into the
        heart of the cup. Take a moment to experience the joy and fulfillment
        you feel, not necessarily because of the result, but because of the way
        you approached the process. Go back to taking deep breaths and repeat
        the process a second time to complete the drill. It should take no
        longer than two minutes to finish. Recite your affirmation three times a
        day for three weeks.
      </div>
      <div class="p-mb-2">
        In my first book, Your Short Game Solution, I wrote that one of the main
        differences between many of the talented pros I work with and most
        everyone else is that the champion is willing to do whatever it takes to
        be great. Success doesn’t come for free—you have to pay for it. If the
        steps to greatness feel awkward or uncomfortable, great; challenge
        equals change. I know more than a few Tour players (Jason Day comes to
        mind), who diligently work on their breathing and focus daily. If you
        want to be the “Boss of the Moss” and you’re coming from a bad place,
        you have to be willing to do the little things daily.
      </div>
      <div class="p-mb-2">
        Define Your Process After your neurology begins to rewire, you’ll use
        your deep-breathing pattern and neural linguistic cue not only in daily
        affirmations, but to start your process when you play. I suggest you
        start your breathing as you walk up to the green complex and execute
        your cue just before you walk into the ball. This timing should allow
        you to closely replicate what you practice during your affirmation
        exercise.
      </div>
      <div class="p-mb-2">
        It’s important that your process is completely defined and clear. As you
        recall, I offered a six-step green-reading process in Chapter 6, and
        three pre-putt process options that incorporated resonant rhythm and
        target focus in Chapter 7. Now it’s time to finish it off by adding a
        post-putt process. Combining all three and clearly defining the final
        product is important for four reasons: 1. Your mind will take comfort in
        going through the steps in the same order and in the same way each time,
        leading to both focus and clarity. 2. You’ll avoid the tendency to
        either slow down or rush when pressure mounts. Instead, you’ll simply do
        what you always do: execute your steps one at a time. 3. Focusing on
        something that you can control helps you stay in the present so you
        don’t try too hard or worry about things you can’t control. 4. A clearly
        defined process can be measured and evaluated, which allows for
        accountability. Answering for your actions is important for growth.
      </div>
      <div class="p-mb-2">
        Your Putting Solution is holistic. When you combine the mastery of
        simple techniques with daily skill development and a great process,
        there’s no need to worry about results. They are merely by-products of
        these actions and will take care of themselves.
      </div>
      <div class="p-mb-2">
        Evaluating Process and Making It Priority No. 1 Very few PGA Tour
        players are more inspiring than Ben Crane. Beyond the victories, he’s
        honest, humble, and has a huge, giving heart. One of the things I’ve
        learned through working with Ben is how to properly evaluate a putt (any
        shot, really). Most players assess using their emotions based on the
        result. This is dangerous for your self-image, because bad putts are
        coming no matter who you are. Ben’s approach is much more mature; he
        ignores emotion and results and instead evaluates and scores the
        execution of his process using a 1-to-5 scale. If he skips a step or
        feels doubt or fear, it’s a “1.” If he focuses on the target, is
        engrossed in the process, and reacts with complete trust, it’s a “5.” On
        the practice green, Ben and I are constantly working on helping him get
        to a place where all he has to do is trust his natural gifts.
      </div>
      <div class="p-mb-2">
        You can’t serve two masters. You’re either going to pay homage to the
        results or to the process. Making the process the most important thing
        is a huge benefit, because it allows you to focus intently without
        “over-trying,” or forcing an outcome. Your goal should be to “try” the
        right amount and not 1 percent more. At my academy at Shadow Ridge, I
        created a “Process Scorecard” that my students fill out in addition to
        their regular scorecard when they play. They must post two scores: the
        total number of strokes it took to complete the round, and the total
        number of shots they played where they executed their process with
        intention and conviction (a “4” or “5” on Ben Crane’s scale.) Ideally,
        your process score and your score relative to par should be a perfect
        match.
      </div>
    </div>
  </Panel>
  <Panel
    header="CHAMPIONSHIP ATTRIBUTE NO. 2: GROWTH MINDSET"
    :toggleable="true"
    :collapsed="true"
  >
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/mental3.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      A player with a growth mindset sees skills as attainable, not set in
      stone, and uses motivation to achieve goals. Brains and talent are
      overrated; improvement starts with hard work and effort. Credit: Trevor
      Ragan, trainugly.com, adapted from Mindset: The New Psychology of Success
      by Carol Dweck, Ph.D.
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        In her groundbreaking book Mindset: The New Psychology of Success,
        Stanford University professor and psychologist Carol Dweck, Ph.D.,
        suggests that a person’s belief systems regarding their own abilities
        and potential fuels their behavior and predicts their success. Moreover,
        the ideal mindset creates motivation and productivity and is a much
        better predictor of success than talent or intelligence. Even more
        important to me as a coach of young people is the idea that a changed
        mindset can have a profound effect on a person’s future and career.
      </div>
      <div class="p-mb-2">
        There are essentially two mindsets: fixed and growth. In a fixed
        mindset, people believe that their basic qualities, such as their
        intelligence and talent, are fixed traits. In a growth mindset, people
        believe their abilities can be developed through dedication and hard
        work—brains and talent are just the starting point. This view creates a
        love of learning and a resilience that’s essential for accomplishment.
        Virtually all successful people have these qualities.
      </div>
      <div class="p-mb-2">
        As you peruse the table above, look critically at yourself in each
        category. How do you think and react for each action item? Create a
        heading in the Personal Growth section in your journal and label it “The
        Right Stuff.” Underneath, make two columns with the labels “Obstacles”
        and “Solutions.” Next, write down all the roadblocks and pressures you
        typically feel on the course, and then use a growth mindset to write
        down a solution for each. Intellectually, you must accept that playing
        golf is hard and that obstacles are always lying in wait. Embrace them
        as opportunities to grow, because they’re never going away.
      </div>
      <div class="p-mb-2">
        In my opinion, the genesis for developing a growth mindset is in having
        a dream—a long-term vision of what you want to accomplish in the
        future—and the guts to not let anything or anyone stop you from
        attaining it. A person with a growth mindset cares little about the
        emotional judgment of others; only progress matters. As passion fuels
        your approach, you naturally create resolve, courage, strength of
        character, fortitude, toughness, tenacity, perseverance, endurance, and
        resilience—some people call this “grit.” When you have it, you’ll use
        obstacles, challenges, and poor shots to direct future actions, not
        throw a pity party. The obstacles standing in your way aren’t
        roadblocks—they’re the road map to success.
      </div>
      <div class="p-mb-2">
        Mike Krzyzewski, head coach of the men’s basketball team at Duke
        University, once said, “If you find a road without any obstacles on it,
        it’s probably not worth taking.”
      </div>
      <div class="p-mb-2">
        This quote captures the growth mindset perfectly. I had the privilege of
        watching one of Coach K’s practices at Cameron Indoor Stadium as he
        prepared his veteran players and incoming freshman for the start of
        Duke’s 2014–15 championship season—a bucket-list moment for me. Coach K
        has created a clear culture of growth in his program. Fold into that
        culture a mixture of hard work, talent, and accountability, and it’s not
        at all surprising that the Blue Devils are perennially relevant.
      </div>
      <div class="p-mb-2">
        I’ve tried hard to establish a similar culture at my academy and with my
        clients on Tour. I demand that each student react unemotionally to their
        misses and self-coach by stating the solution in a positive way with
        zero negative talk. (Of course, I’m there to guide them if they
        misdiagnose.) We write out technical, training, and mental management
        keys at the completion of each session. I demand that they endeavor to
        stay on task and be accountable to the plan by completing homework,
        scheduling follow-up e-mails, and journaling training results. Many of
        the Tour players I coach send out an audio report after each tournament
        round so everyone on the team knows what happened that day and what
        solutions are to be implemented going forward. We’re all in, which is
        one reason they play so well. Is that the culture you’re currently
        operating in?
      </div>
      <div class="p-mb-2">
        Unfortunately, the traditional approach to golf instruction and
        improvement isn’t helping you. It’s broken. Golf is the only sport I can
        think of where players are commonly taught and not coached. To wit, a
        student shows up for a lesson once in a while, generally when they’re at
        the end of their rope. The teacher tells them everything they’re doing
        wrong from a technical standpoint. They came to the lesson feeling bad
        about their game and now they feel worse. They leave with concepts on
        how to improve mechanics, but there’s no discussion of mindset, the keys
        to effective training, mental management, skill development, or
        structure to keep them accountable. They’re paying for a ray of hope and
        little else.
      </div>
      <div class="p-mb-2">
        It’s time for all golf professionals to get with the times and establish
        coaching protocols for performance. I’m happy to report that I’m not the
        only instructor who feels this way. Many of the coaches I meet on the
        road, including my fellow Golf Magazine Top 100 Teachers in America,
        Golf Digest’s 50 Best Teachers, and my friends at the Titleist
        Performance Institute, are on board. There are also a lot of really
        great, young, up-and-coming coaches who embrace the growth mindset and
        are getting great results with their students. The teaching profession
        is evolving for the better—now’s the time for a mature, disciplined
        approach from the student.
      </div>
    </div>
  </Panel>
  <Panel
    header="CHAMPIONSHIP ATTRIBUTE NO. 3: POST-SHOT ROUTINE"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        As mentioned above, one key strategy in employing a growth mindset is
        having a disciplined and effective post-shot routine. It’s a completely
        mental exercise and should take no more than five seconds. The time
        directly following the execution of an action is when your self-image is
        most malleable, which is either really good news or bad news, depending
        on your mental habits. After you hit a putt, the results will meet your
        expectations or they won’t. The question is, how are you reacting to it
        and what should you do to grow at the fastest rate?
      </div>
      <div class="p-mb-2">
        On a putt that feels great, take ownership of it with a bit of emotion.
        Give it a fist pump like the Tiger Woods of old, or, if you’re not wired
        that way, just smile. As you do, take a few seconds to embrace what you
        did well, i.e., “that was great rhythm,” “that was a great read,” or “I
        stroked that with great focus and conviction.” That’s called
        “imprinting.” The ultimate goal after a great putt is to create a
        high-energy positive emotional state to play in, and regular positive
        imprints will grow your self-image, increase your confidence, and put a
        little swagger in your step.
      </div>
      <div class="p-mb-2">
        Don’t undersell your successes. Embracing positive results seems logical
        and easy enough, but it’s not all that common at the club level. Many of
        the weekend warriors I coach tend to “ho-hum” their good shots, or even
        deride themselves despite their good fortune, as in “It’s about time I
        made a putt,” “That was lucky,” or “If I’d only putted that way last
        week!” Never self-pity, self-mock, or self-loathe. When you do well,
        take the credit.
      </div>
      <div class="p-mb-2">
        On a putt that feels lousy, the five seconds following your miss are
        some of the most important you’ll have that day, and they can have a
        profound effect on your career as a golfer. To borrow from the great
        poet Robert Frost, you can choose a road that leads to sorrow or one
        that leads to growth and fulfillment, but the right choice isn’t
        necessarily intuitive or easy to discern.
      </div>
      <div class="p-mb-2">
        PATH A: THE ONE MORE INVITING On this path, you choose to embrace your
        poor results emotionally and wallow in self-pity, whine, bellyache, hang
        your head, or use the offending club as a tomahawk. Ugly stuff. With
        this mindset, problems appear impossible to solve as you replay them
        over and over in your mind. If this describes you, then not only is your
        future bleak, but you’re no fun to play with even if you look like a pro
        and have a swing to match. This road is fraught with danger, since every
        round is a roller coaster of emotion and you never know what’s around
        the next corner.
      </div>
      <div class="p-mb-2">
        PATH B: THE ONE LESS TRAVELED On this path, you choose to look at the
        result unemotionally and use the information you discern to help direct
        future actions in a positive way. Your first step on this path is to ask
        “What’s the solution?” or “If I had a do over, what would I do
        differently?” Once you determine the correction, state it in a positive
        way—“Play more break,” “Swing with rhythm,” “Maintain my suspension
        point,” or “Run a cleaner process”—and then replay that correction in
        your mind while visualizing the improved outcome. Then leave it. The
        shot is over. Don’t take it with you to the next shot, the next hole, or
        to dinner. Focus on the first step of the next shot in your round.
      </div>
      <div class="p-mb-2">
        When you choose this path, your poor shots won’t cause you to lose
        confidence or second-guess your mechanics. Instead, they’ll help you
        recommit to your foundational beliefs and use any negative energy
        produced by the result to steel your resolve for the next putt or swing.
        Determination born from mistakes is the greatest of all competitive
        traits. You see it in the face of champions in any sport at the biggest
        moments. It’s the will to win. It’s another gear, but it’s mental, not
        physical.
      </div>
      <div class="p-mb-2">
        Two roads diverged in a wood, and I— I took the one less traveled by,
        And that has made all the difference. FROM “THE ROAD NOT TAKEN” BY
        ROBERT FROST
      </div>
      <div class="p-mb-2">
        The great actress Katharine Hepburn once said that her secret to success
        was to “never complain or explain,” and the same can be said for
        behavior on the road less taken. The championship mind owes no one an
        explanation. I recall the late, great Seve Ballesteros’s response to a
        reporter when asked to clarify how he four-putted a hole: “I miss, I
        miss, I miss, I make.”
      </div>
      <div class="p-mb-2">
        The inviting path doesn’t lend itself to sustained growth or fulfilled
        potential. One January afternoon a few years back I met up with
        Champions Tour player Duffy Waldorf to help him get ready for his
        upcoming season. He had taken some time off, and to be honest, his
        performance in the first couple of hours of our session was pretty
        lame—definitely not up to his standards or mine. Through analysis, trial
        and error, and needed repetitions, his shots gradually improved until he
        got to a point at the end of the day where he was firing on all
        cylinders. After the session, as we were writing key concepts into his
        journal and summing up the day, I told him, “I’m really proud of you for
        acting like a true pro through the first couple of hours. You didn’t
        bitch and moan, get frustrated or make excuses. You kept your head in it
        and accepted the bad start as a challenge, and I think that maturity was
        the key to the day.” Duffy replied, “Well, for the most part, those
        types of players never make it out on Tour—it’s a barrier to entry.”
        Then, after a slight pause, he added, “Though I suppose a Scott Hoch or
        two slips through every once in a while.” I literally fell out of the
        cart I laughed so hard. No offense to Mr. Hoch—if anything it shows how
        much talent he had, because he was a heck of a player in his prime. But
        usually the players who “complain and explain”—as Hoch was famous
        for—fail to reach great heights.
      </div>
    </div>
  </Panel>
  <Panel
    header="CHAMPIONSHIP ATTRIBUTE NO. 4: UNDERSTANDING THAT ATTITUDE SHAPES REALITY"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        One thing I’m constantly reminded of on Tour is how a player’s attitude
        shapes his or her reality and predicts the future. I may have two
        different players in the middle of the pack in the Strokes Gained
        Putting statistic (the same reality), yet one player claims he “hasn’t
        made a putt all year” while the other feels encouraged because he’s
        “stroking it really good.” It doesn’t take a rocket scientist to predict
        which of these two players will exhibit resiliency when the inevitable
        lip-out occurs, or who is more likely to execute with confidence in the
        big moment.
      </div>
      <div class="p-mb-2">
        Fellow PGA Tour coach Sean Foley and I were discussing attitude at a
        recent tournament, and at one point he offered the following lesson.
        “Two players are driving to the course in two separate cars. There’s
        more traffic than expected—both are going to be late. Player one becomes
        stressed, angry, impatient, and aggravated by anyone who pulls into his
        lane or any extra slowing of traffic. He’s obsessed with the negative
        consequences of his misfortune. At one point, he angrily shakes his fist
        at a Hells Angel driving between lanes. His attitude has allowed the
        environment to ruin his day and cloud his judgment. This could end
        badly.
      </div>
      <div class="p-mb-2">
        “Player two, on the other hand, understands his predicament but chooses
        to focus on a solution. He calls his playing partner to let him know
        he’ll be late and he accepts the environment for what it is: an
        opportunity to accomplish something he hasn’t had time for recently. He
        decides it’s the ideal time to call his mom and tell her how much he
        loves and appreciates her. The conversation uplifts him—he feels
        blessed, which makes it easier to tackle the obstacles he’ll encounter
        when he eventually arrives at his destination. Stress-free, he feels
        ready for the challenge.”
      </div>
      <div class="p-mb-2">
        Again, it’s the same environment for both players, but the attitude of
        the actor creates a different reality for each. Fortunately, you can
        choose the attitude you bring to the car ride, and it’s the precursor to
        everything that follows. At the end of the day, we will all meet our
        maker. How would you like to be remembered by those who love you? Choose
        to meet obstacles with a positive attitude and change your reality for
        the better.
      </div>
    </div>
  </Panel>
  <Panel
    header="CHAMPIONSHIP ATTRIBUTE NO. 5: EMBRACING THE RISK OF EXCELLENCE"
    :toggleable="true"
    :collapsed="true"
  >
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/mental4.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      All putts are not equal, even those struck from the same distance. As the
      make percentages for a six-foot putt by a low-handicap player prove, the
      more a putt breaks or runs downhill, the less likely the player will make
      it.
    </div>
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/mental5.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      On any sloping green, the area where the expected make percentage is 50
      percent or higher is biased toward the low side of the cup. You may be
      just as likely to hole a straight eight-footer from below the hole as you
      are a four-foot putt from the high side of the green.
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        To perform at a high level, you must value and willingly accept the
        risks required to be excellent more than you value “not failing.” Call
        it “playing with courage,” “playing like you don’t care,” or simply
        believing in your abilities. In sport, as in life, fortune favors the
        brave. Think about it: The reward for performing at the top compared to
        merely playing well is highly disproportionate in golf, whether you’re
        talking about prize money, better playing opportunities, legacy,
        sponsorship, or scholarship dollars. On the PGA Tour, you could make
        every cut in a calendar year and lose your card, yet if you can manage
        just one win, you’ll find yourself riding down Magnolia Lane during
        Masters week with fans praising your name. As such, you have to embrace
        the risk of going for it and failing as a natural part of being
        excellent. If you don’t, there’s someone else who will, and if it’s
        their week, you’ll find yourself looking up at them on the leaderboard.
      </div>
      <div class="p-mb-2">
        To drive the point home for young players whose parents are usually
        telling them to “play it safe,” I ask them the following question (in
        front of their parents, when possible). “Do you ever want to
        three-putt”? The normal response is a quizzical look and a firm “no,”
        but of course it’s a trap. The truth is that if you don’t suffer the
        occasional three-putt, then you’re not playing aggressively enough to
        one-putt often, and it takes more than an occasional one-putt to stand
        apart from the crowd. One-putts need to occur regularly.
      </div>
      <div class="p-mb-2">
        Elite putters know that you have to be willing to lose—and know you’ll
        be okay when you do—in order to win.
      </div>
      <div class="p-mb-2">
        For those who still don’t quite get it, answer this question: If you’re
        playing basketball in a rivalry game and you finish the game with zero
        fouls, did you play good defense? Obviously, fouling the other team is
        bad if you look at it through a narrow lens, but from a champion’s
        perspective, it’s a necessary part of winning. A player who plays a full
        game without fouling didn’t play aggressively enough to maximize their
        effect on the outcome. There’s obviously a sweet zone of aggressiveness
        to operate in, but that zone is rarely occupied by the timid.
      </div>
      <div class="p-mb-2">
        PGA Tour player Brad Faxon, one of the best putters of the modern era,
        once told me that during his best putting rounds, he noticed he would
        finish all 18 holes without a single tap-in. Essentially, every putt was
        hit so firmly that if it didn’t go in (which it did a lot), it went far
        enough past the hole that he had to mark his ball before he could
        finish. This isn’t a comment on the optimal pace to roll the ball, but
        rather a testimony to the mindset of a master putter.
      </div>
      <div class="p-mb-2">
        Get the Odds in Your Favor Be bold, not reckless. Certain putts deserve
        more caution than others, forcing you to alter your definition of
        success. I suggest you categorize the putts you face like a stoplight:
        “green” for go (most putts), “yellow” for caution (downhill sliders for
        example), and “red” for simply cozying the ball up to the hole (you know
        the situation when you see it). A red-light putt and your subsequent
        decision to play it safe is a reflection of your prudence, not fear.
      </div>
      <div class="p-mb-2">
        Prudence aside, the easiest way to reduce the number of putts it takes
        you to complete a round is to hit your approach shots into more
        green-light zones than yellow or red ones, even if it means aiming away
        from the flag. In my wedge book, Your Short Game Solution, I called this
        “quality position,” and it applies to putting as well. A quick study of
        standard make percentages will help you see the light. Assume that your
        average make percentage from six feet over the course of a year is
        exactly 50 percent. That doesn’t mean there’s a 50 percent chance you’ll
        make every six-footer you face. Some putts are easier than others; for
        example, you’ll probably make more straight uphill putts from six feet
        than straight downhill ones, or those with substantial break. The table
        below is a generic illustration of make percentages for a club-level
        golfer from six feet on a moderately fast green with a significant
        slope.
      </div>
      <div class="p-mb-2">
        From the illustration it’s easy to see that downhill breaking putts
        require more adept judgment and skill as you attempt to match line and
        speed, which lowers the make percentage. In addition, downhill putts
        rolling slowly across a bumpy green are much less likely to hold their
        line. For this reason, when you face a cup location cut on a slope,
        you’d be wise to value quality position when playing a wedge shot,
        bunker shot, lag putt, or approach shot into a green. Let’s call giving
        yourself a better than 50 percent chance to convert the putt the
        “scoring zone.” On a green with slope, the scoring zone isn’t a circle
        around the cup as you’d expect, but an oblong oval biased toward the low
        side of the hole, as shown below.
      </div>
      <div class="p-mb-2">
        (Note: These pictures are for illustrative purposes only, and do not
        reflect data for any individual. Actual make percentages differ greatly
        based on individual skill, stroke tendencies, severity of slope, speed
        of the greens, and quality of the surface.)
      </div>
      <div class="p-mb-2">
        Statistics show that players make a slightly smaller percentage of
        birdie putts from a given distance than they do when the same putt is
        for par, and I have heard more than a few academics suggest that this is
        due to our normal tendency to practice “loss aversion,” but I’m not
        completely sold on the concept. Without proper controls, you can make
        data suggest almost anything, and as Mark Twain said, “There are lies,
        damned lies, and statistics.” Plus, it’s simply a matter of fact that
        most par putts are easier to make than birdie putts, if only because
        birdie putts follow longer approach shots, which typically end up in a
        random pattern around the hole, while par putts are typically preceded
        by short-game shots, which tend to roll across the green toward the pin
        and are influenced by slope to end up below the hole more often. I think
        this is why Jack Nicklaus said that the key to playing Pebble Beach,
        with its severe back-to-front-sloped Poa annua greens, was being able
        control your approach shots so that they ended up on the front of every
        green and below the hole. Valuing quality position puts the odds in your
        favor!
      </div>
    </div>
  </Panel>
  <Panel
    header="CHAMPIONSHIP ATTRIBUTE NO. 6: PREPAREDNESS"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        A major goal with any player is to free up, trust, and let it go, but
        trust that’s sustainable over time has to be earned. The work must come
        first. Being and feeling prepared allows confident belief to occur
        naturally. Effective preparation requires a long-term perspective,
        because what you do today doesn’t affect tomorrow’s performance, but
        rather, your performance a month from now. There is no “cramming” for an
        upcoming event. In fact, the one factor that correlates most directly to
        performance is to be well-rested, which means that the evening before a
        tournament, you’re better off going to a movie and turning in early than
        running out to the putting green to get in some “extra work.”
      </div>
      <div class="p-mb-2">
        You gain confidence only when you pay your dues on time and in full. A
        recent experience crystalized this truth for me. Not long ago I was one
        of the keynote speakers at the World Golf Fitness Summit in San Diego, a
        three-day event attended by more than seven hundred golf, medical, and
        fitness professionals from around the world. My daughter, who was
        eighteen at the time and showing interest in a sports-medicine career,
        made the trip with me, which made for a great couple of days. Waiting
        for my time to speak, we sat in the audience as former NFL coaching
        great Dick Vermeil shared the motivational keys he used to lead the St.
        Louis Rams to victory in Super Bowl XXXIV. Despite no more than a casual
        glance at my presentation earlier in the week, I felt at ease and
        confident as I walked onstage, which was a bit surprising because I
        anticipated being nervous. Although I don’t speak in front of big groups
        often, I talk and coach short-game performance every day. I live it. And
        because I “own” my content, I felt completely prepared and comfortable.
        The words flowed naturally despite following a coaching legend and the
        intense environment of the summit (I made my daughter proud that day).
      </div>
      <div class="p-mb-2">
        Just a week later, I was working with two of my longest-tenured
        professional clients, Tom Pernice, Jr. and Charlie Wi, at Bear Creek
        Golf Club in Southern California. After a full morning of short-game
        practice, we played nine holes. It was a beautiful serene evening—I was
        playing with good friends with little more than a friendly wager on the
        line and absolutely nothing to stress out about. But as I addressed a
        short putt for par on the first hole, I could feel nervous energy
        entering my mind and body. It’s ridiculous—I began playing golf as a
        three-year-old, competed as a collegian for four years and as a
        professional for another five, have specialized in the short game for
        decades and teach putting nearly every day. Shouldn’t I have been calm
        and confident?
      </div>
      <div class="p-mb-2">
        No, because knowledge doesn’t create confidence. The work you’ve put in
        does. The reality as I stood over that short putt at Bear Creek is that
        I hadn’t hit a putt in months, nor invested any meaningful time training
        in my process, so I wasn’t physically or mentally ready to perform. You
        can’t rest on what you’ve done or what you know. Being prepared is a
        cumulative by-product of doing the right things the right way every day.
        The work has to come first. Trust has to be earned.
      </div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
    </div>
  </Panel>
  <Panel header="JOURNAL WORK" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Open your journal to the Personal Growth section and write in the six
        “It” factor traits discussed in this chapter, then make the decision to
        do the “right stuff” every day. Begin by writing down one simple
        affirmation about how you can feel, think, and act like a champion for
        each trait. Example: “I am going to lead the Tour in attitude” or “I
        love overcoming the obstacles in my way because they lead me down the
        path of sustainable improvement.” Recite them once a day for three
        weeks.
      </div>
      <div class="p-mb-2">
        In the same personal growth area, create a tab with the heading, “Items
        I Choose to Make Important: My Putting Process.” Write out the three
        stages of your overall process in complete detail. These are the
        organized steps that allow you to read the green, be confident and
        engaged, make your stroke a childlike reaction to the target, and grow
        from the result. Of course, tailor your steps to fit your personality
        and tendencies. Use the summation of the process that I’ve laid out for
        you (Chapters 6–8) as a guide. It should look something like this:
      </div>
      <div class="p-mb-2">
        Pre-Putt 1. Begin to cycle deep cleansing breaths as I approach the
        green and identify the fall lines relative to the cup and ball. Recite
        my affirmation: “I’m the boss of the greens. I love to putt.” 2. Crouch
        down behind the hole on the opposite side of the ball and scan
        horizontally (coins) to discern the slope of the last three to four feet
        of the putt. 3. Walk to the ball side, stop halfway, and use the side
        view to discern if the putt is uphill or downhill and how much. Use the
        feels from my feet to help determine the degree of slope—they never lie.
        4. Finish the walk and crouch down behind the ball and piece together
        the first part of the putt using horizontal eye scans. Take note of the
        shiny and dark hues in the green between the ball and the hole (you’ve
        seen the grass from three positions, the grain affecting the putt should
        be clear). Visualize the intended speed, and if break is detected, shift
        my body and perspective slowly down the slope and allow my subconscious
        to choose both a start line and an entry point into the hole, clearly
        defining my three points. 5. Use my cue to initiate my walk in to the
        ball and signal my brain that it’s “go” time. Stay clear and committed
        to the chosen strategy. 6. Focus externally and engage with the target.
        Use the picture of the ball’s predicted roll to guide my rehearsal
        swings, feeling optimal rhythm and appropriate energy. 7. Align the face
        to the start line and address the ball, scan the three points
        deliberately, settle eyes on my Quiet Eye spot, and ...
      </div>
      <div class="p-mb-2">
        Your Putt: ... 1. let it go. React subconsciously. Putt back and through
        past my eyes with a slight awareness of my stroke key and nothing more.
      </div>
      <div class="p-mb-2">
        Post-Putt 1. Imprint “That’s like me” (good putts) or unemotionally
        state the solution in a positive way (bad putts) so that I remain
        committed to my foundations and grow from the experience.
      </div>
      <div class="p-mb-2">
        Now own it. Stay in the present and commit to making the process—not
        your results—the most important thing!
      </div>
    </div>
  </Panel>
</template>
