<template>
  <div class="p-d-flex-column">
    <quickReference />
    <puttingBasics />
    <artOfPutting />
    <psychologyOfPutting />
    <advancedTechniques />
  </div>
</template>
<script>
import quickReference from "@/components/UtleyPutting/quickReference";
import puttingBasics from "@/components/UtleyPutting/puttingBasics";
import artOfPutting from "@/components/UtleyPutting/artOfPutting";
import psychologyOfPutting from "@/components/UtleyPutting/psychologyOfPutting";
import advancedTechniques from "@/components/UtleyPutting/advancedTechniques";

export default {
  name: "UtleyPutting",
  components: {
    quickReference,
    puttingBasics,
    artOfPutting,
    psychologyOfPutting,
    advancedTechniques,
  },
};
</script>

<style lang="scss">
// GENERAL STYLES
.accent1 {
  font-weight: bold;
}
.accent2 {
  color: red;
  font-weight: bold;
}
.accent3 {
  color: red;
}
.fontSmall {
  font-size: small;
}
.fontSmaller {
  font-size: smaller;
}
.fontMedium {
  font-size: medium;
}
.fontLarge {
  font-size: large;
}
.taLeft {
  text-align: left;
  padding: 0 0.25em 0 0.25em;
}
</style>
<span class="accent1"></span>
<img src="@/assets/images/hardy1/hardyFinish1.png" alt="Hardy Posture" />
<div class="accent1 fontSmall p-mb-2"></div>
