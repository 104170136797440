<template>
  <div class="p-d-flex-column">
    <Panel header="BTT Introduction" :toggleable="true" :collapsed="true">
      <BTTIntroduction />
    </Panel>
    <Panel header="Balance" :toggleable="true" :collapsed="true">
      <Balance />
    </Panel>
    <Panel header="Tempo" :toggleable="true" :collapsed="true">
      <Tempo />
    </Panel>
    <Panel header="Tension Awareness" :toggleable="true" :collapsed="true">
      <TensionAwareness />
    </Panel>
  </div>
</template>

<script>
import BTTIntroduction from "@/components/piaBalanceTempoTension/bttIntroduction";
import Balance from "@/components/piaBalanceTempoTension/balance";
import Tempo from "@/components/piaBalanceTempoTension/tempo";
import TensionAwareness from "@/components/piaBalanceTempoTension/tensionAwareness";

export default {
  name: "SieckmannPutting",
  components: {
    BTTIntroduction,
    Balance,
    Tempo,
    TensionAwareness,
  },
};
</script>

<style lang="scss">
// GENERAL STYLES
.accent1 {
  font-weight: bold;
}
.accent2 {
  color: red;
  font-weight: bold;
}
.accent3 {
  color: red;
}
.fontSmall {
  font-size: small;
}
.fontSmaller {
  font-size: smaller;
}
.fontMedium {
  font-size: medium;
}
.fontLarge {
  font-size: large;
}
.taLeft {
  text-align: left;
  padding: 0 0.25em 0 0.25em;
}
</style>
<span class="accent1"></span>
<img src="@/assets/images/hardy1/hardyFinish1.png" alt="Hardy Posture" />
<div class="accent1 fontSmall p-mb-2"></div>
