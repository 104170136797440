<template>
  <Panel header="Nutrition and Energy" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2"><b>FOOD AND ENERGY/ CONCENTRATION/</b></div>
      <div class="p-mb-2">DURING A ROUND</div>
      <div class="p-mb-2">
        How often do you resist your hunger urges during a round of golf by
        telling yourself you’ll eat when you reach the clubhouse? How often do
        you forego food at the halfway hut because you think eating something
        results in a guaranteed double-bogey the next hole? How often do you
        struggle to drag your legs up the 18th fairway because you’re so low on
        energy? Most golfers reading this will answer ‘regularly’ to at least
        one of the above questions, but nutrition in golf is extremely
        important, and something we should all take seriously.
      </div>
      <div class="p-mb-2">
        Some rounds of golf can burn as many as <b>2,000 calories</b>. To give
        some perspective, that’s the recommended daily intake for women and 500
        short of the daily recommended intake for men.
      </div>
      <div class="p-mb-2">
        If you haven’t consumed enough calories, you become tired.
      </div>
      <div class="p-mb-2">
        If you’re tired, you’re far more likely to make bad decisions and mental
        mistakes that could cost you the monthly medal or even the club
        championship.
      </div>
      <div class="p-mb-2">
        The first thing to do is make sure you come prepared. If you don’t bring
        your own snacks, you’ll inevitably end up eating chocolate bars, which
        really won’t do you much good.
      </div>
      <div class="p-mb-2">
        First of all, make sure you have a solid meal before you go out to play.
      </div>
      <div class="p-mb-2">
        After three or four holes, a good idea is to eat a cereal bar. This will
        provide a small boost of carbohydrate and ensure you aren’t dipping into
        your reserves.
      </div>
      <div class="p-mb-2">
        The more you dip into your reserves, the more your energy levels will
        suffer.
      </div>
      <div class="p-mb-2">
        You don’t want to eat many carbs as the aim at this point is to
        stabilise levels, not increase them.
      </div>
      <div class="p-mb-2">
        Some will favour fruit and nuts, which provide a good balance of slowly
        digesting fibre and fat.
      </div>
      <div class="p-mb-2">
        Around the turn, you should be looking to eat something that provides a
        good balance of protein, carbohydrate and fat.
      </div>
      <div class="p-mb-2">
        Something like a tuna or chicken sandwich on brown bread is ideal –
        chicken and tuna provide high-quality and therefore more digestible
        protein, and brown bread is a source of preferable carbs.
      </div>
      <div class="p-mb-2">
        Bacon is more processed and eating white bread can result in a crash, so
        as tempting as it is, it’s probably best to avoid a bacon sandwich on
        white.
      </div>
      <div class="p-mb-2">
        Over the final six holes, you should be looking for an energy boost –
        this will make sure you don’t feel sluggish and help you concentrate.
      </div>
      <div class="p-mb-2">
        Bananas, dried fruit and nuts are all good options.
      </div>
      <div class="p-mb-2">
        It's an often-overlooked aspect of performance but it can make a huge
        difference. So, just as you spend time and money on lessons and new
        equipment, invest a little into your nutrition and fuel yourself for
        better golf.
      </div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
    </div>
  </Panel>
  <Panel header="Nutrition and Emotion" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2"><b>FOOD AND EMOTIONS</b></div>
      <div class="p-mb-2">
        The links between food and anxiety have been well documented for more
        than a decade. Some foods and supplements can help produce a calmer
        state, while others can create additional stress and arousal. Some of us
        are more influenced by what we eat than others, but all of us can better
        manage our arousal if we know what helps and hurts our efforts.
      </div>
      <div class="p-mb-2">
        FOODS TO REDUCE ANXIETY: To recap, here are nine foods and beverages
        that may promote calm: <br />Raw fruits and vegetables. <br />Pumpkin
        and squash seeds. <br />Fermented foods (yogurt). <br />Walnuts.
        <br />Foods high in vitamin C. <br />Whole grains. <br />Tea.
        <br />Different types of milk. <br />Dark chocolate
      </div>
      <div class="p-mb-2">
        FOODS THAT STIMULAGE ANXIETY: Caffeine stimulates you by increasing the
        neurotransmitters in your brain, sympathetic nervous system activity,
        and the release of adrenaline-the same reactions that occur when your
        body responds to stress!
      </div>
      <div class="p-mb-2">
        Too much salt from processed and snack foods, as well as salt we add to
        our foods, stresses the body by depleting potassium, a mineral necessary
        for the nervous system and by increasing blood pressure.
      </div>
      <div class="p-mb-2">
        Moderate to large amounts of simple sugars in beverages, cereals,
        cookies, candies, processed iuices, and the like can lead to extreme
        fluctuations in blood sugar, which can trigger anxiety, palpitations,
        irritability, and trembling.
      </div>
      <div class="p-mb-2">
        In addition, skipping meals, eating large meals, and eating too fast all
        compromise your abilities to digest your food and maintain steady
        productions of blood sugar for energy. As blood sugar fluctuates,
        tendencies toward anxiety can increase or become difficult to manage.
      </div>
      <div class="p-mb-2">
        Supplements: they recommend vit C, B complex, multivites, calcium,
        magnesium, amino acids.
      </div>
    </div>
  </Panel>
</template>
