<template>
  <Panel header="Introduction" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        The first step in learning how to roll putts in the direction you intend
        is to clearly define how you plan on doing it, leaving no variable or
        nuance unexplored. Your competency depends on understanding and
        believing in your method, coupled with effective training.
      </div>
      <div class="p-mb-2">
        If you were to paint a picture of your putting stroke, what would it
        look like? If the image isn’t clear and precise, or if it takes you a
        while to conjure it, you have a problem. How can you master something if
        you haven’t defined it? How can you commit to a plan that even you—its
        creator—don’t fully understand? You can’t. Believing in and trusting
        your method is an act of will, not a feeling you get after a few made
        putts. In other words, if you don’t own it, you’ll never have it.
      </div>
      <div class="p-mb-2">
        When the time is right to begin teaching a student technique, my first
        task is not only to present a clear picture of how the putter should
        move (based on his or her style), but to also make them understand the
        benefits of moving it that way, as well as the fundamentals required to
        get it done. The “doing” part of putting is the easy part. Let’s face
        it—you don’t have to be a world-class athlete to stroke putts. As you’ll
        learn, the quality and consistency of your stroke depends more on your
        ability to grasp why you swing the putter a certain way—and creating a
        feel for this movement—than it does your physical talents. So let’s get
        started. Lesson one: To consistently start the ball on line, you must:
        1. Square the putterface to your intended line at impact 2. Contact the
        ball on the sweet spot of the putterface 3. Maintain a “neutral” putter
        path through impact 4. Strike the ball in the putter’s center of mass
        These are the building blocks of a successful stroke. Some are more
        critical than others, but all affect the start line in some way. As you
        begin the process, keep in mind that your stroke is a means to an end,
        not the end itself.
      </div>
      <div class="p-mb-2"></div>
    </div>
  </Panel>
  <Panel header="Square Putterface" :toggleable="true" :collapsed="true">
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/physics01.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      When it comes to starting the ball on line, nothing is more important than
      squaring the face at impact. Face angle is king.
    </div>

    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        SQUARE PUTTERFACE Contacting the ball so that it rolls in the direction
        you intend is mostly influenced by the angle of the putterface at
        impact. If your putter is pointing to the right of your intended line as
        you make contact with the ball, the putt will almost assuredly start to
        the right of your line, regardless of the other variables. The same goes
        if the putterface is pointing to the left at impact. Numerous studies
        indicate that face angle determines up to 85 percent of start direction.
        This isn’t “coach-speak”—it’s an absolute.
      </div>
      <div class="p-mb-2">
        Therefore, the first step in starting the ball on line is to visualize
        what a properly aimed putterface looks like at address and then figure
        out how to re-create the same face angle at impact.
      </div>
    </div>
  </Panel>
  <Panel header="Sweet-Spot Contact" :toggleable="true" :collapsed="true">
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/physics2.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      Putts struck on the sweet spot are more likely to start on line and with
      the correct speed. Missing the sweet spot causes the putter to twist,
      creating face-angle errors at impact as well as a loss of energy
      transferred to the ball.
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Step two is to contact the ball on the sweet spot. Errors here don’t
        affect start line as heavily as face angle, but they should definitely
        be avoided. If you make contact with the ball out toward the toe of the
        putterhead, it’s highly likely the ball will start slightly to the right
        of your intended line and end up short of your target. Why? Because
        every putter twists, or rotates, when contact is made outside of the
        sweet spot (named as such because it’s the spot on the putterface that’s
        most resistant to twisting). Toe contact forces the putterhead to rotate
        open, creating a face angle that points to the right of the intended
        line; the ball goes right and rolls with less energy. Impacting the ball
        with the heel area of the putterface does the opposite; the putterhead
        twists in the direction of the impact point and closes, resulting in a
        starting direction left of the intended line.
      </div>
      <div class="p-mb-2">
        Until recently, the sweet spots of most mass-marketed putters were often
        mislabeled, because manufacturers tended to position contact marks and
        alignment aids in the horizontal center of the putterhead, not in the
        location of the club’s maximum moment of inertia (the spot that offers
        the most resistance to twisting). Now, thankfully, manufacturers go
        through great pains to ensure that any putterhead markings coincide with
        the exact location of the sweet spot, so you can rest assured that if
        you strike the ball where you think the sweet spot resides, you’ll be
        optimizing the stability of the putterface at impact. The research and
        development performed at outfits like Titleist’s Scotty Cameron Studio
        are extremely advanced, using science to provide today’s golfers with
        advantages other generations lacked.
      </div>
    </div>
  </Panel>
  <Panel header="Stroke Path" :toggleable="true" :collapsed="true">
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/physics3.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      Maintaining a neutral path four to six inches (two to three inches on each
      side of the ball) around the impact point helps you start the ball on line
      consistently and negates the need for precise placement of the ball.
    </div>
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/physics4.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      A great stroke shape runs down the target line through the impact zone and
      arcs symmetrically on the ends, with the face square to that arc at all
      times. The amount of arc may differ from player to player.
    </div>
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/physics5.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      Your body type and the amount you bend over from your hips at address
      determine how much your putter will arc throughout the stroke. If you
      stand tall, your stroke shape will arc more than if you bend over.
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Sweet spots are sweet spots, and any golfer can see the benefit of
        striking the ball with a square putterface. What’s truly up for debate
        is the ideal putterhead path and stroke shape relative to the starting
        line. Path errors aren’t as deadly as face errors, because the energy
        transfer and compression at impact with your putter (compared to the
        energy transfer and compression created when hitting a driver) is very
        low, providing some wiggle room for you to match the nuances of your
        body type, posture, and comfort level to your chosen path. The key,
        again, is to choose a particular shape, clearly define it, and then
        commit to it.
      </div>
      <div class="p-mb-2">
        Unless there’s strong disagreement, I try to get my players to swing the
        putter on a very slight, symmetrical arc—so slight, in fact, that the
        putter remains on (or nearly on) an extension of the target line with
        the face square to the line for two to three inches on either side of
        impact. An advantage to this stroke shape is that it doesn’t require you
        to position the ball perfectly in the same place in your stance on every
        putt—you’ve got two or three inches on either side of the ball where the
        putter is moving straight down the line with the face square to the
        target. At any point in this zone, contact conditions fall within an
        acceptable standard, accommodating natural human error when it comes to
        positioning the ball in your stance.
      </div>
      <div class="p-mb-2">
        What does this ideal stroke shape look like? As you can see from these
        photos, my putterhead arcs slightly away from the target line (dotted)
        the farther it gets from its starting point. Also notice how square and
        straight everything is around the impact zone. Even though my stroke
        arcs, the face remains square to the arc from start to finish.
      </div>
      <div class="p-mb-2">
        The exact amount of arc (and face rotation) you’ll create in your stroke
        depends on your putting posture. In general, the more you bend from your
        hips at address (picture Michelle Wie), the less your stroke will arc,
        leading to less putterface rotation. Because I feel like less rotation
        and arc are beneficial for consistency, you’ll notice that many of the
        players I coach on Tour bend over quite a bit from their hips at setup,
        with their weight evenly balanced over the arches of both feet.
      </div>
    </div>
  </Panel>
  <Panel header="Ball Contact Point" :toggleable="true" :collapsed="true">
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/physics6.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      Optimal launch: A slight ascending strike is one factor that helps create
      the ideal effective loft at impact, which lifts the ball slightly off the
      ground with no spin for the first four to six inches of the putt.
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        The final part of your stroke picture is the putterface contacting the
        ball in the putterhead’s center of mass, neither too high nor too low on
        the face. In order for this to happen, the putterhead should be
        ascending slightly (one to two degrees) into impact. The combination of
        these variables, along with the loft built into your putterface, will
        create optimal launch conditions, which are necessary to get the ball
        rolling on your intended line.
      </div>
      <div class="p-mb-2">
        Launch conditions? Yes, putts do launch. On perfect impact, you create
        just enough upward movement to keep the ball from spinning either
        backward or forward for the first four to six inches of its roll. The
        angle of attack and resulting effective loft of the putter lift the ball
        a smidgen into the air (and out of any depression that the ball may have
        settled into) while pushing it toward the target parallel to the green’s
        surface. Once the ball comes back into contact with the green, its
        forward momentum and lack of spin allow it to roll on your line without
        bouncing.
      </div>
      <div class="p-mb-2">
        Note: Grooves on a putter change this dynamic slightly—a ball hit with
        this type of putter will start rolling with topspin immediately after
        impact. Manufacturers who offer putters with grooved faces claim that
        this is beneficial, because a ball that immediately spins forward is
        less likely to be deflected off line by the ground. The testing I’ve
        seen on this matter has been far from scientific and at best has offered
        circumstantial evidence. Plus, it doesn’t take into account different
        grass types and grass heights. Twenty years of coaching and a lifetime
        of putting experience make me a bit skeptical of these claims, to say
        the least.
      </div>
      <div class="p-mb-2">
        Your Putting-Picture Recap Putterface aligned to your intended start
        line at both setup and impact. Putterhead path tracing a slightly
        symmetrical arc, with the putterface remaining square to the arc
        throughout the stroke. Ball struck in the sweet spot of the putter, with
        the putterhead ascending slightly into impact.
      </div>
    </div>
  </Panel>
</template>
