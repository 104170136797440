<template>
  <img
    class="img4"
    src="@/assets/images/mental/arousalEmotionGrid1.png"
    alt="Tough Minded"
  />
  <div class="accent1 fontSmall p-mb-2">
    Oversimplified for golf: consider an arousal-emotion grid
  </div>
  <img
    class="img4"
    src="@/assets/images/mental/arousalEmotionWheel.png"
    alt="Tough Minded"
  />
  <div class="accent1 fontSmall p-mb-2">
    Oversimplified for golf: consider an arousal-emotion grid
  </div>
  <img
    class="img9"
    src="@/assets/images/mental/emotionWheel1.png"
    alt="EmotionWheel1"
  />
  <div class="accent1 fontSmall p-mb-2">Gloria Wilcox Emotion Wheel</div>
  <div class="fontSmall taLeft">
    <div class="p-mb-2">
      “I’ll teach you to ride on the wind’s back, and away we go!” Peter Pan,
      who never grew up”
    </div>
    <div class="p-mb-2">
      SWING KEY: Greatness is not how you perform in good times; it is how you
      react in bad.
    </div>
    <div class="p-mb-2">
      When the alarm sounds at six-thirty, Vikki Templeton blinks the sleep from
      her eyes and swings into action. First, she gets her two boys ready for
      school, and then she joins her husband, Greg, in the barn to help him
      finish milking their 180 cows. Depending on the time of the year and the
      weather, there is silage to process, weeds to spray and tractor work to be
      done before heading off at three-twenty to retrieve the boys from school.
      In a good week, Vikki can sneak in one round of golf.
    </div>
    <div class="p-mb-2">
      Such is life on a dairy farm near Nar Nar Goon, a tiny town of five
      hundred people to the east of the Templetons’ home. About forty miles to
      the west is Melbourne, the second-largest city in Australia. When Vikki
      gazes from her back door, she sees their L-shaped farm spreading out
      below, a tranquil, green-brown vista that demands intense attention but
      provides enormous satisfaction. There are many days when Vikki performs
      her farm work with “thoughts of golf occupying her head, and those
      thoughts always bring a smile.”
    </div>
    <div class="p-mb-2">
      Vikki is accompanied on her chores by Lady, her constant companion, while
      the other farm dog, Buddy, prefers to lie around thinking about work.
      Another pet, a budgerigar bird, flies free from her cage all day, picks
      food off plates, tries to bathe in the sink while Vikki cooks dinner and
      gets really loud if she feels she’s being ignored. It is a demanding life
      in an idyllic setting. The rhythm of farm life is built around the
      satisfaction of a job well done.
    </div>
    <div class="p-mb-2">
      Australians live a lot of their lives outdoors. The country is populated
      by a sports-passionate people, and for a country of only 20 million,
      one-sixteenth the size of the United States, it has produced an
      astonishing number of great golfers, including Hall of Fame members Peter
      Thomson, David Graham, Greg Norman and Karrie Webb. Vikki, while not
      possessing those accomplishments, is world-class in her passion for life
      and for the game of golf.
    </div>
    <div class="p-mb-2">
      Vikki started playing the game when she was sixteen years old but played
      very irregularly, without a lesson or a handicap. Then, when she was in
      her late thirties, she decided that one round a week, when possible, would
      be a great break from farming. The more she played, the more she loved the
      game. She learned about VISION54 by reading golf magazines, and bought our
      first two books in September 2007. Then one of those happenstances
      occurred that make you believe life dances to an unheard tune and a
      faintly felt rhythm.
    </div>
    <div class="p-mb-2">
      Greg proposed that they go to a seminar on cows in Germany. Vikki said
      they didn’t both need to attend and offered this alternative: “If you go,
      can I pick something else for me?” she asked. And that’s how Vikki ended
      up attending our program in Phoenix. At our school, Vikki learned the
      factors that allowed her to access the joys of surrendering to her love of
      the game of golf. It was there that she learned to embrace the journey and
      there that she learned about the tools for emotional resilience that have
      not only allowed her to enjoy golf more, but also made her a better
      player.
    </div>
    <div class="p-mb-2">
      Emotional resilience is all about learning how to keep your head in the
      game, and it is an essential component to great golf. This is not as
      one-sided as it sounds, since to manage your emotions is to understand the
      impact that emotions have on both the physical and mental aspects of you,
      and to recognize how that influences your performance. Emotional
      resilience is also about not letting unpleasant experiences in the past
      affect present performance. That resilience involves learning how to build
      a reserve of positive emotions by focusing on the good you have
      experienced, and learning how to use the good you have experienced to make
      yourself better.
    </div>
    <div class="p-mb-2">
      Emotions release hormones that affect the access you have to your higher
      brain functions. Simply put: Anger makes you stupid, while joy allows you
      to access a peak-performance state.You can learn to create a reserve of
      energy that lets you stay focused and coordinated for the entire round of
      golf. Bad bounces and bad weather are beyond your control, but you can
      learn to manage your emotional state when these things happen.
    </div>
    <div class="p-mb-2">
      Soon after Vikki went through our program, we got an e-mail from her
      saying she had won her first scratch tournament ever. She plays off an 18
      handicap and has a career-best 82 gross on a par-72 course. That’s pretty
      remarkable, really, when you consider she had played regularly for only
      about four years.
    </div>
    <div class="p-mb-2">
      What changed the most for Vikki, what deepened, was her understanding of
      the beauty of the game and her realization that a large part of the allure
      of golf is the magical way in which it mirrors life. She deepened her
      relationship with golf, and this gave her greater control over her
      emotional state both on and off the golf course. Simply put, a happy
      person plays better golf. The greater attachment Vikki felt to the game,
      the higher her level of performance.
    </div>
    <div class="p-mb-2">
      My VISION54 is represented in the song ‘Piece of Sky’ by Barbra Streisand:
      ‘The more I live, the more I realize the less I know,’” says Vikki. “You
      never stop learning, and you can learn from any situation, good and bad. I
      think if you stop learning, you stop living. I love it.” By getting in
      “touch with that joy, by releasing that passion, Vikki’s performance on
      the golf course improved. What she learned from us was that there are
      specific, intentional ways you can create that euphoric state—and ways to
      avoid the destructive downward spirals.”
    </div>
    <div class="p-mb-2">
      It is essential to manage your emotions to play your best golf. This is
      one of those nonnegotiable rules of peak-performance golf. Just imagine
      one of those endless lines of dominoes when someone is trying to break the
      world record for longest lines. When the first domino is pushed, they all
      start falling, taking each other down one by one. That’s the way your
      emotions interact with your physical and mental functioning. But you have
      the power to control whether the chain reaction is positive or negative.
    </div>
    <div class="p-mb-2">
      Positive emotions release DHEA, which is a hormone that functions like a
      lubricant to the brain, allowing you to slip into full access of higher
      brain function. It is a state in which you make clearer, more informed and
      more committed decisions. You also have full access to your visual
      centers, fine motor skills and the executive center of the brain. That’s
      the happy place in which you can achieve great things. This is where great
      golf shots live. You know that feeling when you step up to a golf shot and
      you are certain you are going to hit it well? That’s this state—and it
      doesn’t have to be elusive.
    </div>
    <div class="p-mb-2">
      DHEA doesn’t guarantee greatness, but it does allow you to have full
      access to your ability and makes it possible to sustain being great. DHEA
      is a performance-enhancing drug and it’s free and legal inside of you. All
      you have to do to trigger its release is to genuinely feel a positive
      emotional state.
    </div>
    <div class="p-mb-2">
      Negative emotions release cortisol, which is a hormone that blocks full
      access to higher brain functions. Cortisol levels that are too high
      inhibit the functioning of the thalamus, a part of the brain. As a result,
      you only access the reptilian and mammalian parts of your brain. You think
      like a dog, and you play like a dog—except you probably don’t have as much
      fun as a dog. You think poorly, behave poorly and perform poorly. This is
      a disruptive state that can last six to ten hours—long enough to sabotage
      your golf game and a good part of the rest of your day.
    </div>
    <div class="p-mb-2">
      It doesn’t really matter if your personality is introverted or
      extroverted; these hormones are still being released and affecting the
      access to your higher brain functions. We all experience joy and we all
      get angry, and we show that joy and anger in different, highly individual
      ways. What does matter is that you understand and honor who you are, and
      that you learn an ability to “go with the flow” and shift your state when
      necessary.
    </div>
    <div class="p-mb-2">
      Do you play your best with higher or lower adrenaline levels? Are you more
      like Tiger Woods, Suzann Pettersen, Sergio Garcia and Christina Kim? They
      need a lot of adrenaline to perform at their best. Or are you more like
      Annika Sorenstam, Ernie Els, Catriona Matthew and Retief Goosen, who all
      play better with less adrenaline? If you are not clear where you belong,
      go back to a time when you played well and analyze it. How were you
      then—amped up or mellow? You can also experiment playing with higher and
      lower adrenaline levels and record what happens.
    </div>
    <div class="p-mb-2">
      We know some players who say they perform better when they are angry,
      since they finally get enough adrenaline flowing. But we already said that
      anger makes us stupid. So how can you get to that state of high adrenaline
      without the negative side effect of cortisol? Well, remember Sergio Garcia
      as a rookie playing in front of Tiger Woods at the 1999 PGA Championship,
      galloping out onto the fairway with a big smile on his face after hitting
      that ball from behind a tree? That is happy adrenaline. We have tools that
      will allow you to manage your adrenaline levels without triggering the
      release of the bad chemicals.
    </div>
    <div class="p-mb-2">
      Often, when things are going badly on the golf course, you become a
      spectator to your own demise. How silly is that? It’s your life, your
      golf—take control. Adrenaline levels can be changed in a few seconds
      through breathing exercises and other physical tools. For example, take
      long, deep cleansing breaths—breathing in to the count of three and
      breathing out to the count of six. This will immediately lower your
      adrenaline level and calm your nervous system.
    </div>
    <div class="p-mb-2">
      If your play has become lethargic and you need a boost, a more rapid
      breathing pattern will increase your adrenaline level. You can also raise
      your adrenaline level by doing jumping jacks or running sprints. We know
      players who will do this as part of their pre-round routine in order to
      get their adrenaline level up. Trust us, there will be people who think
      you have gone off your rocker. But also trust us on this: It works.
    </div>
    <div class="p-mb-2">
      Ideally, you should come off the golf course with more energy and feeling
      better about yourself than when you started, no matter the result of your
      play. How often can you say that happens for you? Part of your pre-round
      routine should be to take an emotional inventory of yourself and evaluate
      your emotional state. Do you need to take it up a notch today or bring it
      down? This will vary from day to day, no matter who you are. With
      practice, you will learn to be aware of the fluctuations in your emotional
      state during a round and develop strategies to manage it.
    </div>
    <div class="p-mb-2">
      We have said that if your cortisol is too high, it will affect you for
      many hours. Remember that it’s the same for DHEA. Cortisol and DHEA come
      from the same base molecules. So if you have a lot of cortisol, you will
      have less DHEA and vice versa. If you start the day or the round with a
      lot of DHEA, that is long-lasting as well, and that is a good thing.
    </div>
    <div class="p-mb-2">
      That is what we call emotional resilience. It doesn’t mean you don’t hit
      bad shots, but it does help you manage the situation and have access to
      the whole brain when you do have disappointing outcomes. Resilience can be
      seen as the ability to sustain competence under stress and in challenging
      conditions. This hardiness allows you to stay in the game. It’s the
      ingredient of mental toughness that allows you to be unflappable and
      maintain the ability to stay calm and focused while others are
      experiencing chaos.
    </div>
    <div class="p-mb-2">
      A great way to keep track of your emotional state is to draw a cross on
      the scorecard or in the yardage book. The vertical axis is your
      adrenaline, with high adrenaline at the top and low at the bottom. The
      horizontal axis represents your emotions. The left side is DHEA (the good
      stuff) and the right side is cortisol (the bad stuff). Put an X where you
      play your best golf. Are you happy and contented when the X is just under
      the midline on the adrenaline axis? Or are you joyous playing when you are
      higher on the adrenaline axis? Only you know.
    </div>
    <div class="p-mb-2">
      After each hole, write down where you are on this grid. Put a 1 on the
      grid after the first hole, a 2 after number two, etc. This will give you
      an awareness of where you go emotionally during a round. Let’s say you
      play your best golf when your emotions are in the upper left quadrant.
      After five holes, you notice that you are down to the right, almost
      feeling like you don’t care what’s happening. What are you going to do? Do
      you need to get more adrenaline in you? The point is that all of us will,
      at times, slip into the cortisol side of the grid. How quickly can you
      become aware of this slide and do something to shift your emotional state
      before your cortisol levels get too high and you are cooked for the round?
    </div>
    <div class="p-mb-2">
      When you listen to Vikki talk about the revelations she has had about golf
      through our school, you can’t help but wonder if her experiences as a
      farmer have aided her insights. By watching the seasons change and being
      attuned to the cycle of life,Vikki was well aware that there are forces of
      nature beyond our control to which we must surrender. You can plant the
      seeds, but you can’t make it rain.
    </div>
    <div class="p-mb-2">
      Our energy must be focused on those aspects of life we can influence
      through careful preparation, concentrated understanding and total
      commitment. That’s a key concept to emotional resilience: Work on what you
      can control and don’t worry about what you can’t control. This is a bit of
      emotional triage central to VISION54. Don’t borrow trouble by worrying
      about that which you can’t control. There are enough controllable things
      that need our attention.
    </div>
    <div class="p-mb-2">
      What I learned from Every Shot Must Have a Purpose is that golf is a game
      that is a continually changing journey,” says Vikki. “Golf changes shot to
      shot, day to day, course to course, and to enjoy the ride to its full
      potential, you must understand all its elements and totally commit to its
      actions. Golf “evolves around your ability not only to understand the
      technical side of the game, but also to grasp the other five elements of
      the game that affect you,” says Vikki. “To understand the way you interact
      with all the elements—physical, technical, emotional, mental, social and
      spiritual—affects the way you hit that little white ball.”
    </div>
    <div class="p-mb-2">
      Certainly, a high-functioning individual is someone who has integrated all
      aspects of human existence—the physical, technical, mental, emotional,
      social and spiritual. Your state of being affects your physiology, and
      therefore it affects your performance. This is true in all areas of human
      activity, not just golf. By controlling your emotional state, by managing
      your emotional resilience, you increase the possibility of the total
      integration that leads to peak-performance play. You have greatly
      increased your chances of success.
    </div>
    <div class="p-mb-2">
      Your emotions affect how you think, how you see, how you reason and how
      you perceive the world around you. They also affect your motor skills. We
      helped Vikki with the tempo of her golf swing, but it was what she learned
      about her relationship with the game that really enabled her to improve.
      Great players—without exception—are in love with the game. They are also
      not afraid of failing or looking silly. When they are not playing well,
      they are able to tap into their passion for the game and salvage a decent
      round by managing their emotions. They rarely give up on a round of golf.
    </div>
    <div class="p-mb-2">
      The actions we must commit to also interact with our performance,” says
      Vikki. “These actions—strategy-making, decision-making, commitment to the
      decision, focus, execution and reaction to the shot—are all interconnected
      with the elements. It is your ability to pull these elements and actions
      together that will determine your potential to get the ball in the hole in
      the fewest shots. And when we practice, we must look at all the elements,
      not just the technical, so that when we hit the course, we can fully
      commit to the ‘trust element,’ so that we can reach our own VISION54
      goals.
    </div>
    <div class="p-mb-2">
      That last statement is crucial. While our approach evolved as a challenge
      to players to make not just par on every hole, but rather birdie—and thus
      shoot a 54 on a par-72 course—it is really a metaphor for individual
      peak-performance goals. Everyone has his or her own VISION54 objectives.
      What are yours? Is it to break 100, 90, 80, 70, 60? What is essential is
      not where you are going but how you are going to get there. That’s what we
      provide—not a single direction, but several roads. Staying engaged with
      and committed to the process will create greater emotional resilience for
      you.
    </div>
    <div class="p-mb-2">
      The main thing I learned from VISION54 it that it is not only about golf,
      but also about life,” says Vikki. “Life is often mirrored in your golf.
      The lessons learned in VISION54 are so transferable to your everyday life.
      One thing I have learned is to only concentrate on what I can control and
      not dwell on the uncontrollable. When I look out my back door, I see our
      farm spreading out below and the work that I need to do for the day. Scary
      some days—until you focus on the controllable and forget the
      uncontrollable.
    </div>
    <div class="p-mb-2">
      Vikki is clearly a woman of considerable passion, and because of that, she
      was extremely receptive when she attended our school. Her heart was open
      to the game of golf. That’s a huge advantage to have. An important step
      toward developing emotional resilience and gaining control of your
      emotions on the golf course is allowing yourself to feel the game at your
      very core—in the heart of your being.
    </div>
    <div class="p-mb-2">
      That was easier for Vikki than for many because of who she is. But it is a
      state that anyone can reach. For some, managing their emotional resilience
      might be the most challenging—and most important—of the Essentials. For
      everyone, it is the doorway to achieving peak performance in the Play Box.
    </div>
    <div class="p-mb-2">
      We are not saying that you will never get angry, sad or frustrated. Of
      course you will, you are a human being. But we are saying that you can
      learn to recognize what is happening earlier in the process and stop the
      emotional chain reaction before the hormones that shut down access to your
      higher brain function take over. Emotional resilience is the positive
      capacity of people to cope with stress, like putting that drive that went
      out of bounds behind you.
    </div>
    <div class="p-mb-2">
      Emotional resilience also has a cumulative effect on your performance.
      “Positive emotions cause DHEA and other neuropeptides to be released into
      the body. This neurobiological base makes you more adaptive to disruptions
      and more resistant to getting off track with your game. You’re able to
      stay focused and remain aware of options, and maintain access to good
      feelings even when you feel stressed. You literally have more chemical
      resistance when others are succumbing to the pressure. It’s not just a
      good idea; it’s a neurobiological state that you can create that directly
      affects your ability to perform. This emotional sturdiness allows you to
      keep your head in the game. It’s the neurobiology of how the tough get
      going when the going gets tough.”
    </div>
    <div class="p-mb-2">
      Just understanding the physical process of all this has been beneficial to
      many players with whom we have worked. Lots of avid golfers who have been
      at the game a long time without getting the results they want slip into a
      high-cortisol and low-adrenaline state. They become apathetic, find it
      boring to practice and even get a little depressed. Learning about
      emotional resilience becomes critical to rekindling the spirit of their
      game. This low-performance state can easily happen to those players who
      equate their score to who they are as a person. They can’t separate who
      they are from what they do. If they play well, they are happy. If they
      play poorly, they are miserable. This way of interpreting the game is not
      sustainable. If you are not careful, you might think that you are your
      score. Actively creating more DHEA is essential to staying in a good state
      for golf and for life.
    </div>
  </div>
</template>
