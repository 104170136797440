<template>
  <Panel header="The Basics" :toggleable="true" :collapsed="true">
    <Panel header="Introduction" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          You bought this book because you want to make more putts. I can help
          you do that, but before we get to work on your putting stroke, we need
          to make sure your setup fundamentals look good. If you can put
          yourself in good position before you make your stroke, and keep that
          good setup position consistent over time, you're going to have a great
          chance to roll the ball well.
        </div>
        <div class="p-mb-2">
          Guys that do roll the ball really well—like Brad Faxon, Tiger Woods or
          Dave Stockton—get complimented all the time about how smooth their
          strokes look. And it is true that they have a nice flow. But I know
          from years of work on my own game and hundreds of hours of watching
          players with all kinds of strokes on the practice green that the
          “flow” in a beautiful putting stroke starts with seemingly mundane
          details like grip, stance and alignment.
        </div>
        <div class="p-mb-2">
          The players who get those details right—grip, stance and
          alignment—almost can't help but hit nice putts. The hands are neutral
          and set up to work with each other, not against each other. The eyes
          are in great position to see the line accurately. The grip lets the
          putterhead rotate back and release naturally through impact. In other
          words, it looks comfortable and easy. It looks confident. You've got a
          lot of the work that goes into a solid putt done before you even make
          the stroke.
        </div>
        <div class="p-mb-2">
          “Let's go over the different elements of a good setup step by step.
          We'll start with the grip and work our way through stance and
          alignment. After that, you'll be ready to learn the stroke and make it
          consistently.
        </div>
      </div>
    </Panel>
    <Panel header="Grip" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">yyy</div>
        <div class="p-mb-2">yyy</div>
      </div>
      <img class="img4" src="@/assets/images/utleyPutting/grip1.png" />
      <div class="accent1 fontSmall p-mb-2">
        (LEFT) When I put my right hand in place, my thumb runs right down the
        top of the shaft, and my palm is facing the target. That's a nice,
        neutral position. (RIGHT) The grip runs under the muscle at the base of
        my thumb and along the lifeline in my palm. My fingertips hold the grip
        in place, not my whole fingers. That's a key component of feel.
      </div>
      <img class="img4" src="@/assets/images/utleyPutting/grip2.png" />
      <div class="accent1 fontSmall p-mb-2">
        (LEFT) When I'm forming my grip, I put my right hand on first, then open
        my fingers just enough to slide my left hand into place. “(RIGHT) When
        my grip is completed, both thumbs run parallel to each other, and my
        hands are neutral and directly opposite each other.
      </div>
      <img class="img4" src="@/assets/images/utleyPutting/grip3.png" />
      <div class="accent1 fontSmall p-mb-2">
        By setting my right-hand grip along the lifeline in my palm and not in
        my fingers, the putter shaft runs in a line with my forearm.
      </div>
      <img class="img4" src="@/assets/images/utleyPutting/grip4.png" />
      <div class="accent1 fontSmall p-mb-2">
        When I let the grip drift into my fingers, notice how the shaft hangs
        below the line of my forearm.
      </div>
      <img class="img4" src="@/assets/images/utleyPutting/grip5.png" />
      <div class="accent1 fontSmall p-mb-2">
        (LEFT) See the space between my hand and the grip of the putter? I'm not
        wrapping my fingers around the grip. The pressure points are my
        lifelines and my fingertips. “(CENTER) From this angle, you can see the
        reverse overlap—my left index finger is running along the outside of the
        knuckles of my right hand. In a regular overlapping grip, for a full
        shot, my right little finger would be overlapping.
      </div>
    </Panel>
    <Panel header="Stance and Alignment" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">yyy</div>
        <div class="p-mb-2">yyy</div>
        <img class="img4" src="@/assets/images/utleyPutting/stance1.png" />
        <div class="accent1 fontSmall p-mb-2">
          (LEFT) The tops of my forearms are aligned with each other and square
          to the target line. Get this alignment wrong—one forearm higher than
          the other—and you'll struggle to consistently hit it where you aim it.
          “(RIGHT) You can see the change in alignment here. My right forearm is
          set up higher than my left, which will cause me to miss putts to the
          left.
        </div>
        <img class="img4" src="@/assets/images/utleyPutting/stance2.png" />
        <div class="accent1 fontSmall p-mb-2">
          (LEFT) To determine where your eye line sits in relation to the target
          line, get into your posture and hold a ball next to your left eye.
          Drop it and watch where it lands. “(RIGHT) The ball should land just
          inside the target line and an inch or two to the right of the ball
          you'd actually hit with your stroke.
        </div>
        <img class="img4" src="@/assets/images/utleyPutting/stance3.png" />
        <div class="accent1 fontSmall p-mb-2">
          (LEFT) In my putting setup, my feet are as far apart as they would be
          if you and I were standing here talking. My elbows are soft and close
          to my sides, and the shaft of my putter is leaning slightly toward the
          target. (CENTER) In this good setup postion, I'm tilted at the waist,
          but my spine is pretty straight and my chin is up from my
          chest.(RIGHT) If you let your weight get over your toes, your tendency
          will be to slump your spine and let it curve down toward the ball.
          Aside from being uncomfortable, this position prevents you from making
          a free-flowing putting stroke.
        </div>
        <img class="img4" src="@/assets/images/utleyPutting/stance4.png" />
        <div class="accent1 fontSmall p-mb-2">
          (LEFT) Here, my elbows are sticking out, away from my sides, instead
          of resting in a relaxed position next to my sides. (CENTER) Here, my
          hands are set too low, which forces the putter to go back too far to
          the outside.(RIGHT) My hands are set too high, It's hard to make a
          natural stroke from this position because the wrists have to get too
          involved to compensate.
        </div>
      </div>
    </Panel>
    <Panel header="Alternative Styles" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">yyy</div>
        <div class="p-mb-2">yyy</div>
      </div>
      <img class="img4" src="@/assets/images/utleyPutting/styles1.png" />
      <div class="accent1 fontSmall p-mb-2">
        (ABOVE) The principles I teach are the same even when the hands are
        reversed on the putter. I'm still maintaining the grip up the lifelines,
        and the hands are neutral.
      </div>
      <img class="img4" src="@/assets/images/utleyPutting/styles2.png" />
      <div class="accent1 fontSmall p-mb-2">
        (RIGHT) My setup with a cross-handed grip is basically identical to what
        I would do with a standard grip. The cross-handed grip keeps your left
        wrist flat to the target through the stroke.
      </div>
      <img class="img4" src="@/assets/images/utleyPutting/styles3.png" />
      <div class="accent1 fontSmall p-mb-2">
        The left-hand grip is the same as the conventional grip I teach, but the
        right-hand grip is pivoted so that the hand runs parallel to the target
        line. The biggest benefit of the claw is that it completely removes the
        hinge in your right wrist.
      </div>
    </Panel>
  </Panel>
</template>
