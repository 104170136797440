<template>
  <Panel
    header="Advanced Techniques: Green Reading etc."
    :toggleable="true"
    :collapsed="true"
  >
    <Panel header="Introduction" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          Your stance and setup have improved, and you've got your grip running
          up the lifelines instead of down in the fingers. Your stroke is
          looking great, and the ball is rolling nicely. So what happens now?
          How do you take that from the practice green out to the course?
        </div>
        <div class="p-mb-2">
          It starts with the stroke you're making. I really believe that whether
          you know it or not, you've got enough natural talent that a putting
          factor like speed control will take care of itself if you can learn to
          hit your putts solid. Yes, there are other factors—like break, grain
          and green conditions—that go into making a good stroke, and those are
          the things we'll be talking about in this chapter. But the first thing
          you have to remember is that making a good stroke and hitting a solid
          putt are the most important things you can do. Do that and it's funny
          how many times the ball seems to run into the hole for you.
        </div>
      </div>
    </Panel>
    <Panel header="Green Reading" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          Let's talk about reading greens. Ideally, you want to use that great
          putting stroke you've been working on to send the ball in the right
          direction. Jack Nicklaus picks a spot in front of his ball and aims
          for that spot, on both his full shots and putts. I use more of a path
          technique—I pick out two or three or four objects that my ball may
          roll over along the way. The most important part is to be able to see
          the break, and see the line you want the ball to start on. I'm
          teaching people a stroke that they can hopefully repeat no matter what
          kind of putt they have. So the goal is to figure out what the ball is
          going to do after it leaves the putter, so that we can make the ball
          leave on the right line to curve into the hole. I call it the high
          point of the break. You're trying to hit your putts so that they start
          on a line that will get the ball to roll solidly to the high point of
          the break. The ground takes care of the rest of it.
        </div>
        <div class="p-mb-2">
          As a tour player, my green-reading preparation starts days—and
          sometimes years—before the actual tournament round I'll be playing in.
          I have a yardage book for every tournament I play in, and the book
          comes with straightforward measurements like the distances from
          certain landmarks to the center of the green, and topographical
          information about each green.
        </div>
        <div class="p-mb-2">
          For example, I can look at this chart of the fifteenth hole at Fox
          Den, where I played the Knoxville event on the Nationwide Tour, and
          tell you exactly what I'm supposed to do. The notation on the bottom
          says that the ball really runs on this fairway, so driver is too much.
          There's no reason to risk hitting it through the dogleg into the trees
          on the left. The “OK” designations short right of the green tell me
          where I can miss the green and have the best chance of getting up and
          down—usually the place where there's flat terrain and short grass, or
          maybe a big bunker without much of a lip.
        </div>
        <div class="p-mb-2">
          I spend a lot of my time carefully drawing arrows on the green to show
          the fall lines—the ridges built into the surface of the green that
          have the most influence on how putts will break. Here, you can see a
          long, gentle ridge in the front-right quadrant of the green, a sharper
          hump in the back-left section and another hump in the back right. The
          dots show the different places where pins have been set on the hole.
          For example, if the pin is set front right, I know I can miss a little
          to the left of the pin and the ball will funnel toward the hole. And
          to get to that back-left pin, I know I've got to carry it up onto the
          tier, or else the ball will kick off the ridge and leave me with a
          tough putt.
        </div>
        <div class="p-mb-2">
          The yardage book is the first piece of the greens-reading puzzle.
          Next, I play practice rounds and carefully note what my shots do when
          they hit each green. Do they feed left or right? Do they tend to check
          up, or run toward the back of the green? I also pay close attention to
          the topography of the area around the green as I walk up to it after
          hitting my approach. Is the area flat, or is there a mountain or
          valley to one side of the green or the other?
        </div>
        <img class="img4" src="@/assets/images/utleyPutting/advanced1.png" />
        <div class="accent1 fontSmall p-mb-2">
          I draw the green contours in the yardage guides I use for each
          tournament I play in. If I know the general characteristics of the
          green, it helps me when I'm reading particular putts on that green.
        </div>
        <img class="img4" src="@/assets/images/utleyPutting/advanced2.png" />

        <div class="accent1 fontSmall p-mb-2">
          Your first read on a putt comes as you're approaching your ball for
          the first time. You're trying to get an overall sense of the
          topography of the green.
        </div>
        <div class="p-mb-2">
          Walking up, I can usually get a general idea of what the green's
          tendencies are. That way, if I'm torn between two reads later on, I
          can use that piece of general information about the green to break the
          tie in my mind. Then I'll hit some practice putts from different spots
          around the green, taking note not so much of the specific amount of
          break, but of the general tendencies. What do putts from the
          front-left corner to the middle tend to do? Are they fast or slow?
          Does a putt up into the back left break more than I expect, or does it
          break less than it looks? All of these notes go into the yardage book,
          which I bring with me year after year. I also draw onto the pages what
          the tournament-round putts I've faced have done. After three or four
          years, I've got a pretty good scouting report on just about any pin
          location they can throw at me.
        </div>
        <div class="p-mb-2">
          You might be thinking to yourself, that's fine for a tour player, but
          how does that help me? If you play at a club, you're in a great
          position to get detailed information about every green at your course.
          In fact, if you go out and play without it, it's like leaving a club
          out of your bag. It's as easy as going out with a little notebook for
          a couple of practice rounds and recording the general tendencies of
          the greens. It's amazing what this one step, actually going out and
          taking notes on your greens, adds to your body of knowledge. You might
          have a general idea that the green on number four is fast and breaks
          hard right, but do you really know what a putt from four or five
          different spots on that green to a front-right pin really does? Go
          practice them, and you'll never be shocked by what your putt does
          during a round that really counts.
        </div>
        <div class="p-mb-2">
          That kind of information is great to have, but what happens when
          you're playing a course for the first time? You obviously “want to
          walk toward each green after your approach shot with your head up and
          your eyes open. You're looking for the overall tilt of the green,
          which influences the way most putts will break. Once you're actually
          looking over your putt, there's a straightforward, quick method to
          deciphering the break. I start by looking at my putt from behind the
          ball, and then I walk behind the hole and look at it from the opposite
          direction. When I'm returning to my ball, I make sure to walk to the
          low side of the hole—the opposite side from where the ball will
          break—and look at the putt from halfway between the ball and the hole.
          For example, if I'm looking at a twenty-footer with a foot of break
          from left to right, I want to look at the break from the right side of
          the ball, halfway between the ball and the hole. From that position,
          you get the best perspective on the amount of break the putt will
          have. This is something I do when I play, and most tour players do it
          out of instinct. I'm emphasizing it very purposefully because Dave
          Stockton, one of the best putters of all time, told me it's one of the
          most important things he teaches in his clinics.
        </div>
        <img class="img4" src="@/assets/images/utleyPutting/advanced3.png" />
        <div class="accent1 fontSmall p-mb-2">
          Next, I move behind the hole, on a line with my ball, and look at the
          putt from that direction. You often can get a better sense of the
          break from that side.
        </div>
        <img class="img4" src="@/assets/images/utleyPutting/advanced4.png" />
        <div class="accent1 fontSmall p-mb-2">
          On the way back to my ball, I read the putt from halfway between my
          ball and the hole, on the low side of the break—the opposite side from
          where the ball will break. This gives me a feel for just how much
          break to play.
        </div>
        <div class="p-mb-2">
          Once you look at your putt from that angle, you'll probably wonder if
          you're really seeing as much break as you think you are. You are. Most
          amateur players dramatically underestimate the amount of break in the
          average putt. Add in the fact that many players fixate on the hole and
          not the high point—where they need to aim the face to account for the
          amount of break in the putt—and it's easy to see why most putts miss
          on the low side.
        </div>
        <div class="p-mb-2">
          The read itself is one part of the equation, but the type of putt
          itself is another important thing to consider. When you take into
          account the topography of the green, keep in mind that a tilt in the
          green early in the putt is going to have less impact on the roll of
          your ball than a tilt toward the end, near the hole. In other words,
          as your ball slows down near the hole, it will take more break than it
          did when it was coming right off the putterhead. In practical terms,
          you're doing a general read on the overall tilt of the green for the
          first ten or fifteen feet of a twenty-footer, then a more detailed,
          specific read for the last five feet. The same principles hold true
          when you have a downhill putt. You have to make a smaller, slower
          stroke to account for the downhill slope, and when your ball is
          rolling more slowly, it will tend to take even the most subtle break.
          You can read less break into an uphill putt, because you're making an
          aggressive stroke to account for the slope. That's why players at
          every level, from the PGA Tour to the B-flight at the club
          championship, hate sidehill-downhill putts. They have a lot of
          side-to-side break, and they have to be hit gently to account for the
          downhill slope. Get too tentative with them and you leave yourself
          another downhiller to finish up. Get too bold and you're ten or
          fifteen feet past the hole.
        </div>
        <div class="p-mb-2">
          It's really a golf cliché by now, but you are better off leaving
          yourself below the hole—where you have an uphill putt left—on an
          approach shot, pitch or chip. You'll be less tentative with your
          stroke, because you're less worried about knocking it by the hole and
          leaving yourself a tester coming back. Because the ball comes off the
          putter faster in the beginning of an uphill putt, it will break less
          overall, which lets you play the putt straighter. The idea of leaving
          yourself below the hole is a good one for lag putting as well. On a
          long putt, say one of fifty or sixty feet, your goal is to leave
          yourself within five feet of the hole for the next putt. It's
          obviously better to favor the side of the hole that leaves you
          something relatively straight and uphill, if possible. That's
          something I think about automatically when I go through my read and
          pre-shot routine for a long putt. It's like playing pool. You want to
          know where your best leave is—the best spot to hit the next shot.
        </div>
        <div class="p-mb-2">
          Another small but significant factor in green reading is grain—and
          that's not just for those of you who play on Bermuda greens. It's been
          my experience that almost every grass grows in a certain direction.
          Sure, Bermuda greens have a more pronounced grain that will make your
          ball move more than closely mown bent grass. But when you're playing
          on bent and you can see the shine on the greens, that means you're
          putting with the grain, while a shaded color means you're playing into
          it. If the greens are already quick, that difference in the direction
          of the grain is going to make a difference in how hard you have to hit
          the putt. That's great information to know, and your brain needs to
          process it at least on a subconscious level.
        </div>
        <div class="p-mb-2">
          One other thing to keep in mind when you make your reads is the
          moisture on the greens. After a night of rain, the greens are
          obviously going to run a little bit slower. If the sun is out, you can
          expect those same wet greens to dry out over the course of the day—and
          dry out even quicker if the wind is blowing. That can make a
          significant difference in green speed from the time you play the first
          hole to the time you make the turn. Unless you're in the desert,
          there's also morning dew to consider. Until that dew burns off, the
          greens will run slower. If you're in a period of wet weather, the
          grass at the course will tend to grow more quickly—and the maintenance
          people won't have as much chance to get out and cut the grass. Longer
          grass on the green usually out and cut the grass. Longer grass on the
          green usually translates into slower speeds. The opposite is true as
          well. In a dry summer (or a standard summer here in Scottsdale, where
          I live), greens tend to play firm and fast. Those kinds of greens
          actually make a different kind of sound when your approach shot lands
          on them—a hollow clunk instead of a wetter thwack.
        </div>
        <div class="p-mb-2">
          If you're feeling good about your stroke and you start to make some
          good reads using the strategies I've been talking about, it's amazing
          how your speed starts to take care of itself. Your brain can sense
          factors like uphill and downhill, and when you start reading enough
          break into your putts, your brain won't be subconsciously trying to
          yank the putter onto the path that will get the ball started on the
          right line. That confidence just starts to grow and grow. One of the
          questions I get over and over again is, “How do I know how hard to hit
          it?” My answer is that you already know how hard. You just have to put
          yourself in position to use that information.
        </div>
      </div>
    </Panel>
    <Panel
      header="Alignment aids: Eye Dominance, Ball Line"
      :toggleable="true"
      :collapsed="true"
    >
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          So you feel good about the read. Now what? I have a regular physical
          and mental routine that I follow every time I hit a putt, and it helps
          me consistently translate my read into where I actually hit the putt.
          It starts with eye dominance. Everyone has one eye that is dominant
          over the other, and the dominant eye leads in terms of focusing on a
          target. You can figure out which of your eyes is dominant by looking
          up at a target that's pretty far away, like a light or a street sign,
          and pointing at it without thinking too much about what you're doing.
          Without moving your finger, try closing your left eye. If your finger
          is pointing directly at the target, you're right-eye dominant. If your
          finger is pointing off to the side of the target, you're left-eye
          dominant.
        </div>
        <div class="p-mb-2">
          Why is eye dominance important to know? Because, as you can see from
          that simple eye test I just described, if you rely on your
          non-dominant eye to line you up with your target, you could be a few
          inches—or even a foot—off as to where the face of your putter is
          actually aimed. That means you'll have to do something in your stroke,
          either consciously or unconsciously, to get the ball back on line. I
          know I'm right-eye dominant, so I set the putter down with my right
          hand first, and let my right hand do the aligning of the putter toward
          my intended line. I've already made my calculations for the break and
          speed, so at this point, just before I pull the trigger, I'm going
          into the visualization and swing-thought processes I described in the
          last chapter.
        </div>
        <img class="img4" src="@/assets/images/utleyPutting/advanced5.png" />
        <div class="accent1 fontSmall p-mb-2">
          (ABOVE LEFT) I use a marker to make a straight line on my ball, along
          the model name on the side. Then, when I line up my putt, I place this
          straight line on the ball directly on the line I want my ball to
          travel. That way, I have another alignment aid, and I can watch the
          line on the ball as the putt rolls to see if I made a good stroke. If
          I can still see the line all the way to the hole, the stroke was
          pretty pure. If the line disappears, that means I've come across the
          ball.
        </div>
        <div class="p-mb-2">
          My goal is always to roll the ball as well as I can. Making the putt
          is nice, obviously, but watching my ball roll nicely is the primary
          feedback I'm looking for when I hit a putt. A great way to get an
          instant read on that is to draw a line on the ball. I use a
          Sharpie-type marker to make a straight line along the model name on
          the side of the ball. Then, I take a lot of care to align that
          straight line with the line I want my putt to start on—not on a line
          with the hole. When I stroke my putt, I want that line to appear as a
          continuous stripe on the ball as it rolls toward the hole. If the line
          wobbles, it means I've cut across the ball through impact.
        </div>
        <img class="img4" src="@/assets/images/utleyPutting/advanced6.png" />
        <div class="accent1 fontSmall p-mb-2">
          (ABOVE) Once I've drawn a line on the ball, I place the ball in front
          of my marker, aligned with the line I want my putt to start on—not in
          line with the hole.
        </div>
        <img class="img4" src="@/assets/images/utleyPutting/advanced7.png" />
        <div class="accent1 fontSmall p-mb-2">
          (LEFT) I always draw the same number that's printed on my ball right
          next to the pre-printed figure. I want to be able to identify it
          easily, even in deep rough.
        </div>
      </div>
    </Panel>
    <Panel
      header="'Aggressive/Confident' Putting"
      :toggleable="true"
      :collapsed="true"
    >
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          Another question I hear quite a lot from average players I see is
          about how “aggressive” I am when I putt, or how aggressive the average
          person should be on regular country-club greens. Again, that depends
          on what the definition of “aggressive” is.
        </div>
        <div class="p-mb-2">
          Aggressive can mean that if you're standing on the green with a putter
          in your hand, you're trying to make the putt—no matter if it's ten
          feet straight up the hill or forty-five feet down the slope with two
          breaks. Aggressive can also mean you try to iron out any break in
          shorter putts by hitting it hard, right at the hole. I'd call my own
          putting aggressive from thirty feet out to about ten feet out, where I
          feel like my ability to roll the ball well is going to have positive
          results. In other words, I'm not trying to simply lag it up there
          close. From about ten feet and in, I feel like I should make almost
          every putt. Does that mean I do, or that I feel confident over every
          one? Absolutely not. When you add in tournament pressure, nerves, fast
          greens and the funky stroke here and there, you're going to miss a
          few. But the fact that I feel good about my stroke—and the fact that a
          good stroke breeds putting confidence—helps put me in a frame of mind
          where I expect good things to happen when I'm standing over a
          ten-footer. I feel good about my read and stroke, so I don't usually
          try to take the break out of a putt by hitting it harder, unless I'm
          really close, like inside four feet. Unless you're feeling really
          confident about your own stroke—and you're ready for a three-footer if
          you miss—I don't recommend that strategy in your own game, either.
        </div>
        <div class="p-mb-2">
          What does my level of aggressiveness or confidence in putting mean for
          you? Well, “success” in putting is a relative term. You want to assess
          where your own putting is, and turn the dial to more successful
          results in terms of your own game. For you, that could mean getting a
          better handle on your long putting and reducing three-putts. It could
          mean standing over an important fifteen-footer for par and feeling
          good about your stroke, not absolute dread about what's going to
          happen next. Maybe you have a very tangible scoring goal—like breaking
          ninety or eighty for the first time. Some changes to your fundamentals
          and a small amount of practice—just an hour or two on the practice
          green each week—can get you there.
        </div>
        <div class="p-mb-2">
          You can compare your success to what a tour player can do with a
          putter, but it's important to keep a few things in mind. First of all,
          when you watch a tour event on television, it's easy to get the idea
          that a tour player is a lock to make any six-footer he looks at. Even
          the putting records themselves are a little deceptive. I set the PGA
          Tour record for the fewest putts over nine holes at the 2002 Air
          Canada Championship, with six. Everybody asks me if the hole looked
          huge that day. Actually, it didn't feel like anything out of the
          ordinary. I missed a lot of greens and made some nice putts to get up
          and down, but the big thing was that I chipped in twice. That record
          is more a testament to my short game than to my putting. Just remember
          that when you're watching a telecast, you're mostly seeing the six or
          eight guys playing well and making birdies. Of course they're feeling
          good with the putter that week. You also have to remember that the
          average tour player has a tremendous amount of skill and works a ton
          on his game. Tour players shoot low scores because they hit fairways
          and greens and make putts, and also because when they miss greens,
          they get it up and down. If I played in from a ten-or
          twelve-handicapper's second shot, I wouldn't shoot too many over par.
        </div>
        <div class="p-mb-2">
          There's no physical reason you can't take what tour players do in
          their putting games and use it to improve your own stroke. Just don't
          beat yourself up when you miss the odd three-footer for par, or
          three-putt from twenty feet. I take it as absolute truth that the only
          thing I can control in the putting game is my own stroke. I know that
          I can make a great stroke and roll the ball great and the putt still
          might not go in. Maybe my read was a little off, or maybe the green
          wasn't quite true. What we're trying to do here is improve the average
          of all your putts—get the bad ones less bad, and the good ones
          consistently on line. If we can do that, you're going to make more
          putts, leave yourself less work to do to clean up long putts, and most
          importantly, enjoy yourself a heck of a lot more out there.
        </div>
      </div>
    </Panel>
    <Panel header="Conclusions" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          I've given you a lot of technical and procedural information here, but
          I have to point out one important fact. You can easily get too caught
          up in the process of reading your putt and disrupt the most powerful
          tool you have in your putting arsenal—instinct. I can remember playing
          with Ben Crenshaw in the first two rounds of a tour event many years
          ago, and I was amazed by the way he seemed to just walk up and hit his
          long putts without too much of a careful read. My interpretation of
          that was that he knew that a precise read from thirty or forty feet
          was impossible, so he was relying on his instincts and a good roll to
          get the ball close. Of course, when he got closer, he took his time
          and made a precise read—because he was in a position to take advantage
          of it.
        </div>
        <div class="p-mb-2">
          I'm not suggesting that you walk up and whack your putts with no
          thought about where they might go. But you want your routine on the
          putting green to be a mixture of conscious information gathering and
          positive visualization, and then you want to get out of the way and
          let your instincts take care of the rest.
        </div>
      </div>
    </Panel>
    <Panel header="xxx" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">xxx</div>
        <div class="p-mb-2">xxx</div>
        <div class="p-mb-2">xxx</div>
        <img class="img4" src="@/assets/images/utleyPutting/stance2.png" />
        <div class="accent1 fontSmall p-mb-2">xxx</div>
      </div>
    </Panel>
  </Panel>
</template>
