<template>
  <Panel header="Introduction" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        The key to managing your arousal is your ability to manage
        stress-related tension (the muscular expression).
      </div>
      <div class="p-mb-2">
        In describing rounds, our tour clients often refer to "good tension" and
        "bad tension." Without the butterflies and excitement of the good
        tension that gives them the needed focus, tempo, and desire to compete,
        players find themselves playing underaroused, with a lax swing, little
        drive and poor focus. Playing with bad tension, on the other hand,
        leaves players feeling stiff, rushed, and bound by a tight body and an
        overwrought mind. The big difference? Good tension is an arousal level
        that you can control. Bad tension is an arousal level that is
        controlling you.
      </div>
      <div class="p-mb-2">
        Our bodies are designed to alter functions so that we have the best
        chance of survival when faced with life threatening situations - a
        ‘fight or flight’ response. Physiological changes are triggered by
        hormones our bodies release in the face of danger.
      </div>
      <div class="p-mb-2">
        The key here, however, is that the body does not know the difference
        between real and perceived danger. For example, your life is not truly
        in danger when you hit your first tee shot of a major tournament in
        front of a crowd.
      </div>
      <div class="p-mb-2">
        Several things happen when you encounter real or perceived danger:
      </div>
    </div>
  </Panel>
  <Panel header="1. Hormonal Release" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        1. You release hormones: Initially epinephrine (adrenaline) and
        norepinephrine are released to jump start your system by producing more
        blood sugar and raising your heart rate. Later, endorphins are released
        to numb pain and injury. in the early stages you feel energized, perhaps
        even having to club down a bit to adjust for the extra length you
        suddenly possess. But unless you manage your arousal, your clubs will
        come to feel like unwieldy sticks in your hands and your brain and/or
        body will feel exhausted, sometimes before the completion of your round.
      </div>
    </div>
  </Panel>
  <Panel header="2. Tachycardia" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        2. Your heart races: As pulse and blood pressure climb, more fuel is
        carried to your important muscles and major organs, especially your
        brain. Prolonged arousal can make your hands and feet feel cold, stiff,
        or numb since the blood that normally would have flowed there has been
        rerouted. This, along with muscle tension or alignment, leads players to
        feel uncomfortable with their setup, usually without really knowing why.
      </div>
    </div>
  </Panel>
  <Panel header="3. Diarrhoea" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        3. You need to go to the toilet: As your metabolism speeds up, you want
        to relieve yourself more often.
      </div>
    </div>
  </Panel>
  <Panel header="4. Nausea" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        4. You feel nauseous: Some players find it difficult to eat before and
        during the round. Again the body is diverting energy normally used for
        digestion to functions for survival (large muscles/brain not GIT)
      </div>
    </div>
  </Panel>
  <Panel header="5. Dry Mouth" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        5. Your mouth dries up: As energy for digestion is diverted, the
        production of saliva is slowed. Later, because of the lack of proper
        digestion, constipation.
      </div>
    </div>
  </Panel>
  <Panel header="6. Muscular Tension" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        6. Your muscles tense up: They receive a signal from the brain that
        danger is at hand and receive a surge of oxygen rich blood. At the
        instant your muscles are alerted, your impulse is either to run away as
        fast as possible or to fight, but golf allows neither. So this muscle
        tension must instead be managed, and all energies must be channeled into
        concentration and focus. The tension also must be released because
        holding on to it inhibits the swing. Releasing it by aggression,
        however-destroying tee markers, kicking bags, breaking clubs---can
        actually leave the player with a shaky “adrenaline hangover," a sore
        toe, and if that player is a touring professional, a hefty fine.
      </div>
      <div class="p-mb-2">
        The best golfers always monitor their bodies for tension and take steps
        to manage arousal.
      </div>
      <div class="p-mb-2">
        Since most players are not naturally aware of their arousal, we teach
        them to watch for physical signs of tension. Their heads tilt forward,
        putting strain on their necks. Their shoulders tighten. Their arms are
        held closer to the body, and their hands tighten into fists, fidget with
        coins or other objects, or grip the clubs too tightly. Their torsos
        become rigid and lean more forward, often straining the lower back.
        Their teeth clench and their lips tighten. Those chewing gum start to
        chew rapidly. The eye muscles are more strained and their brows become
        furrowed. And, as noted before, their breaths become quicker and
        shorter.
      </div>
      <div class="p-mb-2">
        These physical changes usually result in swings that are quicker and
        shorter and putting strokes that more resemble a punch, pull, or push.
        As we have mentioned, average players all too often misdiagnose their
        swing problems as mechanical when the real problem is overarousal. The
        mechanics are breaking down under pressure, but they often return when
        the player gets more relaxed.
      </div>
    </div>
  </Panel>
  <Panel header="7. Hypercapnoea" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        7. Your breathing quickens and gets shorter: As muscle tension builds,
        chest muscles can constrict, causing you to take shorter quicker
        breaths. Overly aroused players will often unknowingly hold their breath
        as they execute a shot or putt, usually resulting in an awkward steering
        and a mis-hit.
      </div>
    </div>
  </Panel>
</template>
