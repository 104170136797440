<template>
  <Panel header="Hydration" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        DURING THE ROUND (250ml = 1cup == 8oz == 1/2 'flask')
        <b>1/2 'flask' every 30 min </b>
      </div>
      <div class="p-mb-2">
        A recent study found that when we start to sweat, we reduce our
        hydration levels. If these levels drop by just 2% it will affect our
        muscle speed, strength, coordination, and ability to make clear
        decisions. With these factors making up 95% of the game, it's crucial
        that you remain hydrated whilst playing golf; especially during those
        hot, summer days.
      </div>
      <div class="p-mb-2">
        How often do golfers find themselves playing a great round but then
        burning out with three to four holes to go. One of the main reasons is
        likely due to loss of sweat and fluids. So what, and how much, should
        you be consuming during your round of golf? We take a look here.
      </div>
      <div class="p-mb-2">Water</div>
      <div class="p-mb-2">
        It's a pretty obvious answer, but water is the most important thing you
        should be consuming during your round. Experts say you should be having
        1-2 cups (250-500ml) before the round commences, then 1/2-1 cup
        (125-250ml) every 15-30 minutes on the course. For 3.5 hr round = 3.5 -
        7 cups (1.5 to 3.5 'flasks'). I.e.,
        <b>1 flask per 9 holes on not sweaty day.</b>
      </div>
      <div class="p-mb-2">
        Obviously, some players sweat more and some sweat less. So, with that in
        mind, your fluid intake should be altered depending on which category
        you fall into.
      </div>
      <div class="p-mb-2">
        By consuming water during the round, the blood becomes easier to
        transport to the areas which need it most. Becoming dehydrated means our
        blood is slightly thicker, moves slower and increases our heart rate;
        which is not what you want when playing.
      </div>
      <div class="p-mb-2">Isotonic Drinks</div>
      <div class="p-mb-2">
        When sweating, it's not just water that you are losing but also
        electrolytes such as sodium, chloride, and potassium. These electrolytes
        help your fluid balance, nerve impulses, and muscle contraction; all
        important aspects for performance.
      </div>
      <div class="p-mb-2">
        Although the amount of water you lose through sweat is much higher than
        the amount of electrolytes you lose, it's still important to replenish
        these electrolytes whilst on the course.
      </div>
      <div class="p-mb-2">
        Isotonic drinks include Lucozade, Gatorade and Powerade. You can also
        find electrolytes in fresh fruit, a nutrition bar or a sandwich.
      </div>
      <div class="p-mb-2">AFTER THE ROUND</div>
      <div class="p-mb-2">
        After you have completed the round, there is obviously one beverage that
        most golfers will choose: an ice cold beer. Although this is a
        completely understandable decision, do bear in mind that alcohol
        increases dehydration due to its diuretic properties. Adding a splash of
        lemonade will slightly reduce this effect.
      </div>
      <div class="p-mb-2">
        If you aren't partial to an odd beer after your round, then the best way
        to rehydrate is to consume a minimum of 500ml of water.
      </div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
    </div>
  </Panel>
</template>
