<template>
  <div class="p-d-flex-column">
    <Panel
      header="Technique (Sieckmann: Short Game Solution)"
      :toggleable="true"
      :collapsed="true"
    >
      <sieckmann1Technique />
    </Panel>
    <Panel
      header="Adaptations (Sieckmann: Short Game Solution)"
      :toggleable="true"
      :collapsed="true"
    >
      <sieckmann1Adaptations />
    </Panel>
  </div>
</template>

<script>
import sieckmann1Technique from "@/components/sieckmann1Finesse/sieckmann1Technique";
import sieckmann1Adaptations from "@/components/sieckmann1Finesse/sieckmann1Adaptations";

export default {
  name: "sieckmannFinesse",
  components: {
    sieckmann1Technique,
    sieckmann1Adaptations,
  },
};
</script>
<style lang="scss"></style>
