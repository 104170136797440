import { createStore } from "vuex";
import * as note from "@/store/note.js";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    note,
  },
});
