<template>
  <div class="fontSmall taLeft">
    <div class="p-mb-2">
      Tame Your Tension “If you asked me the single most important key to
      longevity, I would have to say it is avoiding worry, stress and tension.
      And if you didn’t ask me, I’d still have to say it.” Comedian George
      Burns, who lived to be one hundred SWING KEY: The greatest saboteur of the
      golf swing is tension. You can learn to control it.
    </div>
    <div class="p-mb-2">
      There is nothing that can put your balance and tempo out of whack quicker
      than tension. This is the demon that comes between you and your golf
      swing. And it is a demon with which we all struggle. That is simply a fact
      of life—and golf is a part of life. Two keys are first to understand where
      in your swing tension is likely to reside, and second to develop the
      skills to manage that tension so you can play your best golf. You can’t
      eliminate tension, but you can learn to manage it.
    </div>
    <div class="p-mb-2">
      While tension can have its roots in your mind—a fear about the situation
      you are in, warranted or not, or simply indecision—it manifests itself in
      your body. Mental concern translates into physical tension. Literally,
      your body tenses up in certain areas, and that makes it extremely
      difficult to make a fluid golf swing once, let alone repeat that swing
      time after time. Tension in the swing or putting stroke ruins your
      technique. It’s as simple as that.
    </div>
    <div class="p-mb-2">
      First, let’s talk about awareness. We know tension exists in all of us,
      but where does it reside for you? Some players have too much tension
      during the entire swing. Others have tension show up at specific points
      during the swing—the takeaway, the downswing, the weight shift or at
      impact, for example. For some, it shows up only under competitive
      pressure, while “others find even the act of recreational golf stressful.
      Mastery of technique is of no value if you do not learn tension awareness.
    </div>
    <div class="p-mb-2">
      Understanding the importance of tension awareness is crucial to playing
      peak-performance golf. This understanding is one of the keys to allowing
      you to create a holistic approach to the game, blending all of the
      Essential Playing Skills into one unified performer of the game of golf.
      To grasp the importance of this skill, all you have to do is watch the
      best players try to tame their nerves. Even the most skilled players are
      at times victimized by tension.
    </div>
    <div class="p-mb-2">
      When a shot goes wrong, it is easy to focus in on the purely mechanical
      aspects of the game: “He came over the top on that swing” or “She took the
      club back too far on the inside.” That’s what we hear the television
      commentators say. But those are not mistakes the player makes all the
      time. Why now? Why in this situation? Why did the player come over the top
      or take the club back too far on the inside at this very moment when it
      mattered most?
    </div>
    <div class="p-mb-2">
      Tension is the great instigator of disrupted technique and will first
      manifest itself in the Think Box, where stress will keep you from making a
      clear decision or keep you from making a full commitment to that decision.
      Then that tension crosses the Decision Line with you into the Play Box. In
      an absolute worst-case scenario, this is what has happened: • You have
      made a poor decision in the Think Box about the shot you are going to
      play. • There is a part of you that has doubts about the decision you have
      made, so you are not committed to your decision. • Your mind is a jumble
      in the Play Box and you have completely lost contact with your Play Box
      Awareness. • You make a swing that is out of balance and lacking your
      tempo, and the ball flies horribly awry.
    </div>
    <div class="p-mb-2">
      Yes, we can go to the videotape and point out the flaws in the swing you
      have just made, but what the video doesn’t show is the tension in your
      body and the way it has disrupted your mind. If you focus only on the
      swing you have made when analyzing a result that did not go as planned,
      you are looking only at a small part of the puzzle. You have lost the big
      picture, the real picture, the complete view. What people like to call
      “choking” involves clutter, confusion and a lack of commitment more than
      merely a poor swing. In fact, they are the formula for a poor swing.
      Athletes in all sports talk about the importance of “slowing the game
      down.” This means learning not to let tension disrupt the performance
      routine you have developed.
    </div>
    <div class="p-mb-2">
      Tension is not merely a mental or physical component of the game, but
      rather the beast with a foot in both camps and therefore the single
      biggest obstacle to a fully integrated swing. It is easy to say tension
      begins with fear or nervousness or doubt about the situation at hand, but
      that out-of-control thinking process is also the result of specific
      physical activities taking place in your body when you are under
      stress—and they are physical activities you can control.
    </div>
    <div class="p-mb-2">
      Barbara Bonney, the world-famous soprano who attended our school, began
      singing professionally in 1979 and, because her father was a teaching pro,
      played golf from the age of eight until she was twelve, at which time she
      shifted her focus to music. It wasn’t until well into her adult life that
      she started to play the game seriously, as a diversion from professional
      and personal pressures.
    </div>
    <div class="p-mb-2">
      “I took a year off from work when I got divorced, and playing golf kept me
      sane and fit,” Barbara says. “During that year off, I was seriously
      thinking of becoming a teaching pro rather than returning to the music
      business, so I was working on my game very seriously. I liked the look of
      the VISION54 program, and actually speak Swedish, so I thought it would be
      fun to work with Pia and Lynn.”
    </div>
    <div class="p-mb-2">
      What she found was that VISION54 contains some startling similarities to
      the peak-performance approach she had to music. “I would say the whole
      VISION54 program was a reflection of the tools I use to teach my students
      singing: self-awareness, self-esteem, focus and relaxation, physical
      strength,” says Barbara. “It was so interesting to compare the concepts
      because, at the end of the day, they are the same. Performance remains the
      same, whether it is on the golf course or on the stage of an opera house
      or concert hall. I think it is important to do the work in the studio or
      the range, then to walk out on stage or the first tee with your brain
      switched off. The only option is to follow your instincts and react to an
      ever-changing and evolving situation.
    </div>
    <div class="p-mb-2">
      Stan Freimuth is a retired money manager who found out about VISION54 from
      a magazine article. He had a 15 handicap for six years before he attended
      our school. Through better management of his Tension Awareness and after
      two years learning our skills for managing his tension, Stan was able to
      bring his handicap down to 5. He and three friends return to our school
      for one day twice a year for a tune-up.
    </div>
    <div class="p-mb-2">
      “I learned how to be my own coach and came to understand that what exists
      between my ears and in my gut is more important than the angle of “my
      wrists,” Stan says. “Now it astounds me how people always go immediately
      to the technical when they are struggling with their game. I feel now like
      I always have an awareness of the complete game, of the mental, emotional,
      physical and social as well as the technical.”
    </div>
    <div class="p-mb-2">
      Stan is someone who knows the importance of maintaining control and making
      clear, informed decisions. As a money manager, that was essential. In his
      job, he also knew the importance of navigating his way through stressful
      situations. But on the golf course he was not so good at those skills.
      Part of what Stan had to learn was to bring the discipline he applied in
      his job to the sport he loves. At work he was fearless, and he needed to
      learn how to become that person on the golf course.
    </div>
    <div class="p-mb-2">
      “I had no idea how important this was before going to Pia and Lynn, which
      is a little embarrassing when you consider my job and what it required,”
      Stan said. “Now when the wheels come off on the golf course, I don’t think
      about my mechanics. I know that it always comes back to tension and I do
      breathing exercises. The problem isn’t a breakdown in my mechanics, but in
      what led to the breakdown in my mechanics.”
    </div>
    <div class="p-mb-2">
      Common places for tension to show up are in the hands, arms, shoulders and
      especially jaw. Now, who would think that the jaw would be a part of the
      body that could undermine your ability to make a fluid golf swing? But
      this is where science is our friend, and an extremely useful tool.
      Research shows that there are more nerve endings in the jaw than in any
      other part of the body.
    </div>
    <div class="p-mb-2">
      If you grit your teeth and try to make a swing with a clenched jaw, you
      have likely started a chain reaction that will lead to a poor golf shot.
      Find some photos of Ben Hogan making a swing, especially some of the
      frozen images of him in the downswing. Look at his face. It is startling
      how relaxed he looks. There is no tension in his jaw. In fact, his entire
      body appears to be at peace. Part of that calm state is the complete trust
      he had in his swing, but that trust also came from the fact that he tamed
      his tension and that his body was not working against him.
    </div>
    <div class="p-mb-2">
      Similarly, when Tiger Woods hits one of his wild shots, those who watch
      him play a lot can often tell, before the club head ever strikes the ball,
      that the result is not going to be good. When Tiger reaches for another
      gear, you can see it in his face; you can see his body tense as he
      “muscles up” to hit the ball harder. Lost is the breathtaking fluidity
      that is his swing at its best.
    </div>
    <div class="p-mb-2">
      In the previous chapters we have talked about Play Box, Decision and
      Commitment, Balance, and Tempo.Tension can sabotage all of those
      Essentials. That’s why Tension Awareness is one of the Essentials. The
      first step toward taming tension is understanding how it manifests itself
      in you. You can become aware of the tension level in your body by creating
      it during practice. The more you are aware of it, the more likely you are
      to tame it. And tension can be tamed.
    </div>
    <div class="p-mb-2">
      Some players experience tension because they try to make swings that their
      body can’t really do. In fact, isn’t that a common source of tension in
      all aspects of our lives—when we try to be someone we aren’t and do not
      honor the essence of our selves? Always celebrate who you are. The
      stress-free swing is made when you are working with yourself and not
      against yourself.
    </div>
    <div class="p-mb-2">
      This is an important point to remember: Anyone’s swing improves when that
      person learns how to manage tension. The less tense you are, the more
      fluid your swing will be—it’s as simple as that. With better awareness and
      a little bit of practice, anyone can improve in this area. When are you
      tense? On the first tee with people watching? When you have an important
      putt or need to drive the ball in the fairway? And when you have
      identified those moments when tension is most likely to occur, how do you
      manage it?
    </div>
    <div class="p-mb-2">
      For some of you, it’s as simple as taking a few deep-belly breaths to get
      the tension out. Fuzzy Zoeller used to whistle while he walked the
      fairways. Lee Trevino would talk to whoever would listen. There is a lot
      of waiting-around time in golf and it brings to mind the old axiom: “Idle
      hands are the devil’s playground.” Tension can build during those idle
      moments. That’s why you have to fill those moments with activities that
      release tension. Some of our players stretch or even jump in place. Others
      send their minds on vacations to happy places with comforting thoughts.
      Kevin Streelman focuses on grip pressure when he is looking to relax;
      Suzann Pettersen is aware that tension for her resides in her upper body
      and jaw, and focuses on relaxing those areas.
    </div>
    <div class="p-mb-2">
      In our golf schools, we establish Tension Awareness through several
      different exercises. One is the Tai Chi swing. Tai Chi is a Chinese
      exercise art that involves extremely slow, graceful movements. We have our
      players make a complete golf swing at a speed at which it takes two to
      three minutes to complete the full swing. You can also do this Tai Chi
      swing with your eyes closed. As you concentrate on your movements, you
      will discover the parts of your swing where tension exists. You will feel
      where the tension lives.
    </div>
    <div class="p-mb-2">
      When you have identified a tension spot—say it is at the top of the
      backswing—stop and slowly go back and repeat that portion of the swing.
      What do you need to do to lessen the tension in that part of your swing?
      How does “the tension manifest itself? Do your shoulders tighten up or
      does your grip pressure change? Does an awareness of the location of the
      tension ease the tension or make it more intense? Monitor your body.
    </div>
    <div class="p-mb-2">
      Here is an exercise you can do: Hit four shots with the same club,
      starting with an extremely light grip pressure and ending with an
      extremely tight grip pressure. Maintain the grip pressure you start with
      throughout the swing. Where in the spectrum of tightness does the grip
      work best for you? Do this exercise with all of your shots, including
      chips and putts. When you play, is your grip pressure constant or is it
      all over the map? Many players squeeze the club more tightly at takeaway
      or in the transition to the downswing or right before contact.
    </div>
    <div class="p-mb-2">
      Now hit three shots with three different levels of tension in your upper
      body—the shoulders, arms and jaw. Try to be rubbery loose and try to be
      grit-your-teeth tense. Where in that spectrum of tension do you swing
      best? If your tension level changes on the golf course, do you become
      tighter or looser? Many golfers clench their jaw while swinging. Some
      players get twitchy with their wrists when making the putting stroke. Some
      people respond to pressure by getting lazy with their swing.
    </div>
    <div class="p-mb-2">
      Learning the tension level you want to have in the Play Box is extremely
      important, and a key to achieving your desired level of tension is
      learning to manage tension between shots. Barbara Bonney taught us a great
      tip for managing tension in the jaw. Put your tongue behind your bottom
      front teeth. When you do that, the jaw drops and relaxes into a functional
      position. This literally prevents you from gritting your teeth. We will
      also have a player hit shots with a potato chip between his or her teeth,
      the goal being to execute the shot without biting through the chip. You
      can also concentrate on feeling a low center of gravity as you walk
      between shots, or you can exaggerate purging exhales in your breathing,
      rolling your shoulders or doing any other stretch that helps your body be
      more functional. These disciplines work; they reduce tension. Try them.
    </div>
    <div class="p-mb-2">
      To pretend tension doesn’t exist or that you are immune to it is pure
      folly. And to think that the same tension level is the optimal state for
      all golfers is to deny a core principle of VISION54: You are an individual
      first and a golfer second. What works for you doesn’t necessarily work for
      everyone else, and what works for everyone else doesn’t necessarily work
      for you. What is true for everyone is that tension sabotages the swing.
      Your specific tools for managing your tension will be determined by you
      through experimentation. You can never be a blank slate devoid of emotions
      when you hit a golf ball. Some people play best when they are in fifth
      gear—like Greg Norman—and some play their best when they are in second
      gear—like Nick Faldo. You have to find the gear in which you function
      best, and you have to learn the ability to shift from gear to gear.
    </div>
  </div>
</template>
