<template>
  <Panel header="Concluding Thoughts" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        The tools you need to play your best golf are all in this book. These
        are skills that will complement your technique and make you a player of
        the game and not just a student of the swing. Like your game, like your
        swing, like every aspect of your life, they are uniquely yours. And like
        everything else, the degree to which you will succeed with these tools
        depends on your commitment to them. VISION54 is not a place where you
        arrive, but rather a path upon which you travel. Golf is a game you can
        play your entire life. That is part of its unique nature. These tools
        are skills for your entire life, and they can last a lifetime—if you
        commit to making that happen.
      </div>
      <div class="p-mb-2">
        To play your best golf now, you must be fully engaged with every shot
        you hit. That engagement is not an accident. It is the result of the
        tools you have learned here. Now you have to put all of that knowledge
        to work. You must practice with a purpose, you must play with a purpose,
        and you must do both with a passion. Your toolbox is now full. What are
        you going to build with those tools? It’s up to you to determine the
        ways in which these tools work best for you.
      </div>
      <div class="p-mb-2">
        The 8 Essential Playing Skills are exactly that—Essentials. They are the
        product of endless hours of interaction we have had with players of all
        skill levels. These tools will enhance the skills you already have, and
        they will build upon the unquestionable fundamentals of the game. Proper
        grip, stance and posture are certainly a necessity, but more is needed
        for you to be a complete player. The 8 Essentials are fundamental to
        your enjoyment of the game and your performance. For us, peak
        performance in golf is a blend of science and art. For so long the art
        has been implicit, like saying, “Trust it.” The essential playing skills
        make the implicit explicit.
      </div>
      <div class="p-mb-2">
        Fortunately, how you choose to be today or for each shot is not
        predetermined by how you were yesterday, or by your last shot. But that
        will only become your reality if you decide to take control of the
        Essential Playing Skills. Many players know they should focus on the
        process, but they don’t really know how to do it. The 8 Essential
        Playing Skills are the process. You need to focus on executing each
        skill. You need to learn how to do the skill, not just understand the
        skill. If you focus on how you do each Essential Playing Skill, you are
        engaged in the process that leads to your most desired outcomes.
      </div>
      <div class="p-mb-2">
        Many are fooled by the fact that any malfunction in the traditional
        fundamentals will always manifest itself in the technical aspect of the
        swing and/or putting stroke. But this does not mean that technique is
        where the trouble originated. We hope we have impressed upon you that
        you can play significantly better without changing your swing. In many
        ways, the skills we have revealed to you are so fundamental—so
        essential—they are often overlooked or they are implicitly talked about
        and not made an explicit part of your game through practice. You can’t
        let that happen. We are not saying that you should ignore your
        mechanics. We are saying you will play much better if your technical
        skills are complemented by the 8 Essentials.
      </div>
      <div class="p-mb-2">
        We also hope we have impressed upon you the fact that golf is all about
        the Play Box. That is where the game happens; that is where it is
        experienced. Everything else you have learned is designed to help create
        a state of pure engagement when you are in the Play Box. As we said,
        this is the sacred ground of golf. This is where you celebrate the game.
        For that celebration to be joyous, you need to reach a state of total
        engagement and complete commitment. You will figure out what tools work
        best for you in the Play Box to achieve that peak-performance state.
      </div>
      <div class="p-mb-2">
        As for the other Essentials, they are all crucial and necessary, but you
        will be the person who figures out how to prioritize them in regard to
        your game. After the Play Box, the order in which the other Essentials
        appear in this book is relatively random. Arrange them in the way that
        works best for you. Maybe you go most astray in the Decide and Commit
        area, or perhaps you have problems with Balance, Tempo and Tension
        Awareness, Emotional Resilience, Storing Memories or Self-Talk. You
        should use this book to customize your MY54 and to learn how to manage
        your NOT54.
      </div>
      <div class="p-mb-2">
        Playing your best golf is all about understanding your relationship with
        the game and gaining a better understanding of how you interact with the
        game, especially under the pressure to perform. Our suggestion is that
        you focus on one Essential at a time. Practice that Essential and then
        take it out onto the course. Keep stats about your performance with any
        Essential you work on. Create a report card grading yourself from one to
        five. After getting all of the Essentials to a functional level, you
        will know which ones need regular maintenance for you and which ones
        will serve you best in warming up for a round.
      </div>
      <div class="p-mb-2">
        The 2 Practice Essentials are simply nonnegotiable. To not follow them
        is to waste your time when you practice. You will get a good return on
        investment for the hours you spend on the practice range only if you
        follow these Essentials: Make practice like golf—simulate the game or
        practice on the course—and learn how to best integrate the skills you
        practice into your game. For both Practice Essentials, you want to pay
        attention to your intention. This makes so much sense, but it is also
        often overlooked. You get good at what you practice, so you want to
        practice golf—not practice practice.
      </div>
      <div class="p-mb-2">
        Your practice must have a purpose or you are ingraining bad habits,
        especially a sloppy Play Box state. If you just step up and slap the
        ball in practice, that habit will carry over to the golf course, and you
        will just step up and slap the ball there. If you have only good lies in
        practice, how will you handle the situation when you have a bad lie on
        the golf course? You can prepare for the unexpected by simulating the
        unexpected in your practice. Be creative with your practice. Have fun
        with it. Create an atmosphere of engagement. Build the positive
        perspective that will follow you onto the golf course. You shouldn’t be
        afraid to leave the range to go play—you should be excited, joyous,
        thrilled.
      </div>
      <div class="p-mb-2">
        All swing instructors would agree that the game comes down to how you
        perform when you step up to the golf ball—how you perform in the Play
        Box. Learning is meaningless unless that knowledge can be put to use
        when it matters most. We differ with a lot of teachers in that we don’t
        believe that perfecting the golf swing should be the prime focus of
        instruction; we believe this is putting the cart before the horse.
        Actually, it is more like putting the horse in the cart. It’s not going
        to get you anywhere. Golf is a total human experience that is much more
        complete and much more satisfying than merely the mechanical motions
        involved.
      </div>
      <div class="p-mb-2">
        Swinging the club perfectly is not the purpose of the game; the purpose
        is to get the ball in the hole in the fewest number of stokes. You are
        playing for your score, not to get style points. You don’t have to swing
        it perfectly; you have to swing it well enough. You don’t have to have
        perfect technique; you have to have functional technique: Certainly, you
        have to grip the club properly, stand properly and rotate properly—but
        you don’t have to do any of that perfectly. Obsessing over perfection
        will distract you from your ultimate goal—to play your best golf now.
      </div>
      <div class="p-mb-2">
        Lots of good-enough, some good and a few great shots lead to low scores.
        We often say you can practice for perfection, but to play successfully,
        you need to be OK with “good enough” on the course. Are you in
        good-enough position on the fairway to hit your second shot? Is the shot
        in a good enough spot on the green for you to have a reasonable putt?
      </div>
      <div class="p-mb-2">
        Swing theory is all well and good, and there are many methods of
        teaching out there that work just fine. And that’s the point: There are
        many swings that work just fine. There is not one swing that is the
        best, or everyone would be using that swing. But there is one swing that
        is best for you, and that is your swing. You have to find it and make it
        repeat. At VISION54 we are technically agnostic. We are asking you to
        take swing theory one step further—recognize and embrace all of the
        diverse elements that affect technique and performance.
      </div>
      <div class="p-mb-2">
        The details of a good swing are only one aspect of the formula that
        leads to a happy golf shot. Remember this formula; write it down and
        embrace it: PTMESS—Golf is Physical, Technical, Mental, Emotional,
        Social, and your Spirit of the game. You cannot focus on just one
        aspect—like just the technical—and expect to play your best golf. This
        is the whole-game approach, remember?
      </div>
      <div class="p-mb-2">
        Golf should exist on many levels for you. Make your dreams big; make
        your goals off the charts; make your vision of the future in the
        brightest colors imaginable. Make your worldview VISON54. But don’t
        allow it to be just a vision, just a dream. Make it happen. That’s the
        part that is up to you. You can have your goals, but make certain you
        have a plan for how to achieve those goals. It takes thousands of nails
        to build a house. The same is true for your golf dreams. But only you
        can drive in those nails.
      </div>
      <div class="p-mb-2">
        To play the game as well as you are capable of playing it, you have to
        master the game within the game. What is your playing focus today? Is
        it, “I will finish every shot in balance”? Is it, “I will remain in a
        good mood no matter how others behave”? Or is it, “I am going to
        distract my self-talk today by singing to it”? Whatever you choose as a
        playing focus, it has to be something under your control. And whatever
        you choose, you have to commit to it completely.
      </div>
      <div class="p-mb-2">
        Everybody puts the focus on outcome in golf: What did you shoot? Did you
        win? But those are things you can’t control. You can’t control score;
        you could get bad bounces or the wind could blow.You can’t control
        winning; you can play very well and someone else can play one stroke
        better.There is no way to control that. So you have to focus on things
        that are under your control, like the inner game. That is where you
        create an environment in which the possibility for peak performance not
        only exists but thrives.
      </div>
      <div class="p-mb-2">
        What you focus on will be up to you. What works best for you will be
        your discovery. It will be your promise, your commitment to yourself.
        Just make sure it is something under your control. Give your focus a
        score relating to how much it is under your control, with one
        representing no control and one hundred representing complete control.
        Once you are sure your playing focus is under your control, make it
        specific and state it positively. Your playing focus will enable you to
        create a state of complete engagement with the game. It will keep you on
        task.
      </div>
      <div class="p-mb-2">
        We are often asked how we feel about goal-setting, and it’s a tricky
        question. Failing to reach your goal can lead to disappointment and a
        loss of confidence, so goals can be confusing. But it is important, in
        golf and life, to have a clear vision of what you want to achieve and to
        understand what aspects of your vision are under your control. Your mind
        and body need the direction of your goals to function at a high level.
      </div>
      <div class="p-mb-2">
        We talk to the players about three types of goals. The first is to
        imagine what you want to happen. Your nervous system needs to be
        prepared for success. Imaging greatness is just as important as the
        physical part of practice. You want to really experience what being
        great feels like. Dream big; believe that anything is possible and that
        you can truly be as great as you want to be. Nurture the ability that
        has been inside you since you were a child. Tap into your full potential
        in terms of both how you play golf and who you are as a person.
      </div>
      <div class="p-mb-2">
        The research is very clear on this: For sustainable performance, the
        extrinsic (outcome goals) needs to be balanced with the intrinsic (your
        spirit of the game). Most players only focus on the extrinsic goals, and
        that can lead to great frustration. You should pursue your goals, but
        don’t let results control your emotions. Love doing what you are doing
        beyond the outcomes! Conjure up a dream of greatness and paint the
        details in vivid colors. An important initial step toward greatness is
        to imagine greatness. Embracing the spirit of a specific goal can be
        great motivation for achieving that goal.
      </div>
      <div class="p-mb-2">
        The second type of goal is more traditional, and it involves outcome,
        such as lowering your average number of putts per round or your scoring
        average. This motivates some people and works well for them. Others are
        the kind of people who can lose their motivation when they don’t reach
        goals of this kind, sometimes within an unrealistically quick time
        frame.
      </div>
      <div class="p-mb-2">
        You should pay attention to how you react to this type of goal-setting.
        If it helps you, keep doing it; if it doesn’t support your progress,
        eliminate it from your program. One year, Annika Sorenstam decided to
        improve her bunker play, kept detailed statistics on her performance and
        ended the year moving from number forty-five in sand saves to number
        one. This approach works for her extremely detail-oriented approach to
        golf—and to life! Many other great players are relieved to learn that
        they don’t need to focus so much on these kind of goals. It frees them
        up.
      </div>
      <div class="p-mb-2">
        The third type of goal is what we call “to-do goals.” They are the
        actions you decide to commit to that are 100 percent under your control.
        It could be: “Today I will exercise for forty-five minutes.” Or perhaps:
        “My focus today will be to swing at eighty percent of full tempo” or
        “Today I will simulate golf for more than fifty percent of my practice
        time.” Our experience is that we all do well with the first and third
        approaches to goal-setting. Imagine what you want to be, then set to-do
        goals that get you there step-by-step.
      </div>
      <div class="p-mb-2">
        We can’t emphasize enough how important it is to act on the aspects of
        your game that will improve your performance. When Sorenstam decided to
        focus on making her bunker play better, it was in large part because
        there were so few other areas of her game that needed improvement. She
        identified a weakness and addressed it. Sometimes we even tell our
        students that they can’t come back to our school until they have gone
        home and done something to move their journey forward.
      </div>
      <div class="p-mb-2">
        Go back to the title of this book, Play Your Best Golf Now. Let’s look
        at each of those words and see what a beautiful sentence that is.
      </div>
      <div class="p-mb-2">
        PLAY: Don’t ever forget that this is a game. Nothing that happens on the
        golf course is going to change your overall life. Even if you are a
        professional, the most important aspects of your life are off the golf
        course: those you love and who love you. You should never fear a
        game.You should enjoy it. No matter your score, the sun comes up and the
        flowers bloom.
      </div>
      <div class="p-mb-2">
        YOUR: Reaching peak performance is a totally personal process. Your peak
        performance can only happen to you, no one else. And your path for
        achieving a peak-performance state will also be uniquely yours. Yes,
        there are the Essentials, but they are the ingredients to the soup, and
        you will be the chef who determines the proportions that work best for
        you and suit your taste. You will figure out the recipe for your MY54,
        and you will understand your NOT54.
      </div>
      <div class="p-mb-2">
        BEST: What a wonderful word this is! We don’t say “perfect,” we say
        “best.” And your best golf is, once again, a completely personal
        definition. Every principle embraced by VISION54 begins with the
        foundation that the player is a person first and a golfer second. You
        are a person who happens to play golf. The meaning of “best golf” not
        only varies from person to person, but it will vary for you from day to
        day, round to round. What you have learned here will help you play your
        best with what you have and who you are on this particular day.
      </div>
      <div class="p-mb-2">
        GOLF: What an awesome game we have the pleasure of playing! While all
        sports require focus and concentration, and while peak performance in
        any activity has similar requirements to reach that state, golf is a
        uniquely tranquil activity that embraces the beauty of its surroundings
        and involves the personality of its participants more than any other. It
        is a game for life; it is a game about life.
      </div>
      <div class="p-mb-2">
        NOW: The dream does not have to be deferred. The way to get better, the
        way to score lower, is to play the best you can play right now. If you
        are able to maximize your potential today, perhaps the bar will be
        raised higher tomorrow. What you can achieve is affected by what you
        believe you can achieve. Play your best golf now, today, and who knows
        what you can achieve tomorrow? But enjoy the now; that’s where you are
        living, not in the tomorrow—or the yesterday.
      </div>
      <div class="p-mb-2">
        We think one of the fun things about our approach is that it is
        simultaneously very specific and very improvisational. Our Essentials
        are like the outline for an ad-lib acting piece. There are components
        that are necessary—pieces that are the essential parts to the puzzle of
        playing golf—but we are giving you complete freedom in how you arrange
        those pieces. The final picture of your best golf will be drawn by you,
        not by us.
      </div>
      <div class="p-mb-2">
        We can point out the Essentials and we can help you learn the skills
        that enable you to master those Essentials, but none of this works if
        you just read them and understand them. It’s all about doing, taking
        action. All the Essentials are free. You don’t need any equipment to
        become good at them. They live inside of you and are always available.
        When will you start using them? Become an actor rather than a reactor.
        Create possibilities with your swing and your game.
      </div>
      <div class="p-mb-2">
        You will Play Your Best Golf Now when you understand your needs in the
        exciting equation of golf, and then formulate the best strategy for
        employing the Essentials for you—just as the remarkable people you have
        met in these pages have done. From them, we hope you have learned this:
        The Essential Playing Skills work, and they worked for each of these
        people in very different ways.
      </div>
      <div class="p-mb-2">
        They each found their stepladder to better golf in a different place.
        But they all enjoy golf more now because they learned skills that helped
        them reach their full potential and enabled them to enjoy the experience
        of golf more. Now you have the tools to do the same thing—enjoy! It’s
        time for you to Play Your Best Golf Now.
      </div>
    </div>
  </Panel>
</template>
