<template>
  <Panel header="Introduction" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        If you regularly read golf magazines, take lessons, or watch The Golf
        Channel, you probably believe that the following are the most important
        basics or fundamentals concerning one's ability to employ the perfect
        backswing action: 1. Make a sweeping, one-piece take-away, controlled by
        the arms and shoulders, keeping the wrists quiet. 2. Turn your shoulders
        at a 90-degree angle to your spine, making sure to rotate your left
        shoulder under your chin. 3. Shift 60 percent of your body weight onto
        your right foot and leg. 4. Keep your arms in front of you. 5. Turn your
        hips 45 degrees. 6. Maintain your spine angle. 7. Keep your head steady.
        I admit that at one time I believed these and other "tried-and-true"
        basics that were supposedly followed by all great players. Frankly,
        though, trusting in these supposed fundamentals was the chief reason I
        never reached my full potential as a player. I only discovered the
        falsehoods after tireless research and have since concluded that there
        is not, in fact, one set of fundamentals. But rather, there are
        two—those relating to the one- and two-plane swings. By the time I woke
        up to reality, it was too late for me to use what I had learned to
        become a successful tour player. Time had certainly passed me by, and,
        besides that, I had already switched professions and had become a
        teacher and golf course designer. That's the bad news. The good news is
        that it's not too late for you to learn from the following clear-cut,
        proven fundamentals that will help you become either a one- or two-plane
        pure swinger.
      </div>
      <div class="p-mb-2">
        Before we begin exploring the swing in detail, let me reiterate that it
        is not my intention to define the absolutely perfect golf swing. Nor do
        I believe that you, or any golfer for that matter, should expect to make
        an absolutely perfect golf swing every time. That applies to Tiger Woods
        as much as it applies to you. In golf, as in any sport, there is no such
        thing as perfection of execution. Take such basketball greats as Magic
        Johnson, Larry Bird, Kareem Abdul-Jabbar, and Michael Jordan. I can
        assure you that each of these players missed thousands of shots, even
        uncontested ones. In golf, perfection would mean a player would shoot an
        18-hole score of 32 on a regulation par-72 course. That would require
        the player to hit every shot in the hole whenever the hole is in reach
        (i.e., a hole-in-one on each par-three hole, and holing out in two shots
        on every par-four and par-five hole).
      </div>
      <div class="p-mb-2">
        Now, with the tremendous advances in talent, equipment, coaching, and
        physical training that are taking place not only on the PGA Tour but
        worldwide, I believe that, 20 or even 10 years from now, there might be
        scoring that we can't conceive of today. But no one will ever shoot a 32
        on a par-72 course, because there never will be such a thing as
        perfection in any athletic endeavor.
      </div>
      <div class="p-mb-2">
        Of course, every golfer should strive to improve every time he or she
        tees up, whether for a round or a practice session. But to get back to
        my purpose here, I want to explain the two distinct methods of building
        a functional golf swing. I will start by defining what a functional
        swing is. By functional swing I mean a swing that allows you to hit the
        ball with accuracy and for good distance so that you can reliably hit a
        high percentage of fairways from the tee and greens in regulation. This
        requires that you, first, swing the club on a reasonably good,
        consistent plane around you, and second, swing the clubhead so that it
        is moving in an accurate direction (i.e. toward the intended target) at
        the instant when it meets the ball. As with the elements of the address,
        let's take a careful look at how to build a sound backswing using the
        one-plane swing method.
      </div>
    </div>
  </Panel>
  <Panel
    id="BackswingShoulderTurn"
    header="Shoulders"
    :toggleable="true"
    :collapsed="true"
  >
    <img
      src="@/assets/images/hardy1/hardyShoulders1.png"
      alt="Hardy Shoulders1"
    />
    <div class="accent1 fontSmall p-mb-2">
      This Inch-by-Inch Drill is ideal for getting a feel for the shoulder-turn
      plane I'm advocating
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        <span class="accent1">The plane of the swing</span> is an imaginary line
        or panel, if you will, that
        <span class="accent1"
          >runs upward on an angle from the golf ball to your shoulders at
          address, and then continues up behind you.</span
        >
        As mentioned earlier, it contains two directional elements—around and
        up/down. In order to swing successfully using a one-plane swing, you
        must learn to turn both your shoulders and your torso, as well as swing
        your arms and the golf club around, as closely as possible to this ideal
        plane. Now, as I stated earlier, there is no such thing as perfection in
        the golf swing. I have found that you can hit excellent shots as long as
        you keep the turn of your shoulders and torso close to the plane I've
        described. If anything, I would like to see your
        <span class="accent1"
          >shoulders turn on a plane that is a little flatter</span
        >
        at address than the plane from your shoulders to the ball. This would
        mean that when your shoulders reached the top of the backswing, they
        would point somewhere within a zone extending from the ball to slightly
        beyond it. I first mentioned this zone in the last chapter when
        discussing posture in the one-plane swing.
      </div>
      <div class="p-mb-2">
        One reason I am an advocate of the one-plane golf swing is that it has
        fewer moving parts.
        <span class="accent2"
          >One plane means that the shoulders, the arms, and the club shaft are
          all swung up and onto the single plane line</span
        >
        as I've described. However, now might be a good time to mention that the
        one-plane swing requires somewhat more athleticism of the golfer than
        does the two-plane swing. More athleticism is required because, when you
        bend from the hips at address, it means that your shoulders (as well as
        your arms and the club) will be turning on a different axis from that of
        your hips. That is, your shoulders will be turning more vertically than
        your hips. This creates tension in the muscles in your torso that only
        increases as you turn back to the top of the swing. To execute the
        one-plane golf swing, you have to be supple enough so that you can
        stretch or coil the shoulders and torso, which are tilted forward,
        against the lower torso and the hips, which are more upright. Ideally,
        you should turn your shoulders 90 degrees or more from where they were
        in the address position.
      </div>
      <div class="p-mb-2">
        To get the feeling of turning your upper torso only, stand upright, with
        the front of your thighs against the edge of a table or desk. While
        keeping your legs in contact with the desk, simulate a backswing by
        turning your shoulders as far to the right as you can. If you can turn
        your shoulders 60 to 70 degrees while keeping the front of your legs
        against the table, you possess the needed suppleness to make the
        one-plane swing work for you.
      </div>
      <div class="p-mb-2">
        Next, stand upright with no "props," again simulating a backswing by
        turning your shoulders to the right as far as you can while keeping your
        hips in place. Can you feel a stretching of the muscles along the base
        of your spine? If so, that's good. Now turn your shoulders as fully as
        you can back to the left, as in your follow-through. You should feel
        that same tension. Now repeat the drill, only this time bend from the
        waist as you would at address before making your turn in both
        directions. You are gradually learning how to turn your upper body
        independently of your lower body, as you must to become a one-plane
        player.
      </div>
      <div class="p-mb-2">
        Sometimes visualizing the plane can help you learn the movement of your
        shoulders along this plane. So heed these instructions. As you stand at
        address, you are bent over 35 to 45 degrees.
        <span class="accent1"
          >As you turn on the backswing, visualize the right shoulder going up
          and behind you while the left shoulder moves down and in front of
          you.</span
        >
        Similarly, when you
        <span class="accent1"
          >turn in the downswing, let the right shoulder go down and out toward
          the ball and the left shoulder move up and away from the target
          line</span
        >. Finally, in the
        <span class="accent1"
          >follow-through, the right shoulder should continue to move down and
          in front of you while the left shoulder moves up and behind you on
          your left side.</span
        >
      </div>
      <div class="p-mb-2">
        Visualizing the plane positions and practicing them along with the
        earlier Inch-by-Inch Drill or with the
        <a href="#On Plane Shoulder Turn">On Plane Shoulder Turn</a> Drill. To
        strengthen turn and oblique muscles try
        <a href="#ObliqueAbsStrengthener">Oblique Abs Strengthener</a> Drill.
      </div>
    </div>
  </Panel>
  <Panel id="BackswingArms" header="Arms" :toggleable="true" :collapsed="true">
    <img src="@/assets/images/hardy1/hardyArms1.png" alt="hardyArms1" />
    <div class="accent1 fontSmall p-mb-2">
      Here we see how the left arm moves in the backswing, with its upper biceps
      area hugging the chest.
    </div>
    <img src="@/assets/images/hardy1/hardyArms2.png" alt="hardyArms1" />
    <div class="accent1 fontSmall p-mb-2">
      Here we see how, in the one-plane swing, the right elbow moves up and
      behind the body. It is permissible for the one-plane golfer to allow his
      or her right elbow to point farther outward, with the club arriving in a
      slightly laid-off position.
      <a href="#ElbowsUpArmsAcross">Elbows Up, Arms Across Drill</a>
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        As I mentioned in Chapter 1, the one-plane golf swing is something like
        hitting a baseball. Now, let me go into more detail. The main difference
        is that in baseball, you're swinging a bat at a pitch that's between
        knee and chest high. In golf, your swing must deliver the club to the
        back of a ball resting on the ground (or just off the ground, on a tee).
        In the baseball swing and the one-plane golf swing, the arms move very
        similarly; they move around your chest. In a one-plane
        <span class="accent1"
          >backswing, the arms move slightly up from address and as far across
          and around your chest as possible;</span
        >
        and on the
        <span class="accent1"
          >through-swing they again move as far across and around your chest as
          possible.</span
        >
        Now, I realize that this arm movement is inconsistent with recent
        thought that you should always keep your arms in front of you. That is
        very good advice for a two-plane swing, but it is disastrous for the
        one-plane golfer. On the backswing, the left arm should swing inward and
        directly across the chest as it does when the baseball player cocks the
        bat prior to stepping into the pitch. Your
        <span class="accent1"
          >left arm should swing in and back onto the same plane as your
          shoulders are turning and should stay in contact with the chest not
          only on the backswing but throughout the downswing until just before
          impact</span
        >
        when the arms start to swing back across the chest. The
        <span class="accent1"
          >left arm will also remain straight, although not rigid, throughout
          the backswing.</span
        >
      </div>
      <div class="p-mb-2">
        Here's another teaching tip for you to try, which I gave to Peter
        Jacobsen when we first started working together. It's also one you might
        see Scott McCarron use on the practice range or even between shots
        during a tournament.
        <span class="accent1"
          >Take a small towel or a head-cover. Insert it under your left upper
          arm</span
        >
        and try to hold it in place as you make short back- and through-swings.
        As you get used to holding the object in place under your arm, gradually
        extend the length of your swings in each direction. If your left arm
        acts like that of most golfers, you'll probably find it difficult to
        hold the item in place. Keep working at it, though, and you'll get the
        feeling of your upper left arm being pinned across your chest throughout
        the backswing and into the follow-through. This practice exercise
        teaches you to keep your body in control of your swing, with your left
        arm simply going along with your body.
      </div>
      <div class="p-mb-2">
        <span class="accent1"
          >As you turn past the midway point in the backswing, your left forearm
          should begin to turn in a clockwise motion so that the back of the
          forearm faces the sky.</span
        >
        This pronation of the forearm
        <span class="accent1"
          >allows you to set the club shaft and the clubhead behind you while
          keeping it moving on the same plane as your arm and shoulders.</span
        >
        Practice this pronating move in front of a mirror as you complete the
        final one-third of the backswing.
      </div>
      <div class="p-mb-2">
        We've been talking only about the movement of your left arm to this
        point. As for your <span class="accent1">right or rear arm,</span> it
        should be folding up and behind you, with the
        <span class="accent1"
          >elbow moving along a plane parallel to the turn of your torso and
          your shoulders.</span
        >
        Your right elbow, incidentally,
        <span class="accent1"
          >should stay slightly away from the body until you reach the top of
          the backswing. At this point the pronating movement of your left arm
          may bring the right elbow in closer.</span
        >
        However, you should not pin your elbow tightly against your side, as you
        may have read in other publications. Pinning your elbow to your side
        could force your left arm to swing on too flat a plane. By the same
        token, the right elbow should not point directly downward at the top of
        the backswing. Rather, it should point out and away from the body or
        behind you. Ideally,
        <span class="accent1"
          >the right arm, from the elbow to the shoulder, should be on a line
          pointing behind you that mirrors the bent-over angle of the
          spine.</span
        >
        In fact, if you are going to make a mistake, I'd much rather see you
        point the right elbow more behind the body. When the elbow points well
        away from you, it's referred to in teaching circles as a "flying right
        elbow," which most teachers will tell you is a serious flaw. It isn't a
        serious flaw, though, and—as noted—some pretty fair professional
        "hybrid" swingers exaggerate the flying right elbow position—John Daly,
        Fred Couples, Jack Nicklaus in his prime years, Miller Barber, Gay
        Brewer, and even Byron Nelson. I believe it is much easier to drop the
        right arm slightly at the start of the downswing (as you would if the
        elbow flies a bit) than it is to lift it free of the body after having
        kept it in tightly throughout the backswing. But, again, keeping the
        right arm and elbow slightly free of the body and pointing behind you at
        least on the same line as the angle of your spine rather than straight
        down would be best of all.
      </div>
      <div class="p-mb-2">
        "I feel my right elbow go immediately up and behind me, pulling my left
        arm into my chest," says PGA Tour player Tom Pernice, Jr. "It feels like
        my arms are far more inside and narrower than ever before."
      </div>
      <div class="p-mb-2">
        One last word on the right arm: Many golfers
        <span class="accent1"
          >cock the club too upright, off plane, with the right arm and hand as
          they pronate the left arm</span
        >. Despite the efforts of the left arm to set the club on plane, the
        right arm and hand do not cooperate and instead
        <span class="accent1"
          >lift the club's shaft above the plane and "across the line."</span
        >
        For these golfers, I suggest they
        <span class="accent1"
          >also rotate the right forearm and wrist clockwise while rotating the
          left forearm. The feeling will be that the back of the right hand is
          turning and facing directly away from the target at the top of the
          backswing</span
        >. A note of caution: Too much rotation of either forearm can result in
        the opposite condition, that of a club that can be too far beneath the
        plane, or "laid off." However, if the club is not exactly right and on
        plane, then it is preferable for a one-plane swinger to have the club
        pointed slightly left.
      </div>
      <div class="p-mb-2">
        <span class="accent1"
          >"After my left arm is pinned to my chest... I complete my backswing
          by turning my right wrist and forearm clockwise to set the club on
          plane,"</span
        >
        says PGA Tour player Brad Faxon. "I feel very low and deep with my arms,
        and laid off too."
      </div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
    </div>
  </Panel>
  <Panel header="Hips" :toggleable="true" :collapsed="true">
    <img src="@/assets/images/hardy1/hardyHips1.png" alt="hardyArms1" />
    <div class="accent1 p-mb-2">
      In swinging back, the one-plane swinger should turn the shoulders fairly
      vigorously while limiting hip turn, promoting torque and power.
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        The role of the hips in the one-plane swing is very different from their
        role in the two-plane swing. The one-plane swing actually loads torque
        from a maximum shoulder turn against the lower torso and hips, which you
        would like to have remain as stable as possible. For an effective and
        powerful one-plane swing, you
        <span class="accent1"
          >need to turn your shoulders at least 90 degrees, while at the same
          time limiting the turning of your hips to 45 degrees or less.</span
        >
        This would give you a differential of at least 45 degrees between the
        amount of your shoulders' turn and that of your hips.
      </div>
      <div class="p-mb-2">
        You are coiling your upper body against your lower body like a spring.
        If you do this correctly, not only will you build tremendous power, but
        you will also find that the downswing begins automatically with the
        release of this pent-up tension between your upper and lower torsos, as
        well as your hips. When the hips do turn, they are somewhat affected by
        the movement of the shoulders, even though the hips and shoulders are
        not on the same plane. The hips are above your legs and on a fairly
        level plane. The shoulders are on a much steeper plane. Furthermore, the
        steeper turning of the shoulders around the bent-over spine will pull
        the hips slightly out of a level position as they turn.
      </div>
      <div class="p-mb-2">
        The <span class="accent1">hips end up on a tilted plane,</span> which is
        <span class="accent1"
          >about halfway between that of the shoulders and level to the
          ground.</span
        >
        <span class="accent1">
          As the left hip turns, it goes down slightly</span
        >
        in the backswing. This is a
        <span class="accent1">result of the dominating shoulder turn.</span> Do
        not fight this natural tilt.
        <span class="accent1"
          >Allow the hips to be influenced by the shoulders rather than actually
          forcing the hips into a turn.</span
        >
        As you swing, practice keeping the hips subject to the turn of the
        shoulders. The more you work at it, the greater the tension you will
        build into your backswing turn.
      </div>
      <div class="p-mb-2">
        A word is necessary here about the role of the right leg during the
        backswing. As you execute the one-plane backswing, keep your right or
        rear leg angled inward at least as much as it was angled in at address.
        You never want your right leg to sway or buckle outward as you continue
        to swing back. This is a very common flaw among golfers of all ability
        levels, but it is particularly damaging to the one-plane swinger. That's
        because when your right leg sways back, your lower body tends to go with
        it and your upper body often leans left. When this happens, you'll
        drastically narrow the width of the backswing. Remember, in the
        one-plane swing you do not want extra width. However, you do not want to
        make a move, even if it is a narrowing one that would drastically affect
        the swing. Sometimes when the lower body moves to the right the upper
        body moves to the right along with it. When that hap­pens you get even
        wider and a one-plane swing that already tends to be too wide now
        becomes so wide and shallow that you'll be prone to hitting a weak, fat
        shot or a low-flying, thin shot. Sometimes too much grass will intervene
        between the clubhead and the ball on fairway shots, resulting in a
        flyer. When you hit one of these shots, you lose control of your
        distance. So
        <span class="accent1"
          >keep your right leg angled in, which will help keep your hips
          centered over the ball throughout the backswing motion.</span
        >
        If anything, I would rather see the one-plane swinger increase the
        amount that his or her right leg is angled in. This is unusual advice, I
        know, because too much of an increase in this angle can sometimes result
        in a reverse weight shift at the top. But I firmly believe you're better
        off with a tiny bit of reverse weight shift than you would be if you
        allowed that right leg sag outward.
      </div>
      <div class="p-mb-2">
        Let me make one other point about the right leg. As a one-plane swinger,
        it's mandatory that during the backswing you maintain the same slight
        flex in your right knee that you started with at address.
        <span class="accent1"
          >The knee can straighten up a little but never allow it to fully
          straighten and lock.</span
        >
        Allowing your right knee to straighten can negatively affect your
        ability to turn in the downswing. It's very helpful, by the way, to have
        a spotter behind you, watching your right knee as you practice your
        backswing. If your spotter kneels behind you so his or her eyes are
        nearly level with your knee, then he or she will be more likely to
        detect it if your right knee straightens or locks. It also helps if you
        wear shorts during this exercise, so the viewer can get a perfectly
        clear view of what the knee joint is doing as you swing back.
      </div>
      <div class="p-mb-2"></div>
    </div>
  </Panel>
  <Panel header="Spine" :toggleable="true" :collapsed="true">
    <img src="@/assets/images/hardy1/hardySpine1.png" alt="hardySpine1" />
    <div class="accent1 p-mb-2">
      Ideally, during the backswing, the one-plane golfer's spine angle should
      remain constant and centered, with a limited amount of weight being
      transferred to the right foot and leg. In fact, even when reaching the
      top, weight should be balanced on both feet or favor the left foot.
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        In the one-plane backswing, it is
        <span class="accent1"
          >critical that your spine remains centered over the ball
        </span>
        and
        <span class="accent1">does not move upward.</span>
        When viewed face-on, the spine should never drift to the right as you
        swing back, which is a very common flaw, even among professional
        golfers. In fact, with this swing method I would even say that it's all
        right if your spine were to tilt just slightly in the opposite
        direction, that is, toward the target. Many teachers would argue that
        any tilting of the spine toward the target, no matter how small, results
        in a reverse weight shift, and technically they would be correct.
        However, I contend that a slight spine tilt to the left as you reach the
        top is not harmful, as again this serves to limit the width of your
        swing. I might add that none other than Ben Hogan, widely agreed to have
        the most efficient swing of his era (if not of all time), had the
        slightest of spine tilts toward the target at the top of his backswing.
        Among modern-era players, David Duval in his prime had some target-ward
        spine tilt at the top. So you'll be in the best shape if, during the
        entire backswing, you keep your spine as close to centered with no tilt
        right. And, incidentally,
        <span class="accent1"
          >when you accomplish this, your head will remain centered above your
          stance, with your shoulders turning around the fixed point at the nape
          of your neck.</span
        >
      </div>
      <div class="p-mb-2">
        What about the forward-bending angle of the spine during the backswing,
        as viewed from down target? You would like to keep the spine angle
        constant throughout the swing, of course. For one-plane players, it is a
        critical error to raise your head and decrease your spine angle on the
        backswing. If you do, it will cause your shoulder plane to become too
        flat or outside the shoulder plane zone. You must then lower your spine
        angle on the downswing precisely as much as you raised it going back.
      </div>
      <div class="p-mb-2">
        Before moving on, let me make sure you understand where I'm going with
        my instructional logic.
        <span class="accent1"
          >Raising the spine angle during the swing is probably the most harmful
          mistake the one-plane player can make, whereas lowering the spine or
          slightly increasing the spine angle is okay.</span
        >
        The great Ben Hogan lowered his spine angle throughout the backswing and
        then just a touch more as he reached the top. This slight downward move
        allowed him to turn his shoulders within the 48-inch zone.
      </div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
    </div>
  </Panel>
  <Panel header="Weight Distribution" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        You hear a lot of discussion about how the weight should shift from its
        distribution at address, up to the top of the backswing, and then down
        through impact. You may have heard advice that, by the top of your
        backswing, you should have shifted 70, 80, or even 90 percent of your
        weight onto your right or rear foot. Well, this may come as a surprise
        to you, but as a one-plane swinger
        <span class="accent1"
          >you should not be trying to shift very much weight at all to your
          right side on the backswing.</span
        >
        The reason: Your one-plane backswing, if properly executed, will give
        your swing arc plenty of width because the one-plane swing, as discussed
        earlier, inherently has width. Usually, when you shift your weight to
        the right, your legs and midsection, and possibly even your shoulders
        and head, will slide farther away from the target. This increases your
        width, which is something the one-plane swing does not lack to begin
        with.
        <span class="accent1"
          >An exaggerated weight shift, a lateral slide to the right, and
          getting behind the ball are harmful for a one-plane swinger.</span
        >
      </div>
      <div class="p-mb-2">
        At the <span class="accent1">top of the backswing,</span> your weight
        should be
        <span class="accent1">close to evenly distributed</span> between the
        feet. Personally, I'd rather see you keep a
        <span class="accent1"
          >little more than half your weight on your left foot as a protection
          against adding width.</span
        >
        Remember, your swing arc naturally has ample width. You don't need to
        increase your width by moving onto your right foot as you go back. You
        should feel that your
        <span class="accent1"
          >weight is more or less on the balls and insides of both feet,</span
        >
        which creates stability in your lower body, the area that is the swing's
        structural foundation.
      </div>
      <div class="p-mb-2">
        You might find this advice surprising, but it really shouldn't be. These
        days there are a number of swing measurement devices that can, among
        other things, measure your weight distribution at various points in the
        swing. A fair number of PGA Tour players have had their weight
        distributions measured on these devices. I think you would be surprised
        to find that a great many Tour pros show a weight distribution at the
        top of the backswing that's very close to 50-50, with some slightly
        favoring the left or forward foot.
      </div>
      <div class="p-mb-2">
        If you need any further convincing about what's really right about
        weight distribution on the backswing, let me give you an ideal one-plane
        model to mimic: Ernie Els. At the top, Els keeps his weight centered
        beautifully.
      </div>
    </div>
  </Panel>
  <Panel header="Movement of the Club" :toggleable="true" :collapsed="true">
    <img src="@/assets/images/hardy1/hardyClub1.png" alt="hardySpine1" />
    <div class="accent1 p-mb-2">
      Early on in the one-plane swing, the club swings inside—automatically—as
      the right elbow moves up and back and the shoulders rotate clockwise.
    </div>
    <img src="@/assets/images/hardy1/hardyClub2.png" alt="hardySpine1" />
    <div class="accent1 p-mb-2">
      This is a good toe position, at this point of the swing, for a one-plane
      swinger.
    </div>
    <img src="@/assets/images/hardy1/hardyClub3.png" alt="hardySpine1" />
    <div class="accent1 p-mb-2">
      At the top, the one-plane swinger's club should be parallel to the target
      line and is acceptable if it is slightly laid off.
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        We've now discussed all the movements your body should make in the
        one-plane backswing. What about the club itself? If you've moved your
        arms and body correctly, the club will move into the desired positions
        as the backswing progresses. Of course, you need to know what these club
        positions are and check them frequently. If you find that the club is
        out of place, you'll need to go through the arms and body motions so you
        can determine where you started to go awry.
      </div>
      <div class="p-mb-2">
        On the <span class="accent1">takeaway,</span> for the one-plane swing,
        the clubhead should
        <span class="accent1">not remain on the target line </span>once it has
        started moving back. If you are turning your shoulders on plane, with
        your left arm snugly against the chest, the clubhead cannot stay along
        the target line. To keep it anywhere near the target line, you would
        have to push your arms out away from you, or tilt your shoulders instead
        of turning them, or both. So, let the
        <span class="accent1">clubhead move naturally to the inside.</span>
      </div>
      <div class="p-mb-2">
        A key checkpoint comes when the
        <span class="accent1">club shaft has reached hip height.</span> At this
        point, the shaft should be
        <span class="accent1"
          >parallel to the target line and parallel to the ground.</span
        >
        Ask someone to stand behind you and monitor your club shaft position as
        it appears from directly down the target line. The club shaft should
        <span class="accent1"
          >fall over a line down to the insteps or the center of the feet.</span
        >
        (This is quite different from the position of the two-plane player, as
        we'll see later.) The club shaft should also be moving on an arc or path
        a little closer to the body, because this swing is moving on a flatter
        plane and the arms have already come into the chest. Also, the
        <span class="accent1"
          >clubhead's toe should point halfway between the target line and
          straight up, similar to the angle of your spine.</span
        >
        This slightly closed position is necessary because the forearms have not
        yet begun to pronate.
      </div>
      <div class="p-mb-2">
        If anything, I'd rather see you err toward an even more closed clubface,
        meaning the face would point a bit downward. It allows you to get the
        left arm closer to your chest even sooner. You can easily adjust the
        club-face direction from this point in the swing to the top by rotating
        your forearms more to open the face.
      </div>
      <div class="p-mb-2">
        <span class="accent1">As the backswing continues,</span> the plane your
        clubhead moves on will be somewhat wider and flatter to the ground than
        you may be accustomed to. Don't worry—this is what you're looking for.
        If you turn your shoulders along the angle you set them on at address,
        the
        <span class="accent1"
          >club shaft will work its way behind your right shoulder and up onto
          the plane.</span
        >
      </div>
      <div class="p-mb-2">
        As to where the club shaft should be pointing at the
        <span class="accent1">top of the backswing,</span> this is something
        that a lot of amateurs, and even their teachers, get tricked by. So
        let's clearly define our terms. At or near the top of the backswing, we
        want to check where the club shaft is pointing
        <span class="accent1">when the club is parallel to the ground.</span>
        What confuses many golfers is that
        <span class="accent1"
          >this particular point is very rarely the precise top of the
          backswing</span
        >
        for any given player. For the majority of golfers, particularly older
        ones who are not extremely supple, the club shaft will never reach
        parallel. Then there is a large minority of top golfers, namely John
        Daly, who swing the club beyond parallel at the top.
      </div>
      <div class="p-mb-2">
        This might be a good time to review that neither the length of a
        person's swing nor the overall speed or pace of it, are specifically
        tied to either a one-plane or two-plane swing. Length of swing and pace
        of swing are individual traits. A one-plane swing can be long or short,
        fast or slow. The same holds true for the two-plane swing.
      </div>
      <div class="p-mb-2">
        At any rate, in order to compare apples to apples, we should not judge
        where the club shaft is pointing by the spot at which a golfer completes
        his or her backswing. Rather, for the golfer with the compact backswing,
        we must, in effect, extrapolate to make his or her swing longer, and
        then
        <span class="accent1"
          >judge where the club shaft would be pointing if it reached a position
          parallel to the ground.</span
        >
      </div>
      <div class="p-mb-2">
        To most golfers who do not reach parallel at the top, it will appear
        that their club shaft is pointing well to the left of the target line in
        what is known as a "laid off" position. It may be, but the point is that
        if the club shaft had reached parallel, it could very well have been
        pointing at the target. For the player who swings beyond parallel, the
        problem is easier to solve. They simply need to
        <span class="accent1"
          >stop the backswing at the point where the shaft reaches
          parallel,</span
        >
        and then have someone observe from behind to see if it is pointing at
        the target. If you were to simply look at their position upon completion
        of the backswing, you would think the club shaft is pointing well right,
        in what teachers call the "cross the line" position.
      </div>
      <div class="p-mb-2">
        That stated, at the point in the swing described,
        <span class="accent1"
          >the ideal is to have the club shaft pointing parallel to your target
          line.</span
        >
        For the one-plane player, however, I would like to see
        <span class="accent1"
          >any error being on the side of having the club shaft pointing a
          little left of the target, or being a bit laid off.</span
        >
        The reason I prefer that one-plane players be a bit laid off is that,
        from this position, they have the club well behind them and they can
        turn their torsos as hard as they like on the downswing and never swing
        down over the top. From a slightly laid-off position, all it really
        means is that
        <span class="accent1"
          >the left forearm and the club will rotate a little harder back down
          to the correct impact position</span
        >
        as you return your shoulders and arms back down and through the ball.
      </div>
      <div class="p-mb-2">
        As to the position of the clubface itself, the one-plane player should
        have the clubface in a square position at the top, in relation to his or
        her swing plane. However, it's okay for the face to be slightly closed
        or shut in relation to your plane.
      </div>
      <div class="p-mb-2">
        Let's define this square position clearly, as it's often a point of
        confusion. First, when checking the position of your clubface at the
        top, it's best to do it with a driver. This is your least-lofted club,
        so there is the least distortion in judging which way the
        <span class="accent1">clubface</span> is pointing. A clubface that's
        square to your plane would be
        <span class="accent1"
          >halfway between pointing to the sky and pointing perpendicular to the
          ground.</span
        >
        The clubface is completely closed to your plane if it's pointing
        straight up to the sky. The clubface is completely open if the toe is
        hanging straight down, and the clubface points perpendicular to the
        ground. So, if you're a one-plane swinger, you want your driver's
        clubface to be pointing halfway between the sky and perpendicular to the
        ground or, if anything, angled closed so that it's pointing a bit more
        toward the sky. Let me explain this last point further.
      </div>
      <div class="p-mb-2">
        I believe a square-to-closed face balances with the flatter, more
        rounded plane of the one-plane golf swing. That's because a shut face
        requires less forearm rotation in the downswing to return the clubface
        square at impact. All that said, if your clubface is square to your
        swing plane or just a little closed at the top, you're in a very
        desirable position to execute the one-plane downswing.
      </div>
      <div class="p-mb-2">
        The final thought regarding the one-plane backswing concerns the
        <span class="accent1">sequence of movements.</span>The
        <span class="accent1">arms should begin to move first.</span>
        Specifically,
        <span class="accent1"
          >the left arm presses into and crosses the chest while the right elbow
          starts up and behind you.</span
        >
        Once the arms begin to move,
        <span class="accent1">then the shoulders begin to turn</span>, and
        <span class="accent1">then the hips.</span> Each move
        <span class="accent1"
          >follows the start of the preceding move and blends in
          seamlessly</span
        >
        to the completion of the backswing.
      </div>
    </div>
  </Panel>
  <Panel header="Review" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        <span class="accent1">Shoulders:</span> The shoulders turn on a plane
        such that, <span class="accent1">at the top</span> of the backswing,
        <span class="accent1"
          >a line drawn across the top of the shoulders should point</span
        >
        within a zone
        <span class="accent1"
          >between the ball and a point four feet outside</span
        >
        or beyond the ball. The
        <span class="accent1">head should remain centered</span> in the stance,
        and the
        <span class="accent1"
          >shoulders should be turned fully, 90 degrees</span
        >
        or more from their position at address. WARNING Do not let the plane of
        your shoulders wander toward a point more than 48 inches outside the
        ball, or else your backswing shoulder plane will become much too flat.
      </div>
      <div class="p-mb-2">
        <span class="accent1">Arms:</span> The
        <span class="accent1">left arm </span> swings in and
        <span class="accent1"
          >is pulled directly across the chest by the retracting right elbow
          moving up and back.</span
        >
        The upper left arm stays tight to the chest. Near the top of the
        backswing,
        <span class="accent1"
          >it turns (pronates) with the top of the forearm facing somewhat
          upward.</span
        >
        The
        <span class="accent1"
          >right elbow folds, moving up on a line parallel to the plane.</span
        >
        The right arm also
        <span class="accent1">stays sufficiently away from the body.</span> Both
        arms swing around the body, toward the rear, as opposed to remaining in
        front of the chest. <span class="accent1">At the top,</span> the
        <span class="accent1"
          >left arm should be very close to the same plane as the
          shoulders.</span
        >
        The upper part of the
        <span class="accent1">right arm (from elbow to shoulder)</span> should
        be at least
        <span class="accent1">parallel to the angle of the spine,</span> with
        the
        <span class="accent1">right elbow pointing behind the player.</span>
        WARNING Do not let the left arm swing above the plane of the shoulder
        turn.
      </div>
      <div class="p-mb-2">
        <span class="accent1">Hips:</span> Hips
        <span class="accent1">stay centered</span> in the stance or may move
        slightly left during the backswing. WARNING Do not let the hips turn
        more than 45 degrees.
      </div>
      <div class="p-mb-2">
        <span class="accent1">Spine Angle</span> The angle of the spine must
        <span class="accent1"
          >either stay the same as at address or increase slightly (tilt the
          spine more downward and out).</span
        >
        The spine
        <span class="accent1">stays centered over the lower body</span> or, if
        anything, may tilt a bit toward the target. WARNING Do not allow the
        angle of your spine to raise up or to the right on the backswing. This
        movement increases the width of the swing arc unnecessarily, flattens
        the shoulder turn, and decreases the tension between the shoulders and
        the hips.
      </div>
      <div class="p-mb-2">
        <span class="accent1">Weight Distribution:</span> The weight should be
        close to <span class="accent1">centered</span> between the feet at the
        top of the backswing. WARNING Do not allow the majority of your weight
        to shift onto your rear foot. This makes the one-plane swing motion too
        wide and too shallow.
      </div>
      <div class="p-mb-2">
        <span class="accent1">Movement of the Club:</span>
        <span class="accent1"> Takeaway</span> is to the
        <span class="accent1">inside. </span>
        <span class="accent1">Halfway back,</span> the club shaft is
        <span class="accent1">parallel</span> to the
        <span class="accent1">target line</span> and parallel to
        <span class="accent1">the ground</span> and directly above a line
        running through the
        <span class="accent1">center of the feet. </span>
        <span class="accent1">At the top,</span> the club shaft should
        <span class="accent1"
          >point parallel to the target line or slightly left</span
        >
        of parallel (laid off). The
        <span class="accent1"
          >shaft is slightly above and behind the point of the right
          shoulder.</span
        >
        The <span class="accent1">clubface</span> is either
        <span class="accent1">square to the swing plane</span> or slightly
        closed (with the clubface pointing slightly more toward the sky).
        WARNING Do not let the club cross the line at the top of backswing or
        get the clubface into an open position at the top. Either flaw calls for
        substantial manipulation of the club on the downswing of a one-plane
        swing.
      </div>
    </div>
  </Panel>
</template>
