<template>
  <div class="fontSmall taLeft">
    <img src="@/assets/images/hardy2/fig9.24.png" class="img10" alt="Alt" />
    <div class="accent1 fontSmall p-mb-2">
      FIG. 9.24. The shaft/Clubhead Mistake in-to-out has the left arm dropping
      down correctly so the right forearm and handel is on the shaft plane line.
      Unfortunately the right wrist is not throwing both early enough and far
      enough and as a result the clubhead is coming down well under the plane
      line and exiting impact well in from of it.
    </div>
    <img
      src="@/assets/images/hardy2/mistakesText4.png"
      class="img10"
      alt="Alt"
    />
    <img
      src="@/assets/images/hardy2/mistakesText5.png"
      class="img10"
      alt="Alt"
    />
    <img
      src="@/assets/images/hardy2/mistakesText6.png"
      class="img10"
      alt="Alt"
    />
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
  </div>
</template>
