<template>
  <Panel header="Introduction" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        FINESSE WEDGE SKILL DEVELOPMENT AND ADAPTATIONS Being able to see a
        shot, judge your lie, and apply the appropriate adjustments so you can
        pull it off is truly the “art of golf.”
      </div>
      <div class="p-mb-2">
        Successfully applying the finesse wedge fundamentals presented thus far
        will certainly result in great contact, but there’s a lot more to being
        great than that. What happens when you have an odd lie or need to change
        the trajectory? What happens when your solid shots roll too far past the
        pin? Beyond having great technique, great wedge players know how to
        select the appropriate shot, visualize landing spots, manipulate shot
        trajectory, as well as effectively judge and adapt to different lie
        conditions. These are skills—the talented application of knowledge and
        advanced moves that build on the five fundamentals and allow you to
        handle anything the course throws at you. Here are the keys to
        developing them and transforming your finesse wedge swing into a
        legitimate scoring weapon.
      </div>
    </div>
  </Panel>
  <Panel header="Trajectory Control" :toggleable="true" :collapsed="true">
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseAdaptation1.png"
      class="img3"
      alt="Sieckmann Adaptation"
    />
    <div class="accent1 fontSmall p-mb-2">
      Change trajectories by switching to a higher- or lower-lofted club, or
      vary the shaft lean and face angle of the clubhead at address. Whatever
      adaptions you choose, there’s only one arm motion, and it always stays the
      same.
    </div>
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseAdaptation2.png"
      class="img3"
      alt="Sieckmann Adaptation"
    />
    <div class="accent1 fontSmall p-mb-2">
      Change trajectories by switching to a higher- or lower-lofted club, or
      vary the shaft lean and face angle of the clubhead at address. Whatever
      adaptions you choose, there’s only one arm motion, and it always stays the
      same.
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Within the structure of my Finesse Wedge System are two effective ways
        to control trajectory or hit the “trajectory window” you visualize in
        your pre-shot routine. The first and most obvious one is to vary your
        club selection. All things being equal, less-lofted clubs (your 9-iron,
        for example) produce lower trajectories and less spin than higher-lofted
        clubs (i.e., your sand wedge). In addition, you can create different
        trajectories with the same club by making changes to your setup. By
        moving the ball back in your stance and keeping your hands hanging over
        your zipper, the shaft will lean more toward the target. This reduces
        loft, which lowers trajectory. The opposite happens when you move the
        ball up in your stance while keeping your hands hanging in the same
        place, the shaft will move vertically, creating more loft and a higher
        trajectory. (For even higher shots, you can rotate the clubface open as
        well.) This will give you a minimum of three trajectories with each
        club. The good news is that the fundamental rules of the finesse wedge
        swing you’ve already learned still apply. Regardless of the club you use
        or the trajectory you choose, you always set up for finesse, swing on
        plane with a finesse sequence, and release the club with your energy
        flowing toward the target.
      </div>
      <div class="p-mb-2">
        Notice in the photos on the preceding page how shaft lean decreases and
        effective loft increases as I move the ball from off my back foot (frame
        1, lower than normal shot) to up between my toes (frame 3, higher than
        normal shot). An important key is that the more forward you play the
        ball in your stance, the more you need to open your chest to the target
        at address so that your sternum is always pointing to a spot on the
        ground a few inches in front of the ball. This helps get the low point
        of your swing in front of the ball, even with a forward ball position.
      </div>
      <div class="p-mb-2">
        For an extremely high shot (preceding page, bottom right image), create
        maximum loft by setting up as though you’re hitting a bunker shot (this
        will be covered in Chapter 10). Stand farther away from the ball, widen
        your stance and lower your hands to almost knee height. As you drop your
        hands, allow the clubface to rotate open. Even though it’s a completely
        different stance than what you’ve seen so far, it requires the same
        finesse wedge motion as the others (and perhaps a longer, faster arm
        swing).
      </div>
      <div class="p-mb-2">
        Watch how PGA Tour Player Cameron Tringale varies finesse wedge
        trajectory in a special video. Visit
        jsegolfacademy.com/index.php/cameron-tringale.
      </div>
    </div>
  </Panel>
  <Panel header="Distance Control" :toggleable="true" :collapsed="true">
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseAdaptation3.png"
      class="img3"
      alt="Sieckmann Adaptation"
    />
    <div class="accent1 fontSmall p-mb-2">
      SWING LENGTH VS. ENERGY Alter swing length—not its rhythm—to vary shot
      distance: Make a longer swing for more energy on longer wedge shots and a
      shorter swing for less energy on shorter wedge shots. Always match the
      length of your backswing with the length of your throughswing.
    </div>
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseAdaptation4.png"
      class="img3"
      alt="Sieckmann Adaptation"
    />
    <div class="accent1 fontSmall p-mb-2">
      SWING LENGTH VS. ENERGY Alter swing length—not its rhythm—to vary shot
      distance: Make a longer swing for more energy on longer wedge shots and a
      shorter swing for less energy on shorter wedge shots. Always match the
      length of your backswing with the length of your throughswing.
    </div>
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseAdaptation5.png"
      class="img3"
      alt="Sieckmann Adaptation"
    />
    <div class="accent1 fontSmall p-mb-2">
      SWING LENGTH VS. ENERGY Alter swing length—not its rhythm—to vary shot
      distance: Make a longer swing for more energy on longer wedge shots and a
      shorter swing for less energy on shorter wedge shots. Always match the
      length of your backswing with the length of your throughswing.
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        In the short game, it’s relatively easy to hit it straight (unlike the
        power game), so the most valuable skill is learning to control your
        distance. Distance control, or “touch,” is a function of the effective
        loft of the club at impact, the impact conditions, and the player’s
        ability to sense how much energy must be imparted to the ball to get it
        pin high. There’s a certain level of judgment involved, but in technical
        terms you control energy with swing length and rhythm. When you swing in
        rhythm, your backswing and the downswing match on both sides of impact,
        you appear flowing and graceful (the finesse sequence will help), and it
        will take the same amount of time to complete your swing regardless of
        its length. As such, your short wedge swings—for shorter finesse wedge
        shots—will feel “slower” than your big wedge swings (i.e., the ones you
        use for longer finesse wedge shots).
      </div>
      <div class="p-mb-2">
        Every golfer has his or her innate rhythm. What’s good for me or for one
        of my Tour players may not be good for you. There isn’t one “perfect”
        pace. Some players are naturally brisk; Tom Watson and Brandt Snedeker
        come to mind. Others, like Steve Stricker, are more languid. Your rhythm
        is your own, and it should never change. To find it, repeat the
        One-and-Two Drill (here), saying “one-and-two” in a rhythmic manner
        while you swing. Having great rhythm allows you to swing past the ball
        and not “hit” at it. It’s the key to developing great touch.
      </div>
      <div class="p-mb-2">
        Given the lie conditions, the effective loft of the club you’ve chosen,
        and your natural rhythm, there’s only one swing length that’ll impart
        the correct amount of energy to the ball to stop it pin high. To find
        it, get lost mentally in the shot as you set up. Look at the target
        while swinging rhythmically next to the ball, and establish a feel for
        the swing length that matches the energy your mind tells you is
        necessary for the shot at hand. Once you have it, step in and react to
        that picture.
      </div>
    </div>
  </Panel>
  <Panel header="Rough Control" :toggleable="true" :collapsed="true">
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseAdaptation6.png"
      class="img3"
      alt="Sieckmann Adaptation"
    />
    <div class="accent1 fontSmall p-mb-2">
      To extricate your ball from a clean lie in the rough, use your standard
      finesse wedge setup and swing. To hit it crisply from a fluff lie, you’ll
      need to adjust your setup to create a steeper angle of attack and add
      energy.
    </div>
    <h4>HOW TO FINESSE FROM DEEP ROUGH:</h4>
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseAdaptation7.png"
      class="img3"
      alt="Sieckmann Adaptation"
    />
    <div class="accent1 fontSmall p-mb-2">
      STEP 1: Choke down, widen out, and “lean” your weight into your lead thigh
      (all other finesse fundamentals apply).
    </div>
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseAdaptation8.png"
      class="img3"
      alt="Sieckmann Adaptation"
    />
    <div class="accent1 fontSmall p-mb-2">
      STEP 2: These setup adjustments create a steeper, narrower backswing arc.
    </div>
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseAdaptation9.png"
      class="img3"
      alt="Sieckmann Adaptation"
    />
    <div class="accent1 fontSmall p-mb-2">
      STEP 3: Maintain the pressure in your lead leg as you deliver the club
      with a steeper angle of attack. This will minimize grass interference and
      produce crisp ball/club contact.
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        HOW TO ASSESS LIES IN THE ROUGH Even though every situation is
        different, there are essentially two types of lies in the rough: “clean”
        lies and “fluff” lies. As the descriptions indicate, a clean lie allows
        you to get the clubface on the back of the ball with no real grass
        interference—like a shot from the fairway. In this case, no adaptation
        is needed and you can play a normal shot.
      </div>
      <div class="p-mb-2">
        Fluff lies are a different matter, and the trick is determining how much
        the grass behind the ball will influence the shot. USGA rules forbid you
        from testing the surface around your lie, so you can’t press your club
        into the ground or push grass away from the ball. You can, however, set
        your club lightly behind the ball and “measure the fluff.” This act will
        make it fairly easy to determine if you’ll be able to get the clubface
        cleanly on the back of the ball and, if not, the amount of grass that
        will come between the clubface and the ball at impact. Judging how much
        influence the fluff will have on an ensuing shot is critical to
        performance. Use the following criteria: 1. Check for Moisture Seve
        Ballesteros taught my brother, Tom, to determine the moisture content of
        the grass around and underneath the ball. The wetter the grass, the more
        energy it will take out of your swing, mandating a longer, faster
        motion. The dryer the grass, the less it will affect the energy. 2.
        Check the Grain If the grain (the direction in which the grass is
        growing) is lying in your favor (toward the target), then the ball
        should fly out normally, as if from a clean lie. If the grain direction
        is coming toward you, it will exert a dramatic effect and require a
        longer swing and more energy. When I’m out coaching on Tour and locate a
        spot around the short-game practice area with this kind of
        into-the-grain fluffy lie, I’ll bet the player $5 that they come up
        short. Ultimately, it’s a good lesson for them, and it never hurts to
        have a little extra gas money on my end. 3. Check the Density Density
        deals with both the thickness of the grass blades and how close they’re
        growing together, which is species dependent. Thick grasses such as
        Kikuyu, fescue, Bermuda, and bent (which often seeps from the green and
        fairway into the rough) sap the most energy from your intended swing.
        Less dense grasses such as bluegrass and winter rye have less of an
        effect.
      </div>
      <div class="p-mb-2">
        Once you determine that you have a fluff lie and consider the factors
        regarding the severity of its influence, you need to counteract it by
        steepening your angle of attack, which encourages the club to work up
        the plane more abruptly. How do you steepen the angle? Easy—create it
        with your address position by making the following adjustments: widen
        your stance, choke down on the club to the bottom of the grip, and lean
        a little extra weight into your lead thigh, which will allow your upper
        body and the handle of the club to slide toward the target. (The
        parameters for controlling trajectory remain the same.) Creating angle
        will make a huge difference in the quality of your contact, but you may
        also have to ramp up the energy depending on the severity of the lie by
        making a bigger swing. Remember, in the finesse game, you increase swing
        length, not rhythm, to add energy. Don’t confuse “bigger swing” with
        “hit it harder.”
      </div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
    </div>
  </Panel>
  <Panel header="Slope Control" :toggleable="true" :collapsed="true">
    <h3>HOW TO FINESSE FROM A DOWNSLOPE:</h3>
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseAdaptation10.png"
      class="img3"
      alt="Sieckmann Adaptation"
    />
    <div class="accent1 fontSmall p-mb-2">
      HOW TO FINESSE FROM A DOWNSLOPE:STEP 1: Widen your stance, lean into your
      lead leg, and choke down on the handle. STEP 2: Let the club work up more
      abruptly in your backswing. A swing that’s too shallow will lead to dumpy
      little chunks or skulled shots across the green. STEP 3: Shift your swing
      plane a tad out-to-in by thinking “fade.” STEP 4: Maintain pressure into
      your lead thigh the whole way.
    </div>
    <h3>HOW TO FINESSE FROM AN UPSLOPE:</h3>
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseAdaptation11.png"
      class="img3"
      alt="Sieckmann Adaptation"
    />
    <div class="accent1 fontSmall p-mb-2">
      (HIT THE “RAYMOND FLOYD”): STEP 1: Open your stance more than normal.
    </div>
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseAdaptation12.png"
      class="img3"
      alt="Sieckmann Adaptation"
    />
    <div class="accent1 fontSmall p-mb-2">
      STEP 2: Take the club back a little to the inside.
    </div>
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseAdaptation13.png"
      class="img3"
      alt="Sieckmann Adaptation"
    />
    <div class="accent1 fontSmall p-mb-2">
      STEP 3: Think “draw” to create a shallower, inside-out swing that will
      allow you to maintain the ideal angle of attack and catch the ball
      solidly.
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        HOW TO ADJUST FOR SLOPES Creating angle at address isn’t the only way to
        alter your angle of attack. You can also do it by changing your swing
        plane, an asset that comes in handy when you’re faced with the challenge
        of hitting finesse wedges off upslopes and downslopes and with the ball
        either above or below your feet. Delivering the club from inside the
        target line shallows your angle of attack, while swinging outside-in
        (more “across the ball”) steepens it. Depending on the slope on which
        the ball is resting, adjusting the plane one way or the other will make
        a big difference in the quality of your contact.
      </div>
      <div class="p-mb-2">
        1. Downslopes The perfect clubhead angle of attack into the ball is 6
        degrees for a standard lie. If your ball is resting on a downslope with,
        say, a 4-degree grade, you’ll need 10 degrees of attack angle to hit the
        shot crisply (4 degrees for the grade plus the standard 6-degree
        attack). If you fail to make any adjustments to your setup or swing in
        this situation, you’ll come in too shallow, either striking the ground
        behind the ball or skulling the shot across the green. How do you add 4
        degrees of attack angle to maintain the effective angle of 6? You create
        it at address by widening your stance, choking down to the bottom of the
        grip, and leaning down the hill as shown in the photos on the next page.
        In addition, shift your plane a little more out-to-in. On downslopes,
        think “fade” not “draw.”
      </div>
      <div class="p-mb-2">
        2. Upslopes Uphill lies naturally increase your effective angle of
        attack, so if you have a fluff lie you don’t have to do anything more
        than add energy to your swing to hit a successful shot. On a clean lie,
        however, you need to execute with a bit of a “draw” mentality. I call
        this my “Raymond Floyd Shot,” because it mimics his distinct setup and
        swing. Open your stance more than normal at address and make a backswing
        that feels a little inside, or “laid off.” Even though this swing is
        inside-out relative to your body, it’s still delivering the clubhead in
        line with the target, which will help the ball fly straight. The Raymond
        Floyd specialty shot also works well for a ball that happens to be teed
        up in the rough (sitting on top of the grass like a snow cone). In
        addition to swinging with a draw feel, select a less-lofted club. If
        executed properly, it’ll feel like you’re picking the ball off the
        grass.
      </div>
      <div class="p-mb-2">
        3. Sideslopes You’ll also get lies in which the ball is below or above
        your feet. Balls below your feet require a fade mentality in your setup
        and swing. Fades go short, so remember to add a little energy to your
        motion. In addition, widen your stance and get as close to the ball as
        possible. The shaft will sit a little more vertical than normal. That’s
        OK—it’ll stop the heel of the club from catching the turf first.
      </div>
      <div class="p-mb-2">
        For a ball above your feet, face alignment is critical. When you sole on
        the ground with the scoring lines square to the target, the loft of the
        club actually closes the face, despite its appearance. The first
        adjustment is to “aim the face, not the lines on it” at the landing
        area. The face will appear to open and increase in loft as you do this,
        but the ball will fly toward the target. Remember to add energy to your
        swing and shallow your angle of attack by taking the club back a little
        to the inside.
      </div>
      <div class="p-mb-2">
        Desired Angle of Attack on Common Short-Game Lies LIE CONDITION Tight
        Clean Rough Fluff Rough SLOPE Level Neutral Neutral Steep Down Steep
        Steep Steepest Up Shallow Shallow Neutral Ball Above Feet Shallow
        Shallow Shallow Ball Below Feet Steep Steep Steepest
      </div>
    </div>
  </Panel>
  <Panel header="Trouble Control" :toggleable="true" :collapsed="true">
    <h3>The Putt-Chip:</h3>
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseAdaptation14.png"
      class="img3"
      alt="Sieckmann Adaptation"
    />
    <div class="accent1 fontSmall p-mb-2">
      The Putt-Chip: STEP 1: Choke all the way down to the steel on a
      less-lofted iron with the ball a tad back of center. Raise the heel
      section of the club off the ground. STEP 2: Using your big muscles, take
      the club back as though you’re hitting a long lag putt. STEP 3: On your
      throughswing, stay level, use your big muscles, and maintain a nice
      rhythm. STEP 4: Feel as though your arms are “locked in” from start to
      finish.
    </div>
    <h3>The Cock-and-Pop:</h3>
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseAdaptation15.png"
      class="img3"
      alt="Sieckmann Adaptation"
    />
    <div class="accent1 fontSmall p-mb-2">
      The Cock-and-Pop: STEP 1: Take an extra-wide stance and sit, holding extra
      weight in your lead thigh. Lower your hands to near knee-height and lay
      the face open. STEP 2: Hinge the club up using only your wrists. This is
      the “cock.” STEP 3: Throw the clubhead under the ball by unhinging your
      wrists. This is the “pop.” STEP 4: Everything else stays very quiet. This
      shot gives you maximum loft and minimal carry.
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        HOW TO FINESSE YOUR WAY OUT OF TROUBLE When it comes to hitting finesse
        wedges around the green, I want you to feel like you have a shot for
        every conceivable lie and situation, adopt an offensive mindset, and
        then try to hole everything. In addition to your stock finesse wedge
        swing and the adjustments needed to alter trajectory and produce great
        contact from poor lies, it’s helpful to add two additional specialty
        shots to your arsenal.
      </div>
      <div class="p-mb-2">1. The Putt-Chip</div>
      <div class="p-mb-2">
        As the name suggests, this swing is dominated by your big muscles and
        features little hand and wrist action. As a result, it produces a very
        shallow angle of attack and minimal energy, limiting the putt-chip to
        situations that require short carries (less than 7 yards) and clean
        lies. It’s ideal when the fairway is too sticky to putt through or when
        you’re hitting from a lie that’s muddy, sandy, or tight and into the
        grain.
      </div>
      <div class="p-mb-2">
        Use a flatter-faced club such as your 7- or 9-iron (some of my students
        have gotten really good at putt-chipping with a fairway wood) and grip
        it like it’s your putter. You want as much arm hang as possible, so grip
        the club down near where the handle meets the steel. Play the ball a tad
        back of center and lean the shaft two to three degrees toward the
        target, with the heel section of the club raised slightly off the turf.
        (The slight camber of the toe area on the sole will help prevent digging
        through impact). Keep your grip pressure light, but lock in your arms
        and shoulders. To hit the shot, stay level and swing as though you’re
        stroking a long lag putt.
      </div>
      <div class="p-mb-2">
        Your touch on putt-chips (every short-game shot, really) depends on your
        ability to make the proper length swing in rhythm. Experiment with
        different clubs to discover your favorite(s), but refrain from using
        anything with more than 56 degrees of loft. High lofts and extremely
        shallow swings don’t mix.
      </div>
      <div class="p-mb-2">2. The Cock-and-Pop</div>
      <div class="p-mb-2">
        This one is a real “get-out-of-jail-free” shot and is used by all the
        Tour players I coach. The perfect situation for a cock-and-pop shot is
        when the ball is buried in thick rough and you have to carry the ball
        less than 5 yards to a close pin on a firm, fast green, or one that
        slopes away from you. If the first thing that pops into your head when
        assessing a lie is “I’m screwed,” you should opt for the cock-and-pop.
      </div>
      <div class="p-mb-2">
        The cock-and-pop produces an extreme angle of attack, maximum loft, and
        little power—perfect for the situation described above. It’s an odd
        combination that necessitates extreme adaptations to both your setup and
        swing. Start by widening your stance and turning both feet out. It
        should feel as though you’re “sitting” a bit. Setting your feet like
        this aids lower-body stability because it makes it difficult to turn
        your hips. Lean into your lead thigh and choke down to the bottom of the
        grip, as you do on other shots that require a steep angle of attack.
        (Choking down narrows your swing arc, creating a steeper angle as well
        as reducing the energy of the swing. As Seve used to say, “short shot,
        short club.”) Lastly, create maximum loft by laying the face wide open
        so that the back of the club lays flat against the turf.
      </div>
      <div class="p-mb-2">
        To hit the shot, simply hinge the clubhead straight up using only your
        wrists—no arm movement, no shoulder movement, and no hip turn. This is
        the “cock.” On your downswing, unhinge or “pop” the clubhead back under
        the ball using your wrists, with zero hip and chest movement and little
        or no arm swing. Stay down and hold your weight in your lead thigh all
        the way to the finish. It’ll take some practice to get it right, but
        it’s a lot easier to do than to describe.
      </div>
    </div>
  </Panel>
  <Panel
    header="How to Choose Appropriate Shot"
    :toggleable="true"
    :collapsed="true"
  >
    <h3>Shot Selection Effect on Bounce</h3>
    <img
      src="@/assets/images/sieckmannFinesse/sieckFinesseAdaptation16.png"
      class="img3"
      alt="Sieckmann Adaptation"
    />
    <div class="accent1 fontSmall p-mb-2">
      SITUATION: Tight Lie into the Grain: LOB WEDGE Lower than Normal
      Trajectory 10 degrees of shaft lean NO! Sharp leading edge with little
      effective bounce SAND WEDGE Normal Trajectory 3 degrees of shaft lean OK!
      Significantly softer leading edge and more effective bounce PITCHING WEDGE
      Higher than Normal Trajectory 0 degrees of shaft lean YES! Leading edge
      lifted off the ground, exposing maximum amount of effective bounce. THE
      LESSON: On tight lies into the grain, select a less-lofted club and then
      adapt your setup to produce a higher-than-normal-trajectory shot.
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        One of the most common questions students ask me is, “How do I know what
        shot to play?” Being able to see a shot is largely influenced by how you
        practice, which we’ll talk about in the next chapter. One thing that
        you’ll never hear me say is, “Choose the shot that gets the ball rolling
        on the ground as soon as possible, because this provides a greater
        margin for error.” That’s tradition speaking, and it’s pure rot! As if
        it’s impossible to skull or chunk a low shot! What if there’s a slope in
        the green you want to avoid, or you’re simply better at hitting higher
        shots than low ones? Shouldn’t you play to your strengths? There are
        simply too many variables that affect shot choice for anyone to try to
        lobby a universal recommendation. You have to trust your instincts and
        feel your way through each situation. As far as I’m concerned, the
        correct shot is the one you see as you walk up to the ball and assess
        your lie. It’s far easier to commit to a shot you see than to one you’ve
        been told you should play, and it’s better to be committed than correct.
      </div>
      <div class="p-mb-2">
        Consider the Bounce Another common question I get is, “How do I play
        from a tight lie that’s into the grain?” The key is in understanding the
        bounce angle of the club and how you want the club to interact with the
        turf. Picture a tight lie into the grain that requires a short amount of
        carry (say, 5 yards) and a waist-high trajectory. You can meet the
        demands of this shot in three ways: 1) playing a
        lower-than-normal-trajectory shot with a lob wedge; 2) playing a
        normal-trajectory shot with a sand wedge; or 3) playing a
        higher-than-normal-trajectory shot with a pitching wedge (see
        illustration, here). While the effective loft of all three shots may be
        the same and produce similar ball flights, the effective bounce angles
        of the three clubs are completely different. When you adjust your setup
        to play the lower than normal shot with a lob wedge, you’re in essence
        sharpening the leading edge of the club and reducing the bounce,
        practically ensuring that the club will stick into the grain. This is
        uncomfortable, and anything but perfect contact will result in a chunk.
        Taking your pitching wedge and adjusting your setup by removing shaft
        lean and rotating the clubface open to pull off the higher-trajectory
        shot lifts the leading edge off the ground and adds bounce. Even if you
        hit a millimeter behind the ball, the club won’t dig at all and you’ll
        still enjoy a pretty good outcome.
      </div>
      <div class="p-mb-2">
        The lesson: On tight lies into the grain, select a less-lofted club and
        then adapt your setup to produce a higher-than-normal-trajectory shot.
      </div>

      <div class="p-mb-2">
        Obviously, there are a lot of possible situations and setup/swing
        adaptations to handle them, which is what makes discovering the nuances
        of the short game so challenging and fun. But how do you get a feel for
        it all? What you’ll find is that experience is the best teacher. I’ve
        dedicated my entire adult life to learning and coaching the short game,
        and I’ve enjoyed the good fortune of consistently being around the best
        players in the world. Over time, I’ve just figured it out. When I work
        on Tour with someone as talented as Charley Hoffman or Ben Crane, we
        usually invest only about ten minutes of a two-hour session on
        technique, because once you understand the finesse wedge principle, the
        basic shots are easy. What do we do the rest of the time? We scatter
        balls in every conceivable lie condition and we talk through the shots
        and adaptations. Being able to see a shot, judge your lie, and know how
        to adjust is the “art of golf.” Use the tricks presented in this chapter
        to shorten the learning process. Beyond that, however, there are no
        shortcuts. You have to get out to the practice facility and do a lot of
        “art.”
      </div>
      <div class="p-mb-2">
        The good news is that once you learn something about a certain lie,
        it’ll help you the rest of your career, but only if you remember what to
        do the next time. Write what you learn in your journal. Again, keep it
        simple and use words and phrases that make sense to you, such as “on
        downhill lies, think ‘fade’ swing.” Every one of my Tour players makes
        it a habit to keep notes on the little things they learn. They build
        into big successes.
      </div>
    </div>
  </Panel>
  <Panel header="X" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
    </div>
  </Panel>
</template>
