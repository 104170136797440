<template>
  <Panel
    header="Draw, Fade (Hardy: release)"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        <span class="accent1">IN REVIEW: DRAW AND FADE (Hardy: Release)</span>
      </div>
      <div class="p-mb-2">
        <br />1. Change the clubface to grip alignment at address: closed to
        draw, open to fade <br />2. Move the ball slightly in your stance: back
        to draw, forward to fade <br />3. Make your normal swing <br />4. During
        the swing “see” the desired clubface-to-path-to-ball relationship. This
        is to overcome the natural feeling to try and square the clubface. Allow
        yourself to swing with closed face-to-path-to-ball relationship for
        draw, and open face-to-path-to-ball relationship with fade
      </div>
    </div>
    <img
      class="img9"
      src="@/assets/images/fullSwingAdaptations/drawFade1.png"
      alt="Tough Minded"
    />
    <div class="accent1 fontSmall p-mb-2">
      Photo1 shows normal grip and normal clubface. Photo 2 shows normal grip
      and closed clubface. Photo 3 shows normal grip and open clubface.
    </div>
    <img
      class="img9"
      src="@/assets/images/fullSwingAdaptations/drawFade2.png"
      alt="Tough Minded"
    />
    <div class="accent1 fontSmall p-mb-2">
      Photo 1 shows closed clubface in normal grip and a ball back in stance
      (DRAW). Photo 2 shows an open clubface in normal grip and a ball forward
      in stance (FADE)
    </div>

    <img
      class="img10"
      src="@/assets/images/fullSwingAdaptations/drawFadeText1.png"
      alt="drawFadeText1"
    />
    <img
      class="img10"
      src="@/assets/images/fullSwingAdaptations/drawFadeText2.png"
      alt="drawFadeText1"
    />
    <img
      class="img10"
      src="@/assets/images/fullSwingAdaptations/drawFadeText3.png"
      alt="drawFadeText1"
    />
  </Panel>

  <Panel
    header="High Shot (Hardy: release)"
    :toggleable="true"
    :collapsed="true"
  >
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        <span class="accent1">IN REVIEW: HIGHER SHOTS (Hardy: Release)</span>
      </div>
      <div class="p-mb-2">
        <br />1. Create a narrower bottom to the swing <br />2. Make a longer
        backswing and a longer follow through.
      </div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
    </div>
    <img
      class="img9"
      src="@/assets/images/fullSwingAdaptations/highShot.png"
      alt="Tough Minded"
    />
    <div class="accent1 fontSmall p-mb-2">
      For higher shots make a much longer backswing with the clubshaft (hands)
      and a much longer follow through.
    </div>
    <img
      class="img9"
      src="@/assets/images/fullSwingAdaptations/highShotText1.png"
      alt="Tough Minded"
    />
    <img
      class="img9"
      src="@/assets/images/fullSwingAdaptations/highShotText2.png"
      alt="Tough Minded"
    />
    <img
      class="img9"
      src="@/assets/images/fullSwingAdaptations/highShotText3.png"
      alt="Tough Minded"
    />
  </Panel>
</template>
<span class="accent1"></span>
