<template>
  <Panel header="Introduction" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Knowing how to set up and get the ball started on line is an important
        first step in becoming a more effective putter. Unfortunately, this
        knowledge is useless unless you can actually apply it to your setup and
        stroke when you’re out on the course.
      </div>
      <div class="p-mb-2">
        I learned this valuable lesson from one of the first students to enroll
        at my academy at Shadow Ridge Country Club. He was a very successful and
        respected entrepreneur, and after helping him work on his putting one
        day, I invited him to my office so we could write out his technical keys
        and training structure. As we wrote in his journal, he began sharing
        some of the secrets to his financial success. At one point he said,
        “Regardless of what you’re trying to accomplish, James, remember that
        you can’t improve what you can’t measure.” It resonated with me
        immediately—just knowing what to do isn’t good enough, and not having a
        way to confirm that you’re doing what you think you are leaves enormous
        room for doubt. Moreover, resting on the laurels of previous success and
        believing it’ll automatically carry over to future execution often leads
        to disappointment.
      </div>
      <div class="p-mb-2">
        As a player, you need to know with absolute certainty that you can
        execute to standard before you compete. In other words, everything you
        deem crucial to performance must be quantifiable. This is as important
        for mental strength as it is for mechanics—regular confirmation will
        make you more resilient as your round unfolds. When you miss a putt—and
        you will miss, because we are all imperfect and putting is difficult—you
        need to know that your misses are the result of errors in judgment,
        timing, or commitment. They’re temporary. Misses that result from faulty
        mechanics, on the other hand, are not. These will lead to recurring
        disappointment.
      </div>
    </div>
  </Panel>
  <Panel
    header="Block Training Technique1"
    :toggleable="true"
    :collapsed="true"
  >
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/block1.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      PGA Tour player Richard Lee’s guided block-practice station.
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        SIGHTING YOUR “GUN” I liken this process to what a professional rifleman
        goes through before a competitive event. He takes time to sight his gun
        beforehand, so he can’t blame it later if and when he happens to miss.
        Similarly, your first task when starting a new training session is to
        take five to ten minutes to confirm that your fundamentals are in order
        and reaffirm your ability to start the ball on line. There will be days
        when this runs smoothly; other times you’ll have to make a small
        adjustment or two, which will give you your “feel” for the day.
        Regardless, it’s a critical first step, as regular fundamental checkups
        prevent you from playing with a false sense of confidence or relying on
        a feel for too long. Great putters realize that fundamentals never
        change, but the feels for executing them often do.
      </div>
      <div class="p-mb-2">
        The type of practice you’re going to employ to effectively confirm your
        putting foundations is called guided block practice, and your goal is to
        perform these checks as quickly and efficiently as possible. The
        Suspension-Point, Mirror, and Worm Stability drills from Chapter 4 are
        great examples of this type of exercise and should make up a good
        portion of the first part of any practice session. The second part
        should involve checking the elements that directly affect your ability
        to start putts on line: stance, aim, path, face angle, and centeredness
        of hit. Here’s where a guided block-practice station comes in handy. A
        practice station is a practical arrangement of lines and checkpoints
        that allow you to confirm any setup or stroke fundamental on the spot.
        Tour pros use them all the time. Some are simple, while others are
        elaborate. Here are few of my favorites. Experiment with each station
        until you find the one that works best, or as some of my students have
        done, develop your own.
      </div>
      <div class="p-mb-2">
        Guided Block-Practice Station No. 1: Richard Lee In this block-practice
        station, PGA Tour player Richard Lee has read a putt and snapped a chalk
        line on the green to mark his aim line to the hole. To confirm that he’s
        starting the ball on the perfect line when he practices, he putts
        through a ball-width gate that he’s created with two tees set about a
        foot in front of the ball. He’s also erected a second gate through which
        his putter can barely fit to groove a neutral path through impact and
        sweet-spot contact. An alignment stick helps him confirm that his feet
        are in the correct position relative to the ball, while a tee marks his
        ideal distance from it.
      </div>
      <div class="p-mb-2">
        With the station in place, Richard completes ten perfect block-training
        reps, as follows: 1 He aligns the ball to the specific point on the cup
        that represents his start line. 2 He stands behind the ball and
        visualizes three points (ball, start line, and entry point). 3 He holds
        an image of the ball’s roll and speed and walks into the putt clear and
        committed. 4 He engages with the target and makes two practice strokes
        for speed. 5 He addresses the ball, checking foot position and matching
        the line on his putter to the line on the ball. 6 He scans his eyes
        through all three points. 7 He makes a calm, committed, and reactive
        stroke in which the putter cleanly passes through the rails. 8 He rolls
        the ball through the tees while keeping his eyes quiet for a full count
        past impact.
      </div>
      <div class="p-mb-2">
        This may sound like a lot, but the entire process takes less than twenty
        seconds to complete (not including the time required to set up the
        station), which means that you can finish your block-practice training
        within six minutes before moving on to the next phase of your training.
        It doesn’t take much time to train effectively as long as you’re
        disciplined and focused. Richard and other players I coach will tell you
        that I don’t get riled very easily. The exception is when a player
        chooses to train in a half-assed or unfocused manner. Then it gets ugly.
      </div>
      <div class="p-mb-2">
        Six minutes—a small investment for such a huge reward. In this small
        time frame you’ll know with 100 percent certainty that your stance is
        correct, your aim is perfect, your path is neutral, the ball is being
        struck on the sweet spot of your putter, and the face is returning to
        square at impact (as evidenced by the ball passing through the tees).
        That’s a ton of confirmation. In addition, you’ll run through ten great
        reps that train your eyes to scan all three targets, communicate where
        the target is to your brain, and execute your stroke with quiet eyes and
        a quiet mind. Wow!
      </div>
      <div class="p-mb-2">
        Does your current training do all of that? Probably not. I’m sorry, but
        plopping three balls down on the practice green and rolling putts from
        the same spot to the same hole without so much as glancing at the break
        is not effective training. There’s no feedback when you practice this
        way, which means that you can’t measure your fundamentals and ability to
        execute. Therefore, when you make mistakes, you have no way to learn
        from and correct them. In fact, you’ll end up repeating them.
      </div>
    </div>
  </Panel>
  <Panel
    header="Block Training Technique 2"
    :toggleable="true"
    :collapsed="true"
  >
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/block2.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      My personal guided block-practice station. Quick and dirty—but it works.
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        BONUS: TRAINING YOUR AIMER Most players, even good ones, often misalign
        or incorrectly aim their putter, causing good strokes to miss their
        mark. The good news is that poor aimers aren’t doomed to aim poorly
        forever; your ability to execute this skill is malleable and can be
        trained. To get it done, your brain and eyes just need some help
        consistently seeing the truth by assessing the line from two different
        perspectives with the help of instant feedback. In Richard’s execution
        of a perfect rep during his block station practice, he first sights his
        start line from behind the ball using binocular vision (step 2) and then
        views the same image from his setup perspective after aligning his
        putter (step 6). As he sees how his putter is aligned and then scans
        down his start line, his eyes and brain retrain to see reality. In my
        experience, it takes approximately three weeks of consistent work to
        “retrain your aimer.”
      </div>
      <div class="p-mb-2">
        Guided Block-Practice Station No. 2: James Sieckmann The block-practice
        station I use is quicker to set up than Richard’s, so if your time is
        limited, try this one. Step 1: On a relatively flat lie, mark the ball’s
        position on the green with a Sharpie and place a dime on the ground two
        feet in front of it. Step 2: Measure the ideal distance of your toe line
        from the ball and mark it with a tee (as determined in the Mirror Drill
        from the last chapter). Step 3: Place two ball sleeves slightly wider
        then putter-width apart on opposite sides of the ball. Step 4: Roll a
        few putts over the dime, stopping them in the same spot. Mark this
        position with a phony hole or tee.
      </div>
      <div class="p-mb-2">
        My process for completing a successful rep is identical to the one
        listed in Richard Lee’s block-practice station, but because I don’t putt
        for a living (and neither do you), I end the session after five balls
        instead of ten. I prefer my setup to Richard’s, because laying down a
        chalk line on the green—and doing it in just the right place—can be
        difficult and messy. And because I don’t use an actual hole, I can use
        any portion of the practice green or putt with any break. As long as I
        hit the dime, I know I’m executing the right things, but as I said
        earlier, every golfer is different. If you prefer Lee’s station over
        mine, I won’t be offended. My goal is to make you a better putter,
        whatever it takes.
      </div>
      <div class="p-mb-2"></div>
    </div>
  </Panel>
  <Panel
    header="Block Training Technique 3"
    :toggleable="true"
    :collapsed="true"
  >
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/block3.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      Guided block-practice station with Pelz Putting Tutor.
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Guided Block-Practice Station No. 3: Dave Pelz Putting Tutor If you read
        my first book, Your Short Game Solution, you know that Dave Pelz gave me
        my first coaching job after I decided to quit life as a playing
        professional. Dave’s guided block-practice station makes use of his
        popular Pelz Putting Tutor training aid (pelzgolf.com). The Pelz Tutor
        is a fantastic learning tool. It uses steel marbles instead of tees to
        create a gate for the ball to roll through so you can check your ability
        to start putts on line. If you knock the marble on the left out of
        place, you know that the putt was pulled; if you dislodge the marble on
        the right, you know you pushed the putt. A thick, white sight line makes
        it easy to aim the Tutor in any direction you want, and you can change
        the width of the marble gate to increase or lessen the challenge.
      </div>
      <div class="p-mb-2">
        You can’t cheat the Pelz Tutor—either start the ball on line or you’ll
        end up chasing marbles. I’ll often set up my practice station using just
        the Tutor. A lot of golfers putt under a string anchored by two pencils
        or two tees to check that they’re starting the ball on line like I’m
        doing in the photo here, but I think aids like the Pelz Tutor are much
        more effective. (If I use a string line, I will almost always do so in
        tandem with the Pelz Tutor.) My longest-running Tour client, Tom
        Pernice, Jr., was a big “putt-under-the-string” guy, but the feedback
        wasn’t specific enough (though neither of us knew it at the time), and
        Tom would commonly push or pull the ball ever so slightly toward the
        high side of the putt without realizing it. His putting frustrated him
        for years—we all know the agony of striking a putt dead in the sweet
        spot and watching it burn the edge or lip out on the “pro” side. When we
        finally added the Pelz Tutor to his block-practice session, Tom hit the
        marble on the high side of the putt for two weeks straight. You can’t
        buy that kind of feedback. Eventually, Tom learned how to fight his
        subconscious tendency to fudge his start lines to the high side and
        instead commit to the line he selected during his read. He had to feel
        as though he was going to miss low in order to make a breaking putt.
        During the final round of the 2014 Charles Schwab Cup Championship on
        the Champions Tour, Tom drained several clutch breaking putts down the
        stretch to win and grab his biggest prize check in more than eight
        years. He looked calm as I watched him on the television screen, but I
        knew how hard he was battling on the inside. Tom’s commitment to process
        and his desire to work both hard and intelligently is truly an
        inspiration.
      </div>
    </div>
  </Panel>
  <Panel
    header="Block Training Technique 4"
    :toggleable="true"
    :collapsed="true"
  >
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/block4.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      LPGA Tour player Julieta Granada’s guided block-practice station.
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Guided Block-Practice Station No. 4: Julieta Granada In these photos
        below, you’ll find LPGA Tour player Julieta Granada checking her
        foundations. She uses a Perfect Path Trainer (eyelinegolf.com) to create
        three gates for her putter to travel through. (Her big error is taking
        the putter back to the inside, which creates contact out toward the toe
        of the putterface.) Her block-practice station nips both errors in the
        bud. The Path Trainer also has a cutout line for perfect face alignment,
        which Julieta marks with a Sharpie. This allows her to quantify her
        starting line, which she identifies in this example by pegging a tee
        just beyond the inside right edge of the cup (you can see it if you look
        closely).
      </div>
      <div class="p-mb-2">
        Although she starts her process by visualizing her three points and
        walking in with that picture in mind, I added a step where she stands up
        straight to “stack” her nose over her sternum and belt buckle before she
        hinges from her hips to settle into her posture. Adding this move into
        her process ensures that she sets up with her dominant eye two inches
        behind the ball and with her shoulders square instead of in front and
        open, which is her most natural position. The alignment rod marks both
        her ideal distance from the ball (determined in the Mirror Drill) and
        the perfect ball position relative to her feet.
      </div>
      <div class="p-mb-2"></div>
      <div class="p-mb-2"></div>
    </div>
  </Panel>
  <Panel
    header="Creating Block Training Station"
    :toggleable="true"
    :collapsed="true"
  >
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/block5.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      Gather the tools you’ll need to build an effective block-practice station
      and to perform the various drills throughout this book. Whether it’s a
      household item or specifically designed training aid, these tools provide
      the feedback necessary for you to confirm your fundamentals so you can
      take them to the next level.
    </div>
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/block6.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      Expert at Work! Watch me run through a sample block practice station
      session to check my foundations in a special video at
      jsegolfacademy.com/index.php/james-foundation-check.
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        CREATING YOUR BLOCK-PRACTICE STATION Ultimately, you need to trust your
        fundamental mechanics and developed skills in order to free up your
        stroke and simply react to what you see. However, it’s impossible to
        trust something over time if it shows itself to be unworthy. For
        experienced players, the work has to come first. Some measure of trust
        has to be earned.
      </div>
      <div class="p-mb-2">
        Open your journal and revisit the list of technical keys you created at
        the end of Chapter 4. Now that you have a few practical examples of how
        the pros train for success, follow their lead and experiment with
        building your own feedback station with the goal of solidifying the
        critical skill of starting the ball on line. Think of the different
        drills presented in this chapter and the tools you’ll need to
        efficiently—and correctly—measure your execution of each key. Many of
        these items are already in your bag (balls, tees, coins, etc.), but a
        few (alignment sticks, training aids) may require a trip to your local
        golf retailer or e-commerce store. For the tech geeks out there, visit
        www.blastmotion.com/products/golf or the site I most commonly use,
        www.eyelinegolf.com.
      </div>
      <div class="p-mb-2">
        Keep in mind that your guided block-practice station will likely evolve
        as you learn or grow in your practice. In the meantime, kick-start your
        improvement by investing about ten minutes per training day drilling
        your foundations until you execute them consistently enough to “win the
        station” (a 70 to 80 percent success rate over ten minutes of practice).
        In my experience, it takes two to three weeks for your old motor pattern
        to evolve into a new one that produces consistent results. Be fair to
        yourself—stay patient and give it time.
      </div>
      <div class="p-mb-2"></div>
    </div>
  </Panel>
  <Panel header="Journal Work" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        JOURNAL WORK My Block Practice— Towel Stability Drill (one minute) 1
        Suspension-Point Drill (one minute) 2 Practice Station: guided feedback
        for starting the ball on line and confirming setup (eight minutes) 3
        Tutor with gate for putter path 4 Tee to mark distance from the ball 5
        Alignment stick for foot placement
      </div>
    </div>
  </Panel>
</template>
