<template>
  <Panel header="Social:" :toggleable="true" :collapsed="true">
    <Panel header="Image:" :toggleable="true">
      <div class="p-grid">
        <div class="p-col-6">
          <div>
            <img
              src="../assets/images/interPersonal/interPersonal2.png"
              alt="Mental"
              class="img9"
            />
          </div>
        </div>
        <div class="p-col-6">
          <div>
            <img
              src="../assets/images/interPersonal/interPersonal3.png"
              alt="Mental"
              class="img9"
            />
          </div>
        </div>
      </div>
    </Panel>
    <social />
  </Panel>

  <Panel header="Environmental:" :toggleable="true" :collapsed="true">
    <Panel header="Image:" :toggleable="true">
      <div class="p-grid">
        <div class="p-col-6">
          <div>
            <img
              src="../assets/images/interPersonal/weather1.png"
              alt="Mental"
              class="img9"
            />
          </div>
        </div>
      </div>
    </Panel>
    <environment />
  </Panel>
  <Panel header="Nutrition:" :toggleable="true" :collapsed="true">
    <Panel header="Image:" :toggleable="true">
      <div class="p-grid">
        <div class="p-col-6">
          <div>
            <img
              src="../assets/images/interPersonal/nutrition1.png"
              alt="Mental"
              class="img9"
            />
          </div>
        </div>
      </div>
    </Panel>
    <nutrition />
  </Panel>
  <Panel header="Hydration:" :toggleable="true" :collapsed="true">
    <Panel header="Image:" :toggleable="true">
      <div class="p-grid">
        <div class="p-col-6">
          <div>
            <img
              src="../assets/images/interPersonal/nutrition1.png"
              alt="Mental"
              class="img9"
            />
          </div>
        </div>
      </div>
    </Panel>
    <hydration />
  </Panel>
</template>

<script>
import social from "@/components/mental/social";
import environment from "@/components/mental/environment";
import nutrition from "@/components/mental/nutrition";
import hydration from "@/components/mental/hydration";

export default {
  name: "InterPersonal",
  components: {
    social,
    environment,
    nutrition,
    hydration,
  },
};
</script>
