<template>
  <div class="fontSmall taLeft">
    <div class="p-mb-2">
      LEFT ARM CANNOT BECOME PINNED AND FOLD BACK <br />I'm reluctant to give
      this advice but even if you have to "feel" as though you pull the left
      elbow up and back to get it to fold like Lee Westwood does, do it (fig
      9.15). If the sidearm/underarm swing of the right forearm across the torso
      isn't truly pinning the left arm as the inside arm then yank it out of the
      way. You are not extending the left arm through impact to finish in the
      RIT release. It's the right arm that's extending through and you need to
      get the left out of the way.
    </div>
    <div class="p-mb-2"></div>
    <img src="@/assets/images/hardy2/fig9.15.png" class="img10" alt="Alt" />
    <div class="accent1 fontSmall p-mb-2">
      FIG. 9.15. If you're having trouble getting your left arm pinned then yank
      it upwards and inward behind you and force it to fold.
    </div>
    <div class="p-mb-2">
      PROBLEMS WITH WRIST THROW <br />There are a couple of final points I want
      to clear up regarding the correct right wrist throw. Those points concern
      the difference between casting and throwing and the difference between
      scooping and throwing. <br />Cast versus throw: <br />The cast is an
      uncocking (ulnar deviation) motion of the wrists at the start of the
      downswing. Believe it or not a cast is perfectly acceptable in an LOP
      release. In fact you must inhinge or cast prior to impact with the LOP.
      Not so with the RIT. Any unhinging/uncocking (ulnar deviation) should be
      minimal if at all in the RIT. <br />Scoop versus throw: <br />Both of
      these are done with the same wrist action; flexion. The difference is the
      flexion in the throw is initiated BEFORE the clubhead drops below the line
      of the right forearm. The flexion in the scoop is initiated AFTER the
      clubhead drops below an extended line of the right forrearm (fig 9.17.)
      This extended line of the right forearm I call the NO FLY ZONE.
    </div>
    <img src="@/assets/images/hardy2/fig9.17.png" class="img10" alt="Alt" />
    <div class="accent1 fontSmall p-mb-2">
      FIG. 9.17. The large photo shows the clubhead has dropped below the
      extended line of the right forearm and is now in the no-fly zone. Any
      throw initiated from here will result in a scoop which does not close the
      clubface and moves the clubhead in a straight line gaining loft.
    </div>
    <div class="p-mb-2">
      You must begin the throw before the clubhead gets below this line in order
      for the clubshaft to align with the right forearm (fig 9.18). If you wait
      too long and the clubhead drops below the no fly zone, then any throw you
      will make will still have the clubshaft aligned with the left arm and will
      be a week scoop. A correct throw of the shaft onto the right forearm
      results in a flatter plane and a club that moves on an arc with the face
      square to the path and the clubface's loft constant as it moves around. A
      lat throw after the clubhead drops below the no fly zone results in an
      upright plane and a scoop where the clubhead moves on a straight line with
      increasing loft. This lofting, straight line clubhead produces high and
      very weak shots. The "THROW AND DROP" drill is vital to your understanding
      of how to correct a scooping mistake.
    </div>
    <img src="@/assets/images/hardy2/fig9.18.png" class="img10" alt="Alt" />
    <div class="accent1 fontSmall p-mb-2">
      FIG. 9.18. The RIT throw must begin before the clubhead drops below the
      extended line of the right forearm. The large photo shows the limit of
      where the throw must have been initiated. If executed properly the throw
      closes the clubhead in an arc and the loft remains constant.
    </div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
  </div>
</template>
