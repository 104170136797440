<template>
  <div class="p-d-flex-column">
    <Panel
      header="Techniqe  (Hardy: Plane Truth)"
      :toggleable="true"
      :collapsed="true"
    >
      <Panel header="Address" :toggleable="true" :collapsed="true">
        <hardy1Address />
      </Panel>
      <Panel header="Backswing" :toggleable="true" :collapsed="true">
        <hardy1Backswing />
      </Panel>
      <Panel header="Downswing" :toggleable="true" :collapsed="true">
        <hardy1Downswing />
      </Panel>
      <Panel header="Impact" :toggleable="true" :collapsed="true">
        <hardy1Impact />
      </Panel>
      <Panel header="Follow-Through" :toggleable="true" :collapsed="true">
        <hardy1FollowThrough />
      </Panel>
    </Panel>
  </div>
</template>

<script>
import hardy1Address from "@/components/hardyPlaneTruth/hardy1Address";
import hardy1Backswing from "@/components/hardyPlaneTruth/hardy1Backswing";
import hardy1Downswing from "@/components/hardyPlaneTruth/hardy1Downswing";
import hardy1Impact from "@/components/hardyPlaneTruth/hardy1Impact";
import hardy1FollowThrough from "@/components/hardyPlaneTruth/hardy1FollowThrough";
export default {
  name: "hardyPlaneTruth",
  components: {
    hardy1Address,
    hardy1Backswing,
    hardy1Downswing,
    hardy1Impact,
    hardy1FollowThrough,
  },
};
</script>
<style lang="scss">
// GENERAL STYLES
.accent1 {
  font-weight: bold;
}
.accent2 {
  color: red;
  font-weight: bold;
}
.accent3 {
  color: red;
}
.fontSmall {
  font-size: small;
}
.fontSmaller {
  font-size: smaller;
}
.fontMedium {
  font-size: medium;
}
.fontLarge {
  font-size: large;
}
.taLeft {
  text-align: left;
  padding: 0 0.25em 0 0.25em;
}
</style>
<span class="accent1"></span>
<img src="@/assets/images/hardy1/hardyFinish1.png" alt="Hardy Posture" />
<div class="accent1 fontSmall p-mb-2"></div>
