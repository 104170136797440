<template>
  <div class="fontSmall taLeft">
    <div class="p-mb-2">
      IN REVIEW FOR A CORRECT RIT RELEASE BODY MOTION: <br />1) The hips must
      have completed their lateral motion prior to the start of the downswing.
      (By end of transition.) <br />2) The left hip initiates the downswing turn
      and shoulders and right hip immediately start turning after this
      initiatine move. <br />3) As the hips turn, their distance from the target
      line must be maintained or increased. <br />4) Along with a correct hip
      turn, the left side must gently rise and the right shoulder and right hip
      must gently lower to maintain the spine angle. <br />5) Allow the body to
      accelerate gradually in concert with the dropping downward and inward
      arms.
    </div>
    <div class="p-mb-2"></div>
    <img src="@/assets/images/hardy2/fig9.19.png" alt="Alt" class="img2" />
    <div class="accent1 fontSmall p-mb-2">
      FIG. 9.19. The body in the RIT supports the flatter plane inward throwing
      motion by turning in posture with the right foot down until impact or
      after. Without the turn in posture you could not create the inward
      centripetal force and the outward centrifugal motion.
    </div>
    <div class="p-mb-2">
      <span class="accent1">Correct RIT Release: Body Motor</span>
    </div>
    <div class="p-mb-2">
      Let's now revieew the same photo positions of the arms and club motor we
      have just studied and look at what the body is doing during these swing
      phases. Basically the body is moving in a SUPPORTING role that allows us
      to release the club properly. My philosophy on this is the same for either
      release and in fact I said this same thing in the previous chapter in the
      overview of the LOP release. Some may strongly argue this point and say
      the body is the lead actor and is not playing a supporting role. I'm not
      in that camp, exspecially since this book is about the release, which is
      overwhelmingly controlled by the actions of the arm and club motor.
      Howerve that does not in any way diminish the role of the body. Its
      function is to allow, facilitate and amplify the release. One thing is for
      certain: if the body motor "fights" what you are trying to accomplish in
      the release it can TOTALLY DESTROY your efforts. So we need to understand
      just how your body must react to support your release, not destroy it.
    </div>
    <div class="p-mb-2">
      The body motor uspports the RIT release in a very different way than it
      works in the LOP release. In the LOP release the body motor supports a
      left arm pull, an outward right arm and shaft rotation with a lateral
      slide and outward thrust of the hips. The mody motion in the RIT release
      is nearly the opposite. The hips and shoulders rotate while the tailbone
      stays away from any thrust. This motion supports the INWARD CIRCLE CLOSING
      and circular centripetal force that is used with the RID release (fig
      9.19). Let's look at this body motor motion in closer detail. Notice I've
      drawn lines as reference points at address on the top of the head and the
      back of the hips in the down-the-line photos. In the face-on photos I've
      drawn reference points at the head, hips and spine.
    </div>
    <div class="p-mb-2">
      An instant before the start of the downswing, during the transition
      between the backswing and the downswing, any lateral lower body movement
      that transfers weight and adds ground forces to the left foot the RIT
      release golfer needs to make needs to have been completed ["actually left
      arm is down to horizontal(frame 3), so by 'downswing' Hardy means the
      right hand throw/flex"]. It's jsut fine if it was completed at the end of
      the backswing before the transition. Just don't shift the hips laterally
      during the downswing. During the downswing there must be hip rotation not
      hip slide and then rotation. Once the transition period weight transfer
      onto the left foot is accomplished, the left hip initiates the downswing
      body motion and the shoulders quickly join WITH the turning hips.
    </div>
    <div class="p-mb-2">
      The big point in this turning motion is what is happening within your
      turn. There are two big points that must happen. Point one, there must be
      sufficient backward pressure on your tailbone/pelvis so that when yourhips
      rotate they DO NOT thrust outward. The hips msut maintain or even increase
      their relative distance from the target line. This is curcial because of
      the radius issue I have previously discussed on two occasions. If the hip
      position goes outward while turning, the arms and club handle have no room
      to come downward and inward. A throw would result in the clubhead's coming
      into impact well beyound the ball. As a result, you will never fully
      throw/flex the wrist. Instead you will dro the shaft vertically because
      the arms will be too high and too far from you. In essence, you will have
      turned your RIT release into an LOP release if you hip thrust.
    </div>
    <div class="p-mb-2">
      The second point I want to emphasize is the angle your hips and shoulders
      turn on. As you complete your turn, the entire left side goes up and the
      right shoulder and right hip go down. However a word of caution on this
      must be noted. The left side does not go up and right side does not go
      down quickly from the start of the downswing. If this happens it is always
      a sign of a tilting body and a turning one. I DO NOT WANT your to TILT
      your body. There shoult be NO lowering of the right side and rising of the
      left side independent of a turning modtion. As the turn begins, the right
      side stays up and the left down. I want it to stay that way until after
      the initial turn of the left hip. Only after the left hip has initiated
      the downswing and the turning motion of the ENTIRE BODY is under way does
      the left side start rising and the right side start lowering. Let this
      rising and lowereing be gently and not abrupt. Any abrupt up or down
      motion will STOP your turn. I would far rather have the left side of up
      and the right side go down too late than too early or too abruptly.
      Correctly executing the turn and gentle up and down motion, accompanied
      with a correct tailbone position as you turn, ensures you stay in your
      body postur. It's OK if your spine goes down/increases for the RIT
      release; just don't allow it to stand up until during the follow through.
    </div>
    <div class="p-mb-2">
      One final word of caution on the correct RIT body motion; DO NOT try to
      gain early or abrupt body speed during the initiation and early stages of
      the turn. Your arms have not started their inward centripetal force motion
      where they move downward and inward into the body. With the arms in their
      extended position at the start of the downswing any effort to gain early
      body speed will simply thwart your efforts to create inward centripetal
      force with your arms. Early speed with the body will in fact cause your
      arms to be thrown outward. Additionally your body will be fighting your
      extended arm position due to inertia. With your arms extended out from
      your body they are in a drag position relative to rotational body speed.
      It is exactly the same issue you see in figure skaters. They can only turn
      quickly as their arms come into their body. The closer the arms come into
      their body the faster they can rotate. this is the same with the RIT body
      motion. As the arms drop downward and inward and the centripetal force and
      centrifugal result increases the body gains more and more turning speed.
      This is the body working in a true swinging motion versus a leverage
      motion. In the LOP leverage motion the fast and learly hip thrust comes to
      a sudden slowdown/stop whigh whips the upper body which in turn whips the
      arms and then the club. Not so with the throwing/swinging RIT motion where
      everything reaches its maximum speed at impact. In clusion, allow the body
      rotation to slowly accelerate to top speed along with the centripetal
      downward and inward drop and the outward centripetal throw.
    </div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
  </div>
</template>
<span class="accent1"></span>
<img src="@/assets/images/hardy1/hardyFinish1.png" alt="Hardy Posture" />
<div class="accent1 fontSmall p-mb-2"></div>
