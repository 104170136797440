<template>
  <Panel header="Introduction" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        The consistent, square delivery of the clubface to the ball is the goal
        of everything we have talked about so far. If you can consistently
        deliver the clubface squarely to the back of the ball, whether it be by
        employing the one-plane or the two-plane swing method, you are going to
        play good golf.
      </div>
      <div class="p-mb-2">
        That said, though, once you start to deliver the club squarely and
        solidly to the back of the ball, you may be amazed at the quality of the
        shots you are able to hit. The ball will seem as if it is jumping off
        the clubface at a much greater velocity. You'll notice that the shot's
        flight seems stronger—the ball bores through most any wind rather than
        being tossed off-line by even a moderate breeze. Furthermore, if you
        keep working on the mechanics we discussed so that you keep striking the
        ball squarely and solidly for an extended period of time, you'll not
        only find the game physically easier but much less taxing mentally as
        well. You'll get to where you can step on the tee of a hole at your home
        course that has always given you trouble and carry with you a completely
        different outlook.
      </div>
      <div class="p-mb-2">
        Say this hole has out-of-bounds that's well to the left and trees to the
        right, although the fairway itself is actually quite wide. At some point
        in the past, you pulled or hooked a few tee shots out of bounds on this
        hole. Being an intelligent golfer, you knew that this was a costly
        mistake that you didn't want to repeat again. However, in the past, you
        could rarely predict where your shots would fly, because you were not in
        the habit of consistently hitting the ball solidly. So you began to aim
        well right on this hole and put a defensive swing on the ball. Result?
        You almost always hit it in the trees on the right or you overworked
        your hands to compensate for the aim to the right and hit the hook to
        the left that you were trying to avoid in the first place.
      </div>
      <div class="p-mb-2">
        However, once you have begun swinging the club on the correct plane and
        hitting the ball solidly, you'll be able to predict the flight and the
        line of your shots. Now when you step onto this particular tee, do you
        know what will happen? The out-of-bounds stakes 20 yards left of the
        fairway are not going to strike fear into you. Neither will the trees.
        You'll say to yourself, "That fairway is 40 yards wide, and there's
        about 70 yards between those out-of-bounds stakes left and the trees
        right. There's no way I'll hit into either of them." And you won't.
      </div>
      <div class="p-mb-2">
        If this scenario excites you, I think it would be wise for us to review
        the positions the body and the club should be in at the instant the
        clubface meets the ball. My purpose here is not to tell you how to
        adjust yourself at impact. As I've pointed out a number of times, the
        downswing happens too quickly for you to make any specific adjustments
        of the body or the club (even if you could sense the need for them). I
        think you'll find it instructive to see and know what your impact
        position should look like, just as you should be able to see the correct
        address position or var­ious other positions during the swing. It will
        certainly help you to rehearse these positions in front of a mirror. The
        more different they feel from the manner that you're accustomed to
        setting up and swinging, the more I recommend mimicking these static
        positions. When you get out on the practice tee, you'll be working to
        develop a smooth motion in which your body flows smoothly through the
        various positions from address to follow-through. But you must
        understand how the static positions look and feel before you will be
        able to ingrain them into your new golf swing.
      </div>
    </div>
  </Panel>
  <Panel header="Shoulders" :toggleable="true" :collapsed="true">
    <img src="@/assets/images/hardy1/hardyimpact1.png" alt="Hardy Posture" />
    <div class="accent1 fontSmall p-mb-2">
      Just look how free and powerful this one-plane swing is in the impact and
      follow-through areas!
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Have you ever viewed a stop-action photo of a baseball player at the
        moment of impact with the ball? When viewed from the perspective of the
        pitcher, you'll notice that the hitter's shoulders and chest have turned
        well beyond their position prior to when the pitch was delivered. His
        torso is facing the pitcher. At the same instant his arms and the bat
        are extended over the plate. This is his impact position with the
        baseball.
      </div>
      <div class="p-mb-2">
        With the exception of the bent over spine and the more vertical shoulder
        turn, the position of your shoulders and chest are somewhat similar to
        this for the one-plane impact position. They should be
        <span class="accent1"
          >open by at least 30 degrees in relation to the target line,</span
        >
        which is a technical point that surprises many amateur golfers. They
        think the shoulders should be square in relation to the target line, as
        they were at address. Not so. Your shoulder turn is the primary source
        of power in the one-plane downswing. The shoulders must turn as fast as
        they can, and they must turn beyond their original address position at
        impact. They are in effect cracking the whip, with the whip being your
        arms and the golf club. Your arms and club will feel at impact as though
        they are being slung or flailed from impact on into the follow-through.
        To feel this, make some baseball swings at an imaginary ball that is
        waist or chest high. Turn your torso until it faces between the pitcher
        and third baseman before you allow your arms to fling across your chest.
        The farther and faster around you can turn your chest, the more power
        you can generate. You are now imitating a pull hitter in baseball. You
        can easily feel the difference in power between this method and a push
        or opposite-field hitter, who uses very little torso rotation but relies
        instead on an early arm swing. The pull hitter hits for power; the
        opposite-field hitter hits singles.
      </div>
      <div class="p-mb-2">
        A final note about the shoulders: At impact and just beyond, the
        shoulders, if turning in the zone,
        <span class="accent1"
          >will reach their steepest point here. The left shoulder will be at
          its highest point, and a line down through the right shoulder will be
          in its most vertical position.</span
        >
      </div>
    </div>
  </Panel>
  <Panel id="ImpactArms" header="Arms" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Your arms are
        <span class="accent1"
          >somewhat opposite the right side of your torso</span
        >
        as you enter the impact area. This is because most of the torso has
        already turned past being square to the target line and now is in an
        open position in relation to the target line. Your
        <span class="accent1"
          >left or lead arm should be virtually straight and should be
          rotating</span
        >
        and starting to move across the chest. As for your
        <span class="accent1"
          >right arm, it is in the process of unbending
        </span>
        from its position at the start of the downswing and
        <span class="accent1">is beginning to release</span> into the
        follow-through. However, it should
        <span class="accent1">still be slightly bent as you reach impact.</span>
        (If your right arm were to completely extend prior to impact, you would
        have used your arms too early and you'd almost certainly stick the
        clubhead into the ground or pop up out of your spine to avoid the
        ground.)
      </div>
      <div class="p-mb-2">
        As we talked about on the downswing, you also want to make sure your
        <span class="accent1">right elbow</span> is almost touching but
        <span class="accent1">just behind the right hip,</span> rather than
        getting in front of the right hip. Having the elbow just behind the
        right hip indicates that the clubhead has entered the hitting zone more
        on top of the ball than on the inside. Again, when you come into impact
        on top of the ball, your swing and your club are on plane and there is
        no need for any type of manipulation in order to make square contact.
      </div>
      <div class="p-mb-2">
        Also at impact, the back of the left forearm and the back of the left
        hand are pointing at the target.
        <span class="accent1">The wrists and hands</span> should be just
        <span class="accent1"
          >slightly bowed backward ("?? === slightly cupped")</span
        >. As discussed earlier, the amount of left-arm rotation actually
        depends to a great extent on the grip you employ. The stronger the grip,
        the less you will rotate (supinate) the left arm through impact. (You
        may recall that I recommended that the one-plane player employ a grip
        that is, if anything, leaning toward the strong position.) And as your
        left forearm rotates, the tendency will be for the left wrist to bow
        back slightly. Despite what you may have read or heard elsewhere about
        the left wrist leading at impact, this is perfectly acceptable and in
        keeping with the positions and movements of the entire one-plane swing.
        Finally, your wrists should be fully uncocked at impact. This uncocking
        will happen naturally as your arms are whipped around and down by the
        force of your torso turning. Do not attempt to consciously uncock the
        wrist at any particular point in the downswing.
      </div>
      <div class="p-mb-2">
        A final note here about
        <span class="accent2"
          >how the arms and torso work together at impact that just might be the
          secret to the one-plane swing
          <a href="#OnePlaneRelease">(One Plane Release)</a>.</span
        >
        In the one-plane swing downswing, your arms are across your chest and
        opposite your right side. As a result,
        <span class="accent1"
          >when your torso is nearing impact, it is facing into the start of the
          follow-through portion of the plane while the arms are behind, still
          facing the end of the downswing portion of the plane.</span
        >
        It is at this point that your arms start being whipped across your
        torso—from the right side to around the left side. Although
        <span class="accent1"
          >the left arm should continue to rotate to the end of the swing, just
          before impact, the right arm begins to straighten and roll over the
          left arm. ("rt arm moving around and close to the body - rt shoulder
          rotation pushes hands back/up followthrough - while lt arm
          moves/pulled back and up. I.e., rt arm without changing its swing arc
          is now over left arm")</span
        >
        This release of the right arm over the left arm starts to be completed
        during the follow-through. However, it must be initiated before impact.
        If the right arm release is late, the arm will fail to swing around the
        chest correctly and the in-to-in element of the one-plane swing will be
        destroyed.
        <span class="accent1"
          >This left arm rotating and the right arm releasing over the left arm
          is very dynamic. It transfers the power from the turning torso into a
          whipping golf club.</span
        >
        It is the difference in baseball between a check swing and a home run.
        In golf, it is the difference between a weak drive in the rough and a
        300 yarder in the fairway. I
        <span class="accent2"
          >cannot overstate the importance of the left arm rotation and release
          of the right arm.</span
        >
        A one-plane swinger can release the arms as hard as desired as long as
        the club is moving in an arc. The clubhead must be moving left, back to
        the inside immediately after impact. Problems occur when a one-planer
        releases on a straight line.
      </div>
      <div class="p-mb-2">
        The faster and harder your torso can turn left and up the plane, the
        harder and faster you can simultaneously rotate the left arm and release
        the right arm. The result is a swing that is tearing through impact,
        producing powerfully hit straight shots.
      </div>
    </div>
  </Panel>
  <Panel header="Hips" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        The hips, although they have not turned as fast as the shoulders, have
        continued to turn. At impact, they are
        <span class="accent1">open by anywhere from 35 to 50 degrees</span>
        or even more in relation to the target line. The amount depends on the
        golfer's flexibility, strength, and ability to keep the left arm across
        the chest and the hands somewhat opposite the right side of the torso.
        The hips will be turned open by
        <span class="accent1">about 15 degrees more than the shoulders</span>
        at the point of impact. Remember, the hips did not turn nearly as much
        as the shoulders on the backswing. Therefore, the hips will be ahead of
        the shoulders all the way through the downswing. By impact, the shoulder
        turn has almost but not quite caught up to the turn of the hips. Also,
        the hips should be
        <span class="accent1"
          >tilted just enough to accommodate the fairly steep shoulders turning
          ("tilted forward between horizontal and shoulder")</span
        >
        around the spine.
      </div>
    </div>
  </Panel>
  <Panel header="Spine Angle" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        The spine should be set at the same angle of forward lean as at address.
        Because it
        <span class="accent1"
          >is acceptable for the one-plane swinger to increase the spine angle
          at the start of the downswing</span
        >, the
        <span class="accent1"
          >angle may decrease (i.e., the spine rises slightly) at impact.</span
        >
        One difference in the spine of the one-plane swinger at impact is that
        there
        <span class="accent1"
          >may be a slight tilt of the spine to the player's right.</span
        >
        You shouldn't consciously lean to the right, of course. But what happens
        is that when you turn your shoulders on a steeper angle through the
        impact zone as the one-planer does, your
        <span class="accent1"
          >head will tend to drop back just slightly to the right.</span
        >
        The spine will also tilt slightly right to support the head.
      </div>
    </div>
  </Panel>
  <Panel header="Weight Distribution" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        The one-plane swinger exhibits a very stable weight distribution at
        impact. The weight should be
        <span class="accent1"
          >just about evenly distributed between the two feet, with no more than
          60 percent on the left leg.</span
        >
        The left foot is flat on the ground, with the weight that is on that leg
        never going to the outside of that foot. The right foot is not
        necessarily flat to the ground, nor should it be completely up on the
        right toes. It is somewhere between the two positions. The weight
        distribution is indicative of the role of the legs as stabilizers in the
        one-plane swing, while the upper-body turn is the chief power producer.
      </div>
    </div>
  </Panel>
  <Panel header="Club Movement" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        Of course, if you have executed correctly, the
        <span class="accent1">clubhead</span> should be delivered square to the
        target line at impact. It is
        <span class="accent1"
          >delivered from just slightly inside the target line (rather than from
          well inside) and will be moving to the left or inside the target line
          immediately after impact.</span
        >
        This in-to-in arc is crucial to the one-plane swing.
      </div>
      <div class="p-mb-2">
        At impact, the <span class="accent1">club shaft</span> is positioned a
        bit differently than it was at address. First, the shaft is
        <span class="accent1">leaning slightly toward the target</span> as
        opposed to pointing straight up and down. This is because the hands,
        arms, and club are still in the process of catching up to the shoulder
        turn, with the clubhead being the end of the whip, slightly trailing the
        hands. Meanwhile, the club shaft is also
        <span class="accent1">more upright than it was at address.</span>
        This tends to occur in the one-plane swing. At impact the shoulders have
        turned past where they were at address, and the left shoulder is much
        higher. This higher left shoulder lifts the handle of the club slightly
        from where it was at address, resulting in a more upright shaft at
        impact. What this also means, in essence, is that your
        <span class="accent1"
          >wrists are more fully uncocked than they were at address. Thus your
          hands are slightly higher,</span
        >
        your wrists are more arched, and the club shaft is pointing slightly
        more vertically downward in the one-plane impact position than it was at
        address.
      </div>
    </div>
  </Panel>
  <Panel header="Review" :toggleable="true" :collapsed="true">
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        <span class="accent1">Shoulders:</span> The shoulders are
        <span class="accent1">turned along the same tilted plane</span> as
        throughout the swing. Shoulders are
        <span class="accent1">open by at least 20 to 30 degrees</span> in
        relation to the target line. Just beyond impact the shoulders will be in
        their steepest position. WARNING Do not try to keep your shoulders
        square to the target line at impact.
      </div>
      <div class="p-mb-2">
        <span class="accent1">Arms:</span> Arms are
        <span class="accent1">opposite the right side of the torso</span> at
        impact.
        <span class="accent1"
          >Left arm has rotated and is virtually straight.</span
        >
        It is to be flung across the chest.
        <span class="accent1">The right arm has started its release.</span>
        WARNING Do not force your right elbow onto the front of your right hip.
        This move tends to put the clubhead behind you with the face open—in the
        "stuck" position that is the one-plane swinger's most common fault.
      </div>
      <div class="p-mb-2">
        <span class="accent1">Hips:</span> The hips should be
        <span class="accent1"
          >open by 35 to 50 degrees in relation to the target line at impact, or
          slightly more open than the shoulders.</span
        >
        WARNING Do not dramatically tilt the right hip down and the left hip up.
        Doing so will affect the other body parts and throw the club's movement
        off plane. Fat shots will commonly result.
      </div>
      <div class="p-mb-2">
        <span class="accent1">Weight Distribution:</span> Weight should be
        <span class="accent1"
          >evenly distributed on both feet or slightly favoring the left
          leg.</span
        >
        WARNING Do not slide laterally and shift too much weight left. The legs
        act as stabilizers for the turn of the upper body.
      </div>
      <div class="p-mb-2">
        <span class="accent1">Club:</span> The
        <span class="accent1">clubface is square.</span> The
        <span class="accent1">club shaft</span> is
        <span class="accent1">more upright</span> than at address and
        <span class="accent1">leans slightly toward the target.</span>
        WARNING Do not block with the hands and arms and attempt to hold the
        club on a straight line. (Keep the left arm rotating and the right arm
        releasing to allow the club to move on an arc
      </div>
    </div>
  </Panel>
</template>
