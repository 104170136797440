<template>
  <Panel header="Rhythm Faults and Fixes" :toggleable="true" :collapsed="true">
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/rhythmFix1.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      Performing the Color-Coded-Tee drill will train you to understand that
      because rhythm never changes, energy imparted to the ball at impact is
      dictated by the length and speed of your backstroke. Prove it by putting
      “tee-to-tee” for each color code (inset photos)—you’ll produce three
      distinct distances.
    </div>
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/rhythmFix2.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      The length of your backstroke—not your forward-stroke—and gravity conspire
      to create the perfect amount of potential energy for the ball to roll the
      correct distance. That’s what makes the Luke Donald Drill so effective:
      Because the tees prevent you from adding energy as you anticipate impact,
      the resultant roll is entirely dependent on the amount of energy you
      establish at the top of your backstroke. In other words, if you make a
      backstroke that’s too short for the putt you’re facing (a common error),
      the ball won’t reach the hole. If you break the tees or push them forward
      aggressively, you have an unhealthy “hit” impulse at impact.
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        If you feel uncomfortable when you perform the Resonant Rhythm Drill, or
        notice that any stroke pace you make in the 70- to 85-bpm range is far
        different than your normal movement pattern, your current technique for
        distance control contains one or more of the following fatal rhythm
        flaws. Nip them in the bud right now! Poor rhythm is one of the most
        common and destructive errors in putting, and if your rhythm is off,
        you’re adding unnecessary strokes to your score.
      </div>
      <div class="p-mb-2">
        Rhythm Error No. 1: Length Rigidity What It Is: Making the same length
        backstroke for every putt. What It Does: Causes the putterhead to
        decelerate on short putts and creates a “hit” impulse at impact on
        longer putts, making both distance control and returning the face to
        square extremely difficult.
      </div>
      <div class="p-mb-2">
        The Fix: Try my Color-Coded-Tee Drill. Grab three sets of tees of
        varying colors (two white, two yellow, and two black, for example). On
        the green, mark the start position of your ball with a tee hole or dot
        it using a Sharpie. Place one tee five inches behind the starting point
        and just outside your stroke path line (so you don’t knock the tee when
        you putt) and a tee of the same color five inches in front of the
        starting point. Put two tees of the same color five inches beyond the
        first two tees, and two more five inches beyond those. Step into your
        address position and begin making continuous strokes to get into your
        resonant rhythm. Once you have it down, begin to putt balls from your
        starting point by stroking from one tee to the same-colored tee. Start
        with the small stroke and end with the longest. As you roll these putts,
        note the feel of each swing and the distance the ball travels as a
        result. Don’t worry about the Quiet Eye technique or the line—the goal
        of this drill is to help you understand the relationship between stroke
        lengths at rhythm and roll distance for the conditions at hand. The
        drill is complete after you putt two balls at rhythm for each tee
        position.
      </div>
      <div class="p-mb-2">
        Rhythm Error No. 2: Stroke Acceleration What It Is: Making a backswing
        that’s too slow and short for the situation, and then over-accelerating
        the putterhead into the ball. What It Does: Causes poor touch,
        difficulty returning the face to square, and yipped putts.
      </div>
      <div class="p-mb-2">
        The Fix: Try the Luke Donald Drill. The name of this drill is derived
        from the fact that almost every Tuesday when I’m out teaching on Tour, I
        see Luke Donald investing time on the putting green performing this
        exercise. Place a ball on a fairly flat portion of the practice green
        between eight and 12 feet from a hole. Push two tees into the ground,
        one on each side of the equator of the ball and perpendicular to the
        line of your putt. This “gate” will prohibit the forward motion of the
        putterhead after impact. Knowing this, think about how much potential
        energy (length of backstroke) you’ll need to roll the ball to the hole
        with the correct speed. Don’t try to “hit and stop” at impact. Relax,
        make your stroke, and allow the tees to stop your putter. Not only will
        this drill teach you to make the appropriate-length backstroke to roll
        the ball the correct distance without hitting at it, you’ll ingrain the
        feel of returning the face to square at impact (the face makes contact
        with both tees at the same time). After five putts through the tees,
        complete the drill by removing the tees and stroking three more putts
        with the same stroke length and feel.
      </div>
    </div>
  </Panel>
  <Panel
    header="The Yips: Great rhythm dysruptor"
    :toggleable="true"
    :collapsed="true"
  >
    <img
      class="img4"
      src="@/assets/images/sieckmannPutting/yips1.png"
      alt="Sieckmann Putting"
    />
    <div class="accent1 fontSmall p-mb-2">
      If you have a true focal dystonia (the yips), you have no choice but to
      start over with a completely different grip or putting method than you’re
      using now. This will force you to create and use new neurological pathways
      to execute the motion.
    </div>
    <div class="fontSmall taLeft">
      <div class="p-mb-2">
        BREAKING DOWN THE GREAT RHYTHM DISRUPTER: THE YIPS A yip—a spasm-like
        flinch that immediately sends the ball off line and at the wrong
        speed—is clinically known as an “occupational focal dystonia,” and
        unfortunately, it can’t be cured with a subtle change in technique or a
        slight enhancement of mental skill. The neural pathways used to execute
        your stroke are permanently damaged. Your only solution, if indeed you
        have the putting yips, is to change your motor pattern so dramatically
        that you form new pathways to carry out the act. For decades, many of
        those afflicted with the yips successfully “started over” by anchoring a
        long putter to their sternum (or a belly putter to their midsection),
        which fixed their suspension point and allowed them to feel a
        pendulum-like rhythm, both proven yip-busters. But since this method has
        been deemed illegal by the USGA as of January 2016, you’ll have to think
        outside the box.
      </div>
      <div class="p-mb-2">
        Consider starting anew by changing to the claw grip, which positions the
        handle in the crook between your right thumb and forefinger. Positioning
        your bottom hand like this effectively limits the hinging action of your
        wrist, creating a fixed point in your stroke and eliminating the
        right-hand hit impulse. Another idea is to stand on the opposite side of
        the ball and putt left-handed. Either strategy is a fresh start—a shock
        to your system that sets the foundation for the creation of all-new
        neural pathways. Whatever method you choose, work on developing it using
        the fundamentals, training protocols, and mental keys outlined in this
        book. Soon, you’ll once again feel calm, confident, and competent on the
        greens. Starting over the right way may actually be an advantage.
      </div>
      <div class="p-mb-2">
        HOW TO BEAT PERFORMANCE ANXIETY But before you panic, hold tight—you may
        not have the yips! Poor putting and uncontrolled flinches are common,
        but an occupational focal dystonia is not. I would estimate that 90
        percent of the golfers that see themselves as having “the yips” have
        healthy neurology but are suffering from severe performance anxiety
        brought on by the toxic mix of the wrong stuff: poor technique, poor
        outcomes over time, and an unhealthy emotional attachment to their
        results.
      </div>
      <div class="p-mb-2">
        It’s a self-perpetuating process. Shoddy technique creates poor outcomes
        relative to known ability and expectation level. Emotionally, these
        results are embarrassing, and the player internalizes and imprints this
        embarrassment into their subconscious. Humans are naturally programmed
        to avoid such events (fight or flight), so when the environment is right
        for it to recur (similar putt, potential embarrassment looming), a
        player becomes racked with fear and anxiety. Here we go again! In this
        physiological state, the subconscious is rendered useless, forcing you
        to consciously command your body to execute the act. As a result, your
        natural fluid motion disappears and is replaced by a spastic,
        herky-jerky one. The more frequent these spasms occur, the more you
        worry; the more you worry, the more you imprint fear into your
        subconscious, elevating tension levels to an unbearable high. It’s a
        natural yet vicious cycle, and it often bites the hand of even the most
        talented players. I understand it. I’ve lived it, and more importantly,
        I’ve helped hundreds of players break the cycle and perform like they
        know they can.
      </div>
      <div class="p-mb-2">
        The solution to putting with greater confidence and trust is
        multifaceted, but it can be most simply described as “training like an
        adult and playing with the mind of a child.” To wit, children (and any
        beginning golfer, regardless of age) never hyperventilate over the ball
        or feel untimely and unwanted muscle contractions, because even though
        their motor-control and other developed skills are undeveloped, so are
        their expectations, which makes the results nonthreatening. The rare
        10-footer that goes in is an occasion to high-five or dance with joy;
        missed putts—even short ones—are accepted. The player’s self-image is
        independent of outcome and always tilted toward the positive. They enjoy
        the act for what it is, embracing the heroic while ignoring the
        pedestrian. Their healthy perspective and ignorance about the details
        regarding the “how” of putting leaves their conscious mind quiet and
        clutter-free. When the “how” is removed, there’s only the ball and the
        hole, rendering the act of putting a simple see-and-do exercise. This is
        the goal.
      </div>
      <div class="p-mb-2">
        Ultimately, your ability to trust your stroke, especially when something
        is on the line, is dependent on its worthiness. The first step is to
        make it worthy of your expectation (not perfect), and the second is to
        relearn how to let it go and just play. To me that is the definition of
        the “right stuff.”
      </div>
      <div class="p-mb-2">
        THE RIGHT STUFF There are two technical mistakes that directly lead to
        unwanted muscle contractions during the stroke. To boost your confidence
        and trust, you must eliminate these before you dive into the mental
        keys.
      </div>
      <div class="p-mb-2">
        The first of these fatal flaws is what I call an “acceleration flinch,”
        which can be best understood, once again, by re-creating the image of a
        pendulum. As the bob swings back and forth, velocity is least when
        displacement is greatest; the putterhead is momentarily motionless with
        a velocity of zero at the end of the backstroke and completion of your
        forward-stroke, representing potential energy and nothing else. The
        initial start of the forward-stroke marks the point where maximum
        acceleration occurs. At the bottom, velocity and kinetic energy reach
        peak levels, and then the restoring force of gravity slows the velocity
        back to zero at the finish. Relatively speaking, there’s a flat spot of
        speed surrounding impact in which the putterhead is neither accelerating
        nor decelerating. I call this the “unbroken zone” of putting, and
        becoming aware of it is the first step in solving the problem of
        performance anxiety.
      </div>
      <div class="p-mb-2">
        An acceleration flinch occurs when you speed up or slow down the putter
        in the unbroken zone by changing grip pressure and exerting extra force
        on the club. The good news is that you’ve already learned how to relax
        and swing the putter tension free at optimal rhythm. Rekindle that
        feeling and refrain from flinching by performing the Resonant Rhythm,
        Color-Coded-Tee, and Luke Donald drills daily (or as frequently as
        possible). The Luke Donald drill seems to be especially effective. When
        the zone around impact remains unbroken by velocity change, your putter
        will move like a warm knife through butter—smooth, uninterrupted, and
        flowing.
      </div>
    </div>
  </Panel>
</template>
