<template>
  <div class="fontSmall taLeft">
    <div class="p-mb-2">
      Have you ever watched a figure skater stick a triple jump, or a gymnast
      race across the mat and twirl high into the air before returning to her
      feet in perfect balance? When those incredibly skilled, complicated and
      athletic acts are flawlessly performed, it is usually within the rhythm
      created by movement. Quite literally, these athletes have a running start
      at greatness. This is a head start toward peak performance that golfers
      don’t have—unless they have the tools of the Essential Playing Skills.
    </div>
    <div class="p-mb-2">
      One of the challenges of the golf swing is that this graceful motion
      begins from a completely still, static position. There is no running
      start. The tension and the doubt that are often consumed by movement in
      other sports can creep into the body and mind of a golfer more easily
      because there is so much “downtime” in the game. This is why it is
      essential for you to build a bridge between yourself and your skills—a
      bridge that will take you into the Play Box in a peak-performance state,
      committed and without fear or doubt. This is also why many players have
      better balance if they do some kind of a waggle or keep in motion as they
      address the ball, instead of getting too static.The waggle is the
      transition from stillness to motion.
    </div>
    <div class="p-mb-2">
      This transition from address to the swing is challenging. Think of walking
      from bright sunlight into a dark room. Eventually, your eyes do adjust and
      you function perfectly in your new environment. But the transition does
      not come without some initial difficulty in seeing. The same is true when
      you leave that dark room and return to the sunlight. The real challenges
      of life are not when you are in the room but rather when you make
      entrances and exits.
    </div>
    <div class="p-mb-2">
      Golf is a series of entrances and exits. The swing is the room. The
      pre-shot routine is the entrance and the post-shot routine is the exit.
      The goal is to start completely centered and balanced, execute the swing,
      and then return to being completely centered and balanced. From sunlight
      to darkness and back to sunlight without tripping! This is an easily
      disrupted process that requires not just technique but understanding.
    </div>
    <div class="p-mb-2">
      In the next three chapters, we are going to discuss the three Essentials
      that define your swing, the Essentials that make your swing your signature
      on the golf course. These Essentials are Balance, Tempo and Tension
      Awareness. The only way for you to completely integrate your physical and
      technical skills into an efficient golf swing is by finding your balance,
      feeling the tempo that works for you and recognizing where your tension
      resides, and developing strategies for dealing with that tension.
    </div>
    <div class="p-mb-2">
      Again, to go back to our darkened room metaphor: Experience allows you to
      develop strategies for dealing with the transition. You learn to take it
      slowly after making your entrance or exit, until your eyes adjust. Our
      experience working with golfers has led us to understand the importance of
      Balance, Tempo and Tension Awareness. That experience has also led us to
      develop tools to help you enter the Play Box in a calm, rhythmic, balanced
      state and exit it the same way.
    </div>
    <div class="p-mb-2">
      We have met very few players in our work who have as much passion for the
      game of golf as Walter Owen, or Topper, as his friends call him. A
      businessman from Southern California, Topper had been playing the game for
      more than a half century when he came to us after reading about VISION54
      in The Wall Street Journal. Topper approached us at a time when his
      passion for the game was being challenged.
    </div>
    <div class="p-mb-2">
      I was struggling with mental and emotional habits on the course that were
      leaving me unaware of the physical reality and the technical truth that
      would allow me to create the shots that are possible in the situation at
      hand,” Topper says. These bad habits disrupted his technique, “leading to
      some nasty yips, shanks and other assorted clobbered shots that would
      challenge anyone’s self-image as a player,” he says. He was in a downward
      spiral.
    </div>
    <div class="p-mb-2">
      As his handicap rose from +2 to 4, Topper instinctively understood that
      his problems existed not because he had all of a sudden forgotten how to
      play golf or because his technique had drastically deteriorated, but
      rather because something was interfering with his execution of the swing
      and putting stroke. He just didn’t know how to approach the problem. The
      more he struggled, the less fun he had, and the less fun he had, the more
      he struggled.
    </div>
    <div class="p-mb-2">
      Fortunately, Topper avoided one of the biggest mistakes many golfers make.
      Players of every skill level, when they are struggling, start tinkering
      with their swing. Some even embark on the dangerous path of trying to
      change their swing. They assume that when their game leaves them—and
      everyone goes into a slump at some point—the reason for the poor play is
      always a mechanical issue. Such a drastic action as a swing change should
      always be the last resort.
    </div>
    <div class="p-mb-2">
      Of course, when we don’t play well, it is because our technique falters.
      But the real question is why the technique faltered. Where did the problem
      originate? If you don’t commit to a decision, it shows up in the
      technique. If you are cluttered with swing thoughts in the Play Box, it
      shows up in the technique. If you have too much adrenaline, it might
      affect your tempo and show up in the technique. At times it is something
      mechanical, like faulty alignment or misplaced ball position in the
      stance, but very often the technique problem is just a symptom of another
      problem
    </div>
    <div class="p-mb-2">
      Swing changes are difficult even for the most gifted athletes, and often
      the problems that exist do not require blowing up your swing and starting
      all over, but rather fine-tuning what you have. Before you start tinkering
      with your swing—which always makes you worse before you get better—check
      your BTT: Your Balance, Tempo and Tension Awareness. Trust us on this: It
      will only make you better. Attention to your BTT can never be overdone.
      BTT improves your swing without you consciously changing your swing! These
      elements are essential to a solid physical swing.
    </div>
    <div class="p-mb-2">
      The concept of BTT was a crucial breakthrough for Topper. “The
      comprehensive, holistic approach of VISION 54 is fundamental for me, and
      perhaps all, to dig out the bad habits and cultivate the ones required for
      golf glory and in finding your game,” he says. “It is a great privilege to
      be so schooled by the great game, which humbles as harshly as nature’s
      indifference to human folly.
    </div>
    <div class="p-mb-2">
      In our program, Topper came to understand that his game was simply out of
      balance. He had become an angry player and as a result had lost his
      balance on the golf course. While there are many different kinds of golf
      swings that function successfully, they all have these three things in
      common: The player is in Balance, he has found the Tempo that works for
      him, and he has established a Tension Awareness that allows him to repeat
      his swing—especially under pressure.
    </div>
    <div class="p-mb-2">
      I shot 69 today with lots of shots left to be had,” Topper wrote to us
      after he had been at our school. “Putting was unproductive. I missed seven
      inside fifteen feet and left a little bunker shot in the bunker. I have
      benefited greatly from Play Box involvement with balance. BTT is the
      platform on which the mechanics manifest. Now I can experience my
      mechanics instead of intending them, which was so often untoward.
    </div>
    <div class="p-mb-2">
      If you focus on BTT, as Topper did, work to develop the tools we have
      designed for BTT improvement and take them with you into the Play Box, you
      will see the results in the way you perform. You will shoot lower scores.
      One of the keys to improving your game is determining what area of focus
      in your practice will yield the most results. If there were one thing you
      could work on that would have the greatest impact in making you a better
      player, what would it be?
    </div>
    <div class="p-mb-2">
      For many players, the single biggest improvement factor is working on
      their BTT. If you are coming over the top, perhaps the flaw is not in your
      swing but rather in your balance or tempo or a result of tension. Those
      great shots you hit are not accidents but rather the result of smooth
      execution. That silky execution comes at those times when you make a swing
      in balance, at your ideal tempo and without tension. You can make that
      happen more often. The golf swing is a graceful, balletlike movement that
      is most effective when it finds its own rhythm.
    </div>
  </div>
</template>
