<template>
  <div class="fontSmall taLeft">
    <img src="@/assets/images/hardy2/fig9.28.png" class="img10" alt="Alt" />
    <div class="accent1 fontSmall p-mb-2">
      FIG. 9.28. This out-to-in mistake originates with the arms being thrown
      outward along with the clubshaft
    </div>
    <img
      src="@/assets/images/hardy2/mistakesText12.png"
      class="img10"
      alt="Alt"
    />
    <img
      src="@/assets/images/hardy2/mistakesText13.png"
      class="img10"
      alt="Alt"
    />
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
    <div class="p-mb-2"></div>
  </div>
</template>
