<template>
  <Panel
    header="Training Principles (for technique)"
    :toggleable="true"
    :collapsed="true"
  >
    <Panel header="Introduction" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          Facilitates Mastery of Technical and Mental Aspects of Golf:
        </div>
        <div class="p-mb-2">
          The key to Success, Confidence and Belief. Building belief and
          confidence to execute a shot comes from Developing Mastery of both the
          technical and mental components of the shot by executing appropriate
          training techniques. Block Training to learn fundamentals, Random
          Training to develop fundamental and advanced technical and mental
          skills, Games to transition skills from training center to matches on
          the course.
        </div>
      </div>
    </Panel>
    <Panel header="How To Train Smart" :toggleable="true" :collapsed="true">
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          Real (simulated golf) * Hit shots as much like hitting on the course
          as possible * Hit as many different shots and lies as possible * Use
          as many different mental skills as possible
        </div>
        <div class="p-mb-2">
          Integrate practice skills into simulated golf as soon as possible
          (random, games) * Use feedback to evaluate results in real time * Use
          new skill in simulated golf as soon as possible Even before ready to
          take it onto course.
        </div>
      </div>
    </Panel>
    <Panel
      header="Block Practice: Learn and maintain Technical Skills (Internal Focus)"
      :toggleable="true"
      :collapsed="true"
    >
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          10 percent of your daily dedicated practice time Makes use of internal
          focus: the focus used for developing fundamentals, that is first time
          learning, and maintaining what has been learnt
        </div>
        <div class="p-mb-2">
          Primary focus on body movements during swing. Only place is during
          block training.
        </div>
        <div class="p-mb-2">
          1. Pay attention to your intention. What are you going to accomplish
          in this time frame?
        </div>
        <div class="p-mb-2">
          2. Pay attention to the details: grip, alignment, ball position,
          balance, etc.
        </div>
        <div class="p-mb-2">
          3. Put yourself in a learning environment: Go slow; focus internally;
          practice with immediate, accurate, and reliable feedback.
        </div>
        <div class="p-mb-2">
          4. When making changes, focus on the process and not the immediate
          results.
        </div>
        <div class="p-mb-2">
          5. Stay focused on the same thought for at least three weeks.
        </div>
        <div class="p-mb-2">
          6. When training mechanics, intersperse drills with regular swings.
        </div>
        <div class="p-mb-2">
          7. Never practice “looking” at a target while “thinking” mechanics.
          Hit into open space or remove the ball from the equation when
          possible. Once you have the confirmation you need, quit.
        </div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
        <div class="p-mb-2"></div>
      </div>
    </Panel>
    <Panel
      header="Random Practice: Sharpen Playing Skills (technical, mental) (External Focus)"
      :toggleable="true"
      :collapsed="true"
    >
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          Requires external focus: - for honing skills. Focusing externally
          allows an action to occur automatically or subconsciously, like when
          you tied your shoelaces this morning. There’s no “how” when you’re
          externally focused, there’s just “do.”
        </div>
        <div class="p-mb-2">
          Primary focus on target/shape with awareness of swing-thought in
          background. Thought background is something you’re simply aware of,
          not the predominant thought or command on how to play. Commands tend
          to ruin athletic motion and timing, while a simple awareness of a
          mechanic (if subservient to a strong focus on the shot) does not - may
          even help guide execution of shot.
        </div>
        <div class="p-mb-2">
          Practice external focus to develop its execution (won’t develop on its
          own). External focus facilitates reaction shots, I.e., ‘Trust It’
          (unconscious shotmaking). Makes up 100% of play time.
        </div>
        <div class="p-mb-2">
          70 percent of your daily dedicated practice time ROUTINE FOR ALL SHOTS
        </div>
        <div class="p-mb-2">
          1. Practice your full routine and “trusting” with external focus.
          Picture yourself in tournament situations; take the time to get
          focused on the target and the shot. Include post-shot error detection
          and positive imprinting
        </div>
        <div class="p-mb-2">
          2. Shape shots: high, low, draws, fades, etc. Take five yards off or
          add five yards to your stock shot.
        </div>
        <div class="p-mb-2">
          3. Change clubs, lies (uphill, downhill, rough, etc.) and targets
          frequently.
        </div>
        <div class="p-mb-2">
          4. Practice every part of the game and every conceivable situation.
        </div>
        <div class="p-mb-2">
          5. The goal of this type of practice is to learn to perform the
          routine & shots subconsciously and do it without thinking, as you do
          when you’re tying your shoelaces.
        </div>
      </div>
    </Panel>
    <Panel
      header="Games: Transition Training to Playing"
      :toggleable="true"
      :collapsed="true"
    >
      <div class="fontSmall taLeft">
        <div class="p-mb-2">
          20 percent of your daily dedicated practice time. Requires external
          focus - to facilitate taking the skills level attained on the range to
          the course
        </div>
        <div class="p-mb-2">
          Shot performance is exactly as in random practice but used to win
          games not to hit to random targets. 1. Win your way off the practice
          facility by winning one or more gamesThe ability to win games ( attain
          goals) in practice will translate into the ability to execute that
          shot with confidence on the course when it counts. 2. Games can be
          played alone, or with greater effect, against an opponent.
        </div>
      </div>
    </Panel>
    <Panel
      header="The Two Practice Essentials (Pia Nilsson: Play Your Best Golf Now)"
      :toggleable="true"
      :collapsed="true"
    >
      <Panel header="Introduction" :toggleable="true" :collapsed="true">
        <div class="fontSmall taLeft">
          <div class="p-mb-2">
            “The secret is in the dirt.” Ben Hogan, who invented practice”.
            “SWING KEY: You get good at what you practice.”
          </div>
          <div class="p-mb-2">
            The 8 Essential Playing Skills for Peak Performance are the tools
            you need to play your best golf now. The 2 Essential Practice Skills
            will maximize your return on practice time. Together, they will help
            you lower your scores. And, just as there are many myths to overcome
            about playing the game, there is a mountain of misconceptions about
            how to practice the game, some of which will actually inhibit your
            development as a player. Hitting golf balls until your hands bleed
            is only beneficial if you are trying to get a Band-Aid endorsement
            deal.
          </div>
          <div class="p-mb-2">
            Practice needs to be about quality, not only quantity. And it needs
            to be about golf. The more we have worked as instructors, the more
            we have become fascinated with the fact that a lot of players spend
            a lot of time on the practice range with little to show for it. They
            spend hours hitting golf balls, but they don’t get any better at
            golf. They may develop beautiful, textbook swings, but they don’t
            get the ball in the hole any faster. And the last time we checked,
            that’s the point of the game.
          </div>
          <div class="p-mb-2">
            When we recognized this phenomenon, we directed our energy toward
            more efficient practice. What were the mistakes being made by all
            those practice junkies who can’t translate hitting the ball better
            on the range to lower scores on the golf course? We found that there
            are Essential Skills for practice, just as there are for play. The
            key concept that we latched onto is the absolute necessity of
            tearing down the wall between practice and play. They are both golf.
            Immerse yourself in the totality of the game. The main thing you are
            trying to do is to play better golf on the golf course—not hit the
            ball great on the practice range. To accomplish that goal, you need
            to focus all of your attention toward playing on the golf course.
            When you can’t be on the golf course, you have to make your practice
            as golflike as possible. If you are working on your tempo, for
            example, you have to pay full attention to that activity—no
            multitasking allowed—and you have to bring the tempo exercises into
            real-golf situations, either on the course or in simulated golf on
            the practice range.
          </div>
        </div>
      </Panel>
      <Panel header="1: Simulated Golf" :toggleable="true" :collapsed="true">
        <div class="fontSmall taLeft">
          <div class="p-mb-2">
            So the first Practice Essential is about making your practice as
            much like real golf as you can. We call it Simulated Golf. All
            research says that what you practice is what you get good at. If you
            practice with chopsticks, you get good at eating with chopsticks. If
            you practice hitting two dozen 7-irons in a row from a perfect lie,
            you get good at that. If you practice being unfocused, you get good
            at that. The point is, if you want to be good at golf, you need to
            spend at least half your time practicing within the context of the
            game.
          </div>
          <div class="p-mb-2">
            Why do you hit the ball so well on the range and then hit it all
            over the place when you get on the golf course? Because you have
            practiced hitting balls on the range. You haven’t practiced hitting
            balls under real playing conditions.There are shot situations and
            pressures on the course you won’t find on the range—unless you
            intentionally create them.You have to be proactive and change the
            very essence of how you practice. This is under your control.
          </div>
        </div>
      </Panel>
      <Panel
        header="2: Integrate Different Skills"
        :toggleable="true"
        :collapsed="true"
      >
        <div class="fontSmall taLeft">
          <div class="p-mb-2">
            The second Practice Essential is to know how to integrate different
            skills. The three keys to integration are engagement, repetition and
            accurate feedback. Anytime you want to improve on a small piece of
            the game—let’s say it is your tempo—you need to practice in such a
            way that you can integrate the improved skill into your game.What is
            the purpose of your practice? How you practice determines what you
            retain from that practice session and establishes how successfully
            that knowledge transfers to the golf course. Remember the chapter on
            storing memories? The practice won’t get filed away in your mind if
            you are not emotionally connected to it. You won’t hardwire the new
            habits, and that’s what you are trying to do. Don’t scrape and hit;
            instead, think and hit, feel and hit, learn and hit.
          </div>
        </div>
      </Panel>
      <Panel
        header="Summary of 1 and 2: Real Wide Deep"
        :toggleable="true"
        :collapsed="true"
      >
        <div class="fontSmall taLeft">
          <div class="p-mb-2">
            You can summarize the Practice Essentials with this three-word
            framework: Real. Wide. Deep.
          </div>
          <div class="p-mb-2">
            Real: Practice as if you were actually playing golf.
          </div>
          <div class="p-mb-2">
            Wide: Ensure that you are preparing all of the elements of peak
            performance: the physical, technical, mental, emotional, social and
            spiritual elements of the game. Cross-training is essential. Address
            the whole golfer.
          </div>
          <div class="p-mb-2">
            Deep: Stay focused and pay attention to your intention. Why are you
            practicing? What is your intent? You must be 100 percent present. To
            store positive practice memories you must be fully engaged in the
            process of practice.
          </div>
          <div class="p-mb-2">
            Most players don’t get the best return on investment for their
            practice times,” says Scott Dickson, director of golf at Shaughnessy
            Golf and Country “Club in Vancouver, Canada, who has attended our
            school several times, both as a player and as a coach. “In this busy
            world, they need to make practice more efficient. I feel many of
            these practicers would have more fun and be better off playing the
            game as opposed to wasting time on the practice range.
          </div>
          <div class="p-mb-2">
            If you practice being a great practice range player, that’s exactly
            what you will become. To practice only on the range is like working
            on your volleyball game hoping to get good at tennis.“For you to
            improve and perform well under the stress of golf, you must make
            practice more like golf,” says Scott, who heard about VISION54 at
            various PGA seminars. Through his experience, Scott knew most
            players weren’t getting much out of practice. At our school he
            learned why.
          </div>
          <div class="p-mb-2">
            Let’s look at the first Practice Essential. When you practice on the
            range, you need to simulate golf. What do we mean by this? We mean,
            don’t just stand there and mindlessly hit golf balls. Is that how
            you do it when you are playing a round of golf? Of course not. On
            the golf course, every shot is different, every shot produces a new
            challenge, and every shot is a new adventure.
          </div>
          <div class="p-mb-2">
            How can you create that situation on the practice range? Change
            clubs for every shot. Change targets. Do your pre-shot routine for
            every shot. Create pressure by imagining competitive situations. Be
            very specific in the situations you simulate. Imagine you have a
            one-“stroke lead and have to hit the green and two-putt. Imagine the
            wind is blowing against you and you have to decide between a 5-iron
            and 6-iron. Play an imaginary round on the range.”
          </div>
          <div class="p-mb-2">
            “You can also invite things that distract you into your practice.
            Create a slow-play situation or hit balls with people milling around
            you and talking; don’t give yourself a perfect lie. Don’t practice
            only in perfect situations. Make your mind recalibrate for each shot
            you hit on the range. Hit half shots. You need to practice the real
            deal. We call this “Bring It On” practice.”
          </div>
          <div class="p-mb-2">
            “I use a lot of awareness exercises with my students that help them
            both physically and mentally,” Scott says. “I also empower them to
            coach themselves, learn what makes them ‘tick’ as players and build
            on their strengths. That kind of learning stands the test of time on
            the course—holds up to the challenges of golf—better than just
            dishing out technical info.” Scott has created a learning situation
            for his students that is focused and thus fruitful.”
          </div>
          <div class="p-mb-2">
            Andy Fish began teaching golf in 1990 and he learned about our
            VISION54 program while he was at Bob O’Link Golf Club in Highland
            Park, Illinois, through a friend and member who had taken our
            program. “I was interested immediately because of my interest in
            psychology, my undergraduate major, and a need to improve my
            thinking on the course. I soon found out after participating in a
            VISION54 school that it is much bigger than thinking on the course.
            It completely changed the focus of my teaching.
          </div>
          <div class="p-mb-2">
            Andy came away from our school much more aware of the need to focus
            on the whole golfer in his teaching. “Before VISION54, I focused on
            making the mechanics of all my students better,” Andy said. “I now
            look at the student as a golfer on a journey, and I am here to help
            them on their journey. I look at the whole picture. Now I know that
            I am a guide to help them enjoy golf. With this thinking I am
            helping my students more than ever.”
          </div>
          <div class="p-mb-2">
            Andy told us about a woman he worked with who was a 25 handicap and
            was not enjoying golf. She worked hard at hitting balls and was
            getting pointers from her husband. One day she participated in a
            free clinic taught by Andy that focused on the Think Box. “We needed
            to make it a clean (mentally) and comfortable place,” Andy said.
            “This change in thinking not only was successful in lowering her
            handicap to 20 and giving her two new record-low rounds, but she
            also loves to play golf now. She really enjoys the whole process,
            from getting ready to play to lunch after. She now owns her journey.
          </div>
          <div class="p-mb-2">
            Andy says the most common mistake he sees in the recreational golfer
            is the obsession with trying to fix things. “They hit a shot to the
            right and they try to fix it; the next shot is fat and they try to
            fix it,” Andy says. “The player ends up micromanaging their game and
            never focuses on one thing long “enough to improve. I work really
            hard with my students to help them understand their swing and focus
            on the one thing that will most benefit them according to their
            journey.” There has been a lot of research done on practice in
            various disciplines, and we have applied that research to golf. We
            have simplified all of the research available into the Practice
            Essentials. The method we have developed works not only in golf but
            in any skill you are trying to master. A skilled pianist doesn’t
            play the same note over and over. They practice real playing. Why
            should you hit the same shot over and over? If you honor these
            Essentials, you can make any practice session effective. It doesn’t
            matter if it’s for ten minutes or eight hours.
          </div>
          <div class="p-mb-2">
            The first thing you must remember is that to make practice
            beneficial, you have to be completely engaged in what you are doing.
            That means you aren’t listening to music on an iPod or chatting with
            the person hitting balls next to you. To make a new skill become a
            new habit, you need to pay full attention to the intention of your
            activity. If you want to improve your grip, you need to do many
            repetitions fully focused on the grip, while giving yourself
            accurate feedback about how you are doing. If you are practicing
            your balance, we want you to do exercises that provide you feedback
            on balance. Keep it clean and simple. This is true no matter which
            component you are working on: Grip, Posture, Play Box, Decision and
            Commitment, etc.
          </div>
          <div class="p-mb-2">
            And remember this: When you practice one piece of the puzzle—like
            the grip—put it back into the full puzzle as soon as you can. Put it
            back in the game. Practice the grip every opportunity you have, even
            at home, but eventually you have to place it into Simulated Golf—you
            have to practice with your full routine using the new grip and
            hitting different clubs to different targets. And you need to take
            the new grip out onto the course.
          </div>
          <div class="p-mb-2">
            To be good at golf, you need to learn to perform with the skills you
            have. With any piece of the game, you want to learn and create a new
            habit—a neuropathway in the brain—so you need repetition plus
            accurate feedback plus engagement. Let’s say you tend to take the
            club back too far on the inside and you are trying to get back on
            plane. How do you create a new habit? You do it by practicing the
            new move.
          </div>
          <div class="p-mb-2">
            View it as if you are going down a snowy hill with your sled. When
            you make the “new move,” you create a path down the slope with your
            sled. If you stay aware and focused, you can go down the same path
            many times. After a while, a strong rut has formed and the sled will
            not go off the path: The new move has now formed as strong a rut as
            the old move had. In terms of what is happening in your brain, this
            is called “myelination”—when a protein forms around the neuropathway
            to ingrain the skill.
          </div>
          <div class="p-mb-2">
            But if you go down the slope and you are not focused and are not
            giving yourself accurate feedback about the new move, you will end
            up with twelve detours to the main path. When you go out to play,
            the club will not follow the path you desire. That’s why you should
            never embark on a swing change unless you have the time to commit to
            it. Under pressure, the most hardwired neuropathway will win out.
            The old swing will return. You might understand what you are
            supposed to do, you can see it on a video, and you might have done
            it many times during a lesson or practice time, but it’s not yet
            hardwired to show up under competitive playing conditions.
          </div>
          <div class="p-mb-2">
            Think of it this way: You have a certain foot you like to start with
            going down stairs—we all do. Let’s say it’s the right. Now you make
            a “swing change” and switch to starting with the left foot. You
            practice this for some time. Then someone yells, “Fire!” Which foot
            do you think you will use? Of course, the one you are the most
            familiar with, the one that is most hardwired.That’s the swing that
            will show up under pressure.
          </div>
          <div class="p-mb-2">
            Repetition of a specific skill creates repetitive connections in the
            brain. These repetitive connections, along with accurate feedback
            and full engagement, trigger cells that produce the fatty insulation
            called myelin. Myelin insulates the connection, making it fast. The
            connections get optimized, making them more like a broadband
            Internet connection than dial-up. You go from understanding the
            skill to performing the skill without thinking about it.
          </div>
          <div class="p-mb-2">
            Ben Hogan’s neural pathways with a 5-iron turned into a
            superhighway. Ben loved to hit balls. Unfortunately, he did not like
            to putt. Ben’s neural pathways for the putting stroke, combined with
            his negative self-talk about putting, created a less than optimal
            putting performance. Ben myelinated his 5-iron to an extremely high
            level. We think that’s what he meant when he said the secret is in
            the dirt. Had Ben known about myelin, he might have spent more time
            practicing his putting and myelinated his stroke in a more
            beneficial way.
          </div>
          <div class="p-mb-2">
            Essentially, this research concludes that the most productive
            practice simulates real-game situations. Pilots use a simulator, so
            do surgical students. Actors have dress rehearsals and previews in
            front of live audiences before opening night, and sports teams hold
            scrimmages against other teams. There are some things that can only
            be learned in actual game situations. Golf is no different
          </div>
          <div class="p-mb-2">
            If you hit dozens of 8-irons from perfect lies and without a target
            or a routine, you get good at that.
          </div>
          <div class="p-mb-2">
            It is so easy to let bad habits seep into your routine. If you hit
            shots on the range while talking to others, without your mind
            engaged, you are not practicing golf; you are practicing being
            unfocused. If you do not have a purpose for your practice, you will
            actually be hurting your game. Ask yourself: What do you want to be
            good at? You should never let less than half of your practice time
            be simulating the real deal. This also makes practice more fun. Make
            practice part of the game and not a separate component of it.
          </div>
          <div class="p-mb-2">
            I believe that to continue to grow the game and grow the number of
            players, fun should be the number-one ingredient,” says Scott.
            “VISION54 taps into this and allows players to play the game in a
            way that they have never understood before. I have seen countless
            students get more out of the game and drop those handicaps without
            delving into the mechanical, technical world of the game, though
            there is definitely room for that element.
          </div>
          <div class="p-mb-2">
            Most players use the word “work” to describe practice. You can
            change that. You can make it “play,” and in doing so, make it more
            productive. If you don’t have time to play eighteen holes or even
            nine holes, play on the range. Use our exercises to have fun and
            learn. And remember this: Only practice for as long as you can be
            present and engaged. If your attention starts to wander, take a
            break or stop. Don’t practice bad habits.
          </div>
          <div class="p-mb-2">
            There will be times when you have to focus your practice on just a
            small piece of the game, such as turning differently in the
            backswing. At these times you are not practicing golf anymore—you
            are trying to develop a detail of the game. The key here is to put
            the component back in simulation and in real-golf situations as soon
            as possible. You might practice the turn you want for fifteen
            minutes with accurate feedback and then go back to simulating golf
            with the new move.
          </div>
          <div class="p-mb-2">
            Butch Harmon always said one of the amazing things about Tiger Woods
            was his courage to take new things he had learned right out on the
            golf course. That is a sign of confidence, but it is also
            recognition that only in real-golf situations does real learning
            occur. In fact, as Tiger was going through his personal crisis
            during the 2010 season, he was also trying to make a swing change
            that would allow him to be more consistent off the tee. Even though
            he struggled, Woods kept playing, knowing real improvement would
            only come in real-golf situations. He also was willing to accept the
            fact that he would get worse before he got better.
          </div>
          <div class="p-mb-2">
            This is an important fact to be aware of. If you want to make a
            swing change, do you have the necessary time to invest in the
            process? You can’t change your swing the day before a tournament or
            during warm-up. You can make small adjustments, like alignment or
            the position of the ball in your stance, but you are not going to be
            able to change the dynamic motion of the swing easily—or quickly.
            The trap of over-tinkering is a black hole into which many golfers
            fall. If you are a weekend golfer and don’t have hours to practice,
            what is the most efficient way you can get better? A good place to
            start is with the 8 Essential Playing Skills.
          </div>
          <div class="p-mb-2">
            Important rounds don’t wait to come along when all the pieces of
            your game are in perfect shape; they come when they come. You always
            want to know how to play with what you’ve got. You can practice
            being prepared for this, but only if you make practice like golf and
            pay attention to your intention. With those Essentials, your
            practice will be much more productive and will lead you to lower
            scores.
          </div>
        </div>
      </Panel>
      <Panel
        header="Simulated Golf Exercises"
        :toggleable="true"
        :collapsed="true"
      >
        <div class="fontSmall taLeft">
          <div class="p-mb-2">
            Play as many imaginary holes as you can in thirty minutes on the
            practice area. Imagine the course and your playing partners. Hit the
            clubs in the order you would hit them on the course. Take breaks
            between shots.
          </div>
          <div class="p-mb-2">
            Imagine you are leading a tournament by four strokes with three
            holes to go. Play the last three holes.
          </div>
          <div class="p-mb-2">
            Hit ten shots in twenty minutes. Imagine each one having a specific,
            important meaning. What is your strategy for managing your emotional
            state before stepping into the Think Box and the Play Box? What
            percentage of shots did you hit the way you wanted to?
          </div>
          <div class="p-mb-2">
            Imagine each of these situations and hit the shot: Hit a 6-iron to a
            par-3 with the pin fifteen feet from a bunker. “Hit a sixty-yard
            pitch shot against a 30-mph wind. Hit a punch 9-iron between two
            trees four yards apart and twenty yards in front of you. Hit a drive
            around a dogleg with a slight draw. Hit a super-high hybrid over
            some trees ninety yards in front of you
          </div>
          <div class="p-mb-2">
            Make four chip-and-one putts in a row. Make each chip different.
          </div>
          <div class="p-mb-2">
            Make ten consecutive three-foot putts from different locations. Do
            your full routine.
          </div>
          <div class="p-mb-2">
            Putt six different twenty-footers with the goal of getting the ball
            to the hole or a maximum one putter length past the hole.
          </div>
          <div class="p-mb-2">
            Make ten consecutive three-footers from a circle around the hole.
          </div>
          <div class="p-mb-2">
            Chip from the same spot with four different clubs—sand wedge,
            pitching wedge, 9-iron and 8-iron. Do this until you get all four
            shots within a club length of the hole. When finished, repeat the
            same drill to another hole.
          </div>
          <div class="p-mb-2">
            Set up a five-hole course around the chipping green with the par
            being 10. Play as many rounds as you can in thirty minutes.
          </div>
          <div class="p-mb-2">
            Invite distractions to a practice session. Have someone talk during
            your swing or place a shadow across your line or jingle change in a
            pocket. Can you stay engaged in your Play Box even when distractions
            occur? Earl Woods would do this with his son when Tiger was a child.
          </div>
          <div class="p-mb-2">
            Place gloves, head covers or anything you can find at ten-, twenty-,
            thirty-, forty-, fifty-yard intervals and as far as you can go. Hit
            pitch shots at the different distances. Mix it up for every shot.”
          </div>
          <div class="p-mb-2"></div>
          <div class="p-mb-2"></div>
        </div>
      </Panel>
    </Panel>
  </Panel>
</template>
